import { useCallback, useState } from 'react';
import { TFunction } from 'i18next';
import { useMountedState } from '../../hooks';
import { ErrorResponse } from '../responses';

type RequestStateSuccess<T> = {
    type: 'REQUEST_SUCCESS';
    response: T;
};

export const isSucceeded = <T>(
    state: RequestState<T>,
): state is RequestStateSuccess<T> => state.type === 'REQUEST_SUCCESS';
export const isLoading = <T>(
    state: RequestState<T>,
): state is ReturnType<typeof requestError> => state.type === 'REQUEST_START';
export const isFailed = <T>(
    state: RequestState<T>,
): state is ReturnType<typeof requestError> => state.type === 'REQUEST_ERROR';
export const requestInit = () =>
    ({ type: 'REQUEST_INIT', response: null } as const);
export const requestStart = () =>
    ({ type: 'REQUEST_START', response: null } as const);
export const requestSuccess = <T>(response: T): RequestStateSuccess<T> =>
    ({ type: 'REQUEST_SUCCESS', response } as const);
export const requestError = (error: ErrorResponse) =>
    ({ type: 'REQUEST_ERROR', error, response: null } as const);
export const responseOrUndefined = <T>(
    request: RequestState<T>,
): T | undefined =>
    request.type === 'REQUEST_SUCCESS' ? request.response : undefined;
export type RequestState<T> =
    | ReturnType<typeof requestInit | typeof requestStart | typeof requestError>
    | RequestStateSuccess<T>;

export const useRequestState = <T>() => {
    const isMounted = useMountedState();
    const [requestState, setRequestState] = useState<RequestState<T>>(
        requestInit(),
    );
    const setRequestStateCallback = useCallback(
        (state: RequestState<T>) => {
            if (isMounted()) {
                setRequestState(state);
            }
        },
        [setRequestState, isMounted],
    );
    return [requestState, setRequestStateCallback] as const;
};

export const handleErrorMessages = (
    e: ErrorResponse,
    t: TFunction,
): ErrorResponse => {
    if (!e.status || e.message) return e;
    const status = e.status;
    if (status === 404) {
        e.message = t('error.status.404');
    } else if (status === 422) {
        e.message = t('error.status.422');
    } else if (status === 429) {
        e.message = t('error.status.429');
    } else if (status === 409) {
        e.message = t('error.status.409');
    } else if (status === 500) {
        e.message = t('error.status.500');
    } else if (status === 401) {
        e.message = t('error.status.401');
    }
    return e;
};
