import React, { useMemo } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

const useStyles = makeStyles(() => ({
    multiLineCell: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    campusCellText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

const CampusCell: React.FCC = (props: Table.DataCellProps) => {
    const { doneeId, allCampuses } = props.row;

    const classes = useStyles();
    const { campuses } = useSelector((state: AppState) => ({
        campuses: state.Donee.campuses,
    }));
    const campus = useMemo(() => {
        const campus = campuses.find((c) => c.id === doneeId);
        return {
            name: campus?.name,
            address: campus
                ? `${campus.address} ${campus.city}, ${campus.state}`
                : '',
        };
    }, [doneeId, campuses]);

    return (
        <Table.Cell
            {...props}
            className={classes.multiLineCell}
            style={{
                paddingLeft: 16,
                paddingRight: 16,
            }}
        >
            {allCampuses ? (
                <GivelifyLabel
                    className={classes.campusCellText}
                    color="neutralGrey"
                    text="All Campuses"
                    variant="body2"
                />
            ) : (
                <>
                    <div title={campus.name}>
                        <GivelifyLabel
                            className={classes.campusCellText}
                            lineHeight={16}
                            text={campus.name}
                            variant="body2"
                        />
                    </div>
                    <div title={campus.address}>
                        <GivelifyLabel
                            className={classes.campusCellText}
                            color="neutralGrey"
                            text={campus.address}
                            variant="body3"
                        />
                    </div>
                </>
            )}
        </Table.Cell>
    );
};

export default CampusCell;
