import React from 'react';
import { BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { mergeClassNames } from '../utils/classNameUtils';
import {
    GivelifyButtonBaseStyles,
    GivelifyButtonVariableStyles,
    GvlButtonState,
} from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyIcon, GivelifyIconVariant } from '../components/GivelifyIcon';
import LoadingRing from '../components/LoadingRing';
import LoadingDots from '../components/LoadingDots';
import { GivelifyButtonRipple } from './ripple';
import { GivelifyColorOrString } from '../style/givelifyDefaultTheme';
import { t } from 'msw/lib/glossary-de6278a9';

interface GivelifyButtonBaseProps extends GivelifyBoxMarginProps {
    /**
     * Name of the button. Used for click events tracking as well
     */
    name?: string;
    /**
     * Size of the button
     */
    size?: 'xLarge' | 'large' | 'small' | 'dense' | 'auto' | 'shrink';
    /**
     * Width
     */
    width?: 'auto' | '100%' | number;
    /**
     * Min Width
     */
    minWidth?: 'auto' | '100%' | 'initial' | number;
    /**
     * Min Height
     */
    minHeight?: 'auto' | '100%' | 'initial' | number;
    /**
     * Height
     */
    height?: number;
    /**
     * Font size
     */
    fontSize?: number;
    /**
     * Line height
     */
    lineHeight?: number;
    /**
     * Dissable button.
     */
    disabled?: boolean;
    /**
     * Override classes.
     */
    classes?: {
        root?: string;
        icon?: string;
        startIcon?: string;
        endIcon?: string;
        indicator?: string;
    };
    /**
     * Use bold style for text.
     */
    bold?: boolean;
    /**
     * Override normal Style.
     */
    normalStyle?: GvlButtonState;
    /**
     * Override hover Style.
     */
    hoverStyle?: GvlButtonState;
    /**
     * Override active Style.
     */
    activeStyle?: GvlButtonState;
    /**
     * Override disabled Style.
     */
    disabledStyle?: GvlButtonState;
    /**
     * Override focus Style.
     */
    focusStyle?: GvlButtonState;
    /**
     * Depreciated. Use `width="100%"`
     */
    fullWidth?: boolean;
    /**
     * Render icon before text
     */
    startIcon?: React.ReactNode;
    /**
     * Render icon after text
     */
    endIcon?: React.ReactNode;
    /**
     * Render GivelifyIcon before text
     */
    startIconVariant?: GivelifyIconVariant;
    /**
     * Render GivelifyIcon after text
     */
    endIconVariant?: GivelifyIconVariant;
    /**
     * Show loading indicator
     */
    isLoading?: boolean;
    /**
     * Depreciated. use `classes.indicator`
     */
    loadingIndicatorClassName?: string;
    /**
     * Loading indicator variant. (Ring | Dots)
     */
    loadingIndicator?: 'ring' | 'dots';
    /**
     * Wrap text
     */
    wrap?: boolean;
    /**
     * Disable ripple effect
     */
    disableRipple?: boolean;
    /**
     * Color of the text/icon
     */
    color?: GivelifyColorOrString;
    /**
     * Padding of the text/icon
     */
    padding?: number;
    id?: string;
    track?: boolean;
}

interface GivelifyButtonWithTextProps {
    variant?:
        | 'primary'
        | 'secondary'
        | 'ghost'
        | 'danger'
        | 'danger-secondary'
        | 'primary-alt'
        | 'secondary-alt'
        | 'ghost-alt';
    icon?: React.ReactNode;
    iconVariant?: GivelifyIconVariant;
    /**
     * Text to display
     */
    text: string | number;
}

interface GivelifyButtonWithIconProps {
    variant?:
        | 'primary'
        | 'secondary'
        | 'ghost'
        | 'danger'
        | 'danger-secondary'
        | 'primary-alt'
        | 'secondary-alt'
        | 'ghost-alt';
    icon?: React.ReactNode;
    iconVariant: GivelifyIconVariant;
    /**
     * Text to display
     */
    text?: string | number;
}

interface GivelifyButtonWithIconCustomRenderProps {
    variant?:
        | 'primary'
        | 'secondary'
        | 'ghost'
        | 'danger'
        | 'danger-secondary'
        | 'primary-alt'
        | 'secondary-alt'
        | 'ghost-alt';
    icon: React.ReactNode;
    iconVariant?: GivelifyIconVariant;
    /**
     * Text to display
     */
    text?: string | number;
}

interface GivelifyButtonLinkProps {
    variant?: 'link' | 'link-alt';
    icon?: React.ReactNode;
    iconVariant?: GivelifyIconVariant;
    /**
     * Text to display
     */
    text: string | number;
}

interface GivelifyButtonIconTextProps {
    variant: 'icon-text';
    icon?: React.ReactNode;
    iconVariant: GivelifyIconVariant;
    /**
     * Text to display
     */
    text: string | number;
}

interface GivelifyButtonIconTextCustomRenderProps {
    variant: 'icon-text';
    icon: GivelifyIconVariant | React.ReactNode;
    iconVariant?: GivelifyIconVariant;
    /**
     * Text to display
     */
    text: string | number;
}

interface GivelifyButtonIconProps {
    variant: 'icon';
    icon?: React.ReactNode;
    iconVariant: GivelifyIconVariant;
    /**
     * Text to display
     */
    text?: string | number;
}

interface GivelifyButtonIconCustomRenderProps {
    variant: 'icon';
    icon: React.ReactNode;
    iconVariant?: GivelifyIconVariant;
    /**
     * Text to display
     */
    text?: string | number;
}

export type GivelifyButtonProps = React.ButtonHTMLAttributes<
    Omit<HTMLButtonElement, 'color'>
> &
    GivelifyButtonBaseProps &
    (
        | GivelifyButtonWithTextProps
        | GivelifyButtonWithIconProps
        | GivelifyButtonWithIconCustomRenderProps
        | GivelifyButtonLinkProps
        | GivelifyButtonIconTextProps
        | GivelifyButtonIconTextCustomRenderProps
        | GivelifyButtonIconProps
        | GivelifyButtonIconCustomRenderProps
    );

export const GivelifyButton: React.FCC<GivelifyButtonProps> = (props) => {
    const {
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        classes,
        size = 'small',
        variant = 'secondary',
        disabled,
        width = 'auto',
        minWidth,
        minHeight,
        icon,
        iconVariant,
        text,
        bold,
        height,
        fontSize,
        lineHeight,
        className,
        normalStyle,
        hoverStyle,
        disabledStyle,
        activeStyle,
        focusStyle,
        startIcon,
        endIcon,
        startIconVariant,
        endIconVariant,
        isLoading,
        loadingIndicator = 'ring',
        wrap,
        disableRipple,
        fullWidth,
        loadingIndicatorClassName,
        type = 'button',
        color,
        padding,
        track = true,
        ...buttonProps
    } = props;
    const {
        boxMargin,
        btnTextBold,
        btnTextCustom,
        btnDimensions,
        btnActiveState,
        btnDisabledState,
        btnHoverState,
        btnNormalState,
        btnFocusState,
    } = GivelifyButtonVariableStyles({
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        fontSize,
        lineHeight,
        width,
        minWidth,
        minHeight,
        height,
        normalStyle,
        hoverStyle,
        activeStyle,
        disabledStyle,
        focusStyle,
        wrap,
        size,
        color,
        padding,
    });
    const {
        btnRoot,
        btnText,
        btnTextSmall,
        btnTextLarge,
        btnSmall,
        btnLarge,
        btnXLarge,
        btnShrink,
        btnDense,
        btnAuto,
        btnPrimary,
        btnSecondary,
        btnDanger,
        btnDangerSecondary,
        btnGhost,
        btnGhostAlt,
        btnLink,
        btnLinkAlt,
        btnIconText,
        btnIcon,
        btnIconOnly,
        btnIconOnlyRoot,
        btnIconLarge,
        btnIconSmall,
        btnStartIcon,
        btnEndIcon,
        btnPrimaryIconRoot,
        btnPrimaryIconOnly,
        btnDangerLoading,
        btnPrimaryLoading,
        btnSecondaryLoading,
        btnPrimaryAlt,
        btnPrimaryAltLoading,
        btnSecondaryAlt,
        btnSecondaryAltLoading,
    } = GivelifyButtonBaseStyles();
    const rootClassName = mergeClassNames(
        boxMargin,
        btnRoot,
        variant !== 'ghost' &&
            variant !== 'ghost-alt' &&
            variant !== 'link' &&
            variant !== 'link-alt' &&
            variant !== 'icon-text' &&
            btnDimensions,
        btnText,
        size === 'small' &&
            variant !== 'ghost' &&
            variant !== 'ghost-alt' &&
            variant !== 'link' &&
            variant !== 'link-alt' &&
            variant !== 'icon-text' &&
            btnSmall,
        size === 'large' &&
            variant !== 'ghost' &&
            variant !== 'ghost-alt' &&
            variant !== 'link' &&
            variant !== 'link-alt' &&
            variant !== 'icon-text' &&
            btnLarge,
        size === 'xLarge' &&
            variant !== 'ghost' &&
            variant !== 'ghost-alt' &&
            variant !== 'link' &&
            variant !== 'link-alt' &&
            variant !== 'icon-text' &&
            btnXLarge,
        size === 'xLarge' || size === 'large' ? btnTextLarge : btnTextSmall,
        size === 'shrink' && btnShrink,
        size === 'dense' && btnDense,
        size === 'auto' && btnAuto,
        variant === 'primary' && btnPrimary,
        variant === 'primary' && isLoading && btnPrimaryLoading,
        variant === 'primary-alt' && btnPrimaryAlt,
        variant === 'primary-alt' && isLoading && btnPrimaryAltLoading,
        variant === 'secondary' && btnSecondary,
        variant === 'secondary' && isLoading && btnSecondaryLoading,
        variant === 'secondary-alt' && btnSecondaryAlt,
        variant === 'secondary-alt' && isLoading && btnSecondaryAltLoading,
        variant === 'danger' && btnDanger,
        variant === 'danger-secondary' && btnDangerSecondary,
        variant === 'danger' && isLoading && btnDangerLoading,
        variant === 'ghost' && btnGhost,
        variant === 'ghost-alt' && btnGhostAlt,
        variant === 'link' && btnLink,
        variant === 'link-alt' && btnLinkAlt,
        variant === 'icon-text' && btnIconText,
        variant === 'icon' && btnIconOnlyRoot,
        (variant === 'primary' ||
            variant === 'secondary' ||
            variant === 'danger' ||
            variant === 'danger-secondary' ||
            variant === 'ghost') &&
            (icon || iconVariant) &&
            !text &&
            btnPrimaryIconRoot,
        (fontSize || lineHeight || color) && btnTextCustom,
        bold && btnTextBold,
        normalStyle && btnNormalState,
        hoverStyle && btnHoverState,
        activeStyle && btnActiveState,
        disabledStyle && btnDisabledState,
        focusStyle && btnFocusState,
        classes && classes.root ? classes.root : '',
        className,
    );

    const iconClassName = mergeClassNames(
        variant === 'icon-text' && btnIcon,
        variant === 'icon' && btnIconOnly,
        (variant === 'primary' ||
            variant === 'secondary' ||
            variant === 'danger' ||
            variant === 'danger-secondary' ||
            variant === 'ghost') &&
            btnPrimaryIconOnly,
        (fontSize || lineHeight || color) && btnTextCustom,
        classes && classes.icon ? classes.icon : '',
    );

    const startIconClassName = mergeClassNames(
        btnStartIcon,
        size === 'xLarge' || size === 'large' ? btnIconLarge : btnIconSmall,
        classes && classes.startIcon ? classes.startIcon : '',
    );

    const endIconClassName = mergeClassNames(
        btnEndIcon,
        size === 'xLarge' || size === 'large' ? btnIconLarge : btnIconSmall,
        classes && classes.endIcon ? classes.endIcon : '',
    );

    const renderStartIcon = isLoading ? null : variant === 'icon' ||
      variant === 'icon-text' ? (
        icon ? (
            icon
        ) : iconVariant ? (
            <GivelifyIcon
                variant={iconVariant}
                className={iconClassName}
                ignoreOwnClasses
            />
        ) : null
    ) : startIcon ? (
        startIcon
    ) : startIconVariant ? (
        <GivelifyIcon
            variant={startIconVariant}
            className={startIconClassName}
            ignoreOwnClasses
        />
    ) : null;

    const renderEndIcon = endIcon ? (
        endIcon
    ) : endIconVariant ? (
        <GivelifyIcon
            variant={endIconVariant}
            className={endIconClassName}
            ignoreOwnClasses
        />
    ) : null;

    const renderContent = isLoading ? (
        loadingIndicator === 'ring' ? (
            <LoadingRing
                color={
                    variant === 'primary' ||
                    variant === 'danger' ||
                    variant === 'primary-alt'
                        ? 'primary'
                        : variant === 'secondary-alt'
                        ? 'alt'
                        : 'secondary'
                }
                className={classes?.indicator}
            />
        ) : (
            <LoadingDots
                color={
                    variant === 'primary' ||
                    variant === 'danger' ||
                    variant === 'primary-alt'
                        ? 'primary'
                        : variant === 'secondary-alt'
                        ? 'alt'
                        : 'secondary'
                }
                className={classes?.indicator}
            />
        )
    ) : variant === 'icon' ? null : text ? (
        text
    ) : icon ? (
        icon
    ) : iconVariant ? (
        <GivelifyIcon
            variant={iconVariant}
            className={iconClassName}
            ignoreOwnClasses
        />
    ) : null;

    const { trackEvent } = useTrackingContext();

    const onClickInternal = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (buttonProps.name && track) {
            trackEvent(`<${buttonProps.name}>_${BUTTON_IDENTIFIER}`);
        }
        buttonProps.onClick && buttonProps.onClick(event);
    };

    return (
        <button
            {...buttonProps}
            onClick={onClickInternal}
            type={type}
            className={rootClassName}
            disabled={disabled || isLoading}
            id={props.id}
        >
            {renderStartIcon}
            {renderContent}
            {renderEndIcon}
            {disableRipple ||
            variant === 'link' ||
            variant === 'ghost' ||
            variant === 'ghost-alt' ||
            disabled ||
            isLoading ? null : (
                <GivelifyButtonRipple
                    color={
                        variant === 'danger' || variant === 'primary'
                            ? '#FFF'
                            : '#6197b9'
                    }
                />
            )}
        </button>
    );
};

/**
 * Givelify Button
 */
export const GivelifyCloseButton: React.FCC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & GivelifyButtonBaseProps
> = (props) => {
    return <GivelifyButton {...props} variant="icon" iconVariant="bigClose" />;
};
