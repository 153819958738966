import React, { useCallback, useState } from 'react';
import { STATE_DROPDOWN_OPTIONS } from '@givelify/utils';
import { SelectChangeEvent } from '@mui/material';
import { GivelifyDropdown, GivelifyDropdownState } from '../dropdown';
import { getStateValue, isValidState } from '../utils/addressUtils';

export interface StateFieldProps {
    name?: string;
    state: string | undefined;
    onChange?: (state: string) => unknown;
    className?: string;
    placeholder: string;
    showState?: boolean;
}

export const StateField: React.FC<StateFieldProps> = ({
    name,
    state,
    className,
    placeholder,
    onChange,
    showState = false,
}) => {
    const [newState, setState] = useState<string>(getStateValue(state));
    const [inputState, setInputState] =
        React.useState<GivelifyDropdownState>('idle');
    const handleChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const value = event.target.value as string;
            setState(value);
            onChange && onChange(value);
            setInputState(isValidState(value) ? 'idle' : 'error');
        },
        [setState, onChange, setInputState],
    );
    React.useEffect(() => {
        if (showState) {
            setInputState(isValidState(newState) ? 'idle' : 'error');
        }
        //eslint-disable-next-line
    }, [showState]);
    return (
        <GivelifyDropdown
            aria-label={placeholder}
            className={className}
            id="state"
            label={placeholder}
            name={name}
            onChange={handleChange}
            options={STATE_DROPDOWN_OPTIONS}
            placeholder={placeholder}
            shape='input'
            state={inputState}
            value={newState}
            width='fullwidth'
        />
    );
};
