import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { SortDirection } from '@givelify/givelify-ui';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { PAGE_NAME, TrackingProvider, useInvokeApi } from '@givelify/utils';
import {
    getAxiosClient,
    makeApiRequest,
    ApiResponse,
    useApiRequest,
} from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import { BasicPageWrapper } from 'components';
import permissionTypes from 'constants/permissionTypes';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { removeDoneeFaithLeader } from 'store/donee/actions';
import { changeAccountOwner, makeFaithLeader } from 'store/donee/thunks';
import { setOfficers } from 'store/settings/officers/actions';
import { getOfficerTitles } from 'store/settings/officers/thunks';
import { Officer } from 'store/settings/officers/types';
import { deleteUser, editUser } from 'store/user/thunks';
import { UserInfoRequest, UserPermissions } from 'store/user/types';
import {
    EditUserInfo,
    UserInfo,
    UserInfoErrorMessages,
    UserInfoErrors,
} from 'types/userTypes';
import permissionsByPath from 'utils/permissionsByPath';
import { compareUsers } from 'utils/strings/compare';
import {
    lengthBetween1To55,
    lengthBetween1To25,
    validateEmailAddress,
} from 'utils/strings/validations';
import { handleErrorMessages } from '../../../store/thunks';
import { mapUserInfoToRequest } from '../../../store/user/actions';
import FaithLeaderTable from './components/FaithLeaderTable/FaithLeaderTable';
import Header from './components/Header/Header';
import InviteDoneModal from './components/InviteDoneModal/InviteDoneModal';
import ChangeAccountOwnerModal from './components/UserActionModal/ChangeAccountOwnerModal';
import DeleteUserModal from './components/UserActionModal/DeleteUserModal';
import EditUserModal from './components/UserActionModal/EditUserModal';
import InviteNewUserModal from './components/UserActionModal/InviteNewUserModal';
import MakeFaithLeaderModal from './components/UserActionModal/MakeFaithLeaderModal';
import NotificationModal from './components/UserActionModal/NotificationModal';
import { officerStyle } from './style';
import UsersTable from './UsersTable';

const SettingsOfficersComponent: React.FCC = () => {
    const style = officerStyle();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { goToCaptivePortal, onboardingState } = useCaptivePortalContext();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.users.title'),
            sortByAZ: t('pages.settings.users.sort-a-z'),
            sortByZA: t('pages.settings.users.sort-z-a'),
            sortByNew: t('pages.settings.users.sort-new'),
            addNewUser: t('pages.settings.users.add-new-user'),
            editUser: t('pages.settings.users.edit-user'),
            searchUsers: t('pages.settings.users.search-users'),
            noMatchesFound: t('pages.settings.users.no-matches-found'),
            next: t('labels.next'),
            invite: t('labels.invite'),
            save: t('labels.save'),
            userDeleteNotificationAccountOwnerHeader: t(
                'pages.settings.users.notification-modal.cant-delete-account-owner-header',
            ),
            userDeleteNotificationAccountOwner: t(
                'pages.settings.users.notification-modal.cant-delete-account-owner',
            ),
            cantMakeAccountOwnerHeader: t(
                'pages.settings.users.notification-modal.cant-make-account-owner-header',
            ),
            emptyEmailNotificationAccountOwner: t(
                'pages.settings.users.notification-modal.cant-make-account-owner-no-email',
            ),
            inactiveUserNotificationAccountOwner: t(
                'pages.settings.users.notification-modal.cant-make-account-owner-inactive-user',
            ),
        }),
        [t],
    );
    // const secondActionText = [copy.next, copy.invite];
    const dispatch = useDispatch();
    const {
        doneeId,
        officers,
        userRole,
        userId,
        donee,
        isReligiousOrg,
        faithLeaderId,
    } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        officers: state.Settings.Officers.officers,
        userRole: state.User.user.role,
        userId: state.User.user.id,
        donee: state.Donee.donee,
        isReligiousOrg: state.Donee.donee.type === 'church',
        faithLeaderId: state.Donee.donee.onboarding?.appProfile?.faithLeader
            ? state.Donee.donee.onboarding.appProfile.faithLeader.userId
            : undefined,
    }));
    const [officersRequestState, getOfficers] = useInvokeApi<{
        data: Officer[];
    }>();
    const [addNewUserRequestState, addNewUserRequest] = useApiRequest();

    const addNewUserRequestPromise = (
        data: UserInfoRequest,
    ): Promise<ApiResponse<unknown>> => {
        const httpRequest = getAxiosClient().post(
            `/donees/${doneeId}/users`,
            data,
        );
        return makeApiRequest<unknown>(httpRequest);
    };

    const [users, setUsers] = useState(officers);
    const [selectedUser, setSelectedUser] = useState<EditUserInfo>(null);
    const [isOwnUser, setIsOwnUser] = useState(false);
    const [isFaithLeader, setIsFaithLeader] = useState(false);
    const [isAccountOwner, setIsAccountOwner] = useState(false);
    const [isUserHasEmail, setIsUserHasEmail] = useState(false);
    const [isUserActive, setIsUserActive] = useState(false);
    const [inviteNewUserErrorMessage, setInviteNewUserErrorMessage] =
        useState<string>(null);
    const [selectedUserAvatar, setSelectedUserAvatar] = useState<string>(null);
    const [selectedUserOriginalEmail, setSelectedUserOriginalEmail] =
        useState<string>(null);
    const [actionAnchorPosition, setActionAnchorPosition] = React.useState<{
        left: number;
        top: number;
    } | null>(null);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [inviteNewUserModalOpen, setInviteNewUserModalToggle] =
        useState(false);
    const [fromCaptivePortal, setFromCaptivePortal] = useState<boolean>(false);
    const [inviteDoneModalOpen, setInviteDoneModalToggle] = useState(false);
    const [editUserModalOpen, setEditUserModalToggle] = useState(false);
    const [deleteUserModalOpen, setDeleteUserModalToggle] = useState(false);
    const [
        deleteUserNotificationModalOpen,
        setDeleteUserNotificationModalToggle,
    ] = useState(false);
    const [
        emptyEmailNotificationModalOpen,
        setEmptyEmailNotificationModalToggle,
    ] = useState(false);
    const [
        inactiveUserNotificationModalOpen,
        setInactiveUserNotificationModalToggle,
    ] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [makeFaithLeaderModalOpen, setMakeFaithLeaderModalToggle] =
        useState(false);
    const [isMakingFaithLeader, setIsMakingFaithLeader] = useState(false);
    const [changeAccountOwnerModalOpen, setChangeAccountOwnerModalToggle] =
        useState(false);
    const [isChangingAccountOwner, setIsChangingAccountOwner] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [ctaSecondActionText, setCTASecondActionText] = useState(copy.next);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        firstName: null,
        lastName: null,
        title: null,
        email: null,
        role: 'Financial',
    });

    const [userInfoErrors, setUserInfoErrors] = useState<UserInfoErrors>({
        firstName: false,
        lastName: false,
        title: false,
        email: false,
        role: false,
    });

    const [userInfoErrorMessages, setUserInfoErrorMessages] =
        useState<UserInfoErrorMessages>({
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            role: '',
        });

    const [deleteError, setDeleteError] = useState<string>();

    const [makeFaithLeaderError, setMakeFaithLeaderError] = useState<string>();
    const [changeAccountOwnerError, setChangeAccountOwnerError] =
        useState<string>();

    const [addedUserName, setAddedUserName] = useState('');

    const getOfficerTitlesSet = useCallback(async () => {
        await dispatch(getOfficerTitles());
    }, [dispatch]);

    useEffect(() => {
        getOfficerTitlesSet();
    }, [getOfficerTitlesSet]);

    useEffect(() => {
        getOfficers('GET', `/donees/${doneeId}/users`);
    }, [doneeId, getOfficers]);

    useEffect(() => {
        if (officersRequestState.type === 'REQUEST_SUCCESS') {
            dispatch(
                setOfficers(toCamelCase(officersRequestState.response.data)),
            );
        }
        // eslint-disable-next-line
    }, [officersRequestState, dispatch]);

    useEffect(() => {
        const sorted = officers.sort((a, b) =>
            compareUsers(a, b, sortDirection),
        );
        setUsers([...sorted]);
    }, [sortDirection, officers]);

    const inviteUserClick = useCallback(() => {
        setInviteNewUserModalToggle(true);
    }, []);

    const onInviteNewUserModalClose = useCallback(
        (success?: boolean) => {
            setCurrentStep(1);
            setCTASecondActionText(copy.next);
            setUserInfo({
                firstName: null,
                lastName: null,
                title: null,
                email: null,
                role: 'Basic',
            });
            setUserInfoErrors({
                firstName: false,
                lastName: false,
                title: false,
                email: false,
                role: false,
            });
            setUserInfoErrorMessages({
                firstName: '',
                lastName: '',
                title: '',
                email: '',
                role: '',
            });
            setDisabledSubmit(true);
            setInviteNewUserModalToggle(false);

            if (
                fromCaptivePortal &&
                !['finished', 'inReview'].includes(onboardingState)
            ) {
                // need to excpilitly check for `true` as in some cases we get event as the 1st arg
                goToCaptivePortal(success === true);
                return;
            }

            if (['finished', 'inReview'].includes(onboardingState)) {
                navigate(
                    {
                        search: '',
                    },
                    { replace: true },
                );
                setFromCaptivePortal(false);
            }
        },
        [
            copy.next,
            fromCaptivePortal,
            goToCaptivePortal,
            navigate,
            onboardingState,
        ],
    );

    useEffect(() => {
        const errors = {
            firstName: false,
            lastName: false,
            title: false,
            email: false,
            role: false,
        };
        if (lengthBetween1To55(userInfo.firstName)) {
            errors['firstName'] = true;
        }
        if (lengthBetween1To55(userInfo.lastName)) {
            errors['lastName'] = true;
        }
        if (lengthBetween1To25(userInfo.title)) {
            errors['title'] = true;
        }
        if (validateEmailAddress(userInfo.email)) {
            errors['email'] = true;
        }
        setUserInfoErrors({ ...errors });
        setDisabledSubmit(
            !userInfo.firstName ||
                !userInfo.lastName ||
                !userInfo.title ||
                !userInfo.email ||
                errors.firstName ||
                errors.lastName ||
                errors.title ||
                errors.email,
        );
    }, [userInfo]);

    useEffect(() => {
        const errors = {
            firstName: false,
            lastName: false,
            title: false,
            email: false,
            role: false,
        };
        if (selectedUser && lengthBetween1To55(selectedUser?.firstName)) {
            errors['firstName'] = true;
        }
        if (selectedUser && lengthBetween1To55(selectedUser?.lastName)) {
            errors['lastName'] = true;
        }
        if (selectedUser && lengthBetween1To25(selectedUser?.title)) {
            errors['title'] = true;
        }
        if (selectedUser && validateEmailAddress(selectedUser?.email)) {
            errors['email'] = true;
        }
        setUserInfoErrors({ ...errors });
        setDisabledSubmit(
            errors.firstName || errors.lastName || errors.title || errors.email,
        );
    }, [selectedUser]);

    const onStepClick = useCallback(
        (index) => {
            setCurrentStep(index + 1);
            setCTASecondActionText(index === 0 ? copy.next : copy.invite);
        },
        [copy.invite, copy.next],
    );

    const onUserInfoChange = useCallback(
        ({ name, value }) => {
            setUserInfo({ ...userInfo, [name]: value });
        },
        [userInfo],
    );

    const onSelectedUserInfoChange = useCallback(
        ({ name, value }) => {
            setSelectedUser({ ...selectedUser, [name]: value });
        },
        [selectedUser],
    );

    const onNextClick = useCallback(() => {
        setCurrentStep(2);
        setCTASecondActionText(copy.invite);
    }, [copy.invite]);

    const onInviteDoneModalOpen = useCallback(() => {
        setInviteDoneModalToggle(true);
    }, []);

    const onInviteDoneModalClose = useCallback(() => {
        getOfficers('GET', `/donees/${doneeId}/users`);
        setInviteDoneModalToggle(false);
        // eslint-disable-next-line
    }, [doneeId]);

    const onAddAnotherUser = useCallback(() => {
        onInviteDoneModalClose();
        setInviteNewUserModalToggle(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (addNewUserRequestState.type === 'REQUEST_SUCCESS') {
            setAddedUserName(`${userInfo.firstName} ${userInfo.lastName}`);
            onInviteNewUserModalClose(true);
            onInviteDoneModalOpen();
        } else if (addNewUserRequestState.type === 'REQUEST_ERROR') {
            setCurrentStep(1);
            setCTASecondActionText(copy.next);

            const errors = addNewUserRequestState.error.errors;
            if (errors) {
                const userInfoErrors = {
                    firstName: false,
                    lastName: false,
                    title: false,
                    email: false,
                    role: false,
                };

                const userInfoErrorMessages = {
                    firstName: '',
                    lastName: '',
                    title: '',
                    email: '',
                    role: '',
                };

                for (const key of Object.keys(errors)) {
                    userInfoErrors[key] = true;
                    userInfoErrorMessages[key] = errors[key];
                }

                setUserInfoErrors(userInfoErrors);
                setUserInfoErrorMessages(userInfoErrorMessages);
            }
            setInviteNewUserErrorMessage(addNewUserRequestState.error?.message);
        }
        // Disabling exhaustive-deps warnings because following them freezes the application
        // eslint-disable-next-line
    }, [addNewUserRequestState]);

    const onSubmitClick = useCallback(async () => {
        setInviteNewUserErrorMessage(null);
        const requestData = mapUserInfoToRequest(userInfo, false);
        await addNewUserRequest(addNewUserRequestPromise(requestData));

        // eslint-disable-next-line
    }, [userInfo, dispatch]);

    const handleUserActionClick = useCallback(
        (user) => (event: React.MouseEvent<HTMLButtonElement>) => {
            setSelectedUser(user);
            setIsOwnUser(userId === user.id);
            setIsFaithLeader(
                user.id === donee?.onboarding.appProfile.faithLeader.userId,
            );
            setIsAccountOwner(
                user.id === donee?.onboarding.appProfile.accountOwner.userId,
            );
            setIsUserHasEmail(!!user.email);
            setIsUserActive(!!user.active);
            setSelectedUserOriginalEmail(user.email);
            setSelectedUserAvatar(user.avatar);
            const pos = event.currentTarget.getBoundingClientRect();
            setActionAnchorPosition({
                left: pos.left,
                top: pos.top,
            });
        },
        [donee, userId],
    );

    const handleActionPopoverClose = useCallback(() => {
        setActionAnchorPosition(null);
    }, []);

    const onEditClick = useCallback(() => {
        handleActionPopoverClose();
        setDisabledSubmit(false);
        setEditUserModalToggle(true);
    }, [handleActionPopoverClose]);

    const onEditUserModalClose = useCallback(() => {
        setSelectedUser(null);
        setUserInfoErrors({
            firstName: false,
            lastName: false,
            title: false,
            email: false,
            role: false,
        });
        setUserInfoErrorMessages({
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            role: '',
        });
        getOfficers('GET', `/donees/${doneeId}/users`);
        setEditUserModalToggle(false);
        setDisabledSubmit(true);

        // eslint-disable-next-line
    }, [doneeId]);

    const onEditUserSave = useCallback(async () => {
        try {
            await dispatch(
                editUser(
                    selectedUser.email === selectedUserOriginalEmail,
                    selectedUser,
                ),
            );
            onEditUserModalClose();
        } catch (e) {
            onEditUserModalClose();
        }

        // eslint-disable-next-line
    }, [selectedUser, selectedUserOriginalEmail, dispatch]);

    const onDeleteClick = useCallback(() => {
        handleActionPopoverClose();

        if (isAccountOwner) {
            setDeleteUserNotificationModalToggle(true);
        } else {
            setDeleteUserModalToggle(true);
        }
    }, [isAccountOwner, handleActionPopoverClose]);

    const onDeleteUserModalClose = useCallback(() => {
        setDeleteError(undefined);
        getOfficers('GET', `/donees/${doneeId}/users`);
        setDeleteUserModalToggle(false);
        setSelectedUser(null);
    }, [doneeId, getOfficers]);

    const onDeleteUserNotificationModalClose = useCallback(() => {
        setDeleteUserNotificationModalToggle(false);
        setSelectedUser(null);
    }, []);

    const onDeleteUser = useCallback(async () => {
        try {
            setIsDeleting(true);
            setDeleteError(undefined);
            await dispatch(deleteUser(selectedUser));
            if (isReligiousOrg && selectedUser.id === faithLeaderId) {
                dispatch(removeDoneeFaithLeader(new Date()));
            }
            onDeleteUserModalClose();
        } catch (e) {
            setDeleteError(e.message);
        }
        setIsDeleting(false);
    }, [
        selectedUser,
        isReligiousOrg,
        faithLeaderId,
        onDeleteUserModalClose,
        dispatch,
    ]);

    /* Make Faith Leader */
    const onMakeFaithLeaderClick = useCallback(() => {
        handleActionPopoverClose();
        setMakeFaithLeaderModalToggle(true);
    }, [handleActionPopoverClose]);

    const onMakeFaithLeaderModalClose = useCallback(() => {
        setMakeFaithLeaderError(undefined);
        setMakeFaithLeaderModalToggle(false);
        setSelectedUser(null);
    }, []);

    const onMakeFaithLeader = useCallback(async () => {
        try {
            setIsMakingFaithLeader(true);
            setMakeFaithLeaderError(undefined);

            await dispatch(makeFaithLeader(selectedUser));

            onMakeFaithLeaderModalClose();
        } catch (e) {
            const translatedError = handleErrorMessages(e, t);
            setMakeFaithLeaderError(translatedError.message);
        }
        setIsMakingFaithLeader(false);
    }, [selectedUser, onMakeFaithLeaderModalClose, t, dispatch]);
    /* End Make Faith Leader */

    /* Change Account Owner */
    const onChangeAccountOwnerClick = useCallback(() => {
        handleActionPopoverClose();

        if (!isUserHasEmail) {
            setEmptyEmailNotificationModalToggle(true);
        } else if (!isUserActive) {
            setInactiveUserNotificationModalToggle(true);
        } else {
            setChangeAccountOwnerModalToggle(true);
        }
    }, [isUserHasEmail, isUserActive, handleActionPopoverClose]);

    const onChangeAccountOwnerModalClose = useCallback(() => {
        setChangeAccountOwnerError(undefined);
        getOfficers('GET', `/donees/${doneeId}/users`);
        setChangeAccountOwnerModalToggle(false);
        setSelectedUser(null);
    }, [doneeId, getOfficers]);

    const onEmptyEmailNotificationModalClose = useCallback(() => {
        setEmptyEmailNotificationModalToggle(false);
        setSelectedUser(null);
    }, []);

    const onInactiveUserNotificationModalClose = useCallback(() => {
        setInactiveUserNotificationModalToggle(false);
        setSelectedUser(null);
    }, []);

    const onChangeAccountOwner = useCallback(async () => {
        try {
            setIsChangingAccountOwner(true);
            setChangeAccountOwnerError(undefined);

            await dispatch(changeAccountOwner(selectedUser));

            onChangeAccountOwnerModalClose();
        } catch (e) {
            const translatedError = handleErrorMessages(e, t);
            setChangeAccountOwnerError(translatedError.message);
        }
        setIsChangingAccountOwner(false);
    }, [selectedUser, t, dispatch, onChangeAccountOwnerModalClose]);
    /* End Change Account Owner */

    const focusableElementsSelector =
        'button, select, textarea, input, [tabindex]:not([tabindex="-1"])';

    const handleUserKeyPress = useCallback(
        (e) => {
            const modal = selectedUser
                ? document.getElementById('edit-user-modal')
                : document.getElementById('invite-new-user-modal');
            const focusableElements = modal?.querySelectorAll(
                focusableElementsSelector,
            );
            const focusableRadios = document.getElementsByName(
                'user-permission-radio',
            );
            if (focusableElements) {
                const user = selectedUser ? selectedUser : userInfo;
                const focusableElementsLength = focusableElements.length;
                const firstFocusableElement: HTMLElement =
                    focusableElements[1] as HTMLElement;
                const emailInputElement: HTMLElement =
                    focusableElements[5] as HTMLElement;
                const cancelButtonElement: HTMLElement = focusableElements[
                    focusableElementsLength - 3
                ] as HTMLElement;
                const submitButtonElement: HTMLElement = focusableElements[
                    focusableElementsLength - 2
                ] as HTMLElement;
                const lastFocusableElement: HTMLElement = focusableElements[
                    focusableElementsLength - 1
                ] as HTMLElement;
                const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
                if (!isTabPressed) {
                    return;
                }
                if (e.shiftKey) {
                    if (focusableRadios.length > 0) {
                        if (document.activeElement === cancelButtonElement) {
                            focusableRadios[
                                UserPermissions[user.role] - 1
                            ]?.focus(); // add focus for the current permission card
                            e.preventDefault();
                            return;
                        }
                        if (
                            document.activeElement ===
                            focusableRadios[UserPermissions[user.role] - 1]
                        ) {
                            // if focused has reached to last focusable element then focus first focusable element after pressing tab
                            submitButtonElement?.focus(); // add focus for the first focusable element
                            e.preventDefault();
                            return;
                        }
                    }
                    // if shift key pressed for shift + tab combination
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else {
                    if (focusableRadios.length > 0) {
                        if (selectedUser) {
                            if (document.activeElement === emailInputElement) {
                                focusableRadios[
                                    UserPermissions[user.role] - 1
                                ]?.focus(); // add focus for the current permission card
                                e.preventDefault();
                                return;
                            }
                        } else {
                            if (
                                document.activeElement === submitButtonElement
                            ) {
                                focusableRadios[
                                    UserPermissions[user.role] - 1
                                ]?.focus(); // add focus for the current permission card
                                e.preventDefault();
                                return;
                            }
                        }
                        if (
                            document.activeElement ===
                            focusableRadios[UserPermissions[user.role] - 1]
                        ) {
                            // if focused has reached to last focusable element then focus first focusable element after pressing tab
                            cancelButtonElement?.focus(); // add focus for the first focusable element
                            e.preventDefault();
                            return;
                        }
                    }
                    // if tab key is pressed
                    if (document.activeElement === lastFocusableElement) {
                        // if focused has reached to last focusable element then focus first focusable element after pressing tab
                        firstFocusableElement.focus(); // add focus for the first focusable element
                        e.preventDefault();
                    }
                }
            }
        },
        [userInfo, selectedUser],
    );

    const onTableRefresh = () => {
        getOfficers('GET', `/donees/${doneeId}/users`);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
        // eslint-disable-next-line
    }, [currentStep]);

    useEffect(() => {
        const invite = searchParams.get('invite');

        if (invite === '1') {
            setFromCaptivePortal(true);
            setUserInfo((data) => ({ ...data, role: 'Admin' }));
            setInviteNewUserModalToggle(true);
        }
    }, [searchParams]);

    const secondAction = currentStep === 2 ? onSubmitClick : onNextClick;
    const actionPopoverOpen = Boolean(actionAnchorPosition);

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.USERS][userRole] ===
        permissionTypes.FULL_ACCESS;

    return (
        <BasicPageWrapper
            showDivider
            title={copy.title}
        >
            {hasFullAccess && (
                <div className={style.header}>
                    <Header onInviteNewuserClick={inviteUserClick} />
                </div>
            )}
            {isReligiousOrg ? (
                <FaithLeaderTable
                    key={sortDirection}
                    actionAnchorPosition={actionAnchorPosition}
                    actionPopoverOpen={actionPopoverOpen}
                    handleActionPopoverClose={handleActionPopoverClose}
                    handleUserActionClick={handleUserActionClick}
                    isAccountOwner={isAccountOwner}
                    isFaithLeader={isFaithLeader}
                    isOwnUser={isOwnUser}
                    onChangeAccountOwnerClick={onChangeAccountOwnerClick}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onMakeFaithLeaderClick={onMakeFaithLeaderClick}
                    onSortClick={setSortDirection}
                    onTableRefresh={onTableRefresh}
                    sortDirection={sortDirection}
                    userId={userId}
                    users={users}
                />
            ) : (
                <UsersTable
                    key={sortDirection}
                    actionAnchorPosition={actionAnchorPosition}
                    actionPopoverOpen={actionPopoverOpen}
                    donee={donee}
                    handleActionPopoverClose={handleActionPopoverClose}
                    handleUserActionClick={handleUserActionClick}
                    isAccountOwner={isAccountOwner}
                    isFaithLeader={isFaithLeader}
                    isOwnUser={isOwnUser}
                    onChangeAccountOwnerClick={onChangeAccountOwnerClick}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onMakeFaithLeaderClick={onMakeFaithLeaderClick}
                    onSortClick={setSortDirection}
                    onTableRefresh={onTableRefresh}
                    sortDirection={sortDirection}
                    userId={userId}
                    userRole={userRole}
                    users={users}
                />
            )}
            {inviteNewUserModalOpen && (
                <InviteNewUserModal
                    ctaSecondActionText={ctaSecondActionText}
                    currentStep={currentStep}
                    disabledSubmit={disabledSubmit}
                    errorMessage={inviteNewUserErrorMessage}
                    isAnOfficial={fromCaptivePortal}
                    onInviteNewUserModalClose={onInviteNewUserModalClose}
                    onStepClick={onStepClick}
                    onUserInfoChange={onUserInfoChange}
                    secondAction={secondAction}
                    userInfo={userInfo}
                    userInfoErrorMessages={userInfoErrorMessages}
                    userInfoErrors={userInfoErrors}
                />
            )}
            {inviteDoneModalOpen && (
                <InviteDoneModal
                    onAddAnother={onAddAnotherUser}
                    onClose={onInviteDoneModalClose}
                    userName={addedUserName}
                />
            )}
            {editUserModalOpen && (
                <EditUserModal
                    ctaSecondActionText={copy.save}
                    disabledSave={disabledSubmit}
                    onEditUserModalClose={onEditUserModalClose}
                    onSave={onEditUserSave}
                    onUserInfoChange={onSelectedUserInfoChange}
                    userInfo={selectedUser}
                    userInfoErrors={userInfoErrors}
                />
            )}
            {deleteUserModalOpen && (
                <DeleteUserModal
                    deleteError={deleteError}
                    isDeleting={isDeleting}
                    onDelete={onDeleteUser}
                    onDeleteUserModalClose={onDeleteUserModalClose}
                    userAvatar={selectedUserAvatar}
                    userInfo={selectedUser}
                />
            )}
            {deleteUserNotificationModalOpen && (
                <NotificationModal
                    headerText={copy.userDeleteNotificationAccountOwnerHeader}
                    message={copy.userDeleteNotificationAccountOwner}
                    onNotificationModalClose={
                        onDeleteUserNotificationModalClose
                    }
                />
            )}
            {emptyEmailNotificationModalOpen && (
                <NotificationModal
                    headerText={copy.cantMakeAccountOwnerHeader}
                    message={copy.emptyEmailNotificationAccountOwner}
                    onNotificationModalClose={
                        onEmptyEmailNotificationModalClose
                    }
                />
            )}
            {inactiveUserNotificationModalOpen && (
                <NotificationModal
                    headerText={copy.cantMakeAccountOwnerHeader}
                    message={copy.inactiveUserNotificationAccountOwner}
                    onNotificationModalClose={
                        onInactiveUserNotificationModalClose
                    }
                />
            )}
            {makeFaithLeaderModalOpen && (
                <MakeFaithLeaderModal
                    faithLeaderInfo={donee.onboarding.appProfile.faithLeader}
                    isMakingFaithLeader={isMakingFaithLeader}
                    makeFaithLeaderError={makeFaithLeaderError}
                    onMakeFaithLeader={onMakeFaithLeader}
                    onMakeFaithLeaderModalClose={onMakeFaithLeaderModalClose}
                    userAvatar={selectedUserAvatar}
                    userInfo={selectedUser}
                />
            )}
            {changeAccountOwnerModalOpen && (
                <ChangeAccountOwnerModal
                    accountOwnerInfo={donee.onboarding.appProfile.accountOwner}
                    changeAccountOwnerError={changeAccountOwnerError}
                    isChangingAccountOwner={isChangingAccountOwner}
                    onChangeAccountOwner={onChangeAccountOwner}
                    onChangeAccountOwnerModalClose={
                        onChangeAccountOwnerModalClose
                    }
                    userAvatar={selectedUserAvatar}
                    userInfo={selectedUser}
                />
            )}
        </BasicPageWrapper>
    );
};

export const SettingsOfficers: React.FCC = () => {
    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsUsers}
        >
            <SettingsOfficersComponent></SettingsOfficersComponent>
        </TrackingProvider>
    )
}