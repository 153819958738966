import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const usersTableStyle = makeStyles((theme: Theme) => {
    return createStyles({
        userTable: {
            background: '#FFFFFF',
            minWidth: 500,
            borderRadius: '10px',
        },
        faithLeaderTableContent: {
            marginBottom: 24,
        },
        usernameContainer: {
            width: '100%',
            display: 'flex',
            cursor: 'pointer',
        },
        userAvatarWrapper: {
            width: 32,
            marginRight: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        userAvatarGrey: {
            opacity: 0.5,
        },
        userNameContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: 'auto',
        },
        fullName: {
            lineHeight: '20px',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
        },
        title: {
            lineHeight: '14px',
            color: theme.colors.neutralGrey,
        },
        nameHeader: {
            display: 'flex',
            alignItems: 'center',
        },
        headerIcon: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 11,
        },
        moreAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: 8,
        },
        cursorPointer: {
            cursor: 'pointer',
        },
        popoverPaper: {
            width: 246,
            padding: '8px 0px',
            '& ul': {
                padding: 0,
                '& li': {
                    padding: '11px 16px',
                },
            },
        },
        userFullName: {
            display: 'flex',
            '@media (max-width: 1050px)': {
                flexDirection: 'column',
            },
        },
        accountOwnerBox: {
            display: 'flex',
            alignItems: 'center',
            background: '#DFE1E5',
            borderRadius: '4px',
            padding: '1px 4px',
            marginLeft: 8,
            '@media (max-width: 1050px)': {
                marginLeft: 0,
                margin: '4px 0px',
            },
        },
        accountOwner: {
            color: '#46536B',
            lineHeight: '14px',
            fontWeight: 500,
            '@media (max-width: 800px)': {
                fontSize: 12,
            },
        },
        userDetailGreyText: {
            color: theme.colors.neutralGrey75,
        },
        invitationContent: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        invitationBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px 16px',
            maxWidth: 180,
        },
        greyText: {
            color: theme.colors.neutralGrey75,
            paddingLeft: 8,
        },
        sendButton: {
            fontSize: 14,
            lineHeight: '18px',
            color: theme.colors.primary,
            paddingTop: 2,
            paddingBottom: 0,
        },
    });
});
