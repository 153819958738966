import { TRANSACTION_FILTER_VALUE } from '@givelify/api';
import { TFunction } from 'i18next';

export type TransactionFilterProps = {
    value?: TRANSACTION_FILTER_VALUE;
    onChange: (val: TRANSACTION_FILTER_VALUE) => void;
};

export const toLabelText = (
    value: TRANSACTION_FILTER_VALUE,
    t: TFunction,
): string => {
    switch (value) {
        case TRANSACTION_FILTER_VALUE.POSTED:
            return t('bankDeposits.transaction.postedTransactions');
        case TRANSACTION_FILTER_VALUE.PENDING:
            return t('bankDeposits.transaction.pendingTransactions');
        case TRANSACTION_FILTER_VALUE.ALL:
            return t('bankDeposits.transaction.allTransactions');
        default:
            return '';
    }
};

export const toTransactionValueText = (
    value: TRANSACTION_FILTER_VALUE,
    t: TFunction,
): string => {
    switch (value) {
        case TRANSACTION_FILTER_VALUE.POSTED:
            return t('bankDeposits.transaction.posted');
        case TRANSACTION_FILTER_VALUE.PENDING:
            return t('bankDeposits.transaction.pending');
        case TRANSACTION_FILTER_VALUE.ALL:
            return t('bankDeposits.transaction.all');
        default:
            return '';
    }
};

export const capitalize = (s: string) => {
    if (!s) return '';
    if (s.length === 1) return s.toUpperCase();
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};
