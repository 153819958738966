import React, { useEffect } from 'react';
import { ApiHandler, UpdateDoneeResponse } from '@givelify/api';
import {
    TrackingProvider,
    PAGE_NAME,
    isSucceeded,
    useApiRequest,
} from '@givelify/utils';
import permissionTypes from 'constants/permissionTypes';
import { useSelector, useDispatch } from 'react-redux';
import { PATH } from 'router/routes';
import permissionsByPath from 'utils/permissionsByPath';
import { AppState } from '../../../../store';
import { setDoneeCustomReceipt } from '../../../../store/donee/actions';
import ReceiptTabPage from './ReceiptTabPage';

export const CustomizeReceiptTab: React.FCC = () => {
    const dispatch = useDispatch();
    const [doneeRequestState, makeDoneeRequest] =
        useApiRequest<UpdateDoneeResponse>();
    const { doneeId, donee, userRole, useCustomReceipt } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            donee: state.Donee.donee,
            userRole: state.User.user.role,
            useCustomReceipt: state.Donee.donee.customReceipt === 1,
        }),
    );
    const [value, setValue] = React.useState(useCustomReceipt);
    const handleChange = (isOn: boolean) => {
        setValue(isOn);
    };

    useEffect(() => {
        setValue(useCustomReceipt);
    }, [doneeId, useCustomReceipt]);

    const onCancelClick = () => {
        setValue(useCustomReceipt);
    };
    const onSaveClick = async () => {
        if (value !== useCustomReceipt) {
            makeDoneeRequest(
                ApiHandler.instance.donees.updateReceiptState(donee.id, value),
            );
        }
    };
    useEffect(() => {
        if (isSucceeded(doneeRequestState)) {
            dispatch(
                setDoneeCustomReceipt(
                    doneeId,
                    doneeRequestState.response.customReceipt ? 1 : 0,
                    new Date(doneeRequestState.response.updatedAt),
                ),
            );
        }
    }, [doneeRequestState, dispatch, doneeId]);
    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.APP_PROFILE][userRole] ===
        permissionTypes.FULL_ACCESS;

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsAppProfileCustomizeReceipt}
        >
            <ReceiptTabPage
                dirty={value !== useCustomReceipt}
                disableActions={!hasFullAccess}
                doneeName={donee.name}
                error={
                    doneeRequestState.type === 'REQUEST_ERROR'
                        ? doneeRequestState.error.message
                        : undefined
                }
                isOn={value}
                onCancel={onCancelClick}
                onChange={handleChange}
                onSave={onSaveClick}
                saving={doneeRequestState.type === 'REQUEST_START'}
            />
        </TrackingProvider>
    );
};
