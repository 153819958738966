import React, { useMemo } from 'react';
import {
    AddressWithPhone,
    GivelifyLabel,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { ImageWithDimensions } from '../@types/assets/onboarding';
import { SaveCancelButtons } from '../components/SaveCancelButton';
import { I18N_NAMESPACE } from '../consts';
import { ImageButton, ImageButtonRef } from '../imageCropper/ImageButton';
import { CustomizeProfileOrganizationLogo } from '../imageCropper/ImageUploadButtons';
import { profileFormGridStyles } from './CustomizeProfileStepOne/BannerImageSection';
import { OrganizationLogoPreview } from './OrganizationLogoPreview';

interface OrganizationLogoSectionProps {
    organizationName: string;
    organizationAddress: AddressWithPhone;
    organizationLogo: ImageWithDimensions | undefined;
    setOrganizationLogo: (image?: ImageWithDimensions) => unknown;
    className?: string;
    organizationType: string;
    submitProps?: {
        onSave: () => void;
        isSaving?: boolean;
        disable?: boolean;
    };
}

export const OrganizationLogoSection: React.FCC<
    OrganizationLogoSectionProps
> = ({
    organizationName,
    organizationAddress,
    organizationLogo,
    setOrganizationLogo,
    submitProps,
    className,
    organizationType,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { formGridStyles } = profileFormGridStyles({
        top: true,
        bottom: false,
    });

    const { headingText, descriptionText } = useMemo(
        () => ({
            headingText: t('customizeProfile.organizationLogoSection.heading'),
            descriptionText: t(
                'customizeProfile.organizationLogoSection.description',
            ),
        }),
        [t],
    );
    const imageButtonRef = React.useRef<ImageButtonRef>(null);
    const onCancel = React.useCallback(() => {
        if (imageButtonRef && imageButtonRef.current) {
            imageButtonRef.current?.clear();
        }
    }, [imageButtonRef]);
    const isRemoteFile = React.useMemo(() => {
        if (organizationLogo && organizationLogo.url) {
            if (organizationLogo.url.includes('data:')) {
                return false;
            }
        }
        return true;
    }, [organizationLogo]);
    React.useEffect(() => {
        if (isRemoteFile) {
            if (imageButtonRef && imageButtonRef.current) {
                imageButtonRef.current?.reset();
            }
        }
    }, [isRemoteFile, imageButtonRef]);
    return (
        <div className={mergeClassNames(formGridStyles, className)}>
            <GivelifyLabel
                marginBottom={8}
                marginTop={32}
                text={headingText}
                variant="heading4"
            />
            <GivelifyLabel
                marginBottom={8}
                text={descriptionText}
                variant="body1"
            />
            <ImageButton
                ref={imageButtonRef}
                Component={CustomizeProfileOrganizationLogo}
                aspectRatio={1}
                id="organization-logo"
                initialImage={organizationLogo}
                isRound={true}
                onChange={setOrganizationLogo}
                title={headingText}
            />
            <OrganizationLogoPreview
                organizationAddress={organizationAddress}
                organizationLogo={organizationLogo}
                organizationName={organizationName}
                organizationType={organizationType}
            />
            {submitProps && organizationLogo && !isRemoteFile ? (
                <SaveCancelButtons
                    onCancel={onCancel}
                    onSave={submitProps.onSave}
                    saving={submitProps.isSaving}
                    submitDisabled={submitProps.disable}
                />
            ) : null}
        </div>
    );
};
