import React, { useEffect, useState } from 'react';
import CollapsibleItemStack from '../../components/CollapsibleItemStack';
import { SwipeCardsProps } from '../v1/SwipeCards';
import { VideoCards } from '../videoCards';
import CardComplete from './CardComplete';
import CardProfile from './CardProfile';

const StackCards: React.FCC<SwipeCardsProps> = (props) => {
    const {
        card1Dismissed = false,
        card2Dismissed = false,
        onCompleteCardActionClick,
        onCustomizeCardActionClick,
        introductionVideoCardsEnabled,
        hideIntroductionVideoCards = false,
        onVideoCardsCloseClick,
        onCard1CloseClick,
        onCard2CloseClick,
        progress,
        requiredPartIsComplete = false,
        card1ShowRetry = false,
        card1OnReviewClick,
        onboardingCompleted,
        customizeProfileStarted,
        organizationType = 'church',
        hasMid,
    } = props;

    const progressInternal = onboardingCompleted ? 100 : progress;
    const card2Visible = (progressInternal || 0) > 0 && !card2Dismissed;

    const [isVideoCardHidden, setIsVideoCardHidden] = useState(
        hideIntroductionVideoCards,
    );
    const [card1HiddenInternal, setCard1HiddenInternal] =
        useState(card1Dismissed);
    const [card2HiddenInternal, setCard2HiddenInternal] = useState(
        !card2Visible,
    );

    useEffect(() => {
        setCard1HiddenInternal(card1Dismissed);
        setCard2HiddenInternal(!card2Visible);
    }, [card1Dismissed, card2Visible]);

    return (
        <CollapsibleItemStack
            gap={16}
            items={[
                ...(introductionVideoCardsEnabled
                    ? [
                          {
                              item: (
                                  <VideoCards
                                      onDismissClick={() =>
                                          setIsVideoCardHidden(true)
                                      }
                                      organizationType={organizationType}
                                  />
                              ),
                              hidden: isVideoCardHidden,
                              testId: 'videoCards',
                          },
                      ]
                    : []),
                {
                    item: (
                        <CardComplete
                            hasMid={hasMid}
                            onCloseClick={() => setCard1HiddenInternal(true)}
                            onCompleteClick={onCompleteCardActionClick}
                            onReviewClick={card1OnReviewClick}
                            progress={progressInternal}
                            showRetry={card1ShowRetry}
                        />
                    ),
                    hidden: card1HiddenInternal,
                    testId: 'cardComplete',
                },
                {
                    item: (
                        <CardProfile
                            customizeProfileStarted={customizeProfileStarted}
                            onCloseClick={() => setCard2HiddenInternal(true)}
                            onCustomizeClick={onCustomizeCardActionClick}
                            organizationType={organizationType}
                            requiredPartIsComplete={requiredPartIsComplete}
                        />
                    ),
                    hidden: card2HiddenInternal,
                    testId: 'cardProfile',
                },
            ]}
            onAnimationFinished={(index) => {
                if (introductionVideoCardsEnabled) {
                    if (index === 0) {
                        onVideoCardsCloseClick?.();
                    }
                }
                if (index === (introductionVideoCardsEnabled ? 1 : 0)) {
                    onCard1CloseClick?.();
                }
                if (index === (introductionVideoCardsEnabled ? 2 : 1)) {
                    onCard2CloseClick?.();
                }
            }}
        />
    );
};

export default StackCards;
