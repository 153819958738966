import React from 'react';
import { styled } from '@mui/material';
import { CloseIcon } from '../assets';
import { GivelifyButton, GivelifyButtonProps } from '../button';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';

export type GivelifyPillProps = {
    text: string;
    value: number | string | JSX.Element;
    onClick?: () => void;
    dataTestId?: string;
    wrap?: boolean;
    fullwidth?: boolean;
    className?: string;
};

const StyledPill = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'hasRemoveButton' && prop !== 'fullwidth',
})<{ hasRemoveButton?: boolean; fullwidth?: boolean }>(
    ({ hasRemoveButton, fullwidth }) => ({
        width: fullwidth ? '100%' : 'initial',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '4px',
        border: `1px solid ${DesignTokens.border.borderComponentDefault.color}`,
        borderRadius: DesignTokens.measurement.borderRadiusS,
        padding: hasRemoveButton ? '4px 6px 4px 12px' : '4px 12px',
    }),
);

const CloseButton = styled(GivelifyButton)<GivelifyButtonProps>({
    width: '20px',
    height: '20px',
    fontSize: '14px',
    color: DesignTokens.color.iconPrimary,
});

export const GivelifyPill: React.FCC<GivelifyPillProps> = ({
    text,
    value,
    onClick,
    dataTestId,
    wrap,
    fullwidth,
    className,
}) => {
    return (
        <StyledPill
            className={className}
            data-testid={dataTestId}
            fullwidth={fullwidth}
            hasRemoveButton={Boolean(onClick)}
        >
            <GivelifyLabel
                alignContent="center"
                alignItems="center"
                className="pill-label"
                display="flex"
                noWrap={!wrap}
                variant="body2"
            >
                {text}: {value}
            </GivelifyLabel>
            {onClick ? (
                <CloseButton
                    className="pill-close"
                    data-testid="pill-close"
                    name="Remove pill"
                    onClick={onClick}
                    size="small"
                    variant="icon"
                >
                    <CloseIcon />
                </CloseButton>
            ) : null}
        </StyledPill>
    );
};
