import React from 'react';
import { GivelifyNotification } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import { useNotification } from '../notificationContext';
import { MainNotificationWrapper } from './styles';

export const NotificationWrapper: React.FCC = () => {
    const { notification, closeNotification, notificationRef } =
        useNotification();
    if (!notification) return null;
    const position = notification.meta === 'content' ? 'content' : 'main';
    return (
        <MainNotificationWrapper id="nt-wr">
            <div
                ref={notificationRef}
                className={mergeClassNames('nt-root', `nt-root-${position}`)}
                id="nt-root"
            >
                <GivelifyNotification
                    {...notification}
                    onClose={closeNotification}
                    open={!!notification}
                    transitionDuration={0}
                />
            </div>
        </MainNotificationWrapper>
    );
};
