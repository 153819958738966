import React, { useMemo } from 'react';
import {
    GivelifyIcon,
    GivelifyModal,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { GivelifyButton } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useAdvancedTranslation } from '../../../../../utils/i18n';
import HideEnvelopeModalContent from './HideEnvelopeModalContent';
import ShowEnvelopeModalContent from './ShowEnvelopeModalContent';
import useStyles from './styles';

interface ShowHideEnvelopeModalProps {
    active: boolean;
    open: boolean;
    onCloseModal: () => void;
    id: number;
}

const ShowHideEnvelopeModal: React.FCC<ShowHideEnvelopeModalProps> = (
    props,
) => {
    const { id, active, open, onCloseModal } = props;
    const {
        showHideContent,
        modalHeader,
        showHideModal,
        contentModal,
        closeModal,
        modalMobileTitle,
    } = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { at } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            hideEnvelope: at(
                'pages.settings.envelopes2.active-envelopes-tab.hideEnvelope',
            ),
            showEnvelope: at(
                'pages.settings.envelopes2.inactive-envelopes-tab.showEnvelope',
            ),
        }),
        [at],
    );

    return (
        <GivelifyModal
            autoFullscreen
            disableEnforceFocus
            autoFullscreenBreakpoint="sm"
            contentClassName={contentModal}
            contentContainerClassName={showHideModal}
            onClose={onCloseModal}
            open={open}
            width={808}
        >
            {isMobile && (
                <div className={modalHeader}>
                    <div className={closeModal}>
                        <GivelifyButton onClick={onCloseModal} variant="icon">
                            <GivelifyIcon variant="close-circle" />
                        </GivelifyButton>
                    </div>
                    <GivelifyLabel
                        className={modalMobileTitle}
                        text={active ? copy.hideEnvelope : copy.showEnvelope}
                        variant="heading4"
                    />
                </div>
            )}
            <div className={showHideContent}>
                {active ? (
                    <HideEnvelopeModalContent
                        envelopeId={id}
                        onCloseModal={onCloseModal}
                    />
                ) : (
                    <ShowEnvelopeModalContent
                        envelopeId={id}
                        onCloseModal={onCloseModal}
                    />
                )}
            </div>
        </GivelifyModal>
    );
};

export default ShowHideEnvelopeModal;
