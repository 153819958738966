import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName } from '@givelify/utils';
import { useMessageStyles } from '../style';

export interface MessageProps {
    name: string;
    message: string;
    avatarSrc: string | undefined;
}

const decodeURLEncodedInPHP = (msg) => {
    return decodeURIComponent(
        (msg + '')
            .replace(/%(?![\da-f]{2})/gi, function () {
                // PHP tolerates poorly formed escape sequences
                return '%25';
            })
            .replace(/\+/g, '%20'),
    );
};

const decodeHtmlEntity = function (str) {
    return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
};

const Message: React.FCC<MessageProps> = ({ name, message, avatarSrc }) => {
    const { inline, messageStyles } = useMessageStyles();
    return (
        <div className={messageStyles} data-testid="donation-message">
            <GivelifyAvatar
                color="eucalyptus"
                size="xSmall"
                src={avatarSrc}
                text={getInitialsFromName(name)}
            />
            <div className={inline}>
                <GivelifyLabel
                    bold
                    color="navigationTextDefault"
                    marginRight={8}
                    text={name}
                    variant="body1"
                />
                <GivelifyLabel
                    className="word-break"
                    text={decodeHtmlEntity(decodeURLEncodedInPHP(message))}
                    variant="body1"
                />
            </div>
        </div>
    );
};

export default Message;
