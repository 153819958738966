import React from 'react';
import { isNumber } from '@givelify/utils';
import {
    InputAdornment,
    InputBaseComponentProps,
    Stack,
    styled,
    TextField,
} from '@mui/material';
import { IMaskInput, IMask, IMaskInputProps } from 'react-imask';
import { ClearIcon, PasswordHiddenIcon, PasswordVisibleIcon } from '../assets';
import { GivelifyButton } from '../button';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { mergeRefs } from '../utils';
import {
    GivelifyTextFieldHelperTextProps,
    GivelifyTextFieldProps,
} from './types';

type WrapperBoxProps = {
    darkMode?: boolean;
    hasValue?: boolean;
    clearable?: boolean;
    width: 'default' | 'compact' | 'full';
};

const DefaultBox = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'clearable' &&
        prop !== 'width' &&
        prop !== 'darkMode' &&
        prop !== 'hasValue',
})<WrapperBoxProps>(({ darkMode, clearable, width, hasValue }) =>
    darkMode
        ? {
              width:
                  width === 'default'
                      ? `calc(${
                            DesignTokens.measurement.inputFieldTextWidthDefault
                        } +
              ${clearable ? '45px' : '0px'})`
                      : width === 'compact'
                      ? `calc(${
                            DesignTokens.measurement.inputFieldTextWidthCompact
                        } +
              ${clearable ? '45px' : '0px'})`
                      : '100%',
              '& .MuiInputLabel-sizeSmall': {
                  top: '0',
              },
              '& .MuiInputLabel-root': {
                  color: hasValue
                      ? DesignTokens.color.textWhite
                      : DesignTokens.color.textSecondary,
                  '&.Mui-focused': {
                      color: DesignTokens.color.textWhite,
                  },
                  '&.MuiFormLabel-colorError': {
                      color: DesignTokens.color.globalError300,
                  },
              },
              '& .MuiInputBase-root': {
                  background: DesignTokens.color.backgroundComponentToolTip,
                  padding: '0 12px',
                  '& .MuiOutlinedInput-notchedOutline': {
                      border: `2px solid ${DesignTokens.color.globalNeutral700}`,
                      '&&:hover': {
                          border: `2px solid ${DesignTokens.color.globalNeutral700}`,
                      },
                  },

                  '&:not(.Mui-focused)': {
                      '& .MuiOutlinedInput-notchedOutline': hasValue
                          ? {
                                border: `2px solid ${DesignTokens.color.borderComponentFocused}`,
                            }
                          : null,
                  },
                  '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                          border: `2px solid ${DesignTokens.color.globalNeutral400}`,
                      },
                  },

                  '&:hover': {
                      '& .MuiOutlinedInput-notchedOutline': {
                          border: `2px solid ${DesignTokens.color.globalNeutral400}`,
                      },
                  },

                  '&.MuiInputBase-colorError': {
                      '& .MuiOutlinedInput-notchedOutline': {
                          border: `1px solid ${DesignTokens.color.globalError400}`,
                      },
                  },

                  '& .MuiInputAdornment-positionStart': {
                      marginRight: 0,
                      '& .MuiTypography-root': {
                          color: DesignTokens.color.textSecondary,
                      },
                  },

                  '& .clear-btn': {
                      '& .MuiSvgIcon-root': {
                          fill: DesignTokens.color.iconPrimary,
                      },
                  },
              },
              '& .MuiInputBase-input': {
                  color: DesignTokens.color.textWhite,
                  filter: 'none',
                  '&:-webkit-autofill': {
                      WebkitTextFillColor: DesignTokens.color.textWhite,
                      WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                      transition: 'background-color 5000s ease-in-out 0s',
                  },
                  '&:-webkit-autofill:hover': {
                      WebkitTextFillColor: DesignTokens.color.textWhite,
                      WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                      transition: 'background-color 5000s ease-in-out 0s',
                  },
                  '&:-webkit-autofill:focus': {
                      WebkitTextFillColor: DesignTokens.color.textWhite,
                      WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                      transition: 'background-color 5000s ease-in-out 0s',
                  },
                  '&::placeholder': {
                      color: DesignTokens.color.textSecondary,
                  },
                  '&:focus': {
                      '& + .clear-btn': {
                          display: 'none',
                      },
                  },
              },
          }
        : {
              width:
                  width === 'default'
                      ? `calc(${
                            DesignTokens.measurement.inputFieldTextWidthDefault
                        } +
              ${clearable ? '45px' : '0px'})`
                      : width === 'compact'
                      ? `calc(${
                            DesignTokens.measurement.inputFieldTextWidthCompact
                        } +
              ${clearable ? '45px' : '0px'})`
                      : '100%',
              '& .MuiInputLabel-sizeSmall': {
                  top: '0',
              },
              '& .MuiInputBase-root': {
                  '& .MuiInputBase-input': {
                      color: DesignTokens.textStyle.globalComponentInput.color,
                      '&:focus': {
                          '& + .clear-btn': {
                              display: 'none',
                          },
                      },
                  },
              },
          },
);

const StyledTextField = styled(TextField)(({ size, color, disabled }) => ({
    '& input::placeholder': {
        color: DesignTokens.color.textSecondary,
        lineHeight: `${DesignTokens.textStyle.globalComponentInput.font.lineHeight}px`,
        fontWeight: DesignTokens.textStyle.globalComponentInput.font.weight,
        letterSpacing:
            DesignTokens.textStyle.globalComponentInput.letterSpacing,
        fontSize: DesignTokens.textStyle.globalComponentInput.font.size,
    },
    '& .MuiInputAdornment-root': {
        margin: 0,
        padding: 0,
        width:
            size === 'medium'
                ? DesignTokens.measurement.iconButtonBoxSizeMedium
                : DesignTokens.measurement.iconButtonBoxSizeSmall,
    },
    '& .MuiFormHelperText-contained': {
        color:
            color === 'error'
                ? DesignTokens.color.textErrorDefault
                : color === 'warning'
                ? DesignTokens.border.borderComponentWarning.color
                : color === 'success'
                ? DesignTokens.color.textSuccessDefault
                : undefined,
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight:
            size === 'medium'
                ? DesignTokens.measurement.inputFieldPasswordLargeRight
                : DesignTokens.measurement.inputFieldPasswordMediumRight,
    },
    '& label.Mui-focused': {
        color:
            color === 'error'
                ? DesignTokens.border.borderComponentError.color
                : color === 'warning'
                ? DesignTokens.border.borderComponentWarning.color
                : color === 'success'
                ? DesignTokens.border.borderComponentSuccess.color
                : DesignTokens.border.borderComponentFocused.color,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor:
            color === 'error'
                ? DesignTokens.border.borderComponentError.color
                : color === 'warning'
                ? DesignTokens.border.borderComponentWarning.color
                : color === 'success'
                ? DesignTokens.border.borderComponentSuccess.color
                : DesignTokens.border.borderComponentFocused.color,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor:
                color === 'error'
                    ? DesignTokens.border.borderComponentError.color
                    : color === 'warning'
                    ? DesignTokens.border.borderComponentWarning.color
                    : color === 'success'
                    ? DesignTokens.border.borderComponentSuccess.color
                    : DesignTokens.border.borderComponentDefault.color,
            borderWidth: DesignTokens.border.borderComponentDefault.width,
        },
        '&:hover fieldset': {
            borderColor: disabled
                ? DesignTokens.border.borderComponentDisabled
                : color === 'error'
                ? DesignTokens.border.borderComponentError.color
                : color === 'warning'
                ? DesignTokens.border.borderComponentWarning.color
                : color === 'success'
                ? DesignTokens.border.borderComponentSuccess.color
                : DesignTokens.border.borderComponentAccentPrimary.color,
            borderWidth: DesignTokens.border.borderComponentDefault.width,
        },
        '&.Mui-focused fieldset': {
            borderColor:
                color === 'error'
                    ? DesignTokens.border.borderComponentError.color
                    : color === 'warning'
                    ? DesignTokens.border.borderComponentWarning.color
                    : color === 'success'
                    ? DesignTokens.border.borderComponentSuccess.color
                    : DesignTokens.border.borderComponentFocused.color,
            borderWidth: DesignTokens.border.borderComponentFocused.width,
        },
    },
}));

export const GivelifyPasswordField: React.FCC<GivelifyTextFieldProps> = ({
    size = 'medium',
    state = 'idle',
    passwordToggle,
    focused,
    ...props
}) => {
    const [show, setShow] = React.useState(false);
    const toggleVisibility = React.useCallback(() => {
        setShow(!show);
    }, [setShow, show]);
    return (
        <StyledTextField
            {...props}
            InputProps={{
                endAdornment: (
                    <GivelifyButton
                        aria-label="Toggle password visibility"
                        onClick={toggleVisibility}
                        size={size === 'medium' ? 'small' : 'medium'}
                        variant="icon"
                    >
                        {show ? (
                            <PasswordVisibleIcon />
                        ) : (
                            <PasswordHiddenIcon />
                        )}
                    </GivelifyButton>
                ),
            }}
            color={
                state === 'error'
                    ? 'error'
                    : state === 'warning'
                    ? 'warning'
                    : state === 'success'
                    ? 'success'
                    : undefined
            }
            focused={state === 'idle' ? focused : true}
            size={size === 'medium' ? 'small' : 'medium'}
            type={show ? 'text' : 'password'}
        />
    );
};

type MaskedTextFieldProps = {
    value?: string | number;
    onChange: (value: {
        target: { id: string; name: string; value: string | number };
    }) => void;
    id: string;
    name: string;
    maskoptions: IMaskInputProps<HTMLInputElement>;
};

const MaskedTextField = React.forwardRef<HTMLElement, MaskedTextFieldProps>(
    (props, ref) => {
        const { onChange, ...other } = props;
        const [inputValue, setInputValue] = React.useState<string | number>('');
        const handleAccept = (value: string | number) => {
            setInputValue(value);
            onChange({ target: { id: props.id, name: props.name, value } });
        };

        React.useEffect(() => {
            if (ref && 'current' in ref && ref.current) {
                const element = ref.current as HTMLInputElement;
                const mask = IMask(element, props.maskoptions);
                mask.updateValue();
            }
        }, [inputValue, ref, props.maskoptions]);

        return (
            <IMaskInput
                {...other}
                {...props.maskoptions}
                overwrite
                // eslint-disable-next-line
                // @ts-ignore
                inputRef={ref}
                onAccept={handleAccept}
                onChange={() => {
                    /* do nothing */
                }}
            />
        );
    },
);

const GivelifyTextFieldHelperText: React.FC<
    GivelifyTextFieldHelperTextProps
> = ({
    id,
    leftHelperText,
    rightHelperText,
    lengthLabel,
    keepHelperTextSpace,
    disabled,
    darkMode,
    state,
}) => {
    let _leftHelperText = leftHelperText;
    if (keepHelperTextSpace && !_leftHelperText) {
        _leftHelperText = '\xa0'; //&nbsp;
    }

    const _rightHelperText = lengthLabel || rightHelperText;

    const hasHelperText = _leftHelperText || _rightHelperText;
    if (!hasHelperText) return null;

    return (
        <Stack
            flexDirection="row"
            marginTop={1}
            paddingLeft={state === 'error' ? 0 : 2}
            paddingRight={state === 'error' ? 0 : 2}
        >
            {_leftHelperText ? (
                <GivelifyLabel
                    className="left-helper-text"
                    color={
                        disabled
                            ? DesignTokens.color.textDisabled
                            : state === 'error'
                            ? darkMode
                                ? DesignTokens.color.textWhite
                                : DesignTokens.color.textErrorDefault
                            : state === 'warning'
                            ? DesignTokens.color.textWarningDefault
                            : state === 'success'
                            ? DesignTokens.color.textSuccessDefault
                            : DesignTokens.color.textSecondary
                    }
                    id={`${id}-leftHelperText`}
                    lineHeight="16px"
                    text={_leftHelperText}
                    variant="caption1"
                />
            ) : null}
            {_rightHelperText ? (
                <GivelifyLabel
                    color={
                        disabled
                            ? DesignTokens.color.textDisabled
                            : state === 'error'
                            ? darkMode
                                ? DesignTokens.color.textWhite
                                : DesignTokens.color.textErrorDefault
                            : state === 'warning'
                            ? DesignTokens.color.textWarningDefault
                            : state === 'success'
                            ? DesignTokens.color.textSuccessDefault
                            : DesignTokens.color.textSecondary
                    }
                    id={`${id}-rightHelperText`}
                    lineHeight="16px"
                    sx={{ ml: 'auto' }}
                    text={_rightHelperText}
                    variant="caption1"
                />
            ) : null}
        </Stack>
    );
};

export const GivelifyTextField: React.FCC<GivelifyTextFieldProps> = ({
    darkMode,
    clearable,
    onClearClick,
    size = 'medium',
    state = 'idle',
    passwordToggle,
    focused,
    width,
    fullWidth,
    leftHelperText,
    rightHelperText,
    countLength,
    maxLength,
    maskOptions,
    keepHelperTextSpace,
    ...props
}) => {
    const [canClear, setCanClear] = React.useState<boolean>(
        Boolean(props.value) || Boolean(props.defaultValue)
            ? !!clearable
            : false,
    );
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [shrink, setShrink] = React.useState(
        Boolean(props.value) || Boolean(props.defaultValue),
    );

    const lengthLabel = React.useMemo(() => {
        if (!countLength || !maxLength) return '';

        const currentLength = (props?.value as string)?.length || 0;
        return `${currentLength}/${maxLength}`;
    }, [props.value, countLength, maxLength]);

    const onClearClickHandler = React.useCallback(() => {
        setShrink(false);
        if (onClearClick) {
            onClearClick();
        } else {
            if (inputRef.current) {
                inputRef.current.value = '';
                setCanClear(false);
            }
        }
    }, [onClearClick, inputRef]);

    React.useEffect(() => {
        setCanClear(
            Boolean(props.value) || Boolean(props.defaultValue)
                ? !!clearable
                : false,
        );
    }, [props.value, props.defaultValue, clearable]);

    React.useEffect(() => {
        setShrink(Boolean(props.value) || Boolean(props.defaultValue));
    }, [props.value, props.defaultValue]);

    const onChangeHandler = React.useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setCanClear(!!ev.target.value);
            if (props.onChange) {
                props.onChange(ev);
            }
        },
        [props],
    );

    const HelperTextInner = () => (
        <GivelifyTextFieldHelperText
            darkMode={darkMode}
            disabled={props.disabled}
            id={props.id}
            keepHelperTextSpace={keepHelperTextSpace}
            leftHelperText={leftHelperText}
            lengthLabel={lengthLabel}
            rightHelperText={rightHelperText}
            state={state}
        />
    );

    if (props.type === 'password' && passwordToggle) {
        return (
            <DefaultBox width={fullWidth ? 'full' : width ? width : 'default'}>
                <GivelifyPasswordField
                    passwordToggle
                    size={size}
                    state={state}
                    {...props}
                    fullWidth
                />
                <HelperTextInner />
            </DefaultBox>
        );
    }

    return (
        <DefaultBox
            clearable={clearable}
            darkMode={darkMode}
            hasValue={Boolean(inputRef?.current?.value)}
            width={fullWidth ? 'full' : width ? width : 'default'}
        >
            <TextField
                {...props}
                fullWidth
                InputLabelProps={{
                    ...props.InputLabelProps,
                    shrink: props.InputLabelProps?.shrink || shrink,
                }}
                InputProps={{
                    ...props.InputProps,
                    endAdornment:
                        props.InputProps?.endAdornment ||
                        (clearable && canClear) ? (
                            <InputAdornment position="end">
                                <GivelifyButton
                                    disableRipple
                                    className="clear-btn"
                                    data-testid="input-clear-btn"
                                    onClick={onClearClickHandler}
                                    size={
                                        size === 'medium' ? 'small' : 'medium'
                                    }
                                    sx={{
                                        display:
                                            clearable && canClear
                                                ? 'flex'
                                                : 'none',
                                    }}
                                    variant="icon"
                                >
                                    <ClearIcon />
                                </GivelifyButton>
                                {props.InputProps?.endAdornment}
                            </InputAdornment>
                        ) : null,
                    ...(maskOptions && {
                        inputComponent:
                            MaskedTextField as unknown as React.ElementType<InputBaseComponentProps>,
                    }),
                }}
                color={
                    state === 'error'
                        ? 'error'
                        : state === 'warning'
                        ? 'warning'
                        : state === 'success'
                        ? 'success'
                        : undefined
                }
                focused={state === 'idle' ? focused : true}
                inputProps={{
                    ...props.inputProps,
                    maxLength,
                    maskoptions: maskOptions,
                }}
                inputRef={
                    props.inputRef
                        ? mergeRefs([inputRef, props.inputRef])
                        : inputRef
                }
                onBlur={(e) => {
                    !e.target.value && setShrink(false);
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                onChange={onChangeHandler}
                onFocus={(e) => {
                    setShrink(true);
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
                size={size === 'medium' ? 'small' : 'medium'}
                sx={{
                    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                        transform: props.InputProps?.startAdornment
                            ? size === 'medium'
                                ? darkMode
                                    ? 'translate(33px, 13px)'
                                    : 'translate(41px, 13px)'
                                : darkMode
                                ? 'translate(33px, 16px)'
                                : 'translate(41px, 16px)'
                            : size === 'medium'
                            ? 'translate(14px, 13px)'
                            : 'translate(14px, 16px)',
                    },
                    '& .MuiInputBase-input': darkMode
                        ? {
                              paddingLeft: props.InputProps?.startAdornment
                                  ? '8px'
                                  : '0px',
                          }
                        : null,
                }}
                value={
                    // props.value always should be a string when using mask
                    maskOptions &&
                    maskOptions.mask === Number &&
                    isNumber(props.value) &&
                    props.value <= Number.MAX_SAFE_INTEGER
                        ? Number(props.value).toString()
                        : props.value
                }
            />
            <HelperTextInner />
        </DefaultBox>
    );
};
