import React from 'react';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { Grid } from '@mui/material';
import { BasicPageWrapper } from 'components';
import { DashboardTile } from './components';
import { useSettingsDashboard } from './useSettingsDashboard';

const SettingsDashboardView: React.FCC = () => {
    const { dashboardTiles, isMobile, title } = useSettingsDashboard();
    return (
        <BasicPageWrapper showDivider title={title}>
            <Grid container spacing={isMobile ? 2 : 3}>
                {dashboardTiles.map((tile, index) => (
                    <Grid key={index} item md={4} mobile={12} sm={6}>
                        <DashboardTile
                            description={tile.description}
                            href={tile.href}
                            iconUrl={tile.iconUrl}
                            onClick={tile.onClick}
                            title={tile.title}
                        />
                    </Grid>
                ))}
            </Grid>
        </BasicPageWrapper>
    );
};

export const SettingsDashboard: React.FC = () => {
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.Settings}>
            <SettingsDashboardView />
        </TrackingProvider>
    );
};
