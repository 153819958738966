import { Donee, PhysicalAddress, PrimaryRepresentative } from '@givelify/api';
import { ImageWithDimensions } from '@givelify/onboarding';

export interface Representative {
    first_name: string;
    last_name: string;
    official_id: number;
    representative: boolean;
    title: string;
}

export interface FaithLeaderInfo {
    userId: number | null;
    avatar: string | null;
    avatarCroppedCoordinates: string | null;
    avatarOriginal: string | null;
    firstName: string | null;
    isFaithLeader: boolean | null;
    lastName: string | null;
    title: string | null;
    active: boolean | null;
    invitationEmailSent: boolean | null;
    email: string | null;
}

export interface AccountOwnerInfo {
    userId: number | null;
    avatar: string | null;
    avatarCroppedCoordinates: string | null;
    avatarOriginal: string | null;
    firstName: string | null;
    lastName: string | null;
    title: string | null;
}

export interface DoneeState {
    donee?: Donee;
    campuses?: Donee[];
}

export const SET_DONEE_AND_CAMPUSES = 'SET_DONEE_AND_CAMPUSES';
export const SET_DONEE = 'SET_DONEE';
export const SET_DONEE_CUSTOM_RECEIPT = 'SET_DONEE_CUSTOM_RECEIPT';
export const SET_DONEE_MISSION_STATEMENT = 'SET_DONEE_MISSION_STATEMENT';
export const SET_DONEE_ADDRESS = 'SET_DONEE_ADDRESS';
export const SET_DONEE_MAILING_ADDRESS = 'SET_DONEE_MAILING_ADDRESS';
export const SET_DONEE_CONTACT = 'SET_DONEE_CONTACT';
export const SET_DONEE_SOCIAL = 'SET_DONEE_SOCIAL';
export const SET_DONEE_FAITH_LEADER = 'SET_DONEE_FAITH_LEADER';
export const REMOVE_DONEE_FAITH_LEADER = 'REMOVE_DONEE_FAITH_LEADER';
export const SET_DONEE_ACCOUNT_OWNER = 'SET_DONEE_ACCOUNT_OWNER';
export const SET_DONEE_ORGANIZATION_LOGO = 'SET_DONEE_ORGANIZATION_LOGO';
export const SET_DONEE_BANNER_IMAGE = 'SET_DONEE_BANNER_IMAGE';
export const SET_DONEE_PRIMARY_REP = 'SET_DONEE_PRIMARY_REP';

export interface SetDoneeAndCampuses {
    type: typeof SET_DONEE_AND_CAMPUSES;
    donee: Donee;
    campuses: Donee[];
}

export interface SetDonee {
    type: typeof SET_DONEE;
    donee: Donee;
}

export interface SetDoneeCustomReceipt {
    doneeId: number;
    type: typeof SET_DONEE_CUSTOM_RECEIPT;
    customReceipt: number;
    updatedAt: Date;
}

export interface SetDoneeMissionStatement {
    type: typeof SET_DONEE_MISSION_STATEMENT;
    doneeId: number;
    missionStatement: string;
    updatedAt: Date;
}
export interface SetDoneeAddress {
    type: typeof SET_DONEE_ADDRESS;
    doneeId: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    updatedAt: Date;
    timezoneLong: string;
    timezoneShort: string;
    timezone: string;
}

export interface SetDoneeMailingAddress {
    type: typeof SET_DONEE_MAILING_ADDRESS;
    doneeId: number;
    mailingAddress: PhysicalAddress | null;
    updatedAt: Date;
}

export interface SetDoneeContact {
    type: typeof SET_DONEE_CONTACT;
    doneeId: number;
    phone: string;
    email: string;
    established: number;
    congregationSize: string;
    denomination: string;
    updatedAt: Date;
}

export interface SetDoneeSocial {
    type: typeof SET_DONEE_SOCIAL;
    doneeId: number;
    facebook: string;
    instagram: string;
    twitter: string;
    website: string;
    updatedAt: Date;
}

export interface SetDoneeFaithLeader {
    type: typeof SET_DONEE_FAITH_LEADER;
    faithLeaderInfo: FaithLeaderInfo;
    updatedAt: Date;
}

export interface RemoveDoneeFaithLeader {
    type: typeof REMOVE_DONEE_FAITH_LEADER;
    updatedAt: Date;
}

export interface SetDoneeAccountOwner {
    type: typeof SET_DONEE_ACCOUNT_OWNER;
    accountOwnerInfo: AccountOwnerInfo;
    updatedAt: Date;
}

export interface SetDoneeOrganizationLogo {
    type: typeof SET_DONEE_ORGANIZATION_LOGO;
    logo: ImageWithDimensions;
}

export interface SetDoneeBannerImage {
    type: typeof SET_DONEE_BANNER_IMAGE;
    image: ImageWithDimensions;
}

export interface SetDoneePrimaryRep {
    type: typeof SET_DONEE_PRIMARY_REP;
    primaryRepInfo: PrimaryRepresentative;
}

export type DoneeActions =
    | SetDoneeAndCampuses
    | SetDonee
    | SetDoneeCustomReceipt
    | SetDoneeMissionStatement
    | SetDoneeAddress
    | SetDoneeMailingAddress
    | SetDoneeContact
    | SetDoneeSocial
    | SetDoneeFaithLeader
    | RemoveDoneeFaithLeader
    | SetDoneeAccountOwner
    | SetDoneeOrganizationLogo
    | SetDoneeBannerImage
    | SetDoneePrimaryRep;
