import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const officerStyle = makeStyles((theme: Theme) => {
    return createStyles({
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sortButton: {
            width: 160,
            marginRight: 'auto',
            '& .MuiInputBase-root': {
                borderRadius: 16,
                '& .MuiSelect-select': {
                    border: '1px solid #AAAAAA',
                    borderRadius: 16,
                    padding: '8px 16px',
                    minHeight: 'inherit',
                    lineHeight: '16px',
                    boxShadow: 'none',
                },
                '& .MuiSelect-icon': {
                    top: 'inherit',
                },
            },
        },
        searchInput: {
            '& .MuiInput-input': {
                borderRadius: 50,
                height: '40px',
                padding: 4,
                paddingLeft: 40,
                boxSizing: 'border-box',
                width: 336,
                [theme.breakpoints.down('md')]: {
                    minWidth: 0,
                },
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                right: 0,
                top: -85,
                width: 200,
                marginRight: -20,
            },
        },
        addButton: {
            padding: '8px 26.5px',
            marginLeft: 'auto',
            minWidth: 160,
            '& div': {
                fontSize: 15,
                fontWeight: 400,
                letterSpacing: 'initial',
            },
        },
        searchIcon: {
            fontSize: 14,
        },
        searchAdornment: {
            marginLeft: -12,
        },
        noMatchesFound: {
            textAlign: 'center',
            marginTop: 50,
        },
    });
});
