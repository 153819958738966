import React, { useEffect, useState } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { givelithonLaunchModalStyle } from '../style';
import { defaultAvatar } from './types';
dayjs.extend(relativeTime);

export interface DonorProps {
    id: number;
    name: string;
    timeDonate: string;
    picture: string;
    siteUrl?: string;
}

const replacePictureUrl = (siteUrl: string, pictureUrl: string) => {
    let photo;
    if (pictureUrl.length > 0) {
        // example case: 'undefined/data/https://scontent-lga3-2.xx.fbcdn.net/v/t1.30497-1/cp0/c15.0.50.50a/p50x50/84628273_176159830277856_972693363922829312_n.jpg?_nc_cat=1&ccb=1-3&_nc_sid=12b3be&_nc_ohc=K7UnELIpoWwAX86YYjr&_nc_ht=scontent-lga3-2.xx&tp=27&oh=f63278488aafc04ff1fa9928d1ba449c&oe=60C97DB8'
        let idx = pictureUrl.indexOf('https://');

        if (idx < 0) {
            idx = pictureUrl.indexOf('http://');
        }

        if (idx >= 0) {
            photo = pictureUrl.substring(idx);
        } else {
            if (pictureUrl.split('/', 1)[0] === 'undefined') {
                photo = pictureUrl.replace('undefined', siteUrl);
            } else {
                photo = pictureUrl;
            }
        }
    } else {
        photo = defaultAvatar[Math.floor(Math.random() * defaultAvatar.length)];
    }
    return photo;
};

export const DonorItem: React.FCC<DonorProps> = (props) => {
    const classes = givelithonLaunchModalStyle();
    const donateDate = dayjs.tz(props.timeDonate, 'America/New_York').toDate();
    const dateDiff = dayjs(donateDate).fromNow();
    const [photoUrl, setPhotoUrl] = useState<string>('');
    useEffect(() => {
        if (props.siteUrl) {
            setPhotoUrl(replacePictureUrl(props.siteUrl, props.picture));
        }
    }, [props.siteUrl, props.picture]);
    return (
        <div className={classes.donorItem} data-testid="donor-item">
            <div className={classes.donorAvatar}>
                <GivelifyAvatar
                    className={classes.noBackground}
                    color="eucalyptus"
                    data-testid={`donor-avatar-${props.id}`}
                    size="large"
                    src={photoUrl}
                />
            </div>
            <div className={classes.donorInfo}>
                <GivelifyLabel
                    dataTestId={`donor-name-${props.id}`}
                    id={`donor-name-${props.id}`}
                    marginBottom={8}
                    text={props.name}
                    variant="heading3"
                />
                <GivelifyLabel
                    color="neutralGrey"
                    dataTestId={`time-donate-${props.id}`}
                    id={`time-donate-${props.id}`}
                    text={dateDiff}
                    variant="body2"
                />
            </div>
        </div>
    );
};
