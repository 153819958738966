import React, { useMemo, useState } from 'react';
import { GivelifyButton, useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    GivelifyLabel,
    DesignTokens,
    GivelifyToggle,
    CloseIcon,
} from '@givelify/ui';
import {
    MODAL_NAME,
    mergeClassNames,
    useTrackingContext,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Envelope } from 'api/models';
import dayjs from 'dayjs';
import * as ReactSortableHOC from 'react-sortable-hoc';
import matchingPercent from 'utils/matchingPercent';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';
import ShowHideEnvelopeModal from '../ShowHideEnvelopeModal';
import EnvelopeListItemIcon from './EnvelopeListItemIcon';
import EnvelopeProgress from './EnvelopeProgress';
import useStyles from './styles';

const ActiveEnvelopeListItemHandle = ReactSortableHOC.SortableHandle<{
    children: React.ReactNode;
    className: string;
}>((props) => <div {...props} />);

function getPercentage(amount: number | null, goal: number) {
    if (amount) {
        return `${matchingPercent(amount, goal).toLocaleString()}%`;
    }
    return '0%';
}

function getValueRatio(amount: number | null, goal: number) {
    if (amount) {
        return (amount / goal) * 100;
    }
    return 0;
}

const EnvelopeListItem: React.FCC<Envelope> = (props) => {
    const { id, active, isDefault, alwaysOn, name, start, end, goal, amount } =
        props;
    const { trackOpenCloseEvent } = useTrackingContext();
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            edit: t('labels.edit'),
            avaliableByDefault: t(
                'pages.settings.envelopes2.active-envelopes-tab.avaliableByDefault',
            ),
            startsShowing: t(
                'pages.settings.envelopes2.active-envelopes-tab.startsShowing',
            ),
            shownThrough: t(
                'pages.settings.envelopes2.active-envelopes-tab.shownThrough',
            ),
            dndError: t('pages.settings.envelopes2.dndError'),
            showToDonors: t(
                'pages.settings.envelopes2.active-envelopes-tab.showToDonors',
            ),
        }),
        [t],
    );

    const {
        hasFullAccess,
        editEnvelopeClick,
        updatePrioritiesRequestState,
        movedItemId,
        rollbackDragAndDrop,
    } = useEnvelopesContext();

    const onEdit = () => editEnvelopeClick(id);

    const description = useMemo(() => {
        if (isDefault) return copy.avaliableByDefault;
        if (alwaysOn === 'Always') return '';

        if (start && start !== '0000-00-00') {
            const startDate = dayjs(start);
            const now = dayjs();
            if (startDate.isAfter(now)) {
                const formattedDate = startDate.format('MM, DD, YYYY');
                return `${copy.startsShowing} ${formattedDate}`;
            }
        }

        if (end && end !== '0000-00-00') {
            const endDate = dayjs(end);
            const formattedDate = endDate.format('MMM DD, YYYY');
            return `${copy.shownThrough} ${formattedDate}`;
        }
    }, [copy, isDefault, alwaysOn, start, end]);

    const progress = useMemo(() => {
        if (!(goal > 0)) return undefined;

        return {
            value: amount ? getValueRatio(amount, goal) : 0,
            valueText: `$${amount ? amount.toLocaleString() : 0}`,
            percentigeText: getPercentage(amount, goal),
            goal: goal,
        };
    }, [goal, amount]);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const onOpenModal = () => {
        setOpenModal(true);
        trackOpenCloseEvent(
            true,
            active ? MODAL_NAME.ConfirmHideBox : MODAL_NAME.ConfirmShowBox,
        );
    };

    const onCloseModal = () => {
        setOpenModal(false);
        trackOpenCloseEvent(
            false,
            active ? MODAL_NAME.ConfirmHideBox : MODAL_NAME.ConfirmShowBox,
        );
    };

    const itemIsMoved = movedItemId === props.id;
    const isMoving = updatePrioritiesRequestState === 'REQUEST_START';
    const isError = updatePrioritiesRequestState === 'REQUEST_ERROR';
    const currentItemIsError = itemIsMoved && isError;
    const otherItemIsMoving = !itemIsMoved && isMoving;
    const otherItemIsError = !itemIsMoved && isError;

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const commonContent = (
        <>
            <div className={classes.titleWrapper}>
                <GivelifyLabel
                    wrap
                    className={classes.wrap}
                    color={
                        (!active || otherItemIsMoving || otherItemIsError) &&
                        DesignTokens.color.textDisabled
                    }
                    id={`envelope-name-${props.id}`}
                    text={name}
                    variant="heading3S"
                />
                <GivelifyLabel
                    wrap
                    color={
                        !active || otherItemIsMoving || otherItemIsError
                            ? DesignTokens.color.textDisabled
                            : DesignTokens.color.textSecondary
                    }
                    id={`envelope-desc-${props.id}`}
                    text={description}
                    variant="body2"
                />
            </div>
            {progress && (
                <div className={classes.progressWrapper}>
                    <EnvelopeProgress
                        disabled={!active || isMoving || isError}
                        goal={progress.goal}
                        id={props.id}
                        percentigeText={progress.percentigeText}
                        value={progress.value}
                        valueText={progress.valueText}
                    />
                </div>
            )}
        </>
    );

    return (
        <>
            <div
                aria-label={active ? 'Active envelope' : 'Inactive envelope'}
                className={mergeClassNames(
                    classes.wrapper,
                    active && classes.wrapperActive,
                    currentItemIsError && classes.wrapperError,
                )}
                test-id={name}
            >
                {!isMobile && (
                    <ActiveEnvelopeListItemHandle className={classes.dragZone}>
                        <div
                            className={classes.handle}
                            id={`reorderHandler-${props.id}`}
                        >
                            <EnvelopeListItemIcon
                                active={active}
                                id={props.id}
                            />
                        </div>
                        {commonContent}
                    </ActiveEnvelopeListItemHandle>
                )}
                {isMobile && commonContent}
                {hasFullAccess && (
                    <div className={classes.controls}>
                        <div className={classes.switch}>
                            <GivelifyToggle
                                checked={active}
                                classes={{
                                    root: props.active
                                        ? `hide-envelope-root-${props.id}`
                                        : `show-envelope-root-${props.id}`,
                                }}
                                disabled={isMoving || isError}
                                id={
                                    props.active
                                        ? `hide-envelope-${props.id}`
                                        : `show-envelope-${props.id}`
                                }
                                label={copy.showToDonors}
                                onChange={onOpenModal}
                            />
                        </div>
                        <GivelifyButton
                            disabled={isMoving || isError}
                            iconVariant="edit-outline"
                            id={`edit-envelope-${props.id}`}
                            onClick={onEdit}
                            text={copy.edit}
                            variant="icon-text"
                        />
                    </div>
                )}
            </div>
            {currentItemIsError && (
                <div className={classes.errorMessage}>
                    <GivelifyLabel
                        color={DesignTokens.color.textErrorDefault}
                        text={copy.dndError}
                        variant="body1"
                    />
                    <CloseIcon
                        className={classes.closeErrorIcon}
                        onClick={rollbackDragAndDrop}
                    />
                </div>
            )}
            <ShowHideEnvelopeModal
                active={active}
                id={props.id}
                onCloseModal={onCloseModal}
                open={openModal}
            />
        </>
    );
};

export default EnvelopeListItem;
