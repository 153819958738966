import React, { useMemo } from 'react';
import { DesignTokens, DropdownOption, GivelifyLabel } from '@givelify/ui';
import { formatWithTimezone } from '@givelify/utils';
import {
    Stack,
    styled,
    Skeleton,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { Donor } from 'api/models';
import {
    DataTable,
    DataTableColumn,
    DataTablePaginationProps,
    DataTableSortDirection,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import {
    formatCountWithCommas,
    formatMoneyWithCommasAndDolarSign,
} from 'utils/formatMoneyWithCommas';
import { encryptString } from 'utils/hashUtils';
import { DonorNameCell } from './DonorNameCell';

const StyledDataTable = styled(DataTable)(({ theme }) => ({
    '& .th-cell': {
        width: '179.5px',
        maxWidth: '179.5px',
        padding: '0 24px',
    },
    '& .th-cell-name': {
        paddingLeft: theme.spacing(13),
        width: '370px',
        maxWidth: '370px',
    },
    '& .th-cell-lifetimeDonations': {
        paddingRight: theme.spacing(4),
    },
    '& .td-cell': {
        width: '179.5px',
        maxWidth: '179.5px',
        padding: '0 24px',
    },
    '& .td-cell-name': {
        paddingLeft: theme.spacing(4),
        width: '370px',
        maxWidth: '370px',
    },
    '& .td-cell-lifetimeDonations': {
        paddingRight: theme.spacing(4),
    },
    '& .td-cell-skel-lifetimeDonations': {
        width: '100%',
    },
    [theme.breakpoints.down('tablet')]: {
        '& .th-cell': {
            width: '120px',
            maxWidth: '120px',
            padding: '0 12px',
            whiteSpace: 'nowrap',
        },
        '& .th-cell-name': {
            paddingLeft: theme.spacing(10),
            width: '432px',
            maxWidth: '432px',
        },
        '& .th-cell-lifetimeDonations': {
            paddingRight: theme.spacing(3),
        },
        '& .td-cell': {
            width: '120px',
            maxWidth: '120px',
            padding: '0 12px',
        },
        '& .td-cell-name': {
            paddingLeft: theme.spacing(3),
            width: '432px',
            maxWidth: '432px',
        },
        '& .td-cell-lifetimeDonations': {
            paddingRight: theme.spacing(3),
        },
    },
    [theme.breakpoints.down('mobile')]: {
        '& .th-cell': {
            minWidth: '104px',
        },
        '& .th-cell-name': {
            paddingLeft: theme.spacing(2),
            minWidth: '104px',
        },
        '& .th-cell-lifetimeDonations': {
            paddingRight: theme.spacing(2),
        },
        '& .td-cell': {
            minWidth: '104px',
        },
        '& .td-cell-name': {
            paddingLeft: theme.spacing(2),
            minWidth: '104px',
        },
        '& .td-cell-lifetimeDonations': {
            paddingRight: theme.spacing(2),
        },
    },
})) as typeof DataTable;

const TitleLine = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    strong: {
        fontWeight: 900,
    },
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalCaption1.font.size,
    },
}));

const DropdownListItem = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalData1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalData1.font.lineHeight}px`,
    },
}));

const MainLabel = styled('span')({
    fontWeight: 800,
});

export type DonorsTableItem = Pick<
    Donor,
    | 'id'
    | 'name'
    | 'donationStatus'
    | 'lastDonationAmount'
    | 'lastDonationDate'
    | 'lifetimeDonations'
    | 'picture'
>;

export type DonorsTableProps = {
    data: DonorsTableItem[];
    totalCount: number;
    totalUnfilteredDonors: number;
    hasFilters?: boolean;
    sort: string;
    NoResultComponent?: JSX.Element;
    loading?: boolean;
    onSortRequest: (field: string, direction: DataTableSortDirection) => void;
    pagination: DataTablePaginationProps;
};

export const DonorsTable: React.FCC<DonorsTableProps> = ({
    data,
    totalCount,
    totalUnfilteredDonors,
    hasFilters = false,
    loading,
    sort,
    NoResultComponent,
    onSortRequest,
    pagination,
}) => {
    const { enabledFeatures } = useSelector((state: AppState) => ({
        enabledFeatures: state.System.enabledFeatures,
    }));
    const { newDonorDetailsPageEnabled } = useMemo(
        () => ({
            newDonorDetailsPageEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.NEW_DONOR_DETAILS_PAGE,
                false,
            ),
        }),
        [enabledFeatures],
    );
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            showing: t('donors.showing'),
            donors: t('donors.donors'),
            name: t('labels.name'),
            givingStyle: t('donors.givingStyleText'),
            lastGift: t('donors.lastGivenAmount'),
            totalGiven: t('donors.totalGiven'),
            lastDate: t('donors.lastDate'),
            of: t('donors.of'),
            all: t('donors.all'),
            sortBy: t('components.dataTable.sortBy'),
        }),
        [t],
    );
    const navigate = useNavigate();
    const location = useLocation();
    const { PATH } = useGasRouterContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const sortOptions: DropdownOption[] = React.useMemo(
        () => [
            {
                id: 'name-asc',
                label: t('donors.filterLabels.name'),
                secondaryLabel: t('donors.filterLabels.AtoZ'),
            },
            {
                id: 'name-desc',
                label: t('donors.filterLabels.name'),
                secondaryLabel: t('donors.filterLabels.ZtoA'),
            },
            {
                id: 'donationStatus-asc',
                label: t('donors.filterLabels.style'),
                secondaryLabel: t('donors.filterLabels.AtoZ'),
            },
            {
                id: 'donationStatus-desc',
                label: t('donors.filterLabels.style'),
                secondaryLabel: t('donors.filterLabels.ZtoA'),
            },
            {
                id: 'lastDonationAmount-desc',
                label: t('donors.filterLabels.lastAmount'),
                secondaryLabel: t('donors.filterLabels.HighToLow'),
            },
            {
                id: 'lastDonationAmount-asc',
                label: t('donors.filterLabels.lastAmount'),
                secondaryLabel: t('donors.filterLabels.LowToHigh'),
            },
            {
                id: 'lastDonationDate-desc',
                label: t('donors.filterLabels.lastDate'),
                secondaryLabel: t('donors.filterLabels.NewToOld'),
            },
            {
                id: 'lastDonationDate-asc',
                label: t('donors.filterLabels.lastDate'),
                secondaryLabel: t('donors.filterLabels.OldToNew'),
            },
            {
                id: 'lifetimeDonations-desc',
                label: t('donors.filterLabels.totalGiven'),
                secondaryLabel: t('donors.filterLabels.HighToLow'),
            },
            {
                id: 'lifetimeDonations-asc',
                label: t('donors.filterLabels.totalGiven'),
                secondaryLabel: t('donors.filterLabels.LowToHigh'),
            },
        ],
        [t],
    );
    const { sortColumn, sortDirection } = React.useMemo(() => {
        const sortSplit = sort.split('-');
        return {
            sortColumn: sortSplit[0] as keyof DonorsTableItem,
            sortDirection: sortSplit[1] as DataTableSortDirection,
        };
    }, [sort]);
    const onSortClick = React.useCallback(
        (field: keyof DonorsTableItem, direction: DataTableSortDirection) => {
            onSortRequest(field, direction);
        },
        [onSortRequest],
    );
    const onProfileClick = React.useCallback(
        (href: string) => {
            navigate(href, {
                state: { pathname: location.pathname, search: location.search },
            });
        },
        [location.pathname, location.search, navigate],
    );

    const onRenderNameCell = React.useCallback(
        (item: DonorsTableItem, loading?: boolean) => (
            <DonorNameCell
                avatar={item.picture}
                href={
                    newDonorDetailsPageEnabled
                        ? PATH.DONORS.PROFILE_DONATION_HISTORY({
                              donorId: encryptString(item.id?.toString()),
                          })
                        : PATH.DONORS.PROFILE(
                              encryptString(item.id?.toString()),
                          )
                }
                loading={loading}
                name={item.name}
                onClick={onProfileClick}
            />
        ),
        [newDonorDetailsPageEnabled, onProfileClick, PATH.DONORS],
    );
    const formatLastDateCell = React.useCallback(
        (item: DonorsTableItem) =>
            formatWithTimezone(item.lastDonationDate, 'MMM D, YYYY'),
        [],
    );
    const formatLifetimeDonationsCell = React.useCallback(
        (item: DonorsTableItem) =>
            formatMoneyWithCommasAndDolarSign(item.lifetimeDonations),
        [],
    );
    const formatLastDonationAmountCell = React.useCallback(
        (item: DonorsTableItem) =>
            formatMoneyWithCommasAndDolarSign(item.lastDonationAmount),
        [],
    );
    const Title = React.useMemo(() => {
        if (loading) {
            return (
                <Stack alignItems="center" direction="row">
                    <Skeleton animation="wave" height={27} width="120px" />
                </Stack>
            );
        }
        return (
            <TitleLine data-testid="donor-table-title">
                {copy.showing}{' '}
                <strong id="number-of-donors">
                    {isMobile ? (
                        formatCountWithCommas(totalCount)
                    ) : hasFilters ? (
                        <>
                            {formatCountWithCommas(totalCount)} {copy.of}{' '}
                            {formatCountWithCommas(totalUnfilteredDonors)}
                        </>
                    ) : (
                        <>
                            {copy.all}{' '}
                            {formatCountWithCommas(totalUnfilteredDonors)}
                        </>
                    )}{' '}
                    {copy.donors}
                </strong>
            </TitleLine>
        );
    }, [
        loading,
        totalCount,
        hasFilters,
        totalUnfilteredDonors,
        copy,
        isMobile,
    ]);

    const columns = React.useMemo(
        () =>
            [
                {
                    field: 'name',
                    label: copy.name,
                    align: 'left',
                    onRender: onRenderNameCell,
                    noPadding: true,
                },
                {
                    field: 'donationStatus',
                    label: copy.givingStyle,
                    align: 'left',
                },
                {
                    field: 'lastDonationAmount',
                    label: copy.lastGift,
                    align: 'right',
                    format: formatLastDonationAmountCell,
                },
                {
                    field: 'lastDonationDate',
                    label: copy.lastDate,
                    align: 'left',
                    format: formatLastDateCell,
                },
                {
                    field: 'lifetimeDonations',
                    label: copy.totalGiven,
                    align: 'right',
                    format: formatLifetimeDonationsCell,
                },
            ] as DataTableColumn<DonorsTableItem>[],
        [
            onRenderNameCell,
            formatLastDateCell,
            formatLastDonationAmountCell,
            formatLifetimeDonationsCell,
            copy,
        ],
    );
    const columnDirectionArrow = React.useMemo(() => {
        switch (sortColumn) {
            case 'name':
            case 'donationStatus':
                return sortDirection === 'desc' ? 'up-arrow' : 'down-arrow';
            case 'lastDonationAmount':
            case 'lifetimeDonations':
            case 'lastDonationDate':
                return sortDirection === 'desc' ? 'down-arrow' : 'up-arrow';
            default:
                return sortDirection === 'desc' ? 'up-arrow' : 'down-arrow';
        }
    }, [sortColumn, sortDirection]);
    const onMenuItemRender = React.useCallback((option: DropdownOption) => {
        return (
            <DropdownListItem color="inherit" data-testid="dropdown-menu-item">
                <MainLabel>{option.label}</MainLabel> {option.secondaryLabel}
            </DropdownListItem>
        );
    }, []);
    const setRenderValueText = React.useCallback(
        (option: DropdownOption) => {
            return isMobile
                ? copy.sortBy
                : `${option.label} ${option.secondaryLabel}`;
        },
        [copy.sortBy, isMobile],
    );
    return (
        <StyledDataTable<DonorsTableItem>
            NoResultComponent={NoResultComponent}
            columnDirectionArrow={columnDirectionArrow}
            columns={columns}
            loading={loading}
            onMenuItemRender={onMenuItemRender}
            onSortClick={onSortClick}
            pagination={pagination}
            rows={data}
            setRenderValueText={setRenderValueText}
            showNoResults={totalCount === 0 && !loading}
            sortColumn={sortColumn}
            sortColumnDirection={sortDirection}
            sortOptions={sortOptions}
            title={Title}
        />
    );
};
