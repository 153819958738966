import React, { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import step1 from 'assets/integration-icons/step1.svg';
import step2 from 'assets/integration-icons/step2.svg';
import step3 from 'assets/integration-icons/step3.svg';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { IntegrationsStepProps } from './IntegrationsStep';
import IntegrationStepsDesktop from './IntegrationStepsDesktop';
import IntegrationStepsMobile from './IntegrationStepsMobile';

interface IntegrationStepsProps {
    integrationReportType: IntegrationTypes;
}

const IntegrationsBanner: React.FCC<IntegrationStepsProps> = ({
    integrationReportType,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const steps: IntegrationsStepProps[] = useMemo(() => {
        let allSteps = [
            {
                title: t('pages.integrations_banner.step_1.title', 'Congrats'),
                description: t('pages.integrations_banner.step_1.description'),
                children: <img alt="Step1" src={step1} title="Step 1" />,
            },
            {
                title: t('pages.integrations_banner.step_2.title'),
                description: t('pages.integrations_banner.step_2.description', {
                    reportType: integrationReportType.abbreviation,
                    fundCodeLabel: integrationReportType.fundCodeLabel,
                    fundLabel: integrationReportType.fundLabel,
                }),
                children: <img alt="Step2" src={step2} title="Step 2" />,
            },
            {
                title: t('pages.integrations_banner.step_3.title'),
                description: t('pages.integrations_banner.step_3.description', {
                    reportType: integrationReportType.abbreviation,
                    memberIdLabel: integrationReportType.memberIdLabel,
                    envelopeCode: integrationReportType.fundLabel,
                }),
                children: <img alt="Step3" src={step3} title="Step 3" />,
            },
        ];
        if (!integrationReportType.memberIdLabel) {
            allSteps.pop();
        }
        if (integrationReportType.integrationType === 'ACSREALM') {
            allSteps = allSteps.filter((step) => {
                return (
                    step.title !== t('pages.integrations_banner.step_3.title')
                );
            });
        }
        return allSteps;
    }, [integrationReportType, t]);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const ReturnComponent = isMobile
        ? IntegrationStepsMobile
        : IntegrationStepsDesktop;

    return <ReturnComponent steps={steps} />;
};

export default IntegrationsBanner;
