import React from 'react';
import { Collapse } from '@mui/material';

const CollapsibleItemStack: React.FCC<{
    items: { testId?: string; hidden: boolean; item: React.ReactNode }[];
    gap: number;
    onAnimationFinished: (index: number) => void;
}> = ({ items, gap, onAnimationFinished }) => {
    const visibleItems = items.filter((item) => !item.hidden);
    const lastVisibleItemIndex = items.indexOf(
        visibleItems[visibleItems.length - 1],
    );

    const shouldAddMarginBottom = (index: number) =>
        index < lastVisibleItemIndex && visibleItems.length > 0;

    const shouldAddMarginTop = (index: number, hidden: boolean) =>
        hidden && index > lastVisibleItemIndex && visibleItems.length > 0;

    return (
        <>
            {items.map((el, index) => (
                <Collapse
                    key={index}
                    mountOnEnter
                    unmountOnExit
                    data-testid={el.testId}
                    in={!el.hidden}
                    onExited={() => onAnimationFinished(index)}
                >
                    <div
                        key={index}
                        style={{
                            marginBottom: shouldAddMarginBottom(index)
                                ? gap
                                : undefined,
                            marginTop: shouldAddMarginTop(index, el.hidden)
                                ? gap
                                : undefined,
                        }}
                    >
                        {el.item}
                    </div>
                </Collapse>
            ))}
        </>
    );
};

export default CollapsibleItemStack;
