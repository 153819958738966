import React from 'react';
import {
    RequestState,
    responseOrUndefined,
    TimeFrameFilterIds,
    TimeFrameValue,
    useTrackingContext,
} from '@givelify/utils';
import { DonationsStatisticType } from 'api/client/endpoints';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import {
    DonationStatisticByEnvelopes,
    EnvelopeDistributionData,
} from 'store/dashboard/envelopes/types';
import { OVERVIEW_PAGE_GIVING_TREND_BAR } from 'utils/clevertapEvents';
import { useAdvancedTranslation } from 'utils/i18n';
import { getBarChartRangeDate } from 'utils/strings/getRangeBasedDateFormat';
import { OverviewPaper } from '../components';
import { BarChartView } from './BarChartView';
import { Item, Wrapper } from './styles';

const OTHER = 'Other';

export type ResultsData = {
    name: string;
    startDateTime: dayjs.Dayjs;
    endDateTime: dayjs.Dayjs;
} & {
    [key: string]: number;
};

export type BarChartsProps = {
    doneeId: number;
    onboardingEnabled: boolean;
    onboardingCompleted: boolean;
    donationStatisticByEnvelopesRequest: RequestState<DonationStatisticByEnvelopes>;
    envelopesDataRequest: RequestState<EnvelopeDistributionData>;
    rangeDateType: DonationsStatisticType;
    timeFrame: TimeFrameValue;
};
const BarChartComponent: React.FCC<BarChartsProps> = ({
    doneeId,
    onboardingCompleted,
    onboardingEnabled,
    donationStatisticByEnvelopesRequest,
    rangeDateType,
    envelopesDataRequest,
    timeFrame,
}) => {
    const navigate = useNavigate();
    const { t } = useAdvancedTranslation();
    const { trackEvent } = useTrackingContext();
    const { PATH } = useGasRouterContext();

    const copy = {
        givingTrendTitle: t('dashboard.givingTrend.title'),
    };

    const isEmpty = React.useMemo(() => {
        return (
            responseOrUndefined(donationStatisticByEnvelopesRequest)?.data
                .length === 0 ||
            responseOrUndefined(envelopesDataRequest)?.data.length === 0
        );
    }, [donationStatisticByEnvelopesRequest, envelopesDataRequest]);

    const formatData = React.useMemo(() => {
        const data = responseOrUndefined(
            donationStatisticByEnvelopesRequest,
        )?.data;
        const envelopesData = responseOrUndefined(envelopesDataRequest)?.data;
        if (
            !data ||
            !envelopesData ||
            envelopesData.length === 0 ||
            !timeFrame
        ) {
            return {
                data: [],
                keys: [],
            };
        }
        const keys: {
            [id: number]: {
                id: number | number[];
                title: string;
                sum: number;
            };
        } = {};

        envelopesData.forEach((item) => {
            if (item.id) {
                keys[item.id] = {
                    id: null,
                    title: '',
                    sum: 0,
                };
            } else {
                keys[OTHER] = {
                    id: item.aggregatedIds,
                    title: OTHER,
                    sum: 0,
                };
            }
        });

        const result: ResultsData[] = [];
        data.forEach((item) => {
            const tempResult = {
                name: getBarChartRangeDate(
                    dayjs(item.startDateTime),
                    dayjs(item.endDateTime),
                    timeFrame,
                ),
                startDateTime: dayjs(item.startDateTime),
                endDateTime: dayjs(item.endDateTime),
            };
            item.envelopes.forEach((envelope) => {
                if (keys[envelope.envelopeId]) {
                    tempResult[envelope.envelopeName] = envelope.sum;
                    keys[envelope.envelopeId] = {
                        id: envelope.envelopeId,
                        title: envelope.envelopeName,
                        sum: keys[envelope.envelopeId].sum + envelope.sum,
                    };
                } else {
                    if (keys[OTHER]) {
                        keys[OTHER] = {
                            id: keys[OTHER].id,
                            title: OTHER,
                            sum: keys[OTHER].sum + envelope.sum,
                        };
                    }
                }
            });
            if (keys[OTHER]) {
                tempResult[OTHER] = keys[OTHER].sum;
                keys[OTHER].sum = 0;
            }
            result.push(tempResult as ResultsData);
        });

        return {
            data: result,
            keys: Object.values(keys).sort((a, b) => b.sum - a.sum),
        };
    }, [donationStatisticByEnvelopesRequest, envelopesDataRequest, timeFrame]);

    const isLoading = React.useMemo(
        () =>
            !isEmpty &&
            (formatData.keys.length === 0 || formatData.data.length === 0),
        [isEmpty, formatData.data.length, formatData.keys.length],
    );

    const handleChartClick = React.useCallback(
        (startDateTime: dayjs.Dayjs, endDateTime: dayjs.Dayjs) => {
            const queryParams = {
                startDate: startDateTime.format('YYYY-MM-DD'),
                endDate: endDateTime.format('YYYY-MM-DD'),
            };

            const path = PATH.DONATIONS.DONATION_SUMMARY_ENVELOPES({
                newDoneeId: doneeId,
                params: queryParams,
            });
            navigate(path);
            trackEvent(OVERVIEW_PAGE_GIVING_TREND_BAR);
        },
        [PATH.DONATIONS, doneeId, navigate, trackEvent],
    );

    const handleLegendClick = React.useCallback(
        (entry: { id: number | number[]; title: string }) => {
            const queryParams = {
                startDate: timeFrame.start.format('YYYY-MM-DD'),
                endDate: timeFrame.end.format('YYYY-MM-DD'),
                timeFrame:
                    TimeFrameFilterIds.indexOf(timeFrame.selector) > -1
                        ? timeFrame.selector
                        : 'custom',
                envelopeIds: Array.isArray(entry.id)
                    ? entry.id.join('_')
                    : JSON.stringify(entry.id),
            };

            const path = PATH.DONATIONS.DONATIONS_ACTIVITY({
                newDoneeId: doneeId,
                params: queryParams,
            });
            navigate(path);
        },
        [
            timeFrame.start,
            timeFrame.end,
            timeFrame.selector,
            PATH.DONATIONS,
            doneeId,
            navigate,
        ],
    );

    return (
        <Wrapper data-testid="bar-charts-wrapper">
            <Item>
                <OverviewPaper
                    data-testid="paper-title-giving-trend"
                    title={copy.givingTrendTitle}
                    variant="body1B"
                >
                    <BarChartView
                        data={formatData.data}
                        dataKey="name"
                        handleChartClick={handleChartClick}
                        handleLegendClick={handleLegendClick}
                        isEmpty={isEmpty}
                        isLoading={isLoading}
                        keys={formatData.keys}
                        onboardingCompleted={onboardingCompleted}
                        onboardingEnabled={onboardingEnabled}
                        rangeDateType={rangeDateType}
                    />
                </OverviewPaper>
            </Item>
        </Wrapper>
    );
};

export const BarChart = React.memo(BarChartComponent);
