import React, { useEffect, useMemo, useState } from 'react';
import {
    TimeFrameValues,
    TimeFrameLegacyIds,
    TimeFrameValue,
    TrackingProvider,
    PAGE_NAME,
    useInvokeApi,
} from '@givelify/utils';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { ReportInfo } from 'store/report/types';
import { useAdvancedTranslation } from 'utils/i18n';
import {
    FORMAT,
    getFormatFilter,
    LegacyXlsFormatVisible,
} from '../../utils/reportFormatFilterUtils';
import LegacyReportItemContext from './LegacyReportItemContext';
import { LEGACY_REPORT_TYPE } from './models';

const LegacyReportDonations: React.FCC = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation();
    const navigate = useNavigate();
    const copy = useMemo(
        () => ({
            name: t('reports.item.donations'),
        }),
        [t],
    );
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const [timeFrame, setTimeFrame] = useState<TimeFrameValue>({
        ...TimeFrameValues.thisYear,
        selector: 'custom',
    });
    const onTimeFrameChange = (timeFrame: TimeFrameValue) => {
        setTimeFrame(timeFrame);
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [format, setFormat] = useState<string>(getFormatFilter(FORMAT.EXCEL));
    const onFormatFileChange = (value: boolean) => {
        if (value) {
            setFormat(getFormatFilter(FORMAT.CSV));
        } else {
            setFormat(getFormatFilter(FORMAT.EXCEL));
        }
    };

    const [generateRequestState, generateRequest] = useInvokeApi<ReportInfo>();

    const { pathname } = useLocation();

    const pageName = useMemo(() => {
        const segments = pathname.split('/');

        return segments[segments.length - 1];
    }, [pathname]);

    useEffect(() => {
        if (
            !LegacyXlsFormatVisible.includes(pageName) ||
            pageName === 'import-friendly'
        ) {
            setFormat(getFormatFilter(FORMAT.CSV));
        }
    }, [pageName]);

    useEffect(() => {
        setIsLoading(generateRequestState.type === 'REQUEST_START');

        if (generateRequestState.type === 'REQUEST_SUCCESS') {
            navigate(PATH.REPORTS.LEGACY_REPORTS, {
                state: {
                    showInProgress: true,
                },
            });
        } else if (generateRequestState.type === 'REQUEST_ERROR') {
            navigate(PATH.REPORTS.LEGACY_REPORTS, {
                state: {
                    showError: true,
                },
            });
        }
        // listen to generateRequestState changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateRequestState]);

    const onSubmit = (
        startDate: Dayjs,
        endDate: Dayjs,
        pageName: string,
        excludeEmptyEnvelopes?: boolean,
    ) => {
        generateRequest('POST', '/reports/generate', {
            doneeId,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            reportType: LEGACY_REPORT_TYPE[pageName],
            fileType: format,
            timeFramePickerText: TimeFrameLegacyIds[timeFrame.selector],
            excludeEmptyEnvelopes,
        });
    };

    return (
        <TrackingProvider
            pageName={PAGE_NAME.ReportsOldDashboardTab}
            trackPageVisit={false}
        >
            <LegacyReportItemContext
                hasFormatFile
                format={format}
                isLoading={isLoading}
                name={copy.name}
                onFormatFileChange={onFormatFileChange}
                onSubmit={onSubmit}
                onTimeFrameChange={onTimeFrameChange}
                page="GenerateLegacyReport"
                pageName={pageName}
                timeFrame={timeFrame}
                type="custom"
            />
        </TrackingProvider>
    );
};

export default LegacyReportDonations;
