import React from 'react';
import { GivelifyTheme } from '@givelify/givelify-ui';
import { GivelifyThemeProvider } from '@givelify/ui';
import { Logger } from '@givelify/utils';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import LoadingBar from './components/system/LoadingBar';
import FullStory from './fullStory/FullStory';
import { Error500 } from './theme/components/ErrorPage';

type AppWrapperProps = {
    store: Store<unknown, AnyAction>;
    queryClient: QueryClient;
};

export const ThemeWrapper: React.FCC = ({ children }) => (
    <>
        <CssBaseline />
        <MuiThemeProvider theme={GivelifyTheme}>
            <GivelifyThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {children}
                </LocalizationProvider>
            </GivelifyThemeProvider>
        </MuiThemeProvider>
    </>
);

export const DataWrapper: React.FCC<AppWrapperProps> = ({
    store,
    queryClient,
    children,
}) => (
    <ThemeWrapper>
        <Logger.BasicErrorBoundary fallback={<Error500 />}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <React.Suspense fallback={<LoadingBar show />}>
                        <HelmetProvider>{children}</HelmetProvider>
                    </React.Suspense>
                    <FullStory />
                </QueryClientProvider>
            </Provider>
        </Logger.BasicErrorBoundary>
    </ThemeWrapper>
);

const AppWrapper: React.FCC<AppWrapperProps> = ({
    store,
    queryClient,
    children,
}) => (
    <DataWrapper queryClient={queryClient} store={store}>
        {children}
    </DataWrapper>
);

export default AppWrapper;
