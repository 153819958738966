import { ErrorResponse } from '@givelify/utils';
import { DonationsTimeFrameData } from '../types';

export const SET_DONATIONS_PREVIOUS_LOADING = 'SET_DONATIONS_PREVIOUS_LOADING';
export const SET_DONATIONS_PREVIOUS_SUCCESS = 'SET_DONATIONS_PREVIOUS_SUCCESS';
export const SET_DONATIONS_PREVIOUS_FAILED = 'SET_DONATIONS_PREVIOUS_FAILED';

export interface SetDonationsPreviousLoading {
    type: typeof SET_DONATIONS_PREVIOUS_LOADING;
}
export interface SetDonationsPreviousSuccess {
    type: typeof SET_DONATIONS_PREVIOUS_SUCCESS;
    data: DonationsTimeFrameData;
}
export interface SetDonationsPreviousFailed {
    type: typeof SET_DONATIONS_PREVIOUS_FAILED;
    error: ErrorResponse;
}
