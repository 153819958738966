import React from 'react';
import { GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { TileBox, TileContent } from './styles';

export type DashboardTileProps = {
    title: string;
    description: string;
    href: string;
    onClick?: () => void;
    iconUrl: string;
};

export const DashboardTile: React.FC<DashboardTileProps> = ({
    title,
    description,
    href,
    onClick,
    iconUrl,
}) => {
    const onTileClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
            event.preventDefault();
            onClick();
        }
    };
    return (
        <TileBox href={href} onClick={onTileClick} target="_blank">
            <div>
                <img alt={title} src={iconUrl} />
            </div>
            <TileContent>
                <GivelifyLabel className="tile-title" variant="heading2S">
                    {title}
                    <GivelifyIcon
                        color="primary"
                        fontSize="inherit"
                        variant="right-chevron"
                    />
                </GivelifyLabel>
                <GivelifyLabel variant="body1B">{description}</GivelifyLabel>
            </TileContent>
        </TileBox>
    );
};
