import React, { useCallback, useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyLabel,
    GivelifyModal,
    GivelifyPaper,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { OrganizationType } from '../../@types/assets/onboarding';
import ChurchThumbnail1 from '../../assets/church-thumbnail1.png';
import ChurchThumbnail2 from '../../assets/church-thumbnail2.png';
import ChurchThumbnail3 from '../../assets/church-thumbnail3.png';
import MobileNextArrow from '../../assets/mobile-next-arrow.svg';
import MobilePrevArrow from '../../assets/mobile-prev-arrow.svg';
import NonprofitThumbnai1 from '../../assets/nonprofit-thumbnail1.png';
import NonprofitThumbnai2 from '../../assets/nonprofit-thumbnail2.png';
import NonprofitThumbnai3 from '../../assets/nonprofit-thumbnail3.png';
import VideoCloseModalBtn from '../../assets/VideoCloseModalBtn.svg';
import { I18N_NAMESPACE } from '../../consts';
import {
    ONBOARDING_CLICK_DISMISS_CARDS,
    ONBOARDING_CLICK_VIDEO_CARD,
} from '../../utils/clevertapEvents';
import {
    DismissButton,
    MainGrid,
    MainWrapperGrid,
    StyledSlick,
    ThumbnailDescription,
    ThumbnailImg,
    ThumbnailsPaperWrapper,
    ThumbnailSubtitle,
    ThumbnailTitle,
    TitleGrid,
    VideoIframeWrapper,
    VideoModalBackBtn,
    VideoModalBottomGrid,
    VideoModalDescription,
    VideoModalPaper,
    VideoThumbnailWrapper,
    WorshipModalTitleText,
} from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const IMAGE_PATHS = {
    church: [ChurchThumbnail1, ChurchThumbnail2, ChurchThumbnail3],
    nonprofit: [NonprofitThumbnai1, NonprofitThumbnai2, NonprofitThumbnai3],
};

export interface VideoCardsProps {
    organizationType: OrganizationType;
    onDismissClick: () => void;
}

export const VideoCards: React.FCC<VideoCardsProps> = ({
    organizationType,
    onDismissClick: onDismissClickCallback,
}) => {
    const { trackEvent } = useTrackingContext();
    const [activeModalId, setActiveModalId] = useState<null | number>(null);

    const videoThumbnails = useMemo<Array<{ id: number; url: string }>>(
        () =>
            [0, 1, 2].map((i) => ({
                id: i + 1,
                url: IMAGE_PATHS[organizationType][i],
            })),
        [organizationType],
    );

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { t } = useTranslation(I18N_NAMESPACE, { useSuspense: false });
    const copy = React.useMemo(
        () => ({
            title: t(`onboardingCards.learnVideos.${organizationType}.title`),
            dismissText: t('onboardingCards.learnVideos.dismissText'),
            videoTitle: (index: number) =>
                t(
                    `onboardingCards.learnVideos.${organizationType}.videoTitle${index}`,
                ),
            videoSubTitle: (index: number) =>
                t(
                    `onboardingCards.learnVideos.${organizationType}.videoSubTitle${index}`,
                    {
                        defaultValue: '',
                    },
                ),
            videoDescription: (index: number) =>
                t(
                    `onboardingCards.learnVideos.${organizationType}.videoDescription${index}`,
                ),
            videoUrl: (index: number) =>
                t(
                    `onboardingCards.learnVideos.${organizationType}.videoUrl${index}`,
                ),
            modalBackText: t('onboardingCards.learnVideos.modalBackText'),
        }),
        [t, organizationType],
    );

    const thumbnailClickHandler = useCallback(
        (id: number) => {
            trackEvent(ONBOARDING_CLICK_VIDEO_CARD[id - 1]);
            setActiveModalId(id);
        },
        [trackEvent],
    );

    const modalCloseHandler = useCallback(() => {
        setActiveModalId(null);
    }, []);

    const onDismissClick = useCallback(() => {
        trackEvent(ONBOARDING_CLICK_DISMISS_CARDS);
        onDismissClickCallback();
    }, [onDismissClickCallback, trackEvent]);

    const videoThumbnailsComponents = useMemo(
        () =>
            videoThumbnails.map(({ id, url }) => (
                <GivelifyPaper
                    key={id}
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    padding="16px"
                >
                    <GivelifyModal
                        showCloseButton
                        CloseButton={
                            <img alt="Close modal" src={VideoCloseModalBtn} />
                        }
                        PaperComponent={(props) => (
                            <VideoModalPaper
                                {...props}
                                hasTopPadding={organizationType === 'nonprofit'}
                            />
                        )}
                        classes={{
                            closeButton: 'modal-close-btn',
                        }}
                        onClose={modalCloseHandler}
                        open={activeModalId === id}
                    >
                        {organizationType === 'church' &&
                        !isTablet &&
                        !isMobile ? (
                            <WorshipModalTitleText variant="heading3S">
                                <strong>{copy.videoTitle(id)}</strong>
                                &nbsp;
                                {copy.videoSubTitle(id)}
                                &nbsp;
                                <strong>{copy.videoDescription(id)}</strong>
                            </WorshipModalTitleText>
                        ) : null}
                        <VideoIframeWrapper>
                            <iframe
                                allowFullScreen
                                allow="autoplay; fullscreen; picture-in-picture"
                                frameBorder={0}
                                height="523px"
                                src={copy.videoUrl(id)}
                                title={copy.videoTitle(id) + ' video'}
                                width="100%"
                            />
                        </VideoIframeWrapper>
                        <VideoModalBottomGrid
                            container
                            alignItems="center"
                            flexWrap="nowrap"
                            gap="60px"
                            justifyContent="space-between"
                            marginTop="32px"
                        >
                            <VideoModalDescription variant="heading2S">
                                <Trans
                                    i18nKey={`onboardingCards.learnVideos.${organizationType}.videoModalDescription${id}`}
                                    t={t}
                                />
                            </VideoModalDescription>
                            <VideoModalBackBtn
                                onClick={modalCloseHandler}
                                text={copy.modalBackText}
                                variant="secondary"
                            />
                        </VideoModalBottomGrid>
                    </GivelifyModal>
                    <ThumbnailImg
                        alt={copy.videoTitle(id)}
                        onClick={() => thumbnailClickHandler(id)}
                        src={url}
                        width="100%"
                    />
                    <ThumbnailTitle variant="body1B">
                        {copy.videoTitle(id)}
                    </ThumbnailTitle>
                    <ThumbnailDescription
                        display="block"
                        lineHeight="18px"
                        marginTop="4px"
                        variant={
                            organizationType === 'nonprofit'
                                ? 'caption1'
                                : 'body2'
                        }
                    >
                        {copy.videoDescription(id)}
                    </ThumbnailDescription>
                    {copy.videoSubTitle(id) ? (
                        <ThumbnailSubtitle
                            display="block"
                            marginTop="4px"
                            variant="body2B"
                        >
                            {copy.videoSubTitle(id)}
                        </ThumbnailSubtitle>
                    ) : null}
                </GivelifyPaper>
            )),
        [
            videoThumbnails,
            activeModalId,
            modalCloseHandler,
            organizationType,
            isTablet,
            isMobile,
            copy,
            t,
            thumbnailClickHandler,
        ],
    );

    return (
        <MainWrapperGrid marginBottom="32px">
            <MainGrid container flexDirection="column" gap="24px">
                <TitleGrid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <GivelifyLabel variant="heading1S">
                        {copy.title}
                    </GivelifyLabel>
                    <DismissButton
                        disableRipple
                        onClick={onDismissClick}
                        text={
                            <GivelifyLabel
                                color={DesignTokens.color.textAccentDefault}
                                variant="body2"
                            >
                                {copy.dismissText}
                            </GivelifyLabel>
                        }
                        variant="ghost"
                    />
                </TitleGrid>
                <ThumbnailsPaperWrapper container flexGrow={1} gap="32px">
                    {isMobile ? (
                        <StyledSlick
                            dots
                            infinite={false}
                            nextArrow={
                                <img alt="Next arrow" src={MobileNextArrow} />
                            }
                            prevArrow={
                                <img
                                    alt="Previous arrow"
                                    src={MobilePrevArrow}
                                />
                            }
                            slidesToScroll={1}
                            slidesToShow={1}
                        >
                            {videoThumbnailsComponents.map(
                                (component, index) => (
                                    <VideoThumbnailWrapper key={index}>
                                        {component}
                                    </VideoThumbnailWrapper>
                                ),
                            )}
                        </StyledSlick>
                    ) : (
                        videoThumbnailsComponents
                    )}
                </ThumbnailsPaperWrapper>
            </MainGrid>
        </MainWrapperGrid>
    );
};
