import React, { useMemo, useState } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { useAdvancedTranslation } from '../../../../../../../utils/i18n';
import { donorTabStyle } from '../../style';
import AutoIntegrationConfirmModal from '../AutoIntegrationConfirmModal';

interface AutoIntegrationMatchedDonorButtonProps {
    id: number;
    disabled: boolean;
    unMatch: (donorId: number) => void;
    isReadOnly: boolean;
    deleteStatus: boolean;
    setDeleteStatus: () => void;
}

const AutoIntegrationMatchedDonorButton: React.FCC<
    AutoIntegrationMatchedDonorButtonProps
> = ({ id, disabled, unMatch, isReadOnly, deleteStatus, setDeleteStatus }) => {
    const classes = donorTabStyle();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            unmatchTitle: t('pages.integrations.confirmModal.confirmUnmatch'),
            unmatchDonor: t('pages.integrations.confirmModal.unmatchDonor'),
        }),
        [t],
    );

    const [openModal, setOpenModal] = useState<boolean>(false);
    const onCloseModal = () => setOpenModal(false);
    const onOpenModal = () => {
        setOpenModal(true);
        setDeleteStatus();
    };

    const onUnMatchButton = () => unMatch(id);

    return (
        <div className={classes.buttonItem}>
            <GivelifyButton
                className={classes.donorMatchBtn}
                disabled={isReadOnly || disabled}
                name="unmatch"
                onClick={onOpenModal}
                text="Unmatch"
            />
            <AutoIntegrationConfirmModal
                btnLoading={disabled}
                confirmFunction={onUnMatchButton}
                confirmText={copy.unmatchDonor}
                confirmTitle={copy.unmatchTitle}
                onClose={onCloseModal}
                open={openModal && !deleteStatus}
            />
        </div>
    );
};

export default AutoIntegrationMatchedDonorButton;
