import { User, Donee } from '@givelify/api';

export type RoutePathName =
    | 'root'
    | 'socialMediaGiving'
    | 'socialMediaGivingFacebook'
    | 'socialMediaGivingTwitter'
    | 'socialMediaGivingInstagram'
    | 'socialMediaGivingYoutube'
    | 'snapGive'
    | 'onlineGiving'
    | 'givelifyButton'
    | 'givelithon'
    | 'notFound';

export interface QRCodeInfo {
    id: number;
    createdAt: string;
    updatedAt: string;
    doneeId: number;
    downloadCount: number;
    uploadCount: number;
    qrFilenameOriginal: string;
}

export interface OnlineGivingURLsInfo {
    channel: string;
    link: string;
    type: string;
    shortLink: string;
    dynamicLink: string;
}

export type SocialMediaName = 'facebook' | 'twitter' | 'instagram' | 'youtube';

export type RoutePath = Partial<{
    [name in RoutePathName]: string;
}>;

export type RouteUrls = Partial<{
    [name in RoutePathName]: string;
}>;

export type SocialMediaFeatureFlag = {
    [name in SocialMediaName]: boolean;
};

export type ConnectedReduxAction = 'logout' | 'removeAccessToken';
export type ConnectorState = {
    user: User | null;
    donee: Donee | null;
    accessToken: string;
    routePaths: RoutePath;
    routeUrls: RouteUrls;
    socialMediaFeatureFlag: SocialMediaFeatureFlag;
    reduxActions: {
        [x in ConnectedReduxAction]?: () => void;
    };
    givelithonEnabled: boolean;
    snapGiveEnabled: boolean;
    snapGiveQRCodes: QRCodeInfo[];
    onlineGivingURLs: OnlineGivingURLsInfo[];
};

export type ConnectorAction =
    | { type: 'setRoutePath'; payload: RoutePath }
    | { type: 'setSocialMediaFeatureFlag'; payload: SocialMediaFeatureFlag }
    | { type: 'setGivelithonEnabled'; payload: boolean }
    | { type: 'setSnapGiveEnabled'; payload: boolean }
    | { type: 'setSnapGiveQRCodes'; payload: QRCodeInfo[] }
    | { type: 'setOnlineGivingURLs'; payload: OnlineGivingURLsInfo[] }
    | { type: 'setAccessToken'; payload: string }
    | { type: 'setDonee'; payload: Donee }
    | {
          type: 'setReduxAction';
          payload: {
              name: ConnectedReduxAction;
              handler: () => void;
          };
      };

export type ConnectorDispatch = (action: ConnectorAction) => void;

export type ConnectorProviderProps = {
    initialState?: Partial<ConnectorState>;
    children: React.ReactNode;
};

export type GiveNowButtonType = 'lightGive' | 'darkGive' | 'primaryGive';
export const GiveNowButtonTypes = [
    'lightGive',
    'primaryGive',
    'darkGive',
] as const;
