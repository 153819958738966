import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { loadData } from 'store/system/thunks';
import LoadingBar from '../components/system/LoadingBar';
import SecondaryWrapper from '../layout/SecondaryWrapper';
import { AppActions, AppState } from '../store';
import { setAccessToken } from '../store/user/actions';
import { isLoggedIn } from '../store/user/types';

import {
    useGasRouterContext,
    DONEE_SEARCH_QUERY_NAME,
} from './GasRouterProvider';

const GivelithonRoutes: React.FCC = () => {
    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();

    const { loggedIn, userId } = useSelector((state: AppState) => ({
        loggedIn: isLoggedIn(state),
        userId: state.User.user && state.User.user.id,
    }));

    const { PATH } = useGasRouterContext();
    const searchParams = new URLSearchParams(window.location.search);
    const doneeQuery = searchParams.get(DONEE_SEARCH_QUERY_NAME);
    const doneeIdFromQuery = React.useMemo(() => {
        if (doneeQuery) {
            const doneeIdRaw = parseInt(doneeQuery);
            if (isNaN(doneeIdRaw)) return 0;
            return doneeIdRaw;
        }
        return 0;
    }, [doneeQuery]);

    const [isLoading, setIsLoading] = useState(true);
    const onPageLoad = useCallback(async () => {
        if (!loggedIn) {
            return <Navigate to={PATH.DONEE.LOGIN} />;
        }

        const result = await dispatch(loadData(doneeIdFromQuery));
        if (!result) {
            dispatch(setAccessToken(undefined));
            return;
        }

        setIsLoading(false);
        //listen only to loggedIn change
        //eslint-disable-next-line
    }, [loggedIn]);

    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);

    const pageIsReady = !(isLoading || !userId);

    useEffect(() => {
        if (!pageIsReady) return;
    }, [pageIsReady]);

    if (!pageIsReady) {
        return <LoadingBar show={true} />;
    }

    return (
        <SecondaryWrapper>
            <Outlet />
        </SecondaryWrapper>
    );
};

export default GivelithonRoutes;
