import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLoading,
    GivelifyModal,
    GivelifyModalProps,
} from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { LoadingBox, SuccessBox, Row } from './styles';

const LoadingBoxContent = (props: { text: string }) => {
    return (
        <Row>
            <GivelifyLoading color="inherit" size={24} type="circular" />
            <GivelifyLabel text={props.text} variant="body2" />
        </Row>
    );
};

const SuccessBoxContent = (props: { text: string }) => {
    return (
        <Row>
            <GivelifyIcon fontSize={24} variant="success-box-icon" />
            <GivelifyLabel text={props.text} variant="body2" />
        </Row>
    );
};

type ConfirmDialogProps = GivelifyModalProps & {
    status: 'loading' | 'success';
};

export const LoadingDialog: React.FCC<ConfirmDialogProps> = ({
    status,
    ...props
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            loading: t(
                'pages.settings.bank-info.customize-rep-tab.editor.dialog.loading',
            ),
            success: t(
                'pages.settings.bank-info.customize-rep-tab.editor.dialog.success',
            ),
        }),
        [t],
    );
    return (
        <GivelifyModal
            {...props}
            noPadding
            PaperComponent={status === 'loading' ? LoadingBox : SuccessBox}
        >
            {status === 'loading' ? (
                <LoadingBoxContent text={copy.loading} />
            ) : (
                <SuccessBoxContent text={copy.success} />
            )}
        </GivelifyModal>
    );
};
