import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { TooltipProps } from 'recharts';
import { useAdvancedTranslation } from '../../../../../utils/i18n';
import { NameType, ValueType } from '../../../types';
import { GraphTooltip } from '../components/SimplePieChart/styles';

type DonorsChartTooltipProps = TooltipProps<ValueType, NameType>;

export const DonorsChartTooltip: React.FCC<DonorsChartTooltipProps> = (
    props,
) => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            total: t(`overview.total`),
        }),
        [t],
    );

    if (props.payload.length === 0) return null;

    const data = props.payload[0];
    return (
        <GraphTooltip data-testid="graph-tooltip">
            <p data-testid="graph-tooltip-title">
                <strong>{data.name}</strong>
            </p>
            <Box
                data-testid="graph-tooltip-text-total"
                display="flex"
                justifyContent="space-between"
                pb={3}
                width="100%"
            >
                <Box fontWeight="bold" pr={2}>
                    {copy.total}:
                </Box>
                <span>{data.payload.donorsCount}</span>
            </Box>
        </GraphTooltip>
    );
};
