import React from 'react';
import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { styled } from '@mui/material';

interface OnboardingNoteProps {
    text: string;
    linkText: string;
    linkName?: string;
    linkAction?: () => void;
    heading?: string;
    secondaryText?: string;
}

const StyledContainer = styled('div')(() => ({
    borderRadius: '4px',
    background: DesignTokens.color.globalNeutral100,
    padding: '8px',
    marginTop: '8px',
    width: '100%',
}));

export const OnboardingNote: React.FCC<OnboardingNoteProps> = ({
    text,
    linkText,
    linkAction,
    heading,
    secondaryText,
    linkName,
}) => {
    return (
        <StyledContainer>
            {heading && <GivelifyLabel text={heading} />}
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                variant="caption1"
            >
                {text}{' '}
                <GivelifyLink
                    data-testid="note-action"
                    name={linkName}
                    onClick={linkAction}
                    text={linkText}
                    variant="caption1"
                />
            </GivelifyLabel>
            {secondaryText && (
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    text={secondaryText}
                    variant="caption1"
                />
            )}
        </StyledContainer>
    );
};
