import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    innerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    cancelInput: {
        cursor: 'pointer',
    },
    saveButton: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    cancelButton: {
        width: 16,
        height: 15,
        minWidth: 0,
    },
}));
