import React from 'react';
import { GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { Grid, Stack } from '@mui/material';
import { MOCKUP_PATH } from './paths';
import {
    MobilePreviewBack,
    MobilePreviewCase,
    MobilePreviewContent,
    MobilePreviewCover,
    MobilePreviewCoverWrapper,
    MobilePreviewPagination,
    OrgTitle,
    Overlay,
    QuickGiveButtonBox,
} from './styles';

export type QuickGiveMobilePreviewProps = {
    options: string[];
    contentTitle: string;
    imageUrl?: string;
    doneeName: string;
};

export const QuickGiveMobilePreview: React.FCC<QuickGiveMobilePreviewProps> = ({
    imageUrl,
    doneeName,
    options,
    contentTitle,
}) => {
    return (
        <MobilePreviewCase>
            <MobilePreviewContent>
                <MobilePreviewCoverWrapper>
                    <MobilePreviewCover
                        src={imageUrl}
                        title="Organisation cover picture"
                    />
                    <Overlay />
                    <OrgTitle color="white" variant="body1B">
                        {doneeName}
                    </OrgTitle>
                </MobilePreviewCoverWrapper>
                <GivelifyLabel
                    color="gray"
                    marginBottom={5}
                    marginTop={4}
                    text={contentTitle}
                    textAlign="center"
                    variant="caption1"
                />
                <Stack paddingX={2}>
                    <Grid
                        container
                        columnSpacing={1}
                        justifyContent="center"
                        rowSpacing={2}
                    >
                        {options.map((value) => (
                            <Grid
                                key={'default_' + value}
                                item
                                alignItems="center"
                                justifyContent="center"
                                xs={4}
                            >
                                <QuickGiveButtonBox>
                                    <GivelifyLabel
                                        text={value}
                                        variant="body2"
                                    />
                                </QuickGiveButtonBox>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
                <MobilePreviewBack>
                    <GivelifyIcon variant="left-arrow" />
                </MobilePreviewBack>
                <MobilePreviewPagination>
                    <img
                        alt="pagination dots"
                        src={MOCKUP_PATH.PAGINATION_DOTS}
                        title="Pagination dots"
                    />
                </MobilePreviewPagination>
            </MobilePreviewContent>
        </MobilePreviewCase>
    );
};
