import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { formatWithLocaltime } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
    formatMoney,
    formatMoneyWithCommas,
    formatMoneyWithCommasAndDolarSign,
    formatMoneyWithKAndM,
} from 'utils/formatMoneyWithCommas';
import {
    DollarSign,
    TotalAmount,
    TotalAmountWrapper,
    InfoWrapper,
    ChartHeaderWrapper,
    DatesText,
    TotalText,
    AvgDonations,
    ContentWrapper,
} from './styles';

type ChartHeaderComponentProps = {
    total: number;
    donationsCount: number;
    dateStart: dayjs.Dayjs;
    dateEnd: dayjs.Dayjs;
    avgDonations: number;
};

const ChartHeaderComponent: React.FCC<ChartHeaderComponentProps> = ({
    total,
    donationsCount,
    dateStart,
    dateEnd,
    avgDonations,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isSmallTablet = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            totalReceived: t('overview.totalReceived'),
            donation: t('overview.donation'),
            donations: t('overview.donations'),
            avgDonations: t('overview.avgDonations'),
        }),
        [t],
    );

    return (
        <ChartHeaderWrapper>
            <ContentWrapper>
                <TotalAmountWrapper>
                    <DollarSign>$</DollarSign>
                    <TotalAmount
                        data-testid="chart-total-donation"
                        text={
                            isMobile
                                ? total === 0
                                    ? 0
                                    : formatMoneyWithKAndM(total)
                                : total === 0
                                ? 0
                                : formatMoneyWithCommas(total)
                        }
                    />
                </TotalAmountWrapper>
                <InfoWrapper>
                    <TotalText
                        color={DesignTokens.color.textSecondary}
                        data-testid="chart-total-received"
                        variant={isSmallTablet ? 'caption1' : 'body2B'}
                    >
                        {`${copy.totalReceived} `}
                        {isMobile ? null : (
                            <GivelifyLabel
                                color={DesignTokens.color.textPrimary}
                                text={`${donationsCount} ${
                                    donationsCount === 1
                                        ? copy.donation
                                        : copy.donations
                                }`}
                                variant={isSmallTablet ? 'caption1B' : 'body2B'}
                            />
                        )}
                    </TotalText>
                    {isMobile ? (
                        <TotalText
                            color={DesignTokens.color.textPrimary}
                            data-testid="chart-total-received"
                            text={`${donationsCount} ${copy.donations}`}
                        />
                    ) : null}
                    <DatesText
                        color={DesignTokens.color.textSecondary}
                        data-testid="chart-date-range-text"
                        variant={isSmallTablet ? 'caption1' : 'body2'}
                    >
                        {`${formatWithLocaltime(
                            dateStart,
                            'MMM D, YYYY',
                        )} - ${formatWithLocaltime(dateEnd, 'MMM D, YYYY')}`}
                    </DatesText>
                </InfoWrapper>
            </ContentWrapper>
            {!isMobile ? (
                <AvgDonations>
                    <GivelifyLabel text={copy.avgDonations} variant="data1" />
                    <GivelifyLabel
                        text={
                            avgDonations === 0
                                ? formatMoney(0)
                                : formatMoneyWithCommasAndDolarSign(
                                      avgDonations,
                                  )
                        }
                        textAlign="right"
                        variant="body1B"
                    />
                </AvgDonations>
            ) : null}
        </ChartHeaderWrapper>
    );
};

export const ChartHeader = React.memo(ChartHeaderComponent);
