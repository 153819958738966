import React, { useMemo } from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { Divider } from '@mui/material';
import { BasicPageWrapper } from 'components';
import roleTypes from 'constants/roleTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { AddressSection } from './address/AddressSection';
import { MailSection } from './address/MailSection';
import ContactSection from './contact/ContactSection';
import SocialSection from './social/SocialSection';
import { Paper } from './style';

const OrgInfoPageComponent: React.FCC = () => {
    const { donee, userRole } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
        userRole: state.User.user.role,
    }));
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.organization-info.title'),
        }),
        [t],
    );
    const isReadOnly =
        userRole === roleTypes.BASIC || userRole === roleTypes.ADMIN_PANEL_USER;

    return (
        <BasicPageWrapper contentWrapper="legacy" title={copy.title}>
            <Paper>
                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoMailingAddress}
                    trackPageVisit={false}
                >
                    <AddressSection
                        doneeId={donee.id}
                        isReadOnly={isReadOnly}
                        physicalAddress={{
                            street: donee.address,
                            city: donee.city,
                            state: donee.state,
                            postal: donee.zip,
                        }}
                    />
                </TrackingProvider>

                <Divider />

                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoMailingAddress}
                    trackPageVisit={false}
                >
                    <MailSection
                        doneeId={donee.id}
                        isReadOnly={isReadOnly}
                        mailingAddress={
                            donee.mailingAddress
                                ? {
                                      street: donee.mailingAddress.address,
                                      city: donee.mailingAddress.city,
                                      state: donee.mailingAddress.state,
                                      postal: donee.mailingAddress.zip,
                                  }
                                : null
                        }
                    />
                </TrackingProvider>

                <Divider />

                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoContactInfo}
                    trackPageVisit={false}
                >
                    <ContactSection donee={donee} isReadOnly={isReadOnly} />
                </TrackingProvider>

                <Divider />

                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoSocialInfo}
                    trackPageVisit={false}
                >
                    <SocialSection donee={donee} isReadOnly={isReadOnly} />
                </TrackingProvider>
            </Paper>
        </BasicPageWrapper>
    );
};

export const OrgInfoPage: React.FCC = (props) => {
    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsOrganizationInfo}
        >
            <OrgInfoPageComponent {...props} />
        </TrackingProvider>
    );
};
