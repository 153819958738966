import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Address,
    GivelifyBankChequePreview,
    GivelifyLabel,
    isValidAddress,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { ShowAndEditAddress } from '@givelify/ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DirectDepositInfo } from '../../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../../consts';
import { ValidateAccountInfo } from '../../../utils/validation';
import { ChequeUploadSection } from '../documentUpload/ChequeUploadSection';
import DirectDepositAccountInfo from './DirectDepositAccountInfo';
import DirectDepositAddressToggle from './DirectDepositAddressToggle';
import TopStaticText from './TopStaticText';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        manualEntryStyles: {
            overflow: 'visible',
            '& .grid': {
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column-reverse',
                },
            },
            '& .form-column': {
                width: 600,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
            '& .form-flex': {
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column-reverse',
                },
            },
            '& .image-column': {
                flex: 1,
                marginTop: -32,
                marginLeft: 40,
                [theme.breakpoints.down('sm')]: {
                    marginTop: 0,
                    marginBottom: 40,
                    marginLeft: 0,
                },
            },
            '& .top-margin': {
                marginTop: 0,
            },
            '& .address-wrapper': {
                maxWidth: 600,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    maxWidth: 'initial',
                },
            },
        },
    }),
);

export interface DirectDepositManualEntryRef {
    submit: () => DirectDepositInfo;
}

export interface DirectDepositManualEntryProps {
    data: DirectDepositInfo;
    showChequeSection: boolean;
    onValidation: (isValid: boolean) => unknown;
}

const DirectDepositManualEntryComponent = (
    { data, showChequeSection, onValidation }: DirectDepositManualEntryProps,
    forwardRef: React.ForwardedRef<DirectDepositManualEntryRef>,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { manualEntryStyles } = useStyles();
    const routingRef = useRef<HTMLInputElement>(null);
    const accountingRef = useRef<HTMLInputElement>(null);
    const [bankChequeFile, setBankChequeFile] = useState<File | undefined>();
    const [isAccountInfoFormValid, setAccountInfoFormValid] = useState<boolean>(
        Boolean(data.routingNumber) &&
            Boolean(data.accountNumber) &&
            ValidateAccountInfo({
                accountNumber: data.accountNumber.toString(),
                routingNumber: data.routingNumber.toString(),
            }).isValid,
    );
    const [newAddress, setAddress] = useState<Address>(data.bankingAddress);
    const [isAddressValid, setIsAddressValid] = useState<boolean>(
        isValidAddress(data.bankingAddress, false),
    );
    const [yes, setYes] = useState<boolean>(isAddressValid);
    const onChangeAddress = (address: Address) => {
        setAddress(address);
        setIsAddressValid(isValidAddress(address, false));
    };
    const {
        streetPlaceholder,
        cityPlaceholder,
        statePlaceholder,
        zipPlaceholder,
        requiredText,
        stepOneText,
        isPOBoxIsNotAllowedText,
    } = useMemo(
        () => ({
            streetPlaceholder: t('addressForm.placeholder.street'),
            cityPlaceholder: t('addressForm.placeholder.city'),
            statePlaceholder: t('addressForm.placeholder.state'),
            zipPlaceholder: t('addressForm.placeholder.zip'),
            requiredText: t(
                'directDepositManualEntry.topStateText.requiredText',
            ),
            stepOneText: t('directDepositManualEntry.topStateText.stepOne'),
            isPOBoxIsNotAllowedText: t('addressForm.POBoxIsNotAllowed'),
        }),
        [t],
    );

    useEffect(() => {
        if (showChequeSection) {
            onValidation(isAddressValid && bankChequeFile !== undefined);
        } else {
            onValidation(isAddressValid && isAccountInfoFormValid);
        }
    }, [
        isAddressValid,
        isAccountInfoFormValid,
        bankChequeFile,
        onValidation,
        showChequeSection,
    ]);

    useImperativeHandle(forwardRef, () => ({
        submit: () => ({
            ...data,
            routingNumber: routingRef.current?.value || '',
            accountNumber: accountingRef.current?.value || '',
            bankingAddress: newAddress,
            bankChequeFile,
            addressIsSame: yes,
        }),
    }));

    return (
        <div className={manualEntryStyles}>
            <div>
                <TopStaticText />
                <ChequeUploadSection
                    onChange={setBankChequeFile}
                    show={showChequeSection}
                />
                {showChequeSection ? null : (
                    <div className="form-flex">
                        <div className="form-column">
                            <GivelifyLabel
                                bold
                                marginBottom={18}
                                text={stepOneText}
                                variant="heading3"
                            />
                            <GivelifyLabel
                                color="neutralGrey"
                                marginBottom={16}
                                text={requiredText}
                                variant="small"
                            />
                            <DirectDepositAccountInfo
                                accounting={data.accountNumber}
                                accountingRef={accountingRef}
                                isFormValid={setAccountInfoFormValid}
                                routing={data.routingNumber}
                                routingRef={routingRef}
                            />
                        </div>
                        <div
                            className={mergeClassNames(
                                'image-column',
                                showChequeSection && 'top-margin',
                            )}
                        >
                            <GivelifyBankChequePreview />
                        </div>
                    </div>
                )}
            </div>
            {showChequeSection ? null : (
                <div className="address-wrapper">
                    <DirectDepositAddressToggle handleYes={setYes} yes={yes} />
                    <ShowAndEditAddress
                        address={data.bankingAddress}
                        cityPlaceholder={cityPlaceholder}
                        isEdit={!yes}
                        name="Direct Deposit Address Editor"
                        onChange={onChangeAddress}
                        phonePlaceholder={undefined}
                        poBoxNotAllowedText={isPOBoxIsNotAllowedText}
                        setIsAddressValid={setIsAddressValid}
                        statePlaceholder={statePlaceholder}
                        streetPlaceholder={streetPlaceholder}
                        zipPlaceholder={zipPlaceholder}
                    />
                </div>
            )}
        </div>
    );
};

export const DirectDepositManualEntry = forwardRef<
    DirectDepositManualEntryRef,
    DirectDepositManualEntryProps
>(DirectDepositManualEntryComponent);
