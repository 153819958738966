import React from 'react';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import {
    AnswerText,
    AnswerTextWrapper,
    FillLine,
    PercentWrapper,
} from './styles';

export type GivelifyPollAnswerProps = {
    text: string;
    isSelected: boolean;
    fillPercent: number;
};

export const PollAnswer: React.FCC<GivelifyPollAnswerProps> = ({
    fillPercent,
    text,
    isSelected,
}) => {
    return (
        <AnswerText>
            <FillLine
                data-testid="poll-percent-line"
                fillPercent={fillPercent}
            />
            <AnswerTextWrapper>
                <GivelifyLabel
                    data-testid="poll-answer-text"
                    text={text}
                    variant="body2"
                />
                {isSelected ? (
                    <GivelifyIcon
                        data-testid="poll-verify-icon"
                        fontSize={24}
                        variant="verifyIcon"
                    />
                ) : null}
            </AnswerTextWrapper>

            <PercentWrapper>
                <GivelifyLabel
                    data-testid="poll-percent-answer"
                    text={fillPercent + '%'}
                    variant="body2"
                />
            </PercentWrapper>
        </AnswerText>
    );
};
