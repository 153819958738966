import React from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    GivelifyButton,
    GivelifyDatePicker,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyModal,
    GivelifyNotification,
} from '@givelify/ui';
import dayjs from 'dayjs';
import { useDoneeStartDate } from 'hooks/useDoneeStartDate';
import { DialogContent, DialogFooter, DialogRoot } from './style';

type ExportDonationDialogProps = {
    onSubmit: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
    exported?: boolean;
    errorMessage?: string;
};

export const ExportDonationDialog: React.FCC<ExportDonationDialogProps> = ({
    onSubmit: onSubmitCallback,
    exported,
    errorMessage,
}) => {
    const { scopedTranslate, t } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.integration_exported_donations',
    });
    const copy = React.useMemo(
        () => ({
            exportedDonation: scopedTranslate('exportedDonation'),
            selectDateRange: scopedTranslate('selectDateRange'),
            startDate: scopedTranslate('startDate'),
            endDate: scopedTranslate('endDate'),
            startDateRequired: scopedTranslate('startDateRequired'),
            endDateRequired: scopedTranslate('endDateRequired'),
            endDateAfterStartDate: scopedTranslate('endDateAfterStartDate'),
            startDateBeforeEndDate: scopedTranslate('startDateBeforeEndDate'),
            startDateInPast: scopedTranslate('startDateInPast'),
            endDateInPast: scopedTranslate('endDateInPast'),
            noDataFound: scopedTranslate('noDataFound'),
            cancel: t('labels.cancel'),
            export: t('labels.export'),
        }),
        [scopedTranslate, t],
    );
    const doneeStartDate = dayjs.tz(useDoneeStartDate());
    const [open, setOpen] = React.useState(false);
    const [isDownloading, setDownloading] = React.useState<boolean>(false);
    const openModal = React.useCallback(() => setOpen(true), []);
    const [startDate, setStartDate] = React.useState<dayjs.Dayjs | undefined>(
        undefined,
    );
    const [endDate, setEndDate] = React.useState<dayjs.Dayjs | undefined>(
        undefined,
    );
    const closeModal = React.useCallback(() => {
        setOpen(false);
        setStartDate(undefined);
        setEndDate(undefined);
        setDownloading(false);
    }, []);
    const onSubmit = React.useCallback(() => {
        onSubmitCallback(startDate, endDate);
        setDownloading(true);
    }, [endDate, onSubmitCallback, startDate]);
    const errorStartDate = React.useMemo(() => {
        if (startDate === undefined) return '';
        if (startDate === null || !startDate.isValid())
            return copy.startDateRequired;
        if (dayjs().isBefore(startDate)) return copy.startDateInPast;
        if (!endDate || !endDate.isValid()) return '';
        if (startDate.isBefore(endDate)) return '';
        return copy.startDateBeforeEndDate;
    }, [
        copy.startDateBeforeEndDate,
        copy.startDateInPast,
        copy.startDateRequired,
        endDate,
        startDate,
    ]);
    const errorEndDate = React.useMemo(() => {
        if (startDate === undefined) return '';
        if (endDate === undefined) return '';
        if (endDate === null || !endDate.isValid()) return copy.endDateRequired;
        if (dayjs().isBefore(startDate)) return copy.endDateInPast;
        if (!endDate || !endDate.isValid()) return '';
        if (startDate.isBefore(endDate)) return '';
        return copy.endDateAfterStartDate;
    }, [
        copy.endDateAfterStartDate,
        copy.endDateInPast,
        copy.endDateRequired,
        endDate,
        startDate,
    ]);
    const canSubmit =
        !errorStartDate &&
        !errorEndDate &&
        startDate &&
        endDate &&
        startDate.isValid() &&
        endDate.isValid();
    React.useEffect(() => {
        if (exported) {
            setOpen(false);
            setDownloading(false);
        }
    }, [exported]);
    React.useEffect(() => {
        if (errorMessage) {
            setDownloading(false);
        }
    }, [errorMessage]);
    return (
        <DialogRoot>
            <GivelifyButton
                onClick={openModal}
                startIcon={<GivelifyIcon variant="file-export" />}
                text={copy.export}
                variant="ghost"
            />
            <GivelifyModal showCloseButton onClose={closeModal} open={open}>
                <div>
                    <GivelifyNotification
                        open={errorMessage.length > 0}
                        text={errorMessage}
                        variant="error"
                    />
                    <GivelifyLabel
                        fontWeight="bold"
                        marginBottom={5.25}
                        marginTop={errorMessage ? 2 : 0}
                        text={copy.exportedDonation}
                        variant="heading1S"
                    />
                    <GivelifyLabel
                        fontWeight="bold"
                        marginBottom={3}
                        text={copy.selectDateRange}
                        variant="heading2S"
                    />
                    <DialogContent>
                        <GivelifyDatePicker
                            disableFuture
                            fullwidth
                            ariaLabel="startDate"
                            disabled={isDownloading}
                            helperText={errorStartDate}
                            id="startDate"
                            label={copy.startDate}
                            maxDate={dayjs().tz()}
                            minDate={doneeStartDate}
                            name="startDate"
                            onDateChange={setStartDate}
                            size="large"
                            state={errorStartDate ? 'error' : 'idle'}
                            value={startDate}
                        />
                        <GivelifyLabel
                            marginTop={2}
                            text="to"
                            variant="caption2"
                        />
                        <GivelifyDatePicker
                            disableFuture
                            fullwidth
                            ariaLabel="endDate"
                            disabled={isDownloading}
                            helperText={errorEndDate}
                            id="endDate"
                            label={copy.endDate}
                            maxDate={dayjs().tz()}
                            minDate={
                                errorStartDate
                                    ? startDate
                                        ? startDate
                                        : dayjs().tz()
                                    : startDate
                            }
                            name="endDate"
                            onDateChange={setEndDate}
                            size="large"
                            state={errorEndDate ? 'error' : 'idle'}
                            value={endDate}
                        />
                    </DialogContent>
                    <DialogFooter>
                        <GivelifyButton
                            onClick={closeModal}
                            text={copy.cancel}
                        />
                        <GivelifyButton
                            disabled={!canSubmit}
                            isLoading={isDownloading}
                            onClick={onSubmit}
                            text={copy.export}
                            variant="primary"
                        />
                    </DialogFooter>
                </div>
            </GivelifyModal>
        </DialogRoot>
    );
};
