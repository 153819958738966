import React, { ReactChild } from 'react';
import LoadingBar from '../components/system/LoadingBar';
import { useSecondaryLayoutStyles } from '../styles/secondaryLayout';

// Temporarily disabled code, since they were not a part of MVP
// https://github.com/Givelify/donee-portal-ui/commit/b67fdecf5136956634d38cd37db130a708afed08

const SecondaryWrapper: React.FCC<{ children?: ReactChild }> = ({
    children,
}) => {
    const classes = useSecondaryLayoutStyles();

    return (
        <div className={classes.root}>
            <main className={classes.secondaryContent}>
                <React.Suspense fallback={<LoadingBar show />}>
                    <div className={classes.contentWrapper}>{children}</div>
                </React.Suspense>
            </main>
        </div>
    );
};

export default React.memo(SecondaryWrapper);
