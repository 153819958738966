export const toFormData = <T extends Record<string, any>>(
    data: T,
): FormData => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
        const value = data[key];

        if (value) {
            formData.append(key, value);
        }
    });

    return formData;
};
