import React from 'react';
import { GivelifyIconVariant, GivelifyModal } from '@givelify/ui';
import { styled } from '@mui/material';
import { OnboardingModalHeader } from './OnboardingModalHeader';

type OnboardingModalProps = {
    iconVariant: GivelifyIconVariant;
    title: string;
    lockText: string;
};

const OnboardingModalContent = styled('div')(({ theme }) => ({
    padding: '24px 76px',
    [theme.breakpoints.down('smallTablet')]: {
        padding: '24px 16px',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '24px 16px',
    },
    '& .onboarding-content': {
        margin: '24px 0 32px 0',
    },
}));

export const OnboardingModal: React.FCC<OnboardingModalProps> = (props) => {
    return (
        <GivelifyModal
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                },
            }}
            fullScreen={true}
            noPadding={true}
            open={true}
        >
            <OnboardingModalHeader
                iconVariant={props.iconVariant}
                lockText={props.lockText}
                title={props.title}
            />
            <OnboardingModalContent>{props.children}</OnboardingModalContent>
        </GivelifyModal>
    );
};
