import { useMemo } from 'react';
import {
    AddressWithPhone,
    STATE_DROPDOWN_OPTIONS,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import {
    GivelifyFormDropDown,
    GivelifyFormTextField,
    GivelifyLabel,
} from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { I18N_NAMESPACE } from '../../../../consts';
import { OnboardingActions } from '../../OnboardingActions';
import OnboardingPage from '../../OnboardingPage';
import { AddressInfoWrapper } from './AddressInfoWrapper';
import { FormWrapper } from './styles';

interface AddressViewProps {
    onBackClick: () => void;
    onCancelClick: () => void;
}

const AddressForm: React.FCC<AddressViewProps> = ({
    onBackClick,
    onCancelClick,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.primaryRepresentative.address`,
    });

    const copy = useMemo(
        () => ({
            title: scopedTranslate('title'),
            streetPlaceholder: scopedTranslate('streetPlaceholder'),
            cityPlaceholder: scopedTranslate('cityPlaceholder'),
            statePlaceholder: scopedTranslate('statePlaceholder'),
            zipPlaceholder: scopedTranslate('zipPlaceholder'),
            phonePlaceholder: scopedTranslate('phonePlaceholder'),
            poBoxError: scopedTranslate('poBoxError'),
        }),
        [scopedTranslate],
    );

    const STATES_LIST = useMemo(
        () =>
            STATE_DROPDOWN_OPTIONS.map((i) => ({
                id: i.value,
                label: i.label,
            })),
        [],
    );

    const { formState } = useFormContext();

    return (
        <>
            <GivelifyLabel
                style={{
                    marginBottom: 12,
                }}
                text={copy.title}
                variant="heading1S"
            />
            <FormWrapper>
                <div>
                    <GivelifyFormTextField<AddressWithPhone>
                        fullWidth
                        data-testid="pr-street"
                        label={copy.streetPlaceholder}
                        leftHelperText={copy.poBoxError}
                        name="street"
                        placeholder={copy.streetPlaceholder}
                    />
                </div>
                <div>
                    <GivelifyFormTextField<AddressWithPhone>
                        fullWidth
                        data-testid="pr-city"
                        label={copy.cityPlaceholder}
                        name="city"
                        placeholder={copy.cityPlaceholder}
                    />
                </div>
                <div style={isMobile ? {} : { gridColumn: 'span 1' }}>
                    <GivelifyFormDropDown<AddressWithPhone>
                        data-testid="pr-state"
                        label={copy.statePlaceholder}
                        name="state"
                        options={STATES_LIST}
                        placeholder={copy.statePlaceholder}
                        shape="input"
                        width="fullwidth"
                    />
                </div>
                <div style={isMobile ? {} : { gridColumn: 'span 1' }}>
                    <GivelifyFormTextField<AddressWithPhone>
                        fullWidth
                        data-testid="pr-zip"
                        label={copy.zipPlaceholder}
                        maskOptions={{ mask: '00000' }}
                        name="zip"
                        placeholder={copy.zipPlaceholder}
                    />
                </div>
                <div>
                    <GivelifyFormTextField<AddressWithPhone>
                        fullWidth
                        data-testid="pr-phone"
                        label={copy.phonePlaceholder}
                        maskOptions={{ mask: '000-000-0000' }}
                        name="phone"
                        placeholder={copy.phonePlaceholder}
                    />
                </div>
            </FormWrapper>
            <OnboardingActions
                disableContinue={!formState.isValid}
                onBack={onBackClick}
                onCancel={onCancelClick}
            />
        </>
    );
};

const Wrapper: React.FCC<AddressViewProps> = ({
    onBackClick,
    onCancelClick,
}) => {
    return (
        <OnboardingPage
            content={
                <AddressForm
                    onBackClick={onBackClick}
                    onCancelClick={onCancelClick}
                />
            }
            informationContent={<AddressInfoWrapper />}
        />
    );
};

export default Wrapper;
