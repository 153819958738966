import React, { ReactElement, useMemo } from 'react';
import { DesignTokens, GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { Box } from '@material-ui/core';
import { Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import iconAppStore from 'assets/icon-app-store.svg';
import getMobileApp from 'assets/icon-givelify-mobile-app.svg';
import getMobileAppByQr from 'assets/icon-givelify-qr-app.svg';
import iconPlayStore from 'assets/icon-google-play-store.svg';
import iconHeart from 'assets/icon-heart.svg';
import clevertap from 'clevertap-web-sdk';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { webConfig } from 'webConfig';

const Wrapper = styled(Box)(({ theme }) => ({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 16px',
    gap: '24px',

    boxShadow: DesignTokens.shadow.shadowMediumHigh,
    background: 'white',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: '32px',
        paddingTop: '36px',
        paddingBottom: '36px',
        flexDirection: 'row-reverse',
        alignItems: 'start',
        justifyContent: 'start',
        gap: '50px',
    },

    '& .getMobileApp': {
        '& > .onSmallScreen': {
            width: 156,
            height: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 22,
        },
        '& > .onLargeScreen': {
            width: 322,
            height: 'auto',
            marginTop: '-10px',
        },
        '& .iconAppStore': {
            width: 'auto',
            height: '23px',
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                height: '26px',
            },
        },
        '& .iconPlayStore': {
            width: 'auto',
            height: '24px',
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                height: '27px',
            },
        },
    },

    '& .descCard': {
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',

        '& .title': {
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '28px',

            [theme.breakpoints.up('md')]: {
                fontWeight: 800,
                fontSize: '36px',
                lineHeight: '44px',
            },
            [theme.breakpoints.up('lg')]: {
                lineHeight: '48px',
            },
        },

        '& .stepContainer': {
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
            [theme.breakpoints.up('md')]: {
                gap: '12px',
                marginLeft: '28px',
            },
            [theme.breakpoints.up('lg')]: {
                gap: '14px',
            },
        },

        '& .stepNumber': {
            display: 'inline-flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            width: 26,
            height: 26,
            borderRadius: '50%',
            backgroundColor: DesignTokens.color.globalGuidingViolet400,
            color: 'white',
            flexShrink: 0,
            paddingTop: 4,
            paddingRight: 1,
            marginRight: 16,

            '& > span': {
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '26px',
            },
        },
    },
}));

const Step: React.FCC<{
    number: number;
    text: string;
    icon?: ReactElement;
}> = ({ number, text, icon }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div className="stepNumber">
                <span>{number}</span>
            </div>
            <GivelifyLabel
                sx={
                    isSmallScreen
                        ? { fontWeight: 600, lineHeight: '20px' }
                        : { fontWeight: 400, lineHeight: '19px' }
                }
                text={text}
                variant={isSmallScreen ? 'body2' : 'body1'}
            />
            {icon || null}
        </Box>
    );
};

const IconHeart: React.FCC = () => {
    return (
        <img alt="heart" src={iconHeart} style={{ marginLeft: 8 }} width={24} />
    );
};

const FirstDonationPanelView = () => {
    const theme = useTheme();

    const { name: orgName, city } = useSelector(
        (state: AppState) => state.Donee?.donee,
    );

    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('overview.firstDonatePanel.title'),
            desktopSubTitle: t('overview.firstDonatePanel.desktopSubTitle'),
            step1Message: t('overview.firstDonatePanel.step1Message'),
            step2Message: t('overview.firstDonatePanel.step2Message', {
                orgName,
                city,
            }),
            step3Message: t('overview.firstDonatePanel.step3Message'),
            downloadApp: t('overview.firstDonatePanel.downloadApp'),
        }),
        [t, orgName, city],
    );

    const platform: 'IOS' | 'Android' | 'Neither iOS nor Android' =
        useMemo(() => {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return 'IOS';
            } else if (/Android/i.test(navigator.userAgent)) {
                return 'Android';
            } else {
                return 'Neither iOS nor Android';
            }
        }, []);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    const cardName = 'Onboarding First Donation QR Modal';
    useMemo(() => {
        clevertap?.event.push(`View ${cardName}`);
    }, []);

    const iOSClick = () => {
        clevertap?.event.push(`Click ${cardName} App Store_btn`);
        window.open(webConfig.appStoreUrl);
    };

    const androidClick = () => {
        clevertap?.event.push(`Click ${cardName} Google Play_btn`);
        window.open(webConfig.ggPlayStoreUrl);
    };

    return (
        <Wrapper>
            <Box className="getMobileApp">
                <img
                    alt="Get the Givelify mobile app"
                    className={
                        isSmallScreen ? 'onSmallScreen' : 'onLargeScreen'
                    }
                    src={isSmallScreen ? getMobileApp : getMobileAppByQr}
                />
                <Stack
                    alignItems="center"
                    columnGap={isSmallScreen ? '8px' : '6px'}
                    flexDirection="row"
                    justifyContent="center"
                    marginTop={isSmallScreen ? '-28px' : '-6px'}
                >
                    {platform !== 'Android' ? (
                        <Box>
                            <img
                                alt="Download on the App Store"
                                className="iconAppStore"
                                onClick={iOSClick}
                                src={iconAppStore}
                            />
                        </Box>
                    ) : null}

                    {platform !== 'IOS' ? (
                        <Box>
                            <img
                                alt="Download on Google Play"
                                className="iconPlayStore"
                                onClick={androidClick}
                                src={iconPlayStore}
                            />
                        </Box>
                    ) : null}
                </Stack>
            </Box>

            <Box className="descCard">
                <GivelifyLabel className="title" text={copy.title} />
                {isMediumScreen ? (
                    <GivelifyLabel
                        sx={{
                            marginTop: '4px',
                        }}
                        text={copy.desktopSubTitle}
                        variant="body1"
                    />
                ) : null}

                <Box className="stepContainer">
                    <Step number={1} text={copy.step1Message} />
                    <Step number={2} text={copy.step2Message} />
                    <Step
                        icon={<IconHeart />}
                        number={3}
                        text={copy.step3Message}
                    />
                </Box>
            </Box>

            {isSmallScreen && (platform === 'IOS' || platform === 'Android') ? (
                <GivelifyButton
                    onClick={() => {
                        window.open(
                            platform === 'IOS'
                                ? webConfig.appStoreUrl
                                : webConfig.ggPlayStoreUrl,
                        );
                    }}
                    size="large"
                    sx={{
                        width: 290,
                        height: 44,
                        backgroundColor:
                            DesignTokens.color.globalGenerosityOrange400,
                        '&:hover': {
                            backgroundColor:
                                DesignTokens.color.globalGenerosityOrange400,
                        },
                    }}
                    text={
                        <GivelifyLabel
                            sx={{
                                color: 'white',
                                fontWeight: 800,
                                lineHeight: '24px',
                            }}
                            text={copy.downloadApp}
                            variant="body1"
                        />
                    }
                    variant="danger-secondary"
                />
            ) : null}
        </Wrapper>
    );
};

export default FirstDonationPanelView;
