import React, { useMemo } from 'react';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { IntegrationTypes } from '../../../../../types';
import {
    CCBEnvelopeMatchedData,
    IntegrationPaginatedResponse,
} from '../../types';
import AutoIntegrationInfiniteLoader from '../AutoIntegrationInfiniteLoader';
import { TableLoadingComponent } from '../AutoIntegrationLoading';
import AutoIntegrationNoResultComponent from '../AutoIntegrationNoResultComponent';
import AutoIntegrationEnvelopeMatchedRow from './AutoIntegrationEnvelopeMatchedRow';

interface MatchEnvelopeInfiniteLoaderProps {
    reset: true | undefined;
    onReset: () => unknown;
    url: (pageNumber: number) => string;
    unmatchEnvelopeFunction: (envelopeId: number, pageNumber?: number) => void;
    isReadOnly?: boolean;
    btnLoading?: boolean;
    unmatchStatus?: boolean;
    integration: IntegrationTypes;
    reload?: boolean;
    onReloaded?: () => unknown;
    selectedPageNumber?: number;
}

const MatchEnvelopeInfiniteLoader: React.FCC<
    MatchEnvelopeInfiniteLoaderProps
> = ({
    reset,
    onReset,
    url,
    unmatchEnvelopeFunction,
    isReadOnly,
    btnLoading,
    unmatchStatus,
    integration,
    reload,
    onReloaded,
    selectedPageNumber,
}) => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            errorMatchEnvelope: t(
                'pages.integrations.matchError.errorEnvelope',
            ),
            noMatchEnvelope: t('pages.integrations.matchError.noEnvelope'),
        }),
        [t],
    );
    const Error = (
        <GivelifyLabel
            bold
            margin={16}
            text={copy.errorMatchEnvelope}
            variant="body2"
        />
    );
    const Zeroth = (
        <AutoIntegrationNoResultComponent text={copy.noMatchEnvelope} />
    );

    return (
        <AutoIntegrationInfiniteLoader
            ErrorComponent={Error}
            LoadingComponent={TableLoadingComponent}
            ZerothComponent={Zeroth}
            onReloaded={onReloaded}
            onReset={onReset}
            reload={reload}
            renderData={(
                data: IntegrationPaginatedResponse<CCBEnvelopeMatchedData>,
            ) =>
                data.data.map((envelope, i) => (
                    <AutoIntegrationEnvelopeMatchedRow
                        key={i}
                        btnLoading={btnLoading}
                        campusName={envelope.campus?.name}
                        ccbEnvelope={envelope.match.level0}
                        ccbEnvelopeParent={envelope.match.level1}
                        id={envelope.id}
                        integration={integration}
                        isReadOnly={isReadOnly}
                        name={envelope.name}
                        pageNumber={data.pageNumbers[i]}
                        unmatchEnvelope={unmatchEnvelopeFunction}
                        unmatchStatus={unmatchStatus}
                    />
                ))
            }
            reset={reset}
            selectedPageNumber={selectedPageNumber}
            url={url}
        />
    );
};

export default React.memo(MatchEnvelopeInfiniteLoader);
