import React from 'react';
import { styled, Stack, ClickAwayListener } from '@mui/material';
import { TotalDonationsWidgetWrapper } from 'pages/overview/widgets';
import {
    DESKTOP_DRAWER_WIDTH,
    DESKTOP_HEADER_HEIGHT,
} from 'theme/layoutMeaserments';
import { NavigationProps } from '../types';
import { DesktopNavigationMenu } from './DesktopNavigationMenu';

const DesktopNavigationDrawer = styled('div', {
    shouldForwardProp: (propName) => propName !== 'extraMargin',
})<{
    extraMargin?: number;
}>(({ theme, extraMargin }) => ({
    position: 'fixed',
    top: DESKTOP_HEADER_HEIGHT + extraMargin,
    left: 0,
    bottom: 0,
    width: DESKTOP_DRAWER_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('tablet')]: {
        display: 'none',
    },
    zIndex: theme.zIndex.appBar,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2.25),
}));

const NavBarHolder = styled(Stack, {
    shouldForwardProp: (propName) => propName !== 'extraMargin',
})<{
    extraMargin: number;
}>(({ extraMargin, theme }) => ({
    flex: 1,
    paddingTop: extraMargin > 0 ? theme.spacing(3) : theme.spacing(6),
    overflowY: 'auto',
    '.donations-holder': {
        paddingRight: theme.spacing(1.75),
    },
    [theme.breakpoints.down('tablet')]: {
        paddingTop: theme.spacing(6),
    },
}));

type DesktopNavigationMenuProps = {
    navigationProps?: NavigationProps;
};

export const DesktopNavigationMenuWithDonations: React.FCC<
    DesktopNavigationMenuProps
> = ({ navigationProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const onExpand = React.useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, [setIsExpanded]);
    const handleClickAway = React.useCallback(() => {
        setIsExpanded(false);
    }, [setIsExpanded]);
    const handleItemClick = React.useCallback(
        (link: string, label: string) => {
            setIsExpanded(false);
            navigationProps.onClick(link, label);
        },
        [setIsExpanded, navigationProps],
    );
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <DesktopNavigationDrawer
                extraMargin={
                    navigationProps.notificationPosition === 'content'
                        ? 0
                        : navigationProps.notificationHeight
                }
                id="desktop-nav-drawer"
            >
                <NavBarHolder
                    extraMargin={navigationProps.notificationHeight}
                    id="nav-menu-with-donations"
                    role="navigation"
                >
                    <div className="donations-holder" id="donations-holder">
                        <TotalDonationsWidgetWrapper
                            fullWidth
                            className="total-donations"
                        />
                    </div>
                    <DesktopNavigationMenu
                        {...navigationProps}
                        drawerExpanded={isExpanded}
                        onClick={handleItemClick}
                        onDrawerExpandClick={onExpand}
                    />
                </NavBarHolder>
            </DesktopNavigationDrawer>
        </ClickAwayListener>
    );
};
