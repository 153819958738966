import React, { useCallback, useState } from 'react';
import { GivelifyTextField } from '@givelify/givelify-ui';
import { Theme, createStyles, makeStyles, debounce } from '@material-ui/core';

const DEBOUNCE_INPUT = 500;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 22,
            marginBottom: 24,
            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
                marginBottom: 32,
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        searchFieldStyles: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    }),
);

interface YodleeSearchFieldProps {
    onChange: (term: string) => unknown;
    placeholder: string;
}

const YodleeSearchField: React.FCC<YodleeSearchFieldProps> = ({
    placeholder,
    onChange,
}) => {
    const { root, searchFieldStyles } = useStyles();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const handleDebouncedTerm = debounce(
        (value: string) => onChange(value),
        DEBOUNCE_INPUT,
    );
    const handleSearchTerm = useCallback(
        (_e: unknown, value: string) => {
            setSearchTerm(value);
            handleDebouncedTerm(value);
        },
        [setSearchTerm, handleDebouncedTerm],
    );
    return (
        <GivelifyTextField
            ariaLabel={placeholder}
            classes={{
                root: root,
                input: searchFieldStyles,
                inputWrapper: searchFieldStyles,
            }}
            id="search-bank"
            marginLeft="auto"
            marginRight="auto"
            onChange={handleSearchTerm}
            placeholder={placeholder}
            value={searchTerm}
            variant="search"
            width={511}
        />
    );
};

export default React.memo(YodleeSearchField);
