import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
    GivelifyMenuItem,
} from '@givelify/ui';
import { AccordionSummary } from '@mui/material';
import { useAdvancedTranslation } from 'utils/i18n';
import {
    FilterContent,
    FilterContentList,
    FilterFooter,
    FilterList,
    FilterAccordion,
    FilterAccordionDetails,
    FilterMenuItem,
} from '../components/filterMenuItemStyles';
import { EnvelopesFilterProps } from './types';

export const EnvelopesFilterAccordion: React.FCC<
    EnvelopesFilterProps & {
        expanded: boolean;
        onExpandChange: (
            event: React.SyntheticEvent,
            expanded: boolean,
        ) => void;
    }
> = ({ onChange, values, options, disabled, expanded, onExpandChange }) => {
    const { t, at } = useAdvancedTranslation();
    const copy = React.useMemo(
        () => ({
            fund: at('general.donee.Envelopes'),
            allFund: t('donationsActivity.text.allFund'),
            selectAllEnvelopes: at('donationsActivity.text.selectAllEnvelopes'),
            clearAll: t('donationsActivity.text.clearAll'),
            all: t('donationsActivity.text.all'),
        }),
        [t, at],
    );
    const toggleAll = React.useCallback(() => {
        if (values?.length === options?.length) {
            onChange([]);
            return;
        }
        onChange(options.map((l) => l.id));
    }, [onChange, options, values?.length]);
    const clearAll = React.useCallback(() => {
        onChange([]);
    }, [onChange]);
    const onItemClick = React.useCallback(
        (id: number) => {
            if (values.findIndex((item) => item === id) === -1) {
                onChange([...values, id]);
            } else {
                const newValues = values.filter((opt) => opt !== id);
                onChange(newValues);
            }
        },
        [values, onChange],
    );
    const getFilterText = React.useCallback(() => {
        if (options.length === 0) return copy.fund;
        if (values.length === options.length) {
            return `${copy.fund}: ${copy.all}`;
        }
        return `${copy.fund}: ${values.length}`;
    }, [options.length, copy.fund, copy.all, values.length]);
    const onRenderMenuContent = React.useCallback(() => {
        return (
            <FilterContent id="envelopes-filter-options">
                <FilterList>
                    <FilterMenuItem
                        key="all"
                        id="select-all"
                        indeterminate={
                            values.length > 0 && values.length < options?.length
                        }
                        onClick={toggleAll}
                        selected={values.length === options.length}
                        text={copy.selectAllEnvelopes}
                        variant="checkbox"
                    />
                </FilterList>
                <FilterContentList>
                    {options.map((opt) => (
                        <GivelifyMenuItem
                            key={opt.id}
                            id={`option-${opt.id}`}
                            onClick={() => onItemClick(opt.id)}
                            selected={values.includes(opt.id)}
                            text={opt.label}
                            variant="checkbox"
                        />
                    ))}
                </FilterContentList>
                <FilterFooter>
                    <GivelifyLink
                        fontSize={16}
                        onClick={clearAll}
                        text={copy.clearAll}
                    />
                </FilterFooter>
            </FilterContent>
        );
    }, [
        copy.selectAllEnvelopes,
        copy.clearAll,
        toggleAll,
        values,
        options,
        clearAll,
        onItemClick,
    ]);
    if (!options) return null;
    return (
        <FilterAccordion
            disabled={disabled}
            expanded={expanded}
            onChange={onExpandChange}
        >
            <AccordionSummary
                aria-controls="envelopes-filter-content"
                expandIcon={
                    <GivelifyIcon fontSize={24} variant="down-chevron" />
                }
                id="envelopes-filter"
            >
                <GivelifyLabel text={getFilterText()} />
            </AccordionSummary>
            <FilterAccordionDetails>
                {onRenderMenuContent()}
            </FilterAccordionDetails>
        </FilterAccordion>
    );
};
