import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApiHandler } from '@givelify/api';
import {
    AutocompleteOption,
    GivelifyFullscreenLoading,
    LoaderRequestState,
} from '@givelify/ui';
import { isFailed, isSucceeded, useApiRequest } from '@givelify/utils';
import {
    OrganizationType,
    TaxIdInfo,
} from '../../../../@types/assets/onboarding';
import { Denomination } from '../../../../api/models/Denomination';
import { TaxIdInfoUpdateRequest } from '../../../../api/requests/donee/TaxIdInfoUpdateRequest';
import { postDenomination } from '../../../../api/services/denomination/DenominationService';
import {
    TaxIdDetails,
    getTaxIdDetails,
} from '../../../../api/services/taxId/TaxIdInfoService';
import CancelModal from '../../components/CancelModal';
import OnboardingTopSection from '../../OnboardingTopSection';
import { UseFilePickerProps, useFilePicker } from '../../useFilePicker';
import { RequiredTaxIdInfoProps } from '../types';
import ConfirmOrgInfo from './ConfirmOrgInfo';
import Ein from './ein';
import OrganizationInfo from './organizationInfo';
import SelectDocumentTypeChurch from './SelectDocumentType/church';
import SelectDocumentTypeNonprofit from './SelectDocumentType/nonprofit';
import Summary from './summary';
import { TaxIdInfoBreadcrumbs } from './TaxIdInfoBreadcrumbs';
import UploadDocumentChurch from './UploadDocument/church';
import UploadDocumentNonprofit from './UploadDocument/nonprofit';

export type CurrentPage =
    | 'taxId'
    | 'orgInfo'
    | 'confirmOrgInfo'
    | 'yearFounded'
    | 'selectDocumentType'
    | 'uploadDocument'
    | 'summary';

export type DocumentType =
    | 'confirmationLetter'
    | 'bankStatement'
    | '990Form'
    | 'determinationLetter'
    | 'dbaDocument';

const getEmptyTaxIdDetails = (taxId?: string): TaxIdDetails => ({
    address: '-',
    ein: taxId ?? '-',
    city: '',
    name: '-',
    state: '',
    zip: '',
});

interface TaxIdContextProps {
    taxIdInfo: TaxIdInfo;
    taxIdDetails: TaxIdDetails;
    denominationOptions: AutocompleteOption[];
    onBackClick: () => void;
    onCancelClick: (skipModal?: boolean) => void;
    organizationType: OrganizationType;
    onTaxIdContinue: (cleanTaxId: string) => Promise<void>;
    onOrgInfoContinue: (
        year: number,
        religiousAffiliation?: string,
        congregationSize?: string,
    ) => void;
    onSelectDocumentContinue: (documentType: any) => void;
    onUploadDocumentContinue: () => void;
    onConfirmOrgInfoContinue: (orgInfoAccurate: boolean) => void;
    onYearFoundedContinue: () => void;
    onSummaryContinue: () => void;
    onSummaryLinkNavigate: (page: CurrentPage) => void;
    selectedDocumentType?: DocumentType;
    retryMessage?: string | null;
    filePickerProps: UseFilePickerProps;
    isLoading: boolean;
    requireAdditionalInfo?: boolean;
    informationIsAccurate?: boolean;
    uploadFileInsteadLinkClick: () => void;
    nonProfitHappyPathFileUpload: boolean;
    loadingMessage?: string;
    loadingSuccessMessage?: string;
    documentUploadMessage?: string;
    documentUploadSuccess?: string;
}

const NOT_INITIALIZED = () => {
    throw new Error('Context not initialized');
};

const TaxIdContext = createContext<TaxIdContextProps>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    taxIdInfo: undefined,
    taxIdDetails: getEmptyTaxIdDetails(),
    denominationOptions: [],
    onBackClick: NOT_INITIALIZED,
    onCancelClick: NOT_INITIALIZED,
    onTaxIdContinue: NOT_INITIALIZED,
    onOrgInfoContinue: NOT_INITIALIZED,
    onSelectDocumentContinue: NOT_INITIALIZED,
    onUploadDocumentContinue: NOT_INITIALIZED,
    onConfirmOrgInfoContinue: NOT_INITIALIZED,
    onYearFoundedContinue: NOT_INITIALIZED,
    onSummaryContinue: NOT_INITIALIZED,
    onSummaryLinkNavigate: NOT_INITIALIZED,
    organizationType: 'church',
    selectedDocumentType: undefined,
    retryMessage: undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    filePickerProps: undefined,
    isLoading: false,
    requireAdditionalInfo: undefined,
    informationIsAccurate: true,
    uploadFileInsteadLinkClick: NOT_INITIALIZED,
    nonProfitHappyPathFileUpload: false,
    loadingMessage: undefined,
    loadingSuccessMessage: undefined,
    documentUploadMessage: undefined,
    documentUploadSuccess: undefined,
});

export const UseTaxIdContext = () => useContext(TaxIdContext);

const TaxIdContextProvider: React.FCC<RequiredTaxIdInfoProps> = ({
    organizationType,
    onClose,
    getDenominationsRequestState,
    data,
    requireAdditionalInfo,
    doneeId,
    onSubmit,
    loadingMessage,
    loadingSuccessMessage,
    documentUploadMessage,
    documentUploadSuccess,
}) => {
    const [taxIdInfo, setTaxIdInfo] = useState(data);
    const [currentPage, setCurrentPage] = useState<CurrentPage>(
        requireAdditionalInfo ? 'selectDocumentType' : 'taxId',
    );
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] =
        useState<LoaderRequestState>('REQUEST_INIT');
    const [summaryReached, setSummaryReached] = useState<boolean>(false);

    const [informationIsAccurate, setInformationIsAccurate] = useState<
        boolean | undefined
    >();

    const [selectedDocumentType, setSelectedDocumentType] =
        useState<DocumentType>();

    const [customDenomination, setCustomDenomination] =
        useState<Denomination | null>(null);

    const [postDenominationRequest, makePostDenominationRequset] =
        useApiRequest<{ data: Denomination }>();

    const filePicker = useFilePicker();

    const [routesStack, setRoutesStack] = useState<CurrentPage[]>([]);
    useEffect(() => {
        setRoutesStack((prev) => [...prev, currentPage]);
    }, [currentPage]);

    const [taxIdDetails, setTaxIdDetails] = useState<TaxIdDetails>(
        getEmptyTaxIdDetails(),
    );

    const uploadFileInsteadLinkClick = () => {
        setTaxIdDetails(getEmptyTaxIdDetails());
        setCurrentPage('selectDocumentType');
    };

    const nonProfitHappyPathFileUpload = useMemo(
        () => !informationIsAccurate || taxIdDetails?.ein === '-',
        [informationIsAccurate, taxIdDetails?.ein],
    );

    useEffect(() => {
        setSummaryReached(false);
    }, [nonProfitHappyPathFileUpload]);

    const onBreadcrumbsClick = (
        step: 'ein' | 'organizationInfo' | 'yearFounded' | 'summary',
    ) => {
        let targetPage: CurrentPage | undefined;

        if (step === 'ein') {
            if (organizationType === 'church') {
                targetPage = 'taxId';
            } else {
                targetPage = nonProfitHappyPathFileUpload
                    ? 'selectDocumentType'
                    : 'taxId';
            }
        } else if (step === 'organizationInfo') {
            targetPage = 'orgInfo';
        } else if (step === 'yearFounded') {
            targetPage = 'yearFounded';
        } else if (step === 'summary') {
            targetPage = 'summary';
        }

        const targetPageRoutesIndex = routesStack.findIndex(
            (r) => r === targetPage,
        );
        if (targetPage) {
            setCurrentPage(targetPage);
            setRoutesStack(routesStack.slice(0, targetPageRoutesIndex));
        }
    };

    useEffect(() => {
        if (currentPage === 'summary') {
            setSummaryReached(true);
        }
    }, [currentPage]);

    const denominationOptions = useMemo(() => {
        const loaded =
            getDenominationsRequestState.type === 'REQUEST_SUCCESS' &&
            organizationType === 'church';

        if (!loaded) {
            return [];
        }

        const options: AutocompleteOption[] =
            getDenominationsRequestState.response.data.map((item) => {
                return {
                    value: item.id.toString(),
                    label: item.name,
                };
            });
        return options;
    }, [getDenominationsRequestState, organizationType]);

    const onTaxIdContinue = async (cleanTaxId: string) => {
        setTaxIdInfo((prev) => ({
            ...prev,
            taxId: cleanTaxId,
        }));

        if (organizationType === 'church') {
            setCurrentPage(summaryReached ? 'summary' : 'orgInfo');
        } else {
            const result = await getTaxIdDetails(cleanTaxId);
            if (!result.success) {
                setTaxIdDetails(getEmptyTaxIdDetails());
                setCurrentPage('selectDocumentType');
                setIsLoading(false);
                return;
            }
            setTaxIdDetails(result.response);
            setCurrentPage('confirmOrgInfo');
        }
    };

    const checkReligiousAffiliation = (religiousAffiliation: string) => {
        const affiliation = denominationOptions.find(
            (item) =>
                item.label.toLowerCase() ===
                religiousAffiliation?.toLowerCase(),
        );

        if (!affiliation) {
            makePostDenominationRequset(postDenomination(religiousAffiliation));
        } else {
            setCustomDenomination(null);
        }
    };

    useEffect(() => {
        if (isSucceeded(postDenominationRequest)) {
            setCustomDenomination(postDenominationRequest.response.data);
            return;
        }

        if (isFailed(postDenominationRequest)) {
            setCustomDenomination(null);
            return;
        }
    }, [postDenominationRequest]);

    const onOrgInfoContinue = (
        year: number,
        religiousAffiliation?: string,
        congregationSize?: string,
    ) => {
        if (religiousAffiliation) {
            checkReligiousAffiliation(religiousAffiliation);
        }

        setTaxIdInfo((prev) => ({
            ...prev,
            organizationYear: year?.toString(),
            religiousAffiliation: religiousAffiliation,
            congregationSize,
        }));
        setCurrentPage('summary');
    };

    const onSelectDocumentContinue = (documentType: DocumentType) => {
        setSelectedDocumentType(documentType);
        setCurrentPage('uploadDocument');
    };

    const onConfirmOrgInfoContinue = (orgInfoAccurate: boolean) => {
        setInformationIsAccurate(orgInfoAccurate);
        if (orgInfoAccurate) {
            setCurrentPage(summaryReached ? 'summary' : 'yearFounded');
        } else {
            setCurrentPage('selectDocumentType');
        }
    };

    const onYearFoundedContinue = () => {
        setCurrentPage('summary');
    };

    const [showCancelModal, setShowCancelModal] = useState(false);
    const onCancelClick = (skipModal?: boolean) => {
        if (skipModal) {
            onClose();
        } else {
            setShowCancelModal(true);
        }
    };
    const onCancelSubmit = () => onClose();
    const onCancelClose = () => setShowCancelModal(false);

    const onBackClick = () => {
        const cloneArray = [...routesStack];
        cloneArray.pop(); //current route
        const prevRoute = cloneArray.pop();

        if (!prevRoute) {
            onCancelClick(false);
            return;
        }
        setRoutesStack(cloneArray);
        setCurrentPage(prevRoute);
    };

    const onSummaryLinkNavigate = (targetPage: CurrentPage) => {
        const targetPageRoutesIndex = routesStack.findIndex(
            (r) => r === targetPage,
        );

        setCurrentPage(targetPage);
        setRoutesStack(routesStack.slice(0, targetPageRoutesIndex));
    };

    const getPostData = (): TaxIdInfoUpdateRequest => {
        let payload: TaxIdInfoUpdateRequest;
        if (requireAdditionalInfo) {
            payload = {
                einFile: filePicker.uploadedFile,
            };
        } else if (organizationType === 'church') {
            const denominationId = customDenomination
                ? customDenomination.id
                : denominationOptions.find(
                      (item) =>
                          item.label.toLowerCase() ===
                          taxIdInfo.religiousAffiliation?.toLowerCase(),
                  )?.value;

            payload = {
                ein: taxIdInfo.taxId,
                congregationSize: taxIdInfo.congregationSize,
                denominationId: denominationId ? +denominationId : undefined,
                established: parseInt(taxIdInfo.organizationYear),
                einFile: undefined,
            };
        } else if (nonProfitHappyPathFileUpload) {
            payload = {
                established: parseInt(taxIdInfo.organizationYear),
                einFile: filePicker.uploadedFile,
            };
        } else {
            payload = {
                ein: taxIdInfo.taxId,
                established: parseInt(taxIdInfo.organizationYear),
                einFile: undefined,
            };
        }

        return payload;
    };

    const postData = async () => {
        setLoadingState('REQUEST_START');
        setIsLoading(true);
        const payload = getPostData();
        const result = await ApiHandler.instance.donees.updateTaxId(
            doneeId,
            payload,
        );
        setLoadingState(result.success ? 'REQUEST_SUCCESS' : 'REQUEST_ERROR');
    };

    const onComplete = (error?: boolean) => {
        const payload = getPostData();

        if (error) {
            setIsLoading(false);
        } else {
            onSubmit({
                ...data,
                ...payload,
                status: 'in_progress',
            });
        }

        setLoadingState('REQUEST_INIT');
    };

    const onSummaryContinue = postData;

    const onUploadDocumentContinue = async () => {
        if (!requireAdditionalInfo) {
            setCurrentPage(summaryReached ? 'summary' : 'yearFounded');
        } else {
            await postData();
        }
    };

    return (
        <TaxIdContext.Provider
            value={{
                taxIdInfo,
                taxIdDetails,
                denominationOptions,
                onBackClick,
                onCancelClick,
                organizationType,
                onConfirmOrgInfoContinue,
                onOrgInfoContinue,
                onSelectDocumentContinue,
                onTaxIdContinue,
                onUploadDocumentContinue,
                onYearFoundedContinue,
                onSummaryContinue,
                selectedDocumentType,
                retryMessage: taxIdInfo?.retryMessage,
                filePickerProps: filePicker,
                isLoading,
                requireAdditionalInfo,
                informationIsAccurate,
                onSummaryLinkNavigate,
                uploadFileInsteadLinkClick,
                nonProfitHappyPathFileUpload,
                loadingMessage,
                loadingSuccessMessage,
            }}
        >
            {!requireAdditionalInfo && (
                <OnboardingTopSection>
                    <TaxIdInfoBreadcrumbs
                        active={
                            currentPage === 'taxId' ||
                            currentPage === 'confirmOrgInfo' ||
                            currentPage === 'selectDocumentType' ||
                            currentPage === 'uploadDocument'
                                ? 'ein'
                                : currentPage === 'orgInfo' ||
                                  currentPage === 'yearFounded'
                                ? 'organizationInfo'
                                : 'summary'
                        }
                        disableNavigation={!summaryReached}
                        onClick={onBreadcrumbsClick}
                        orgInfoCompleted={!!taxIdInfo.organizationYear}
                        organizationType={organizationType}
                        summaryCompleted={summaryReached}
                        taxIdCompleted={!!taxIdInfo.taxId}
                    />
                </OnboardingTopSection>
            )}
            {currentPage === 'taxId' && (
                <Ein organizationType={organizationType} />
            )}
            {currentPage === 'orgInfo' && <OrganizationInfo />}
            {currentPage === 'confirmOrgInfo' && <ConfirmOrgInfo />}
            {currentPage === 'yearFounded' && <OrganizationInfo />}
            {currentPage === 'selectDocumentType' &&
                organizationType === 'church' && <SelectDocumentTypeChurch />}
            {currentPage === 'selectDocumentType' &&
                organizationType === 'nonprofit' && (
                    <SelectDocumentTypeNonprofit />
                )}
            {currentPage === 'uploadDocument' &&
                organizationType === 'church' && <UploadDocumentChurch />}
            {currentPage === 'uploadDocument' &&
                organizationType === 'nonprofit' && <UploadDocumentNonprofit />}
            {currentPage === 'summary' && <Summary />}
            {showCancelModal && (
                <CancelModal
                    onCancel={onCancelClose}
                    onSubmit={onCancelSubmit}
                    page="taxId"
                />
            )}
            <GivelifyFullscreenLoading
                note={
                    currentPage === 'uploadDocument'
                        ? documentUploadMessage
                        : loadingMessage
                }
                onComplete={onComplete}
                requestState={loadingState}
                successMessage={
                    currentPage === 'uploadDocument'
                        ? documentUploadSuccess
                        : loadingSuccessMessage
                }
                successTimeout={3000}
                timeout={4000}
            />
        </TaxIdContext.Provider>
    );
};

export default TaxIdContextProvider;
