import { ErrorResponse } from '@givelify/utils';
import { EnvelopeDistribution } from '../types';

export const SET_ENVELOPES_CURRENT_LOADING = 'SET_ENVELOPES_CURRENT_LOADING';
export const SET_ENVELOPES_CURRENT_SUCCESS = 'SET_ENVELOPES_CURRENT_SUCCESS';
export const SET_ENVELOPES_CURRENT_FAILED = 'SET_ENVELOPES_CURRENT_FAILED';

export interface SetEnvelopesCurrentLoading {
    type: typeof SET_ENVELOPES_CURRENT_LOADING;
}

export interface SetEnvelopesCurrentSuccess {
    type: typeof SET_ENVELOPES_CURRENT_SUCCESS;
    data: {
        data: EnvelopeDistribution[];
    };
}

export interface SetEnvelopesCurrentFailed {
    type: typeof SET_ENVELOPES_CURRENT_FAILED;
    error: ErrorResponse;
}
