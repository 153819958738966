import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { STATES_DICTIONARY } from '@givelify/givelify-ui';
import { ErrorResponse, requestError, requestSuccess } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import { useWebSocket } from 'hooks/useWebSocket';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { webConfig } from 'webConfig';
import {
    Coordinates,
    LocationSelected,
    OrganizationSuggestionsResponse,
} from './types';
import View from './view';

const MAXIMUM_ORGANIZATION_RESULT = 40;

const getData = (
    orgName: string,
    location: LocationSelected,
    coordinates: Coordinates | undefined,
): string => {
    const { city, state, zip, userTypedValue } = location;
    return JSON.stringify({
        term: !city && !zip ? orgName + ' ' + userTypedValue : orgName,
        city,
        state,
        zip,
        filter: 'all',
        location: coordinates
            ? `${coordinates.latitude},${coordinates.longitude}`
            : undefined,
        accuracy: coordinates?.accuracy,
    });
};

interface SearchFormProps {
    onSelect: (item: OrganizationSuggestionsResponse) => void;
}

const SearchForm: React.FCC<SearchFormProps> = ({ onSelect }) => {
    const { donee } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
    }));

    const doneeStateFullName = useMemo(
        () => STATES_DICTIONARY.find((s) => s.key === donee.state).value,
        [donee.state],
    );

    const [orgName, setOrgName] = useState<string>(donee.name);
    const [location, setLocation] = useState<LocationSelected>({
        city: donee.city,
        state: donee.state,
        userTypedValue: `${donee.city}, ${doneeStateFullName}`,
        zip: '',
    });

    const [connectionState, suggestionsRequestState, makeSuggestionRequest] =
        useWebSocket<OrganizationSuggestionsResponse[]>(
            `${webConfig.searchEngineWsUrl}/echo?token={"token":"${webConfig.searchEngineWsToken}"}`,
            (response, setRequestState) => {
                const data = JSON.parse(response.data);
                if (data.status !== 'success') {
                    const errorResponse: ErrorResponse = {
                        isAxiosError: false,
                        message: 'Error loading suggestion',
                        name: 'name',
                        status: 500,
                    };
                    setRequestState(requestError(errorResponse));
                    return;
                }
                const results = toCamelCase(
                    data.message.hits.slice(0, MAXIMUM_ORGANIZATION_RESULT),
                    {
                        deep: true,
                    },
                ) as unknown as OrganizationSuggestionsResponse[];
                setRequestState(requestSuccess(results));
            },
        );

    const onSearchClick = useCallback(() => {
        const data = getData(orgName, location, undefined);
        makeSuggestionRequest(data);
    }, [location, orgName, makeSuggestionRequest]);

    useEffect(() => {
        if (connectionState === WebSocket.OPEN) {
            onSearchClick();
        }
    }, [connectionState, onSearchClick]);

    return (
        <View
            location={location}
            onSearchClick={onSearchClick}
            onSelect={onSelect}
            orgName={orgName}
            setLocation={setLocation}
            setOrgName={setOrgName}
            suggestionsRequestState={suggestionsRequestState}
        />
    );
};

export default SearchForm;
