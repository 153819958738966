import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

const InfoRibon = styled('div')({
    padding: '12px',
    width: '100%',
    background: DesignTokens.color.backgroundButtonSecondaryDefault,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    overflow: 'hidden',
    display: 'flex',
    maxWidth: '530px',
});

const InfoRibonSpan = styled('span')({
    textDecorationLine: 'underline',
    textDecorationColor: DesignTokens.color.chartMint200LegacyGreen100,
    textDecorationThickness: '2px',
});

const InfoRibonA = styled('a')({
    color: DesignTokens.color.textAccentDefault,
});

export type ChartInfoRibonProps = {
    accent: string;
    content: string;
    linkText: string;
    link?: string;
    onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const ChartInfoRibonComponent: React.FCC<ChartInfoRibonProps> = ({
    accent,
    content,
    link,
    linkText,
    onLinkClick,
}) => {
    return (
        <InfoRibon>
            <GivelifyLabel variant="body2">
                <InfoRibonSpan>{accent}</InfoRibonSpan>
                {content}
                <InfoRibonA href={link} onClick={onLinkClick}>
                    {linkText}
                </InfoRibonA>
            </GivelifyLabel>
        </InfoRibon>
    );
};

export const ChartInfoRibon = React.memo(ChartInfoRibonComponent);
