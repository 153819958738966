import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { Collapse, Stack, styled } from '@mui/material';
import { LegacyDashboardLink } from 'layout/components/LegacyDashboardLink';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { MenuItem } from 'store/system/types';
import { enableOldDahsboard } from 'utils/enableOldDahsboard';
import { MenuSeparator } from '../components';
import { NavMenuItem } from '../components/NavMenuItem';

const DesktopMenuRoot = styled('div')(({ theme }) => ({
    flex: 1,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(4),
    '&:hover': {
        scrollbarWidth: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: DesignTokens.color.backgroundPrimary,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: DesignTokens.color.backgroundComponentQuaternary,
        },
    },
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        backgroundColor: DesignTokens.color.backgroundPrimary,
    },
    overflowY: 'scroll',
}));

const DesktopMenuList = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const DesktopMenuFooter = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(5.5),
    marginBottom: theme.spacing(5),
}));

type DesktopNavigationMenuProps = {
    items: MenuItem[];
    onClick: (link: string, label: string) => void;
    onExpandClick: (id: string) => void;
    drawerExpanded?: boolean;
    onDrawerExpandClick?: () => void;
};

export const DesktopNavigationMenu: React.FCC<DesktopNavigationMenuProps> = ({
    items,
    onClick,
    onExpandClick,
}) => {
    const campuses = useSelector((state: AppState) => state.Donee.campuses);
    const hasOldDashboard = React.useMemo(() => {
        return enableOldDahsboard(campuses);
    }, [campuses]);

    return (
        <DesktopMenuRoot id="desktop-nav-menu">
            <DesktopMenuList id="desktop-nav-menu-list">
                {items.map((item, index) => (
                    <div key={`nav-item-${index}`} className="nav-item-holder">
                        <NavMenuItem
                            key={`nav-item-${item.label}`}
                            item={item}
                            onClick={onClick}
                            onExpandClick={onExpandClick}
                        />
                        {item.hasDivider ? <MenuSeparator /> : null}
                        {item.nested ? (
                            <Collapse
                                unmountOnExit
                                in={item.isExpanded}
                                timeout="auto"
                            >
                                <Stack paddingTop={1} spacing={1}>
                                    {item.nested.map((subItem, subIndex) => (
                                        <NavMenuItem
                                            key={`nav-item-${index}-${subIndex}`}
                                            item={subItem}
                                            onClick={onClick}
                                            onExpandClick={onExpandClick}
                                        />
                                    ))}
                                </Stack>
                            </Collapse>
                        ) : null}
                    </div>
                ))}
            </DesktopMenuList>
            <DesktopMenuFooter id="desktop-nav-menu-footer">
                {hasOldDashboard ? <LegacyDashboardLink /> : null}
            </DesktopMenuFooter>
        </DesktopMenuRoot>
    );
};
