import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GivelifyDropdown, GivelifyDropdownProps } from '../dropdown';
import { OmitFields } from './types';

export type GivelifyFormDropDownProps<Form = Record<string, unknown>> = Omit<
    GivelifyDropdownProps,
    OmitFields
> & {
    name: keyof Form;
};

export const GivelifyFormDropDown = <Form,>(
    props: GivelifyFormDropDownProps<Form>,
) => {
    const { control, errors } = useFormContext();
    const error = errors[props.name] as { message: string };

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ name, onBlur, onChange, value, ref }, { invalid }) => {
                return (
                    <GivelifyDropdown
                        {...props}
                        inputRef={ref}
                        label={props.label}
                        leftHelperText={
                            invalid ? error?.message : props.leftHelperText
                        }
                        name={name}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event);
                            }
                            onBlur();
                        }}
                        onChange={(ev) => onChange(ev.target.value)}
                        state={invalid ? 'error' : 'idle'}
                        value={value || ''}
                    />
                );
            }}
        />
    );
};
