import React, { useMemo } from 'react';
import { GivelifyButton } from '@givelify/ui';
import { useTranslation } from 'react-i18next';

import { SubmitButton, SubmitButtonsHolder } from './style';

interface EditorButtonsProps {
    onCancel: () => void;
    onSubmit?: () => void; // just for temporary. migrating into react-hook-form.
    saving?: boolean;
}

const EditorButtons: React.FCC<EditorButtonsProps> = (
    props: EditorButtonsProps,
) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    return (
        <SubmitButtonsHolder>
            <GivelifyButton
                data-testid="cancel-button"
                disabled={props.saving}
                name="cancel"
                onClick={props.onCancel}
                text={copy.cancel}
                variant="secondary"
            />
            <SubmitButton
                data-testid="save-button"
                isLoading={props.saving}
                name="save"
                text={copy.save}
                type="submit"
                variant="primary"
            />
        </SubmitButtonsHolder>
    );
};

export default EditorButtons;
