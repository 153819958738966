import { useCallback, useMemo } from 'react';
import { GivelifyForm } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { I18N_NAMESPACE } from '../../../../../consts';
import {
    ValidateTaxId,
    ValidationErrors,
} from '../../../../../utils/validation';
import { UseTaxIdContext } from '../TaxIdContext';
import { EinProps, FormSchema } from './types';
import View from './view';

const Ein: React.FCC<EinProps> = ({ organizationType }) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const scopedTranslateValidation = useCallback(
        (key: string, args = undefined) =>
            t(`taxIdPage.validation.${key}`, args),
        [t],
    );

    const copy = useMemo(
        () => ({
            taxIdLength:
                organizationType === 'church'
                    ? scopedTranslateValidation('length')
                    : scopedTranslateValidation('lengthEIN'),
            taxIdConsecutive:
                organizationType === 'church'
                    ? scopedTranslateValidation('consecutive')
                    : scopedTranslateValidation('consecutiveEIN'),
        }),
        [scopedTranslateValidation, organizationType],
    );

    const schema: yup.SchemaOf<FormSchema> = yup.object().shape({
        taxId: yup
            .string()
            .required(copy.taxIdLength)
            .test(
                'tax-id-consecutive',
                copy.taxIdConsecutive,
                (value) =>
                    ValidateTaxId(value || '').errors?.taxId !==
                    ValidationErrors.CONSECUTIVE,
            )
            .test(
                'tax-id-length',
                copy.taxIdLength,
                (value) =>
                    ValidateTaxId(value || '').errors?.taxId !==
                    ValidationErrors.LENGTH,
            ),
    });

    const { onTaxIdContinue, taxIdInfo } = UseTaxIdContext();

    return (
        <GivelifyForm<FormSchema>
            key="default"
            defaultValues={{
                taxId: taxIdInfo.taxId || '',
            }}
            onSubmit={({ taxId }, form) => {
                return onTaxIdContinue(taxId.replace('-', ''));
            }}
            schema={schema}
        >
            <View />
        </GivelifyForm>
    );
};

export default Ein;
