import { GivelifyColorPalette, GasDefaultPalette } from '@givelify/givelify-ui';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const colorsList = GasDefaultPalette.palette.graph.colors;
export const envelopeDonutColorsList =
    GasDefaultPalette.palette.graph.envelopeDonutColors;

export const selectorIconStyles = makeStyles((theme: Theme) => {
    return {
        selector: {
            '& .MuiSelect-icon': {
                marginRight: theme.spacing(3),
                fontSize: '12px',
                top: 'calc(50% - 6px)',
            },
        },
        timeFrameSelector: {
            padding: theme.spacing(2, 0),
        },
    };
});

// TODO: Remove that
export const greyBorder = '1px solid #ccc';

// TODO: those styles are used everywhere, we need to fix that
export const lifeTimeTotalStyles = makeStyles((theme: Theme) => {
    return createStyles({
        box: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            display: 'inline-flex',
            alignItems: 'center',
            height: 38,
            padding: theme.spacing(2, 4, 2, 5),
            borderRadius: '0 5px 5px 0',
            backgroundImage: 'url(/banner-shadow-skewed.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '-13px center',
            position: 'relative',
            margin: theme.spacing(3, 0, 3, 0),
            [theme.breakpoints.down('xs')]: {
                backgroundImage: 'url(/banner-shadow.png)',
                backgroundPosition: '-4px center',
                padding: theme.spacing(2, 3, 2, 3),
                width: '100%',
            },
        },
        boxDisabled: {
            backgroundColor: theme.colors.neutralGrey75,
        },
        totalTitle: {
            fontWeight: 'bold',
        },
        total: {
            fontSize: theme.typography.h5.fontSize,
            fontWeight: 'lighter',
            marginLeft: theme.spacing(3),
        },
        timeFrameOptions: {
            padding: theme.spacing(2, 0),
        },
        menuItem: {
            padding: '11px 16px 11px 0',
            fontSize: 14,
            lineHeight: '22px',
            color: GivelifyColorPalette.primaryDarkGray,
            '&:hover': {
                backgroundColor: `${GivelifyColorPalette.neutralHoverGrey} !important`,
            },
            '&.Mui-selected': {
                backgroundColor: 'transparent !important',
            },
            '& .markSelected': {
                width: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        textField: {
            '& svg path': {
                fill: theme.palette.primary.main,
            },
            '& span': {
                color: theme.palette.primary.main,
                fontSize: theme.typography.h6.fontSize,
                letterSpacing: '-0.9px',
            },
        },
        dashboardTextField: {
            '& *, &:focus *, &:active *': {
                paddingBottom: 0,
                marginLeft: 10,
            },
        },
        alignSelectorLeftOnLargeScreens: {
            maxWidth: 'none',
            [theme.breakpoints.up('lg')]: {
                position: 'relative',
                left: '-77px',
                top: `2px`,
            },
        },
    });
});
