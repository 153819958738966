import { GivelifyFormDropDown } from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const AddressEditorForm = styled('form')({
    width: '100%',
    marginTop: 16,
});

export const EditorInputContainer = styled(Grid)({
    width: '100%',
    marginBottom: 8,
    marginTop: 8,
});

export const StateSelector = styled(GivelifyFormDropDown)({
    '& input': {
        height: 16,
        padding: '15px 16px',
    },
}) as typeof GivelifyFormDropDown;

export const AddressContent = styled('div')({
    marginTop: 24,
});

export const MailValues = styled('div')({
    marginTop: 16,
    '& > div': {
        marginTop: 16,
    },
});
