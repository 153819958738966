import { DesignTokens } from '@givelify/ui';
import { Avatar, AvatarGroup, GlobalStyles, styled } from '@mui/material';
import { CurrentPage } from './types';

export const LoaderWrapper = styled('div')(() => ({
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    textAlign: 'center',
    zIndex: -1,
}));

export const Divider = styled('div')(() => ({
    height: 1,
    backgroundColor: DesignTokens.color.dividerPrimaryLight,
    marginTop: 4,
    marginBottom: 4,
    gridColumn: 'span 2',
}));

export const CalendlyAvatarGroup = styled(AvatarGroup)(() => ({
    justifyContent: 'center',
    marginBottom: 16,
}));

export const CalendlyAvatar = styled(Avatar)(() => ({
    width: 80,
    height: 80,
}));

export const ConfirmationWrapper = styled('div')(() => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    rowGap: 12,
    columnGap: 8,
}));

const CALENDLY_MOBILE_LAYOUT_BREAKPOINT_PX = 1250;

const MOBILE_MEDIA_QUERY = `@media screen and (max-width: ${CALENDLY_MOBILE_LAYOUT_BREAKPOINT_PX}px)`;
const DESKTOP_MEDIA_QUERY = `@media screen and (min-width: ${CALENDLY_MOBILE_LAYOUT_BREAKPOINT_PX}px)`;

export const CalendlyGlobalStyles: React.FCC<{ currentPage: CurrentPage }> = ({
    currentPage,
}) => (
    <GlobalStyles
        styles={() => ({
            '.calendly-popup': {
                [MOBILE_MEDIA_QUERY]: {
                    maxHeight: 'none !important',
                },
                [DESKTOP_MEDIA_QUERY]: {
                    maxHeight:
                        currentPage === 'ScheduleEvent' && 'none !important',
                },
            },
        })}
    />
);

export const CalendlyBackdrop = styled('div')(() => ({
    backgroundColor: 'rgba(31,31,31,0.4)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}));
