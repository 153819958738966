import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    resetPasswordContainer: {
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowX: 'hidden',
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            alignContent: 'flex-start',
        },
    },
    background: {
        width: '100%',
        maxWidth: 1200,
        margin: '0 auto',
        position: 'relative',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        '& .image': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            zIndex: -1,
        },
        [theme.breakpoints.down('lg')]: {
            paddingTop: theme.spacing(4),
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
    },
    logo: {
        width: 120,
        [theme.breakpoints.down('sm')]: {
            width: 156,
        },
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        maxWidth: 1200,
        margin: '0 auto',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(8),
        backgroundColor: theme.palette.common.white,
        overflowY: 'auto',
        '& footer': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            '& .copy': {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                '& span': {
                    whiteSpace: 'nowrap',
                    paddingRight: 10,
                },
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    flexDirection: 'column-reverse',
                    alignItems: 'center',
                    '& a': {
                        display: 'none',
                    },
                },
            },
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderTopRightRadius: 14,
            borderTopLeftRadius: 14,
            marginTop: -14,
            zIndex: 5,
        },
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 13%',
        boxShadow: '0 0 4px rgba(48, 51, 52, 0.2)',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            flexWrap: 'wrap',
            boxShadow: 'none',
        },
        '& label': {
            top: 17,
            left: 12,
            paddingLeft: 5,
            paddingRight: 5,
            background: '#fff',
            zIndex: '10',
            '& + div': {
                marginTop: 0,
            },
        },
        '& input': {
            background: '#fff',
            borderColor: '#ADADAD',
            height: 48,
            boxSizing: 'border-box',
        },
    },
    resetContent: {
        width: '100%',
        maxWidth: 512,
        margin: '0 auto',
    },
    resetTitle: {
        fontWeight: 'lighter',
        color: '#494A50',
    },
}));
