import React from 'react';
import { RequestState } from '@givelify/utils';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const errorMessageStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: theme.palette.error.main,
            fontWeight: 'bold',
        },
    }),
);

interface HandleServerErrorProps {
    requestState: RequestState<unknown>;
    className?: string;
    //eslint-disable-next-line
    style?: object;
}

const HandleServerErrorText: React.FCC<HandleServerErrorProps> = ({
    requestState,
    className,
    style,
}) => {
    const { t } = useTranslation();
    const { errorMessage } = errorMessageStyles();
    if (requestState.type !== 'REQUEST_ERROR') return null;
    return (
        <div className={`${errorMessage} ${className}`} style={style}>
            {requestState.error.message || t('error.status.500')}
        </div>
    );
};

export default React.memo(HandleServerErrorText);
