import React, { useMemo, useState } from 'react';
import { Donee } from '@givelify/api';
import {
    GivelifyTable,
    GivelifyLabel,
    GivelifyButton,
    SortDirection,
    GivelifyTableColumn,
    GivelifyPaper,
} from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import { MenuItem, MenuList, Paper, Popover } from '@material-ui/core';
import permissionTypes from 'constants/permissionTypes';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { resendInviteEmail } from 'store/donee/thunks';
import { Officer } from 'store/settings/officers/types';
import { editUser } from 'store/user/thunks';
import permissionsByPath from 'utils/permissionsByPath';
import { validateEmailAddress } from 'utils/strings/validations';
import RoleTypes from '../../../constants/roleTypes';
import roleTypes from '../../../constants/roleTypes';
import ResendInvitationModal from './components/InvitationModals/ResendInvitationModal';
import SendNewInvitationModal from './components/InvitationModals/SendNewInvitationModal';
import InviteToGivelifyModal from './components/InviteToGivelifyModal/InviteToGivelifyModal';
import { usersTableStyle } from './usersTableStyle';

export interface UsersTableProps {
    users: Officer[];
    sortDirection: SortDirection;
    onSortClick: (newSortDirection: SortDirection) => void;
    handleUserActionClick: (user) => (event) => void;
    actionPopoverOpen: boolean;
    actionAnchorPosition: { top: number; left: number } | null;
    handleActionPopoverClose: () => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onMakeFaithLeaderClick: () => void;
    onChangeAccountOwnerClick: () => void;
    userRole: string;
    userId: number;
    isOwnUser: boolean;
    donee: Donee;
    isFaithLeader: boolean;
    isAccountOwner: boolean;
    onTableRefresh?: () => void;
}

const UsersTable: React.FCC<UsersTableProps> = (props) => {
    const style = usersTableStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const copy = useMemo(
        () => ({
            name: t('labels.name'),
            persimissions: t('labels.persimissions'),
            actions: t('labels.actions'),
            editUser: t('pages.settings.users.edit-user'),
            deleteUser: t('pages.settings.users.delete-user'),
            makeFaithLeader: t('pages.settings.users.make-faith-leader'),
            changeAccountOwner: t('pages.settings.users.make-account-owner'),
            financialCoordinator: t(
                'pages.settings.users.financialCoordinator',
            ),
            accountOwner: t('pages.settings.users.accountOwner'),
            inviteToGivelify: t('pages.settings.users.inviteToGivelify'),
            waitingForActivation: t(
                'pages.settings.users.waitingForActivation',
            ),
            resendInvitation: t('pages.settings.users.resendInvitation'),
            activationLinkExpired: t(
                'pages.settings.users.activationLinkExpired',
            ),
            sendNewInvitation: t('pages.settings.users.sendNewInvitation'),
        }),
        [t],
    );
    const {
        users,
        sortDirection,
        onSortClick,
        handleUserActionClick,
        actionPopoverOpen,
        actionAnchorPosition,
        handleActionPopoverClose,
        onEditClick,
        onDeleteClick,
        onMakeFaithLeaderClick,
        onChangeAccountOwnerClick,
        userRole,
        userId,
        isOwnUser,
        donee,
        isFaithLeader,
        isAccountOwner,
        onTableRefresh,
    } = props;

    const [sendNewInvitationModalOpen, setSendNewInvitationModalOpen] =
        useState(false);
    const [resendInvitationModalOpen, setResendInvitationModalOpen] =
        useState(false);
    const [inviteToGivelifyModalOpen, setInviteToGivelifyModalOpen] =
        useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [emailError, setEmailError] = useState(false);

    const onResendInvitationClick = (user) => {
        setResendInvitationModalOpen(true);
        setSelectedUser(user);
    };

    const onResendInvitationModalClose = () => {
        setResendInvitationModalOpen(false);
        setSelectedUser(null);
        setEmailError(false);
    };

    const onSendNewInvitationModalClose = () => {
        setSendNewInvitationModalOpen(false);
        setSelectedUser(null);
        setEmailError(false);
    };

    const onInviteToGivelifyClick = (user) => {
        setInviteToGivelifyModalOpen(true);
        setSelectedUser(user);
    };

    const onInviteToGivelifyModalClose = () => {
        setInviteToGivelifyModalOpen(false);
        setSelectedUser(null);
        setEmailError(false);
    };

    const handleUserInfoChange = ({ name, value }) => {
        setSelectedUser({ ...selectedUser, [name]: value });
    };

    const handleEmailChange = (email) => {
        if (validateEmailAddress(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        setSelectedUser({ ...selectedUser, email });
    };

    const handleEmailSend = async () => {
        await dispatch(resendInviteEmail(doneeId, selectedUser.id));
        setSendNewInvitationModalOpen(false);
        setResendInvitationModalOpen(false);
    };

    const handleInviteUser = async () => {
        try {
            await dispatch(editUser(false, selectedUser));
            await dispatch(resendInviteEmail(doneeId, selectedUser.id));
            onInviteToGivelifyModalClose();
            onTableRefresh();
        } catch (e) {
            onInviteToGivelifyModalClose();
            onTableRefresh();
        }
    };

    const isChangeFaithLeaderAvailable =
        userRole === RoleTypes.ADMIN && donee?.type === 'church';

    const isChangeAccountOwnerAvailable =
        donee?.onboarding.appProfile.accountOwner.userId === userId;

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.USERS][userRole] ===
        permissionTypes.FULL_ACCESS;

    const columns: GivelifyTableColumn<Officer>[] = useMemo(
        () => [
            {
                label: copy.name,
                width: 210,
                canSort: true,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    const isUserGreyOut = row.email === '' || !row.active;
                    const fullName = `${row.firstName} ${row.lastName}`;
                    return (
                        <div
                            aria-label="User"
                            className={style.usernameContainer}
                        >
                            <div className={style.userAvatarWrapper}>
                                <GivelifyAvatar
                                    className={mergeClassNames(
                                        isUserGreyOut && style.userAvatarGrey,
                                    )}
                                    color="grey"
                                    size="xSmall"
                                    src={row.avatar}
                                />
                            </div>
                            <div className={style.userNameContent}>
                                <div
                                    className={style.userFullName}
                                    id={`user-name-${row.id}`}
                                >
                                    <GivelifyLabel
                                        bold
                                        className={mergeClassNames(
                                            style.fullName,
                                            isUserGreyOut &&
                                                style.userDetailGreyText,
                                        )}
                                        text={fullName}
                                        variant="heading5"
                                    />
                                    {row.isAccountHolder && (
                                        <div className={style.accountOwnerBox}>
                                            <GivelifyLabel
                                                bold
                                                className={style.accountOwner}
                                                id={`user-owner-${row.id}`}
                                                text={copy.accountOwner}
                                                variant="body2"
                                            />
                                        </div>
                                    )}
                                </div>
                                <GivelifyLabel
                                    className={mergeClassNames(
                                        style.title,
                                        isUserGreyOut &&
                                            style.userDetailGreyText,
                                    )}
                                    id={`user-title-${row.id}`}
                                    text={row.title}
                                    variant="small"
                                />
                            </div>
                        </div>
                    );
                },
            },
            {
                label: copy.persimissions,
                width: 180,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    const isUserGreyOut = row.email === '' || !row.active;
                    return (
                        <GivelifyLabel
                            className={
                                isUserGreyOut ? style.userDetailGreyText : null
                            }
                            id={`user-permission-${row.id}`}
                            text={
                                row.role === roleTypes.FINANCIAL
                                    ? copy.financialCoordinator
                                    : row.role
                            }
                            variant="body2"
                        />
                    );
                },
            },
            {
                label: '',
                cellAlign: 'right',
                width: 210,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    const isUserEmailExist = !!row.email;
                    const isUserActive = !!row.active;
                    return (
                        <div className={style.invitationContent}>
                            <div className={style.invitationBody}>
                                {isUserEmailExist && !isUserActive && (
                                    <>
                                        <GivelifyLabel
                                            className={style.greyText}
                                            text={copy.waitingForActivation}
                                            variant="body3"
                                        />
                                        {hasFullAccess ? (
                                            <GivelifyButton
                                                className={style.sendButton}
                                                data-testid={`user-action-${row.id}`}
                                                id={`user-action-${row.id}`}
                                                onClick={() => {
                                                    onResendInvitationClick(
                                                        row,
                                                    );
                                                }}
                                                size="xLarge"
                                                text={copy.resendInvitation}
                                                variant="ghost"
                                            />
                                        ) : null}
                                    </>
                                )}
                                {!isUserEmailExist && hasFullAccess && (
                                    <GivelifyButton
                                        className={style.sendButton}
                                        data-testid={`invite-${row.id}`}
                                        id={`invite-${row.id}`}
                                        onClick={() => {
                                            onInviteToGivelifyClick(row);
                                        }}
                                        size="xLarge"
                                        text={copy.inviteToGivelify}
                                        variant="ghost"
                                    />
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                label: copy.actions,
                headerAlign: 'right',
                cellAlign: 'right',
                width: 96,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    if (!hasFullAccess) return null;
                    return (
                        <div className={style.moreAction}>
                            <GivelifyButton
                                data-testid={`user-more-action-${row.id}`}
                                iconVariant="more"
                                id={`user-action-${row.id}`}
                                onClick={handleUserActionClick(row)}
                                size="small"
                                variant="icon"
                            />
                        </div>
                    );
                },
            },
        ],
        [copy, style, handleUserActionClick, hasFullAccess],
    );
    return (
        <GivelifyPaper>
            <GivelifyTable
                className={style.userTable}
                columns={columns}
                data={users}
                keySelector={(item) => item.id}
                onSort={onSortClick}
                rowId="other-user"
                sortDirection={sortDirection}
                useVirtualList={users.length > 20}
            />
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorPosition={actionAnchorPosition}
                anchorReference="anchorPosition"
                id={actionPopoverOpen ? 'user-action-popover' : undefined}
                onClose={handleActionPopoverClose}
                open={actionPopoverOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper className={style.popoverPaper}>
                    <MenuList>
                        <MenuItem onClick={onEditClick}>
                            {copy.editUser}
                        </MenuItem>
                        {isChangeFaithLeaderAvailable && (
                            <MenuItem
                                onClick={onMakeFaithLeaderClick}
                                style={{
                                    display: isFaithLeader ? 'none' : 'block',
                                }}
                            >
                                {copy.makeFaithLeader}
                            </MenuItem>
                        )}
                        {isChangeAccountOwnerAvailable && (
                            <MenuItem
                                onClick={onChangeAccountOwnerClick}
                                style={{
                                    display: isAccountOwner ? 'none' : 'block',
                                }}
                            >
                                {copy.changeAccountOwner}
                            </MenuItem>
                        )}
                        {userRole === RoleTypes.ADMIN && (
                            <MenuItem
                                onClick={onDeleteClick}
                                style={{
                                    display: isOwnUser ? 'none' : 'block',
                                }}
                            >
                                {copy.deleteUser}
                            </MenuItem>
                        )}
                    </MenuList>
                </Paper>
            </Popover>
            {sendNewInvitationModalOpen && (
                <SendNewInvitationModal
                    email={selectedUser.email}
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    onClose={onSendNewInvitationModalClose}
                    onSend={handleEmailSend}
                    open={sendNewInvitationModalOpen}
                />
            )}
            {resendInvitationModalOpen && (
                <ResendInvitationModal
                    email={selectedUser.email}
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    onClose={onResendInvitationModalClose}
                    onSend={handleEmailSend}
                    open={resendInvitationModalOpen}
                />
            )}
            {inviteToGivelifyModalOpen && (
                <InviteToGivelifyModal
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    handleInviteUser={handleInviteUser}
                    handleUserInfoChange={handleUserInfoChange}
                    onClose={onInviteToGivelifyModalClose}
                    open={inviteToGivelifyModalOpen}
                    user={selectedUser}
                />
            )}
        </GivelifyPaper>
    );
};

export default UsersTable;
