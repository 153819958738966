import React, { useEffect } from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fade, makeStyles, useTheme } from '@material-ui/core';
import IntegrationsStep from './IntegrationsStep';
import { IntegrationStepsViewProps } from './IntegrationStepsDesktop';

const useStyle = makeStyles(() => ({
    carousel: {
        height: '15rem',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}));

const IntegrationStepsMobile: React.FCC<IntegrationStepsViewProps> = (
    props: IntegrationStepsViewProps,
) => {
    const theme = useTheme();
    const classes = useStyle({});
    const [activeItem, setActiveItem] = React.useState(0);

    const setCurrentSlide = (index: number) => {
        setActiveItem(() => {
            return index;
        });
    };

    useEffect(() => {
        const seconds = 4;
        const timer = setInterval(() => {
            setActiveItem(
                activeItem < props.steps.length - 1 ? activeItem + 1 : 0,
            );
        }, seconds * 1000);
        return () => {
            clearInterval(timer);
        };
    }, [activeItem, props.steps.length]);

    return (
        <div className={classes.carousel}>
            <Box>
                {props.steps.map((step, index) => (
                    <Fade
                        key={index}
                        mountOnEnter
                        unmountOnExit
                        in={activeItem === index}
                        timeout={{ enter: 1500 }}
                    >
                        <div>
                            <IntegrationsStep
                                description={step.description}
                                title={step.title}
                            >
                                {step.children}
                            </IntegrationsStep>
                        </div>
                    </Fade>
                ))}
            </Box>
            <Box display="flex" justifyContent="center">
                {props.steps.map((step, index) => (
                    <Box
                        key={index}
                        className={classes.cursorPointer}
                        onClick={() => setCurrentSlide(index)}
                        paddingX={1}
                    >
                        <FontAwesomeIcon
                            color={
                                index === activeItem
                                    ? theme.palette.primary.main
                                    : ''
                            }
                            icon={faCircle}
                        />
                    </Box>
                ))}
            </Box>
        </div>
    );
};

export default IntegrationStepsMobile;
