import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const integrationReportStyle = makeStyles((theme: Theme) => {
    return createStyles({
        noIntegrationContent: {
            padding: theme.spacing(9, 0),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: '670px',
            margin: '0 auto',
            '& svg': {
                fontSize: 80,
                marginBottom: theme.spacing(5),
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(6),
            },
        },
        noResultDefine: {
            textAlign: 'center',
            lineHeight: '22px',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(5),
        },
    });
});
