import { Modal, useMediaQuery, useTheme } from '@material-ui/core';
import { GivelifyButton } from '../button';
import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { modalStyles } from './styles';
import { GvlModalFooterBottomMargin, GvlModalSize } from './types';
import { TrackingProvider, useTrackingContext } from '@givelify/utils';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export interface GivelifyModalProps {
    /**
     * Modal name
     */
    name?: string;
    /**
     * Modal state
     */
    open: boolean;
    /**
     * On close event. Hanlde modal visibility state on this event.
     */
    onClose?: () => void;
    /**
     * Show close button on right top corner.
     */
    showCloseButton?: boolean;
    /**
     * If true, on outside of the modal click will have no effect.
     */
    disableBackdropClick?: boolean;
    /**
     * If provided, than primary button in the footer will be rendered.
     */
    onPrimaryClick?: () => void;
    /**
     * If provided, than secondary button in the footer will be rendered.
     */
    onSecondaryClick?: () => void;
    /**
     * If true, footer buttons will be centered.
     */
    centerButtons?: boolean;
    /**
     * Primary buttons text. Default is "Ok"
     */
    primaryButtonText?: string;
    /**
     * Primary button variant. Default is "primary"
     */
    primaryButtonVariant?: 'primary' | 'secondary' | 'danger';
    /**
     * Primary buttons text. Default is "Cancel"
     */
    secondaryButtonText?: string;
    /**
     * Footer marginBottom variant
     */
    footerBottomMargin?: GvlModalFooterBottomMargin;
    /**
     * Size of the modal
     */
    size?: GvlModalSize;
    /**
     * Custom width for the modal
     */
    width?: number;
    /**
     * Provided classname
     */
    className?: string;
    /**
     * Provided classname for content container
     */
    contentClassName?: string;
    /**
     * Provided classname for header
     */
    headerClassName?: string;
    /**
     * Provided classname for footer
     */
    footerClassName?: string;
    /**
     * Provided classname for content container
     */
    contentContainerClassName?: string;
    /**
     * Fullscreen
     */
    fullscreen?: boolean;
    /**
     * Auto Fullscreen
     */
    autoFullscreen?: boolean;
    autoFullscreenBreakpoint?: Breakpoint | number;
    disableEnforceFocus?: boolean;
    isLoading?: boolean;
    /**
     * If true, disabled primary button.
     */
    isPrimaryButtonDisabled?: boolean;
    /**
     * If true, disabled secondary button.
     */
    isSecondaryButtonDisabled?: boolean;
    testId?: string;
    trackPageVisit?: boolean;
}

/**
 * Givelify Modal
 */
const GivelifyModalInternal: React.FCC<GivelifyModalProps> = (props) => {
    const {
        isPrimaryButtonDisabled = false,
        isSecondaryButtonDisabled = false,
    } = props;
    const {
        titleContainer,
        contentFullscreen,
        contentContainer,
        footerContainer,
        secondaryButton,
        primaryButton,
        content,
    } = modalStyles({
        width: props.width,
        centerButtons: props.centerButtons,
        size: props.size ? props.size : 'small',
        footerMargin: props.footerBottomMargin
            ? props.footerBottomMargin
            : 'wide',
    });
    const theme = useTheme();
    const fullScreen = useMediaQuery(
        theme.breakpoints.down(
            props.autoFullscreenBreakpoint
                ? props.autoFullscreenBreakpoint
                : 'sm',
        ),
    );
    const contentClassName = mergeClassNames(
        (props.fullscreen || (props.autoFullscreen && fullScreen)) &&
            contentFullscreen,
        contentContainer,
        props.contentClassName,
    );
    const headerClassName = mergeClassNames(
        titleContainer,
        props.headerClassName,
    );
    const footerClassName = mergeClassNames(
        footerContainer,
        props.footerClassName,
    );
    const contentContainerClassName = mergeClassNames(
        content,
        props.contentContainerClassName,
    );

    const { trackEvent } = useTrackingContext();
    const onBackdropClick = () => {
        trackEvent('Backdrop_Click');
    };

    const onClose = (
        _event: React.ReactEventHandler<unknown>,
        reason: 'backdropClick' | 'escapeKeyDown',
    ) => {
        if (reason === 'backdropClick') {
            onBackdropClick();
            if (!props.disableBackdropClick) {
                props.onClose && props.onClose();
            }
        } else {
            props.onClose && props.onClose();
        }
    };

    return (
        <Modal
            open={props.open}
            onClose={onClose}
            BackdropProps={{
                style: {
                    background: '#D0CFD9',
                    mixBlendMode: 'lighten',
                    opacity: 0.4,
                },
            }}
            className={props.className}
            disableEnforceFocus={props.disableEnforceFocus}
            data-testid={props.testId}
        >
            <div className={contentClassName}>
                {props.showCloseButton ? (
                    <div className={headerClassName}>
                        <GivelifyButton
                            name="close"
                            variant="icon"
                            iconVariant="close-circle"
                            onClick={props.onClose}
                            marginLeft="auto"
                            padding={0}
                            fontSize={24}
                            color="grey600"
                            data-testid={props.testId && `${props.testId}Close`}
                            track={false}
                        />
                    </div>
                ) : null}
                <div className={contentContainerClassName}>
                    {props.children}
                </div>
                {props.onPrimaryClick || props.onSecondaryClick ? (
                    <div className={footerClassName}>
                        {props.onSecondaryClick ? (
                            <GivelifyButton
                                name="cancel"
                                text={
                                    props.secondaryButtonText
                                        ? props.secondaryButtonText
                                        : 'Cancel'
                                }
                                size="large"
                                className={secondaryButton}
                                onClick={props.onSecondaryClick}
                                disabled={isSecondaryButtonDisabled}
                                data-testid={
                                    props.testId && `${props.testId}Cancel`
                                }
                                track={false}
                            />
                        ) : null}
                        {props.onPrimaryClick ? (
                            <GivelifyButton
                                name="ok"
                                text={
                                    props.primaryButtonText
                                        ? props.primaryButtonText
                                        : 'Ok'
                                }
                                size="large"
                                variant={
                                    props.primaryButtonVariant || 'primary'
                                }
                                className={primaryButton}
                                onClick={props.onPrimaryClick}
                                isLoading={props.isLoading}
                                disabled={isPrimaryButtonDisabled}
                                data-testid={
                                    props.testId && `${props.testId}Submit`
                                }
                                track={false}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </Modal>
    );
};

export const GivelifyModal: React.FCC<GivelifyModalProps> = (props) => (
    <TrackingProvider
        pageName={props.name}
        trackPageVisit={!!(props.open && props.trackPageVisit)}
    >
        <GivelifyModalInternal {...props} />
    </TrackingProvider>
);
