import React, { useMemo } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { matchRowStyle } from '../../../../../components/style';
import { IntegrationTypes } from '../../../../../types';
import { HeaderCell } from '../../../DonorMatching/components/DonorMatchingHeader';

interface AutoIntegrationUnmatchEnvelopeHeaderProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: (sorting: Sorting[]) => void;
    matched?: boolean;
    integration?: IntegrationTypes;
}

const AutoIntegrationUnmatchEnvelopeHeader: React.FCC<
    AutoIntegrationUnmatchEnvelopeHeaderProps
> = ({ sortColumn, sortDirection, onOrderClick, matched, integration }) => {
    const classes = matchRowStyle();
    const { t, scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.integration_envelope_matching.content',
    });
    const copy = useMemo(
        () => ({
            envelopeName: scopedTranslate('ccb.envelopeNameColumnTitle.church'),
            ccbCategory: scopedTranslate('ccb.categoryColumnTitle'),
            planningCenterCategory: scopedTranslate(
                'planningCenter.categoryColumnTitle',
            ),
            f1Fund: scopedTranslate('table.heading.f1Fund'),
            f1GoFund: scopedTranslate('table.heading.f1GoFund'),
            f1SubFund: scopedTranslate('table.heading.subFund'),
            sort: t('labels.sort'),
        }),
        [t, scopedTranslate],
    );
    const { isCCB, isPlanningCenter, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );
    return (
        <Grid container alignItems="center" className={classes.matchHeader}>
            <Grid item className={classes.matchHeaderItem} md={4} xs={6}>
                <HeaderCell
                    label={copy.envelopeName}
                    name={'name'}
                    onOrderClick={() =>
                        onOrderClick([
                            {
                                columnName: 'name',
                                direction:
                                    sortDirection === 'desc' ? 'asc' : 'desc',
                            },
                        ])
                    }
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortLabel={copy.sort}
                />
            </Grid>
            <Grid item className={classes.matchHeaderItem} md={5} xs={6}>
                {matched ? (
                    isCCB || isPlanningCenter ? (
                        <HeaderCell
                            label={
                                isCCB
                                    ? copy.ccbCategory
                                    : copy.planningCenterCategory
                            }
                            name={'match.level0.name'}
                            onOrderClick={() =>
                                onOrderClick([
                                    {
                                        columnName: 'match.level0.name',
                                        direction:
                                            sortDirection === 'asc'
                                                ? 'desc'
                                                : 'asc',
                                    },
                                ])
                            }
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            sortLabel={copy.sort}
                        />
                    ) : (
                        <Grid container>
                            <Grid item md={6}>
                                <HeaderCell
                                    label={isF1Go ? copy.f1GoFund : copy.f1Fund}
                                    name={'match.level0.name'}
                                    onOrderClick={() =>
                                        onOrderClick([
                                            {
                                                columnName: 'match.level0.name',
                                                direction:
                                                    sortDirection === 'asc'
                                                        ? 'desc'
                                                        : 'asc',
                                            },
                                        ])
                                    }
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    sortLabel={copy.sort}
                                />
                            </Grid>
                            {isF1Go ? null : (
                                <Grid item md={6}>
                                    <HeaderCell
                                        label={copy.f1SubFund}
                                        name="match.level0.name"
                                        onOrderClick={() =>
                                            onOrderClick([
                                                {
                                                    columnName:
                                                        'match.level0.name',
                                                    direction:
                                                        sortDirection === 'asc'
                                                            ? 'desc'
                                                            : 'asc',
                                                },
                                            ])
                                        }
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection}
                                        sortLabel={copy.sort}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )
                ) : isCCB || isPlanningCenter ? (
                    <div>
                        {isCCB ? copy.ccbCategory : copy.planningCenterCategory}
                    </div>
                ) : (
                    <Grid container>
                        <Grid item md={6}>
                            {isF1Go ? copy.f1GoFund : copy.f1Fund}
                        </Grid>
                        {isF1Go ? null : (
                            <Grid item md={6}>
                                {copy.f1SubFund}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default AutoIntegrationUnmatchEnvelopeHeader;
