import React from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    DesignTokens,
    GivelifyLabel,
    GivelifyNotificationProps,
} from '@givelify/ui';
import {
    LINK_IDENTIFIER,
    PAGE_NAME,
    useTrackingContext,
} from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import RoleTypes from '../../../constants/roleTypes';
import { NotificationContext } from '../notificationContext';

const ADD_PRIMARY_REP_DEADLINE = '2024-10-01';

export const NotificationProvider: React.FCC = ({ children }) => {
    const { trackEventRaw } = useTrackingContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { PATH: GAS_ROUTER_PATH } = useGasRouterContext();
    const { onboardingNotification, showCaptivePortal } =
        useCaptivePortalContext();
    const [notification, setNotification] =
        React.useState<GivelifyNotificationProps | null>(null);
    const notificationRef = React.useRef<HTMLDivElement>(null);
    const [notificationHeight, setNotificationHeight] = React.useState(0);
    const {
        newEscalatedPrimaryRepVerificationEnabled,
        newSeverePrimaryRepVerificationEnabled,
        donee,
        mainDoneeId,
        user,
    } = useSelector((state: AppState) => ({
        newEscalatedPrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
            false,
        ),
        newSeverePrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_SEVERE_NOTIFICATION,
            false,
        ),
        newDonorDetailsPageEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_DONOR_DETAILS_PAGE,
            false,
        ),
        donee: state.Donee.donee,
        mainDoneeId: state.Donee.campuses ? state.Donee.campuses[0].id : null,
        user: state.User.user,
    }));

    const openNotification = React.useCallback(
        (content: GivelifyNotificationProps) => {
            setNotification(content);
        },
        [],
    );
    const closeNotification = React.useCallback(
        (_, reason: SnackbarCloseReason) => {
            if (reason !== 'clickaway') {
                setNotification(null);
            }
        },
        [],
    );
    React.useEffect(() => {
        if (!showCaptivePortal && onboardingNotification) {
            openNotification(onboardingNotification);
        } else {
            openNotification(null);
        }
    }, [showCaptivePortal, openNotification, onboardingNotification]);
    // Escalated Primary Rep Verification Notification
    React.useEffect(() => {
        if (
            !newEscalatedPrimaryRepVerificationEnabled &&
            !newSeverePrimaryRepVerificationEnabled
        )
            return;
        const sever = newSeverePrimaryRepVerificationEnabled;
        const enabledLocationAdmin =
            location.pathname !== PATH.SETTINGS.BANK_INFO ||
            !location.search.includes('rep');
        const enabledLocationNonAdmin =
            location.pathname !== PATH.SETTINGS.USERS;
        const isAdmin = user.role === RoleTypes.ADMIN;
        const isBasicOrFinancial =
            user.role === RoleTypes.BASIC || user.role === RoleTypes.FINANCIAL;
        const validUser =
            mainDoneeId !== null &&
            donee.id === mainDoneeId &&
            (isAdmin || isBasicOrFinancial);
        const hasMid = donee.onboarding.hasMid;
        const isBahamas = donee.country === 'BHS';
        const hasPrimaryRep =
            donee.onboarding.primaryRepresentative &&
            donee.onboarding.primaryRepresentative.firstName;
        const notificationId = sever
            ? 'primaryRepVerificationSevere'
            : 'primaryRepVerificationEscalated';
        if (
            (isAdmin || isBasicOrFinancial) &&
            validUser &&
            hasMid &&
            !hasPrimaryRep &&
            !isBahamas
        ) {
            if (!notification || notification.id === notificationId) {
                if (!notification || notification.id !== notificationId) {
                    trackEventRaw(
                        `View [${
                            isAdmin
                                ? PAGE_NAME.PrimaryRepresentativeNotification
                                : PAGE_NAME.PrimaryRepresentativeNonAdminNotification
                        }] Box`,
                    );
                }
                const leftDays = dayjs
                    .tz(ADD_PRIMARY_REP_DEADLINE)
                    .diff(dayjs().tz(), 'days');
                const adminPrefix = isAdmin ? 'admin' : 'basic';
                const isAfterOctober1 = dayjs
                    .tz()
                    .isAfter(dayjs.tz(ADD_PRIMARY_REP_DEADLINE));
                const severityPrefix = sever
                    ? isAfterOctober1
                        ? 'due'
                        : 'severe'
                    : 'warning';
                const title = sever ? (
                    <GivelifyLabel variant="body2B">
                        <GivelifyLabel
                            color={
                                severityPrefix === 'severe'
                                    ? DesignTokens.color.textErrorDefault
                                    : undefined
                            }
                            variant="body2B"
                        >
                            {t(
                                `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title1`,
                                {
                                    DAYS: leftDays,
                                },
                            )}
                        </GivelifyLabel>
                        {severityPrefix === 'severe'
                            ? t(
                                  `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title2`,
                              )
                            : null}
                    </GivelifyLabel>
                ) : (
                    t(
                        `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title`,
                    )
                );
                setNotification({
                    id: notificationId,
                    text: title,
                    description: t(
                        `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.description`,
                    ),
                    variant: sever ? 'error' : 'warning',
                    actionButtonProps: !(
                        (!enabledLocationNonAdmin && isBasicOrFinancial) ||
                        (!enabledLocationAdmin && isAdmin)
                    )
                        ? {
                              text: t(
                                  `notifications.addPrimaryRep.common.${adminPrefix}.buttonText`,
                              ),
                              onClick: () => {
                                  trackEventRaw(
                                      `Click [${
                                          isAdmin
                                              ? PAGE_NAME.PrimaryRepresentativeNotification
                                              : PAGE_NAME.PrimaryRepresentativeNonAdminNotification
                                      }] Box <${
                                          isAdmin
                                              ? 'Get Started'
                                              : 'Get Started Non Admin'
                                      }>_${LINK_IDENTIFIER}`,
                                  );
                                  navigate(
                                      isAdmin
                                          ? GAS_ROUTER_PATH.SETTINGS.BANK_INFO(
                                                'rep',
                                            )
                                          : GAS_ROUTER_PATH.SETTINGS.USERS(),
                                  );
                              },
                          }
                        : undefined,
                });
            }
        } else if (notification && notification.id === notificationId) {
            setNotification(null);
        }
        //eslint-disable-next-line
    }, [
        donee,
        location.pathname,
        location.search,
        newEscalatedPrimaryRepVerificationEnabled,
    ]);
    React.useEffect(() => {
        if (!notification) {
            setNotificationHeight(0);
            return;
        }
        const updateHeight = () => {
            if (notificationRef.current) {
                setNotificationHeight(notificationRef.current.offsetHeight);
            }
        };
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [notification]);
    return (
        <NotificationContext.Provider
            value={{
                showNotification: openNotification,
                closeNotification,
                notification,
                notificationHeight,
                notificationRef,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
