import React, { useCallback, useState } from 'react';
import { GivelifyTextFieldState, GivelifyTextField } from '../textField';
import { isValidZip } from '../utils/addressUtils';

export interface ZipFieldProps {
    name?: string;
    zip: string | undefined;
    onChange?: (zip: string) => unknown;
    placeholder: string;
    showState?: boolean;
    zipErrorMessage?: string;
}

export const ZipField: React.FC<ZipFieldProps> = ({
    name,
    zip,
    placeholder,
    onChange,
    showState = false,
    zipErrorMessage,
}) => {
    const [newZip, setZip] = useState<string>(zip || '');
    const [inputState, setInputState] =
        React.useState<GivelifyTextFieldState>('idle');
    const handleChange = useCallback<
        React.ChangeEventHandler<HTMLInputElement>
    >(
        (e) => {
            const value = e.target.value;
            setZip(value);
            onChange && onChange(value);
            setInputState(isValidZip(value) ? 'idle' : 'error');
        },
        [setZip, onChange, setInputState],
    );
    React.useEffect(() => {
        if (showState) {
            setInputState(isValidZip(newZip) ? 'idle' : 'error');
        }
        //eslint-disable-next-line
    }, [showState]);
    return (
        <GivelifyTextField
            fullWidth
            aria-label={placeholder}
            id="zip"
            label={placeholder}
            leftHelperText={
                inputState === 'error' ? zipErrorMessage : undefined
            }
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            state={inputState}
            value={newZip}
        />
    );
};
