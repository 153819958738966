import React from 'react';
import { DesignTokens, GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { InfoBox } from 'components';
import { useTranslation } from 'react-i18next';
import { ListItem, Title } from './styles';

export const RepInfoBox: React.FCC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.title',
            ),
            bulletPoint1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.bulletPoint1',
            ),
            bulletPoint2: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.bulletPoint2',
            ),
            bulletPoint3: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.bulletPoint3',
            ),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.description',
            ),
        }),
        [t],
    );
    return (
        <InfoBox>
            <Title variant="heading2S">{copy.title}</Title>

            <ListItem
                container
                alignItems="flex-start"
                gap="12px"
                wrap="nowrap"
            >
                <GivelifyIcon variant="shieldIcon" />
                <GivelifyLabel
                    color={DesignTokens.color.globalNeutral700}
                    variant="body2"
                >
                    {copy.bulletPoint1}
                </GivelifyLabel>
            </ListItem>
            <ListItem
                container
                alignItems="flex-start"
                gap="12px"
                wrap="nowrap"
            >
                <GivelifyIcon variant="shieldIcon" />
                <GivelifyLabel
                    color={DesignTokens.color.globalNeutral700}
                    variant="body2"
                >
                    {copy.bulletPoint2}
                </GivelifyLabel>
            </ListItem>
            <ListItem
                container
                alignItems="flex-start"
                gap="12px"
                wrap="nowrap"
            >
                <GivelifyIcon variant="shieldIcon" />
                <GivelifyLabel
                    color={DesignTokens.color.globalNeutral700}
                    variant="body2"
                >
                    {copy.bulletPoint3}
                </GivelifyLabel>
            </ListItem>
        </InfoBox>
    );
};
