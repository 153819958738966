import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import { GivelifyLoading } from '../loading';
import { GivelifyModal } from '../modal';
import { DesignTokens } from '../specify';
import { GivelifyFullscreenLoadingProps, LoaderRequestState } from './types';

const StyledModal = styled(GivelifyModal)(({ theme }) => ({
    zIndex: theme.zIndex.modal + 1,
    '& .MuiPaper-root': {
        background: 'transparent',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& .loading-note': {
        textAlign: 'center',
        fontSize: '14px',
    },
}));
const StyledLoading = styled(GivelifyLoading)(() => ({
    color: DesignTokens.color.globalNeutral600,
    strokeLinecap: 'round',
}));

const LoadingBox = styled(Box)(() => ({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    borderRadius: '16px',
    border: `2px solid ${DesignTokens.border.borderComponentDefault.color}`,
    boxShadow: DesignTokens.shadow.shadowHigh,
    background: DesignTokens.color.backgroundComponentPrimaryDefault,
    transition: 'background .3s, border .3s',

    '&.success': {
        borderColor: DesignTokens.color.globalSuccess400,
        background: DesignTokens.color.globalSuccess100,
    },
}));

function useLoaderState(
    requestState: LoaderRequestState,
    timeout: number,
    successTimeout: number,
): [boolean, boolean] {
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [timeLimitExceeded, setTimeLimitExceeded] = useState<boolean>(false);

    useEffect(() => {
        if (requestState === 'REQUEST_START') {
            setTimeout(() => {
                setTimeLimitExceeded(true);
            }, timeout);
        }
    }, [requestState, timeout]);

    useEffect(() => {
        if (timeLimitExceeded && requestState === 'REQUEST_SUCCESS') {
            setShowSuccess(true);
        }
    }, [timeLimitExceeded, requestState]);

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setCompleted(true);
            }, successTimeout);
        }
    }, [showSuccess, successTimeout]);

    return [showSuccess, completed];
}

export const GivelifyFullscreenLoading: React.FCC<
    GivelifyFullscreenLoadingProps
> = ({
    onComplete,
    note,
    requestState,
    successMessage,
    successTimeout,
    timeout,
}) => {
    const [showSuccess, complete] = useLoaderState(
        requestState,
        timeout || 3000,
        successTimeout || 2000,
    );

    useEffect(() => {
        if (complete) {
            onComplete(requestState === 'REQUEST_ERROR');
        }
        //listen to these changes only, we dont wan't to run this when requestState chages
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complete, onComplete]);

    return (
        <StyledModal
            open={
                requestState === 'REQUEST_START' ||
                (requestState === 'REQUEST_SUCCESS' && !complete)
            }
        >
            <LoadingBox className={showSuccess ? 'success' : ''}>
                {showSuccess ? (
                    <GivelifyIcon fontSize={24} variant="success-box-icon" />
                ) : (
                    <StyledLoading size={24} type="circular" />
                )}
                {note && !showSuccess && (
                    <GivelifyLabel
                        className="loading-note"
                        text={note}
                        variant="body1B"
                    />
                )}
                {successMessage && showSuccess && (
                    <GivelifyLabel
                        className="loading-note"
                        text={successMessage}
                        variant="body1B"
                    />
                )}
            </LoadingBox>
        </StyledModal>
    );
};
