import { useCallback, useEffect, useRef, useState } from 'react';
import { useRequestState, requestError, RequestState } from '@givelify/utils';

/**
 * Hook returns a function that can be used to make requests at a later time
 * (Such as in response to user interactions)
 *
 * @param {string} url - endpoint for the request
 * @param {string} data
 */

type ResponseFunctionType<T> = (
    res: MessageEvent,
    fn: React.Dispatch<React.SetStateAction<RequestState<T>>>,
) => unknown;

const errorObject = {
    ...new Error('Unable to create WebSocket'),
    status: 0,
    isAxiosError: false,
};

export const useWebSocket = <Response>(
    url: string,
    onMessage: ResponseFunctionType<Response>,
) => {
    const [requestState, setRequestState] = useRequestState<Response>();
    const [readyState, setReadyState] = useState<number>();
    const ws = useRef<WebSocket | null>(null);

    useEffect(() => {
        if (ws.current?.readyState === WebSocket.OPEN) return;

        ws.current = new WebSocket(url);
        ws.current.onerror = () => {
            setRequestState(requestError(errorObject));
        };
        ws.current.onmessage = (res) => onMessage(res, setRequestState);
        ws.current.onopen = () => setReadyState(ws.current.readyState);
        ws.current.onclose = () => setReadyState(ws.current.readyState);

        return () => {
            ws.current?.close();
        };
        // eslint-disable-next-line
    }, []);

    const makeRequest = useCallback(
        async (data: string) => {
            if (ws.current?.readyState === WebSocket.OPEN) {
                setRequestState({
                    type: 'REQUEST_START',
                    response: undefined,
                });
                ws.current.send(data);
            } else {
                console.error('WebSocket Connection is closed');
            }
        },
        [setRequestState],
    );
    return [readyState, requestState, makeRequest] as const;
};
