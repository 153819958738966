import React, { useCallback, useEffect, useState } from 'react';
import { GivelithonMainPage } from '@givelify/givelithon-ui';
import { useInvokeApi } from '@givelify/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import PageTitle from '../../components/PageTitle';
import { AppState } from '../../store';
import {
    getGivelithonEnvelopeResponse,
    GivelithonEnvelopeProps,
    mapGivelithonEnvelopeData,
} from './types';

const envelopeCompare =
    (isAsc?: boolean) =>
    (
        envelopeA: GivelithonEnvelopeProps,
        envelopeB: GivelithonEnvelopeProps,
    ) => {
        const result = envelopeA.name
            .toLowerCase()
            .localeCompare(envelopeB.name.toLowerCase());

        if (isAsc) return result;
        return -1 * result;
    };

const Givelithon: React.FCC = () => {
    const navigate = useNavigate();
    const doneeId = useSelector((state: AppState) => state.Donee.donee.id);
    const [loading, setLoading] = useState<boolean>(true);
    const [sortValue, setSortValue] = useState<string>('atoz');
    const { PATH } = useGasRouterContext();

    const { doneeName, doneeType } = useSelector((state: AppState) => ({
        doneeName: state.User.user.fullName,
        doneeType: state.Donee.donee.type,
    }));
    const linkToGivelithon = (envelopeId: number) => {
        const url = PATH.FUNDRAISING_TOOLS.LAUNCH_GIVELITHON(envelopeId);
        const win = window.open(url, '_blank');
        win.focus();
    };

    const linkToEditGivelithon = () => {
        navigate(PATH.SETTINGS.ENVELOPES('active'));
    };

    const linkToSettings = () => {
        navigate(PATH.SETTINGS.ENVELOPES('active'));
    };

    const [fetchEnvelopesRequest, makeFetchEnvelopesRequest] =
        useInvokeApi<getGivelithonEnvelopeResponse>();
    const [givelithonEnvelope, setGivelithonEnvelope] = useState<
        GivelithonEnvelopeProps[]
    >([]);
    const getData = useCallback(() => {
        makeFetchEnvelopesRequest(
            'GET',
            `/donees/${doneeId}/envelopes?page=1&active=true&sort=name&pageSize=99999999`,
        );
        setLoading(true);
    }, [makeFetchEnvelopesRequest, doneeId]);

    const onSort = (value) => {
        setSortValue(value.key);
        setLoading(true);
    };

    useEffect(() => {
        getData();
    }, [sortValue, getData]);

    useEffect(() => {
        if (fetchEnvelopesRequest.type === 'REQUEST_SUCCESS') {
            const givelithonEnvelopeResponseList =
                fetchEnvelopesRequest.response.data;
            setGivelithonEnvelope(
                sortValue === 'ztoa'
                    ? givelithonEnvelopeResponseList
                          .reverse()
                          .map((envelope) =>
                              mapGivelithonEnvelopeData(envelope),
                          )
                          .sort(envelopeCompare())
                    : givelithonEnvelopeResponseList
                          .map((envelope) =>
                              mapGivelithonEnvelopeData(envelope),
                          )
                          .sort(envelopeCompare(true)),
            );
            setLoading(false);
        }
    }, [fetchEnvelopesRequest, sortValue]);

    return (
        <>
            <PageTitle description="Givelithon" title="Givelithon" />
            <GivelithonMainPage
                doneeName={doneeName}
                doneeType={doneeType}
                envelopes={givelithonEnvelope}
                links={{
                    toGivelithon: linkToGivelithon,
                    toEditGivelithon: linkToEditGivelithon,
                    toSettings: linkToSettings,
                }}
                loading={loading}
                onSort={onSort}
            />
        </>
    );
};

export default Givelithon;
