import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import useStyles from './styles';

const DonationsHeaderRow: React.FCC = () => {
    const classes = useStyles();

    return (
        <div className={classes.headerRowWrapper}>
            <GivelifyLabel
                bold
                className={classes.column2}
                color="grey600"
                text={'Refund issued'}
                variant="body2"
            />
            <GivelifyLabel
                bold
                className={classes.column5}
                color="grey600"
                text="Donor"
                variant="body2"
            />
            <GivelifyLabel
                bold
                className={classes.column1}
                color="grey600"
                text="Amount"
                variant="body2"
            />
        </div>
    );
};

export default DonationsHeaderRow;
