import React from 'react';
import { GivelifyPillProps } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { FilterPills, SearchByNameInput } from '../components';
import { FilterPanel } from '../filterPanel';
import {
    FilterButton,
    PageFilterRowContainer,
    PageFilterRowWrapper,
} from './styles';

export type PageFilterRowComponentProps = {
    onSearchSubmit: (search: string) => void;
    onFilterButtonClick: () => void;
    onFilterPanelClose: () => void;
    onFilterSubmit: () => void;
    onFilterUndo: () => void;
    onFilterClear: () => void;
    canSubmit: boolean;
    canClear: boolean;
    canUndo: boolean;
    search: string | null;
    disabled?: boolean;
    isFilterPanelOpen: boolean;
    isFilterBtnActive: boolean;
    filterPanelSubmitText?: string;
    filterPillProps?: GivelifyPillProps[];
    children?: React.ReactNode;
};

const PageFilterRowComponent: React.FCC<PageFilterRowComponentProps> = ({
    onSearchSubmit,
    onFilterButtonClick,
    onFilterPanelClose,
    onFilterSubmit,
    onFilterUndo,
    onFilterClear,
    search,
    disabled,
    isFilterPanelOpen,
    isFilterBtnActive,
    filterPanelSubmitText,
    canSubmit,
    canClear,
    canUndo,
    filterPillProps,
    children,
}) => {
    const theme = useTheme();
    const isTabletXs = useMediaQuery(theme.breakpoints.down('tablet'));
    const filterPills = <FilterPills filters={filterPillProps} />;
    return (
        <PageFilterRowContainer gap={1}>
            <PageFilterRowWrapper data-testid="page-filter-row">
                <SearchByNameInput
                    disabled={disabled}
                    onSearchSubmit={onSearchSubmit}
                    search={search}
                />
                {isTabletXs ? null : filterPills}
                <FilterButton
                    isNarrow
                    data-testid="page-filter-button"
                    disabled={disabled}
                    id="page-filter-button"
                    isActive={isFilterBtnActive}
                    name="Filters"
                    onClick={onFilterButtonClick}
                    text="Filters"
                    variant="secondary"
                />
                <FilterPanel
                    canClear={canClear}
                    canSubmit={canSubmit}
                    canUndo={canUndo}
                    onClear={onFilterClear}
                    onClose={onFilterPanelClose}
                    onSubmit={onFilterSubmit}
                    onUndo={onFilterUndo}
                    open={isFilterPanelOpen}
                    submitText={filterPanelSubmitText}
                >
                    {children}
                </FilterPanel>
            </PageFilterRowWrapper>
            {isTabletXs ? filterPills : null}
        </PageFilterRowContainer>
    );
};

export const PageFilterRow = React.memo(PageFilterRowComponent);
