import React, { useMemo, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AppState } from 'store';
import {
    GoogleTagManagerTag,
    getGoogleTagManagerTag,
    GTM_TAG_VALUE,
} from '../utils/getGoogleTagManagerTag';

const GasRouterContext =
    createContext<ReturnType<typeof buildRoutesObject>>(undefined);

const appendSearchParam = (url: string, params: { [x: string]: string }) => {
    const parser = document.createElement('a');
    parser.href = url;

    const searchParams = new URLSearchParams(parser.search);
    Object.entries(params).forEach(([name, value]) => {
        const isNameInvalid = !name || name === 'undefined' || name === 'null';
        const isEmptyDoneeId =
            name === DONEE_SEARCH_QUERY_NAME &&
            (!value || value === 'undefined' || value === 'null');

        if (isNameInvalid || isEmptyDoneeId) return;

        searchParams.set(name, value || '');
    });

    const paramsString = searchParams.toString();
    return paramsString === ''
        ? parser.pathname
        : `${parser.pathname}?${searchParams.toString()}`;
};

export const useDoneeIdFromQuery = () => {
    const [searchParams] = useSearchParams();
    const doneeIdFromQuery = React.useMemo(
        () => getDoneeIdFromQuery(searchParams),
        [searchParams],
    );

    return doneeIdFromQuery;
};

export const getDoneeIdFromQuery = (searchParams: URLSearchParams) => {
    const doneeQuery = searchParams.get(DONEE_SEARCH_QUERY_NAME);
    if (!doneeQuery) return;

    const doneeIdRaw = parseInt(doneeQuery);
    if (isNaN(doneeIdRaw)) return;

    return doneeIdRaw;
};

export const DONEE_SEARCH_QUERY_NAME = 'donee';

export const appendAdditionalSearchParams = (
    url: string,
    doneeId?: number,
    tag?: GoogleTagManagerTag,
) =>
    doneeId || tag
        ? appendSearchParam(url, {
              ...(doneeId && { [DONEE_SEARCH_QUERY_NAME]: doneeId.toString() }),
              ...(tag && { [tag]: GTM_TAG_VALUE }),
          })
        : url;

export const useGasRouterContext = () => useContext(GasRouterContext);

export const buildRoutesObject = (
    doneeId: number,
    tag?: GoogleTagManagerTag,
) => ({
    PATH: {
        OVERVIEW: appendAdditionalSearchParams('/overview', doneeId, tag),
        EDIT_PROFILE: appendAdditionalSearchParams(
            '/edit-profile',
            doneeId,
            tag,
        ),
        ADMIN: {
            LOGIN: '/admin/login/:token',
        },
        DONEE: {
            LOGIN: '/',
            RESET: '/reset-password',
        },
        DONATIONS: {
            ROOT: appendAdditionalSearchParams('/donations', doneeId, tag),
            DONATIONS_ACTIVITY: ({
                newDoneeId = doneeId,
                params,
            }: {
                newDoneeId?: number;
                params?: {
                    startDate?: string;
                    endDate?: string;
                    timeFrame?: string;
                    envelopeIds?: string;
                };
            } = {}) =>
                appendSearchParam('/donations/donations-activity', {
                    [DONEE_SEARCH_QUERY_NAME]: newDoneeId?.toString(),
                    ...(params?.startDate
                        ? { startDate: params?.startDate }
                        : {}),
                    ...(params?.endDate ? { endDate: params?.endDate } : {}),
                    ...(params?.timeFrame
                        ? { timeFrame: params?.timeFrame }
                        : {}),
                    ...(params?.envelopeIds
                        ? { envelopeIds: params?.envelopeIds }
                        : {}),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            DONATION_SUMMARY_ENVELOPES: ({
                newDoneeId = doneeId,
                params,
            }: {
                newDoneeId?: number;
                params?: {
                    startDate?: string;
                    endDate?: string;
                    timeFrame?: string;
                };
            } = {}) =>
                appendSearchParam('/donations/donation-summary/by-envelopes', {
                    [DONEE_SEARCH_QUERY_NAME]: newDoneeId?.toString(),
                    ...(params?.startDate
                        ? { startDate: params?.startDate }
                        : {}),
                    ...(params?.endDate ? { endDate: params?.endDate } : {}),
                    ...(params?.timeFrame
                        ? { timeFrame: params?.timeFrame }
                        : {}),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            DONATION_SUMMARY_DATE: ({
                newDoneeId = doneeId,
                params,
            }: {
                newDoneeId?: number;
                params?: {
                    startDate?: string;
                    endDate?: string;
                    timeFrame?: string;
                };
            } = {}) =>
                appendSearchParam('/donations/donation-summary/by-date', {
                    [DONEE_SEARCH_QUERY_NAME]: newDoneeId?.toString(),
                    ...(params?.startDate
                        ? { startDate: params?.startDate }
                        : {}),
                    ...(params?.endDate ? { endDate: params?.endDate } : {}),
                    ...(params?.timeFrame
                        ? { timeFrame: params?.timeFrame }
                        : {}),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            BANK_DEPOSITS: appendAdditionalSearchParams(
                '/donations/bank-deposits',
                doneeId,
                tag,
            ),
            REFUNDS: appendAdditionalSearchParams(
                '/donations/refunds',
                doneeId,
                tag,
            ),
        },
        FUNDRAISING_TOOLS: {
            ROOT: appendAdditionalSearchParams(
                '/fundraising-tools',
                doneeId,
                tag,
            ),
            ONLINE_GIVING: appendAdditionalSearchParams(
                '/fundraising-tools/online-giving',
                doneeId,
                tag,
            ),
            GIVELIFY_BUTTON: appendAdditionalSearchParams(
                '/fundraising-tools/online-giving/givelify-button',
                doneeId,
                tag,
            ),
            SOCIAL_MEDIA_GIVING: appendAdditionalSearchParams(
                '/fundraising-tools/social-media-giving',
                doneeId,
                tag,
            ),
            SOCIAL_MEDIA_GIVING_FACEBOOK: appendAdditionalSearchParams(
                '/fundraising-tools/social-media-giving/facebook',
                doneeId,
                tag,
            ),
            SOCIAL_MEDIA_GIVING_TWITTER: appendAdditionalSearchParams(
                '/fundraising-tools/social-media-giving/twitter',
                doneeId,
                tag,
            ),
            SOCIAL_MEDIA_GIVING_INSTAGRAM: appendAdditionalSearchParams(
                '/fundraising-tools/social-media-giving/instagram',
                doneeId,
                tag,
            ),
            SOCIAL_MEDIA_GIVING_YOUTUBE: appendAdditionalSearchParams(
                '/fundraising-tools/social-media-giving/youtube',
                doneeId,
                tag,
            ),
            SNAP_GIVE: appendAdditionalSearchParams(
                '/fundraising-tools/snap-give',
                doneeId,
                tag,
            ),
            GIVELITHON: appendAdditionalSearchParams(
                '/givelithon',
                doneeId,
                tag,
            ),
            LAUNCH_GIVELITHON: (envelopeId: number) =>
                appendAdditionalSearchParams(
                    `/givelithon/${envelopeId}`,
                    doneeId,
                    tag,
                ),
        },
        GIVELIFY_BUTTON: (encodedDoneeId: string) =>
            appendAdditionalSearchParams(
                `/givelify-button/${encodedDoneeId}`,
                doneeId,
                tag,
            ),
        DONORS: {
            ROOT: (
                tabId = 'your-donors',
                params?: {
                    [x: string]: string;
                    lastGivenOnStart: string;
                    lastGivenOnEnd: string;
                },
            ) => {
                const { lastGivenOnStart, lastGivenOnEnd, ...rest } =
                    params || {};

                return appendSearchParam('/donors', {
                    [tabId]: undefined,
                    [DONEE_SEARCH_QUERY_NAME]: doneeId?.toString(),
                    ...(params
                        ? {
                              lastGivenOnStart,
                              lastGivenOnEnd,
                              ...rest,
                          }
                        : {}),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                });
            },
            PROFILE: (donorId: string) =>
                appendAdditionalSearchParams(
                    `/donors/${donorId}`,
                    doneeId,
                    tag,
                ),
            PROFILE_DONATION_HISTORY: ({
                donorId,
                params,
            }: {
                donorId?: string;
                params?: {
                    startDate?: string;
                    endDate?: string;
                    timeFrame?: string;
                };
            } = {}) =>
                appendSearchParam(`/donors/${donorId}`, {
                    tab: 'donations',
                    ...(params?.startDate
                        ? { startDate: params?.startDate }
                        : {}),
                    ...(params?.endDate ? { endDate: params?.endDate } : {}),
                    ...(params?.timeFrame
                        ? { timeFrame: params?.timeFrame }
                        : {}),
                    ...(doneeId
                        ? { [DONEE_SEARCH_QUERY_NAME]: doneeId.toString() }
                        : {}),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            PROFILE_MESSAGE_HISTORY: ({
                donorId,
                params,
            }: {
                donorId?: string;
                params?: {
                    startDate?: string;
                    endDate?: string;
                    timeFrame?: string;
                };
            } = {}) =>
                appendSearchParam(`/donors/${donorId}`, {
                    tab: 'messages',
                    ...(params?.startDate
                        ? { startDate: params?.startDate }
                        : {}),
                    ...(params?.endDate ? { endDate: params?.endDate } : {}),
                    ...(params?.timeFrame
                        ? { timeFrame: params?.timeFrame }
                        : {}),
                    ...(doneeId
                        ? { [DONEE_SEARCH_QUERY_NAME]: doneeId.toString() }
                        : {}),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
        },
        SETTINGS: {
            ROOT: appendAdditionalSearchParams('/settings', doneeId, tag),
            APP_PROFILE: (tabId: 'profile' | 'receipt', newDoneeId = doneeId) =>
                appendSearchParam('/settings/app-profile', {
                    tab: tabId,
                    [DONEE_SEARCH_QUERY_NAME]: newDoneeId?.toString(),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            QUICK_GIVE: appendAdditionalSearchParams(
                '/settings/quick-give',
                doneeId,
                tag,
            ),
            ENVELOPES: (tabId: 'active' | 'inactive', newDoneeId = doneeId) =>
                appendSearchParam('/settings/envelopes', {
                    [tabId]: undefined,
                    [DONEE_SEARCH_QUERY_NAME]: newDoneeId?.toString(),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            USERS: (newDoneeId = doneeId) =>
                appendAdditionalSearchParams(
                    '/settings/users',
                    newDoneeId,
                    tag,
                ),
            BANK_INFO: (tabId: 'account' | 'rep') =>
                appendSearchParam('/settings/banking', {
                    tab: tabId,
                    [DONEE_SEARCH_QUERY_NAME]: doneeId?.toString(),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            ORG_INFO: appendAdditionalSearchParams(
                '/settings/organization_info',
                doneeId,
                tag,
            ),
            CAMPUSES: appendAdditionalSearchParams(
                '/settings/campuses',
                doneeId,
                tag,
            ),
            MISSION_STATEMENT: appendAdditionalSearchParams(
                '/settings/mission-statement',
                doneeId,
                tag,
            ),
        },
        REPORTS: {
            ROOT: appendAdditionalSearchParams('/reports', doneeId, tag),
            GENERATE_REPORTS: (generating?: boolean) =>
                appendSearchParam('/reports/generate', {
                    ...(generating ? { generating: undefined } : {}),
                    [DONEE_SEARCH_QUERY_NAME]: doneeId?.toString(),
                    ...(tag && { [tag]: GTM_TAG_VALUE }),
                }),
            LEGACY_REPORTS: appendAdditionalSearchParams(
                '/reports/legacy',
                doneeId,
                tag,
            ),
            LEGACY_REPORTS_DONATIONS: appendAdditionalSearchParams(
                '/reports/legacy/donations',
                doneeId,
                tag,
            ),
            LEGACY_REPORTS_DISBURSMENTS: appendAdditionalSearchParams(
                '/reports/legacy/disbursments',
                doneeId,
                tag,
            ),
            LEGACY_REPORTS_DONORS: appendAdditionalSearchParams(
                '/reports/legacy/donors',
                doneeId,
                tag,
            ),
            LEGACY_REPORTS_IMPORT_FRIENDLY: appendAdditionalSearchParams(
                '/reports/legacy/import-friendly',
                doneeId,
                tag,
            ),
            LEGACY_REPORTS_BY_ENVELOPE: appendAdditionalSearchParams(
                '/reports/legacy/by-envelope',
                doneeId,
                tag,
            ),
            LEGACY_REPORTS_BY_DONOR: appendAdditionalSearchParams(
                '/reports/legacy/by-donor',
                doneeId,
                tag,
            ),
            REPORTS_LIST: appendAdditionalSearchParams(
                '/reports/list',
                doneeId,
                tag,
            ),
            REPORT_HISTORY: appendAdditionalSearchParams(
                '/reports/history',
                doneeId,
                tag,
            ),
            REPORT_DONATIONS: appendAdditionalSearchParams(
                '/reports/generate/donations',
                doneeId,
                tag,
            ),
            REPORT_BANK_DEPOSITS: appendAdditionalSearchParams(
                '/reports/generate/bank-deposits',
                doneeId,
                tag,
            ),
            DONATIONS_BY_DONOR: appendAdditionalSearchParams(
                '/reports/generate/donations-by-donors',
                doneeId,
                tag,
            ),
            DONATION_BY_ENVELOPE: appendAdditionalSearchParams(
                '/reports/generate/donations-by-envelope',
                doneeId,
                tag,
            ),
            REPORT_DONATIONS_LEGACY: appendAdditionalSearchParams(
                '/reports/generate/donation-legacy',
                doneeId,
                tag,
            ),
            REPORT_DONORS: appendAdditionalSearchParams(
                '/reports/generate/donors',
                doneeId,
                tag,
            ),
            REPORT_DISBURSEMENTS: appendAdditionalSearchParams(
                '/reports/generate/disbursements',
                doneeId,
                tag,
            ),
        },
        DATA: {
            ROOT: appendAdditionalSearchParams('/data', doneeId, tag),
            INTEGRATIONS_SETUP: appendAdditionalSearchParams(
                '/data/integrations',
                doneeId,
                tag,
            ),
            INTEGRATION_CONTENT: appendAdditionalSearchParams(
                '/data/integration/content',
                doneeId,
                tag,
            ),
            INTEGRATION_SETUP: (
                type:
                    | 'F1'
                    | 'CCB'
                    | 'ACS'
                    | 'POWERCHURCH'
                    | 'SERVANTKEEPER'
                    | 'SHELBYARENA'
                    | 'ACSREALM'
                    | 'SHELBYNEXT'
                    | 'PLANNING_CENTER'
                    | 'F1GO',
            ) =>
                appendAdditionalSearchParams(
                    `/data/integrations/${type}`,
                    doneeId,
                    tag,
                ),
        },
        NOT_FOUND: '/not-found',
    },
});

export const GasRouterProvider: React.FCC = ({ children }) => {
    const { doneeId, mainDoneeId, donee } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee?.id,
        donee: state.Donee.donee,
        mainDoneeId: state.Donee.campuses?.[0].id,
    }));

    const tag = useMemo(
        () =>
            donee
                ? getGoogleTagManagerTag(
                      donee.onboarding?.hasMid,
                      donee.lastDonationDate,
                  )
                : null,
        [donee],
    );

    const doneeIdSearchParam = doneeId !== mainDoneeId ? doneeId : undefined;

    const routes = useMemo(
        () => buildRoutesObject(doneeIdSearchParam, tag),
        [doneeIdSearchParam, tag],
    );

    return (
        <GasRouterContext.Provider
            value={{
                ...routes,
            }}
        >
            {children}
        </GasRouterContext.Provider>
    );
};
