import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyTextArea } from '@givelify/givelify-ui';
import { debounce } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';

export interface MissionStatementEditorProps {
    missionStatement: string;
    onChange: (missionStatement: string) => unknown;
    onMobilePreviewRequest?: () => void;
}

const MissionStatementEditor: React.FCC<MissionStatementEditorProps> = ({
    missionStatement,
    onChange,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const [value, setValue] = useState<string>(missionStatement);
    const { placeholder } = useMemo(
        () => ({
            placeholder: t('customizeProfile.missionStatement.placeholder'),
        }),
        [t],
    );
    const handleChangeDebounced = debounce((value: string) => {
        onChange(value);
    }, 200);
    useEffect(() => {
        handleChangeDebounced(value);
    }, [value, handleChangeDebounced]);
    const handleChange = React.useCallback(
        (_e: unknown, value: string) => {
            setValue(value);
        },
        [setValue],
    );
    return (
        <GivelifyTextArea
            required
            ariaLabel={placeholder}
            boxSize="large"
            defaultValue={value}
            id={placeholder}
            label={placeholder}
            maxLength={255}
            onChange={handleChange}
            placeholder={placeholder}
            rightHelperText={`${value.length} / 255`}
            rows={4}
            value={value}
        />
    );
};

export default React.memo(MissionStatementEditor);
