import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { Stack } from '@mui/material';

export type ChartHeaderProps = {
    title: string;
    description: string;
};

const ChartHeaderComponent: React.FCC<ChartHeaderProps> = ({
    title,
    description,
}) => {
    return (
        <Stack
            alignItems="center"
            flexDirection="row"
            gap={1}
            justifyContent="center"
        >
            <GivelifyLabel text={title} variant="heading1" />
            <GivelifyLabel
                maxWidth="104px"
                text={description}
                variant="componentLabel"
            />
        </Stack>
    );
};

export const ChartHeader = React.memo(ChartHeaderComponent);
