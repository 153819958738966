import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    root: {
        bottom: '40px',

        '& .MuiSnackbarContent-root': {
            [theme.breakpoints.up(600)]: {
                minWidth: 'calc(344px - 32px)',
            },
        },

        '& .MuiSnackbarContent-message': {
            fontSize: '14px',
            lineHeight: '24px',
            color: '#E3E3E3',
            fontWeight: 'normal',
            padding: '6px 0 6px 16px',
        },
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
