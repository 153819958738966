export interface GivelithonEnvelopeProps {
    id: number;
    doneeId: number;
    name: string;
    amount: number;
    active: boolean;
    goal: number;
    time?: string;
    description?: string;
    fundId: string;
    startDate: string | null;
    endDate: string | null;
    howLong?: 'Always' | 'Timed';
}

export interface GivelithonEnvelopeResponse {
    name: string;
    amount: number;
    detail: string;
    doneeId: number;
    externalId: string;
    goal: number;
    id: number;
    offeringId: number;
    active: boolean;
    start?: string;
    end?: string;
    alwaysOn: 'Always' | 'Timed';
    donationCount: number;
}

export const mapGivelithonEnvelopeData = (
    envelope: GivelithonEnvelopeResponse,
) => {
    const mappedGivelithonEnvelope: GivelithonEnvelopeProps = {
        id: envelope.id,
        doneeId: envelope.doneeId,
        name: envelope.name,
        amount: envelope.amount,
        goal: envelope.goal,
        active: envelope.active,
        fundId: envelope.externalId,
        startDate: envelope.start,
        endDate: envelope.end,
        howLong: envelope.alwaysOn,
        description: envelope.detail,
    };
    return mappedGivelithonEnvelope;
};

export interface LaunchGivelithonDonor {
    id: number;
    name: string;
    timeDonate: string;
    picture: string;
    siteUrl?: string;
}

export interface LaunchGivelithonData {
    envelopeId: number;
    envelopeName: string;
    amount: number;
    goal: number;
}

export interface LaunchGivelithonDonorsResponse {
    id: number;
    name: string;
    photo: string;
    totalDonation: number;
    lastDonationDate: string;
}

export interface LaunchGivelithonEnvelopeResponse {
    offeringId: number;
    offeringName: string;
    goalAmount: number;
    achievedAmount: number;
    startDate: string;
}

export interface LaunchGivelithonDataResponse {
    status: number;
    data: LaunchGivelithonEnvelopeResponse;
}

export interface LaunchGivelithonDonorsDataResponse {
    status: number;
    data: LaunchGivelithonDonorsResponse[];
    total: number;
}

export const mapLaunchGivelithonEnvelopeData = (
    envelope: LaunchGivelithonEnvelopeResponse,
) => {
    const mapLaunchGivelithonEnvelope: LaunchGivelithonData = {
        envelopeId: envelope.offeringId,
        envelopeName: envelope.offeringName,
        amount: envelope.achievedAmount,
        goal: envelope.goalAmount,
    };
    return mapLaunchGivelithonEnvelope;
};

const siteUrl = process.env.REACT_APP_SITE_URL;
export const mapLaunchGivelithonDonorData = (
    donor: LaunchGivelithonDonorsResponse,
) => {
    const mapLaunchGivelithonDonor: LaunchGivelithonDonor = {
        id: donor.id,
        name: donor.name,
        timeDonate: donor.lastDonationDate,
        picture: donor.photo,
        siteUrl: siteUrl,
    };
    return mapLaunchGivelithonDonor;
};

export type getGivelithonEnvelopeResponse = {
    data: GivelithonEnvelopeResponse[];
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        path: string | null;
        per_page: number;
        to: number;
        total: number;
        total_envelopes: number;
        total_matched_envelopes: number;
    };
};
