import React from 'react';
import { useTheme } from '@material-ui/core';

interface Props {
    gap?: number;
    horizontal?: boolean;
}

const Space: React.FCC<Props> = ({ gap, horizontal }) => {
    const theme = useTheme();
    return (
        <div
            style={
                horizontal
                    ? {
                          display: 'inline-block',
                          height: '100%',
                          width: theme.spacing(gap || 5),
                      }
                    : {
                          width: '100%',
                          height: theme.spacing(gap || 5),
                      }
            }
        />
    );
};

export default React.memo(Space);
