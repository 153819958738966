import React from 'react';
import { GivelifyFilter, GivelifyLink, GivelifyMenuItem } from '@givelify/ui';
import { useAdvancedTranslation } from 'utils/i18n';
import {
    FilterContent,
    FilterContentList,
    FilterFooter,
    FilterList,
    FilterMenuItem,
} from '../components/filterMenuItemStyles';
import { EnvelopesFilterProps } from './types';

export const EnvelopesFilter: React.FCC<EnvelopesFilterProps> = ({
    onChange,
    values,
    options,
    disabled,
}) => {
    const { t, at } = useAdvancedTranslation();
    const copy = React.useMemo(
        () => ({
            fund: at('general.donee.Envelopes'),
            allFund: t('donationsActivity.text.allFund'),
            selectAllEnvelopes: at('donationsActivity.text.selectAllEnvelopes'),
            clearAll: t('donationsActivity.text.clearAll'),
        }),
        [t, at],
    );
    const [open, setOpen] = React.useState(false);
    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const toggleAll = React.useCallback(() => {
        if (values?.length === options?.length) {
            onChange([]);
            return;
        }
        onChange(options.map((l) => l.id));
    }, [onChange, options, values?.length]);
    const clearAll = React.useCallback(() => {
        onChange([]);
    }, [onChange]);
    const onItemClick = React.useCallback(
        (id: number) => {
            if (values.findIndex((item) => item === id) === -1) {
                onChange([...values, id]);
            } else {
                const newValues = values.filter((opt) => opt !== id);
                onChange(newValues);
            }
        },
        [values, onChange],
    );
    const getFilterText = React.useCallback(() => {
        if (options.length === 0) return copy.fund;
        if (values.length === options.length) {
            return `${copy.fund}: All`;
        }
        return `${copy.fund}: ${values.length}`;
    }, [options.length, copy.fund, values.length]);
    const onRenderMenuContent = React.useCallback(() => {
        return (
            <FilterContent id="envelopes-filter-options">
                <FilterList>
                    <FilterMenuItem
                        key="all"
                        data-testid="envelopes-filter-select-all"
                        id="select-all"
                        indeterminate={
                            values.length > 0 && values.length < options?.length
                        }
                        onClick={toggleAll}
                        selected={values.length === options.length}
                        text={copy.selectAllEnvelopes}
                        variant="checkbox"
                    />
                </FilterList>
                <FilterContentList>
                    {options.map((opt) => (
                        <GivelifyMenuItem
                            key={opt.id}
                            data-testid="envelopes-filter-option"
                            id={`option-${opt.id}`}
                            onClick={() => onItemClick(opt.id)}
                            selected={values.includes(opt.id)}
                            text={opt.label}
                            variant="checkbox"
                        />
                    ))}
                </FilterContentList>
                <FilterFooter>
                    <GivelifyLink
                        data-testid="filter-clear-all-button"
                        fontSize={16}
                        onClick={clearAll}
                        text={copy.clearAll}
                    />
                </FilterFooter>
            </FilterContent>
        );
    }, [
        copy.selectAllEnvelopes,
        copy.clearAll,
        toggleAll,
        values,
        options,
        clearAll,
        onItemClick,
    ]);
    return (
        <GivelifyFilter
            disabled={disabled}
            id="envelopes-filter"
            menuProps={{
                sx: {
                    '& .MuiPaper-root': {
                        paddingBottom: '0px',
                        overflow: 'hidden',
                        height: '400px',
                    },
                },
            }}
            name="Envelopes"
            onClick={onOpen}
            onClose={onClose}
            onRenderMenuContent={onRenderMenuContent}
            open={open}
            text={getFilterText()}
        />
    );
};
