import { User } from '@givelify/api';
import { isSsnValid } from '@givelify/givelify-ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import {
    GivelifyInputState,
    PrimaryRepresentativeInfo,
} from '../../@types/assets/onboarding';

export interface PrimaryRepresentativeStepOneProps {
    user: User;
    data: PrimaryRepresentativeInfo;
    onValidation: (isValid: boolean) => void;
}

export interface PrimaryRepresentativeStepTwoProps {
    data: PrimaryRepresentativeInfo;
    termsOfUseHRefClick?: () => void;
    onValidation: (isValid: boolean) => void;
}

export type PrimaryRepresentativePageProps = PrimaryRepresentativeStepOneProps &
    PrimaryRepresentativeStepTwoProps & {
        step: 1 | 2;
    };

export interface PrimaryRepresentativeStepRef {
    submit: () => PrimaryRepresentativeInfo;
}
export interface PrimaryRepresentativePageRef {
    submitStep1: () => PrimaryRepresentativeInfo;
    submitStep2: () => PrimaryRepresentativeInfo;
}

export const usePrimaryRepresentativeStepOneStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            adminTerms: {
                textTransform: 'none',
                color: theme.colors?.primaryDarkGray || '#373739',
                wordWrap: 'normal',
                letterSpacing: 0,
                fontFamily: 'Roboto',
                fontSize: 16,
                lineHeight: '22px',
                fontWeight: 400,
                marginTop: 24,
                marginBottom: 36,
                '& a.link': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        }),
);

export const isPrimaryRepresentativeStepOneDataValid = (
    firstName: string,
    lastName: string,
    title: string,
    socialSecurityNumber: string,
    birthDate: Date | null,
) => {
    const validationResult: {
        firstName: GivelifyInputState;
        lastName: GivelifyInputState;
        title: GivelifyInputState;
        socialSecurityNumber: GivelifyInputState;
        birthDate: GivelifyInputState;
    } = {
        firstName: 'success',
        lastName: 'success',
        title: 'success',
        socialSecurityNumber: 'success',
        birthDate: 'success',
    };
    let isValid = true;
    if (firstName.length === 0) {
        validationResult.firstName = 'error';
        isValid = false;
    }
    if (lastName.length === 0) {
        validationResult.lastName = 'error';
        isValid = false;
    }
    if (title.length === 0) {
        validationResult.title = 'error';
        isValid = false;
    }
    if (!isSsnValid(socialSecurityNumber)) {
        validationResult.socialSecurityNumber = 'error';
        isValid = false;
    }
    if (
        birthDate === null ||
        dayjs().subtract(18, 'year').isBefore(birthDate)
    ) {
        validationResult.birthDate = 'error';
        isValid = false;
    }
    return { isValid, validationResult };
};
