import { ErrorResponse, RequestState } from '@givelify/utils';

export interface LifeTimeData {
    data: {
        sum: number;
        donorsCount: number;
        netSum: number;
    };
}

export type LifeTimeState = RequestState<LifeTimeData>;

export const SET_LIFE_TIME_LOADING = 'SET_LIFE_TIME_LOADING';
export const SET_LIFE_TIME_SUCCESS = 'SET_LIFE_TIME_SUCCESS';
export const SET_LIFE_TIME_FAILED = 'SET_LIFE_TIME_FAILED';

export interface SetLifeTimeLoading {
    type: typeof SET_LIFE_TIME_LOADING;
}

export interface SetLifeTimeSuccess {
    type: typeof SET_LIFE_TIME_SUCCESS;
    data: LifeTimeData;
}

export interface SetLifeTimeFailed {
    type: typeof SET_LIFE_TIME_FAILED;
    error: ErrorResponse;
}

export type LifeTimeActions =
    | SetLifeTimeLoading
    | SetLifeTimeSuccess
    | SetLifeTimeFailed;
