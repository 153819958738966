import React, { useMemo, useState } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyLabel, GivelifyNotification } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { I18N_NAMESPACE } from '../../../../../../consts';
import SelectDocumentTypeButton from '../../../../components/SelectDocumentTypeButton';
import { OnboardingActions } from '../../../../OnboardingActions';
import OnboardingPage from '../../../../OnboardingPage';
import OnboardingTopSection from '../../../../OnboardingTopSection';
import { UseTaxIdContext, DocumentType } from '../../TaxIdContext';
import { usePreloadImages } from '../../UploadDocument/usePreloadImages';
import { ReactComponent as DbaDocumentSvg } from './icons/dbaDocument.svg';
import { ReactComponent as LetterSvg } from './icons/letter.svg';

const UploadDocumentIcon: React.FCC<{
    type: DocumentType;
    selectedType?: DocumentType;
    onClick: (value: DocumentType) => void;
}> = ({ type, onClick, selectedType }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.taxId.nonprofit.selectDocumentType.${type}`,
    });

    const copy = useMemo(
        () => ({
            title: scopedTranslate(`title`),
            mobileTitle: scopedTranslate(`mobileTitle`),
            title2: scopedTranslate(`title2`),
            mobileTitle2:
                type === 'determinationLetter'
                    ? scopedTranslate(`mobileTitle2`)
                    : undefined,
            title3:
                type === 'determinationLetter'
                    ? scopedTranslate(`title3`)
                    : undefined,
            alt: scopedTranslate(`alt`),
        }),
        [scopedTranslate, type],
    );

    const title1 = isMobile ? copy.mobileTitle : copy.title;
    const title2 = isMobile ? copy.mobileTitle2 : copy.title2;
    const title3 = isMobile ? undefined : copy.title3;

    const IconComponent =
        (type === 'determinationLetter' && LetterSvg) || DbaDocumentSvg;

    return (
        <SelectDocumentTypeButton
            IconComponent={IconComponent}
            onClick={() => onClick(type)}
            selectedDocumentType={selectedType}
            title1={title1}
            title2={title2}
            title3={title3}
            trackName={type}
            type={type}
        />
    );
};

const SelectDocumentType: React.FCC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    usePreloadImages();

    const {
        onBackClick,
        onCancelClick,
        onSelectDocumentContinue,
        selectedDocumentType,
        retryMessage,
        requireAdditionalInfo,
    } = UseTaxIdContext();

    const [internalDocumentType, setInternalDocumentType] = useState<
        DocumentType | undefined
    >(selectedDocumentType);

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.taxId.church.selectDocumentType`,
    });

    const { header, description, warningMessage } = useMemo(
        () => ({
            header: scopedTranslate('header'),
            description: scopedTranslate('description'),
            warningMessage: scopedTranslate('warningMessage'),
        }),
        [scopedTranslate],
    );

    return (
        <TrackingProvider
            trackPageVisit
            pageName="EIN Federal Tax Id Upload doc"
        >
            <OnboardingPage
                content={
                    <>
                        <GivelifyLabel text={header} variant={'heading1S'} />
                        <GivelifyLabel
                            style={{
                                marginTop: 12,
                                marginBottom: 24,
                            }}
                            text={description}
                            variant={isMobile ? 'body2' : 'body1'}
                        />
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                gap: 16,
                            }}
                        >
                            <UploadDocumentIcon
                                onClick={setInternalDocumentType}
                                selectedType={internalDocumentType}
                                type="dbaDocument"
                            />
                            <UploadDocumentIcon
                                onClick={setInternalDocumentType}
                                selectedType={internalDocumentType}
                                type="determinationLetter"
                            />
                        </div>
                        <OnboardingActions
                            disableContinue={!internalDocumentType}
                            onBack={onBackClick}
                            onCancel={onCancelClick}
                            onContinue={() =>
                                onSelectDocumentContinue(internalDocumentType)
                            }
                        />
                    </>
                }
                header={
                    requireAdditionalInfo && (
                        <OnboardingTopSection>
                            <GivelifyNotification
                                open
                                text={retryMessage || warningMessage}
                                variant="warning"
                            />
                        </OnboardingTopSection>
                    )
                }
            />
        </TrackingProvider>
    );
};

export default SelectDocumentType;
