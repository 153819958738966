import React from 'react';
import { DesignTokens, GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { useWindowSize } from '@givelify/utils';
import {
    styled,
    Collapse,
    Stack,
    Dialog,
    ClickAwayListener,
    ButtonBase,
} from '@mui/material';
import { LegacyDashboardLink } from 'layout/components/LegacyDashboardLink';
import { TotalDonationsWidgetWrapper } from 'pages/overview/widgets';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { MenuItem } from 'store/system/types';
import {
    MOBILE_HEADER_HEIGHT,
    MOBILE_FOOTER_HEIGHT,
} from 'theme/layoutMeaserments';
import { enableOldDahsboard } from 'utils/enableOldDahsboard';
import { MenuSeparator, NavMenuItem } from '../components';

export type MoreNavLinkProps = {
    active?: boolean;
    items: MenuItem[];
    onClick: (link: string, label: string) => void;
    onExpandClick: (id: string) => void;
    onMoreToggle: (open: boolean) => void;
    openMoreMenu: boolean;
};

const MoreDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        margin: 0,
        background: 'purple',
        maxHeight: 'initial',
        maxWidth: 'initial',
        borderRadius: 0,
        boxShadow: DesignTokens.shadow.shadowLowHigh,
    },
    '& .MuiBackdrop-root': {
        top: MOBILE_HEADER_HEIGHT,
        bottom: MOBILE_FOOTER_HEIGHT,
        backgroundColor: 'initial',
    },
    top: MOBILE_HEADER_HEIGHT,
    bottom: MOBILE_FOOTER_HEIGHT,
});

const MoreDialogRoot = styled('div', {
    shouldForwardProp: (propName) => propName !== 'vh' && propName !== 'vw',
})<{ vh?: number; vw?: number }>(({ vh, vw, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: DesignTokens.color.backgroundSecondary,
    height: vh - MOBILE_HEADER_HEIGHT - MOBILE_FOOTER_HEIGHT,
    width: vw,
    padding: theme.spacing(2, 0.25, 0, 2),
    gap: theme.spacing(1),
    '.donations-holder': {
        paddingRight: theme.spacing(1.75),
    },
}));

const MoreDialogContent = styled('div')(({ theme }) => ({
    flex: 1,
    paddingRight: theme.spacing(1),
    '&:hover': {
        scrollbarWidth: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: DesignTokens.color.backgroundSecondary,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: DesignTokens.color.backgroundComponentQuaternary,
        },
    },
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        backgroundColor: DesignTokens.color.backgroundSecondary,
    },
    overflowY: 'scroll',
}));

type NavButtonProps = {
    active?: boolean;
    open?: boolean;
};

const NavButton = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open',
})<NavButtonProps>(({ active, open, theme }) => ({
    position: 'relative',
    boxShadow: 'initial',
    borderRadius: DesignTokens.measurement.borderRadiusS,
    fontSize: '20px',
    color: DesignTokens.color.textSecondary,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 0),
    gap: theme.spacing(0.5),
    '&:hover, &.Mui-focusVisible': {
        backgroundColor: DesignTokens.color.backgroundComponentSecondaryAccent,
    },
    '.nav-lbl': {
        fontSize: '10px',
        lineHeight: '12px',
        fontWeight: 800,
    },
    ...(active && {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
        color: DesignTokens.color.textAccentDefault,
    }),
    ...(open && {
        backgroundColor: DesignTokens.color.backgroundComponentSecondaryAccent,
    }),
    '.nav-icn-root': {
        width: '20px',
        height: '20px',
        fontSize: '20px',
    },
}));

export const MoreNavItem: React.FCC<MoreNavLinkProps> = ({
    active,
    onClick,
    onExpandClick,
    onMoreToggle,
    items,
    openMoreMenu,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            more: t('labels.more'),
            signInToLegacyDashboard: t('footer.signInToLegacyDashboard'),
        }),
        [t],
    );
    const campuses = useSelector((state: AppState) => state.Donee.campuses);
    const hasOldDashboard = React.useMemo(() => {
        return enableOldDahsboard(campuses);
    }, [campuses]);
    const innerSize = useWindowSize();
    const handleItemClick = React.useCallback(
        (link: string, label: string) => {
            onMoreToggle(false);
            onClick(link, label);
        },
        [onClick, onMoreToggle],
    );
    const handleClick = React.useCallback(() => {
        onMoreToggle(!openMoreMenu);
    }, [openMoreMenu, onMoreToggle]);
    const handleClose = React.useCallback(() => {
        onMoreToggle(false);
    }, [onMoreToggle]);
    return (
        <>
            <NavButton
                disableRipple
                active={active}
                data-testid="nav-item-more"
                id="nav-item-more"
                onClick={handleClick}
                open={openMoreMenu}
            >
                <div className="nav-icn-root">
                    <GivelifyIcon
                        data-testid="nav-item-more-icon"
                        fontSize="inherit"
                        variant="more"
                    />
                </div>
                <GivelifyLabel
                    bold
                    className="nav-lbl"
                    color="inherit"
                    data-testid="nav-item-more-lbl"
                    text={copy.more}
                />
            </NavButton>

            <MoreDialog
                id="mobie-nav-more-root"
                onClose={handleClose}
                open={openMoreMenu}
                role="menu"
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MoreDialogRoot
                        id="mobie-nav-more"
                        vh={innerSize.innerHeight}
                        vw={innerSize.innerWidth}
                    >
                        <div className="donations-holder" id="donations-holder">
                            <TotalDonationsWidgetWrapper
                                fullWidth
                                className="total-donations"
                            />
                        </div>
                        <MoreDialogContent id="mobile-more-menu-list">
                            {items.map((item, index) => (
                                <div key={`mob-nav-item-${index}`}>
                                    <NavMenuItem
                                        key={`nav-item-${item.label}`}
                                        item={item}
                                        onClick={handleItemClick}
                                        onExpandClick={onExpandClick}
                                        variant="mobile-expanded"
                                    />
                                    {item.hasDivider ? <MenuSeparator /> : null}
                                    {item.nested ? (
                                        <Collapse
                                            unmountOnExit
                                            in={item.isExpanded}
                                            timeout="auto"
                                        >
                                            <Stack paddingTop={1} spacing={1}>
                                                {item.nested.map(
                                                    (subItem, subIndex) => (
                                                        <NavMenuItem
                                                            key={`nav-item-${index}-${subIndex}`}
                                                            item={subItem}
                                                            onClick={
                                                                handleItemClick
                                                            }
                                                            onExpandClick={
                                                                onExpandClick
                                                            }
                                                            variant="mobile-expanded"
                                                        />
                                                    ),
                                                )}
                                            </Stack>
                                        </Collapse>
                                    ) : null}
                                </div>
                            ))}
                        </MoreDialogContent>
                        {hasOldDashboard ? (
                            <LegacyDashboardLink
                                onClick={() => onMoreToggle(false)}
                            />
                        ) : null}
                    </MoreDialogRoot>
                </ClickAwayListener>
            </MoreDialog>
        </>
    );
};
