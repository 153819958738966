import React from 'react';
import { GetEnvelopesResponse } from '@givelify/api';
import { useApiRequest } from '@givelify/utils';
import { useFilterParams } from 'api/hooks/useFilterParams';
import { getEnvelopesService } from 'api/utils/serviceProvider';
import { isEqual } from 'lodash';

const paramNames = ['envelopeIds'] as const;

export type EnvelopesFilterState = {
    loading: boolean;
    options: {
        id: number;
        label: string;
    }[];
    values: number[];
};

export const useAllEnvelopesFilter = (doneeId: number) => {
    const [filterState, setFilterState] = React.useState<EnvelopesFilterState>({
        loading: true,
        options: [],
        values: [],
    });
    const [activeEnvelopes, setActiveEnvelopes] =
        React.useState<EnvelopesFilterState>({
            loading: true,
            options: [],
            values: [],
        });
    const [inactiveEnvelopes, setInactiveEnvelopes] =
        React.useState<EnvelopesFilterState>({
            loading: true,
            options: [],
            values: [],
        });
    const service = getEnvelopesService();
    const [getAllActiveRequest, makeGetAllActiveRequest] =
        useApiRequest<GetEnvelopesResponse>();
    const [getAllInactiveRequest, makeGetAllInactiveRequest] =
        useApiRequest<GetEnvelopesResponse>();
    const prevStateRef = React.useRef([getAllActiveRequest.type]);
    const [filter, setFilter] = useFilterParams<number[], typeof paramNames>(
        [],
        paramNames,
        (state, _paramName) => {
            if (state && state.length > 0) {
                return state.join('_');
            }
            return '';
        },
        (values) => {
            const splited = values['envelopeIds'].split('_');
            const newValues = splited
                .map((value) => parseInt(value))
                .filter((x) => !isNaN(x))
                .sort();
            return newValues;
        },
    );
    React.useEffect(() => {
        void makeGetAllActiveRequest(service.getAllActiveEnvelopes(doneeId));
        void makeGetAllInactiveRequest(
            service.getAllInactiveEnvelopes(doneeId),
        );
        setFilterState({
            options: [],
            values: [],
            loading: true,
        });
        // listen only to doneeId change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doneeId]);

    React.useEffect(() => {
        if (getAllActiveRequest.type === 'REQUEST_SUCCESS') {
            const options = getAllActiveRequest.response.data.map(
                (envelope) => ({
                    id: envelope.id,
                    label: envelope.name,
                }),
            );
            setActiveEnvelopes({
                options,
                values: [],
                loading: false,
            });
        }
        prevStateRef.current.push(getAllActiveRequest.type);
        //listen only to getRequest change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllActiveRequest]);

    React.useEffect(() => {
        if (getAllInactiveRequest.type === 'REQUEST_SUCCESS') {
            const options = getAllInactiveRequest.response.data.map(
                (envelope) => ({
                    id: envelope.id,
                    label: envelope.name,
                }),
            );
            setInactiveEnvelopes({
                options,
                values: [],
                loading: false,
            });
        }
        prevStateRef.current.push(getAllActiveRequest.type);
        //listen only to getRequest change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllInactiveRequest]);

    React.useEffect(() => {
        if (!activeEnvelopes.loading && !inactiveEnvelopes.loading) {
            //merge active and inactive envelopes
            setFilterState({
                options: [
                    ...activeEnvelopes.options,
                    ...inactiveEnvelopes.options,
                ],
                values: filter,
                loading: false,
            });
        }
        prevStateRef.current.push(getAllActiveRequest.type);
        //listen only to getRequest change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeEnvelopes, inactiveEnvelopes]);

    React.useEffect(() => {
        if (!isEqual(filter, filterState.values)) {
            setFilterState({
                ...filterState,
                values: filter,
            });
        }
        //listen only to getRequest change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const onChange = (values: number[]) => {
        setFilter(values);
        setFilterState({
            ...filterState,
            values,
        });
    };

    return [filterState, onChange] as const;
};
