import React, { useMemo } from 'react';
import {
    AddressWithPhone,
    GivelifyIcon,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { formatWithLocaltime } from '@givelify/utils';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { I18N_NAMESPACE } from '../../../../consts';
import { webConfig } from '../../../../utils/webConfig';
import { OnboardingActions } from '../../OnboardingActions';
import {
    Divider,
    SummaryWrapperStyled,
} from '../../requiredBankInfo/AccountDetails/Summary/styles';
import { PrimaryRepStep } from '../types';

const ItemsWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
    },
}));

const ActionLabel = ({
    text,
    onClick,
}: {
    text: string;
    onClick: () => void;
}) => (
    <GivelifyLink
        onClick={onClick}
        text={
            <Grid
                container
                alignItems="center"
                flexDirection="row"
                gap="4px"
                justifyContent="right"
            >
                <GivelifyIcon
                    color={DesignTokens.color.textAccentDefault}
                    size={16}
                    variant="edit-outline"
                />
                {text}
            </Grid>
        }
        variant="caption1"
    ></GivelifyLink>
);

type ItemType = {
    title: string;
    description: string[] | undefined;
    navigateTo: PrimaryRepStep;
};

const SummaryItemsWrapper: React.FCC<{
    items: ItemType[];
    onEditClick: (step: PrimaryRepStep) => void;
}> = ({ items, onEditClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    return (
        <SummaryWrapperStyled
            style={{
                gridTemplateColumns: isMobile ? '1fr auto' : '3fr 3fr 1fr',
                alignSelf: 'start',
            }}
        >
            {items.map((item, index) => (
                <React.Fragment key={item.title}>
                    <GivelifyLabel
                        style={isMobile ? { gridColumn: 'span 2' } : {}}
                        text={item.title}
                        variant="body1B"
                    />
                    <div>
                        {item.description?.map((i, iIndex) => (
                            <GivelifyLabel
                                key={iIndex}
                                text={i}
                                variant="body2"
                            />
                        ))}
                    </div>
                    <ActionLabel
                        onClick={() => onEditClick(item.navigateTo)}
                        text="Edit"
                    />
                    {index !== items.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </SummaryWrapperStyled>
    );
};

interface SummaryProps {
    address: AddressWithPhone;
    ssn: string;
    firstName: string;
    lastName: string;
    title: string;
    birthDate: Date | null;
    onBack: () => void;
    onCancel: () => void;
    onSubmit: () => void;
    isLoading: boolean;
    onEditClick: (step: PrimaryRepStep) => void;
}

const Summary: React.FCC<SummaryProps> = ({
    address,
    ssn,
    firstName,
    lastName,
    title,
    birthDate,
    onEditClick,
    onBack,
    onCancel,
    onSubmit,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.primaryRepresentative.summary`,
    });

    const copy = useMemo(
        () => ({
            header: scopedTranslate('header'),
            description: scopedTranslate('description'),
            note1: scopedTranslate('note1'),
            note2: scopedTranslate('note2'),
            note3: scopedTranslate('note3'),
            continueText: scopedTranslate('continueText'),
            name: scopedTranslate('name'),
            title: scopedTranslate('title'),
            dob: scopedTranslate('dob'),
            ssn: scopedTranslate('ssn'),
            address: scopedTranslate('address'),
            phoneNumber: scopedTranslate('phoneNumber'),
        }),
        [scopedTranslate],
    );

    const items1: ItemType[] = [
        {
            title: copy.name,
            description: [`${firstName} ${lastName}`],
            navigateTo: 'info',
        },
        {
            title: copy.title,
            description: [title],
            navigateTo: 'info',
        },
        {
            title: copy.dob,
            description: [formatWithLocaltime(birthDate)],
            navigateTo: 'info',
        },
        {
            title: copy.ssn,
            description: [ssn],
            navigateTo: 'info',
        },
    ];
    const items2: ItemType[] = [
        {
            title: copy.address,
            description: [
                `${address.street} ${address.state}`,
                `${address.city} ${address.zip}`,
            ],
            navigateTo: 'address',
        },
        {
            title: copy.phoneNumber,
            description: [address.phone],
            navigateTo: 'address',
        },
    ];

    return (
        <div
            style={{
                maxWidth: 1088,
            }}
        >
            <GivelifyLabel text={copy.header} variant="heading1S" />
            <GivelifyLabel
                style={{
                    marginTop: 12,
                    marginBottom: 24,
                    color: DesignTokens.color.textSecondary,
                }}
                text={copy.description}
                variant="body1"
            />
            <ItemsWrapper>
                <SummaryItemsWrapper items={items1} onEditClick={onEditClick} />
                <SummaryItemsWrapper items={items2} onEditClick={onEditClick} />
            </ItemsWrapper>
            {!isMobile && (
                <GivelifyLabel
                    style={{
                        marginTop: 12,
                        color: DesignTokens.color.textSecondary,
                    }}
                    variant="body2"
                >
                    {copy.note1}{' '}
                    <GivelifyLink
                        href={webConfig.termsOfUse}
                        target="_blank"
                        text={copy.note2}
                    />{' '}
                    {copy.note3}
                </GivelifyLabel>
            )}

            <OnboardingActions
                continueText={copy.continueText}
                disableContinue={false}
                onBack={onBack}
                onCancel={onCancel}
                onContinue={onSubmit}
                submitButtonName="submit"
            />
        </div>
    );
};

export default Summary;
