import React from 'react';
import {
    Breadcrumbs as MUIBreadcrumbs,
    makeStyles,
    Theme,
} from '@material-ui/core';
import NavigateNextIcon from '../assets/icon/navigate-next.svg';
import { useConnectorState } from '../provider/Connector';
import { RoutePathName } from '../types';
import Label from './Label';
import Link from './Label/Link';

const useStyle = makeStyles((_: Theme) => ({
    container: {
        position: 'absolute',
        top: '8px',
    },
    link: {
        display: 'flex',
    },
}));

interface Props {
    previousPath: Exclude<
        RoutePathName,
        | 'notFound'
        | 'socialMediaGivingFacebook'
        | 'socialMediaGivingTwitter'
        | 'socialMediaGivingInstagram'
        | 'socialMediaGivingYoutube'
        | 'givelifyButton'
    >;
    currentLinkLabel: string;
}

export type LandingPageName = Props['previousPath'];

const PreviousPathLabels: { [x in LandingPageName]: string } = {
    root: 'Fundraising Tools',
    socialMediaGiving: 'Social media giving',
    onlineGiving: 'Online giving',
    givelithon: 'Givelithon',
    snapGive: 'Snap to give',
};

// TODO: Routes, Labels mapping
const Breadcrumbs: React.FCC<Props> = ({
    currentLinkLabel,
    previousPath,
}: Props) => {
    const cs = useStyle();
    const { routeUrls } = useConnectorState();

    return (
        <MUIBreadcrumbs
            aria-label="breadcrumb"
            className={cs.container}
            separator={<img alt=">" src={NavigateNextIcon} />}
        >
            <Link<'link'>
                className={cs.link}
                tag="link"
                to={routeUrls[previousPath] || ''}
                variant="body2"
            >
                {PreviousPathLabels[previousPath]}
            </Link>
            <Label variant="body2">{currentLinkLabel}</Label>
        </MUIBreadcrumbs>
    );
};

export default Breadcrumbs;
