import React, { useCallback, useEffect, useState } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { useApiRequest } from '@givelify/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { LoadTotalUnmatchedStateAsync } from '../../../../../../../store/automatedIntegration/thunks';
import { PaginatedResponse } from '../../../../../../../types/paginationTypes';
import { IntegrationTypes } from '../../../../../types';
import { matchSelect } from '../../../../../utils/integrationFormatFilterUtils';
import { CCBEnvelope, integrationUrl } from '../../types';
import { getIntegrationEnvelope } from '../automatedIntegrationRequest';
import AutoIntegrationUnmatchEnvelopeHeader from './AutoIntegrationUnmatchEnvelopeHeader';
import UnmatchEnvelopeInfiniteLoader from './UnmatchEnvelopeInfiniteLoader';

interface F1CCBUnmatchEnvelopeContentProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onSortingChange: (sorting: Sorting[]) => void;
    campusSelected: number;
    integration: IntegrationTypes;
    match: matchSelect;
    isReadOnly: boolean;
}

const AutoIntegrationUnmatchEnvelopeContent: React.FCC<
    F1CCBUnmatchEnvelopeContentProps
> = ({
    sortColumn,
    sortDirection,
    onSortingChange,
    campusSelected,
    integration,
}) => {
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));

    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);
    const onChangeRequest = useCallback(
        () => setRequestSuccess(!requestSuccess),
        [requestSuccess],
    );
    const [reset, setReset] = useState<true | undefined>();
    const [reload, setReload] = useState<boolean>(false);
    const url = useCallback(
        (pageNumber: number) => {
            return integrationUrl(
                userDoneeId,
                `envelopes?has_match=0&page=${pageNumber}&per_page=10${
                    campusSelected !== 0 ? '&campus_id=' + campusSelected : ''
                }&order_by=name&sort=${sortDirection}`,
            );
        },
        [userDoneeId, campusSelected, sortDirection],
    );
    useEffect(() => {
        setReset(true);
    }, [doneeId]);
    const onReset = () => setReset(undefined);
    const onReloaded = () => setReload(false);

    const [ccbEnvelopeResponse, requestCcbEnvelope] =
        useApiRequest<PaginatedResponse<CCBEnvelope>>();
    const [ccbEnvelopeList, setCcbEnvelopeList] = useState<CCBEnvelope[]>([]);
    useEffect(() => {
        if (ccbEnvelopeResponse.type === 'REQUEST_SUCCESS') {
            if (integration.integrationType === 'F1') {
                setCcbEnvelopeList(
                    ccbEnvelopeResponse.response.data.filter((envelope) => {
                        return envelope.parentId === null;
                    }),
                );
            } else {
                setCcbEnvelopeList(ccbEnvelopeResponse.response.data);
            }
        }
    }, [ccbEnvelopeResponse, integration.integrationType]);

    useEffect(() => {
        requestCcbEnvelope(getIntegrationEnvelope(userDoneeId));
    }, [userDoneeId, requestCcbEnvelope]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (requestSuccess) {
            setReload(true);
            dispatch(LoadTotalUnmatchedStateAsync());
            setRequestSuccess(false);
        }
    }, [requestSuccess, dispatch]);

    return (
        <>
            <AutoIntegrationUnmatchEnvelopeHeader
                integration={integration}
                onOrderClick={onSortingChange}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            />
            <UnmatchEnvelopeInfiniteLoader
                ccbEnvelopeList={ccbEnvelopeList}
                integration={integration}
                onChangeRequest={onChangeRequest}
                onReloaded={onReloaded}
                onReset={onReset}
                reload={reload}
                reset={reset}
                url={url}
            />
        </>
    );
};

export default AutoIntegrationUnmatchEnvelopeContent;
