import React from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useTimeframeFilter } from 'api/hooks';
import { BasicPageWrapper } from 'components';
import { MainFilterRow, useEnvelopesFilter } from 'components/filters';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from '../../../store';
import NoResultsScreen from '../components/NoResultsScreen';
import { RefundsInfiniteLoader } from './RefundsInfiniteLoader';

export const Refunds: React.FCC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            refundTitle: t('menu.donations.refunds'),
            refunds: t('dashboard.refunds'),
            total: t('dashboard.total'),
            depositsTab: t('bankDeposits.tabName'),
            refundsTab: t('refunds.tabName'),
        }),
        [t],
    );
    const navigate = useNavigate();
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const { PATH } = useGasRouterContext();
    const tabHrefs = React.useMemo(
        () => [PATH.DONATIONS.BANK_DEPOSITS, PATH.DONATIONS.REFUNDS],
        [PATH.DONATIONS.BANK_DEPOSITS, PATH.DONATIONS.REFUNDS],
    );
    const [timeFrame, setTimeFrame] = useTimeframeFilter();
    const [envelopesFilterValue, setEnvelopesFilterValue] = useEnvelopesFilter(
        doneeId,
        timeFrame,
    );
    const handleTabCahnge = React.useCallback(
        (index: number) => {
            if (index === 0) {
                navigate(tabHrefs[0]);
            }
        },
        [navigate, tabHrefs],
    );
    const noResults =
        envelopesFilterValue.values.length === 0 &&
        !envelopesFilterValue.loading;

    return (
        <BasicPageWrapper
            contentWrapper="legacy"
            tabProps={{
                value: 1,
                options: [
                    {
                        label: copy.depositsTab,
                        tabHref: tabHrefs[0],
                        id: 'bank-deposits-tab',
                    },
                    {
                        label: copy.refundsTab,
                        tabHref: tabHrefs[1],
                        id: 'refunds-tab',
                    },
                ],
                onChange: handleTabCahnge,
            }}
            title={copy.refundTitle}
        >
            <TrackingProvider trackPageVisit pageName={PAGE_NAME.Refunds}>
                <MainFilterRow
                    envelopesFilterProps={{
                        values: envelopesFilterValue.values,
                        options: envelopesFilterValue.options,
                        disabled: envelopesFilterValue.loading,
                        onChange: setEnvelopesFilterValue,
                    }}
                    page="refund"
                    timeFrameFilterProps={{
                        value: timeFrame,
                        onChange: setTimeFrame,
                    }}
                    totalBannerProps={{
                        position: 'row',
                        size: 'small',
                        mode: noResults ? 'empty' : 'real',
                        topText: copy.total,
                        bottomText: copy.refunds,
                    }}
                />
                {noResults ? (
                    <NoResultsScreen pageName="refund" />
                ) : (
                    <RefundsInfiniteLoader
                        doneeId={doneeId}
                        envelopeIds={
                            envelopesFilterValue.options.length ===
                            envelopesFilterValue.values.length
                                ? undefined
                                : envelopesFilterValue.values
                        }
                        envelopesLoading={envelopesFilterValue.loading}
                        timeFrame={timeFrame}
                    />
                )}
            </TrackingProvider>
        </BasicPageWrapper>
    );
};
