import React from 'react';
import { ProfileMobilePreview } from '../mobilePreviews/ProfileMobilePreview';
import { CustomizeProfileStepTwoProps } from './CustomizeProfileStepTwo';

export const CustomizeProfileMobilePreview: React.FCC<
    Omit<
        CustomizeProfileStepTwoProps,
        'setIsValid' | 'onMobilePreviewRequest' | 'settingsUsersPath'
    >
> = ({ appProfile, organizationName, organizationType }) => {
    return (
        <ProfileMobilePreview
            hideTitle
            coverUrl={appProfile.bannerImage?.croppedUrl}
            fullAddress={appProfile.organizationAddress}
            isNonprofit={organizationType === 'nonprofit'}
            organizationName={organizationName}
            representativeAvatar={appProfile.faithLeaderImage?.croppedUrl}
            size="large"
        />
    );
};
