import { DesignTokens, GivelifyLabel, GivelifyPaper } from '@givelify/ui';
import { styled } from '@mui/material';

export const MobilePreviewCase = styled(GivelifyPaper)(({ theme }) => ({
    width: 302,
    height: 605,
    position: 'relative',
    backgroundColor: 'rgba(228, 227, 232, 0.5)',
    borderRadius: 50,
}));

export const MobilePreviewContent = styled('div')(({ theme }) => ({
    position: 'absolute',
    transformOrigin: 'top left',
    left: 11,
    right: 11,
    top: 11,
    bottom: 11,
    border: '1px solid #EEEEEE',
    overflow: 'hidden',
    borderRadius: 50,
    backgroundColor: theme.palette.background.paper,
}));

export const MobilePreviewCoverWrapper = styled('div')({
    position: 'relative',
    width: 280,
    height: 157.5,
    overflow: 'hidden',
});

export const MobilePreviewCover = styled('img')({
    width: '100%',
    height: '100%',
});

export const Overlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
}));

export const OrgTitle = styled(GivelifyLabel)({
    position: 'absolute',
    transformOrigin: 'bottom left',
    bottom: 24,
    left: 0,
    right: 0,
    textAlign: 'center',
});

export const MobilePreviewPagination = styled('div')({
    position: 'absolute',
    transformOrigin: 'bottom left',
    top: 145.5,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const QuickGiveButtonBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    borderRadius: DesignTokens.measurement.borderRadiusL,
    boxShadow: DesignTokens.shadow.shadowHigh,
    padding: theme.spacing(1, 0),
}));

export const MobilePreviewBack = styled('div')({
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: DesignTokens.color.backgroundSecondary,
    fontSize: 24,
    left: 16,
    top: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
