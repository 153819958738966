import React from 'react';
import CCBMatchingHeader from './CCBMatchingHeader';
import CommonMatchingHeader from './CommonMatchingHeader';
import { MatchingHeaderProps } from './types';

const MatchingHeader: React.FCC<MatchingHeaderProps> = (props) => {
    const showCcbHeader = props.integrationType === 'CCB';

    return showCcbHeader ? (
        <CCBMatchingHeader />
    ) : (
        <CommonMatchingHeader {...props} />
    );
};

export default MatchingHeader;
