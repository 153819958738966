import React from 'react';
import {
    TablePagination,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PaginationCamelCase } from 'types/paginationTypes';
import { Donation } from '../../../DonorProfile';
import DonationMemo from './DonationMemo';

interface PaginatedMemosProps {
    donations: Donation[];
    pagination: Partial<PaginationCamelCase>;
    pageChange: (e: unknown, pageNumber: number) => unknown;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        donorDetailPaginationStyles: {
            '& button': {
                padding: theme.spacing(2),
            },
        },
    }),
);

const PaginatedMemos: React.FCC<PaginatedMemosProps> = ({
    donations,
    pagination,
    pageChange,
}) => {
    const { t } = useTranslation();
    const perPage = parseInt(pagination.perPage);
    const { donorDetailPaginationStyles } = useStyles();

    return (
        <>
            {donations.map((donation) => (
                <DonationMemo
                    key={donation.id}
                    donation={donation}
                    note={donation.note}
                />
            ))}
            {!!donations.length && (
                <TablePagination
                    backIconButtonProps={{
                        'aria-label': t('labels.previous'),
                        disabled:
                            pagination.currentPage === 1 ||
                            pagination.currentPage === 0,
                    }}
                    className={donorDetailPaginationStyles}
                    component="div"
                    count={pagination.total}
                    nextIconButtonProps={{
                        'aria-label': t('labels.next'),
                        disabled:
                            pagination.currentPage === pagination.totalPages,
                    }}
                    onPageChange={pageChange}
                    page={
                        pagination.currentPage > 0
                            ? pagination.currentPage - 1
                            : 1
                    }
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[perPage]}
                />
            )}
        </>
    );
};

export default PaginatedMemos;
