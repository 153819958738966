import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ApiEndpoints } from '../../endpoints';
import { EnvelopesQuery } from '../../queries';
import { GetEnvelopesResponse } from '../../responses';
import { IEnvelopesService } from '../interfaces';

export class EnvelopesService implements IEnvelopesService {
    public async getEnvelopesData(
        doneeId: number,
        query: EnvelopesQuery,
    ): Promise<ApiResponse<GetEnvelopesResponse>> {
        const url = ApiEndpoints.envelopes.data(doneeId, query);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEnvelopesResponse>(httpRequest);
        return result;
    }
}
