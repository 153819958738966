import React, { useContext } from 'react';
import { ApiHandler, Deposit, TRANSACTION_FILTER_VALUE } from '@givelify/api';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyInfiniteScroll } from '@givelify/ui';
import { getDateRangeHash } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { Context } from 'layout/MainLayoutContext';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import DonationsRowTable, {
    donationsRowTableStyles,
} from '../../components/DonationsRowTable';
import NoResultsScreen from '../../components/NoResultsScreen';
import BankDepositLoading from './BankDepositLoading';
import BankDepositRow from './BankDepositRow';
import { getDataForInfiniteLoader } from './service';

type BankDepositInfiniteLoaderProps = {
    timeFrame: TimeFrameValue;
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    doneeId: number;
    handleOpen: (donation: Deposit) => unknown;
};

export const BankDepositRowLoading = (
    <>
        <BankDepositLoading />
        <BankDepositLoading />
        <BankDepositLoading />
    </>
);

export const BankDepositInfiniteLoader: React.FCC<
    BankDepositInfiniteLoaderProps
> = ({ timeFrame, transactionFilter, doneeId, handleOpen }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { mainHolderDivRef } = useContext(Context);
    const { t } = useTranslation();
    const { row } = donationsRowTableStyles();
    const copy = React.useMemo(
        () => ({
            bankDepositsError: t('error.errorBankDeposits'),
            noBankDeposits: t('error.noBankDeposits'),
        }),
        [t],
    );
    const dateRangeHash = getDateRangeHash(timeFrame);
    const { data, error, fetchNextPage, hasNextPage, isFetching } =
        useInfiniteQuery(
            [
                ApiHandler.instance.donees.getDeposits.name,
                doneeId,
                dateRangeHash,
                transactionFilter,
            ],
            ({ pageParam = 1 }) =>
                getDataForInfiniteLoader(
                    doneeId,
                    pageParam,
                    timeFrame,
                    transactionFilter,
                    true,
                ),
            {
                getNextPageParam: (lastPage) => {
                    if (lastPage === undefined) return 1;
                    if (lastPage.success === false) return undefined;

                    const { pagination } = lastPage.response;
                    return pagination &&
                        pagination.currentPage < pagination.totalPages
                        ? pagination.currentPage + 1
                        : undefined;
                },
            },
        );
    const loadMore = React.useCallback(() => {
        if (!isFetching) {
            fetchNextPage();
        }
    }, [fetchNextPage, isFetching]);
    const Error = (
        <GivelifyLabel
            bold
            marginTop={16}
            text={copy.bankDepositsError}
            variant="heading5"
        />
    );
    const isEmpty =
        !data ||
        data.pages.length === 0 ||
        (data.pages[0].success && data.pages[0].response.data.length === 0);
    const loading = isFetching && (!data || data.pages.length === 0);
    const infiniteScrollProps =
        isMobile && mainHolderDivRef
            ? {
                  getScrollParent: () => mainHolderDivRef.current,
                  useWindow: false,
              }
            : {};
    return (
        <GivelifyInfiniteScroll
            empty={isEmpty}
            error={!!error}
            errorComponent={Error}
            hasMore={hasNextPage}
            loadMore={loadMore}
            loading={loading}
            loadingComponent={BankDepositRowLoading}
            zerothComponent={<NoResultsScreen pageName="bankDeposit" />}
            {...infiniteScrollProps}
        >
            {data && data.pages && (
                <>
                    <DonationsRowTable key="dep-head" />
                    {data.pages.flatMap((page, i) =>
                        page.success
                            ? page.response.data.map((d, j) => (
                                  <BankDepositRow
                                      key={`dep-${i}-${j}`}
                                      className={row}
                                      donation={d}
                                      onOpenDetail={handleOpen}
                                  />
                              ))
                            : null,
                    )}
                </>
            )}
        </GivelifyInfiniteScroll>
    );
};
