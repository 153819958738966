import React, { useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { Divider, Menu as MuiMenu, MenuItem } from '@material-ui/core';
import { useCampusesContext } from 'pages/settings/campuses/CampusesProvider';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { CampusItem, CAMPUS_ITEM_STATUS } from '../../types';
import DeleteCampusModal from '../DeleteCampusModal';
import EditModal from '../EditModal';
import useStyles from '../styles';

const Menu: React.FCC<{
    item: CampusItem;
    usersAvailable: boolean;
    appProfileAvailable: boolean;
    envelopesAvailable: boolean;
    buttonId?: string;
}> = ({ item, buttonId }) => {
    const classes = useStyles();
    const { status } = item;
    const { isEditable } = useCampusesContext();
    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem.menu',
    });

    const copy = useMemo(
        () => ({
            addUsers: scopedTranslate('addUsers'),
            editAppProfile: scopedTranslate('editAppProfile'),
            editCampusName: scopedTranslate('editCampusName'),
            editEnvelopes: scopedTranslate('editEnvelopes'),
            deleteCampus: scopedTranslate('deleteCampus'),
        }),
        [scopedTranslate],
    );

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuOpenClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuCloseClick = () => {
        setAnchorEl(null);
    };

    const isApproved = status === CAMPUS_ITEM_STATUS.APPROVED;
    const isPending = status === CAMPUS_ITEM_STATUS.PENDING;

    const shouldShowMenu = isApproved || (isPending && isEditable);
    const isMenuOpen = !!anchorEl;

    const addUsersClick = () => {
        navigate(PATH.SETTINGS.USERS(item.id));
    };

    const editAppProfileClick = () => {
        navigate(PATH.SETTINGS.APP_PROFILE('profile', item.id));
    };

    const editEnvelopesClick = () => {
        navigate(PATH.SETTINGS.ENVELOPES('active', item.id));
    };

    if (!shouldShowMenu) return null;

    return (
        <>
            <GivelifyButton
                className={classes.edit}
                color="neutralGrey"
                iconVariant="more"
                id={buttonId}
                onClick={handleMenuOpenClick}
                variant="ghost"
            />
            {showEditModal && (
                <EditModal
                    data={item}
                    onClose={() => {
                        setShowEditModal(false);
                    }}
                    open={showEditModal}
                />
            )}
            {showDeleteModal && (
                <DeleteCampusModal
                    campusId={item.id}
                    onClose={() => setShowDeleteModal(false)}
                    open={showDeleteModal}
                />
            )}
            {isMenuOpen && (
                <MuiMenu
                    keepMounted
                    anchorEl={anchorEl}
                    classes={{
                        paper: classes.menuPaper,
                    }}
                    id="simple-menu"
                    onClose={handleMenuCloseClick}
                    open={isMenuOpen}
                >
                    {isApproved && (
                        <MenuItem
                            className={classes.menuItem}
                            onClick={addUsersClick}
                        >
                            <GivelifyLabel
                                text={copy.addUsers}
                                variant="body2"
                            />
                        </MenuItem>
                    )}
                    {isApproved && (
                        <MenuItem
                            className={classes.menuItem}
                            onClick={editAppProfileClick}
                        >
                            <GivelifyLabel
                                text={copy.editAppProfile}
                                variant="body2"
                            />
                        </MenuItem>
                    )}
                    {isEditable && (
                        <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                                handleMenuCloseClick();
                                setShowEditModal(true);
                            }}
                        >
                            <GivelifyLabel
                                text={copy.editCampusName}
                                variant="body2"
                            />
                        </MenuItem>
                    )}
                    {isApproved && (
                        <MenuItem
                            className={classes.menuItem}
                            onClick={editEnvelopesClick}
                        >
                            <GivelifyLabel
                                text={copy.editEnvelopes}
                                variant="body2"
                            />
                        </MenuItem>
                    )}
                    {isEditable && (
                        <>
                            <Divider />
                            <MenuItem
                                className={classes.menuItem}
                                onClick={() => {
                                    handleMenuCloseClick();
                                    setShowDeleteModal(true);
                                }}
                            >
                                <GivelifyLabel
                                    text={copy.deleteCampus}
                                    variant="body2"
                                />
                            </MenuItem>
                        </>
                    )}
                </MuiMenu>
            )}
        </>
    );
};

export default Menu;
