import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyLink } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { Dayjs } from 'dayjs';
import ReportsPageHeader from 'pages/reports/components/ReportsPageHeader';
import {
    reportItemStyles,
    legacyReportStyles,
} from 'pages/reports/LegacyReports/components/style';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAdvancedTranslation } from 'utils/i18n';
import { ReportsHeaderPageName } from '../report/models';
import { OLD_DASHBOARD_REPORT_GENERATE_PAGE } from './cleverTapEvents';
import LegacyReportForm from './LegacyReportForm';
import LegacyReportInfo from './LegacyReportInfo';

interface LegacyReportItemContextProps {
    type: 'custom' | 'quickbooks';
    name: string;
    timeFrame: TimeFrameValue;
    onTimeFrameChange: (timeFrame: TimeFrameValue) => unknown;
    format: string;
    onFormatFileChange: (value: boolean) => void;
    hasFormatFile: boolean;
    onSubmit: (
        startDate: Dayjs,
        endDate: Dayjs,
        pageName: string,
        excludeEmptyEnvelopes?: boolean,
    ) => void;
    isLoading?: boolean;
    page?: ReportsHeaderPageName;
    pageName?: string;
}

const LegacyReportItemContext: React.FCC<LegacyReportItemContextProps> = ({
    type,
    name,
    timeFrame,
    onTimeFrameChange,
    format,
    onFormatFileChange,
    hasFormatFile,
    onSubmit,
    isLoading,
    page,
    pageName,
}) => {
    const { PATH } = useGasRouterContext();
    const classes = reportItemStyles();
    const legacyStyles = legacyReportStyles();

    const navigate = useNavigate();
    const { t } = useAdvancedTranslation();

    const backToReportGenerate = () => {
        navigate(PATH.REPORTS.GENERATE_REPORTS());
    };

    const translated = useMemo(
        () => ({
            backToReports: t('reports.legacyReportsDashboard.backToReports'),
            historyLink: t(
                'reports.legacyReportsDashboard.viewPreviousReports',
            ),
            donations: {
                pageName: t('reports.legacyReportsDashboard.item.donations'),
                pageDescription: t(
                    'reports.legacyReportsDashboard.item.donationsDescription',
                ),
            },
            disbursments: {
                pageName: t(
                    'reports.legacyReportsDashboard.item.disbursements',
                ),
                pageDescription: t(
                    'reports.legacyReportsDashboard.item.disbursementsDescription',
                ),
            },
            donors: {
                pageName: t('reports.legacyReportsDashboard.item.donors'),
                pageDescription: t(
                    'reports.legacyReportsDashboard.item.donorsDescription',
                ),
            },
            'import-friendly': {
                pageName: t(
                    'reports.legacyReportsDashboard.item.importFriendly',
                ),
                pageDescription: t(
                    'reports.legacyReportsDashboard.item.importFriendlyDescription',
                ),
            },
            'by-envelope': {
                pageName: t(
                    'reports.legacyReportsDashboard.item.quickBooksEnvelope',
                ),
                pageDescription: t(
                    'reports.legacyReportsDashboard.item.quickBooksEnvelopeDescription',
                ),
            },
            'by-donor': {
                pageName: t(
                    'reports.legacyReportsDashboard.item.quickBooksDonor',
                ),
                pageDescription: t(
                    'reports.legacyReportsDashboard.item.quickBooksDonorDescription',
                ),
            },
        }),
        [t],
    );

    const givelifyIconVariants = {
        donations: 'hand-holding-usd-fill',
        disbursments: 'bank-deposits',
        donors: 'donors-old',
        'import-friendly': 'import-friendly',
        'by-envelope': 'by-envelope-fill',
        'by-donor': 'by-donor-fill',
    };

    const viewHistory = () => {
        navigate(PATH.REPORTS.REPORT_HISTORY);
    };

    const BackToReports = (
        <>
            <GivelifyIcon
                className={classes.backToReportsIcon}
                fontSize={24}
                variant="left-arrow"
            />
            <span>{translated.backToReports}</span>
        </>
    );

    return (
        <TrackingProvider
            trackPageVisit
            pageName={OLD_DASHBOARD_REPORT_GENERATE_PAGE(pageName)}
        >
            <ReportsPageHeader page={page ? page : 'GenerateReport'} />
            <Grid
                container
                className={
                    classes.navigarionLinks +
                    ' ' +
                    legacyStyles.viewPreviousReports
                }
                justifyContent="space-between"
            >
                <GivelifyLink
                    className={classes.backToReports}
                    data-testid="legacy-back-to-reports"
                    onClick={() => navigate(PATH.REPORTS.LEGACY_REPORTS)}
                    text={BackToReports}
                />
                <GivelifyLink
                    name="View Previous Reports"
                    onClick={viewHistory}
                    text={translated.historyLink}
                />
            </Grid>

            <LegacyReportInfo
                description={translated[pageName].pageDescription}
                icon={
                    <GivelifyIcon
                        className={legacyStyles.reportIcon}
                        fontSize={42}
                        variant={givelifyIconVariants[pageName]}
                    />
                }
                name={translated[pageName].pageName}
            />

            <div className={classes.reportItemContent}>
                <LegacyReportForm
                    backToReports={backToReportGenerate}
                    format={format}
                    hasFormatFile={hasFormatFile}
                    isLoading={isLoading}
                    name={name}
                    onFormatFileChange={onFormatFileChange}
                    onSubmit={onSubmit}
                    onTimeFrameChange={onTimeFrameChange}
                    pageName={pageName}
                    timeFrame={timeFrame}
                    type={type}
                />
            </div>
        </TrackingProvider>
    );
};

export default LegacyReportItemContext;
