import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/material';
import { GivelifyButton } from '../button';
import { GivelifyIcon, GivelifyIconVariant } from '../icon';
import { GivelifyLabel } from '../label';
import { StyledAction, StyledMessage, StyledSnackbar } from './styles';
import { GivelifyNotificationProps } from './types';

export const GivelifyNotification: React.FCC<GivelifyNotificationProps> = ({
    variant,
    text,
    description,
    showDismiss,
    dismissLabel,
    customMessage,
    actionButtonProps,
    global,
    onClose,
    ...props
}) => {
    const onDismiss = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (onClose) {
                onClose(event, 'dismiss');
            }
        },
        [onClose],
    );
    const innerClose = React.useCallback(
        (
            event: React.SyntheticEvent<any> | Event,
            reason: SnackbarCloseReason | 'dismiss',
        ) => {
            if (onClose) {
                onClose(event, reason);
            }
        },
        [onClose],
    );
    const action = React.useMemo(
        () => (
            <StyledAction data-testid="gvl-ntf-action-root">
                {actionButtonProps ? (
                    <GivelifyButton
                        data-testid="gvl-ntf-action-btn"
                        {...actionButtonProps}
                        className={mergeClassNames(
                            'ntf-act',
                            showDismiss
                                ? `ntf-act-sec-${variant}`
                                : `ntf-act-prm-${variant}`,
                        )}
                        text={actionButtonProps.text || ''}
                        variant={showDismiss ? 'secondary' : 'primary'}
                    />
                ) : null}
                {showDismiss ? (
                    <GivelifyButton
                        disableRipple
                        className="ntf-act-dismiss"
                        data-testid="gvl-ntf-action-dismiss"
                        onClick={onDismiss}
                        size="small"
                        text={dismissLabel ? dismissLabel : 'Dismiss'}
                        variant="ghost"
                    />
                ) : null}
            </StyledAction>
        ),
        [actionButtonProps, showDismiss, variant, dismissLabel, onDismiss],
    );
    const iconVariant: GivelifyIconVariant = React.useMemo(
        () =>
            variant === 'success'
                ? 'success-box-icon'
                : variant === 'error'
                ? 'error-box-icon'
                : variant === 'info'
                ? 'info-box-icon'
                : 'warning-box-icon',
        [variant],
    );
    const message = React.useMemo(
        () => (
            <StyledMessage
                data-testid="gvl-ntf-message-root"
                noActions={actionButtonProps === undefined && !showDismiss}
            >
                <GivelifyLabel
                    className="ntf-title"
                    data-testid="gvl-ntf-text"
                    variant="body2B"
                >
                    <GivelifyIcon className="ntf-icon" variant={iconVariant} />
                    {text}
                </GivelifyLabel>
                {description ? (
                    typeof description === 'string' ? (
                        <GivelifyLabel
                            className="ntf-content"
                            data-testid="gvl-ntf-description"
                            variant="body2"
                        >
                            {description}
                        </GivelifyLabel>
                    ) : (
                        description
                    )
                ) : null}
            </StyledMessage>
        ),
        [actionButtonProps, showDismiss, iconVariant, text, description],
    );
    return (
        <StyledSnackbar
            {...props}
            action={customMessage ? undefined : action}
            data-testid="gvl-ntf-root"
            global={global}
            message={customMessage ? customMessage : message}
            onClose={innerClose}
            variant={variant}
        />
    );
};
