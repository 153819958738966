import React, { useEffect, useMemo, useState, createRef } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyColorPalette } from '@givelify/givelify-ui';
import {
    PAGE_NAME,
    TrackingProvider,
    useTrackingContext,
} from '@givelify/utils';
import dayjs from 'dayjs';
import Confetti from 'react-confetti';
import FlipMove from 'react-flip-move';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import givelifyLogo from '../assets/images/givelify-logo.svg';
import { DigitRoll } from '../CountUp';
import { EVENTS } from '../utils/clevertap';
import { useTranslation } from '../utils/i18n';
import { DonorItem, DonorProps } from './components/DonorItem';
import { GivelithonProgressBar } from './components/GivelithonProgressBar';
import { GivelithonSetting } from './components/GivelithonSetting';
import { givelithonLaunchModalStyle } from './style';

export interface GivelithonLaunchModalProps {
    envelopeId: number;
    doneeName: string;
    doneeType?: string;
    name: string;
    goal: number;
    amount: number;
    totalDonor: number;
    donorList: DonorProps[];
    thankYouTimeOut?: number;
    siteUrl: string;
}

const LaunchPage: React.FCC<GivelithonLaunchModalProps> = (props) => {
    const { trackEvent } = useTrackingContext();
    const { amount } = props;
    const { t } = useTranslation(undefined, props.doneeType);
    const classes = givelithonLaunchModalStyle();
    const [today, setDate] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    const date = dayjs(today).format('MMM DD, YYYY');
    const time = dayjs(today).format('hh:mm a');

    const copy = useMemo(
        () => ({
            totalDonation: t('launchGivelithon.totalDonation'),
            getGivingGoing: t('launchGivelithon.getGivingGoing'),
            thankYou: t('launchGivelithon.thankYou'),
        }),
        [t],
    );

    const donorsCopy = useMemo(
        () => t('launchGivelithon.donor', { count: props.totalDonor }),
        [t, props.totalDonor],
    );

    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const fullScreenFunction = useFullScreenHandle();
    const onFullScreen = () => {
        fullScreenFunction.enter();
        setFullScreen(true);
    };
    const exitFullScreen = () => {
        fullScreenFunction.exit();
        setFullScreen(false);
    };

    const [hideDonors, setHideDonors] = useState<boolean>(false);
    const onHideDonors = () => setHideDonors(true);
    const onShowDonors = () => setHideDonors(false);

    const [completed, setCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (amount >= props.goal) {
            if (props.goal > 0) {
                setCompleted(true);
            } else {
                setCompleted(false);
            }
        }
        setTimeout(() => {
            setCompleted(false);
        }, props.thankYouTimeOut || 5000);
        // listen only to amount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);

    useEffect(() => {
        if (fullScreenFunction.active) {
            setFullScreen(true);
        } else {
            setFullScreen(false);
        }
    }, [fullScreenFunction]);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        if (completed) {
            trackEvent(EVENTS.GOAL_COMPLETED, {
                doneeName: props.doneeName,
                envelopeId: props.envelopeId,
                amount,
                goal: props.goal,
            });
        }
        // listen only to completed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completed]);

    return (
        <FullScreen handle={fullScreenFunction}>
            <div className={classes.launchGivelithon}>
                <GivelithonSetting
                    exitFullScreen={exitFullScreen}
                    fullScreen={fullScreen}
                    hideDonors={hideDonors}
                    onFullScreen={onFullScreen}
                    onHideDonors={onHideDonors}
                    onShowDonors={onShowDonors}
                />
                <div className={classes.modalContent}>
                    <div
                        className={`${classes.totalContent} ${
                            completed ? ' complete-goal' : ''
                        } ${hideDonors ? ' hide-donors' : ''}`}
                        data-testid="total-content"
                    >
                        {completed && (
                            <div data-testid="completed-confetti">
                                <Confetti />
                            </div>
                        )}
                        <GivelifyLabel
                            id="giving-partner-name"
                            marginBottom={24}
                            text={props.doneeName}
                            variant="heading2"
                        />
                        <GivelifyLabel
                            bold
                            wordBreak
                            className={classes.envelopeName}
                            id="launch-envelope-name"
                            text={props.name}
                            variant="heading1"
                        />
                        <div
                            className={classes.countAmount}
                            data-amount-received={amount}
                            id="launch-count-amount"
                        >
                            <DigitRoll
                                delay={2}
                                height={8}
                                num={amount}
                                width={4.5}
                            />
                        </div>
                        {amount > 0 ? (
                            <GivelifyLabel
                                className={classes.totalDonation}
                                color={GivelifyColorPalette.neutralGrey75}
                                dataTestId="total-donation"
                                text={copy.totalDonation}
                                variant="body1"
                            />
                        ) : (
                            <GivelifyLabel
                                className={classes.totalDonation}
                                color={GivelifyColorPalette.primary}
                                dataTestId="get-giving-going"
                                text={copy.getGivingGoing}
                                variant="heading2"
                            />
                        )}
                        {completed && (
                            <GivelifyLabel
                                className={classes.thankYou}
                                text={copy.thankYou}
                                variant="heading1"
                            />
                        )}
                        <img
                            alt="Givelify"
                            className={classes.logo}
                            src={givelifyLogo}
                        />
                    </div>
                    <div className={classes.progressContent}>
                        {props.goal > 0 ? (
                            <GivelithonProgressBar
                                goal={props.goal}
                                value={amount}
                            />
                        ) : null}
                    </div>
                    <div
                        className={`${classes.listDonorContent} ${
                            completed ? ' complete-goal' : ''
                        } ${hideDonors ? ' hide-donors' : ''}`}
                    >
                        <div className={classes.listDonorHeader}>
                            <GivelifyLabel
                                id="launch-total-donor"
                                marginRight="auto"
                                text={donorsCopy}
                                variant="heading4"
                            />
                            <GivelifyLabel
                                color={GivelifyColorPalette.neutralGrey}
                                dataTestId="launch-date"
                                id="launch-date"
                                text={date}
                                variant="h2Mobile"
                            />
                            <GivelifyLabel
                                color={GivelifyColorPalette.neutralPlatinum}
                                marginLeft={8}
                                marginRight={8}
                                text="|"
                                variant="h2Mobile"
                            />
                            <GivelifyLabel
                                color={GivelifyColorPalette.neutralGrey}
                                dataTestId="launch-time"
                                id="launch-time"
                                text={time}
                                variant="h2Mobile"
                            />
                        </div>
                        {!hideDonors && (
                            <div
                                className={classes.listDonor}
                                data-testid="launch-donor-list"
                                id="launch-donor-list"
                            >
                                <FlipMove
                                    duration={500}
                                    enterAnimation="accordionVertical"
                                    leaveAnimation="fade"
                                    staggerDurationBy="30"
                                >
                                    {props.donorList?.map((donor) => (
                                        <div key={donor.id} ref={ref}>
                                            <DonorItem
                                                id={donor.id}
                                                name={donor.name}
                                                picture={donor.picture}
                                                siteUrl={props.siteUrl}
                                                timeDonate={donor.timeDonate}
                                            />
                                        </div>
                                    ))}
                                    {props.donorList?.length === 0 && (
                                        <div className={classes.noDonor} />
                                    )}
                                </FlipMove>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </FullScreen>
    );
};

export const GivelithonLaunch: React.FCC<GivelithonLaunchModalProps> = (
    props,
) => {
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.GivelithonLaunch}>
            <LaunchPage {...props} />
        </TrackingProvider>
    );
};
