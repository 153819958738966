import React from 'react';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '@givelify/api';
import { GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { SwipeableDrawer, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    EnvelopesFilterAccordion,
    EnvelopesFilterProps,
} from './envelopesFilter';
import { MessageFilterAccordion, MessageFilterProps } from './messageFilter';
import {
    TransactionFilterAccordion,
    TransactionFilterProps,
} from './transactionFilter';

const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

type MainFilterDrawerProps = {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    envelopesFilterProps?: EnvelopesFilterProps;
    transactionFilterProps?: TransactionFilterProps;
    messageFilterProps?: MessageFilterProps;
};

export const MainFilterDrawer: React.FCC<MainFilterDrawerProps> = ({
    open,
    onOpen,
    onClose,
    envelopesFilterProps,
    messageFilterProps,
    transactionFilterProps,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            filter: t('bankDeposits.text.filter'),
            apply: t('donationsActivity.text.apply'),
        }),
        [t],
    );
    const [openFilterDrawer, setOpenFilterDrawer] = React.useState<
        'envelopes' | 'message' | 'transaction' | 'none'
    >('none');
    const [envelopesFilterValue, setEnvelopesFilterValue] = React.useState<
        number[]
    >([]);
    const [messageFilterValue, setMessageFilterValue] = React.useState<
        MESSAGE_FILTER_VALUE[]
    >([]);
    const [transactionFilterValue, setTransactionFilterValue] = React.useState(
        TRANSACTION_FILTER_VALUE.ALL,
    );
    const onReset = React.useCallback(() => {
        setEnvelopesFilterValue(envelopesFilterProps?.values);
        setTransactionFilterValue(transactionFilterProps?.value);
        setMessageFilterValue(messageFilterProps?.values);
    }, [envelopesFilterProps, transactionFilterProps, messageFilterProps]);
    const handleApply = React.useCallback(() => {
        envelopesFilterProps?.onChange(envelopesFilterValue);
        transactionFilterProps?.onChange(transactionFilterValue);
        messageFilterProps?.onChange(messageFilterValue);
        onClose();
    }, [
        envelopesFilterProps,
        envelopesFilterValue,
        transactionFilterProps,
        transactionFilterValue,
        messageFilterProps,
        messageFilterValue,
        onClose,
    ]);
    const handleClose = React.useCallback(() => {
        onReset();
        onClose();
    }, [onReset, onClose]);
    React.useEffect(() => {
        setEnvelopesFilterValue(envelopesFilterProps?.values);
    }, [envelopesFilterProps]);
    React.useEffect(() => {
        setMessageFilterValue(messageFilterProps?.values);
    }, [messageFilterProps]);
    React.useEffect(() => {
        setTransactionFilterValue(transactionFilterProps?.value);
    }, [transactionFilterProps]);

    const iOS =
        typeof navigator !== 'undefined' &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
        <SwipeableDrawer
            PaperProps={{
                sx: {
                    borderRadius: '16px 16px 0 0',
                    minHeight: '75vh',
                    maxHeight: '75vh',
                },
            }}
            anchor="bottom"
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            onClose={handleClose}
            onOpen={onOpen}
            open={open}
        >
            <TitleRow>
                <GivelifyLabel
                    fontSize={18}
                    text={copy.filter}
                    variant="heading3M"
                />
                <GivelifyLink
                    fontSize={18}
                    onClick={handleApply}
                    text={copy.apply}
                />
            </TitleRow>
            {envelopesFilterProps ? (
                <EnvelopesFilterAccordion
                    disabled={envelopesFilterProps.disabled}
                    expanded={openFilterDrawer === 'envelopes'}
                    onChange={setEnvelopesFilterValue}
                    onExpandChange={(_event, expanded) =>
                        setOpenFilterDrawer(expanded ? 'envelopes' : 'none')
                    }
                    options={envelopesFilterProps.options}
                    values={envelopesFilterValue}
                />
            ) : null}
            {messageFilterProps ? (
                <MessageFilterAccordion
                    expanded={openFilterDrawer === 'message'}
                    onChange={setMessageFilterValue}
                    onExpandChange={(_event, expanded) =>
                        setOpenFilterDrawer(expanded ? 'message' : 'none')
                    }
                    values={messageFilterValue}
                />
            ) : null}
            {transactionFilterProps ? (
                <TransactionFilterAccordion
                    expanded={openFilterDrawer === 'transaction'}
                    onChange={setTransactionFilterValue}
                    onExpandChange={(_event, expanded) =>
                        setOpenFilterDrawer(expanded ? 'transaction' : 'none')
                    }
                    value={transactionFilterValue}
                />
            ) : null}
        </SwipeableDrawer>
    );
};
