export const GTM_TAG_VALUE = '1';

export type GoogleTagManagerTag = 'Onboarding' | 'MIDApproved' | undefined;

export function getGoogleTagManagerTag(
    hasMid: boolean,
    lastDonationDate: Date | null,
): GoogleTagManagerTag {
    return hasMid
        ? lastDonationDate
            ? undefined
            : 'MIDApproved'
        : 'Onboarding';
}
