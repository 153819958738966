import React, { useCallback, useEffect, useMemo } from 'react';
import { FullWidthModal, FullWidthModalProps } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';

interface OnboardingFooter {
    footer:
        | {
              submitText?: string;
              onCancel: () => void;
              onSubmit: () => void;
              disableSubmit: boolean;
              isLoading: boolean;
              setButtonBringToFront: (value: boolean) => void;
          }
        | undefined;
}

type FullWidthModalOnboardingProps = Omit<
    FullWidthModalProps,
    'footer' | 'onClose'
> &
    OnboardingFooter & { onClose?: () => void };

export const FullWidthModalOnboarding: React.FCC<
    FullWidthModalOnboardingProps
> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { cancelText, submitText } = useMemo(
        () => ({
            cancelText: t('labels.cancel'),
            submitText: t('labels.submit'),
        }),
        [t],
    );
    const handleClose = useCallback(() => {
        if (props.onClose) props.onClose();
    }, [props]);

    useEffect(() => {
        if (!props.footer) return;

        props.footer.setButtonBringToFront(false);
        return () => props.footer?.setButtonBringToFront(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!props.open) return null;
    if (props.footer === undefined)
        return (
            <FullWidthModal
                {...props}
                footer={undefined}
                onClose={handleClose}
                onStepClick={props.onStepClick}
            />
        );
    const footer = {
        ...props.footer,
        cancelText,
        submitText: props.footer.submitText || submitText,
    };
    return (
        <FullWidthModal
            {...props}
            footer={footer}
            onClose={handleClose}
            onStepClick={props.onStepClick}
        />
    );
};
