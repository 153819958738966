import { GivelifyLabel } from '@givelify/ui';
import { Box, styled } from '@mui/material';

export const MissionContainer = styled('div')({
    display: 'flex',
});
export const MissionEditorSide = styled('div')(({ theme }) => ({
    maxWidth: 600,
    [theme.breakpoints.down('tablet')]: {
        maxWidth: '100%',
        marginBottom: 75,
        display: 'flex',
        flexDirection: 'column',
    },
}));
export const MissionTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('smallTablet')]: {
        order: 0,
    },
}));
export const MissionTipContainer = styled('div')(({ theme }) => ({
    marginTop: 56,
    display: 'flex',
    alignContent: 'flex-start',
    [theme.breakpoints.down('smallTablet')]: {
        order: 3,
    },
}));
export const MissionTip = styled(GivelifyLabel)({
    width: 52,
    flex: '0 0 auto',
    height: 22,
    background: '#373739',
    color: '#FFFFFF',
    borderRadius: '31px',
    textAlign: 'center',
    marginRight: 16,
    textTransform: 'none',
});
export const MissionDescriptionHolder = styled('div')({
    maxWidth: '532px',
});
export const MissionExample = styled('div')({
    marginTop: 12,
    color: '#676E77',
    maxWidth: 435,
    backgroundImage: 'url(/caption-open.svg)',
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat, no-repeat',
    padding: '6px 10px 6px 20px',
    fontSize: '14px',
    lineHeight: '18px',
});
export const MissionExampleCaptionClosed = styled('img')({
    marginLeft: 3,
    marginBottom: 3,
});
export const MissionEditor = styled(Box)(({ theme }) => ({
    marginLeft: 3,
    marginBottom: 3,
    [theme.breakpoints.down('smallTablet')]: {
        order: 1,
    },
}));
export const MissionPreviewSide = styled('div')(({ theme }) => ({
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));
export const SaveCancelButtonsWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('smallTablet')]: {
        order: 1,
        width: '100%',
        position: 'relative',
        height: 72,
    },
}));
