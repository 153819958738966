import React, { useCallback, useMemo } from 'react';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { PaginatedResponse } from 'types/paginationTypes';
import MatchingInfiniteLoader from '../../common/MatchingInfiniteLoader';
import { useMatchingStyles } from '../../DonorMatching';
import { EnvelopeMatchingRow } from '../types';
import CommonEnvelopeMatchingRow from './CommonEnvelopeMatchingRow';

interface OwnProps {
    integration: IntegrationTypes;
    onSuccess: () => void;
    url: (pageNumber: number) => string;
    reportId?: number;
    allCampuses: boolean;
    isReadOnly: boolean;
}

const EnvelopeMatchingInfiniteLoader: React.FCC<OwnProps> = ({
    integration,
    onSuccess,
    url,
    reportId,
    allCampuses,
    isReadOnly,
}) => {
    const { campuses } = useSelector((state: AppState) => ({
        campuses: state.Donee.campuses,
    }));
    const { even, odd } = useMatchingStyles();
    const { t } = useTranslation();
    const { errorMatchEnvelopes, noMatchEnvelopes } = useMemo(
        () => ({
            errorMatchEnvelopes: t('error.errorMatchEnvelopes'),
            noMatchEnvelopes: t('error.noMatchEnvelopes'),
        }),
        [t],
    );

    const campusesMap = useMemo(
        () => new Map(campuses.map((c) => [c.id, c.name])),
        [campuses],
    );

    const renderData = useCallback(
        (dataSet: PaginatedResponse<EnvelopeMatchingRow>) =>
            dataSet.data.map((r, i) => (
                <CommonEnvelopeMatchingRow
                    key={i}
                    allCampuses={allCampuses}
                    campusName={campusesMap.get(r.doneeId)}
                    className={`${i % 2 === 0 ? even : odd}`}
                    doneeId={r.doneeId}
                    externalId={r.externalId}
                    id={r.id}
                    integration={integration}
                    isReadOnly={isReadOnly}
                    name={r.name}
                    onSuccess={onSuccess}
                    reportId={reportId}
                />
            )),
        [
            allCampuses,
            even,
            integration,
            odd,
            onSuccess,
            reportId,
            campusesMap,
            isReadOnly,
        ],
    );

    return (
        <MatchingInfiniteLoader
            integration={integration}
            loadErrorText={errorMatchEnvelopes}
            noItemsText={noMatchEnvelopes}
            renderData={renderData}
            url={url}
        />
    );
};

export default React.memo(EnvelopeMatchingInfiniteLoader);
