import { GivelifyLabel } from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const Title = styled(GivelifyLabel)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const ListItem = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));
