import React from 'react';
import { Hidden } from '@mui/material';

import YouTubeLinkArrow from '../../../assets/social-media-preview/youtube-link-arrow.svg';
import YouTubeBio from '../../../assets/social-media-preview/youtube.png';

import Label from '../../../components/Label';

import { useTranslations } from '../../../utils/translations';
import { useStyle } from './style';

const Preview: React.FCC = () => {
    const cs = useStyle();

    const { preview } = useTranslations('socialMediaGiving.youtube.page', {
        preview: ['title', 'linkDesc'],
    });

    return (
        <div className={cs.previewContainer}>
            <div>
                <Label className={cs.previewTitle} variant="body3">
                    {preview.title}
                </Label>
                <img
                    alt="Livestream on YouTube"
                    className={cs.previewImage}
                    src={YouTubeBio}
                    title="youtube"
                />
            </div>

            <div className={cs.previewArrowsContainer}>
                <Hidden xsDown>
                    <img
                        alt={preview.linkDesc}
                        className={cs.linkArrow}
                        src={YouTubeLinkArrow}
                        title="indicator arrow"
                    />
                    <Label className={cs.linkArrowText} variant="body3">
                        {preview.linkDesc}
                    </Label>
                </Hidden>
            </div>
        </div>
    );
};

export default Preview;
