import {
    ApiResponse,
    getAxiosClient,
    makeApiRequest,
    TimeFrameValue,
    toFormData,
} from '@givelify/utils';
import { AxiosRequestConfig } from 'axios';
import { ApiEndpoints } from '../../endpoints';
import { MESSAGE_FILTER_VALUE } from '../../models';
import {
    UpdateBankInfoRequest,
    UpdateMailingAddressRequest,
    UpdateOrganizationInfoRequest,
    UpdatePhysicalAddressRequest,
    UpdatePrimaryRepRequest,
    UpdateSocialInfoRequest,
    UpdateTaxIdRequest,
} from '../../requests';
import {
    GetBankAccountsResponse,
    GetDepositsResponse,
    GetDoneeResponse,
    GetQuickGiveResponse,
    GetRefundedDonationsResponse,
    UpdateBankInfoResponse,
    UpdateDoneeResponse,
} from '../../responses';
import { IDoneeService } from '../interfaces';

export class DoneesService implements IDoneeService {
    public async getCurrentDonee(
        config?: AxiosRequestConfig<any> | undefined,
    ): Promise<ApiResponse<GetDoneeResponse>> {
        const url = ApiEndpoints.donees.donee();
        const httpRequest = getAxiosClient().get(url, config);
        const result = await makeApiRequest<GetDoneeResponse>(httpRequest);
        return result;
    }

    public async getBankAccounts(
        doneeId: number,
    ): Promise<ApiResponse<GetBankAccountsResponse>> {
        const url = ApiEndpoints.donees.bankAccounts(doneeId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetBankAccountsResponse>(
            httpRequest,
        );
        return result;
    }

    public async updateBankInfo(
        doneeId: number,
        payload: UpdateBankInfoRequest,
    ): Promise<ApiResponse<UpdateBankInfoResponse>> {
        const url = ApiEndpoints.donees.bankInfo(doneeId);
        const formData = toFormData(payload);
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = makeApiRequest<UpdateBankInfoResponse>(httpRequest);
        return result;
    }

    public async updatePrimaryRepresentative(
        doneeId: number,
        payload: UpdatePrimaryRepRequest,
    ): Promise<ApiResponse<unknown>> {
        const url = ApiEndpoints.donees.beneficiary(doneeId);
        const formData = toFormData(payload);
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = makeApiRequest<unknown>(httpRequest);
        return result;
    }

    public async getQuickGive(
        doneeId: number,
    ): Promise<ApiResponse<GetQuickGiveResponse>> {
        const url = ApiEndpoints.donees.quickGive(doneeId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetQuickGiveResponse>(httpRequest);
        return result;
    }

    public async updateQuickGive(
        doneeId: number,
        payload: any,
    ): Promise<ApiResponse<unknown>> {
        const url = ApiEndpoints.donees.quickGive(doneeId);
        const httpRequest = getAxiosClient().put(url, payload);
        const result = await makeApiRequest<unknown>(httpRequest);
        return result;
    }

    public async getRefundedDonations(
        doneeId: number,
        timeFrame: TimeFrameValue,
        envelopeIds: number[],
        pageNumber: number,
        messageFilter?: MESSAGE_FILTER_VALUE[],
    ): Promise<ApiResponse<GetRefundedDonationsResponse>> {
        const url = ApiEndpoints.donees.donationsRefunded(
            doneeId,
            timeFrame,
            envelopeIds,
            pageNumber,
            messageFilter,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetRefundedDonationsResponse>(
            httpRequest,
        );
        return result;
    }

    public async getDeposits(
        doneeId: number,
        timeFrame: TimeFrameValue,
        transactionFilter: any,
        onlyDate?: boolean,
        pageNumber?: number,
    ): Promise<ApiResponse<GetDepositsResponse>> {
        const url = ApiEndpoints.donees.deposits(
            doneeId,
            timeFrame,
            transactionFilter,
            onlyDate,
            pageNumber,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetDepositsResponse>(httpRequest);
        return result;
    }

    public async updateTaxId(
        doneeId: number,
        payload: UpdateTaxIdRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const formData = toFormData(payload);
        formData.append('_method', 'PATCH');
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateSocialInfo(
        doneeId: number,
        payload: UpdateSocialInfoRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateMailingAddress(
        doneeId: number,
        payload: UpdateMailingAddressRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            mailingAddress: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updatePhysicalAddress(
        doneeId: number,
        payload: UpdatePhysicalAddressRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            physicalAddress: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateReceiptState(
        doneeId: number,
        payload: boolean,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            customReceipt: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateMissionStatement(
        doneeId: number,
        payload: string,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            missionStatement: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateOrganizationInfo(
        doneeId: number,
        payload: UpdateOrganizationInfoRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = ApiEndpoints.donees.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }
}
