import React, { useMemo } from 'react';
import {
    FacebookLoading,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
    GivelifyPaper,
    GivelifyTable,
    GivelifyTableColumn,
    SortDirection,
} from '@givelify/givelify-ui';
import {
    formatWithLocaltime,
    formatWithTimezone,
    getAxiosClient,
} from '@givelify/utils';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Report } from '../../../../store/report/types';
import { reportName } from '../../utils/reportTypeFilterUtils';

interface ReportHistoryContentProps {
    data: Report[];
    currentPage: number;
    totalItems: number;
    rowsPerPage: number;
    onChangePage: (newPage: number) => void;
    sorting: {
        columnName: keyof Report;
        direction: SortDirection;
    };
    onSortingChange: (column: keyof Report, direction: SortDirection) => void;
    isLoading: boolean;
}

interface RenderCellProps<T> {
    row: T;
}

const Loading = () => (
    <FacebookLoading borderRadius={4} height={24} width={150} />
);

const useStyles = makeStyles(() => ({
    link: {
        display: 'flex',
        '& > :hover': {
            textDecoration: 'none',
        },
    },
}));

const FileCell = ({ row }: RenderCellProps<Report>) => {
    const { downloadLink, reportState, fileName } = row;
    const fileExtention = fileName.split('.');
    const { link } = useStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            download: t('reports.download', {
                fileType: fileExtention[fileExtention.length - 1],
            }),
            generating: t('reports.generating'),
        }),
        [t, fileExtention],
    );

    const download = () => {
        getAxiosClient({ responseType: 'blob', withCredentials: true })
            .get(downloadLink)
            .then((r) => {
                const href = URL.createObjectURL(r.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    };

    return reportState === 'complete' ? (
        <GivelifyLink
            className={link}
            id={`reportDownload-${row.id}`}
            name="Download"
            onClick={download}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                }}
            >
                <GivelifyIcon
                    className="downloadIcon"
                    color="primary"
                    size={16}
                    variant="download"
                />
                <GivelifyLabel
                    color="primary"
                    text={copy.download}
                    variant="body2"
                />
            </div>
        </GivelifyLink>
    ) : (
        <div
            id={`reportGenerating-${row.id}`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8,
            }}
        >
            <GivelifyIcon spin size={24} variant="loading" />
            <GivelifyLabel text={copy.generating} variant="body2" />
        </div>
    );
};

const ReportHistoryContent: React.FCC<ReportHistoryContentProps> = ({
    data,
    currentPage,
    totalItems,
    rowsPerPage,
    onChangePage,
    sorting,
    onSortingChange,
    isLoading,
}) => {
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            reportType: t('reportHistory.type'),
            dateRequested: t('reportHistory.dateRequested'),
            requestedBy: t('reportHistory.requestedBy'),
            file: t('reportHistory.file'),

            donations: t('reports.item.donationsLegacy'),
            reconciliation: t('reports.item.bankDeposits'),
            donors: t('reports.item.donors'),
            importFriendly: t('reports.item.donationsImportFriendly'),
            quickbooksDonors: t('reports.item.quickbooksDonor'),
            quickbooksEnvelopes: t('reports.item.quickbooksEnvelope.church'),
            disbursements: t('reports.item.disbursements'),

            generating: t('reports.generating'),
        }),
        [t],
    );

    const columns: GivelifyTableColumn<Report>[] = useMemo(
        () => [
            {
                label: 'Report Date',
                width: 230,
                fixed: true,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => (
                    <GivelifyLabel
                        id={`reportDateRange-${row.id}`}
                        text={`${formatWithLocaltime(
                            row.startDate,
                        )} - ${formatWithLocaltime(row.endDate)}`}
                        variant="body2"
                    />
                ),
                renderLoading: Loading,
            },
            {
                name: 'reportType',
                label: copy.reportType,
                width: 230,
                canSort: true,
                // eslint-disable-next-line react/display-name
                renderCell: ({ cellValue, row }) => (
                    <GivelifyLabel
                        id={`reportType-${row.id}`}
                        text={reportName(cellValue, t, row.donorName)}
                        variant="body2"
                    />
                ),
                renderLoading: Loading,
            },
            {
                name: 'createdAt',
                label: copy.dateRequested,
                width: 180,
                canSort: true,
                // eslint-disable-next-line react/display-name
                renderCell: ({ cellValue, row }) => (
                    <GivelifyLabel
                        id={`reportRequestedDate-${row.id}`}
                        text={formatWithTimezone(cellValue)}
                        variant="body2"
                    />
                ),
                renderLoading: Loading,
            },
            {
                name: 'requestedByName',
                label: copy.requestedBy,
                width: 200,
                canSort: true,
                renderLoading: Loading,
                renderCell: ({ row }) => (
                    <GivelifyLabel
                        id={`reportRequestedBy-${row.id}`}
                        text={row.requestedByName}
                        variant="body2"
                    />
                ),
            },
            {
                label: copy.file,
                width: 200,
                renderCell: FileCell,
                renderLoading: Loading,
            },
        ],
        [copy, t],
    );

    return (
        <GivelifyPaper>
            <GivelifyTable
                columns={columns}
                data={data}
                keySelector={(item) => item.id}
                loading={isLoading}
                onSort={(newSortDirection, newSortColumn) => {
                    onSortingChange(newSortColumn, newSortDirection);
                }}
                pagination={{
                    currentPage,
                    totalItems,
                    rowsPerPage,
                    onChangePage,
                }}
                sortColumn={sorting.columnName}
                sortDirection={sorting.direction}
            />
        </GivelifyPaper>
    );
};

export default ReportHistoryContent;
