import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyBankChequePreviewStyleProps = GivelifyBoxMarginProps;

export const checkPreviewStyles = makeStyles((theme: Theme) => {
    return createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyBankChequePreviewStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyBankChequePreviewStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyBankChequePreviewStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyBankChequePreviewStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        checkSuperContainer: {
            marginLeft: 'auto',
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                width: '100%',
                marginTop: 30,
                justifyContent: 'center',
            },
        },
        checkContainer: {
            width: 354,
        },
        checkTitle: {
            fontSize: 12,
            lineHeight: '16px',
            color: '#AAAAAA',
            textAlign: 'center',
        },
        checkImage: {
            width: 354,
            height: 160,
            position: 'relative',
        },
        checkDescriptions: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 12,
        },
        checkDescriptionRouting: {
            marginRight: 12,
        },
        checkDescriptionAccount: {
            marginLeft: 12,
        },
        checkDescriptionMain: {
            fontWeight: 'bold',
            fontSize: 12,
            lineHeight: '12px',
            color: '#373739',
            textAlign: 'center',
        },
        checkDescriptionCaption: {
            fontSize: 12,
            lineHeight: '16px',
            color: '#5B5B5B',
            textAlign: 'center',
            marginTop: 4,
        },
        checkOutline1: {
            position: 'absolute',
            left: 99,
            top: 131,
        },
        checkOutline1Arrow: {
            position: 'absolute',
            left: 120,
            top: 143.5,
        },
        checkOutline1Dot: {
            position: 'absolute',
            left: 119,
            top: 162,
        },
        checkOutline2: {
            position: 'absolute',
            left: 144,
            top: 131,
        },
        checkOutline2Arrow: {
            position: 'absolute',
            left: 166,
            top: 143,
        },
        checkOutline2Dot: {
            position: 'absolute',
            left: 233,
            top: 162,
        },
    });
});
