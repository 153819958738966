import { GivelifyButton, GivelifyLabel, GivelifyModal } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useDocumentUploadContext } from '../../DocumentUploadContext';
import { useUploadDocumentTranslation } from '../useUploadDocumentTranslation';

type PreviewModalProps = {
    onClose: () => void;
    open: boolean;
    modalType: 'example' | 'uploaded';
    fileExtension: string;
    uploadedFile?: File;
    exampleImage: string;
    fileContent: string;
};

const PreviewModal: React.FCC<PreviewModalProps> = ({
    onClose,
    open,
    modalType,
    exampleImage,
    uploadedFile,
    fileExtension,
    fileContent,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { selectedDocumentType } = useDocumentUploadContext();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { text, copy } = useUploadDocumentTranslation(selectedDocumentType!);

    return (
        <GivelifyModal
            fullWidth
            showCloseButton
            maxWidth="mobile"
            name="Uploaded doc"
            onClose={onClose}
            open={open}
        >
            {!isMobile && (
                <GivelifyLabel
                    align="center"
                    style={{ marginTop: 24 }}
                    text={
                        modalType === 'example'
                            ? text?.exampleModalHeader
                            : text?.uploadedModalHeader
                    }
                    variant="heading1S"
                />
            )}

            {fileExtension === 'pdf' ? (
                uploadedFile && (
                    <iframe
                        src={URL.createObjectURL(uploadedFile)}
                        style={{
                            height: '100vh',
                            margin: '40px 24px',
                        }}
                        title="Preview"
                    ></iframe>
                )
            ) : (
                <img
                    alt={
                        modalType === 'example'
                            ? text?.imageAlt
                            : text?.documentType
                    }
                    src={modalType === 'example' ? exampleImage : fileContent}
                    style={{
                        height: '100%',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        margin: '40px 24px',
                    }}
                />
            )}
            <GivelifyButton
                onClick={onClose}
                style={
                    isMobile
                        ? {}
                        : {
                              alignSelf: 'center',
                          }
                }
                text={copy.close}
                variant="primary"
            />
        </GivelifyModal>
    );
};

export default PreviewModal;
