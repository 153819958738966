import React, { useMemo, useState } from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { DesignTokens, GivelifyAvatar, GivelifyLabel } from '@givelify/ui';
import {
    mergeClassNames,
    useInitialsFromName,
    useTrackingContext,
} from '@givelify/utils';
import { Menu, ClickAwayListener, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { webConfig } from 'webConfig';
import { selectorStyles } from '../../../../../pages/donations/components/style';
import { logoutUser } from '../../../../../store/user/thunks';
import {
    CurrencyAnnotationWrapper,
    IconButtonBlock,
    NewMenuOptionItem,
    UserAvatarWrapper,
    UserDropdownBlockMobile,
} from '../../../../../styles/headerLayout';
import {
    GO_TO_EDIT_PROFILE,
    USER_LOGOUT,
} from '../../../../../utils/clevertapEvents';
import CalendlyModal, { useCalendly } from '../CalendlyModal';
import { CurrencyAnnotation } from '../CurrencyAnnotation';
import { PendingInvites } from '../PendingInvites/PendingInvites';

// TODO: remove this interface after new header is used
interface UserDropdownMobileProps {
    userName: string;
    userAvatar: string;
    country?: string;
    hideEditProfile: boolean;
}

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
} as const;

const transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
} as const;

const View: React.FCC<UserDropdownMobileProps> = ({
    userName,
    userAvatar,
    country,
    hideEditProfile,
}) => {
    const { trackEvent } = useTrackingContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { PATH } = useGasRouterContext();
    const { inviteOfficialEnabled, navigateToUsers } =
        useCaptivePortalContext();
    const [open, isOpen] = useState<boolean>(false);

    const handleOpen = React.useCallback(() => isOpen(true), []);

    const handleClose = React.useCallback(() => isOpen(false), []);

    const pushEditProfilePage = React.useCallback(() => {
        trackEvent(GO_TO_EDIT_PROFILE);
        navigate(PATH.EDIT_PROFILE);
        handleClose();
    }, [trackEvent, navigate, PATH.EDIT_PROFILE, handleClose]);

    const pushSupportPage = React.useCallback(() => {
        window.open(webConfig.supportUrl, '_blank');
        handleClose();
    }, [handleClose]);

    const dispatch = useDispatch();

    const logoutDonee = React.useCallback(() => {
        trackEvent(USER_LOGOUT);
        logoutUser()(dispatch);
        handleClose();
    }, [dispatch, handleClose, trackEvent]);
    const initial = useInitialsFromName(userName, 1);
    const { newMenuOptions, newUserMenuOptions } = selectorStyles();

    const anchorRef = React.useRef<HTMLDivElement>(null);

    const {
        signedIn,
        myProfile,
        goToHelp,
        signOut,
        scheduleCall,
        inviteOfficial,
    } = useMemo(
        () => ({
            signedIn: t('userDropdown.signedIn'),
            myProfile: t('userDropdownMobile.myProfile'),
            goToHelp: t('userDropdownMobile.goToHelp'),
            signOut: t('userDropdownMobile.signOut'),
            scheduleCall: t('userDropdownMobile.scheduleCall'),
            inviteOfficial: t('userDropdown.inviteAnOfficial'),
        }),
        [t],
    );

    const { isCalendlyEnabled, openCalendly, setOpenCalendly } = useCalendly();

    return (
        <>
            {openCalendly && (
                <CalendlyModal onClose={() => setOpenCalendly(false)} />
            )}
            <ClickAwayListener onClickAway={handleClose}>
                <UserDropdownBlockMobile>
                    <UserAvatarWrapper ref={anchorRef}>
                        <IconButtonBlock
                            data-testid="open-dropdown-button"
                            isOpen={open}
                            onClick={handleOpen}
                            size="small"
                            variant="icon"
                        >
                            <GivelifyAvatar
                                data-testid="donor-name-avatar-mobile"
                                size="xSmall"
                                src={userAvatar}
                                text={userAvatar ? '' : initial}
                            />
                        </IconButtonBlock>
                    </UserAvatarWrapper>
                    <Menu
                        anchorEl={anchorRef.current}
                        anchorOrigin={anchorOrigin}
                        className={mergeClassNames(
                            newMenuOptions,
                            newUserMenuOptions,
                        )}
                        data-testid="dropdown-menu"
                        onClose={handleClose}
                        open={open}
                        transformOrigin={transformOrigin}
                    >
                        <NewMenuOptionItem disabled>
                            <GivelifyLabel
                                color={DesignTokens.color.textSecondary}
                                data-testid="user-fullname"
                                text={signedIn}
                                variant="body1"
                            />
                            <GivelifyLabel
                                color={DesignTokens.color.textPrimary}
                                text={userName}
                                variant="body1"
                            />
                        </NewMenuOptionItem>
                        {!hideEditProfile && (
                            <NewMenuOptionItem
                                data-testid="edit-profile-item"
                                onClick={pushEditProfilePage}
                            >
                                <GivelifyLabel
                                    text={myProfile}
                                    variant="body1"
                                />
                            </NewMenuOptionItem>
                        )}
                        <NewMenuOptionItem disabled>
                            <GivelifyLabel text="Help" variant="body1" />
                        </NewMenuOptionItem>
                        <NewMenuOptionItem
                            data-testid="support-page-item"
                            onClick={pushSupportPage}
                        >
                            <GivelifyLabel
                                style={{
                                    paddingLeft: 8,
                                }}
                                text={goToHelp}
                                variant="body1"
                            />
                        </NewMenuOptionItem>
                        {isCalendlyEnabled && (
                            <NewMenuOptionItem
                                data-testid="openCalendly"
                                onClick={() => {
                                    setOpenCalendly(true);
                                    handleClose();
                                }}
                            >
                                <GivelifyLabel
                                    style={{
                                        paddingLeft: 8,
                                    }}
                                    text={scheduleCall}
                                    variant="body1"
                                />
                            </NewMenuOptionItem>
                        )}
                        {inviteOfficialEnabled && (
                            <div>
                                <NewMenuOptionItem
                                    data-testid="inviteOfficial"
                                    onClick={() => {
                                        navigateToUsers(true);
                                        handleClose();
                                    }}
                                >
                                    <GivelifyLabel
                                        style={{
                                            paddingLeft: 8,
                                        }}
                                        text={inviteOfficial}
                                        variant="body1"
                                    />
                                </NewMenuOptionItem>
                                <PendingInvites
                                    isMobile
                                    onAction={handleClose}
                                />
                            </div>
                        )}
                        <NewMenuOptionItem
                            data-testid="logout-donee-item"
                            onClick={logoutDonee}
                        >
                            <GivelifyLabel text={signOut} variant="body1" />
                        </NewMenuOptionItem>

                        {country === 'BHS' ? (
                            <CurrencyAnnotationWrapper>
                                <Divider />
                                <CurrencyAnnotation
                                    renderLabel
                                    country={country}
                                    renderTooltip={false}
                                />
                            </CurrencyAnnotationWrapper>
                        ) : null}
                    </Menu>
                </UserDropdownBlockMobile>
            </ClickAwayListener>
        </>
    );
};

export default View;
