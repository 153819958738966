import { useMemo } from 'react';
import { GivelifyBreadcrumbs, GivelifyBreadcrumbsOption } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../consts';

interface AccountDetailsBreadcrumbsProps {
    active: string;
    disableNavigation: boolean;
    onClick: (step: string) => void;
    accountInfoCompleted: boolean;
    mailingAddressCompleted: boolean;
    summaryCompleted: boolean;
}

export const AccountDetailsBreadcrumbs: React.FCC<
    AccountDetailsBreadcrumbsProps
> = ({
    active,
    disableNavigation,
    onClick,
    accountInfoCompleted,
    mailingAddressCompleted,
    summaryCompleted,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const copy = useMemo(
        () => ({
            accountInfo: t('bankInfo.accountInfo'),
            mailingAddress: t('bankInfo.mailingAddress'),
            summary: t('bankInfo.summary'),
        }),
        [t],
    );

    const breadcrumbsOptions: GivelifyBreadcrumbsOption[] = [
        {
            id: 'accountInfo',
            label: copy.accountInfo,
            onClick: () => onClick('accountInfo'),
            isCompleted: accountInfoCompleted,
        },
        {
            id: 'mailingAddress',
            label: copy.mailingAddress,
            onClick: () => onClick('mailingAddress'),
            isCompleted: mailingAddressCompleted,
        },
        {
            id: 'summary',
            label: copy.summary,
            onClick: () => onClick('summary'),
            isCompleted: summaryCompleted,
        },
    ];

    return (
        <GivelifyBreadcrumbs
            active={active}
            disableNavigation={disableNavigation}
            options={breadcrumbsOptions}
        />
    );
};
