import React, { useContext, useMemo } from 'react';
import { formatWithTimezone } from '@givelify/utils';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DonationNote } from 'types/donationTypes';
import { Donation } from '../../../../DonorProfile';
import { DonorProfileContext } from '../../../../DonorProfileContext';
import envelopeNameSummary from '../../../envelopeNameSummary';
import DonationMemoMessage from './DonationMemoMessage';

interface DonationMemoProps {
    donation: Donation;
    note: DonationNote;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
        blueBorderBottom: {
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: theme.colors.guidingVioletLight,
        },
    };
});

const baseTranslation = 'pages.donor_details';

const DonationMemo: React.FCC<DonationMemoProps> = ({ note, donation }) => {
    const classes = useStyle();
    const { donor } = useContext(DonorProfileContext);
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            totalDonated: t(`${baseTranslation}.donation_memo_title`, {
                envelopes: envelopeNameSummary(donation.envelopes),
                date: formatWithTimezone(donation.createdAt, 'MMM. DD, YYYY'),
            }),
            receivedDateMessage: `${t('labels.received')}: ${formatWithTimezone(
                donation.createdAt,
                'MMM. DD, YYYY - h:mma',
            )}`,
        }),
        [t, donation],
    );

    return (
        <div>
            <h3>{copy.totalDonated}</h3>
            <DonationMemoMessage
                dateMessage={copy.receivedDateMessage}
                message={note.memo}
                name={donor.name}
                photo={donor.picture}
            />
            {note.reply?.text && (
                <DonationMemoMessage
                    message={note.reply.text}
                    name={note.reply.userName}
                    photo={note.reply.userPicture}
                />
            )}
            <div className={classes.blueBorderBottom} />
        </div>
    );
};
export default DonationMemo;
