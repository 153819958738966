import React, { useCallback, useMemo, useState } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithTimezone, toISODate } from '@givelify/utils';
import { Paper, Box, makeStyles } from '@material-ui/core';
import Space from 'components/Space';
import dayjs from 'dayjs';
import { integrationStyle } from 'pages/integrations/components/style';
import { useAllIntegrations } from 'pages/integrations/IntegrationSetup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { showReportGeneratingDialog } from 'store/integrations/actions';
import { usePaperStyles } from 'styles/paperClasses';
import roleTypes from '../../../../../constants/roleTypes';
import ReportGeneratingDialog from '../CustomReports/CustomReportForm/ReportGeneratingDialog';
import AllItemsMatchedNotification from './components/AllItemsMatchedNotifications';
import IntegrationReportContent from './components/IntegrationReportContent';

export interface ShowBannerRouteState {
    buildCustomReportClick: () => void;
}

const useStyles = makeStyles(() => ({
    customReportButton: {
        minWidth: 213,
    },
}));

const IntegrationReports: React.FCC<ShowBannerRouteState> = ({
    buildCustomReportClick,
}) => {
    const componentClasses = useStyles();

    const { t } = useTranslation();
    const [error] = useState();
    const dispatch = useDispatch();
    const {
        ReportIntegration: { scheduledReport },
        reportType,
        frequency,
        isOpenReportGeneratingDialog,
        userRole,
    } = useSelector((state: AppState) => ({
        ReportIntegration: state.ReportIntegration,
        totalDonationCount: state.Report.reportTotalDonationSinceLastExport,
        reportType: state.ReportIntegration.scheduledReport.reportType,
        frequency: state.ReportIntegration.scheduledReport.frequency,
        isOpenReportGeneratingDialog:
            state.Integrations.isOpenReportGeneratingDialog,
        userRole: state.User.user.role,
    }));

    const allIntegrations = useAllIntegrations();
    const selectedIntegration = useMemo(
        () =>
            allIntegrations.find(
                (integration) => integration.integrationType === reportType,
            ),
        [reportType, allIntegrations],
    );

    const classes = usePaperStyles();
    const styles = integrationStyle();

    let nextDateReport;
    switch (frequency) {
        case 'Daily':
            nextDateReport = toISODate(dayjs().add(1, 'day'));
            break;
        default:
            nextDateReport = toISODate(scheduledReport.nextRunDate);
            break;
    }

    const copy = useMemo(
        () => ({
            frequency: t('pages.integration.frequencyReport', {
                frequency: frequency?.toLowerCase(),
            }),
            buildCustomReport: t('pages.integrations.buildCustomReport'),
        }),
        [t, frequency],
    );

    const closeReportGeneratingDialog = useCallback(() => {
        dispatch(showReportGeneratingDialog(false));
    }, [dispatch]);

    const isReadOnly =
        userRole !== roleTypes.ADMIN &&
        userRole !== roleTypes.FINANCIAL &&
        userRole !== roleTypes.ADMIN_PANEL_USER;

    return (
        <>
            {error && <p>{error}</p>}
            <div className={styles.frequency}>
                <div>
                    <GivelifyLabel
                        className={styles.frequencyText}
                        text={copy.frequency}
                        variant="body3"
                    />
                    <GivelifyLabel
                        bold
                        className={styles.frequencyText}
                        marginLeft={4}
                        marginRight={4}
                        text={scheduledReport.scheduledDay}
                        variant="body3"
                    />
                    <GivelifyLabel
                        bold
                        className={styles.frequencyText}
                        text={formatWithTimezone(nextDateReport)}
                        variant="body3"
                    />
                </div>
            </div>
            <ReportGeneratingDialog
                handleClose={closeReportGeneratingDialog}
                open={isOpenReportGeneratingDialog}
            />
            <AllItemsMatchedNotification />
            <Box display="flex" justifyContent="flex-end">
                <GivelifyButton
                    className={componentClasses.customReportButton}
                    disabled={isReadOnly}
                    name="buildCustomReport"
                    onClick={buildCustomReportClick}
                    text={copy.buildCustomReport}
                    variant="secondary"
                />
            </Box>
            <Space gap={3} />
            <Paper className={`${classes.paperWithoutPadding}`}>
                <IntegrationReportContent
                    integration={selectedIntegration}
                    isReadOnly={isReadOnly}
                />
            </Paper>
        </>
    );
};

export default IntegrationReports;
