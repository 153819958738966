import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ApiEndpoints } from '../../endpoints';
import { GetEmailResponse, GetEnabledFeaturesResponse } from '../../responses';
import { ICommonService } from '../interfaces';

export class CommonService implements ICommonService {
    public async getEnabledFeatures(): Promise<
        ApiResponse<GetEnabledFeaturesResponse>
    > {
        const url = ApiEndpoints.common.enabledFeatures();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEnabledFeaturesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getEmail(
        token: string,
    ): Promise<ApiResponse<GetEmailResponse>> {
        const url = ApiEndpoints.common.email(token);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEmailResponse>(httpRequest);
        return result;
    }
}
