import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    getStateName,
    phoneFormatter,
    GivelifyMobilePreviewBase,
    DesignTokens,
} from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import backArrow from '../assets/mockups/back-icon.svg';
import buttonFavourite from '../assets/mockups/button-favourite.svg';
import locationIcon from '../assets/mockups/location-icon.svg';
import mapNormal from '../assets/mockups/map-normal.png';
import previewAvatar from '../assets/mockups/mobile-preview-avatar.svg';
import mpcNormal from '../assets/mockups/mobile-preview-cover-normal.svg';
import phoneIcon from '../assets/mockups/phone-icon.svg';
import shareIcon from '../assets/mockups/share-icon.svg';
import verifiedIcon from '../assets/mockups/verified-icon.svg';
import { I18N_NAMESPACE } from '../consts';
import {
    MobileMap,
    MobilePreviewAvatar,
    MobilePreviewBackArrow,
    MobilePreviewButton,
    MobilePreviewContainer,
    MobilePreviewCover,
    MobilePreviewCoverSection,
    MobilePreviewDetailsSection,
    MobilePreviewFav,
    MobilePreviewLocationSec,
    MobilePreviewOrgBadge,
    MobilePreviewOrgSec,
    MobilePreviewPhoneSec,
    MobilePreviewShare,
    MobilePreviewTaxTip,
} from './profileMobilePreviewStyle';

export interface ProfileMobilePreviewProps {
    isNonprofit?: boolean;
    organizationName?: string;
    profileStatement?: string;
    fullAddress?: {
        street?: string;
        city?: string;
        state?: string;
        zip?: string;
        phone?: string;
    };
    coverUrl?: string;
    representativeName?: string;
    representativeAvatar?: string;
    size?: 'large' | 'small';
    hideTitle?: boolean;
    isVerified?: boolean;
    title?: string;
    titleContentGap?: number;
}

export const ProfileMobilePreview: React.FCC<ProfileMobilePreviewProps> =
    React.memo((props) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const copy = useMemo(
            () => ({
                yourOrganization: t('mobile-preview.your-organization'),
                organizationsRepresentative: t(
                    'mobile-preview.organizations-representative',
                ),
                taxDeductibleOrganization: t(
                    'mobile-preview.tax-deductible-organization',
                ),
                give: t('mobile-preview.give'),
                profilePreview: t('customizeProfile.profilePreview'),
            }),
            [t],
        );
        const {
            coverUrl,
            fullAddress,
            title,
            hideTitle,
            size = 'small',
            isNonprofit,
            representativeName,
            representativeAvatar,
            profileStatement,
            organizationName,
            isVerified,
            titleContentGap = 25,
        } = props;
        return (
            <MobilePreviewContainer>
                {hideTitle ? null : (
                    <GivelifyLabel
                        color={DesignTokens.color.neutralGrey}
                        text={title ? title : copy.profilePreview}
                        variant="body2"
                    />
                )}
                <GivelifyMobilePreviewBase
                    marginTop={hideTitle ? undefined : -80.75 + titleContentGap}
                    scale={size === 'large' ? undefined : 0.75}
                >
                    <MobilePreviewCoverSection>
                        <MobilePreviewCover
                            alt="organization cover"
                            onError={(event) => {
                                event.currentTarget.src = mpcNormal;
                            }}
                            src={coverUrl || mpcNormal}
                            title="Organization cover picture"
                        />
                        <MobilePreviewBackArrow>
                            <img
                                alt="back"
                                src={backArrow}
                                title="Back arrow"
                            />
                        </MobilePreviewBackArrow>
                        <MobilePreviewShare>
                            <img
                                alt="share"
                                src={shareIcon}
                                title="Share icon"
                            />
                        </MobilePreviewShare>
                    </MobilePreviewCoverSection>
                    <MobilePreviewDetailsSection>
                        <MobilePreviewOrgSec>
                            <GivelifyLabel
                                text={organizationName || copy.yourOrganization}
                                variant="heading2S"
                            />
                            {isVerified ? (
                                <MobilePreviewOrgBadge
                                    alt="verified"
                                    src={verifiedIcon}
                                    title="Verified badge"
                                />
                            ) : null}
                        </MobilePreviewOrgSec>
                        {!isNonprofit && (
                            <GivelifyLabel
                                color={DesignTokens.color.grey600}
                                text={
                                    representativeName ||
                                    copy.organizationsRepresentative
                                }
                                variant="heading3S"
                            />
                        )}
                        <MobilePreviewTaxTip>
                            {copy.taxDeductibleOrganization}
                        </MobilePreviewTaxTip>
                        {profileStatement ? (
                            <div className="profileStatement">
                                {profileStatement}
                            </div>
                        ) : null}
                        <MobilePreviewButton>{copy.give}</MobilePreviewButton>
                        <MobilePreviewPhoneSec>
                            <img
                                alt="phone"
                                src={phoneIcon}
                                title="Phone icon"
                            />
                            <div className="phoneValue">
                                {fullAddress && fullAddress.phone
                                    ? phoneFormatter(fullAddress.phone) ||
                                      '(678) 687-4115'
                                    : '(678) 687-4115'}
                            </div>
                        </MobilePreviewPhoneSec>
                        <MobilePreviewLocationSec>
                            <img
                                alt="location"
                                src={locationIcon}
                                title="Location icon"
                            />
                            <div className="phoneValue">
                                {`${
                                    fullAddress?.street || '620 Hembree Pkwy'
                                }, ${fullAddress?.city || 'Roswell'}, ${
                                    getStateName(fullAddress?.state) ||
                                    'Georgia'
                                } ${fullAddress?.zip || '30076'}`}
                            </div>
                        </MobilePreviewLocationSec>
                        {!isNonprofit && (
                            <MobilePreviewAvatar
                                alt="avatar"
                                height={72}
                                onError={(event) => {
                                    event.currentTarget.src = previewAvatar;
                                }}
                                src={representativeAvatar || previewAvatar}
                                title="Avatar picture"
                                width={72}
                            />
                        )}
                        <MobilePreviewFav
                            alt="fav"
                            src={buttonFavourite}
                            title="fav picture"
                        />
                    </MobilePreviewDetailsSection>
                    <MobileMap alt="map" src={mapNormal} title="map preview" />
                </GivelifyMobilePreviewBase>
            </MobilePreviewContainer>
        );
    });

ProfileMobilePreview.displayName = 'ProfileMobilePreview';
