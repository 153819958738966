import { MESSAGE_FILTER_VALUE } from '@givelify/api';
import { TFunction } from 'i18next';

export type MessageFilterProps = {
    values?: MESSAGE_FILTER_VALUE[];
    onChange: (filters: MESSAGE_FILTER_VALUE[]) => unknown;
};

export const toMessageStringText = (
    value: MESSAGE_FILTER_VALUE,
    t: TFunction,
): string => {
    switch (value) {
        case MESSAGE_FILTER_VALUE.UN_REPLIED:
            return t('donationsActivity.messages.unReplied');
        case MESSAGE_FILTER_VALUE.REPLIED:
            return t('donationsActivity.messages.replied');
        case MESSAGE_FILTER_VALUE.NO_MESSAGES:
            return t('donationsActivity.messages.noMessages');

        default:
            return t('Unknown');
    }
};

export const getMessageFilterQuery = (
    values?: MESSAGE_FILTER_VALUE[],
): string => {
    let result = '';
    if (!values || values.length === 0) return result;
    values.forEach((v) => {
        switch (v) {
            case MESSAGE_FILTER_VALUE.NO_MESSAGES:
                result += '&no_messages=1';
                break;
            case MESSAGE_FILTER_VALUE.REPLIED:
                result += '&replied_messages=1';
                break;
            case MESSAGE_FILTER_VALUE.UN_REPLIED:
                result += '&un_replied_messages=1';
                break;
            default:
                break;
        }
    });
    return result;
};
