import { DesignTokens, GivelifyMenuItem } from '@givelify/ui';
import { styled, Accordion, AccordionDetails } from '@mui/material';

export const FilterAccordion = styled(Accordion)({
    '&.MuiPaper-root': {
        margin: 0,
    },
});

export const FilterAccordionDetails = styled(AccordionDetails)({
    '&.MuiAccordionDetails-root': {
        padding: 0,
    },
});

export const FilterList = styled('ul')({
    padding: 0,
    listStyleType: 'initial',
    marginBlock: 0,
    marginInline: 0,
    paddingInline: 0,
});

export const FilterContentList = styled(FilterList)(() => ({
    margin: 0,
    flexGrow: 1,
    overflow: 'auto',
}));

export const FilterMenuItem = styled(GivelifyMenuItem)(({ theme }) => ({
    padding: theme.spacing(1.5, 4, 1.5, 2),
}));

export const FilterContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
}));

export const FilterFooter = styled('div')(({ theme }) => ({
    padding: theme.spacing(1.875, 2),
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: DesignTokens.color.borderComponentSecondaryAccent,
    flexShrink: 0,
}));
