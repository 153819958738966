import React, { useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyTextField,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import {
    GivelifyLink,
    GivelifyLabel as GivelifyLabelNew,
    GivelifyNotification,
} from '@givelify/ui';
import { RequestState } from '@givelify/utils';
import { Theme, useMediaQuery } from '@material-ui/core';
import { webConfig } from 'webConfig';
import LocationAutocomplete from './LocationAutocomplete';
import ResultList from './ResultList';
import useStyles from './styles';
import { LocationSelected, OrganizationSuggestionsResponse } from './types';

interface SearchFormViewProps {
    orgName: string;
    setOrgName: (value: string) => void;
    location: LocationSelected;
    setLocation: (value: LocationSelected) => void;
    suggestionsRequestState: RequestState<OrganizationSuggestionsResponse[]>;
    onSearchClick: () => void;
    onSelect: (item: OrganizationSuggestionsResponse) => void;
}

const SearchFormView: React.FCC<SearchFormViewProps> = ({
    orgName,
    setOrgName,
    location,
    setLocation,
    suggestionsRequestState,
    onSearchClick,
    onSelect,
}) => {
    const [showNotification, setShowNotification] = useState(true);

    const classes = useStyles();
    const isMobile = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down('sm'),
    );

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.searchForm',
    });

    const copy = useMemo(
        () => ({
            title1: scopedTranslate('title1'),
            title2: scopedTranslate('title2'),
            titleLink: scopedTranslate('titleLink'),
            choose: scopedTranslate('choose'),
            subheader: scopedTranslate('subheader'),
            orgNameLabel: scopedTranslate('orgNameLabel'),
        }),
        [scopedTranslate],
    );

    return (
        <>
            <GivelifyNotification
                className={classes.notification}
                onClose={() => {
                    setShowNotification(false);
                }}
                open={showNotification}
                text={
                    <GivelifyLabelNew variant="body1B">
                        {copy.title1}
                        <GivelifyLink
                            href={webConfig.signupUrl}
                            rel="noreferrer noopener"
                            target="_blank"
                            text={copy.titleLink}
                            variant="body1B"
                        />
                        {copy.title2}
                    </GivelifyLabelNew>
                }
                variant="info"
            />
            <GivelifyLabel
                className={classes.header}
                text={copy.choose}
                variant={isMobile ? 'h3Mobile' : 'heading3'}
            />
            <GivelifyLabel
                className={classes.subheader}
                text={copy.subheader}
                variant="body1"
            />
            <div className={classes.controls}>
                <GivelifyTextField
                    ariaLabel="organizationName"
                    boxSize={isMobile ? 'dense' : 'large'}
                    id="orgName"
                    onChange={(_, value) => setOrgName(value)}
                    placeholder={copy.orgNameLabel}
                    value={orgName}
                />
                <LocationAutocomplete
                    onChange={(value) => setLocation(value)}
                    value={location}
                />
                <GivelifyButton
                    className={classes.searchButton}
                    disabled={!location || !orgName}
                    iconVariant="search"
                    onClick={onSearchClick}
                    variant="primary"
                />
            </div>
            <ResultList
                onSelect={onSelect}
                suggestionsRequestState={suggestionsRequestState}
            />
        </>
    );
};

export default SearchFormView;
