import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
import { ResponsiveContainer, Line, LineChart } from 'recharts';

const ChartBox = styled('div')({
    width: '670px',
    height: '192px',
    marginTop: '16px',
});

export type ChartGraphProps = {
    data: { value: number }[];
};

const ChartGraphComponent: React.FCC<ChartGraphProps> = ({ data }) => {
    return (
        <ChartBox aria-label="main chart infographic" data-testid="chart-graph">
            <ResponsiveContainer height="95.8%" width="97%">
                <LineChart
                    data={data}
                    margin={{ right: 10, left: 10, top: 4, bottom: 4 }}
                >
                    <Line
                        dataKey="value"
                        dot={{
                            stroke: DesignTokens.color.textPrimary,
                            strokeWidth: 2,
                            r: 7,
                        }}
                        stroke={DesignTokens.color.textPrimary}
                        strokeWidth="4px"
                        type="linear"
                    />
                </LineChart>
            </ResponsiveContainer>
        </ChartBox>
    );
};

export const ChartGraph = React.memo(ChartGraphComponent);
