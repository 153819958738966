import { AuthEndpoints } from './authEndpoints';
import { CommonEndpoints } from './commonEndpoints';
import { DoneesEndpoints } from './doneesEndpoints';
import { EnvelopesEndpoints } from './envelopesEndpoints';
import { UsersEndpoints } from './usersEndpoints';

export const ApiEndpoints = {
    auth: AuthEndpoints,
    users: UsersEndpoints,
    donees: DoneesEndpoints,
    common: CommonEndpoints,
    envelopes: EnvelopesEndpoints,
};
