import React, { useMemo } from 'react';
import { UpdateOrganizationInfoRequest } from '@givelify/api';
import {
    GivelifyForm,
    GivelifyFormDropDown,
    GivelifyFormTextField,
    GivelifyLink,
    emailRegex,
} from '@givelify/ui';
import { isValidPhoneNumber } from '@givelify/utils';
import { styled } from '@mui/material';
import { ErrorText } from 'components/ErrorText';
import { useAdvancedTranslation } from 'utils/i18n';
import { webConfig } from 'webConfig';
import { AnySchema, number, object, string } from 'yup';
import EditorButtons from '../EditorButtons';
import { EditorItemTitle } from './styles';

const useEditorTranslation = () => {
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            phoneNumber: t(
                'pages.settings.organization-info.contact.phoneNumber',
            ),
            invalidPhoneNumber: t(
                'pages.settings.organization-info.contact.invalidPhoneNumber',
            ),
            phoneMaxlen: t(
                'pages.settings.organization-info.contact.phoneMaxlen',
            ),
            emailMaxlen: t(
                'pages.settings.organization-info.contact.emailMaxlen',
            ),
            email: t('pages.settings.organization-info.contact.email'),
            size: at('pages.settings.organization-info.contact.size'),
            sizePlaceholder: at(
                'pages.settings.organization-info.contact.sizePlaceholder',
            ),
            denomination: t(
                'pages.settings.organization-info.contact.denomination',
            ),
            denominationHelp: t(
                'pages.settings.organization-info.contact.denominationHelp',
            ),
            established: t(
                'pages.settings.organization-info.contact.established',
            ),
            invalidYear: t(
                'pages.settings.organization-info.contact.invalidYear',
            ),
            contactSupport: t(
                'pages.settings.organization-info.contact.contactSupport',
            ),
            emailRequired: t('error.signup.email'),
            phoneRequired: t('error.signup.phone'),
            required: t('labels.required'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t, at],
    );

    return copy;
};

const GridWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
    [theme.breakpoints.down('tablet')]: {
        gridTemplateColumns: '1fr',
    },
}));

interface ContactSectionEditorProps {
    data?: UpdateOrganizationInfoRequest;
    onCancel: () => void;
    onSubmit: (data: UpdateOrganizationInfoRequest) => void;
    saving?: boolean;
    error?: string;
    isChurch?: boolean;
    denominations: { id: string; name: string }[];
    loadingDenominations: boolean;
}
const phoneMask = '(000) 000-0000';
const sizeOptions = [
    { id: '1 - 99', label: '1 - 99' },
    { id: '100 - 499', label: '100 - 499' },
    { id: '500 - 1,499', label: '500 - 1,499' },
    { id: '1,500 - 4,999', label: '1,500 - 4,999' },
    { id: '5,000 - 9,999', label: '5,000 - 9,999' },
    { id: '10,000 +', label: '10,000 +' },
];
const View: React.FC<ContactSectionEditorProps> = (props) => {
    const copy = useEditorTranslation();

    const denominationOptions = props.denominations.map((denom) => ({
        id: denom.id,
        label: denom.name,
    }));
    return (
        <>
            <GridWrapper>
                <div>
                    <EditorItemTitle
                        text={copy.phoneNumber}
                        variant="heading3S"
                    />
                    <GivelifyFormTextField<UpdateOrganizationInfoRequest>
                        fullWidth
                        keepHelperTextSpace
                        aria-label="Phone Input"
                        id="phone"
                        maskOptions={{
                            mask: phoneMask,
                            lazy: false,
                        }}
                        name="phone"
                    />
                </div>
                <div>
                    <EditorItemTitle text={copy.email} variant="heading3S" />
                    <GivelifyFormTextField
                        fullWidth
                        aria-label="Email input"
                        id="email"
                        maxLength={255}
                        name="email"
                    />
                </div>
                <div>
                    <EditorItemTitle
                        text={copy.established}
                        variant="heading3S"
                    />
                    <GivelifyFormTextField
                        fullWidth
                        aria-label="Established year"
                        id="established"
                        maxLength={4}
                        name="established"
                    />
                </div>
                {props.isChurch && (
                    <>
                        <div>
                            <EditorItemTitle
                                text={copy.denomination}
                                variant="heading3S"
                            />
                            <GivelifyFormDropDown
                                aria-label="Denomination Select"
                                id="denomination-select"
                                leftHelperText={
                                    <>
                                        {`${copy.denominationHelp} `}
                                        <GivelifyLink
                                            href={webConfig.supportUrl}
                                            style={{
                                                fontSize: 'inherit',
                                                fontWeight: 'inherit',
                                                lineHeight: 'inherit',
                                            }}
                                            target="_blank"
                                            text={copy.contactSupport}
                                        />
                                    </>
                                }
                                name="denominationId"
                                options={denominationOptions}
                                shape="input"
                                width="fullwidth"
                            />
                        </div>
                        <div>
                            <EditorItemTitle
                                text={copy.size}
                                variant="heading3S"
                            />
                            <GivelifyFormDropDown
                                aria-label="Congregation Size"
                                id="congregationSize-select"
                                name="congregationSize"
                                options={sizeOptions}
                                shape="input"
                                width="fullwidth"
                            />
                        </div>
                    </>
                )}
            </GridWrapper>
            {props.error && <ErrorText text={props.error} />}
            <EditorButtons onCancel={props.onCancel} saving={props.saving} />
        </>
    );
};

const FormWrapper: React.FC<ContactSectionEditorProps> = (props) => {
    const copy = useEditorTranslation();

    const schema = object<
        Record<keyof UpdateOrganizationInfoRequest, AnySchema>
    >({
        phone: string()
            .test('phone', copy.invalidPhoneNumber, isValidPhoneNumber)
            .required(copy.required)
            .max(14, copy.phoneMaxlen),
        email: string()
            .matches(emailRegex, copy.emailRequired)
            .required(copy.required)
            .max(255, copy.emailMaxlen),
        established: string().test('year', copy.invalidYear, (year) => {
            if (!/^\d{4}$/.test(year)) return false;
            return parseInt(year) <= new Date().getFullYear();
        }),
        congregationSize: string().nullable(),
        denominationId: number().nullable(),
    });
    return (
        <GivelifyForm<UpdateOrganizationInfoRequest>
            defaultValues={{
                phone: props.data.phone || '',
                email: props.data.email || '',
                established: props.data.established || '',
                denominationId: props.data.denominationId || null,
                congregationSize: props.data.congregationSize || null,
            }}
            onSubmit={(data) => {
                props.onSubmit(data);
            }}
            schema={schema}
        >
            <View {...props} />
        </GivelifyForm>
    );
};

export default FormWrapper;
