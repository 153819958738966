import { useMemo } from 'react';
import { GivelifyBreadcrumbs, GivelifyBreadcrumbsOption } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';

interface PrimaryRepBreadcrumbsProps {
    active: string;
    disableNavigation: boolean;
    onClick: (step: string) => void;
    infoCompleted: boolean;
    addressCompleted: boolean;
    summaryCompleted: boolean;
}

export const PrimaryRepBreadcrumbs: React.FCC<PrimaryRepBreadcrumbsProps> = ({
    active,
    disableNavigation,
    onClick,
    infoCompleted,
    addressCompleted,
    summaryCompleted,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const copy = useMemo(
        () => ({
            info: t('primaryRepresentative.breadcrumbs.info'),
            address: t('primaryRepresentative.breadcrumbs.address'),
            summary: t('primaryRepresentative.breadcrumbs.summary'),
        }),
        [t],
    );

    const breadcrumbsOptions: GivelifyBreadcrumbsOption[] = [
        {
            id: 'info',
            label: copy.info,
            onClick: () => onClick('info'),
            isCompleted: infoCompleted,
        },
        {
            id: 'address',
            label: copy.address,
            onClick: () => onClick('address'),
            isCompleted: addressCompleted,
        },
        {
            id: 'summary',
            label: copy.summary,
            onClick: () => onClick('summary'),
            isCompleted: summaryCompleted,
        },
    ];

    return (
        <GivelifyBreadcrumbs
            active={active}
            disableNavigation={disableNavigation}
            options={breadcrumbsOptions}
        />
    );
};
