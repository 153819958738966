import React, { useMemo, useState } from 'react';
import { GivelifyIcon, GivelifyButton } from '@givelify/givelify-ui';
import { Box, Dialog, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import ExtensionPanel from './ExtensionPanel';
import RadioGroup from './RadioGroup';
import { MobileSelectorProps, SelectOptionProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        fontSize: '16px',
        borderRadius: '3px',
    },
    buttonText: {
        paddingLeft: theme.spacing(2),
        fontWeight: 'bold',
    },
    dialog: {
        '& .MuiExpansionPanel-root:before': {
            opacity: 0,
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-scrollPaper': {
                alignItems: 'flex-end',
            },

            '& .MuiDialog-paperWidthSm': {
                maxWidth: '100%',
                width: '100%',
                margin: 'unset',
                height: 'calc(100vh - 50px)',
            },
        },
    },
    title: {
        paddingLeft: theme.spacing(4),
        fontSize: '16px',
        fontWeight: 'bold',
    },
    divider: {
        border: '2px solid #D7EBF4',
        width: '100%',
        marginBottom: 'unset',
        marginTop: 'unset',
    },
    radioGroup: {
        width: '100%',
    },
}));

const MobileSelector: React.FCC<MobileSelectorProps> = ({
    headerText,
    properties,
    onSelect,
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const singleItem = properties.length === 1;
    const ExtensionPanelWrapper = singleItem ? React.Fragment : ExtensionPanel;

    const [result, setResult] = useState<SelectOptionProps[]>(
        properties.map((p) => p.value),
    );
    const onItemChange = (index: number, value: SelectOptionProps) => {
        const newResult = [...result];
        newResult[index] = value;
        setResult(newResult);
    };

    const onApplyClick = () => {
        onSelect(result);
        handleClose();
    };

    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            apply: t(`labels.apply`),
        }),
        [t],
    );

    return (
        <>
            <IconButton className={classes.iconButton} onClick={handleOpen}>
                <GivelifyIcon variant="sort" />
                <div className={classes.buttonText}>{result[0].label}</div>
            </IconButton>
            <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                onClose={handleClose}
                open={open}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingTop={2}
                >
                    <h3 className={classes.title}>{headerText}</h3>
                    <GivelifyButton
                        bold
                        name="apply"
                        onClick={onApplyClick}
                        text={copy.apply}
                        variant="ghost"
                    />
                </Box>
                <hr className={classes.divider} />
                {properties.map((property, index) => (
                    <ExtensionPanelWrapper key={index} label={property.label}>
                        <RadioGroup
                            onChange={(selectedItem) =>
                                onItemChange(index, selectedItem)
                            }
                            options={property.options}
                            value={property.value}
                        />
                    </ExtensionPanelWrapper>
                ))}
            </Dialog>
        </>
    );
};

export default MobileSelector;
