import { useEffect, useState, useMemo, useCallback } from 'react';
import {
    GivelifySnackbar,
    IntegrationPaginatedResponse,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/material';
import { Envelope, Poll, PollAnswer } from 'api/models';
import { createEnvelope, getEnvelopes, updateEnvelope } from 'api/requests';
import { answerPoll, getPolls } from 'api/requests/Poll';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { updateNotification } from 'store/doneeNotification/thunks';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import GivingTuesday from '../index';
import { GivingTuesdayContext } from './GivingTuesdayContext';
import { CurrentStep } from './types';

const GIVING_TUESDAY_START_DATE = '2023-11-26';
const GIVING_TUESDAY_END_DATE = '2023-12-01';

const HASHTAG = '#givingtuesday2023';

const ENVELOPE_DEFAULT_NAME = 'GivingTuesday2023';

const localStorageKey = 'givingTuesdayTimestamp';
const saveToLocalStorage = (userId: number, value: number) => {
    const arr = getFromLocalStorage();
    arr.push({ userId, timestamp: value });

    localStorage.setItem(localStorageKey, JSON.stringify(arr));
};

const getFromLocalStorage = () => {
    const arrayAsString = localStorage.getItem(localStorageKey);

    try {
        const parsed = JSON.parse(arrayAsString);
        const array: { userId: number; timestamp: number }[] = Array.isArray(
            parsed,
        )
            ? parsed
            : [];

        return array;
    } catch {
        return [];
    }
};

const useGivingTuesdayPoll = () => {
    const { donee } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
    }));

    const [poll, setPoll] = useState<Poll>();

    const [isLoading, setIsLoading] = useState(true);
    const [requestStatus, makeApiRequest] = useApiRequest<{
        data: Poll[];
    }>();

    useEffect(() => {
        makeApiRequest(getPolls(donee.id));
    }, [donee.id, makeApiRequest]);

    useEffect(() => {
        if (requestStatus.type !== 'REQUEST_SUCCESS') return;

        const givingTuesdayPoll = requestStatus.response.data.find(
            (d) => d.tag === 'GivingTuesday2023',
        );
        if (!givingTuesdayPoll) return;

        setPoll(givingTuesdayPoll);
        setIsLoading(false);
    }, [requestStatus]);

    return { givingTuesdayPoll: poll, givingTuesdayPollIsLoading: isLoading };
};

const useGivingTuesdayEnvelopes = () => {
    const { donee } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
    }));

    const [givingTuesdayEnvelopes, setGivingTuesdayEnvelopes] = useState<
        Envelope[]
    >([]);
    const [allEnvelopes, setAllEnvelopes] = useState<Envelope[]>([]);

    const [givingTUesdayRequestState, makeGivingTuesdayRequest] =
        useApiRequest<IntegrationPaginatedResponse<Envelope>>();
    const [allEnvelopesRequestState, makeAllEnvelopesRequest] =
        useApiRequest<IntegrationPaginatedResponse<Envelope>>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        makeGivingTuesdayRequest(
            getEnvelopes(donee.id, 'active', 1, true, 'priority', true),
        );
        makeAllEnvelopesRequest(
            getEnvelopes(donee.id, 'any', 1, true, 'priority'),
        );
    }, [donee.id, makeGivingTuesdayRequest, makeAllEnvelopesRequest]);

    useEffect(() => {
        if (
            givingTUesdayRequestState.type !== 'REQUEST_SUCCESS' ||
            allEnvelopesRequestState.type !== 'REQUEST_SUCCESS'
        )
            return;

        setGivingTuesdayEnvelopes(givingTUesdayRequestState.response.data);
        setAllEnvelopes(allEnvelopesRequestState.response.data);
        setIsLoading(false);
    }, [givingTUesdayRequestState, allEnvelopesRequestState]);

    return {
        allEnvelopes,
        givingTuesdayEnvelopes,
        envelopesLoading: isLoading,
    };
};

export const GivingTuesdayContextInternal: React.FCC<{
    hideModal: () => void;
    showSnackbar: () => void;
}> = ({ children, hideModal, showSnackbar }) => {
    const { donee, userId } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
        userId: state.User.user.id,
    }));

    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();

    const [currentStep, setCurrentStep] = useState<CurrentStep>('welcome');

    const { givingTuesdayPoll, givingTuesdayPollIsLoading } =
        useGivingTuesdayPoll();

    const { allEnvelopes, givingTuesdayEnvelopes, envelopesLoading } =
        useGivingTuesdayEnvelopes();

    const [pollAnswer, setPollAnswer] = useState<PollAnswer>();
    const [customPollAnswer, setCustomPollAnswer] = useState<string>();
    useEffect(() => {
        setCustomPollAnswer(undefined);
    }, [pollAnswer]);

    const [showEnvelopesLoader, setShowEnvelopesLoader] = useState(false);
    const joinGivingTuesdayClick = () => {
        if (envelopesLoading) {
            setShowEnvelopesLoader(true);
        } else {
            handleJoinGivingTuesdayClick();
        }
    };

    const handleJoinGivingTuesdayClick = useCallback(() => {
        if (givingTuesdayEnvelopes?.length > 0) {
            setCurrentStep('envelopeList');
        } else {
            setCurrentStep('createEnvelope');
        }
    }, [givingTuesdayEnvelopes?.length]);

    useEffect(() => {
        if (!envelopesLoading && showEnvelopesLoader) {
            handleJoinGivingTuesdayClick();
            setShowEnvelopesLoader(false);
        }
    }, [envelopesLoading, showEnvelopesLoader, handleJoinGivingTuesdayClick]);

    const remindMeLaterClick = () => {
        const currentTimeStamp = Date.now();
        saveToLocalStorage(userId, currentTimeStamp);
        hideModal();
    };

    const noThanksClick = () => setCurrentStep('poll');

    const confirmAndOptOutClick = async () => {
        if (pollAnswer) {
            const answer = { id: pollAnswer.id };
            if (customPollAnswer) {
                (answer as any).customAnswer = customPollAnswer;
            }
            const result = await answerPoll(
                donee.id,
                givingTuesdayPoll.id,
                answer,
            );
            if (!result.success) return false;
            showSnackbar();
        }
        dispatch(updateNotification('hideGivingTuesday2023Modal', true));
        hideModal();
    };

    const updateEnvelopeConfirmClick = async (envelope: Envelope) => {
        const updateResult = await updateEnvelope(donee.id, {
            ...envelope,
            detail: `${envelope.detail} ${HASHTAG}`,
            start: envelope.alwaysOn === 'Always' ? null : envelope.start,
            end: envelope.alwaysOn === 'Always' ? null : envelope.end,
        });
        if (!updateResult.success) return updateResult;

        dispatch(updateNotification('hideGivingTuesday2023Modal', true));
        setCurrentStep('share');
    };

    const showCreateEnvelopeFormClick = () => setCurrentStep('createEnvelope');

    const createNewEnvelopeClick = async (name: string) => {
        const result = await createEnvelope(donee.id, {
            active: true,
            name: name || ENVELOPE_DEFAULT_NAME,
            detail: HASHTAG,
            alwaysOn: 'Timed',
            start: GIVING_TUESDAY_START_DATE,
            end: GIVING_TUESDAY_END_DATE,
            id: null,
            goal: null,
            externalId: null,
            isDetailPublic: false,
            isDefault: false,
        });
        if (!result.success) return result;

        dispatch(updateNotification('hideGivingTuesday2023Modal', true));
        setCurrentStep('share');
    };

    const backClick = () => {
        if (currentStep === 'poll') {
            setCurrentStep('welcome');
        } else if (currentStep === 'envelopeList') {
            setCurrentStep('welcome');
        } else if (currentStep === 'createEnvelope') {
            if (givingTuesdayEnvelopes?.length > 0) {
                setCurrentStep('envelopeList');
            } else {
                setCurrentStep('welcome');
            }
        } else if (currentStep === 'share') {
            hideModal();
        }
    };

    return (
        <GivingTuesdayContext.Provider
            value={{
                isEnabled: true,
                currentStep,

                givingTuesdayPoll,
                givingTuesdayPollIsLoading,

                pollAnswer,
                setPollAnswer,
                customPollAnswer,
                setCustomPollAnswer,

                allEnvelopes,
                givingTuesdayEnvelopes,
                envelopesLoading,

                joinGivingTuesdayClick,
                remindMeLaterClick,
                noThanksClick,
                confirmAndOptOutClick,
                updateEnvelopeConfirmClick,
                showCreateEnvelopeFormClick,
                createNewEnvelopeClick,
                backClick,
            }}
        >
            <GivingTuesday />
        </GivingTuesdayContext.Provider>
    );
};

export const GivingTuesdayProvider: React.FCC = ({ children }) => {
    const {
        hideGivingTuesday2023Modal,
        enabledFeatures,
        userId,
        donee,
        campuses,
    } = useSelector((state: AppState) => ({
        userId: state.User.user.id,
        hideGivingTuesday2023Modal:
            state.DoneeNotifications.hideGivingTuesday2023Modal,
        enabledFeatures: state.System.enabledFeatures,
        donee: state.Donee.donee,
        campuses: state.Donee.campuses,
    }));

    const { featureEnabled } = useMemo(
        () => ({
            featureEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.GIVING_TUESDAY_2023,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const prevTimestamp = getFromLocalStorage()?.find(
        (i) => i.userId === userId,
    )?.timestamp;

    const timestampIsValid = useMemo(() => {
        if (!prevTimestamp) return true;

        const currentTimestamp = Date.now();
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

        return currentTimestamp - prevTimestamp >= oneDayInMilliseconds;
    }, [prevTimestamp]);

    const hasMid = (donee.parentCampus || campuses[0]).onboarding?.hasMid;

    // When user answers the poll we should set the flag and hide modal
    // When user picks or creates new envelope we should set the flag but should not hide the modal
    // When user leaves Share page we should hide the modal (flag was already set)
    const [showModal, setShowModal] = useState(
        featureEnabled &&
            !hideGivingTuesday2023Modal &&
            timestampIsValid &&
            hasMid,
    );

    const [showSnackbar, setShowSnackbar] = useState(false);

    return (
        <>
            <GivelifySnackbar
                autoHideDuration={5000}
                message="Thanks! Your feedback was received"
                onClose={(
                    _: React.SyntheticEvent<unknown, Event>,
                    reason: SnackbarCloseReason,
                ) => {
                    if (reason === 'clickaway') return;
                    setShowSnackbar(false);
                }}
                open={showSnackbar}
            />
            {showModal ? (
                <GivingTuesdayContextInternal
                    hideModal={() => setShowModal(false)}
                    showSnackbar={() => setShowSnackbar(true)}
                >
                    {children}
                </GivingTuesdayContextInternal>
            ) : (
                children
            )}
        </>
    );
};
