import React from 'react';
import { TimeFrameId, mergeClassNames } from '@givelify/utils';
import { PaperProps, styled } from '@mui/material';
import { GivelifyButton } from '../button';
import { DesignTokens } from '../specify';
import {
    CalendarComponentProps,
    GivelifyDatePickerShortcuts,
    GivelifyDatePickerTextProps,
    PickerMode,
    ThemeMode,
} from './types';

const RangePickerWrapper = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'themeMode' && propName !== 'pickerMode',
})<{ themeMode: ThemeMode; pickerMode: PickerMode }>(
    ({ theme, themeMode, pickerMode }) => ({
        backgroundColor:
            themeMode === 'dark'
                ? DesignTokens.color.backgroundComponentToolTip
                : DesignTokens.color.backgroundSecondary,
        borderRadius: DesignTokens.measurement.borderRadiusS,
        border: '1px solid ' + DesignTokens.color.borderComponentDefault,
        display: 'flex',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        overflow: 'hidden',
        ...(pickerMode === 'range' && {
            width: '552px',
            height: '496px',
            '& .rp-side': {
                width: '120px',
                flexShrink: 0,
            },
            '& .rp-divider': {
                backgroundColor:
                    themeMode === 'dark'
                        ? DesignTokens.color.globalNeutral700
                        : DesignTokens.color.globalNeutral300,
                width: '1px',
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
                flexShrink: 0,
            },
            '& .rp-content': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            '& .rp-cta-root': {
                width: '100%',
                position: 'relative',
                marginTop: '2.9px',
                marginBottom: '2.9px',
            },
            '& .rp-cta-mark': {
                position: 'absolute',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: DesignTokens.color.textAccentDefault,
                top: 'calc(50% - 4px)',
            },
            '& .rp-cta': {
                width: '100%',
                fontSize: DesignTokens.textStyle.globalBody2.font.size,
                lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
                color:
                    themeMode === 'dark'
                        ? DesignTokens.color.textDisabled
                        : DesignTokens.color.textSecondary,
                backgroundColor: 'transparent',
                outline: 'none',
                border: 'none',
                height: '32px',
                padding: 0,
                alignItems: 'left',
                textAlign: 'left',
                paddingLeft: theme.spacing(2),
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                '&:hover': {
                    color:
                        themeMode === 'dark'
                            ? DesignTokens.color.textWhite
                            : DesignTokens.color.textPrimary,
                },
                '&:active': {
                    color:
                        themeMode === 'dark'
                            ? DesignTokens.color.textWhite
                            : DesignTokens.color.textPrimary,
                    fontWeight: 800,
                },
            },
            '& .rp-cta-active': {
                color:
                    themeMode === 'dark'
                        ? DesignTokens.color.textWhite
                        : DesignTokens.color.textPrimary,
                fontWeight: 800,
            },
            '& .rp-footer': {
                display: 'flex',
                gap: theme.spacing(1),
                marginTop: 'auto',
                justifyContent: 'flex-end',
            },
            '& .rp-footer-cta': {
                minWidth: '120px',
                height: '40px',
            },
        }),
        ...(pickerMode === 'date' && {
            width: 'initial',
            height: 'initial',
            '& .rp-side': {
                display: 'none',
            },
            '& .rp-divider': {
                display: 'none',
            },
            '& .rp-content': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            '& .rp-cta-root': {
                display: 'none',
            },
            '& .rp-cta-mark': {
                display: 'none',
            },
            '& .rp-cta': {
                display: 'none',
            },
            '& .rp-cta-active': {
                display: 'none',
            },
            '& .rp-footer': {
                display: 'none',
            },
            '& .rp-footer-cta': {
                display: 'none',
            },
        }),
        [theme.breakpoints.down('mobile')]: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: DesignTokens.measurement.borderRadiusM,
            boxShadow: DesignTokens.shadow.shadowHigh,
            overflow: 'hidden',
            width: 'initial',
            height: 'initial',
            minHeight: pickerMode === 'range' ? '448px' : 'initial',
            '& .rp-side': {
                display: 'none',
            },
            '& .rp-divider': {
                display: 'none',
            },
            '& .rp-content': {
                width: '336px',
                display: 'flex',
                flexDirection: 'column',
            },
            '& .rp-cta-root': {
                display: 'none',
            },
            '& .rp-cta-mark': {
                display: 'none',
            },
            '& .rp-cta': {
                display: 'none',
            },
            '& .rp-cta-active': {
                display: 'none',
            },
            '& .rp-footer-cta': {
                minWidth: '120px',
                height: '32px',
            },
        },
    }),
);

type GivelifyPickerLayoutProps = {
    pickerMode?: PickerMode;
    children: JSX.Element;
    currentRange: TimeFrameId | null;
    onShortcutClick: (key: TimeFrameId) => void;
    canSave?: boolean;
    onSubmit?: () => void;
    onCancel?: () => void;
    rangePickerShortcuts?: {
        label: string;
        key: TimeFrameId;
    }[];
    texts: GivelifyDatePickerTextProps;
    className?: string;
};

export const GivelifyPickerLayout: React.FCC<
    CalendarComponentProps & PaperProps & GivelifyPickerLayoutProps
> = ({
    themeMode = 'light',
    pickerMode = 'date',
    currentRange,
    onShortcutClick,
    children,
    canSave,
    onSubmit,
    onCancel,
    className,
    rangePickerShortcuts = GivelifyDatePickerShortcuts,
    texts,
    ...props
}) => {
    return (
        <RangePickerWrapper
            {...props}
            className={className}
            pickerMode={pickerMode}
            themeMode={themeMode}
        >
            <div className="rp-side">
                {rangePickerShortcuts.map((shortcut) => {
                    return (
                        <div
                            key={shortcut.key}
                            aria-label={`${shortcut.label} shortcut`}
                            className="rp-cta-root"
                        >
                            {currentRange === shortcut.key ? (
                                <span className="rp-cta-mark" />
                            ) : null}
                            <button
                                className={mergeClassNames(
                                    'rp-cta',
                                    currentRange === shortcut.key &&
                                        'rp-cta-active',
                                )}
                                data-testid={`${shortcut.label.replace(
                                    /\s/g,
                                    '-',
                                )}-custom-date`}
                                id={`rp-cta-${shortcut.key}`}
                                onClick={() => onShortcutClick(shortcut.key)}
                            >
                                {shortcut.label}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="rp-divider" />
            <div className="rp-content">
                <div className="rp-calendar">{children}</div>
                <div className="rp-footer" id="datepicker-footer">
                    <GivelifyButton
                        className="rp-footer-cta"
                        data-testid="datepicker-cancel-btn"
                        id="rp-cancel"
                        name="Cancel"
                        onClick={onCancel}
                        size="medium"
                        text={texts.cancel}
                        variant={
                            themeMode === 'dark' ? 'ghost-rivert' : 'secondary'
                        }
                    />
                    <GivelifyButton
                        disableRipple
                        className="rp-footer-cta"
                        data-testid="datepicker-submit-btn"
                        disabled={!canSave}
                        id="rp-submit"
                        name="Save"
                        onClick={onSubmit}
                        size="medium"
                        text={texts.submit}
                        variant="primary"
                    />
                </div>
            </div>
        </RangePickerWrapper>
    );
};
