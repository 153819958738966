import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../consts';

const InfoWrapper = styled('div')(() => ({
    '& .icon': {
        textAlign: 'center',
        marginBottom: '16px',
    },
}));

export const PrimaryRepInfoHelp: React.FCC = () => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            infoHeading: t('primaryRepresentative.aboutPrimaryRep'),
            infoDescription: t('primaryRepresentative.primaryRepDescription'),
        }),
        [t],
    );

    return (
        <InfoWrapper>
            <div className="icon">
                <GivelifyIcon fontSize={72} variant="primary-rep-colored" />
            </div>
            <GivelifyLabel
                style={{
                    marginBottom: 16,
                }}
                text={copy.infoHeading}
                variant="heading2S"
            />
            <GivelifyLabel text={copy.infoDescription} variant="body2" />
        </InfoWrapper>
    );
};
