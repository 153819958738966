import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { loadingStyles } from './styles';

interface LoadingDotsProps {
    className?: string;
    color?: 'primary' | 'secondary' | 'alt';
}

const LoadingDots: React.FCC<LoadingDotsProps> = (props) => {
    const {
        dots,
        dot,
        dot1,
        dot2,
        dot3,
        primaryBackground,
        secondaryBackground,
        altBackground,
    } = loadingStyles();
    const classNameDot1 = mergeClassNames(
        dot,
        props.color === 'primary' && primaryBackground,
        props.color === 'secondary' && secondaryBackground,
        props.color === 'alt' && altBackground,
        dot1,
    );
    const classNameDot2 = mergeClassNames(
        dot,
        props.color === 'primary' && primaryBackground,
        props.color === 'secondary' && secondaryBackground,
        props.color === 'alt' && altBackground,
        dot2,
    );
    const classNameDot3 = mergeClassNames(
        dot,
        props.color === 'primary' && primaryBackground,
        props.color === 'secondary' && secondaryBackground,
        props.color === 'alt' && altBackground,
        dot3,
    );
    const className = mergeClassNames(dots, props.className);

    return (
        <div className={className}>
            <span className={classNameDot1}></span>
            <span className={classNameDot2}></span>
            <span className={classNameDot3}></span>
        </div>
    );
};

export default LoadingDots;
