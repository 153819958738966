import React, { useMemo } from 'react';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { integrationReportStyle } from './style';

const NoIntegrationReport: React.FCC = () => {
    const { t } = useTranslation();
    const classes = integrationReportStyle();

    const copy = useMemo(
        () => ({
            noIntegration: t('pages.integrations.table.noResult'),
        }),
        [t],
    );

    return (
        <div className={classes.noIntegrationContent}>
            <FontAwesomeIcon icon={faFileAlt} />
            <GivelifyLabel
                className={classes.noResultDefine}
                text={copy.noIntegration}
                variant="body1"
            />
        </div>
    );
};

export default NoIntegrationReport;
