import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';
import { WidgetLoadingIcon, WidgetLoadingIconProps } from './WidgetLoadingIcon';

type WidgetHeaderBoxProps = {
    loading?: boolean;
};

const WidgetHeaderBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'loading',
})<WidgetHeaderBoxProps>((props) => ({
    display: 'flex',
    marginBottom: '38px',
    maxHeight: props.loading ? '82px' : '54px',
}));

const StyledWidgetLoadingIcon = styled(WidgetLoadingIcon)({
    flexShrink: 0,
});

const WidgetDetailsBox = styled('div')({
    marginLeft: '20px',
});

const Title = styled(GivelifyLabel)({
    opacity: 0.6,
    marginBottom: '2px',
});

export type WidgetHeaderProps = {
    id: string;
    title: string;
    description: string;
    loading?: boolean;
    iconProps?: Omit<WidgetLoadingIconProps, 'loading'>;
};

export const WidgetHeader: React.FCC<WidgetHeaderProps> = ({
    id,
    title,
    description,
    loading,
    iconProps,
}) => {
    const details = React.useMemo(() => {
        return loading ? null : (
            <WidgetDetailsBox className="wg-details-box">
                <Title
                    className="wg-title"
                    data-testid={`${id}-title`}
                    id={`${id}-title`}
                    text={title}
                />
                <GivelifyLabel
                    className="wg-descr"
                    data-testid={`${id}-description`}
                    fontSize="20px"
                    id={`${id}-description`}
                    lineHeight="24px"
                    text={description}
                />
            </WidgetDetailsBox>
        );
    }, [loading, id, title, description]);
    return (
        <WidgetHeaderBox
            className="wg-header-box"
            data-testid={`${id}-header`}
            id={`${id}-header`}
            loading={loading}
        >
            <StyledWidgetLoadingIcon
                className="wg-icon-box"
                color={iconProps.color}
                icon={iconProps.icon}
                loading={loading}
            />
            {details}
        </WidgetHeaderBox>
    );
};
