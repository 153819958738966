import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const TileBox = styled('a')(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: DesignTokens.measurement.borderRadiusL,
    backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
    boxShadow: DesignTokens.shadow.shadowMediumLow,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    cursor: 'pointer',
    '& .tile-title': {
        color: DesignTokens.color.backgroundButtonPrimaryHover,
        alignItems: 'center',
        display: 'flex',
    },
    '&:hover': {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryHover,
        '& .tile-title': {
            textDecoration: 'underline',
        },
    },
    height: '100%',
    textDecoration: 'none',
}));

export const TileContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));
