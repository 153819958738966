import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyColorPalette,
    GivelifyInfo,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { CLICK_OLD_DASHBOARD_REPORT_TYPE } from 'pages/reports/GenerateReports/legacy/cleverTapEvents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { legacyReportStyles } from './style';

interface LegacyReportItemProps {
    name: string;
    icon: JSX.Element;
    define: string;
    include: string;
    link: string;
    quickbook?: boolean;
    id?: string;
}

const LegacyReportItem: React.FCC<LegacyReportItemProps> = (props) => {
    const classes = legacyReportStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            quickbooksCompatible: t('reports.item.quickbooksCompatible'),
            getReport: t('reports.getReport'),
        }),
        [t],
    );

    const handleClick = () => {
        trackEvent(CLICK_OLD_DASHBOARD_REPORT_TYPE(props.name));
        navigate(props.link);
    };
    return (
        <Grid
            item
            className={classes.reportItemContainer}
            onClick={handleClick}
        >
            <div className={classes.reportItemHeader} data-testid={props.id}>
                <GivelifyLabel
                    color="#000"
                    fontWeight={600}
                    marginRight={8}
                    text={props.name}
                    variant="heading5"
                />
                <GivelifyInfo
                    classes={{
                        icon: classes.infoIcon,
                        tooltip: classes.reportTooltip,
                        text: classes.reportTooltipText,
                    }}
                    placement="bottom"
                    text={props.include}
                />
            </div>
            <div className={classes.reportItemBody}>
                <div className={classes.reportItemDefine}>
                    <GivelifyLabel
                        className={classes.define}
                        color={GivelifyColorPalette.navigationTextDefault}
                        text={props.define}
                        variant="body2"
                    />
                </div>
                <div className={classes.getReport}>
                    {props.icon}
                    <GivelifyButton
                        className={classes.getReportLink + ' reportLink'}
                        onClick={handleClick}
                        text={copy.getReport}
                        variant="ghost"
                    />
                </div>
            </div>
        </Grid>
    );
};

export default LegacyReportItem;
