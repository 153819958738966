import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { mergeClassNames } from '../utils/classNameUtils';
import useStyles from './styles';
import { LINK_IDENTIFIER, useTrackingContext } from '@givelify/utils';

export interface GivelifyLinkProps {
    href?: string;
    onClick?: () => void;
    className?: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    id?: string;
    name?: string;
    track?: boolean;
}

export const GivelifyLink: React.FCC<GivelifyLinkProps> = ({
    href,
    onClick,
    className,
    target,
    children,
    id,
    track = true,
    name,
}) => {
    const classes = useStyles();
    const linkClassName = useMemo(
        () => mergeClassNames(classes.link, className),
        [classes.link, className],
    );
    const isExternalUrl = useMemo(() => {
        if (!href) return false;
        return new URL(href, location.origin).origin !== location.origin;
    }, [href]);
    const { trackEvent } = useTrackingContext();

    const handleClick = React.useCallback(() => {
        if (name && track) {
            trackEvent(`<${name}>_${LINK_IDENTIFIER}`);
        }
        onClick?.();
    }, [name, onClick, track, trackEvent]);

    if (href) {
        if (isExternalUrl)
            return (
                <a
                    id={id}
                    href={href}
                    className={linkClassName}
                    target={target || '_self'}
                    onClick={handleClick}
                >
                    {children}
                </a>
            );

        return (
            <Link
                id={id}
                to={href}
                className={linkClassName}
                target={target || '_self'}
                onClick={handleClick}
            >
                {children}
            </Link>
        );
    }

    return (
        <span id={id} onClick={handleClick} className={linkClassName}>
            {children}
        </span>
    );
};
