import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { useApiRequest, TimeFrameValues } from '@givelify/utils';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material';
import { useTimeframeFilter } from 'api/hooks';
import { GetDonationsStatisticResponse } from 'api/services/responses/donations';
import { DatePickersWidget, OverviewTrendChart } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    EnvelopeDistributionData,
    DonationStatisticByEnvelopes,
} from 'store/dashboard/envelopes/types';
import { getRangeDateType } from 'utils/timeFrameUtils';
import {
    useOverviewLoaderData,
    useRevalidateWhenScreenChanges,
    useRevalidateWhenTimezonChanges,
} from './hooks';
import { OverviewPoll } from './overviewPoll';
import {
    ChartsWrapper,
    Content,
    TitleWrapper,
    TrendChartWrapper,
} from './overviewStyles';
import { BarChart } from './widgets/barChartWidget';
import { PieCharts } from './widgets/pieChartsWidget';

export const OverviewMain: React.FCC = () => {
    const { t } = useTranslation();
    const [timeFrame, setTimeFrame] = useTimeframeFilter(
        TimeFrameValues['thisMonth'],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const rangeDateType = getRangeDateType(timeFrame, isMobile);
    const lifetimeTimeFrame = TimeFrameValues['lifetime'];

    useRevalidateWhenScreenChanges();
    useRevalidateWhenTimezonChanges();

    const requests = useOverviewLoaderData();

    const [getEnvelopesDataRequest] = useApiRequest<EnvelopeDistributionData>(
        requests.envelopesData,
    );
    const [getDonationDataRequest] =
        useApiRequest<GetDonationsStatisticResponse>(
            requests.donationsStatistics,
        );
    const [getDonationStatisticByEnvelopes] =
        useApiRequest<DonationStatisticByEnvelopes>(
            requests.donationsStatisticsByEnvelopes,
        );

    const { donee, onboardingEnabled, onboardingCompleted } = useSelector(
        (state: AppState) => ({
            donee: state.Donee.donee,
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
        }),
    );

    const doneeId = donee.id;

    const copy = React.useMemo(
        () => ({
            title: t('overview.title'),
            analytics: t('overview.analytics'),
        }),
        [t],
    );

    return (
        <Content data-testid="overview-page-content">
            <TitleWrapper>
                <DatePickersWidget
                    setTimeFrame={setTimeFrame}
                    timeFrame={timeFrame}
                    title={copy.title}
                />
            </TitleWrapper>
            <TrendChartWrapper>
                <OverviewTrendChart
                    donationsDataRequest={getDonationDataRequest}
                    rangeDateType={rangeDateType}
                    timeFrame={timeFrame}
                />
            </TrendChartWrapper>
            {!isMobile ? <OverviewPoll /> : null}
            <TitleWrapper>
                <GivelifyLabel
                    data-testid="analytics-title"
                    role="heading"
                    text={copy.analytics}
                    variant="heading2M"
                />
            </TitleWrapper>
            <ChartsWrapper>
                <PieCharts
                    doneeId={doneeId}
                    envelopesDataRequest={getEnvelopesDataRequest}
                    lifetimeTimeFrame={lifetimeTimeFrame}
                    onboardingCompleted={onboardingCompleted}
                    onboardingEnabled={onboardingEnabled}
                    timeFrame={timeFrame}
                />
                <BarChart
                    donationStatisticByEnvelopesRequest={
                        getDonationStatisticByEnvelopes
                    }
                    doneeId={doneeId}
                    envelopesDataRequest={getEnvelopesDataRequest}
                    onboardingCompleted={onboardingCompleted}
                    onboardingEnabled={onboardingEnabled}
                    rangeDateType={rangeDateType}
                    timeFrame={timeFrame}
                />
            </ChartsWrapper>
        </Content>
    );
};
