import { DesignTokens, GivelifyTab } from '@givelify/ui';
import { styled } from '@mui/material';

export const DonationSummaryTabs = styled(GivelifyTab)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    borderStyle: 'none',
    gap: theme.spacing(1),
    '& .tab-item': {
        minWidth: '128px',
        minHeight: '32px',
        padding: '0',
        borderRadius: '32px',
        background: DesignTokens.color.backgroundButtonSecondaryHover,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:before': {
            display: 'none',
        },
        '& .tab-label': {
            color: DesignTokens.color.textSecondary,
            fontSize: '14px',
        },
        [theme.breakpoints.down('mobile')]: {
            minHeight: '28px',
            '& .tab-label': {
                fontSize: '12px',
            },
        },
    },
    '& .tab-selected': {
        background: DesignTokens.color.backgroundButtonPrimaryDefault,
        '& .tab-label': {
            color: DesignTokens.color.textWhite,
        },
        '& .tab-marker': {
            display: 'none',
        },
    },
}));

export const DatePickerWrapper = styled('div')(({ theme }) => ({
    marginBottom: '32px',
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '24px',
    },
}));

export const NumbersRollWrapper = styled('div')(({ theme }) => ({
    margin: '48px 0',
    [theme.breakpoints.down('tablet')]: {
        margin: '24px 0 48px',
    },
    [theme.breakpoints.down('mobile')]: {
        margin: '24px 0',
    },
}));
