import React from 'react';
import { GivelifyButton, GivelifySnackbar } from '@givelify/givelify-ui';
import { copyToClipboard } from '@givelify/utils';
import { SnackbarCloseReason } from '@material-ui/core';
import clevertap from 'clevertap-web-sdk';

import DarkGivelifyButton from '../../../assets/online-giving/dark-givelify-button.svg';
import LightGivelifyButton from '../../../assets/online-giving/light-givelify-button.svg';
import PrimaryGivelifyButton from '../../../assets/online-giving/primary-givelify-button.svg';
import Label from '../../../components/Label';
import { GiveNowButtonType } from '../../../types';
import { GIVENOW_BUTTON_URLS } from '../../../types/const';
import {
    DARK_GIVE_BUTTON_COPY_CODE,
    PRIMARY_GIVE_BUTTON_COPY_CODE,
    LIGHT_GIVE_BUTTON_COPY_CODE,
} from '../../../utils/cleverTapEvents';
import { useTranslations } from '../../../utils/translations';
import { usePageStyle } from './style';

type ButtonPreviewProps = {
    type: GiveNowButtonType;
    givingLink: string;
};

const CLEVERTAP_EVENTS: { [x in ButtonPreviewProps['type']]: string } = {
    lightGive: LIGHT_GIVE_BUTTON_COPY_CODE,
    primaryGive: PRIMARY_GIVE_BUTTON_COPY_CODE,
    darkGive: DARK_GIVE_BUTTON_COPY_CODE,
};

export const ButtonPreview: React.FCC<ButtonPreviewProps> = ({
    type,
    givingLink,
}: ButtonPreviewProps) => {
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const cs = usePageStyle();

    const { givelifyButton: gb } = useTranslations('onlineGiving', {
        givelifyButton: [
            'preview',
            'lightGive',
            'darkGive',
            'primaryGive',
            'copyCode',
        ],
    });

    const codeBlock = React.useMemo(
        () =>
            `<a target="_blank" href="${givingLink}"><img src="${GIVENOW_BUTTON_URLS[type]}" alt="Givelify" /></a>`,
        [givingLink, type],
    );

    const handleCopyCodeClick = () => {
        clevertap?.event.push(CLEVERTAP_EVENTS[type]);

        copyToClipboard(codeBlock).then((isSuccess) => {
            if (isSuccess) setIsSnackbarOpen(true);
        });
    };

    const handleCloseSnackbar = (
        _: React.SyntheticEvent<unknown>,
        reason: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') return;

        setIsSnackbarOpen(false);
    };

    return (
        <>
            <GivelifySnackbar
                autoHideDuration={3000}
                message="Code copied."
                onClose={handleCloseSnackbar}
                open={isSnackbarOpen}
            />
            <div className={cs.buttonBlock}>
                <div>
                    <div
                        className={cs.buttonLabel}
                        data-testid="button-preview-title"
                    >
                        <Label variant="heading5">{gb[type]}</Label>
                    </div>

                    <div className={cs.givelifyButton}>
                        <Label
                            className={cs.previewLabel}
                            color="neutral-grey"
                            variant="body3"
                        >
                            {gb.preview}
                        </Label>
                        <img
                            alt={type + ' givelify button'}
                            data-testid="button-preview-image"
                            src={
                                type === 'primaryGive'
                                    ? PrimaryGivelifyButton
                                    : type === 'lightGive'
                                    ? LightGivelifyButton
                                    : DarkGivelifyButton
                            }
                        />
                    </div>
                </div>

                <div>
                    <div
                        className={cs.codeBlock}
                        data-testid={`${type}-button-code-block`}
                    >
                        {codeBlock}
                    </div>
                    <GivelifyButton
                        className={cs.copyCodeButton}
                        data-testid="copy-code-button"
                        name={`${type}_copyCode`}
                        onClick={handleCopyCodeClick}
                        size="small"
                        text={gb.copyCode}
                        variant="secondary"
                    />
                </div>
            </div>
        </>
    );
};
