import { GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const ContactDetail = styled(GivelifyLabel)({
    marginBottom: 24,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
});

export const EditorItemTitle = styled(GivelifyLabel)({
    lineHeight: '20px',
    marginBottom: 8,
});

export const EditorInputField = styled(GivelifyLabel)(({ theme }) => ({
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
    },
}));
