import React, { useMemo, useState, useEffect } from 'react';
import { SignedUser, mapSignedUserToLocalUser } from '@givelify/api';
import {
    GivelifyButton,
    GivelifyCheckbox,
    emailRegex,
} from '@givelify/givelify-ui';
import { useInvokeApi } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    FormControlLabel,
    Theme,
    makeStyles,
    createStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { boolean, object, string } from 'yup';
import { TextInput } from '../../../components/inputs/TextInput';
import Space from '../../../components/Space';
import { AppState } from '../../../store';
import { setUser } from '../../../store/user/actions';
import HandleServerErrorText from '../../../theme/components/HandleServerErrorText';
import { phoneRegExp } from '../../../utils/validationRegExp';

const removePhoneNumberFormatting = (phone: string) =>
    phone.replace(/\D+/g, '');

export const addPhoneNumberFormatting = (phone: string | undefined) => {
    if (!phone) return '';
    const array = phone
        .toString()
        .replace(/\D+/g, '')
        .match(/^(\d{3})(\d{3})(\d{4})$/);
    if (!array || array.length < 4) return '';
    return `${array[1]}-${array[2]}-${array[3]}`;
};

interface EditProfile {
    title: string;
    firstName: string;
    lastName: string;
    profilePicUrl?: string;
    email: string;
    phoneNumber: string;
    isNotification: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContent: {
            marginTop: theme.spacing(3),
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'end',
            },
        },
        cancelButton: {
            marginRight: 24,
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
                marginBottom: 24,
            },
        },
    }),
);

export const endPoint = (userId: string | number) => `users/${userId}`;

type EditProfileFormProps = {
    disabled: boolean;
};

const EditProfileForm: React.FCC<EditProfileFormProps> = ({ disabled }) => {
    const { PATH } = useGasRouterContext();
    const { t } = useTranslation();
    const { cancelButton, buttonContent } = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [requestState, makeRequest] = useInvokeApi<{ data: SignedUser }>();
    const user = useSelector((state: AppState) => state.User.user);
    const [isNotification, setIsNotification] = useState<boolean>(user.notify);
    const handleIsNotification = (checked: boolean) =>
        setIsNotification(checked);
    const copy = useMemo(
        () => ({
            save: t('labels.save'),
            cancel: t('labels.cancel'),

            title: t('editProfile.form.title'),
            firstName: t('editProfile.form.firstName'),
            lastName: t('editProfile.form.lastName'),
            email: t('editProfile.form.email'),
            phoneNumber: t('editProfile.form.phoneNumber'),
            isNotification: t('editProfile.form.isNotification'),

            emailRequired: t('error.signup.email'),
            phoneRequired: t('error.signup.phone'),
            titleRequired: t('editProfile.errorMessage.titleRequired'),
            firstNameRequired: t('editProfile.errorMessage.firstNameRequired'),
            lastNameRequired: t('editProfile.errorMessage.lastNameRequired'),
            titleLength: t('editProfile.errorMessage.titleLength'),
            firstNameLength: t('editProfile.errorMessage.firstNameLength'),
            lastNameLength: t('editProfile.errorMessage.lastNameLength'),
            firstNameShort: t('editProfile.errorMessage.shortError', {
                nameItem: t('editProfile.form.firstName'),
            }),
            lastNameShort: t('editProfile.errorMessage.shortError', {
                nameItem: t('editProfile.form.lastName'),
            }),
        }),
        [t],
    );
    const form = useForm<EditProfile>({
        mode: 'onBlur',
        resolver: yupResolver(
            object({
                title: string()
                    .required(copy.titleRequired)
                    .max(25, copy.titleLength),
                firstName: string()
                    .required(copy.firstNameRequired)
                    .max(55, copy.firstNameLength)
                    .min(3, copy.firstNameShort),
                lastName: string()
                    .required(copy.lastNameRequired)
                    .max(55, copy.lastNameLength)
                    .min(1, copy.lastNameShort),
                email: string().matches(emailRegex, copy.emailRequired),
                phoneNumber: string().matches(phoneRegExp, copy.phoneRequired),
                isNotification: boolean(),
            }),
        ),
    });

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            dispatch(
                setUser(mapSignedUserToLocalUser(requestState.response.data)),
            );
            navigate(PATH.OVERVIEW);
        }
    }, [requestState, navigate, dispatch, PATH.OVERVIEW]);

    const handleSubmit = async () => {
        const values = form.getValues();
        makeRequest('PATCH', endPoint(user.id), {
            title: values.title,
            name: values.firstName,
            lname: values.lastName,
            email: values.email,
            phone: removePhoneNumberFormatting(values.phoneNumber),
            notify: isNotification,
        });
    };
    const onClick = () => navigate(PATH.OVERVIEW);
    const loading = requestState.type === 'REQUEST_START';
    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <TextInput<EditProfile>
                defaultValue={user.title}
                disabled={disabled}
                formRef={form}
                id="title-input"
                marginBottom={16}
                name="title"
                placeholder={copy.title}
                type="text"
            />
            <TextInput<EditProfile>
                defaultValue={user.firstName}
                disabled={disabled}
                formRef={form}
                id="firstName-input"
                marginBottom={16}
                name="firstName"
                placeholder={copy.firstName}
                type="text"
            />
            <TextInput<EditProfile>
                defaultValue={user.lastName}
                disabled={disabled}
                formRef={form}
                id="lastName-input"
                marginBottom={16}
                name="lastName"
                placeholder={copy.lastName}
                type="text"
            />
            <TextInput<EditProfile>
                defaultValue={user.email}
                disabled={disabled}
                formRef={form}
                id="email-input"
                marginBottom={16}
                name="email"
                placeholder={copy.email}
                type="text"
            />
            <TextInput<EditProfile>
                defaultValue={addPhoneNumberFormatting(user.phone)}
                disabled={disabled}
                formRef={form}
                id="phoneNumber-input"
                marginBottom={16}
                name="phoneNumber"
                placeholder={copy.phoneNumber}
                type="text"
            />
            <Space gap={1} />
            <FormControlLabel
                control={
                    <GivelifyCheckbox
                        ariaLabel="Notify checkbox"
                        checked={isNotification}
                        disabled={disabled}
                        label={copy.isNotification}
                        marginLeft={15}
                        name="notify"
                        onChange={handleIsNotification}
                    />
                }
                disabled={disabled}
                label=""
            />
            <HandleServerErrorText requestState={requestState} />
            <div className={buttonContent}>
                <GivelifyButton
                    className={cancelButton}
                    name="cancel"
                    onClick={onClick}
                    size="xLarge"
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    disabled={disabled || loading}
                    isLoading={loading}
                    name="save"
                    size="xLarge"
                    text={copy.save}
                    type="submit"
                    variant="primary"
                />
            </div>
        </form>
    );
};

export default EditProfileForm;
