import { User } from '@givelify/api';
import { Reducer } from 'redux';
import {
    getAccessTokenLocalStorage,
    removeAccessTokenLocalStorage,
    clearLocalStorage,
    setAccessTokenLocalStorage,
    setUserEmailLocalStorage,
} from './accessTokenInLocalStorage';
import {
    UserState,
    UserActions,
    SET_ACCESS_TOKEN,
    SET_USER,
    SET_USER_AVATAR,
    HIDE_CONTINUE_ON_MOBILE_SCREEN,
} from './types';

export let accessToken: string | undefined = undefined;

let user: User;
let hideContinueOnMobileScreen: boolean;

const getInitialState = (): UserState => {
    try {
        user = JSON.parse(localStorage.getItem(SET_USER));
        accessToken = getAccessTokenLocalStorage();
        hideContinueOnMobileScreen = Boolean(
            localStorage.getItem(HIDE_CONTINUE_ON_MOBILE_SCREEN),
        );
    } catch (error) {
        if (error instanceof SyntaxError) {
            // If the data is corrupt, just remove it
            localStorage.removeItem(SET_USER);
            removeAccessTokenLocalStorage();
        }
        user = null;
        accessToken = undefined;
        hideContinueOnMobileScreen = null;
    }

    return {
        user,
        accessToken,
        email: user?.email,
        hideContinueOnMobileScreen,
    };
};

const UserReducer = (state: UserState, action: UserActions) => {
    const _state = state || getInitialState();
    return UserReducerInner(_state, action);
};

const UserReducerInner: Reducer<UserState, UserActions> = (state, action) => {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            if (action.accessToken === undefined) {
                accessToken = undefined;
                user = undefined;
                clearLocalStorage();
                return {
                    ...state,
                    accessToken,
                    user,
                    email: undefined,
                };
            }

            accessToken = 'Bearer ' + action.accessToken;
            setAccessTokenLocalStorage(accessToken);

            return {
                ...state,
                accessToken,
                hideContinueOnMobileScreen,
            };

        case SET_USER:
            setUserEmailLocalStorage(action.user?.email);
            return {
                ...state,
                user: action.user,
            };
        case SET_USER_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: action.avatar.url,
                    // eslint-disable-next-line
                    avatarOriginal: action.avatar.croppedUrl,
                    // eslint-disable-next-line
                    avatar_cropped_coordinates:
                        action.avatar.dimensions.croppedAreaPixels,
                },
            };
        case HIDE_CONTINUE_ON_MOBILE_SCREEN:
            hideContinueOnMobileScreen = true;
            localStorage.setItem(HIDE_CONTINUE_ON_MOBILE_SCREEN, String(true));

            return {
                ...state,
                hideContinueOnMobileScreen: true,
            };
        default:
            return state;
    }
};

/**
 * Factory for easily producing a UserReducer with a different initial state
 * Ideal for testing and cosmos
 *
 * @param initialState
 */
export const UserReducerFactory =
    (initialState: UserState) =>
    (state = initialState, action: UserActions) =>
        UserReducer(state, action);

export default UserReducer;
