import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ApiEndpoints } from '../../endpoints';
import {
    ForgotPasswordRequest,
    LoginAdminRequest,
    LoginRequest,
    ResetPasswordRequest,
} from '../../requests';
import { LoginResponse } from '../../responses';
import { IAuthService } from '../interfaces';

export class AuthService implements IAuthService {
    public async login(
        payload: LoginRequest,
    ): Promise<ApiResponse<LoginResponse>> {
        const url = ApiEndpoints.auth.login();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<LoginResponse>(httpRequest);
        return result;
    }

    public async loginAdmin(
        payload: LoginAdminRequest,
    ): Promise<ApiResponse<LoginResponse>> {
        const url = ApiEndpoints.auth.adminLogin();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<LoginResponse>(httpRequest);
        return result;
    }

    public async logout(): Promise<ApiResponse<void>> {
        const url = ApiEndpoints.auth.logout();
        const httpRequest = getAxiosClient().post(url);
        const result = await makeApiRequest<void>(httpRequest);
        return result;
    }

    public async forgotPassword(
        payload: ForgotPasswordRequest,
    ): Promise<ApiResponse<void>> {
        const url = ApiEndpoints.auth.forgotPassowrd();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<void>(httpRequest);
        return result;
    }

    public async resetPassword(
        payload: ResetPasswordRequest,
    ): Promise<ApiResponse<void>> {
        const url = ApiEndpoints.auth.resetPassword();
        const httpRequest = getAxiosClient().put(url, payload);
        const result = await makeApiRequest<void>(httpRequest);
        return result;
    }
}
