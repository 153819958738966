import React from 'react';
import { GivelifyModal, GivelifySnackbar } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';
import { SNACKBAR_TIMEOUT } from '../../../types/const';
import { useTranslations } from '../../../utils/translations';
import { ShareModalContent, ShareActionResult } from './ShareModalContent';

const useStyle = makeStyles((theme: Theme) => ({
    content: {
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 32px)',
        },
    },
}));

export default function useShareModal() {
    const cs = useStyle();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const { linkCopied, emailSent } = useTranslations('general.notifications', [
        'linkCopied',
        'emailSent',
    ]);

    const handleClose = (result: ShareActionResult) => {
        setIsModalOpen(false);

        console.log(result);
        setIsSnackbarOpen(true);
        setSnackbarMessage(
            result === 'CopyLink'
                ? linkCopied
                : result === 'SendEmail'
                ? emailSent
                : '',
        );
    };

    const modalToRender = () => (
        <>
            {snackbarMessage ? (
                <GivelifySnackbar
                    autoHideDuration={SNACKBAR_TIMEOUT}
                    message={snackbarMessage}
                    onClose={() => setIsSnackbarOpen(false)}
                    open={isSnackbarOpen}
                />
            ) : null}

            <GivelifyModal
                disableBackdropClick
                showCloseButton
                contentClassName={cs.content}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                open={isModalOpen}
                testId="share-modal"
            >
                <ShareModalContent onClose={handleClose} />
            </GivelifyModal>
        </>
    );

    return [() => setIsModalOpen(true), modalToRender] as const;
}
