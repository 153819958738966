import React from 'react';
import {
    GivelifyTooltipModal,
    GivelifyLabel,
    GivelifyIcon,
    GivelifyButton,
    DesignTokens,
} from '@givelify/ui';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';

export const TimeZoneNote: React.FCC = () => {
    const { timezoneLong, timezoneShort, timezoneEnabled, isMainCampus } =
        useSelector((state: AppState) => {
            return {
                timezoneLong: state.Donee.donee.timezoneLong,
                timezoneShort: state.Donee.donee.timezoneShort,
                timezoneEnabled:
                    isFeatureEnabled(
                        state.System.enabledFeatures,
                        Features.TIMEZONES,
                        false,
                    ) && state.Donee.donee.timezone !== null,
                isMainCampus:
                    state.Donee.donee?.id === state.Donee.campuses?.[0].id,
            };
        });
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            timezoneNote: t('timezones.notes.address.title'),
            timezoneNoteCampus: t('timezones.notes.address.titleCampus'),
            timezoneDesc: t('timezones.notes.address.description'),
            gotIt: t(`labels.gotIt`),
        }),
        [t],
    );
    const [open, setOpen] = React.useState(false);
    if (!timezoneEnabled) return null;
    return (
        <Stack mt={2}>
            <GivelifyLabel
                text={
                    isMainCampus ? copy.timezoneNote : copy.timezoneNoteCampus
                }
            />
            <Box alignItems="center" display="flex">
                <GivelifyLabel
                    alignItems="center"
                    data-testid="org-timezone"
                    display="flex"
                    fontWeight={700}
                >
                    {`${timezoneLong} - ${timezoneShort}`}
                </GivelifyLabel>
                <GivelifyTooltipModal
                    open={open}
                    placement="bottom"
                    size="small"
                    title={
                        <Stack gap={2} maxWidth={375}>
                            <Stack justifyContent="flex-end">
                                <GivelifyLabel
                                    color="white"
                                    display="flex"
                                    variant="heading3S"
                                    width="100%"
                                >
                                    {copy.timezoneNote}
                                    <GivelifyButton
                                        onClick={() => setOpen(false)}
                                        sx={{ ml: 'auto', p: 0 }}
                                        variant="icon"
                                    >
                                        <GivelifyIcon
                                            color="white"
                                            variant="close"
                                        />
                                    </GivelifyButton>
                                </GivelifyLabel>
                            </Stack>
                            <GivelifyLabel
                                color="white"
                                text={copy.timezoneDesc}
                                variant="body2"
                            />
                            <Stack justifyContent="flex-end" mt={2}>
                                <GivelifyButton
                                    onClick={() => setOpen(false)}
                                    sx={{ ml: 'auto' }}
                                    text={copy.gotIt}
                                    variant="primary"
                                />
                            </Stack>
                        </Stack>
                    }
                >
                    <Stack display="inline-block">
                        <GivelifyButton
                            onClick={() => setOpen(true)}
                            size="small"
                            sx={{ p: 0, ml: 1 }}
                            variant="icon"
                        >
                            <GivelifyIcon
                                color={
                                    DesignTokens.color
                                        .backgroundButtonPrimaryDefault
                                }
                                variant="info"
                            />
                        </GivelifyButton>
                    </Stack>
                </GivelifyTooltipModal>
            </Box>
        </Stack>
    );
};
