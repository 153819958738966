import React, { useCallback, useMemo, useState } from 'react';
import {
    IntegratedSorting,
    SortingState,
    SortingStateProps,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableFixedColumns,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { formatWithTimezone, getInitialsFromName } from '@givelify/utils';
import { Box, TableCellBaseProps } from '@material-ui/core';
import { TableColumnProps } from 'components/Tables/TableColumnTitle';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { IntegrationTypes } from '../../../../../types';
import { exportTabStyle } from '../../style';
import { AutoIntegrationExportedDonationData } from '../../types';
import ExportTableLoading, {
    exportF1GoTableColumnLoading,
    exportF1TableColumnExtensions,
    exportF1TableColumnLoading,
    exportPCTableColumnLoading,
    exportTableColumnExtensions,
    exportTableColumnLoading,
} from './ExportTableLoading';

export const TableComponent = ({ ...restProps }: TableCellBaseProps) => {
    const classes = exportTabStyle();
    return <Table.Table {...restProps} className={classes.exportedTable} />;
};

interface DonorNameProps {
    name: string;
    picture: string;
}
const DonorNameCell: React.FCC<DonorNameProps> = ({ name, picture }) => {
    return (
        <Box alignItems="center" display="flex" flexDirection="row">
            <GivelifyAvatar
                color="eucalyptus"
                size="xSmall"
                src={picture}
                text={getInitialsFromName(name)}
            />
            <GivelifyLabel
                bold
                color="primaryDarkGray"
                marginLeft={8}
                text={name}
                variant="body3"
            />
        </Box>
    );
};
const cell: React.FCC = (props: Table.DataCellProps) => {
    const { column, row } = props;
    switch (column.name) {
        case 'donorName':
            return (
                <Table.Cell {...props}>
                    <DonorNameCell
                        name={row.donorName}
                        picture={row.donorPicture}
                    />
                </Table.Cell>
            );
        case 'amount':
            return (
                <Table.Cell {...props}>
                    <GivelifyLabel
                        bold
                        color="primaryDarkGray"
                        text={`$${formatMoneyWithCommas(row.amount)}`}
                        variant="body3"
                    />
                </Table.Cell>
            );
        case 'exportDate':
            return (
                <Table.Cell {...props}>
                    <GivelifyLabel
                        color="primaryDarkGray"
                        text={`${formatWithTimezone(row.exportDate)}`}
                        variant="body3"
                    />
                </Table.Cell>
            );
        default:
            return <Table.Cell {...props} />;
    }
};

interface AutoIntegrationExportTableProps extends SortingStateProps {
    rows: AutoIntegrationExportedDonationData[];
    isLoading: boolean;
    className?: string;
    integration: IntegrationTypes;
}

const AutoIntegrationExportTable: React.FCC<
    AutoIntegrationExportTableProps
> = ({ rows, sorting, onSortingChange, isLoading, integration }) => {
    const { t } = useAdvancedTranslation();
    const TRANSLATION_KEY = 'pages.integration_exported_donations';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );
    const text = useMemo(
        () => ({
            donorName: scopedTranslate('donorName'),
            givelifyEnvelope: scopedTranslate('givelifyEnvelope'),
            ccbCategory: scopedTranslate('ccbCategory'),
            f1goCategory: scopedTranslate('f1goCategory'),
            f1Fund: scopedTranslate('f1Fund'),
            subFund: scopedTranslate('f1SubFund'),
            donationAmount: scopedTranslate('donationAmount'),
            exportDate: scopedTranslate('exportDate'),
            planningCenterFud: scopedTranslate('planningCenterFund'),
        }),
        [scopedTranslate],
    );

    const { isCCB, isPlanningCenter, isF1, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    const columns: TableColumnProps[] = useMemo(() => {
        let cols = [];
        if (isCCB) {
            cols = [
                { name: 'donorName', title: text.donorName },
                { name: 'givelifyEnvelope', title: text.givelifyEnvelope },
                { name: 'chmsEnvelopeName', title: text.ccbCategory },
                {
                    name: 'amount',
                    title: text.donationAmount,
                },
                { name: 'exportDate', title: text.exportDate },
            ];
        } else if (isF1Go) {
            cols = [
                { name: 'donorName', title: text.donorName },
                { name: 'givelifyEnvelope', title: text.givelifyEnvelope },
                { name: 'chmsEnvelopeName', title: text.f1goCategory },
                {
                    name: 'amount',
                    title: text.donationAmount,
                },
                { name: 'exportDate', title: text.exportDate },
            ];
        } else if (isPlanningCenter) {
            cols = [
                { name: 'donorName', title: text.donorName },
                { name: 'givelifyEnvelope', title: text.givelifyEnvelope },
                { name: 'chmsEnvelopeName', title: text.planningCenterFud },
                {
                    name: 'amount',
                    title: text.donationAmount,
                },
                { name: 'exportDate', title: text.exportDate },
            ];
        } else {
            cols = [
                { name: 'donorName', title: text.donorName },
                { name: 'givelifyEnvelope', title: text.givelifyEnvelope },
                { name: 'chmsEnvelopeName', title: text.f1Fund },
                { name: 'f1subFundName', title: text.subFund },
                {
                    name: 'amount',
                    title: text.donationAmount,
                },
                { name: 'exportDate', title: text.exportDate },
            ];
        }

        return cols;
    }, [isCCB, isF1Go, isPlanningCenter, text]);

    const [leftFixedColumns] = useState(['name']);
    const [sortingStateColumnExtensions] = useState([
        { columnName: 'donorName', sortingEnabled: false },
        { columnName: 'givelifyEnvelope', sortingEnabled: false },
        { columnName: 'chmsEnvelopeName', sortingEnabled: false },
        { columnName: 'f1subFundName', sortingEnabled: false },
        { columnName: 'amount', sortingEnabled: false },
        { columnName: 'exportDate', sortingEnabled: false },
    ]);

    if (isLoading) {
        return (
            <ExportTableLoading
                columns={
                    isCCB
                        ? exportTableColumnLoading
                        : isF1Go
                        ? exportF1GoTableColumnLoading
                        : isF1
                        ? exportF1TableColumnLoading
                        : exportPCTableColumnLoading
                }
                noOfRows={8}
            />
        );
    }
    return (
        <Grid columns={columns} rows={rows}>
            <SortingState
                columnExtensions={sortingStateColumnExtensions}
                onSortingChange={onSortingChange}
                sorting={sorting}
            />
            <IntegratedSorting />
            <Table
                cellComponent={cell}
                columnExtensions={
                    integration.integrationType === 'CCB'
                        ? exportTableColumnExtensions
                        : exportF1TableColumnExtensions
                }
                tableComponent={TableComponent}
            />
            <TableHeaderRow showSortingControls />
            <TableFixedColumns leftColumns={leftFixedColumns} />
        </Grid>
    );
};

export default AutoIntegrationExportTable;
