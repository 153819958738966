import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';
import MissionStatementEditor, {
    MissionStatementEditorProps,
} from './MissionStatementEditor';

const useTipStyles = makeStyles((theme: Theme) =>
    createStyles({
        tipStyles: {
            marginTop: 20,
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
            '& .tip-icon-wrapper': {
                marginRight: theme.spacing(3),
            },
            '& .tip-icon': {
                display: 'inline-block',
                padding: theme.spacing(1, 3),
                marginBottom: theme.spacing(2),
                borderRadius: 31,
                backgroundColor: '#373739',
            },
            '& .tip-text, & .quote-text': {
                flex: '1 1 auto',
            },
            '& .quote-open': {
                width: 16,
                height: 12.57,
                marginRight: theme.spacing(1),
            },
            '& .quote-close': {
                width: 13.75,
                height: 11.47,
                marginLeft: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            '& .quote-container': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& .quote': {
                display: 'flex',
                marginTop: 12,
                maxWidth: 393,
            },
            '& .inline': {
                display: 'inline',
            },
        },
    }),
);

const usePreviewStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewProfile: {
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
            },
        },
    }),
);

const Tip: React.FCC = React.memo(() => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { tipStyles } = useTipStyles();
    const { tipDescription, exampleLabel, tipQuote, tipLabel } = useMemo(
        () => ({
            tipDescription: t(
                'customizeProfile.missionStatement.tipDescription',
            ),
            tipQuote: t('customizeProfile.missionStatement.tipQuote'),
            exampleLabel: `${t('labels.example')}:`,
            tipLabel: t('labels.tip'),
        }),
        [t],
    );
    return (
        <div className={tipStyles}>
            <div className="tip-icon-wrapper">
                <GivelifyLabel
                    className="tip-icon"
                    color="primaryWhite"
                    fontSize={14}
                    text={tipLabel}
                    variant="body1"
                />
            </div>
            <div>
                <GivelifyLabel text={tipDescription} variant="body1" />
                <GivelifyLabel
                    bold
                    marginBottom={8}
                    marginTop={16}
                    text={exampleLabel}
                    variant="body1"
                />
                <div className="quote-container">
                    <div className="quote">
                        <GivelifyIcon
                            className="quote-open"
                            color="guidingVioletLight"
                            variant="quote-open"
                        />
                        <div className="quote-text">
                            <GivelifyLabel
                                className="inline"
                                color="grey600"
                                marginBottom={8}
                                text={tipQuote}
                                variant="body1"
                            />
                            <GivelifyIcon
                                className="inline quote-close"
                                color="guidingVioletLight"
                                variant="quote-close"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

Tip.displayName = 'Tip';

const MissionStatement: React.FCC<MissionStatementEditorProps> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { headingText, viewProfilePreview } = useMemo(
        () => ({
            headingText: t('customizeProfile.missionStatement.heading'),
            viewProfilePreview: t('customizeProfile.viewProfilePreview'),
        }),
        [t],
    );
    const { viewProfile } = usePreviewStyles();
    return (
        <>
            <GivelifyLabel
                marginBottom={16}
                text={headingText}
                variant="heading4"
            />
            <MissionStatementEditor {...props} />
            <Tip />
            <div className={viewProfile}>
                <GivelifyButton
                    onClick={props.onMobilePreviewRequest}
                    text={viewProfilePreview}
                    variant="link"
                />
            </div>
            <GivelifyTextDivider marginBottom={0} marginTop={20} />
        </>
    );
};

MissionStatement.displayName = 'MissionStatement';

export default React.memo(MissionStatement);
