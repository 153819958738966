import React, { useCallback, useMemo } from 'react';
import {
    GivelifyBox,
    GivelifyLabel,
    GivelifyButton,
} from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import continueOnMobile from 'assets/integration-icons/continue-on-mobile.svg';
import Space from 'components/Space';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: 32,
        paddingRight: 32,
        alignItems: 'center',
        textAlign: 'center',
    },
    icon: {
        width: 130,
        height: 130,
        flex: 5,
    },
    textWrapper: {
        flex: 3,
    },
}));

interface ContinueOnMobileProps {
    onContinueClick: () => void;
    textNotification?: string;
}

//TODO; move to common

const ContinueOnMobile: React.FCC<ContinueOnMobileProps> = ({
    onContinueClick,
    textNotification,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integrations.continueOnMobile';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const copy = useMemo(
        () => ({
            heading: scopedTranslate('heading'),
            body: scopedTranslate('body'),
            continue: scopedTranslate('continue'),
        }),
        [scopedTranslate],
    );

    return (
        <GivelifyBox className={classes.wrapper}>
            <img
                alt={copy.continue}
                className={classes.icon}
                src={continueOnMobile}
            />
            <GivelifyBox className={classes.textWrapper}>
                <GivelifyLabel text={copy.heading} variant="heading3" />
                <Space gap={2} />
                <GivelifyLabel text={copy.body} variant="body1" />
            </GivelifyBox>
            <GivelifyButton
                name="continueOnMobile"
                onClick={onContinueClick}
                text={textNotification ? textNotification : copy.continue}
                variant="link"
            />
        </GivelifyBox>
    );
};

export default ContinueOnMobile;
