import React, { useState, useMemo } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithTimezone, useTrackingContext } from '@givelify/utils';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BANK_DEPOSITS_REFUNDS_PAGE_DETAILS_LNK } from 'utils/clevertapEvents';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { DonationItemRowProps } from '../../../../DonationActivity/DonationActivityInfiniteLoader/DonationActivityRow';
import useStyles from '../../styles';
import RefundDetails from './RefundDetails/RefundDetailsDesktop';

const DonationItemRow: React.FCC<DonationItemRowProps> = (props) => {
    const classes = useStyles(props);
    const [open, setOpen] = useState<boolean>(props.openInitial || false);
    const { t } = useTranslation();
    const { trackEvent } = useTrackingContext();

    const handleOpen = () => {
        setOpen(!open);
        trackEvent(BANK_DEPOSITS_REFUNDS_PAGE_DETAILS_LNK);
    };

    const icon = useMemo(() => (open ? faAngleUp : faAngleDown), [open]);

    const RefundText = useMemo(
        () => ({
            viewDetail: t('refunds.text.viewDetail'),
            closeDetail: t('refunds.text.closeDetail'),
            refundStatus: t('refunds.text.refundStatus'),
        }),
        [t],
    );

    const refundDate = formatWithTimezone(props.latestStatusAt);

    const viewDetailText = RefundText.viewDetail;
    const closeDetailText = RefundText.closeDetail;

    const showSecondRow = !props.hideExpandButton;

    return (
        <div className={classes.wrapper}>
            <div
                className={classes.firstRowWrapper}
                id={`donor-info-${props.id}`}
            >
                <GivelifyLabel
                    className={classes.column2}
                    id={`donor-time-${props.id}`}
                    text={refundDate}
                    variant="body1"
                />
                <GivelifyLabel
                    className={classes.column5}
                    id={`donor-name-${props.id}`}
                    text={props.name}
                    variant="body1"
                />
                <GivelifyLabel
                    className={classes.column1}
                    id={`donation-total-${props.id}`}
                    text={`$${formatMoneyWithCommas(
                        props.donation - props.fees || 0,
                    )}`}
                    variant="body1"
                />
            </div>
            {showSecondRow && (
                <div className={classes.secondRowWrapper}>
                    {!props.hideExpandButton && (
                        <div className={classes.openDetail}>
                            <Link
                                className={classes.btnDetail}
                                id={`view-detail-${props.id}`}
                                onClick={handleOpen}
                            >
                                <GivelifyLabel
                                    color="primary"
                                    text={
                                        open ? closeDetailText : viewDetailText
                                    }
                                    variant="body1"
                                />
                                <FontAwesomeIcon icon={icon} />
                            </Link>
                        </div>
                    )}
                </div>
            )}
            {open && (
                <div
                    className={classes.donationDetailsWrapper}
                    id={`donation-detail-${props.id}`}
                >
                    <RefundDetails
                        date={props.date}
                        envelopes={props.envelopes}
                        fees={props.fees}
                        receiptNumber={props.receiptNumber}
                        total={props.donation}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(DonationItemRow);
