import React, { useEffect, useMemo } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useAdvancedTranslation } from 'utils/i18n';
import { webConfig } from 'webConfig';
import { useGivingTueasdayContext } from './context/GivingTuesdayContext';
import { ReactComponent as CalendarIconSvg } from './img/calendarIcon.svg';

const CalendarIcon = styled(CalendarIconSvg)(({ theme }) => ({
    width: 151,
    [theme.breakpoints.down('mobile')]: {
        width: 88,
    },
}));

const WelcomeScreen: React.FCC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { joinGivingTuesdayClick, remindMeLaterClick, noThanksClick } =
        useGivingTueasdayContext();

    const { supportChurchGivingTuesday, supportNonprofitGivingTuesday } =
        webConfig;

    const { donee } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
    }));

    const learnMoreLink =
        donee.type === 'church'
            ? supportChurchGivingTuesday
            : supportNonprofitGivingTuesday;

    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'givingTuesday.welcome',
    );

    const copy = useMemo(
        () => ({
            header: scopedATranslate('header'),
            description: scopedATranslate('description'),
            learnMore: scopedTranslate('learnMore'),
            joinButtonText: scopedTranslate('joinButtonText'),
            remondMeLaterButtonText: scopedTranslate('remondMeLaterButtonText'),
            noThanksButtonText: scopedTranslate('noThanksButtonText'),
        }),
        [scopedTranslate, scopedATranslate],
    );

    useEffect(() => {
        pushEvent('View GT23 modal');
    }, []);

    const joinGivingTuesdatClickInternal = () => {
        pushEvent('Click GT23 modal Opt-in_btn');
        joinGivingTuesdayClick();
    };

    const remindMeLaterClickInternal = () => {
        pushEvent('Click GT23 modal Remind me_btn');
        remindMeLaterClick();
    };

    const noThanksClickInternal = () => {
        pushEvent('Click GT23 modal No thanks_btn');
        noThanksClick();
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <CalendarIcon
                style={{
                    marginBottom: 16,
                }}
            />
            <GivelifyLabel
                align="center"
                text={copy.header}
                variant={isMobile ? 'heading2S' : 'heading2M'}
            />
            <GivelifyLabel
                align="center"
                style={{
                    color: DesignTokens.color.textSecondary,
                    marginTop: 16,
                    marginBottom: 32,
                    marginLeft: 48,
                    marginRight: 48,
                }}
                variant={isMobile ? 'body2' : 'body1'}
            >
                {`${copy.description} `}
                <GivelifyLink
                    href={learnMoreLink}
                    target="_blank"
                    text={copy.learnMore}
                    variant={isMobile ? 'body2B' : 'body1'}
                />
            </GivelifyLabel>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    maxWidth: 290,
                }}
            >
                <GivelifyButton
                    onClick={joinGivingTuesdatClickInternal}
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                    text={copy.joinButtonText}
                    variant="primary"
                />
                <GivelifyButton
                    onClick={remindMeLaterClickInternal}
                    text={copy.remondMeLaterButtonText}
                    variant="secondary"
                />
                <GivelifyButton
                    onClick={noThanksClickInternal}
                    text={copy.noThanksButtonText}
                    variant="ghost"
                />
            </div>
        </div>
    );
};

export default WelcomeScreen;
