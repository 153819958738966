import React from 'react';
import { TimeFrameValue } from '@givelify/utils';
import {
    DatePickersWidgetView,
    DatePickersWidgetViewProps,
} from './DatePickersWidgetView';

type DatePickersWidgetProps = Omit<
    DatePickersWidgetViewProps,
    'onChangeHandler'
> & {
    setTimeFrame: (dateRange: TimeFrameValue) => void;
};

export const DatePickersWidget: React.FCC<DatePickersWidgetProps> = ({
    setTimeFrame,
    ...props
}) => {
    return <DatePickersWidgetView onChangeHandler={setTimeFrame} {...props} />;
};
