import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';
import { NameType, ValueType } from 'pages/overview/types';
import { TooltipProps } from 'recharts';
import { formatMoneyWithKAndM } from 'utils/formatMoneyWithCommas';

const GraphToolTip = styled('div')({
    backgroundColor: '#4a4a4a',
    color: '#fefefe',
    padding: '15px 20px 5px 20px',
    borderRadius: 10,
    fontSize: '14px',
});

type CustomTooltipProps = TooltipProps<ValueType, NameType>;

export const CustomizedTooltip: React.FCC<CustomTooltipProps> = ({
    label,
    payload,
    active,
}) => {
    if (!active || !label || !payload || payload.length === 0) return null;

    return (
        <GraphToolTip data-testid="bar-chart-tooltip">
            <GivelifyLabel
                color={DesignTokens.color.textWhite}
                data-testid="chart-tooltip-label"
                variant="body1"
            >
                {label}
            </GivelifyLabel>
            {payload.map((p, i) => {
                const title = p.name;
                const value = formatMoneyWithKAndM(
                    // we are passing the values as string, so its safe to assume here
                    p.value as string,
                );
                const text = `${title}: $${value}`;
                return (
                    <p key={i} data-testid={`item-${title}`}>
                        {text}
                    </p>
                );
            })}
        </GraphToolTip>
    );
};
