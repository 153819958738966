import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { DonationsStatisticType } from 'api/client/endpoints';
import dayjs from 'dayjs';
import { colorsList } from 'pages/overview/components/styles';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Rectangle,
} from 'recharts';
import { AxisInterval } from 'recharts/src/util/types';
import { formatMoneyWithKAndM } from 'utils/formatMoneyWithCommas';
import { ResultsData } from '../../BarChart';
import { CustomizedTooltip } from '../CustomizedTooltip';
import { BarChartContainer, LegendText } from './styles';

const formatterYAxis = (value: string) => `$${formatMoneyWithKAndM(value)}`;

interface StackedBarChartProps {
    rangeDateType: DonationsStatisticType;
    data: ResultsData[];
    handleChartClick: (
        startDateTime: dayjs.Dayjs,
        endDateTime: dayjs.Dayjs,
    ) => void;
    handleLegendClick: (entry: {
        id: number | number[];
        title: string;
    }) => void;
    dataKey: string;
    keys: { id: number | number[]; title: string }[];
}

const margin = { top: 20, right: 5, left: 5, bottom: 5 };

const SimpleBarChartComponent = ({
    data,
    handleChartClick,
    handleLegendClick,
    dataKey,
    rangeDateType,
    keys,
}: StackedBarChartProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const [hoveredLabel, setHoveredLabel] = React.useState<string | null>(null);

    const xAxisTickInterval: AxisInterval = React.useMemo(() => {
        if (isMobile) {
            switch (rangeDateType) {
                case 'hourly':
                    return 5;
                default:
                    return 'preserveEnd';
            }
        } else if (isTablet) {
            switch (rangeDateType) {
                case 'hourly':
                    return 2;
                default:
                    return 'preserveEnd';
            }
        } else {
            switch (rangeDateType) {
                case 'hourly':
                    return 2;
                default:
                    return 'preserveEnd';
            }
        }
    }, [isMobile, isTablet, rangeDateType]);

    return (
        <BarChartContainer data-testid="bar-chart-container">
            <ResponsiveContainer aspect={1} maxHeight={430} width="99%">
                <BarChart data={data} margin={margin}>
                    <XAxis
                        axisLine={false}
                        dataKey={dataKey}
                        interval={xAxisTickInterval}
                        tick={(props) => (
                            <text
                                data-testid={`x-axis-tick-${props.payload.index}`}
                                orientation="bottom"
                                textAnchor="middle"
                                x={props.x}
                                y={props.y}
                            >
                                <tspan dy="0.71em" x={props.x}>
                                    {props.payload.value}
                                </tspan>
                            </text>
                        )}
                        tickLine={false}
                    />
                    <YAxis
                        axisLine={false}
                        tickFormatter={formatterYAxis}
                        tickLine={false}
                    />
                    <CartesianGrid
                        stroke={DesignTokens.color.dividerPrimaryLight}
                        strokeDasharray="6 6"
                        vertical={false}
                    />
                    <Tooltip content={<CustomizedTooltip />} cursor={false} />
                    <Legend
                        formatter={(id, entry) => (
                            <LegendText
                                data-testid={`legend-text-${id}`}
                                name="Giving trend legend"
                                onClick={() =>
                                    handleLegendClick({
                                        id: JSON.parse(entry.id),
                                        title: entry.value,
                                    })
                                }
                                text={id}
                            />
                        )}
                        iconSize={8}
                        iconType="circle"
                        payload={keys.map((entry, index) => ({
                            id: JSON.stringify(entry.id),
                            type: 'circle',
                            value: entry.title,
                            color: colorsList[index % colorsList.length],
                        }))}
                    />
                    {keys.map((key, index) => (
                        <Bar
                            key={index}
                            cursor="pointer"
                            dataKey={key.title}
                            fill={colorsList[index % colorsList.length]}
                            onClick={(props) =>
                                handleChartClick(
                                    props.startDateTime,
                                    props.endDateTime,
                                )
                            }
                            onMouseOut={() => setHoveredLabel(null)}
                            onMouseOver={(props) => setHoveredLabel(props.name)}
                            shape={(props) => (
                                <Rectangle
                                    {...props}
                                    fillOpacity={
                                        props.name === hoveredLabel ? 0.9 : 1
                                    }
                                    // no other way to test without role
                                    // eslint-disable-next-line jsx-a11y/aria-role
                                    role={`bar-${props.index}`}
                                />
                            )}
                            stackId={1}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </BarChartContainer>
    );
};

export const SimpleBarChart = React.memo(SimpleBarChartComponent);
