import React, { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    SelfIcon,
    CloseOutlinedIcon,
} from '@givelify/ui';
import { styled } from '@mui/material';
import { useCfroContext } from '../context';
import { CurrentStep } from '../context/types';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: '34px 44px',
    background: 'white',
    borderRadius: 20,
    display: 'grid',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        marginBottom: theme.spacing(2),
    },
    gridTemplateAreas: `
	"icon header button"
	"icon text button"
	`,
    gap: 16,
    gridTemplateColumns: '1fr 6fr 3fr',
    [theme.breakpoints.down('md')]: {
        gap: 32,
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
	"icon"
	"header"
	"text"
	"button"
	`,
    },
}));

const Icon = styled(SelfIcon)(({ theme }) => ({
    fontSize: 64,
    lineHeight: 64,
    gridArea: 'icon',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
        justifySelf: 'center',
    },
}));

const Button = styled(GivelifyButton)(({ theme }) => ({
    gridArea: 'button',
    alignSelf: 'center',
    justifySelf: 'center',
    [theme.breakpoints.up('md')]: {
        justifySelf: 'end',
    },
}));

const CloseButton = styled(GivelifyButton)(() => ({
    position: 'absolute',
    right: 12,
    top: 12,
}));

export type ConfirmContactInfoPanelViewProps = {
    currentStep: CurrentStep;
};

const ConfirmContactInfoPanelView: React.FCC<
    ConfirmContactInfoPanelViewProps
> = ({ currentStep }) => {
    const { onOpenCfroModal, onOpenResendModal, onCloseInvitePanel } =
        useCfroContext();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'overview.confirmContactInfoPanel',
    });
    const copy = useMemo(
        () => ({
            confirmHeading: scopedTranslate('confirmHeading'),
            inviteHeading: scopedTranslate('inviteHeading'),
            confirmText: scopedTranslate('confirmText'),
            inviteText: scopedTranslate('inviteText'),
            confirmButtonText: scopedTranslate('confirmButtonText'),
            inviteButtonText: scopedTranslate('inviteButtonText'),
            resendInviteButtonText: scopedTranslate('resendInviteButtonText'),
        }),
        [scopedTranslate],
    );

    const isConfirm = currentStep === 'Confirm';
    const isInvite = currentStep === 'Invite';
    const isResend = currentStep === 'Resend';

    const buttonClick = () => {
        if (isConfirm || isInvite) {
            onOpenCfroModal();
        } else if (isResend) {
            onOpenResendModal();
        }
    };

    const wrapperTestId = {
        Confirm: 'cfroStep1',
        Invite: 'cfroStep2',
        Resend: 'cfroStep3',
    };

    const submitBtnText = {
        Confirm: copy.confirmButtonText,
        Invite: copy.inviteButtonText,
        Resend: copy.resendInviteButtonText,
    };

    return (
        <Wrapper data-testid={wrapperTestId[currentStep]}>
            <Icon />
            {(isInvite || isResend) && (
                <CloseButton
                    name="close"
                    onClick={onCloseInvitePanel}
                    size="large"
                    variant="icon"
                >
                    <CloseOutlinedIcon />
                </CloseButton>
            )}
            <GivelifyLabel
                color={DesignTokens.color.globalInfo400}
                sx={{
                    gridArea: 'header',
                }}
                text={isConfirm ? copy.confirmHeading : copy.inviteHeading}
                variant="heading1S"
            />
            <GivelifyLabel
                sx={{
                    gridArea: 'text',
                    fontWeight: 400,
                }}
                variant="body1"
            >
                {isConfirm ? copy.confirmText : copy.inviteText}
            </GivelifyLabel>
            <Button
                data-testid="cfro"
                name="Submit"
                onClick={buttonClick}
                size="large"
                sx={{
                    width: '206px',
                }}
                text={submitBtnText[currentStep]}
                variant="primaryAlt"
            />
        </Wrapper>
    );
};

export default ConfirmContactInfoPanelView;
