import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyMobilePreviewBase,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { MOCKUP_PATH } from './paths';
import { receiptPreviewStyle } from './receiptPreviewStyle';

export interface ReceiptMobilePreviewProps {
    doneeName: string;
    isOn?: boolean;
}

export const ReceiptMobilePreview: React.FCC<ReceiptMobilePreviewProps> = (
    props,
) => {
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            title: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.title',
            ),
            general: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.general',
            ),
            childrens: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.childrens',
            ),
            receiptN: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.receiptN',
            ),
            editMemberId: at(
                'pages.settings.app-profile.customize-receipt-tab.preview.edit-member',
            ),
            caption: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.caption',
            ),
            creditCard: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.credit-card',
            ),
            search: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.search',
            ),
            give: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.give',
            ),
            home: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.home',
            ),
            profile: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.profile',
            ),
        }),
        [t, at],
    );
    const {
        mobilePreviewContent,
        mobilePreviewDetails,
        mobilePreviewDetails2,
        mobilePreviewDetails3,
        mobilePreviewTabBar,
        mobilePreviewTitleBar,
        mobilePreviewTitleText,
        mobilePreviewDateText,
        mobilePreviewDetailsRow,
        mobilePreviewDetailsRowLined,
        mobilePreviewDetailsRowText,
        mobilePreviewDetailsRowValue,
        mobilePreviewBackArrow,
        mobilePreviewDetailsRowCard,
        mobilePreviewVisa,
        mobilePreviewTabBarIconNormal,
        mobilePreviewTabBarIconSelected,
        mobilePreviewAmmount,
        mobilePreviewHead,
    } = receiptPreviewStyle();
    return (
        <GivelifyMobilePreviewBase
            classes={{ content: mobilePreviewContent }}
            marginTop={-80}
            scale={0.75}
        >
            <div className={mobilePreviewHead}>
                <img alt="Head" src={MOCKUP_PATH.HEAD_DARK} title="Head" />
            </div>
            <div className={mobilePreviewDetails}>
                <div className="white">
                    <div className="inner">
                        <div className={mobilePreviewTitleBar}>
                            <img
                                alt="back"
                                className={mobilePreviewBackArrow}
                                src={MOCKUP_PATH.ICONS.BACK_ARROW_ACTIVE}
                                title="Back arrow"
                            />
                            <GivelifyLabel
                                color="guidingViolet"
                                marginLeft="auto"
                                text={copy.title}
                                variant="heading4"
                            />
                        </div>
                        <div className={mobilePreviewAmmount}>
                            <img
                                alt="Amount"
                                src={MOCKUP_PATH.ICONS.AMOUNT}
                                title="Amount"
                            />
                        </div>
                        <GivelifyLabel
                            className={mobilePreviewTitleText}
                            text={props.doneeName}
                            variant="heading4"
                        />
                        <GivelifyLabel
                            className={mobilePreviewDateText}
                            color="grey600"
                            text="Oct 22, 2019 6:32 PM EDT"
                            variant="body2"
                        />
                        <div className={mobilePreviewDetailsRowLined}>
                            <GivelifyLabel
                                className={mobilePreviewDetailsRowText}
                                color="grey600"
                                text={copy.general}
                                variant="body2"
                            />
                            <GivelifyLabel
                                className={mobilePreviewDetailsRowValue}
                                color="grey600"
                                text="$100"
                                variant="body2"
                            />
                        </div>
                        <div className={mobilePreviewDetailsRowLined}>
                            <GivelifyLabel
                                className={mobilePreviewDetailsRowText}
                                color="grey600"
                                text={copy.childrens}
                                variant="body2"
                            />
                            <GivelifyLabel
                                className={mobilePreviewDetailsRowValue}
                                color="grey600"
                                text="$25"
                                variant="body2"
                            />
                        </div>
                        <div className={mobilePreviewDetailsRowLined}>
                            <GivelifyLabel
                                bold
                                className={mobilePreviewDetailsRowValue}
                                text="Total: $125.00"
                                variant="body1"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={mobilePreviewDetails2}>
                <div className={mobilePreviewDetailsRow}>
                    <GivelifyLabel
                        className={mobilePreviewDetailsRowText}
                        text={copy.creditCard}
                        variant="heading5"
                    />
                    <div className={mobilePreviewDetailsRowCard}>
                        <img
                            alt="visa"
                            className={mobilePreviewVisa}
                            src={MOCKUP_PATH.ICONS.VISA}
                            title="Visa Card Logo"
                        />
                        <GivelifyLabel
                            className={mobilePreviewDetailsRowValue}
                            text="**** 2345"
                            variant="heading5"
                        />
                    </div>
                </div>
                <div className={mobilePreviewDetailsRow}>
                    <GivelifyLabel
                        className={mobilePreviewDetailsRowText}
                        text={copy.receiptN}
                        variant="body1"
                    />
                    <div className={mobilePreviewDetailsRowCard}>
                        <GivelifyLabel
                            className={mobilePreviewDetailsRowValue}
                            text="2108030724"
                            variant="heading5"
                        />
                    </div>
                </div>
            </div>
            {props.isOn ? (
                <div className={mobilePreviewDetails3}>
                    <div className={mobilePreviewDetailsRow}>
                        <GivelifyLabel
                            color="guidingViolet"
                            text={copy.editMemberId}
                            variant="heading5"
                        />
                    </div>
                    <div className={mobilePreviewDetailsRow}>
                        <GivelifyLabel
                            color="grey600"
                            text={copy.caption}
                            variant="body2"
                        />
                    </div>
                </div>
            ) : null}
            <div className={mobilePreviewTabBar}>
                <div className={mobilePreviewTabBarIconNormal}>
                    <img
                        alt="home"
                        src={MOCKUP_PATH.ICONS.HOME}
                        title="Home icon"
                    />
                    <div className="text">{copy.home}</div>
                </div>
                <div className={mobilePreviewTabBarIconNormal}>
                    <img
                        alt="search"
                        src={MOCKUP_PATH.ICONS.SEARCH}
                        title="Search icon"
                    />
                    <div className="text">{copy.search}</div>
                </div>
                <div className={mobilePreviewTabBarIconNormal}>
                    <img
                        alt="give"
                        src={MOCKUP_PATH.ICONS.GIVE}
                        title="Give icon"
                    />
                    <div className="text">{copy.give}</div>
                </div>
                <div className={mobilePreviewTabBarIconSelected}>
                    <img
                        alt="profile"
                        src={MOCKUP_PATH.ICONS.PROFILE}
                        title="Profile icon"
                    />
                    <div className="text">{copy.profile}</div>
                </div>
            </div>
        </GivelifyMobilePreviewBase>
    );
};
