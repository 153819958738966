import React from 'react';
import { colorsList } from 'pages/overview/components/styles';
import { NameType, ValueType } from 'pages/overview/types';
import {
    ResponsiveContainer,
    PieChart,
    Tooltip,
    Pie,
    Cell,
    TooltipProps,
} from 'recharts';
import { RenderCustomizedLabel } from '../ChartLabel';
import { CustomizedTooltip } from '../CustomizedTooltip';
import { PieChartWrapper } from './styles';

export interface SimplePieChartProps<T extends Record<string, unknown>> {
    nameKey: keyof T & string;
    dataKey: keyof T & string;
    data: T[];
    fillColors?: Record<string, string>;
    onSectorClick?: (entry: T, isSector?: boolean) => void;
    tooltip?: React.FCC<TooltipProps<ValueType, NameType>>;
}

const SimplePieChartComponent = <T extends Record<string, unknown>>({
    data,
    nameKey,
    dataKey,
    fillColors,
    onSectorClick,
    tooltip,
}: SimplePieChartProps<T>) => {
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
    return (
        <PieChartWrapper data-testid="pie-chart">
            <ResponsiveContainer aspect={1} maxHeight={400} width="99%">
                <PieChart>
                    <Tooltip content={tooltip ? tooltip : CustomizedTooltip} />
                    <Pie
                        // https://github.com/recharts/recharts/issues/750
                        // the library seems to have a bug
                        // where customized label component does not get props.
                        // Thus it does not render the label at all
                        // isAnimationActive={false} seems to solve the problem
                        // but it loses the animation
                        data={data}
                        dataKey={dataKey}
                        innerRadius="60%"
                        isAnimationActive={false}
                        label={RenderCustomizedLabel}
                        labelLine={false}
                        nameKey={nameKey}
                        outerRadius="100%"
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                cursor="pointer"
                                fill={
                                    fillColors?.[
                                        (entry?.name as string)?.toLowerCase()
                                    ] || colorsList[index % colorsList.length]
                                }
                                fillOpacity={hoveredIndex === index ? 0.9 : 1}
                                onClick={() => onSectorClick?.(entry, true)}
                                onMouseOut={() => setHoveredIndex(null)}
                                onMouseOver={() => setHoveredIndex(index)}
                                // no other way to test without role
                                // eslint-disable-next-line jsx-a11y/aria-role
                                role={`${entry[nameKey]
                                    .toString()
                                    .replace(/ /g, '')}-chart-segment-${index}`}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </PieChartWrapper>
    );
};

export const SimplePieChart = React.memo(SimplePieChartComponent);
