import { Donee } from '@givelify/api';
import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getDenominationsApiEndpoint,
    getDoneeOnboardingApiEndpoint,
} from '../../consts/endpoints';
import { GetDenominationsResponse } from '../../responses/denomination/GetDenominationsResponse';

export const getOnboardingRequest = async (
    doneeId: number,
): Promise<ApiResponse<Donee>> => {
    const url = getDoneeOnboardingApiEndpoint(doneeId);
    const httpRequest = getAxiosClient().get(url);
    return await makeApiRequest<Donee>(httpRequest);
};

export const getDenominationsRequest = async (): Promise<
    ApiResponse<GetDenominationsResponse>
> => {
    const url = getDenominationsApiEndpoint();
    const httpRequest = getAxiosClient().get(url);
    return await makeApiRequest<GetDenominationsResponse>(httpRequest);
};
