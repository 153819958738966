import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { YodleeBank, YodleeBankOnClick } from './usaBankList';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        showYodleeBankStyles: ({ logo }: { logo: string }) => ({
            cursor: 'pointer',
            border: '2px solid #f5f5f5',
            borderRadius: 5,
            margin: theme.spacing(1),
            padding: theme.spacing(3, 4),
            display: 'inline-block',
            '& .logo': {
                height: 50,
                width: 150,
                backgroundImage: `url(${logo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
                [theme.breakpoints.down('xs')]: {
                    height: 40,
                    width: 120,
                },
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2, 3),
            },
        }),
    }),
);

const ShowYodleeBank: React.FCC<YodleeBank & YodleeBankOnClick> = ({
    id,
    name,
    logo,
    onClick,
}) => {
    const handleClick = () => onClick(id);
    const { showYodleeBankStyles } = useStyles({ logo });
    return (
        <div className={showYodleeBankStyles} onClick={handleClick}>
            <div className="logo" title={name}></div>
        </div>
    );
};

export default React.memo(ShowYodleeBank);
