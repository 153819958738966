import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';

export const LoadingState: React.FCC = () => {
    return (
        <div data-testid="loading-state">
            <FacebookLoading borderRadius={0} height={90} width="100%" />
            <div style={{ marginBottom: 10 }} />
            <FacebookLoading borderRadius={0} height={90} width="100%" />
            <div style={{ marginBottom: 10 }} />
            <FacebookLoading borderRadius={0} height={90} width="100%" />
        </div>
    );
};
