import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { confirmationModalStyle } from './ConfirmationModalStyle';

interface DeleteUserModalProps {
    onNotificationModalClose: () => void;
    message: string;
    headerText: string;
}

const NotificationModal: React.FCC<DeleteUserModalProps> = (
    props: DeleteUserModalProps,
) => {
    const style = confirmationModalStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            ok: t('labels.ok'),
        }),
        [t],
    );
    return (
        <GivelifyModal
            autoFullscreen
            open
            showCloseButton
            contentClassName={style.confirmationModal}
            name="notification-modal"
            onClose={props.onNotificationModalClose}
            size="small"
        >
            <div className={style.modalContainer}>
                <GivelifyLabel
                    className={style.statement}
                    marginTop={32}
                    text={props.headerText}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={style.desc}
                    marginTop={16}
                    text={props.message}
                    variant="body1"
                />

                <div className={style.modalFooter}>
                    <GivelifyButton
                        className={style.saveButton}
                        marginRight={8}
                        name="okButton"
                        onClick={props.onNotificationModalClose}
                        size="xLarge"
                        text={copy.ok}
                        variant="secondary"
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default NotificationModal;
