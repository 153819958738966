import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

type WidgetPaperProps = {
    width?: number;
    height?: number;
};

const WidgetPaper = styled('div')<WidgetPaperProps>(({ width, height }) => ({
    width: width || '100%',
    height: height || '100%',
    backgroundColor: DesignTokens.color.backgroundSecondary,
    borderRadius: '12px',
    padding: '24px',
    overflow: 'hidden',
}));

const Title = styled(GivelifyLabel)({
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    color: DesignTokens.color.textPrimary,
    opacity: 0.6,
    marginBottom: '10px',
});

type WidgetLoadingProps = {
    isLoading: boolean;
    children: JSX.Element;
    width?: number;
    height?: number;
};

export const WidgetLoading: React.FCC<WidgetLoadingProps> = ({
    isLoading,
    width,
    height,
    children,
}) => {
    return isLoading ? (
        // Replace later with real loading widget
        <WidgetPaper data-testid="widget-loading" height={height} width={width}>
            <Title role="status" text="Loading" />
        </WidgetPaper>
    ) : (
        children
    );
};
