import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSend = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>send</title>
        <path
            d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z"
            fill="current"
        />
    </svg>
);

export const SendIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgSend} fontSize={fontSize} />
);
