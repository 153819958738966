import {
    DonationService,
    DonationsService,
    DonorsService,
    EnvelopesService,
    ReportsService,
    PollService,
    IntegrationService,
} from '../services/implimentations';
import {
    IEnvelopesService,
    IDonationService,
    IDonationsService,
    IDonorsService,
    IReportsService,
    IPollService,
    IIntegrationService,
} from '../services/interfaces';

const envelopeService = new EnvelopesService();
const pollService = new PollService();
const donationService = new DonationService();
const donationsService = new DonationsService();
const donorsService = new DonorsService();
const reportsService = new ReportsService();
const integrationService = new IntegrationService();

export function getEnvelopesService(): IEnvelopesService {
    return envelopeService;
}

export function getPollService(): IPollService {
    return pollService;
}

export function getDonationService(): IDonationService {
    return donationService;
}

export function getDonationsService(): IDonationsService {
    return donationsService;
}

export function getDonorsService(): IDonorsService {
    return donorsService;
}

export function getReportsService(): IReportsService {
    return reportsService;
}

export function getIntegrationService(): IIntegrationService {
    return integrationService;
}
