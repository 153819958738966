import React from 'react';
import { styled } from '@mui/material';

const ErrorTextBlock = styled('div')(({ theme }) => ({
    color: '#DF5E61',
    borderRadius: 4,
    display: 'inline-block',
    marginTop: theme.spacing(3),
    backgroundColor: 'rgba(213, 41, 44, 0.1)',
    padding: theme.spacing(2, 4),
    width: '100%',
}));

export const ErrorText: React.FCC<{
    text: string;
}> = ({ text }) => {
    return <ErrorTextBlock>{text}</ErrorTextBlock>;
};
