import React, { useState, useEffect, useMemo } from 'react';
import { useInvokeApi } from '@givelify/utils';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DonorResponse } from '../../../../types/donorTypes';
import { ExpandableInput } from './ExpandableInput';

interface Props {
    donorId: number;
    doneeId: number;
    memberId: string;
    readOnly?: boolean;
}

const AssignDonorMemberId: React.FCC<Props> = ({
    donorId,
    doneeId,
    memberId,
    readOnly,
}) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            assignLabel: t('labels.assignId'),
            saveLabel: t('labels.save'),
        }),
        [t],
    );

    const [value, setValue] = useState<string>();
    const [requestState, makeRequest] = useInvokeApi<DonorResponse>();
    const updateDonor = (value: string) => {
        return makeRequest('PATCH', `donees/${doneeId}/donors/${donorId}`, {
            // eslint-disable-next-line
            external_id: value,
        });
    };
    const handleUpdate = (newValue) => {
        if (newValue === value) {
            return;
        }
        updateDonor(newValue);
    };

    useEffect(() => {
        setValue(memberId);
    }, [memberId]);

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            const externalId = requestState?.response?.data?.externalId;
            setValue(externalId || '');
        }
    }, [requestState]);

    if (requestState.type === 'REQUEST_START') {
        return <CircularProgress size="33px" />;
    }

    return (
        <ExpandableInput
            assignLabel={copy.assignLabel}
            onValueUpdate={handleUpdate}
            readOnly={readOnly}
            saveLabel={copy.saveLabel}
            value={value}
        />
    );
};

export default AssignDonorMemberId;
