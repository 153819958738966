import React from 'react';
import {
    GivelifyColorPalette,
    GivelifyLabel,
    GivelifyLabelStyles,
    GivelifyListTile,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { FaithLeaderEditorInfo } from '../../../@types/assets/onboarding';

interface ListItemProps {
    faithLeader: FaithLeaderEditorInfo;
    uploadButton?: JSX.Element;
    selected: boolean;
    onSelect: () => void;
}

const useStyles = makeStyles(() => ({
    selectedListItemContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        maxWidth: 510,
        overflow: 'hidden',
        gap: 8,
    },
    selectedListItemContentText: {
        flex: 1,
    },
    nameWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

const ListItem: React.FCC<ListItemProps> = ({
    faithLeader,
    uploadButton,
    selected,
    onSelect,
}) => {
    const classes = useStyles();
    const { body1, bold, body2, text } = GivelifyLabelStyles({});

    const NameRow = () => (
        <div
            className={mergeClassNames(body1, bold, text)}
            id={`faith-name-${faithLeader.userId}`}
        >
            {`${faithLeader.firstName} ${faithLeader.lastName}`}
            {faithLeader.isYou && (
                <span className={mergeClassNames(body2, text)}>
                    &nbsp;(you)
                </span>
            )}
        </div>
    );

    return (
        <GivelifyListTile
            headerContent={
                <div className={classes.selectedListItemContentWrapper}>
                    <div className={classes.selectedListItemContentText}>
                        <div className={classes.nameWrapper}>
                            <NameRow />
                        </div>
                        <GivelifyLabel
                            color={GivelifyColorPalette.neutralGrey}
                            id={`faith-title-${faithLeader.userId}`}
                            text={faithLeader.title}
                            variant="body3"
                        />
                    </div>
                    {uploadButton}
                </div>
            }
            id={`select-faith-${faithLeader.userId}`}
            onSelect={onSelect}
            selected={selected}
        />
    );
};

export default ListItem;
