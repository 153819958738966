import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithTimezone } from '@givelify/utils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    multiLineCell: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
}));

const DownloadedByCell: React.FCC = (props: Table.DataCellProps) => {
    const { lastDownloadedByName, downloadedAt } = props.row;
    const classes = useStyles();

    return (
        <Table.Cell
            {...props}
            className={classes.multiLineCell}
            style={{
                paddingLeft: 16,
                paddingRight: 16,
            }}
        >
            <GivelifyLabel
                lineHeight={16}
                text={lastDownloadedByName}
                variant="body2"
            />
            {downloadedAt && (
                <GivelifyLabel
                    color="neutralGrey"
                    text={formatWithTimezone(downloadedAt)}
                    variant="body3"
                />
            )}
        </Table.Cell>
    );
};

export default DownloadedByCell;
