import React, { useState, useMemo, useEffect } from 'react';
import { ApiHandler, ResetPasswordRequest } from '@givelify/api';
import { GasDefaultPalette, GivelifyButton } from '@givelify/givelify-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Link, makeStyles, Theme } from '@material-ui/core';
import { TextInput } from 'components/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import * as Yup from 'yup';
import { getEmail } from '../../../store/user/thunks';
import { SET_EMAIL } from '../../../store/user/types';
import GivelifyModal from '../../../theme/components/GivelifyModal';
import Space from '../../Space';
import RecoverPassword from '../recoverPassword/RecoverPassword';

const useStyle = makeStyles((theme: Theme) => ({
    resetPasswordContent: {},
    Button: {
        marginRight: 0,
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    hasVal: {
        '& label': {
            display: 'inline-block',
            position: 'absolute',
            top: '-6px !important',
            left: 12,
            paddingLeft: 5,
            paddingRight: 5,
            background: '#fff',
        },
    },
    loginBack: {
        cursor: 'pointer',
    },
    yourEmail: {
        display: 'flex',
        alignItems: 'center',
        '& a': {},
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 14,
        },
    },
    emailContent: {
        margin: theme.spacing(0, 2),
    },
    notEmail: {
        fontStyle: 'italic',
    },

    modalContent: {
        background: '#fff',
        position: 'absolute',
        width: 690,
        padding: '48px 66px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        borderRadius: 10,
        textAlign: 'center',
        '& button': {
            '& svg': {
                margin: 0,
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: theme.spacing(8, 4, 4),
            top: 'auto',
            bottom: 0,
            left: 0,
            transform: 'translateX(0) translateY(0)',
        },
    },
    closeModal: {
        position: 'absolute',
        top: 10,
        right: 10,
        border: '1px solid #85919F',
        borderRadius: '50%',
        width: 16,
        height: 16,
        maxWidth: 16,
        minWidth: 16,
        padding: 0,
        lineHeight: '100%',
        textAlign: 'center',
        display: 'inline-flex',
        alignItem: 'center',
        justifyContent: 'center',
        color: '#85919F',
        fontSize: 12,
        [theme.breakpoints.down('xs')]: {
            width: 24,
            maxWidth: 24,
            minWidth: 24,
            height: 24,
            fontSize: 15,
        },
    },
    matchError: {
        fontSize: 12,
        textAlign: 'right',
        color: GasDefaultPalette.palette.state.error.text,
    },
    resetForm: {
        textAlign: 'right',
    },
}));

const ResetPasswordForm: React.FCC = () => {
    const classes = useStyle({});
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [formError, setFormError] = useState<string>('');
    const [send, setSent] = useState<boolean>(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const { PATH } = useGasRouterContext();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSignIn = () => {
        setOpen(false);
        navigate(PATH.DONEE.LOGIN);
    };

    const ResetPasswordFormDataText = useMemo(
        () => ({
            text: {
                required_password: t('reset_password.text.required_password'),
                required_confirm_password: t(
                    'reset_password.text.required_confirm_password',
                ),
                success: t('reset_password.text.success'),
                login: t('reset_password.text.login'),
                yourEmail: t('reset_password.text.your_email'),
                notYou: t('reset_password.text.not_you'),
                matched_password: t('reset_password.text.matched_password'),
                short_password: t('reset_password.text.short_password'),
                error_password: t('reset_password.text.error_password'),
            },
            title: {
                password: t('reset_password.title.password'),
                password_holder: t('reset_password.title.password_holder'),
                confirm_password: t('reset_password.title.confirm_password'),
                confirm_password_holder: t(
                    'reset_password.title.confirm_password_holder',
                ),
                reset_password: t('reset_password.title.reset_password'),
            },
        }),
        [t],
    );

    const form = useForm<ResetPasswordRequest>({
        mode: 'onBlur',
        resolver: yupResolver(
            Yup.object({
                password: Yup.string()
                    .required(ResetPasswordFormDataText.text.required_password)
                    .min(6, ResetPasswordFormDataText.text.short_password)
                    .matches(/^(?=.*[A-Z])(?=.*\d).*$/, {
                        message: ResetPasswordFormDataText.text.error_password,
                        excludeEmptyString: true,
                    }),
                passwordConfirmation: Yup.string()
                    .required(
                        ResetPasswordFormDataText.text
                            .required_confirm_password,
                    )
                    .oneOf(
                        [Yup.ref('password'), null],
                        ResetPasswordFormDataText.text.matched_password,
                    ),
                token: Yup.string().optional().nullable(),
            }) as Yup.SchemaOf<ResetPasswordRequest>,
        ),
    });

    const submitDisabled = useMemo(
        () =>
            !(
                form.getValues().password &&
                form.getValues().passwordConfirmation
            ),
        [form],
    );

    const handleSubmit = async (value: ResetPasswordRequest) => {
        value.token = window.location.search.split('=').pop();
        const request = await ApiHandler.instance.auth.resetPassword(value);
        if (request.success) {
            setSent(true);
            setFormError('');
        } else {
            setFormError(request.error.message);
        }
    };

    useEffect(() => {
        const token = window.location.search.split('=').pop();

        if (!token) {
            navigate(PATH.DONEE.LOGIN);
        } else {
            dispatch(getEmail(token));
        }
        // run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userEmail = localStorage.getItem(SET_EMAIL)
        ? localStorage.getItem(SET_EMAIL)
        : '';

    return (
        <form
            className={classes.resetForm}
            onSubmit={form.handleSubmit(handleSubmit)}
        >
            {!send && (
                <div className={classes.resetPasswordContent}>
                    <Typography color="error">{formError}</Typography>
                    <Typography className={classes.yourEmail} component="span">
                        <Typography variant="subtitle1">
                            {ResetPasswordFormDataText.text.yourEmail}:
                        </Typography>
                        <Link
                            className={classes.emailContent}
                            href={'mailto:' + userEmail}
                        >
                            {userEmail}
                        </Link>
                        <Typography className={classes.notEmail}>
                            <Link onClick={handleOpen}>
                                ( {ResetPasswordFormDataText.text.notYou} )
                            </Link>
                        </Typography>
                    </Typography>
                    <Space gap={4} />
                    <TextInput<ResetPasswordRequest>
                        className={
                            form.getValues().password ? classes.hasVal : ''
                        }
                        formRef={form}
                        id="password"
                        label={
                            form.getValues().password
                                ? ResetPasswordFormDataText.title.password
                                : ''
                        }
                        name="password"
                        placeholder={
                            ResetPasswordFormDataText.title.password_holder
                        }
                        type="password"
                    />
                    <Space gap={4} />
                    <TextInput<ResetPasswordRequest>
                        className={
                            form.getValues().passwordConfirmation
                                ? classes.hasVal
                                : ''
                        }
                        formRef={form}
                        id="password_confirmation"
                        label={
                            form.getValues().passwordConfirmation
                                ? ResetPasswordFormDataText.title
                                      .confirm_password
                                : ''
                        }
                        name="passwordConfirmation"
                        placeholder={
                            ResetPasswordFormDataText.title
                                .confirm_password_holder
                        }
                        type="password"
                    />
                    <Space gap={4} />
                    <GivelifyButton
                        className={classes.Button}
                        disabled={submitDisabled}
                        name="submit"
                        onClick={form.handleSubmit(handleSubmit)}
                        size="xLarge"
                        text={ResetPasswordFormDataText.title.reset_password}
                        variant="primary"
                    />
                </div>
            )}
            {send && (
                <div>
                    <Space gap={5} />
                    <Typography variant="h4">
                        {ResetPasswordFormDataText.text.success}
                        <Link
                            className={classes.loginBack}
                            onClick={() => navigate(PATH.DONEE.LOGIN)}
                        >
                            {ResetPasswordFormDataText.text.login}
                        </Link>
                    </Typography>
                </div>
            )}
            <GivelifyModal
                handleClose={handleClose}
                isOpen={open}
                label="Reset Password"
            >
                <RecoverPassword handleClose={handleSignIn} />
            </GivelifyModal>
        </form>
    );
};

export default ResetPasswordForm;
