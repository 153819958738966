import React, { ReactNode } from 'react';
import { GivelifyInfoBox } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';

const OnboardingPage: React.FCC<{
    content: ReactNode;
    informationContent?: ReactNode | null;
    header?: ReactNode;
}> = ({ content, informationContent, header }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('mobile'));

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '9fr 1fr 344px',
                maxWidth: 1088,
                ...(mobile && { gridTemplateColumns: '1fr' }),
            }}
        >
            {header && (
                <div
                    style={{
                        gridColumn: 'span 3',
                    }}
                >
                    {header}
                </div>
            )}
            <div>{content}</div>
            {!mobile && informationContent && (
                <div
                    style={{
                        gridColumn: '3',
                    }}
                >
                    <GivelifyInfoBox>{informationContent}</GivelifyInfoBox>
                </div>
            )}
        </div>
    );
};

export default OnboardingPage;
