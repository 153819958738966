import React from 'react';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '@givelify/api';
import {
    GivelifyLifetimeBanner,
    GivelifyLifetimeBannerSize,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { getDonationService } from '../../../api/utils/serviceProvider';
import { AppState } from '../../../store';
import { LifeTimeData } from '../../../store/dashboard/lifeTime/types';

const isUndefinedORNull = (v: unknown) => v === undefined || v === null;
type AllDonationsTotalProps = {
    timeFrame: TimeFrameValue;
    envelopeIds: number[];
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    messageFilter?: MESSAGE_FILTER_VALUE[];
    pageName: 'activity' | 'bankDeposit' | 'refund';
    size: GivelifyLifetimeBannerSize;
};

export const getPreTextForDonations = (
    pageName: AllDonationsTotalProps['pageName'],
    donationTotalText: {
        activityTotal: string;
        bankDepositTotal: string;
        refundTotal: string;
    },
) =>
    pageName === 'activity'
        ? donationTotalText.activityTotal
        : pageName === 'bankDeposit'
        ? donationTotalText.bankDepositTotal
        : donationTotalText.refundTotal;

const AllDonationsTotal: React.FCC<AllDonationsTotalProps> = ({
    timeFrame,
    envelopeIds,
    transactionFilter,
    messageFilter,
    pageName,
    size,
}) => {
    const { t } = useTranslation();
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));

    const service = getDonationService();

    const [getRequest, makeGetRequest] = useApiRequest<LifeTimeData>();

    React.useEffect(
        () => {
            makeGetRequest(
                service.getTotalDonationsByQuery(
                    doneeId,
                    {
                        timeFrame,
                        envelopeIds,
                        transactionFilter,
                        messageFilter,
                    },
                    pageName,
                ),
            );
        },
        //listen to these changes
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [
            doneeId,
            timeFrame,
            envelopeIds,
            transactionFilter,
            messageFilter,
            pageName,
        ],
    );

    const donationTotalText = React.useMemo(
        () => ({
            activityTotal: t('donationsActivity.text.totalTitle'),
            bankDepositTotal: t('bankDeposits.text.totalTitle'),
            refundTotal: t('refunds.text.totalTitle'),
            donations: t('dashboard.donations'),
            refunds: t('dashboard.refunds'),
            deposits: t('dashboard.deposits'),
            total: t('dashboard.total'),
        }),
        [t],
    );

    return (
        <GivelifyLifetimeBanner
            isDisabled={false}
            isLoading={
                getRequest.type === 'REQUEST_INIT' ||
                getRequest.type === 'REQUEST_START'
            }
            leftBottomText={
                pageName === 'activity'
                    ? donationTotalText.donations
                    : pageName === 'bankDeposit'
                    ? donationTotalText.deposits
                    : donationTotalText.refunds
            }
            leftText={donationTotalText.total}
            rightText={
                (getRequest.type !== 'REQUEST_SUCCESS' ||
                isUndefinedORNull(getRequest.response.data.sum)
                    ? t('Unknown')
                    : `$${formatMoneyWithCommas(
                          pageName === 'refund'
                              ? getRequest.response.data.netSum
                              : getRequest.response.data.sum,
                      )}`) || '$0.00'
            }
            size={size}
        />
    );
};

export default AllDonationsTotal;
