import React from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyIcon,
} from '@givelify/ui';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountInfo } from './AccountInfo';
import { Box } from './styles';
import { AccountBoxProps } from './types';

type AccountSuccessProps = AccountBoxProps & {
    onReplaceClick: () => void;
    disableReplace?: boolean;
};

export const AccountSuccess: React.FCC<AccountSuccessProps> = (props) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newAccount: t(
                'pages.settings.bank-info.customize-account-tab.active-account',
            ),
            success: t(
                'pages.settings.bank-info.customize-account-tab.success',
            ),
            replace: t(
                'pages.settings.bank-info.customize-account-tab.replace',
            ),
            status: t('pages.settings.bank-info.customize-rep-tab.box.status'),
        }),
        [t],
    );
    const { bankingInfo, onReplaceClick, disableReplace } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        <Box>
            <div>
                <Stack alignItems="center" flexDirection="row">
                    <GivelifyLabel
                        marginRight="auto"
                        text={copy.newAccount}
                        variant={isMobile ? 'heading3S' : 'heading2S'}
                    />
                    {!disableReplace && !isMobile ? (
                        <GivelifyButton
                            disableRipple
                            isNarrow
                            onClick={onReplaceClick}
                            text={copy.replace}
                            variant="ghost"
                        />
                    ) : null}
                </Stack>
                <Stack alignItems="center" flexDirection="row" gap={0.5}>
                    <GivelifyLabel variant={isMobile ? 'body2' : 'body1'}>
                        {copy.status}{' '}
                    </GivelifyLabel>
                    <GivelifyLabel
                        color={DesignTokens.color.textSuccessDefault}
                        variant={isMobile ? 'body2' : 'body1'}
                    >
                        {copy.success}
                    </GivelifyLabel>
                    <GivelifyIcon fontSize={20} variant="success-box-icon" />
                </Stack>
            </div>
            <AccountInfo bankingInfo={bankingInfo} className="detailes" />
        </Box>
    );
};
