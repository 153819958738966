import React from 'react';
import { GivelifyTab } from '@givelify/ui';
import { ReportType } from 'store/report/types';
import AutoIntegrationTabsHeader from './AutoIntegrationTabsHeader';
import CommonTabsHeader from './CommonTabsHeader';

interface IntegrationTabHeaderProps {
    value: number;
    onChange: (newValue: number) => void;
    integrationNoDonor: boolean;
    integrationNoEnvelope?: boolean;
    integrationType: ReportType;
}

const TabsHeader: React.FCC<IntegrationTabHeaderProps> = ({
    value,
    onChange,
    integrationNoDonor,
    integrationNoEnvelope,
    integrationType,
}) => {
    const showAutoIntegrationTabs = [
        'CCB',
        'F1',
        'PLANNING_CENTER',
        'F1GO',
    ].includes(integrationType);
    return (
        <GivelifyTab
            onChange={onChange}
            options={
                showAutoIntegrationTabs
                    ? AutoIntegrationTabsHeader()
                    : CommonTabsHeader({
                          integrationNoDonor,
                          integrationNoEnvelope,
                      })
            }
            value={value}
        />
    );
};

export default TabsHeader;
