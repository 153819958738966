import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';

interface DonationStatProps {
    label: string;
    value: number | string;
    isMoney?: boolean;
    hasLoadedDonor?: boolean;
    id?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
    wrapper: {
        textAlign: 'center',
    },
    label: {
        fontSize: '16px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    value: {
        fontSize: '24px',
        lineHeight: '27px',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
}));

export const DonationStat: React.FCC<DonationStatProps> = ({
    label,
    value,
    isMoney,
    hasLoadedDonor,
    id,
}) => {
    const classes = useStyle({});

    const displayValue = isMoney ? `$${formatMoneyWithCommas(+value)}` : value;

    return (
        <div className={classes.wrapper}>
            <div className={classes.label}>{label}</div>
            {!hasLoadedDonor ? (
                <FacebookLoading
                    borderRadius={0}
                    height={30}
                    marginTop={4}
                    width="100%"
                />
            ) : id ? (
                <div className={classes.value} id={id}>
                    {displayValue}
                </div>
            ) : (
                <div className={classes.value}>{displayValue}</div>
            )}
        </div>
    );
};
