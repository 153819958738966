import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const BasicAppProfileViewStyles = makeStyles((theme: Theme) =>
    createStyles({
        basicAppProfileStyles: {
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
            '& .form-section': {
                maxWidth: 600,
                flex: 1,
            },
            '& .image-section': {
                marginLeft: 100,
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            '& .border-bottom': {
                borderBottom: '1px solid #CAD2DB',
            },
            '& .content': {
                marginBottom: 23,
                '& .address-wrapper': {
                    display: 'flex',
                    marginTop: theme.spacing(3),
                    alignItems: 'center',
                },
                '& .address-wrapper > div:first-child': {
                    flex: 1,
                },
                '& .hide-sm': {
                    display: 'initial',
                    [theme.breakpoints.down('xs')]: {
                        display: 'none',
                    },
                },
                '& .show-sm': {
                    display: 'none',
                    [theme.breakpoints.down('xs')]: {
                        display: 'initial',
                    },
                },
            },
        },
    }),
);
