import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import {
    ImgContainer,
    LinkContainer,
    SocialItemValue,
    ValLink,
} from './styles';

type SocialItemProps = {
    imgSrc: string;
    imgAlt: string;
    link: string;
    isReadOnly: boolean;
    testId: string;
};

const SocialItem: React.FCC<SocialItemProps> = (props: SocialItemProps) => {
    return (
        <SocialItemValue>
            <ImgContainer>
                <img alt={props.imgAlt} src={props.imgSrc} />
            </ImgContainer>
            <LinkContainer>
                {props.isReadOnly ? (
                    <GivelifyLabel
                        data-testid={`${props.testId}-txt`}
                        text={props.link}
                        variant="body1"
                    />
                ) : (
                    <ValLink
                        data-testid={`${props.testId}-url`}
                        href={props.link}
                        target="blank"
                        text={props.link}
                    />
                )}
            </LinkContainer>
        </SocialItemValue>
    );
};

export default SocialItem;
