import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import Heading from 'components/Heading';
import PageTitle from 'components/PageTitle';
import Space from 'components/Space';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH } from 'router/routes';
import { ReportType } from 'store/report/types';
import { isFeatureEnabled, Features } from 'utils/featureGating';
import { useAdvancedTranslation } from 'utils/i18n';
import { AppState } from '../../../store';
import { integrationStyle } from '../components/style';
import ThirdPartyModal from '../components/ThirdPartyModal';
import { useIntegrationContext } from '../IntegrationContext';
import { IntegrationTypes } from '../types';
import { useIntegrationCallback } from '../utils/useIntegrationCallback';
import IntegrationItemCard from './IntegrationItemCard';
import { IntegrationsSetupDialog } from './IntegrationSetupDialog';
import { F1goIntegrationSetupDialog } from './IntegrationSetupDialog/f1goIntegrationSetupDialog';
import F1IntegrationSetupDialog from './IntegrationSetupDialog/F1IntegrationSetupDialog';
import PCIntegrationSetupDialog from './IntegrationSetupDialog/PCIntegrationSetupDialog';

const useCustomIntegrations = () => {
    const { PATH } = useGasRouterContext();
    return useMemo<IntegrationTypes[]>(
        () => [
            {
                name: 'Planning Center',
                title: 'Planning Center',
                integrationType: 'PLANNING_CENTER',
                generalIntegrationType: 'PLANNING_CENTER',
                abbreviation: 'Planning Center',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('PLANNING_CENTER'),
                imagePath: '/planning_center.png',
                memberIdLabel: 'Envelope No',
                memberLabel: 'Envelope',
                fundCodeLabel: 'Fund Id',
                fundLabel: 'Offering',
            },
            {
                name: 'FellowshipOne ™',
                integrationType: 'F1',
                abbreviation: 'F1',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('F1'),
                imagePath: '/f1.png',
                memberIdLabel: 'Member ID',
                memberLabel: 'Member',
                fundCodeLabel: 'Envelope Code',
                fundLabel: 'Envelope',
            },
            {
                name: 'Church Community Builder',
                integrationType: 'CCB',
                abbreviation: 'CCB',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('CCB'),
                imagePath: '/ccb.png',
                memberIdLabel: 'Member ID',
                memberLabel: 'Member',
                fundCodeLabel: 'Envelope Code',
                fundLabel: 'Envelope',
            },
            {
                name: 'ACS Technologies™',
                title: 'ACS People & ACS Realm ®',
                integrationType: 'ACS',
                generalIntegrationType: 'ACS',
                abbreviation: 'ACS',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('ACS'),
                imagePath: '/acs.png',
                memberIdLabel: 'Member ID',
                memberLabel: 'Member',
                fundCodeLabel: 'Fund Code',
                fundLabel: 'Fund',
            },
            {
                name: 'Power Church Software',
                integrationType: 'POWERCHURCH',
                abbreviation: 'PC',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('POWERCHURCH'),
                imagePath: '/powerchurch.png',
                memberIdLabel: 'Envelope #',
                memberLabel: 'Envelope',
                fundCodeLabel: 'Fund #',
                fundLabel: 'Envelope',
            },
            {
                name: 'Servant Keeper ®',
                integrationType: 'SERVANTKEEPER',
                abbreviation: 'SK',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('SERVANTKEEPER'),
                imagePath: '/servantkeeper.png',
                memberIdLabel: 'Envelope No',
                memberLabel: 'Envelope',
                fundCodeLabel: 'External Account Name',
                fundLabel: 'External Account Name',
            },
            {
                name: 'Shelby Arena',
                integrationSystem: 'Shelby System',
                title: 'Shelby Arena & Shelby Next ®',
                integrationType: 'SHELBYARENA',
                generalIntegrationType: 'SHELBY',
                abbreviation: 'Shelby Arena',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('SHELBYARENA'),
                imagePath: '/shelbysystem.png',
                memberIdLabel: 'Envelope No',
                memberLabel: 'Envelope',
                fundCodeLabel: 'Fund Id',
                fundLabel: 'Offering',
            },
            {
                name: 'F1 GO',
                integrationType: 'F1GO',
                abbreviation: 'F1 GO',
                title: 'FellowshipOne GO',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('F1GO'),
                imagePath: '/f1go-assets/f1go.png',
                memberIdLabel: 'Member ID',
                memberLabel: 'Member',
                fundCodeLabel: 'Envelope Code',
                fundLabel: 'Envelope',
            },
        ],
        [PATH.DATA],
    );
};

export const useAllIntegrations = () => {
    const { PATH } = useGasRouterContext();
    const customIntegrations = useCustomIntegrations();

    return useMemo<IntegrationTypes[]>(
        () => [
            ...customIntegrations,
            {
                name: 'ACS REALM Technologies™',
                title: 'ACS People & ACS Realm ®',
                integrationType: 'ACSREALM',
                abbreviation: 'ACS Realm',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('ACSREALM'),
                imagePath: '/acs.png',
                memberIdLabel: 'Member ID',
                memberLabel: 'Member',
                fundCodeLabel: 'Fund Code',
                fundLabel: 'Fund',
            },
            {
                name: 'Shelby Next',
                title: 'Shelby Arena & Shelby Next ®',
                integrationType: 'SHELBYNEXT',
                generalIntegrationType: 'SHELBY',
                abbreviation: 'Shelby Next',
                about: 'by Givelify',
                link: PATH.DATA.INTEGRATION_SETUP('SHELBYNEXT'),
                imagePath: '/shelbysystem.png',
                memberIdLabel: 'Envelope No',
                memberLabel: 'Envelope',
                fundCodeLabel: 'External Offering Name',
                fundLabel: 'External Offering Name',
            },
        ],
        [customIntegrations, PATH.DATA],
    );
};

const Integrations: React.FCC = () => {
    const { t, at } = useAdvancedTranslation();
    const { trackEvent } = useTrackingContext();
    const classes = integrationStyle();
    const [open, setOpen] = React.useState(false);
    const [selectedIntegrationType, setSelectedIntegrationType] =
        useState<IntegrationTypes | null>(null);

    const navigate = useNavigate();

    const integrationCallback = useIntegrationCallback();

    const enabledFeatures = useSelector(
        (state: AppState) => state.System.enabledFeatures,
    );

    const customIntegrations = useCustomIntegrations();

    useEffect(() => {
        if (integrationCallback) {
            setSelectedIntegrationType(
                customIntegrations.find(
                    (integration) =>
                        integration.integrationType === 'PLANNING_CENTER',
                ),
            );
            setOpen(true);
        }
    }, [integrationCallback, setSelectedIntegrationType, customIntegrations]);

    const handleClickOpen = (type: ReportType) => {
        const selectedType = customIntegrations.find(
            (integration) => integration.integrationType === type,
        );
        setSelectedIntegrationType(selectedType);
        trackEvent('EnableIntegration_click', { type });
        setOpen(true);
    };

    const handleClose = (type: ReportType) => {
        trackEvent('EnableIntegration_CloseModal', { type });
        setOpen(false);
        navigate(
            {
                search: '',
            },
            { replace: true },
        );
    };

    const copy = useMemo(
        () => ({
            thirdParty: t('pages.integrations.content.thirdParty'),
            thirdPartyTitleBegin: t(
                'pages.integrations.content.thirdPartyTitleBegin',
            ),
            thirdPartyTitleEnd: t(
                'pages.integrations.content.thirdPartyTitleEnd',
            ),
            subtitleContent1: t('pages.integrations.content.subtitle_content1'),
            subtitleContent2: t('pages.integrations.content.subtitle_content2'),
        }),
        [t],
    );
    const [openThirdPary, setOpenThirdParty] = useState<boolean>(false);
    const handleOpenThirdParty = () => {
        setOpenThirdParty(true);
    };
    const handleCloseThirdPary = () => {
        setOpenThirdParty(false);
    };

    const handlePCIntegrationSetup = () => {
        setOpen(false);
        navigate({
            pathname: PATH.DATA.INTEGRATION_CONTENT,
            search: '',
        });
    };

    const { setHaveBanner } = useIntegrationContext();
    const setShowBanner = () => setHaveBanner(true);

    return (
        <>
            <PageTitle
                description={at('pages.integrations.content.about_body')}
                title={t('pages.integrations.title')}
            />
            <Heading underline text={t('pages.integrations.title')} />
            <Space gap={5} />
            <GivelifyLabel text={copy.subtitleContent1} variant="body1" />
            <GivelifyLabel text={copy.subtitleContent2} variant="body1" />
            <Space gap={5} />
            <Grid container spacing={2}>
                {customIntegrations.map((integration, index) => {
                    if (
                        integration.integrationType === 'F1' &&
                        !isFeatureEnabled(
                            enabledFeatures,
                            Features.INTEGRATION_F1,
                        )
                    ) {
                        return null;
                    }
                    if (
                        integration.integrationType === 'CCB' &&
                        !isFeatureEnabled(
                            enabledFeatures,
                            Features.INTEGRATION_CCB,
                        )
                    ) {
                        return null;
                    }
                    if (
                        integration.integrationType === 'PLANNING_CENTER' &&
                        !isFeatureEnabled(
                            enabledFeatures,
                            Features.INTEGRATION_PC,
                        )
                    ) {
                        return null;
                    }
                    if (
                        integration.integrationType === 'F1GO' &&
                        !isFeatureEnabled(
                            enabledFeatures,
                            Features.INTEGRATION_F1_GO,
                        )
                    ) {
                        return null;
                    }
                    return (
                        <Grid
                            key={index}
                            item
                            id={`integration-card-${integration.integrationType}`}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <IntegrationItemCard
                                handleOpen={handleClickOpen}
                                imagePath={integration.imagePath}
                                integrationAbout={integration.about}
                                integrationName={integration.name}
                                integrationTitle={integration.title}
                                integrationType={integration.integrationType}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <div className={classes.thirdParty}>
                <GivelifyLabel
                    color="neutralGrey"
                    text={copy.thirdPartyTitleBegin}
                    variant="caption2"
                />
                <GivelifyLabel
                    className={classes.openThirdPartyBtn}
                    color="neutralGrey"
                    onClick={handleOpenThirdParty}
                    text={copy.thirdParty}
                    variant="caption2"
                />
                <GivelifyLabel
                    color="neutralGrey"
                    text={copy.thirdPartyTitleEnd}
                    variant="caption2"
                />
            </div>
            <ThirdPartyModal
                closeModal={handleCloseThirdPary}
                openModal={openThirdPary}
            />
            {selectedIntegrationType?.integrationType === 'F1' && (
                <F1IntegrationSetupDialog
                    handleClose={() =>
                        handleClose(selectedIntegrationType.integrationType)
                    }
                    open={open}
                    setShowBanner={setShowBanner}
                    type={selectedIntegrationType}
                />
            )}
            {selectedIntegrationType?.integrationType === 'PLANNING_CENTER' && (
                <PCIntegrationSetupDialog
                    handleClose={() =>
                        handleClose(selectedIntegrationType.integrationType)
                    }
                    integrationCallback={integrationCallback}
                    onDone={handlePCIntegrationSetup}
                    open={open}
                    setShowBanner={setShowBanner}
                />
            )}
            {selectedIntegrationType?.integrationType === 'F1GO' && (
                <F1goIntegrationSetupDialog
                    handleClose={() =>
                        handleClose(selectedIntegrationType.integrationType)
                    }
                    open={open}
                    setShowBanner={setShowBanner}
                />
            )}
            {selectedIntegrationType?.integrationType !== 'F1' &&
                selectedIntegrationType?.integrationType !==
                    'PLANNING_CENTER' &&
                selectedIntegrationType?.integrationType !== 'F1GO' && (
                    <IntegrationsSetupDialog
                        handleClose={() =>
                            handleClose(selectedIntegrationType.integrationType)
                        }
                        open={open}
                        setShowBanner={setShowBanner}
                        type={selectedIntegrationType || null}
                    />
                )}
        </>
    );
};

export default Integrations;
