import React, { useCallback, useMemo } from 'react';
import {
    GivelifyModal,
    GivelifyBox,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import warningIcon from 'assets/integration-icons/warning.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        height: 84,
    },
    detailsText: {
        marginTop: 16,
        marginBottom: 24,
    },
}));

interface ConfirmModalProps {
    totalUnmatchedItems: number;
    open: boolean;
    onClose: () => void;
    onDownloadClick: () => void;
    onMatchClick: () => void;
}

const ConfirmModal: React.FCC<ConfirmModalProps> = ({
    totalUnmatchedItems,
    open,
    onClose,
    onDownloadClick,
    onMatchClick,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integrations.confirmModal';
    const scopedTranslate = useCallback(
        (key: string, args = undefined) => t(`${TRANSLATION_KEY}.${key}`, args),
        [t],
    );

    const copy = useMemo(
        () => ({
            download: scopedTranslate('download'),
            matchItems: scopedTranslate('matchItems'),
            heading: scopedTranslate('heading', { totalUnmatchedItems }),
            body: scopedTranslate('body'),
        }),
        [scopedTranslate, totalUnmatchedItems],
    );

    return (
        <GivelifyModal
            centerButtons
            showCloseButton
            name="confirmModal"
            onClose={onClose}
            onPrimaryClick={onDownloadClick}
            onSecondaryClick={onMatchClick}
            open={open}
            primaryButtonText={copy.download}
            secondaryButtonText={copy.matchItems}
        >
            <GivelifyBox className={classes.wrapper}>
                <img alt="" className={classes.icon} src={warningIcon} />
                <GivelifyLabel text={copy.heading} variant="heading3" />
                <GivelifyLabel
                    className={classes.detailsText}
                    text={copy.body}
                    variant="body1"
                />
            </GivelifyBox>
        </GivelifyModal>
    );
};

export default ConfirmModal;
