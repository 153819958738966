import { TimeFrameValue } from '@givelify/utils';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '../models';
import { getDonationQuery } from '../queries';

export const DoneesEndpoints = {
    donee: () => `/donee`,
    donees: (doneeId: number) => `/donees/${doneeId}`,
    bankAccounts: (doneeId: number) => `/donees/${doneeId}/bank-accounts`,
    bankInfo: (doneeId: number) => `/donees/${doneeId}/bank-info`,
    beneficiary: (doneeId: number) => `/donees/${doneeId}/beneficiary`,
    quickGive: (doneeId: number) => `/donees/${doneeId}/quickgive`,
    donations: (
        doneeId: number,
        pageNumber: number,
        timeFrame: TimeFrameValue,
        envelopeIds: number[],
    ) =>
        `/donees/${doneeId}/donations${getDonationQuery({
            timeFrame,
            envelopeIds,
            page: pageNumber,
        })}`,
    donationsRefunded: (
        doneeId: number,
        timeFrame: TimeFrameValue,
        envelopeIds: number[],
        pageNumber: number,
        messageFilter?: MESSAGE_FILTER_VALUE[],
    ) =>
        `/donees/${doneeId}/donations${getDonationQuery({
            timeFrame,
            envelopeIds,
            messageFilter,
            page: pageNumber,
            transactionFilter: TRANSACTION_FILTER_VALUE.REFUNDED,
        })}`,
    deposits: (
        doneeId: number,
        timeFrame: TimeFrameValue,
        transactionFilter?: TRANSACTION_FILTER_VALUE,
        onlyDate?: boolean,
        pageNumber?: number,
    ) =>
        `/donees/${doneeId}/deposits${getDonationQuery({
            timeFrame,
            transactionFilter,
            page: pageNumber,
            onlyDate,
        })}`,
};
