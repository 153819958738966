import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { formatWithTimezone, TimeFrameValue } from '@givelify/utils';
import { Grid } from '@mui/material';
import { TimeLabel } from './styles';

type NoDonationsProps = {
    text: string;
    timeFrame: TimeFrameValue;
};

const NoDonationsComponent: React.FCC<NoDonationsProps> = ({
    text,
    timeFrame,
}) => {
    return (
        <Grid
            container
            alignItems="center"
            data-testid="table-empty-state"
            justifyContent="center"
        >
            <GivelifyLabel text={text} variant="heading2S" />
            &nbsp;
            <TimeLabel
                fontWeight="bold"
                text={`${formatWithTimezone(
                    timeFrame.start,
                    'MMM D, YYYY',
                )} - ${formatWithTimezone(timeFrame.end, 'MMM D, YYYY')}`}
                variant="heading2S"
            />
        </Grid>
    );
};

export const TableEmptyState = React.memo(NoDonationsComponent);
