import React, { useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyLabel,
    GivelifyLink,
    GivelifyTooltipModal,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { styled } from '@mui/material';
import { OldDashboardNavigationPrompt } from 'components/OldDashboardNavigationPrompt/OldDashboardNavigationPrompt';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import {
    NAVIGATION_CLICK_GO_TO_OLD_DASHBOARD,
    NAVIGATION_CLICK_GO_TO_REPORTS,
    NAVIGATION_CLICK_OLD_DASHBOARD_LINK,
    NAVIGATION_VIEW_OLD_DASHBOARD_NAVIGATION_PROMPT,
} from 'utils/clevertapEvents';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { webConfig } from 'webConfig';

const StyledLink = styled(GivelifyLink)({
    textUnderlinePosition: 'under',
    marginTop: 'auto',
    height: '56px',
    minHeight: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '16px',
});

const OldDashboardLinkWrapper = styled('span')({
    display: 'inline-block',
    width: 'fit-content',
});

interface LegacyDashboardLinkProps {
    onClick?: () => void;
}

export const LegacyDashboardLink: React.FCC<LegacyDashboardLinkProps> = ({
    onClick,
}) => {
    const [promptOpen, setPromptOpen] = useState<boolean>(false);
    const { trackEvent } = useTrackingContext();
    const navigate = useNavigate();
    const { enabledFeatures } = useSelector((state: AppState) => ({
        enabledFeatures: state.System.enabledFeatures,
    }));
    const features = useMemo(
        () => ({
            legacyReportsDashboard: isFeatureEnabled(
                enabledFeatures,
                Features.LEGACY_REPORTS_DASHBOARD,
                false,
            ),
        }),
        [enabledFeatures],
    );
    const { t } = useTranslation();

    const copy = React.useMemo(
        () => ({
            more: t('labels.more'),
            signInToLegacyDashboard: t('footer.signInToLegacyDashboard'),
        }),
        [t],
    );

    const { PATH } = useGasRouterContext();

    const openPrompt = () => {
        setPromptOpen(true);
        trackEvent(NAVIGATION_VIEW_OLD_DASHBOARD_NAVIGATION_PROMPT);
        onOldDashboardClick();
    };

    const onOldDashboardClick = React.useCallback(() => {
        trackEvent(NAVIGATION_CLICK_OLD_DASHBOARD_LINK);
    }, [trackEvent]);

    const goToReports = () => {
        setPromptOpen(false);
        onClick?.();
        trackEvent(NAVIGATION_CLICK_GO_TO_REPORTS);
        navigate(PATH.REPORTS.LEGACY_REPORTS);
    };

    const goToOldDashboard = () => {
        setPromptOpen(false);
        trackEvent(NAVIGATION_CLICK_GO_TO_OLD_DASHBOARD);
        onClick?.();
        window.open(webConfig.legacyDashboardSignInUrl, '_blank');
    };

    return features.legacyReportsDashboard ? (
        <GivelifyTooltipModal
            open={promptOpen}
            placement="top-start"
            title={
                <OldDashboardNavigationPrompt
                    onAccept={() => goToReports()}
                    onCancel={() => goToOldDashboard()}
                />
            }
            variant="large"
        >
            <OldDashboardLinkWrapper>
                <StyledLink
                    onClick={openPrompt}
                    target="_blank"
                    text={
                        <GivelifyLabel
                            bold
                            noWrap
                            className="legacy-dashboard-link"
                            color={DesignTokens.color.textAccentDefault}
                            text={copy.signInToLegacyDashboard}
                            variant="body2B"
                        />
                    }
                />
            </OldDashboardLinkWrapper>
        </GivelifyTooltipModal>
    ) : (
        <StyledLink
            href={webConfig.legacyDashboardSignInUrl}
            onClick={onOldDashboardClick}
            target="_blank"
            text={
                <GivelifyLabel
                    bold
                    noWrap
                    className="legacy-dashboard-link"
                    color={DesignTokens.color.textAccentDefault}
                    text={copy.signInToLegacyDashboard}
                    variant="body2B"
                />
            }
        />
    );
};
