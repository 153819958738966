import React, { useEffect, useState } from 'react';
import {
    GivelifyBox,
    GivelifyButton,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { debounce, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { OrganizationType } from '../../@types/assets/onboarding';
import { ReactComponent as ArrowNextSvg } from '../../assets/arrow-next.svg';
import { CardComplete } from './CardComplete';
import { CardProfile } from './CardProfile';
import { swipeCardsStyles } from './swipeCardsStyles';

export interface SwipeCardsProps {
    introductionVideoCardsEnabled?: boolean;
    hideIntroductionVideoCards?: boolean;
    onVideoCardsCloseClick?: () => void;
    newOnboarding?: boolean;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    drawerWidth?: number;
    defaultCardWidth?: number;
    parentWidth?: number;
    parentPadding?: number;
    containerPadding?: number;
    swipeLength?: number;
    progress?: number;
    onCompleteCardActionClick: () => void;
    onCustomizeCardActionClick?: () => void;
    onCard1CloseClick?: () => void;
    onCard2CloseClick?: () => void;
    cardSpacing?: number;
    card1Dismissed?: boolean;
    card2Dismissed?: boolean;
    card1Disabled?: boolean;
    card2Disabled?: boolean;
    requiredPartIsComplete?: boolean;
    card1ShowRetry?: boolean;
    card1OnReviewClick?: () => void;
    deviations?: {
        portWidth: number;
        cardWidth: number;
        cardSpacing: number;
        portOffset: number;
        cardPositionXSecond: number;
    };
    onboardingCompleted?: boolean;
    customizeProfileStarted?: boolean;
    organizationType?: OrganizationType;
    hasMid?: boolean;
}

const positionXFirst = 0;

export const SwipeCards: React.FCC<SwipeCardsProps> = (props) => {
    const {
        cardSpacing = 32,
        swipeLength = 0.25,
        drawerWidth = 0,
        containerPadding = 0,
        defaultCardWidth = 1040,
        parentWidth = 1072,
        parentPadding = 16,
        card1Dismissed = false,
        card2Dismissed = false,
        card1Disabled = false,
        card2Disabled = false,
        onCompleteCardActionClick,
        onCustomizeCardActionClick,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        onCard1CloseClick,
        progress,
        requiredPartIsComplete = false,
        card1ShowRetry = false,
        card1OnReviewClick,
        deviations = {
            portWidth: 0,
            cardWidth: 0,
            cardSpacing: 0,
            portOffset: 0,
            cardPositionXSecond: 0,
        },
    } = props;
    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => setWidth(window.innerWidth);
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    const [viewPortParams, setViewPortParams] = useState({
        portWidth: 0,
        cardWidth: defaultCardWidth,
        spacePadding: 0,
        portOffset: 0,
        cardSpacing: 0,
        cardArrowSpacing: 0,
        drawerWidth: 0,
        cardPositionXSecond: 0,
        card1Dismissed: false,
        card2Dismissed: false,
    });
    const [noCard1, setNoCard1] = useState(false);
    const [noCard2, setNoCard2] = useState(false);
    // const [positionXSecond, setPositionXSecond] = useState(0);
    const [positionX, setPositionX] = useState(positionXFirst);
    const [currentX, setCurrentX] = useState(positionXFirst);

    useEffect(() => {
        const currentDrawerWidth = xsScreen ? 0 : drawerWidth;
        const portWidth =
            width -
            2 * containerPadding -
            currentDrawerWidth +
            deviations.portWidth;
        const oneCard = card1Dismissed || card2Dismissed || noCard1 || noCard2;
        const newCardSpacing = oneCard ? 0 : cardSpacing;
        const cardArrowSpacing = oneCard
            ? 0
            : portWidth < 505 || xsScreen
            ? 0
            : newCardSpacing;
        const cardSpace =
            portWidth < 505 || xsScreen
                ? portWidth - 2 * parentPadding
                : portWidth - 2 * cardArrowSpacing - 46;
        const newCardWidth =
            defaultCardWidth <= cardSpace
                ? defaultCardWidth + deviations.cardWidth
                : cardSpace + deviations.cardWidth;
        const spacePadding =
            (portWidth - newCardWidth) * 0.5 + deviations.cardSpacing;
        const portOffset =
            portWidth > parentWidth
                ? (portWidth - parentWidth) * -0.5 + deviations.portOffset
                : parentPadding - containerPadding + deviations.portOffset;
        setViewPortParams({
            portWidth,
            cardWidth: newCardWidth,
            spacePadding,
            portOffset: portOffset,
            cardSpacing: newCardSpacing,
            drawerWidth: currentDrawerWidth,
            cardPositionXSecond:
                -newCardWidth - newCardSpacing + deviations.cardPositionXSecond,
            card1Dismissed,
            card2Dismissed,
            cardArrowSpacing,
        });
        // eslint-disable-next-line
    }, [
        width,
        drawerWidth,
        xsScreen,
        containerPadding,
        cardSpacing,
        parentWidth,
        parentPadding,
        setViewPortParams,
        card1Dismissed,
        card2Dismissed,
        noCard1,
        noCard2,
    ]);
    const {
        container,
        buttonRight,
        buttonLeft,
        hide,
        arrowHolder,
        arrowRight,
        arrowLeft,
    } = swipeCardsStyles({
        cardSpacing: viewPortParams.cardSpacing,
        arrowSpacing: viewPortParams.cardArrowSpacing,
    });
    const selectedIndex = () => {
        return positionX === positionXFirst ? 0 : 1;
    };
    const next = () => {
        if (currentX === positionXFirst) {
            setPositionX(viewPortParams.cardPositionXSecond);
            setCurrentX(viewPortParams.cardPositionXSecond);
        }
    };
    const prev = () => {
        if (currentX === viewPortParams.cardPositionXSecond) {
            setPositionX(positionXFirst);
            setCurrentX(positionXFirst);
        }
    };
    const [dragData, setDragData] = useState({ start: -1, end: -1 });
    useEffect(() => {
        if (card1Disabled || card2Disabled || noCard1 || noCard2) return;
        if (dragData.end !== -1) {
            const distance = dragData.end - dragData.start;
            if (Math.abs(distance) > viewPortParams.cardWidth * swipeLength) {
                //can move
                if (distance > 0) {
                    prev();
                } else {
                    next();
                }
            } else {
                //reset
                setPositionX(currentX);
            }
            setDragData({ start: -1, end: -1 });
        }
        //we want useEffect to listent only to these changes
        //eslint-disable-next-line
    }, [dragData, noCard1, noCard2, card1Disabled, card2Disabled]);
    const swipeNextWithDebounce = debounce(() => {
        next();
        setNoCard1(true);
    }, 250);
    const swipePrevWithDebounce = debounce(() => {
        prev();
        setNoCard2(true);
    }, 250);
    useEffect(() => {
        if (
            positionX !== positionXFirst &&
            positionX !== viewPortParams.cardPositionXSecond
        ) {
            setPositionX(viewPortParams.cardPositionXSecond);
            setCurrentX(viewPortParams.cardPositionXSecond);
        }
        if (viewPortParams.card1Dismissed && !noCard1) {
            swipeNextWithDebounce();
        } else if (viewPortParams.card2Dismissed && !noCard2) {
            swipePrevWithDebounce();
        }
        //we want useEffect to listent only to these changes
        //eslint-disable-next-line
    }, [viewPortParams]);
    return (
        <GivelifyBox
            divProps={{
                id: 'comp-cards-swipe-vp',
                onMouseDown: (event) => {
                    setDragData({ start: event.screenX, end: -1 });
                },
                onMouseUp: (event) => {
                    setDragData({ start: dragData.start, end: event.screenX });
                },
                style: {
                    boxSizing: 'border-box',
                    userSelect: 'none',
                    msUserSelect: 'none',
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                },
            }}
            left={viewPortParams.portOffset}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginTop={marginTop}
            minHeight={368}
            overflow="hidden"
            paddingTop={2}
            position="relative"
            width={viewPortParams.portWidth}
        >
            <GivelifyBox
                className={container}
                display="flex"
                divProps={{
                    id: 'comp-cards-swipe-cont',
                }}
                left={viewPortParams.spacePadding + positionX}
                marginTop={2}
                minHeight={320}
                position="relative"
                width={
                    viewPortParams.cardWidth * 2 + viewPortParams.cardSpacing
                }
            >
                {card1Disabled || (noCard1 && selectedIndex() === 0) ? null : (
                    <CardComplete
                        disable={selectedIndex() === 1}
                        dismissed={card1Dismissed}
                        onCloseClick={onCard1CloseClick}
                        onCompleteClick={onCompleteCardActionClick}
                        onReviewClick={card1OnReviewClick}
                        progress={progress}
                        showRetry={card1ShowRetry}
                        width={viewPortParams.cardWidth}
                    />
                )}
                {card1Disabled || card2Disabled || noCard1 || noCard2 ? null : (
                    <div className={arrowHolder}>
                        <div className="inside">
                            <GivelifyButton
                                className={
                                    selectedIndex() === 1
                                        ? buttonLeft
                                        : `${buttonLeft} ${hide}`
                                }
                                icon={
                                    <SvgIcon
                                        className={arrowLeft}
                                        component={ArrowNextSvg}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        width={16}
                                    />
                                }
                                id="swipe-arrow-1"
                                onClick={prev}
                                variant="ghost"
                            />
                            <GivelifyButton
                                className={mergeClassNames(
                                    buttonRight,
                                    selectedIndex() !== 0 && hide,
                                )}
                                icon={
                                    <SvgIcon
                                        className={arrowRight}
                                        component={ArrowNextSvg}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        width={16}
                                    />
                                }
                                id="swipe-arrow-2"
                                onClick={next}
                                variant="ghost"
                            />
                        </div>
                    </div>
                )}
                {card2Disabled || (noCard2 && selectedIndex() === 1) ? null : (
                    <CardProfile
                        disable={selectedIndex() === 0}
                        dismissed={card2Dismissed}
                        onCustomizeClick={onCustomizeCardActionClick}
                        requiredPartIsComplete={requiredPartIsComplete}
                        width={viewPortParams.cardWidth}
                    />
                )}
            </GivelifyBox>
            <GivelifyBox
                alignItems="center"
                display="flex"
                height={48}
                justifyContent="center"
                left={viewPortParams.spacePadding}
                position="relative"
                width={viewPortParams.cardWidth}
            >
                {card1Disabled || card2Disabled || noCard1 || noCard2 ? null : (
                    <GivelifyButton
                        height={12}
                        id="swipe-toggle-1"
                        marginRight={4}
                        normalStyle={{
                            padding: 0,
                            backgroundColor:
                                positionX === positionXFirst
                                    ? 'primary'
                                    : 'neutralPlatinum',
                        }}
                        onClick={prev}
                        text=""
                        variant="primary"
                        width={12}
                    />
                )}
                {card1Disabled || card2Disabled || noCard1 || noCard2 ? null : (
                    <GivelifyButton
                        height={12}
                        id="swipe-toggle-2"
                        marginLeft={4}
                        normalStyle={{
                            padding: 0,
                            backgroundColor:
                                positionX === positionXFirst
                                    ? 'neutralPlatinum'
                                    : 'primary',
                        }}
                        onClick={next}
                        text=""
                        variant="primary"
                        width={12}
                    />
                )}
            </GivelifyBox>
        </GivelifyBox>
    );
};
