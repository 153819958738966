import React from 'react';
import { GivelifySinglePolls } from '@givelify/ui';
import {
    useApiRequest,
    responseOrUndefined,
    isSucceeded,
} from '@givelify/utils';
import { Poll, PollAnswer } from 'api/models';
import {
    GetActivePollsListResponse,
    PostPollAnswerResponse,
} from 'api/services/responses/poll';
import { getPollService } from 'api/utils/serviceProvider';
import roleTypes from 'constants/roleTypes';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { OverviewPollWrapper } from './styles';

const ACTIVE_POLL_OPEN_KEY_PREFIX = 'POLL_OPEN';

export const OverviewPoll: React.FCC = () => {
    const [getActivePollsListRequest, makeGetActivePollsListRequest] =
        useApiRequest<GetActivePollsListResponse>();
    const [postPollsAnswerRequest, makePostPollsAnswerRequest] =
        useApiRequest<PostPollAnswerResponse>();
    const [activePoll, setActivePoll] = React.useState<Poll | null>(null);
    const [activePollOpen, setActivePollOpen] = React.useState<boolean>(true);

    const { donee, user } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
        user: state.User.user,
    }));

    const role = user.role;

    const isAdminPanelRole = role === roleTypes.ADMIN_PANEL_USER;

    const pollService = getPollService();

    const doneeId = donee.id;

    React.useEffect(() => {
        void makeGetActivePollsListRequest(
            pollService.getActivePollsList(doneeId),
        );
        // listen only for doneeId changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doneeId]);

    React.useEffect(() => {
        if (isSucceeded(getActivePollsListRequest)) {
            const poll = responseOrUndefined(getActivePollsListRequest)
                ?.data[0];
            setActivePoll(poll);
            if (poll) {
                const openPollString = localStorage.getItem(
                    `${ACTIVE_POLL_OPEN_KEY_PREFIX}_${doneeId}_${poll.id}`,
                );
                try {
                    const openPoll = openPollString
                        ? JSON.parse(openPollString)
                        : true;
                    setActivePollOpen(openPoll);
                } catch (_) {
                    setActivePollOpen(true);
                }
            }
        }
    }, [doneeId, getActivePollsListRequest]);

    React.useEffect(() => {
        if (isSucceeded(postPollsAnswerRequest)) {
            setActivePoll(responseOrUndefined(postPollsAnswerRequest)?.data);
        }
    }, [postPollsAnswerRequest]);

    const onPollAnswer = React.useCallback(
        (answer: PollAnswer) => {
            void makePostPollsAnswerRequest(
                pollService.postPollAnswer(doneeId, activePoll.id, answer),
            );
        },
        [activePoll, doneeId, makePostPollsAnswerRequest, pollService],
    );

    const onCollapseToggle = React.useCallback(() => {
        setActivePollOpen(!activePollOpen);
        localStorage.setItem(
            `${ACTIVE_POLL_OPEN_KEY_PREFIX}_${doneeId}_${activePoll.id}`,
            JSON.stringify(!activePollOpen),
        );
    }, [activePoll, activePollOpen, doneeId]);

    if (!activePoll) return null;

    return (
        <OverviewPollWrapper>
            <GivelifySinglePolls
                active={activePoll.active}
                answers={activePoll.answers}
                endDate={dayjs(activePoll.endDate)}
                isAnswered={isAdminPanelRole || activePoll.isAnswered}
                onCollapseToggle={onCollapseToggle}
                onPollAnswer={onPollAnswer}
                open={activePollOpen}
                title={activePoll.title}
                totalAnswers={activePoll.totalAnswers}
            />
        </OverviewPollWrapper>
    );
};
