import React from 'react';
import { getInitialsFromName } from '@givelify/utils';
import {
    Bubble,
    DesktopName,
    Image,
    ImageWrapper,
    Message,
    MessageWrapper,
    MessageDate,
    MessageText,
    MobileName,
} from './styles';

type DonationMemoMessageProps = {
    name: string;
    message: string;
    photo?: string;
    dateMessage?: string;
};

const DonationHistoryMessageMessageComponent: React.FCC<
    DonationMemoMessageProps
> = ({ name, message, photo, dateMessage }) => {
    return (
        <Message>
            <ImageWrapper>
                <Image
                    alt="User Profile"
                    color="eucalyptus"
                    src={photo}
                    text={getInitialsFromName(name)}
                    title="User Profile"
                />
            </ImageWrapper>
            <MobileName>{name}</MobileName>
            <MessageWrapper>
                <Bubble>
                    <DesktopName>{name}</DesktopName>{' '}
                    <MessageText>{message}</MessageText>
                </Bubble>
                <MessageDate>{dateMessage}</MessageDate>
            </MessageWrapper>
        </Message>
    );
};

export const DonationHistoryMessageMessage = React.memo(
    DonationHistoryMessageMessageComponent,
);
