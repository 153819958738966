import React from 'react';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName } from '@givelify/utils';
import { Box, makeStyles, Theme } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) => {
    const spacing1 = theme.spacing(1);
    const spacing2 = theme.spacing(2);
    const spacing3 = theme.spacing(3);
    const spacing4 = theme.spacing(4);
    return {
        imageWrapper: {
            flex: '0 0 auto',
            width: '32px',
            height: '32px',
            borderRadius: '100px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginRight: spacing3,
            [theme.breakpoints.down('xs')]: {
                width: '16px',
                height: '16px',
            },
        },
        image: {
            width: '100%',
            height: '100%',
            [theme.breakpoints.down('xs')]: {
                fontSize: '12px',
            },
        },
        desktopName: {
            flex: '0 0 auto',
            paddingRight: spacing4,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        mobileName: {
            fontSize: '14px',
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                flex: '0 0 auto',
                display: 'block',
                width: '80%',
            },
        },
        bubble: {
            display: 'flex',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.gas.palette.input.idle.border,
            borderRadius: '25px',
            fontSize: '16px',
            paddingTop: spacing2,
            paddingBottom: spacing2,
            paddingRight: spacing4,
            paddingLeft: spacing4,
            [theme.breakpoints.down('xs')]: {
                marginLeft: '20px',
                marginTop: spacing1,
            },
        },
        messageDate: {
            textAlign: 'end',
            paddingRight: '1rem',
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'start',
                marginLeft: '20px',
                fontSize: '12px',
            },
        },
        message: {
            display: 'flex',
            paddingTop: spacing3,
            paddingBottom: spacing3,
            [theme.breakpoints.down('xs')]: {
                flexWrap: 'wrap',
            },
        },
    };
});

interface DonationMemoMessageProps {
    name: string;
    message: string;
    photo?: string;
    dateMessage?: string;
}

const DonationMemoMessage: React.FCC<DonationMemoMessageProps> = ({
    name,
    message,
    photo,
    dateMessage,
}) => {
    const classes = useStyle();
    return (
        <div className={classes.message}>
            <div className={classes.imageWrapper}>
                <GivelifyAvatar
                    className={classes.image}
                    color="eucalyptus"
                    src={photo}
                    text={getInitialsFromName(name)}
                    title="User Profile"
                />
            </div>
            <div className={classes.mobileName}>{name}</div>
            <div>
                <div className={classes.bubble}>
                    <div className={classes.desktopName}>{name}</div> {message}
                </div>
                <Box className={classes.messageDate}>{dateMessage}</Box>
            </div>
        </div>
    );
};

export default DonationMemoMessage;
