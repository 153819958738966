import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyColorPalette,
    GivelifyInfo,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import quickbookIcon from '../../../assets/quickbooks-logo.png';
import { reportRowStyles } from '../components/style';

interface ReportItemProps {
    name: string;
    icon: JSX.Element;
    define: string;
    include: string;
    link: string;
    quickbook?: boolean;
    testId?: string;
    isLegacy?: boolean;
}

const ReportItem: React.FCC<ReportItemProps> = (props) => {
    const classes = reportRowStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            quickbooksCompatible: t('reports.item.quickbooksCompatible'),
            getReport: t('reports.getReport'),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClick = () => {
        trackEvent(`${props.isLegacy ? 'Legacy ' : ''}${props.name} Report`);
        navigate(props.link);
    };
    return (
        <Grid
            item
            className={classes.reportItemContainer}
            onClick={handleClick}
        >
            <div
                className={classes.reportItemHeader}
                data-testid={props.testId}
            >
                <GivelifyLabel
                    marginRight={8}
                    text={props.name}
                    variant="heading5"
                />
                <GivelifyInfo
                    classes={{ tooltip: classes.reportTooltip }}
                    text={props.include}
                />
            </div>
            <div className={classes.reportItemBody}>
                <div className={classes.reportItemDefine}>
                    <GivelifyLabel
                        className={classes.define}
                        color={GivelifyColorPalette.neutralGrey}
                        text={props.define}
                        variant="body2"
                    />
                    {!isMobile && props.quickbook && (
                        <GivelifyLabel
                            color={GivelifyColorPalette.primaryDarkGray}
                            marginBottom={6}
                            marginTop="auto"
                            text={copy.quickbooksCompatible}
                            variant="body3"
                        />
                    )}
                </div>
                <div className={classes.getReport}>
                    <GivelifyButton
                        className={classes.getReportLink + ' reportLink'}
                        onClick={handleClick}
                        text={copy.getReport}
                        variant="ghost"
                    />
                    {isMobile && props.quickbook && (
                        <img
                            alt="Quicksbooks"
                            className={classes.quickbookIcon}
                            src={quickbookIcon}
                        />
                    )}
                    {props.icon}
                </div>
            </div>
        </Grid>
    );
};

export default ReportItem;
