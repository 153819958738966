import React, { useMemo } from 'react';
import { useTabFilter } from 'api/hooks';
import { BasicPageWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGasRouterContext } from 'router/GasRouterProvider';
import RoleTypes from '../../../constants/roleTypes';
import { AppState } from '../../../store';
import { CustomizeAccountTab } from './customizeAccount';
import {
    PrimaryRepresentativeTab,
    PrimaryRepEditorRef,
} from './primaryRepresentative';

const TAB_PATH = {
    account: 'account',
    rep: 'rep',
} as const;

export const BankInfo: React.FCC = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useTabFilter([TAB_PATH.account, TAB_PATH.rep], {
        tab: TAB_PATH.account,
    });
    const copy = useMemo(
        () => ({
            description: t('pages.settings.bank-info.caption'),
            accountTab: t(
                'pages.settings.bank-info.customize-account-tab.title',
            ),
            repTab: t('pages.settings.bank-info.customize-rep-tab.title'),
            secure: t('labels.secure'),
        }),
        [t],
    );
    const { user, donee, campuses } = useSelector((state: AppState) => ({
        user: state.User.user,
        donee: state.Donee.donee,
        campuses: state.Donee.campuses,
    }));
    const editorRef = React.useRef<PrimaryRepEditorRef>(null);
    const { PATH } = useGasRouterContext();
    const isAdmin = user.role === RoleTypes.ADMIN;
    const isHQ = donee.id === campuses[0]?.id && !donee.parentCampus;
    const showPrimaryRepTab = isAdmin && isHQ;
    const handleChange = (newValue: number) => {
        if (
            newValue === 0 &&
            editorRef.current &&
            editorRef.current.isEditing()
        ) {
            editorRef.current.showNotification(
                PATH.SETTINGS.BANK_INFO(TAB_PATH.account),
            );
            return;
        }
        const newTab = newValue === 1 ? TAB_PATH.rep : TAB_PATH.account;
        if (newTab !== tab.tab) {
            setTab({ tab: newTab });
        }
    };
    return (
        <BasicPageWrapper
            showSecure
            description={copy.description}
            tabProps={{
                onChange: handleChange,
                options: [
                    {
                        label: copy.accountTab,
                        tabHref: PATH.SETTINGS.BANK_INFO(TAB_PATH.account),
                    },
                    ...(showPrimaryRepTab
                        ? [
                              {
                                  label: copy.repTab,
                                  tabHref: PATH.SETTINGS.BANK_INFO(
                                      TAB_PATH.rep,
                                  ),
                              },
                          ]
                        : []),
                ],
                value: tab.tab === TAB_PATH.account ? 0 : 1,
            }}
            title={tab.tab === TAB_PATH.account ? copy.accountTab : copy.repTab}
        >
            {tab.tab === TAB_PATH.account && <CustomizeAccountTab />}
            {tab.tab === TAB_PATH.rep && showPrimaryRepTab && (
                <PrimaryRepresentativeTab editorRef={editorRef} />
            )}
        </BasicPageWrapper>
    );
};
