import React, { useRef, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { FileType, initialFileType } from '../../../utils/fileState';
import FileUploadState from './FileUploadState';
import { UploadDocument } from './UploadDocument';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chequeSectionStyles: {
            border: '1px solid #F3CE6D',
            borderRadius: 4,
            padding: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
    }),
);

export interface DocumentUploadSectionProps {
    title: JSX.Element;
    description: JSX.Element;
    show: boolean;
    onChange: (image: File | undefined) => unknown;
}

export const DocumentUploadSection: React.FCC<DocumentUploadSectionProps> = (
    props,
) => {
    const { show, onChange, title, description } = props;
    const { chequeSectionStyles } = useStyles();
    const docRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<FileType>(initialFileType);
    if (!show) return null;
    const handleFile = (file: FileType) => {
        setFile(file);
        if (file.status === 'success') {
            onChange(file.file);
        }
    };
    const onRemove = () => {
        setFile(initialFileType);
        onChange(undefined);
        if (docRef.current) {
            docRef.current.value = '';
        }
    };
    return (
        <div className={chequeSectionStyles}>
            {title}
            {description}
            <UploadDocument
                docInputRef={docRef}
                onChange={handleFile}
                status={file.status}
            />
            <FileUploadState file={file} onRemove={onRemove} />
        </div>
    );
};
