import { styled } from '@mui/material';
import { DesignTokens } from '..';

export type GivelifyBoxMarginProps = {
    scale?: number;
    marginLeft?: number | 'auto';
    marginRight?: number | 'auto';
    marginBottom?: number | 'auto';
    marginTop?: number | 'auto';
    margin?: number | 'auto';
};

export const MobilePreviewContent = styled('div')({
    height: 667,
    backgroundColor: DesignTokens.color.backgroundPrimary,
    overflow: 'hidden',
    borderRadius: 50,
    position: 'relative',
});
export const MobilePreviewBase = styled('div')<GivelifyBoxMarginProps>(
    ({
        theme,
        marginLeft,
        marginTop,
        marginRight,
        marginBottom,
        margin,
        scale,
    }) => ({
        marginLeft: marginLeft ? marginLeft : margin,
        marginTop: marginTop ? marginTop : margin,
        marginRight: marginRight ? marginRight : margin,
        marginBottom: marginBottom ? marginBottom : margin,
        boxSizing: 'border-box',
        width: 407,
        height: 699,
        overflow: 'hidden',
        backgroundColor: 'rgba(228, 227, 232, 0.5)',
        position: 'relative',
        display: 'inline-block',
        textAlign: 'left',
        borderRadius: 50,
        boxShadow: DesignTokens.shadow.shadowHigh,
        paddingTop: 14.47,
        paddingBottom: 17.53,
        paddingLeft: 16,
        paddingRight: 16,
        transform: scale ? `scale(${scale})` : 'initial',
        zIndex: 1,
        [theme.breakpoints.down('smallTablet')]: {
            width: 375,
            height: 667,
        },
    }),
);
