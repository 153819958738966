import React, { useCallback } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTrackingContext } from '@givelify/utils';
import { Link, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DONOR_PROFILE_PAGE_CLICK_BACK } from 'utils/clevertapEvents';

const useStyle = makeStyles(() => ({
    link: {
        cursor: 'pointer',
        fontSize: '16px',
    },
}));

interface BackLinkProps {
    className?: string;
    id?: string;
}

export const BackLink: React.FCC<BackLinkProps> = ({ className, id }) => {
    const { trackEvent } = useTrackingContext();
    const navigate = useNavigate();
    const classes = useStyle({});

    const goBack = useCallback(() => {
        trackEvent(DONOR_PROFILE_PAGE_CLICK_BACK);
        navigate(-1);
    }, [navigate, trackEvent]);

    const { t } = useTranslation();
    const copy = {
        text: t('labels.back'),
    };

    return (
        <Link
            className={`${classes.link} ${className}`}
            id={id}
            onClick={goBack}
        >
            <FontAwesomeIcon icon={faArrowLeft} /> {copy.text}
        </Link>
    );
};
