import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyMenuStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { DropDownOptions } from './DropDownOptions';
import { GivelifyIcon } from '../components/GivelifyIcon';
import { debounce, Popover } from '@material-ui/core';
import { mergeRefs } from '../utils/mergeRefs';

export interface OptionItem {
    value: string;
    label: string;
    description?: string;
    hideInSelect?: boolean;
}

export interface GivelifyMenuProps extends GivelifyBoxMarginProps {
    id: string;
    value?: string[];
    onChange?: (values: string[], items: OptionItem[]) => void;
    optionsHeader?: string;
    placeholder?: string;
    state?: 'normal' | 'error' | 'warning' | 'success';
    options: OptionItem[];
    classes?: {
        root?: string;
        select?: string;
        optionsRoot?: string;
        controlsRoot?: string;
        arrow?: string;
        checkRoot?: string;
        checkIcon?: string;
        option?: {
            root?: string;
            checkRoot?: string;
            checkIcon?: string;
            contentRoot?: string;
            activeOption?: string;
            label?: string;
            description?: string;
            header?: string;
            footer?: string;
        };
    };
    checkMarkVariant?: 'mark' | 'radio' | 'checkbox';
    hideCheckMark?: boolean;
    className?: string;
    width?: number | 'auto' | '100%';
    optionsWidth?: number | 'auto' | '100%';
    selectionMode?: 'single' | 'multi';
    onHeaderRender?: () => React.ReactNode;
    onFooterRender?: () => React.ReactNode;
    disableAutoScroll?: boolean;
    renderMenuLabel?: (values: string[] | undefined) => string;
    open?: boolean;
    onOpenToggle?: (
        open: boolean,
        reason: 'close' | 'blur' | 'select' | 'open',
    ) => void;
    menuRootRef?: React.Ref<HTMLDivElement>;
    menuButtonRef?: React.Ref<HTMLButtonElement>;
    menuHeight?: 'auto' | number;
    size?: 'normal' | 'small';
}

export const GivelifyMenu: React.FCC<GivelifyMenuProps> = (props) => {
    const {
        id,
        options,
        optionsHeader,
        value,
        placeholder,
        margin,
        marginLeft,
        marginBottom,
        marginRight,
        marginTop,
        width = 'auto',
        className,
        classes,
        state = 'normal',
        hideCheckMark,
        checkMarkVariant = 'mark',
        selectionMode = 'single',
        onChange,
        onHeaderRender,
        onFooterRender,
        disableAutoScroll,
        renderMenuLabel,
        open: passedOpen,
        onOpenToggle,
        menuRootRef,
        menuButtonRef,
        menuHeight,
        optionsWidth = width,
        size = 'normal',
    } = props;
    const innerRef = React.useRef<HTMLButtonElement>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [valueSelectionMap, setValueSelectionMap] = React.useState<{
        [id: string]: boolean;
    }>({});
    const [activeOptionIndex, setActiveOptionIndex] = React.useState(-1);
    const [open, setOpen] = React.useState(false);
    const [optionsToRender, setOptionsToRender] = React.useState<
        {
            value: string;
            label: string;
            selected: boolean;
            active: boolean;
            description?: string;
        }[]
    >([]);
    const [menuWidth, setMenuWidth] = React.useState(optionsWidth);
    const {
        boxMargin,
        mnRoot,
        mnSelect,
        mnDown,
        mnUp,
        mnSelectError,
        mnSelectFocused,
        mnSelectSuccess,
        mnSelectWarning,
    } = GivelifyMenuStyles({
        margin,
        marginLeft,
        marginBottom,
        marginRight,
        marginTop,
        width,
        size,
    });
    const closeWithDebounce = React.useCallback(() => {
        const call = debounce(() => {
            if (onOpenToggle) {
                onOpenToggle(false, 'select');
            } else {
                setOpen(false);
            }
        }, 80);
        call();
    }, [setOpen, onOpenToggle]);

    const rootClassName = mergeClassNames(
        boxMargin,
        mnRoot,
        classes?.root ? classes.root : '',
        className,
    );

    const selectClassName = mergeClassNames(
        mnSelect,
        state === 'error' && mnSelectError,
        state === 'warning' && mnSelectWarning,
        state === 'success' && mnSelectSuccess,
        open && state === 'normal' && mnSelectFocused,
        classes?.select ? classes.select : '',
    );

    const arrowClassName = mergeClassNames(
        mnDown,
        open && mnUp,
        classes?.arrow ? classes.arrow : '',
    );

    React.useEffect(() => {
        if (options) {
            const map: { [id: string]: boolean } = {};
            for (let index = 0; index < options.length; index++) {
                map[options[index].value] = false;
            }
            setValueSelectionMap(map);
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (value !== undefined) {
            const labels: string[] = [];
            const newVals: {
                [id: string]: boolean;
            } = {};
            for (let index = 0; index < options.length; index++) {
                const option = options[index];
                if (value.includes(option.value)) {
                    newVals[option.value] = true;
                    if (!option.hideInSelect) labels.push(option.label);
                } else {
                    newVals[option.value] = false;
                }
            }
            const newLabel = labels.join(', ');
            setValueSelectionMap(newVals);
            setInputValue(newLabel);
        }
        // eslint-disable-next-line
    }, [value]);

    React.useEffect(() => {
        const opts = options.map((item, index) => ({
            value: item.value,
            label: item.label,
            selected: valueSelectionMap[item.value],
            active: activeOptionIndex === index,
            description: item.description,
        }));
        setOptionsToRender(opts);
    }, [
        options,
        valueSelectionMap,
        setOptionsToRender,
        setActiveOptionIndex,
        activeOptionIndex,
    ]);
    React.useEffect(() => {
        if (!open && activeOptionIndex >= 0) {
            setActiveOptionIndex(-1);
        }
    }, [open, activeOptionIndex, setActiveOptionIndex]);
    React.useEffect(() => {
        if (passedOpen != undefined) {
            setOpen(passedOpen);
        }
    }, [passedOpen, setOpen]);
    const onItemSelect = React.useCallback(
        (value: string) => {
            const selected = options.find((x) => x.value === value);
            if (selected) {
                if (onChange) {
                    if (selectionMode === 'single') {
                        onChange([selected.value], [{ ...selected }]);
                        closeWithDebounce();
                        return;
                    } else if (selectionMode === 'multi') {
                        const selectedVals: string[] = [];
                        const selectedItems: OptionItem[] = [];
                        for (let index = 0; index < options.length; index++) {
                            const option = options[index];
                            if (
                                valueSelectionMap[option.value] &&
                                selected.value !== option.value
                            ) {
                                selectedVals.push(option.value);
                                selectedItems.push({ ...option });
                            } else if (
                                !valueSelectionMap[option.value] &&
                                selected.value === option.value
                            ) {
                                selectedVals.push(option.value);
                                selectedItems.push({ ...option });
                            }
                        }
                        onChange(selectedVals, selectedItems);
                    }
                } else {
                    if (selectionMode === 'single') {
                        const newVals = { ...valueSelectionMap };
                        newVals[selected.value] = !newVals[selected.value];
                        for (let index = 0; index < options.length; index++) {
                            const option = options[index];
                            if (option.value === selected.value) {
                                newVals[option.value] = true;
                            } else {
                                newVals[option.value] = false;
                            }
                        }
                        const newLabel = selected.label;
                        setValueSelectionMap(newVals);
                        setInputValue(newLabel);
                        closeWithDebounce();
                        return;
                    } else if (selectionMode === 'multi') {
                        const newVals = { ...valueSelectionMap };
                        newVals[selected.value] = !newVals[selected.value];
                        const labels: string[] = [];
                        for (let index = 0; index < options.length; index++) {
                            const option = options[index];
                            if (newVals[option.value]) {
                                if (!option.hideInSelect)
                                    labels.push(option.label);
                            }
                        }
                        const newLabel = labels.join(', ');
                        setValueSelectionMap(newVals);
                        setInputValue(newLabel);
                    }
                }
            }
        },
        [
            setValueSelectionMap,
            setInputValue,
            valueSelectionMap,
            onChange,
            options,
            selectionMode,
            closeWithDebounce,
        ],
    );
    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === 'ArrowUp') {
                let nextActive = activeOptionIndex;
                if (nextActive < 0) {
                    nextActive = 0;

                    for (let index = options.length - 1; index >= 0; index--) {
                        const option = options[index];
                        if (valueSelectionMap[option.value]) {
                            nextActive = index;
                            break;
                        }
                    }
                }
                nextActive =
                    (nextActive - 1 + optionsToRender.length) %
                    optionsToRender.length;
                setActiveOptionIndex(nextActive);
            } else if (event.key === 'ArrowDown') {
                let nextActive = activeOptionIndex;
                if (nextActive < 0) {
                    nextActive = -1;
                    for (let index = options.length - 1; index >= 0; index--) {
                        const option = options[index];
                        if (valueSelectionMap[option.value]) {
                            nextActive = index;
                            break;
                        }
                    }
                }
                nextActive = (nextActive + 1) % optionsToRender.length;
                setActiveOptionIndex(nextActive);
            } else if (event.key === 'Enter' || event.key === ' ') {
                if (activeOptionIndex < 0) return;
                const selectedItem = optionsToRender[activeOptionIndex];

                onItemSelect(selectedItem.value);
                event.preventDefault();
            }
        },
        [
            activeOptionIndex,
            optionsToRender,
            options,
            valueSelectionMap,
            onItemSelect,
            setActiveOptionIndex,
        ],
    );
    const toggleOpen = React.useCallback(() => {
        if (onOpenToggle) {
            onOpenToggle(!open, open ? 'close' : 'open');
        } else {
            setOpen(!open);
        }
    }, [open, setOpen, onOpenToggle]);
    const onMenuClose = React.useCallback(() => {
        if (onOpenToggle) {
            onOpenToggle(false, 'blur');
        } else {
            setOpen(false);
        }
    }, [setOpen, onOpenToggle]);
    const renderLabel = React.useCallback(() => {
        if (inputValue) {
            if (renderMenuLabel) {
                if (onChange) {
                    return renderMenuLabel(value);
                } else {
                    const selectedVals: string[] = [];
                    for (let index = 0; index < options.length; index++) {
                        const option = options[index];
                        if (valueSelectionMap[option.value]) {
                            selectedVals.push(option.value);
                        }
                    }
                    return renderMenuLabel(selectedVals);
                }
            }
            return inputValue;
        }
        return placeholder;
    }, [
        placeholder,
        inputValue,
        value,
        options,
        valueSelectionMap,
        onChange,
        renderMenuLabel,
    ]);
    React.useEffect(() => {
        if (optionsWidth == width && innerRef && innerRef.current) {
            if (width === '100%') {
                const rect = innerRef.current.getBoundingClientRect();
                setMenuWidth(rect.width);
            } else if (width !== 'auto') {
                setMenuWidth(width);
            }
        }
    }, [innerRef, width, optionsWidth, setMenuWidth]);
    return (
        <div id={`${id}-select`} className={rootClassName} ref={menuRootRef}>
            <button
                id={`${id}-gvlmn`}
                className={selectClassName}
                onClick={toggleOpen}
                onKeyDown={onKeyDown}
                data-testid={`${id}-gvlmn`}
                ref={
                    menuButtonRef
                        ? mergeRefs([menuButtonRef, innerRef])
                        : innerRef
                }
            >
                <span data-testid={`${id}-gvlmn-text`}>{renderLabel()}</span>
                <GivelifyIcon
                    variant="chevron-down"
                    className={arrowClassName}
                />
            </button>
            <Popover
                open={open}
                anchorEl={innerRef.current}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                onClose={onMenuClose}
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
            >
                <DropDownOptions
                    id={`${id}-gvlmn-options`}
                    options={optionsToRender}
                    onSelect={onItemSelect}
                    header={optionsHeader}
                    className={props.classes ? props.classes.optionsRoot : ''}
                    checkMarkVariant={checkMarkVariant}
                    hideCheckMark={hideCheckMark}
                    optionClasses={classes?.option}
                    onHeaderRender={onHeaderRender}
                    onFooterRender={onFooterRender}
                    disableAutoScroll={disableAutoScroll}
                    menuHeight={menuHeight}
                    width={menuWidth}
                />
            </Popover>
        </div>
    );
};
