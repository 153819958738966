import React from 'react';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { useSelector } from 'react-redux';
import roleTypes from '../../../../constants/roleTypes';
import { AppState } from '../../../../store';
import { IntegrationTypes } from '../../types';
import { matchSelect } from '../../utils/integrationFormatFilterUtils';
import AutoIntegrationDonorTabContent from './AutoIntegrationContent/AutoIntegrationDonorTabContent';
import AutoIntegrationEnvelopeTabContent from './AutoIntegrationContent/AutoIntegrationEnvelopeTabContent';
import AutoIntegrationExportedContent from './AutoIntegrationContent/AutoIntegrationExportedContent';
import AutoIntegrationHomeTabContent from './AutoIntegrationContent/AutoIntegrationHomeTabContent';
import AutoIntegrationSettingTabContent from './AutoIntegrationContent/AutoIntegrationSettingTabContent';

interface AutoIntegrationTabsContentProps {
    currentTab: number;
    integration: IntegrationTypes;
    goToFirstTab: () => void;
    goToTab: (tab: number) => void;
    defaultMatchStatus: matchSelect;
    integrationSync: string;
    closeSync: boolean;
    handleCloseSync: () => void;
}

const AutoIntegrationTabsContent: React.FCC<
    AutoIntegrationTabsContentProps
> = ({
    currentTab,
    goToFirstTab,
    integration,
    goToTab,
    defaultMatchStatus,
    integrationSync,
    closeSync,
    handleCloseSync,
}) => {
    const { userRole } = useSelector((state: AppState) => ({
        userRole: state.User.user.role,
    }));
    const isReadOnly =
        userRole !== roleTypes.ADMIN && userRole !== roleTypes.FINANCIAL;
    return (
        <>
            {currentTab === 0 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsHomeTab}
                >
                    <AutoIntegrationHomeTabContent
                        closeSync={closeSync}
                        goToTab={goToTab}
                        handleCloseSync={handleCloseSync}
                        integrationSync={integrationSync}
                    />
                </TrackingProvider>
            )}
            {currentTab === 1 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsDonorsTab}
                >
                    <AutoIntegrationDonorTabContent
                        defaultMatchStatus={defaultMatchStatus}
                        integration={integration}
                        isReadOnly={isReadOnly}
                    />
                </TrackingProvider>
            )}
            {currentTab === 2 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsEnvelopesTab}
                >
                    <AutoIntegrationEnvelopeTabContent
                        defaultMatchStatus={defaultMatchStatus}
                        integration={integration}
                        isReadOnly={isReadOnly}
                    />
                </TrackingProvider>
            )}
            {currentTab === 3 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsExportedTab}
                >
                    <AutoIntegrationExportedContent integration={integration} />
                </TrackingProvider>
            )}
            {currentTab === 4 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsSettingsTab}
                >
                    <AutoIntegrationSettingTabContent
                        integration={integration}
                        savedSetting={goToFirstTab}
                    />
                </TrackingProvider>
            )}
        </>
    );
};

export default AutoIntegrationTabsContent;
