import React from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import errorMark from '../assets/error-mark-circle-blue.svg';
import { I18N_NAMESPACE } from '../consts';

interface YodleeErrorContentProps {
    onManualEntry: () => unknown;
    onRefresh: () => unknown;
}

const useStyles = makeStyles(() =>
    createStyles({
        errorContent: {
            textAlign: 'center',
            '& .icon': {
                width: 80,
                height: 80,
            },
            '& .footer': {
                marginTop: 40,
                marginBottom: 52,
                display: 'flex',
            },
        },
    }),
);

const YodleeErrorContent: React.FCC<YodleeErrorContentProps> = (props) => {
    const { onManualEntry, onRefresh } = props;
    const { errorContent } = useStyles();
    const { t } = useTranslation(I18N_NAMESPACE);
    const { heading, description, description2, refresh, manualEntry } =
        React.useMemo(
            () => ({
                heading: t('directDeposit.error.heading'),
                description: t('directDeposit.error.description'),
                description2: t('directDeposit.error.description2'),
                refresh: t('directDeposit.error.refresh'),
                manualEntry: t('directDeposit.error.manual-entry'),
            }),
            [t],
        );
    return (
        <div className={errorContent}>
            <img alt="Error mark" className="icon" src={errorMark} />
            <GivelifyLabel
                bold
                marginBottom={24}
                marginTop={32}
                text={heading}
                variant="heading2"
            />
            <GivelifyLabel text={description} />
            <GivelifyLabel text={description2} />
            <div className="footer">
                <GivelifyButton
                    marginLeft="auto"
                    marginRight={8}
                    onClick={onRefresh}
                    size="large"
                    text={refresh}
                />
                <GivelifyButton
                    marginLeft={8}
                    marginRight="auto"
                    onClick={onManualEntry}
                    size="large"
                    text={manualEntry}
                    variant="primary"
                />
            </div>
        </div>
    );
};

export const YodleeSavingAccountError: React.FCC<{
    onClick: () => unknown;
}> = ({ onClick }) => {
    const { errorContent } = useStyles();
    const { t } = useTranslation(I18N_NAMESPACE);
    const { heading, description, refresh } = React.useMemo(
        () => ({
            heading: t('directDeposit.yodlee.savingAccountError.heading'),
            description: t(
                'directDeposit.yodlee.savingAccountError.description',
            ),
            refresh: t('directDeposit.yodlee.savingAccountError.tryAgain'),
        }),
        [t],
    );
    return (
        <div className={errorContent}>
            <img alt="Error mark" className="icon" src={errorMark} />
            <GivelifyLabel
                bold
                marginBottom={24}
                marginLeft={16}
                marginRight={16}
                marginTop={32}
                text={heading}
                variant="heading2"
            />
            <GivelifyLabel
                marginLeft={16}
                marginRight={16}
                text={description}
            />
            <div className="footer">
                <GivelifyButton
                    marginLeft="auto"
                    marginRight="auto"
                    onClick={onClick}
                    size="large"
                    text={refresh}
                />
            </div>
        </div>
    );
};

export default YodleeErrorContent;
