import React, { useMemo, useState, useEffect } from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { useInvokeApi } from '@givelify/utils';
import { Box, makeStyles, Theme } from '@material-ui/core';
import DefaultLoading from 'pages/overview/components/DefaultLoading';
import { useTranslation } from 'react-i18next';
import { mapDonationsResponseDataToDonation } from 'types/donorTypes';
import { PaginatedResponse } from 'types/paginationTypes';
import { DonationResponseData } from '..';
import PaginatedMemos from './PaginatedMemos';

interface DonorMemoHistoryProps {
    doneeId: number;
    donorId: number;
}

const Loading: JSX.Element = (
    <FacebookLoading
        borderRadius={0}
        height="50vh"
        marginTop={4}
        width="100%"
    />
);

const useStyle = makeStyles((theme: Theme) => {
    const spacing4 = theme.spacing(4);
    return {
        heading: {
            fontSize: '16px',
            width: '100%',
            borderBottom: '1px solid #D8D8D8',
            paddingBottom: spacing4,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        wrapper: {
            padding: theme.spacing(3, 5),
            width: '100%',
            flex: 1,
        },
    };
});

const baseTranslation = 'pages.donor_details';
const DonorMemoHistory: React.FCC<DonorMemoHistoryProps> = ({
    doneeId,
    donorId,
}) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const pageChange = (e: unknown, pageNumber: number) => {
        setPageNumber(pageNumber + 1);
    };
    const [requestState, makeRequest] =
        useInvokeApi<PaginatedResponse<DonationResponseData>>();

    useEffect(() => {
        if (requestState.type !== 'REQUEST_START') {
            makeRequest(
                'GET',
                `/donees/${doneeId}/donors/${donorId}/messages?page=${pageNumber}`,
            );
        }
        // eslint-disable-next-line
    }, [pageNumber, doneeId, donorId]);

    const copy = useMemo(
        () => ({
            noMessagesToShow: t(`${baseTranslation}.no_memo_to_show`),
            memoHeading: t(`${baseTranslation}.memo_heading`),
        }),
        [t],
    );

    return (
        <Box className={classes.wrapper}>
            <h1 className={classes.heading}>{copy.memoHeading}</h1>
            <DefaultLoading
                error={0}
                loading={Loading}
                requestState={requestState}
            >
                {(response: PaginatedResponse<DonationResponseData> | 0) => {
                    if (response === 0) {
                        return <div>{t('error.status.500')}</div>;
                    }
                    const donationsResponse = response.data;
                    if (!donationsResponse.length) {
                        return (
                            <Box marginY="1rem" textAlign="center">
                                {copy.noMessagesToShow}
                            </Box>
                        );
                    }
                    const pagination = response.pagination;
                    const donations = donationsResponse.map((d) =>
                        mapDonationsResponseDataToDonation(d),
                    );
                    return (
                        <PaginatedMemos
                            donations={donations}
                            pageChange={pageChange}
                            pagination={pagination}
                        />
                    );
                }}
            </DefaultLoading>
        </Box>
    );
};
export default DonorMemoHistory;
