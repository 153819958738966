import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyLabel, DesignTokens } from '@givelify/ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle';
import {
    SettingsRightColumn,
    SettingsSecondTitle,
    settingsStyle,
    SettingsTitle,
    SettingsTitleRow,
    TitleLabelWrapper,
    TitleRowWrapper,
} from './style';

export interface SettingsPageProps {
    title: string;
    titleId?: string;
    titleSecondRow?: string;
    description?: string;
    useDivider?: boolean;
    pageName: PAGE_NAME;
    trackPageVisit: boolean;
    fullWidth?: boolean;
    safeAndSecure?: boolean;
    rightColumnComponent?: React.ReactNode;
}

const SettingsPage: React.FCC<SettingsPageProps> = (props) => {
    const { divider } = settingsStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            secure: t('labels.secure'),
        }),
        [t],
    );
    return (
        <TrackingProvider
            pageName={props.pageName}
            trackPageVisit={props.trackPageVisit}
        >
            <PageTitle description={props.description} title={props.title} />
            <SettingsTitleRow>
                <TitleRowWrapper>
                    <TitleLabelWrapper>
                        <SettingsTitle
                            data-testid={props.titleId}
                            id={props.titleId}
                            text={props.title}
                            variant="heading2"
                        />
                        {props.safeAndSecure ? (
                            <>
                                <GivelifyIcon
                                    style={{
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginRight: '8px',
                                    }}
                                    variant="lock"
                                />
                                <GivelifyLabel
                                    color="neutralGrey"
                                    data-testid="safe-and-secure"
                                    text={copy.secure}
                                    variant="body2"
                                />
                            </>
                        ) : null}
                    </TitleLabelWrapper>
                </TitleRowWrapper>
                {props.rightColumnComponent ? (
                    <SettingsRightColumn>
                        {props.rightColumnComponent}
                    </SettingsRightColumn>
                ) : null}

                {props.titleSecondRow && (
                    <SettingsSecondTitle
                        color={DesignTokens.color.textSecondary}
                        marginTop={1}
                        text={props.titleSecondRow}
                        variant="heading3S"
                    />
                )}

                {props.useDivider && (
                    <div
                        className={
                            props.pageName === PAGE_NAME.SettingsEnvelopes
                                ? divider
                                : ''
                        }
                        style={{
                            boxShadow: 'inset 0px -1px 0px #C2C0CD',
                            width: '100%',
                            height: 1,
                            marginTop: 16,
                            order: 2,
                        }}
                    />
                )}
            </SettingsTitleRow>
            <div>{props.children}</div>
        </TrackingProvider>
    );
};

export default SettingsPage;
