import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyModal,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { formatWithLocaltime } from '@givelify/utils';
import EnvelopeMatching from '../../../../EnvelopeMatching';
import { useModalTableStyles } from './MatchingDonorModal';

interface MatchingEnvelopeModalProps {
    handleClose: () => void;
    reportId: number;
    startDate: string;
    endDate: string;
    open: boolean;
}

const MatchingEnvelopeModal: React.FCC<MatchingEnvelopeModalProps> = ({
    handleClose,
    reportId,
    startDate,
    endDate,
    open,
}) => {
    const { modalContent, forReport, forReportHead } = useModalTableStyles();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            forReport: t('pages.integrations.forReport'),
            matchTitle: t('pages.integration_envelope_matching.title'),
        }),
        [t],
    );

    return (
        <GivelifyModal
            autoFullscreen
            showCloseButton
            onClose={handleClose}
            open={open}
            size="xlarge"
        >
            <div className={modalContent}>
                <div className="header">
                    <GivelifyLabel
                        bold
                        className={forReportHead}
                        text={copy.matchTitle}
                        variant="heading2"
                    />
                    <div className={forReport}>
                        <GivelifyLabel
                            bold
                            text={copy.forReport}
                            variant="body2"
                        />
                        <GivelifyLabel
                            text={`${formatWithLocaltime(
                                startDate,
                                'MM/DD/YYYY',
                            )} - ${formatWithLocaltime(endDate, 'MM/DD/YYYY')}`}
                            variant="body2"
                        />
                    </div>
                </div>
                <div className="body">
                    <EnvelopeMatching
                        handleClose={handleClose}
                        reportId={reportId}
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default MatchingEnvelopeModal;
