import React, { useState } from 'react';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyNotification } from '@givelify/ui';
import { Grid } from '@material-ui/core';
import F1LoginForm from '../../IntegrationSetup/IntegrationSetupDialog/F1LoginForm';
import { useF1Styles } from '../../IntegrationSetup/IntegrationSetupDialog/styles';
import F1FindKeyStep from './F1FindKeyStep';

interface F1LoginContentProps {
    doneCallBack: () => void;
}

const F1LoginContent: React.FCC<F1LoginContentProps> = ({ doneCallBack }) => {
    const classes = useF1Styles();
    const { t: trans } = useAdvancedTranslation();
    const t = (key, d?) =>
        trans(`pages.integrations.content.setup-dialog.${key}`, d || '');
    const copy = {
        connect: t('f1IntegrationSetup.connect'),
        findKey: t('f1IntegrationSetup.findKey'),
        findKeyStep1: t('f1IntegrationSetup.findKeyStep1'),
        findKeyStep2: t('f1IntegrationSetup.findKeyStep2'),
        findKeyStep3: t('f1IntegrationSetup.findKeyStep3'),
    };
    const [errorMessage, setErrorMessage] = useState<string>('');

    return (
        <div className={classes.f1LoginContent}>
            <GivelifyLabel
                className={classes.connect}
                text={copy.connect}
                variant="body1"
            />
            <Grid container>
                <Grid item xs={12}>
                    <GivelifyNotification
                        className={classes.notification}
                        open={!!errorMessage}
                        text={errorMessage}
                        variant="error"
                    />
                </Grid>
                <Grid item className={classes.loginForm} md={6} xs={12}>
                    <F1LoginForm
                        doneCallBack={doneCallBack}
                        setError={setErrorMessage}
                    />
                </Grid>
                <Grid item className={classes.findKey} md={6} xs={12}>
                    <GivelifyLabel
                        bold
                        className={classes.findKeyTitle}
                        text={copy.findKey}
                        variant="heading4"
                    />
                    <F1FindKeyStep
                        stepNumber={1}
                        stepTitle={copy.findKeyStep1}
                    />
                    <F1FindKeyStep
                        stepNumber={2}
                        stepTitle={copy.findKeyStep2}
                    />
                    <F1FindKeyStep
                        stepNumber={3}
                        stepTitle={copy.findKeyStep3}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default F1LoginContent;
