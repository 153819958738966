import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import { OnboardingModal } from '../OnboardingModal';
import TaxIdContext from './TaxIdInfo/TaxIdContext';
import { RequiredTaxIdInfoProps } from './types';

export const RequiredTaxIdInfo: React.FCC<RequiredTaxIdInfoProps> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            title: t('taxId.modalTitle'),
            lockText: t('directDeposit.safeAndSecure'),
            back: t('captivePortal.back'),
            cancel: t('captivePortal.cancel'),
            continue: t('captivePortal.continue'),
        }),
        [t],
    );

    return (
        <OnboardingModal
            iconVariant="organization"
            lockText={copy.lockText}
            title={copy.title}
        >
            <TaxIdContext {...props} />
        </OnboardingModal>
    );
};
