import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const homeTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        homeTabContent: {
            marginTop: theme.spacing(4),
        },
        donorItem: {
            '& > div': {
                marginRight: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                },
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(3),
            },
        },
        envelopeItem: {
            '& > div': {
                marginLeft: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                },
            },
        },
        homeItemContent: {
            height: '100%',
            borderRadius: 10,
            background: theme.palette.background.paper,
            boxShadow: '0 0 9px rgba(131, 131, 131, 0.4)',
            padding: theme.spacing(5),
            textAlign: 'center',
        },
        homeItemTitle: {
            lineHeight: '22px',
            fontWeight: 700,
        },
        unMatchContent: {
            marginTop: 56,
        },
        number: {
            fontSize: 48,
            lineHeight: '38px',
            marginBottom: theme.spacing(3),
        },
        viewUnMatch: {
            marginTop: theme.spacing(5),
        },
        totalMatched: {
            marginTop: theme.spacing(5),
        },
        centerButton: {
            margin: '0 auto',
        },
        matchedNumber: {
            fontWeight: 700,
            background: '#f6f6f6',
            borderRadius: 15,
            padding: theme.spacing(1, 2),
            marginRight: theme.spacing(2),
        },
        noUnMatchContent: {
            marginTop: theme.spacing(5),
        },
        caughtUp: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(5),
        },
        f1Alert: {
            background: '#FCF4E2',
            border: '1px solid #EFB353',
            padding: theme.spacing(4),
            borderRadius: 6,
            marginBottom: theme.spacing(3),
            fontSize: 12,
            lineHeight: '14px',
        },
    });
});

export const donorTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        searchContent: {
            marginLeft: 'auto',
        },
        selector: {
            '& .MuiSelect-icon': {
                marginRight: theme.spacing(3),
                fontSize: '12px',
                top: 'calc(50% - 6px)',
            },
        },
        autocomplete: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            '&:focus-within': {
                boxShadow: '0 0 0',
                border: 0,
                borderRadius: '25px',
            },
            '& .MuiInput-input': {
                border: 'none',
                backgroundColor: theme.palette.background.paper,
            },
        },
        autocompleteInput: {
            '& .MuiInputBase-root': {
                border: '1px solid #D8D8D8',
                borderRadius: '25px',
                paddingRight: '16px !important',
                paddingLeft: theme.spacing(3),
                paddingBottom: 0,
                height: 40,
                '&:focus-within': {
                    boxShadow: '0 0 0',
                },
            },
            '&:focus': {
                border: 'none',
                outline: 0,
                boxShadow: 'none',
            },
            '& .MuiInputBase-input': {
                paddingLeft: '16px !important',
                '&:focus': {
                    outline: 0,
                    border: 'none',
                    boxShadow: '0 0 0',
                },
            },
        },
        autocompletePopper: {
            display: 'none',
        },
        donorTableContent: {
            marginTop: theme.spacing(3),
        },
        matchRowContent: {
            flexWrap: 'nowrap',
            borderBottom: '1px solid #cacaca',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            },
        },
        matchItem: {
            padding: theme.spacing(4, 3),
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid #e3e3e3',
            lineHeight: '16px',
            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #e3e3e3',
            },
        },
        matchColumn: {
            padding: theme.spacing(3, 4),
        },
        matchDonorLogo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #e3e3e3',
            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #e3e3e3',
            },
        },
        matchDonorItem: {
            padding: theme.spacing(3, 3, 2),
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid #e3e3e3',
            lineHeight: '16px',
            '& > div': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #e3e3e3',
            },
        },
        matchDonorF1Item: {
            padding: theme.spacing(2, 3, 3),
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid #e3e3e3',
            lineHeight: '16px',
            '& > div': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #e3e3e3',
            },
        },
        f1Donor: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        givelifyAvatar: {
            width: 40,
        },
        matchDonorButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3),
            },
        },
        f1DonorId: {
            fontSize: 12,
            lineHeight: '16px',
            color: '#676E77',
        },
        donorNameRow: {
            marginLeft: theme.spacing(2),
        },
        donorAddress: {
            whiteSpace: 'unset',
        },
    });
});

export const envelopeTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        envelopeTableContent: {
            marginTop: theme.spacing(3),
        },
        confirmModalContent: {
            textAlign: 'center',
            padding: theme.spacing(0, 7),
        },
        modalTitle: {
            marginBottom: theme.spacing(4),
        },
        confirmModalGroupButton: {
            marginTop: theme.spacing(8),
            '& button': {
                margin: theme.spacing(0, 3),
            },
        },
    });
});

export const settingTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        settingContent: {
            marginTop: theme.spacing(4),
        },
        settingDesc: {
            whiteSpace: 'normal',
        },
        turnSetting: {
            marginBottom: theme.spacing(6),
        },
        credentialContent: {
            marginTop: theme.spacing(6),
        },
        chooseIntegration: {
            color: '#4a4a4a !important',
            marginBottom: theme.spacing(3),
        },
        fromSubmit: {
            '& > *': {
                margin: theme.spacing(3),
            },
        },
        formUpdate: {
            width: '50%',
            minWidth: 512,
        },
        labelUpdate: {},
        formControl2: {
            border: 'none',
            boxShadow: 'none',
            '& > label': {
                background: '#fff',
                padding: '0 5px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        '& .MuiInputBase-input': {
            border: 'none',
        },
        button: {
            margin: theme.spacing(1, 1, 0, 0),
            marginLeft: 'auto',
            backgroundColor: '#715AFF',
            color: '#fff',
            padding: 16,
            '&:hover': {
                backgroundColor: '#20263E',
                color: '#fff',
            },
        },
        errorNotification: {
            marginBottom: theme.spacing(3),
        },
        betaSignUpField: {
            textAlign: 'right',
        },
        field: {
            width: '460px',
        },
        hasVal: {
            '& label': {
                display: 'inline-block',
                position: 'absolute',
                top: -6,
                left: 12,
                paddingLeft: 5,
                paddingRight: 5,
                background: '#fff',
            },
        },
        Button: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    });
});

export const exportTabStyle = makeStyles((theme: Theme) => {
    return createStyles({
        exportTabContent: {
            marginTop: theme.spacing(4),
        },
        searchIcon: {
            fontSize: 14,
        },
        searchExport: {
            marginRight: `-${theme.spacing(3)}px`,
        },
        searchInput: {
            marginRight: theme.spacing(5),
            maxWidth: 340,
            marginLeft: 'auto',
            '& .MuiInput-input': {
                borderRadius: 50,
                height: '32px',
                padding: theme.spacing(1, 3),
                boxSizing: 'border-box',
                minWidth: 340,
                [theme.breakpoints.down('md')]: {
                    minWidth: 0,
                },
            },
        },
        root: {
            width: '100%',
        },
        tableRow: {
            '& .MuiTableCell-root': {
                color: '#373739',
            },
        },
        TableCellExport: {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
        tableCellName: {
            width: 248,
        },
        DonationName: {
            display: 'flex',
            alignItems: 'center',
        },
        IconUser: {
            background: '#bdbdbd',
            color: '#FAFCFC',
            width: 40,
            height: 40,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 8,
            '& .MuiSvgIcon-root': {
                fontSize: 30,
            },
        },
        IdUser: {
            display: 'block',
            height: 40,
        },
        DonationId: {
            color: '#676E77',
            fontSize: 12,
            lineHeight: 14,
        },
        tableCellSubFund: {
            width: 216,
        },
        inputPropsClassName: {
            marginLeft: '100px',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        donationPagination: {
            '& button': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        exportTableWrap: {
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1)',
            background: '#fff',
            marginTop: theme.spacing(2),
        },
        exportedTable: {
            '& > thead': {
                '& tr th': {
                    lineHeight: '16px',
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    fontSize: 14,
                },
            },
            '& > tbody': {
                '& tr:hover': {
                    background: GivelifyColorPalette.neutralHoverGrey,
                },
                '& tr td': {
                    lineHeight: '16px',
                    padding: theme.spacing(3),
                    fontSize: 14,
                    [theme.breakpoints.down('xs')]: {
                        padding: theme.spacing(3),
                    },
                },
            },
        },
        donorPicture: {
            minWidth: 32,
        },
    });
});
