import React, { ReactText, useEffect, useState } from 'react';
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    FilterOptionsState,
} from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import {
    givelifySelectBaseStyle,
    givelifySelectOptionBaseStyle,
    givelifySelectStyle,
} from './styles';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyInput, GivelifyInputProps } from '../input';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import { GivelifyIcon } from '../components/GivelifyIcon';

export interface GivelifySelectProps
    extends Omit<GivelifyInputProps, 'value' | 'onChange' | 'defaultValue'> {
    /**
     * Input value
     */
    value?: ReactText;
    /**
     * Options array.
     */
    options: { key: ReactText; value: string }[];
    /**
     * onChange callback.
     */
    onChange?: (
        value: string | { key: ReactText; value: string } | null,
    ) => void;
    /**
     * If 'true' will display "(key) - value"
     */
    useKeyValueForName?: boolean;
    /**
     * Show loading indicator
     */
    isLoading?: boolean;
    /**
     * Provide custom renderer for options.
     */
    renderOption?: (
        option: { key: ReactText; value: string },
        selected: boolean,
    ) => React.ReactNode;
    /**
     * Pass a ref to the `input` element.
     */
    inputRef?: React.Ref<any>;
    /**
     * Default value
     */
    defaultValue?: string | number;
    /**
     * If it can be a search input with suggestions
     */
    freeSolo?: boolean;
    /**
     * Customized options filter options
     */
    customizedFilterOptions?: (
        options: any[],
        state: FilterOptionsState<any>,
    ) => any[];
}

function getOptionSelected(): (
    option: { key: string | number; value: string },
    value: { key: string | number; value: string },
) => boolean {
    return (option, value) => {
        if (value.key) return option.key === value.key;
        return option.key === (value as unknown);
    };
}

function getOptionLabel(
    props: React.PropsWithChildren<GivelifySelectProps>,
): (option: { key: string | number; value: string }) => string {
    return (option) => {
        if (!option) return '';

        const key = option.key || option;
        const selectedOption = props.options.filter((x) => x.key === key);
        if (!selectedOption || selectedOption.length === 0) return '';
        return props.useKeyValueForName
            ? `(${selectedOption[0].key}) - ${selectedOption[0].value}`
            : selectedOption[0].value;
    };
}

export const GivelifySelect: React.FCC<GivelifySelectProps> = (props) => {
    const {
        boxMargin,
        selectInputHolder,
        selectInputOptionsHolder,
        selectInputOptionItem,
        textfieldEnd,
    } = givelifySelectStyle({
        fullwidth: props.fullWidth,
        width: props.width,
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
    });
    const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false);
    const className = mergeClassNames(boxMargin, selectInputHolder);
    const { defaultValue, customizedFilterOptions, ...inputProps } = props;
    const filterOptions = customizedFilterOptions
        ? customizedFilterOptions
        : createFilterOptions<{ key: ReactText; value: string }>({});

    return (
        <Autocomplete<
            { key: ReactText; value: string },
            boolean,
            boolean,
            boolean
        >
            filterOptions={filterOptions}
            freeSolo={props.freeSolo}
            className={className}
            fullWidth={props.fullWidth}
            id={props.id}
            open={autoCompleteOpen}
            onOpen={() => {
                setAutoCompleteOpen(true);
            }}
            onClose={() => {
                setAutoCompleteOpen(false);
            }}
            getOptionSelected={getOptionSelected()}
            getOptionLabel={getOptionLabel(props)}
            options={props.options}
            classes={{
                paper: selectInputOptionsHolder,
                option: selectInputOptionItem,
                input: textfieldEnd,
            }}
            renderOption={(
                option: { key: string | number; value: string },
                state: { selected: boolean },
            ) =>
                props.renderOption ? (
                    props.renderOption(option, state.selected)
                ) : (
                    <GivelifySelectOptionBase
                        selected={state.selected}
                        option={option}
                        useKeyValueForName={props.useKeyValueForName}
                    />
                )
            }
            renderInput={(params: any) => (
                <GivelifySelectBase
                    {...inputProps}
                    params={params}
                    isLoading={inputProps.isLoading}
                    autoCompleteOpen={autoCompleteOpen}
                    onToggle={() => {
                        setAutoCompleteOpen(!autoCompleteOpen);
                    }}
                />
            )}
            forcePopupIcon={false}
            value={
                props.value
                    ? { key: props.value, value: props.value.toString() }
                    : undefined
            }
            onChange={(_event: React.ChangeEvent<unknown>, value: unknown) => {
                const valueToSet = value as
                    | string
                    | { key: ReactText | number; value: string }
                    | null;
                if (props.onChange) props.onChange(valueToSet);
            }}
            loading={props.isLoading}
            defaultValue={
                props.defaultValue
                    ? { key: props.defaultValue, value: '' }
                    : undefined
            }
        />
    );
};

interface GivelifySelectOptionBaseProps {
    option: { key: string | number; value: string };
    selected: boolean;
    useKeyValueForName?: boolean;
}

const GivelifySelectOptionBase: React.FunctionComponent<
    GivelifySelectOptionBaseProps
> = (props) => {
    const { selectInputOptionItemContainer, selectInputCheck } =
        givelifySelectOptionBaseStyle();
    return (
        <div className={selectInputOptionItemContainer}>
            <p>
                {props.useKeyValueForName
                    ? `(${props.option.key}) - ${props.option.value}`
                    : props.option.value}
            </p>
            <div className={selectInputCheck}>
                {props.selected && (
                    <GivelifyIcon variant="check" color="inherit" />
                )}
            </div>
        </div>
    );
};

interface GivelifySelectBaseProps
    extends Omit<GivelifyInputProps, 'value' | 'onChange'> {
    params: AutocompleteRenderInputParams;
    isLoading?: boolean;
    autoCompleteOpen?: boolean;
    onToggle: () => void;
}

const GivelifySelectBase: React.FCC<GivelifySelectBaseProps> = (
    props: GivelifySelectBaseProps,
) => {
    const { selectInputDropDownIcon, selectInputDropDownRing } =
        givelifySelectBaseStyle();
    return (
        <GivelifyInput
            {...props}
            width={undefined}
            fullWidth={true}
            inputPropsRef={props.params.InputProps.ref}
            endAdornment={
                <div
                    className={selectInputDropDownIcon}
                    onClick={props.onToggle}
                >
                    {props.isLoading && (
                        <CircularProgress
                            className={selectInputDropDownRing}
                            color="inherit"
                            size={20}
                        />
                    )}
                    {props.autoCompleteOpen ? (
                        <GivelifyIcon
                            variant="chevron-up"
                            color="inherit"
                            size="13px"
                        />
                    ) : (
                        <GivelifyIcon
                            variant="chevron-down"
                            color="inherit"
                            size="13px"
                        />
                    )}
                </div>
            }
            inputProps={props.params.inputProps}
        />
    );
};

export type GivelifyFormSelectProps<Form extends FieldValues> =
    GivelifySelectProps & {
        name: keyof Form;
        formRef: UseFormMethods<Form>;
    };

export const GivelifyFormSelect = <Form extends FieldValues>(
    props: GivelifyFormSelectProps<Form>,
) => {
    const { formRef, ...inputProps } = props;

    const { register, errors } = formRef;
    const name = inputProps.name.toString();

    const error = (errors as any)[name];
    const massage = error && error.message ? error.message : '';
    const [value, setValue] = useState('');
    const onChange = (
        value: string | { key: number | string; value: string } | null,
    ) => {
        if (value) {
            if ((value as any).key) {
                if (value) setValue((value as any).key.toString());
            } else {
                setValue(value.toString());
            }
        }
    };
    useEffect(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue.toString());
        }
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <input
                value={value}
                name={name}
                ref={register}
                hidden
                // eslint-disable-next-line
                onChange={() => {}}
            />
            <GivelifySelect
                className={props.className}
                helperText={massage}
                error={!!massage}
                {...inputProps}
                onChange={onChange}
            />
        </>
    );
};
