import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { DesignTokens } from '../specify';

const InfoBox = styled('div')(({ theme }) => ({
    border: `1px solid ${DesignTokens.color.neutralPlatinum}`,
    borderRadius: '8px',
    borderTop: `8px solid ${DesignTokens.color.borderComponentPrimaryAccent}`,
    padding: '24px 14px',
}));

export const GivelifyInfoBox: React.FCC<{ children: ReactNode }> = (props) => {
    return <InfoBox>{props.children}</InfoBox>;
};
