import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { mergeClassNames } from '../utils/classNameUtils';

export interface FacebookLoadingProps {
    width: string | number;
    height: string | number;
    marginTop?: number | string;
    marginBottom?: number | string;
    marginLeft?: number | string;
    marginRight?: number | string;
    borderRadius: number | string;
    style?: CSSProperties;
    className?: string;
}

const styles = ({
    width,
    height,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    borderRadius,
}: FacebookLoadingProps): CSSProperties => ({
    width,
    height,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    borderRadius,
});

const useStyles = makeStyles(() =>
    createStyles({
        '@keyframes placeHolderShimmer': {
            '0%': {
                backgroundPosition: '-468px 0',
            },
            '100%': {
                backgroundPosition: '468px 0',
            },
        },
        animate: {
            animation: `$placeHolderShimmer 1s`,
            animationFillMode: 'forwards',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            background:
                'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
            backgroundSize: '800px 104px',
        },
    }),
);

export const FacebookLoading: React.FCC<FacebookLoadingProps> = React.memo(
    (props) => {
        const { animate } = useStyles(props);
        return (
            <div
                className={mergeClassNames(animate, props.className)}
                style={{ ...props.style, ...styles(props) }}
            />
        );
    },
);
