import React from 'react';
import { makeStyles, RadioGroup as MUIRadioGroup } from '@material-ui/core';
import SelectorItem from './SelectorItem';
import { GivelifyMobileSelectFilterOptionProps } from '../../types';

interface RadioGroupProps {
    options: GivelifyMobileSelectFilterOptionProps[];
    onChange: (selectedItem: GivelifyMobileSelectFilterOptionProps) => void;
    value?: GivelifyMobileSelectFilterOptionProps;
}

const useStyles = makeStyles(() => ({
    radioGroup: {
        width: '100%',
    },
}));

const RadioGroup: React.FCC<RadioGroupProps> = ({
    value,
    options,
    onChange,
}) => {
    const classes = useStyles();

    const onChangeInternal = (
        _: React.ChangeEvent<HTMLInputElement>,
        value: string,
    ) => {
        const selectedOption = options.find((o) => o.value === value);

        if (selectedOption) {
            onChange(selectedOption);
        }
    };

    return (
        <MUIRadioGroup
            value={value?.value}
            onChange={onChangeInternal}
            classes={{
                root: classes.radioGroup,
            }}
        >
            {options.map((option, i) => (
                <SelectorItem
                    key={i}
                    option={option}
                    selected={option.value === value?.value}
                />
            ))}
        </MUIRadioGroup>
    );
};

export default RadioGroup;
