import React, { useMemo } from 'react';
import {
    GivelifyPaper,
    GivelifyLabelStyles,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { mergeClassNames, formatWithTimezone } from '@givelify/utils';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { RefundDetailsProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'grid',
        padding: 16,
        backgroundColor: theme.colors.grey50,
    },
    greyLight: {
        color: theme.colors.navigationTextDefault,
    },
    greyDark: {
        color: theme.colors.primaryDarkGray,
    },
    divider: {
        gridColumn: 'span 3',
        width: '100%',
        margin: 0,
        height: 1,
        backgroundColor: theme.colors.grey200,
    },
    totalDivider: {
        backgroundColor: theme.colors.grey500,
        marginBottom: 6,
    },
    rightAlign: {
        justifySelf: 'end',
    },
    netLabel: {
        gridColumn: 'span 2',
    },
}));

const formatMoney = (money: string | number) =>
    `$${formatMoneyWithCommas(money)}`;

interface RefundDetailsMobileProps extends RefundDetailsProps {
    id: number;
    donorName: string;
    donationDate: string;
}

const RefundDetailsMobile: React.FCC<RefundDetailsMobileProps> = (props) => {
    const classes = useStyles();
    const { small } = GivelifyLabelStyles({});
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'bankDeposits.text',
    });

    const copy = useMemo(
        () => ({
            donationDetails: scopedTranslate('donationDetails'),
            donation: scopedTranslate('donation'),
            fees: scopedTranslate('fees'),
            total: scopedTranslate('total'),
            receipt: scopedTranslate('receipt'),
            envelopeName: scopedTranslate('envelopName'),
            donationDate: scopedTranslate('donationDate'),
            refundAmount: scopedTranslate('refundAmount'),
            donor: scopedTranslate('donor'),
        }),
        [scopedTranslate],
    );

    const envelopes = props.envelopes;

    const formattedDonationDate = formatWithTimezone(
        props.donationDate,
        'MMM D, YYYY',
    );

    return (
        <GivelifyPaper className={classes.wrapper}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}
            >
                <div id={`donor-name-${props.id}`}>
                    <span className={mergeClassNames(small, classes.greyLight)}>
                        {`${copy.donor}:`}
                    </span>{' '}
                    <span className={mergeClassNames(small, classes.greyDark)}>
                        {props.donorName}
                    </span>
                </div>
                <div id={`refund-date-${props.receiptNumber}`}>
                    <span className={mergeClassNames(small, classes.greyLight)}>
                        {`${copy.donationDate}:`}
                    </span>{' '}
                    <span className={mergeClassNames(small, classes.greyDark)}>
                        {formattedDonationDate}
                    </span>
                </div>
                <div id={`receipt-no-${props.receiptNumber}`}>
                    <span className={mergeClassNames(small, classes.greyLight)}>
                        {`${copy.receipt} #`}
                    </span>{' '}
                    <span className={mergeClassNames(small, classes.greyDark)}>
                        {props.receiptNumber}
                    </span>
                </div>
                <div className={classes.divider} />
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto auto',
                        gap: 10,
                        marginTop: 10,
                    }}
                >
                    <GivelifyLabel text={copy.envelopeName} variant="small" />
                    <GivelifyLabel
                        className={classes.rightAlign}
                        text={copy.donation}
                        variant="small"
                    />
                    <GivelifyLabel
                        className={classes.rightAlign}
                        text={copy.fees}
                        variant="small"
                    />

                    {envelopes.map((envelope) => {
                        const feePercent = props.fees / props.total;
                        const donation = formatMoney(envelope.amount || 0);
                        const fees = formatMoney(
                            props.fees ? envelope.amount * feePercent : 0,
                        );

                        return (
                            <React.Fragment key={envelope.id}>
                                <GivelifyLabel
                                    id={`envelope-name-${envelope.id}`}
                                    text={envelope.name}
                                    variant="small"
                                />
                                <GivelifyLabel
                                    className={classes.rightAlign}
                                    color="primaryDarkGray"
                                    id={`donation-${envelope.id}-${props.receiptNumber}`}
                                    text={donation}
                                    variant="small"
                                />
                                <GivelifyLabel
                                    className={classes.rightAlign}
                                    color="primaryDarkGray"
                                    id={`fee-${envelope.id}-${props.receiptNumber}`}
                                    text={`-${fees}`}
                                    variant="small"
                                />
                            </React.Fragment>
                        );
                    })}
                    {envelopes.length > 1 && (
                        <>
                            <div className={classes.divider} />
                            <GivelifyLabel text="Total" variant="small" />
                            <GivelifyLabel
                                className={classes.rightAlign}
                                id={`total-donation-${props.receiptNumber}`}
                                text={formatMoney(props.total || 0)}
                                variant="small"
                            />
                            <GivelifyLabel
                                className={classes.rightAlign}
                                id={`total-fee-${props.receiptNumber}`}
                                text={`-${formatMoney(props.fees || 0)}`}
                                variant="small"
                            />
                        </>
                    )}
                    <div
                        className={mergeClassNames(
                            classes.divider,
                            classes.totalDivider,
                        )}
                    />
                    <GivelifyLabel
                        className={classes.netLabel}
                        color="primaryDarkGray"
                        text={copy.total}
                        variant="small"
                    />
                    <GivelifyLabel
                        className={classes.rightAlign}
                        color="primaryDarkGray"
                        id={`total-net-${props.receiptNumber}`}
                        text={formatMoney(props.total - props.fees)}
                        variant="small"
                    />
                </div>
            </div>
        </GivelifyPaper>
    );
};

export default RefundDetailsMobile;
