import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { GivelifyButton } from '../../button';
import { GivelifyLabel } from '../../label';
import { ErrorPageProps, useErrorPageStyles } from '../utils';
import { serverErrorSvg } from './svgComponent';

const useStyles = makeStyles(() =>
    createStyles({
        '@keyframes blink1': {
            from: {
                visibility: 'hidden',
            },
            '50%': {
                visibility: 'hidden',
            },
            to: {
                visibility: 'visible',
            },
        },
        '@keyframes blink2': {
            from: {
                visibility: 'visible',
            },
            '50%': {
                visibility: 'hidden',
            },
            to: {
                visibility: 'hidden',
            },
        },
        svgStyles: {
            '& .background-gradient': {
                fill: '#e6e6b0',
                animation: '$blink1 1s infinite',
            },
            '& .black-line': {
                animation: '$blink2 1s infinite',
            },
        },
    }),
);

export const ServerErrorPage: React.FCC<ErrorPageProps> = ({
    pageNotFoundText,
    descriptionText,
    overviewButtonText,
    returnButtonText,
    overviewButtonUrl,
    showReturnToPreviousButton,
    marginTopDesktop,
    marginTopMobile,
}) => {
    const { svgStyles } = useStyles();
    const { styles } = useErrorPageStyles({
        showReturnToPreviousButton,
        svgWidth: 335,
        marginTopDesktop,
        marginTopMobile,
    });
    const goBack = () => window.history.go(-1);
    return (
        <div className={styles}>
            <div className={svgStyles}>{serverErrorSvg}</div>
            <h1>{pageNotFoundText || 'Oops, something went wrong.'}</h1>
            <GivelifyLabel
                text={
                    descriptionText ||
                    'Try refreshing the page or come back later.'
                }
                variant="heading2"
                className="label"
            />
            <div className="buttons">
                {overviewButtonUrl && (
                    <GivelifyButton
                        size="xLarge"
                        variant="primary"
                        text={overviewButtonText || 'Go To Overview'}
                        className="main-button"
                        onClick={() =>
                            (window.location.href = overviewButtonUrl)
                        }
                    />
                )}
                {showReturnToPreviousButton && (
                    <GivelifyButton
                        variant="secondary"
                        text={returnButtonText || 'Return To Previous Page'}
                        size="xLarge"
                        className="previous-button"
                        onClick={goBack}
                    />
                )}
            </div>
        </div>
    );
};
