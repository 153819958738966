import { ErrorResponse } from '@givelify/utils';
import { EnvelopeDistribution } from '../types';

export const SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX =
    'SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX';
export const SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX =
    'SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX';
export const SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX =
    'SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX';

export const SET_INITIAL_GIVING_TREND_STATISTICS_STATE =
    'SET_INITIAL_GIVING_TREND_STATISTICS_STATE';

export interface SetInitialGivingTrendStatisticsState {
    type: typeof SET_INITIAL_GIVING_TREND_STATISTICS_STATE;
    count: number;
}

export interface SetGivingTrendEnvelopesStatisticsLoadingAtIndex {
    type: typeof SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX;
    index: number;
}

export interface SetGivingTrendEnvelopesStatisticsSuccessAtIndex {
    type: typeof SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX;
    index: number;
    data: {
        data: EnvelopeDistribution[];
    };
}

export interface SetGivingTrendEnvelopesStatisticsFailedAtIndex {
    type: typeof SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX;
    index: number;
    error: ErrorResponse;
}
