import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { webConfig } from 'webConfig';
import { SETTINGS_ASSETS_PATH } from '../SettingsAssetsPath';
import { DashboardTileProps } from './components';

export const useSettingsDashboard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { PATH } = useGasRouterContext();
    const { isSynagogue, isChurch, enabledFeatures } = useSelector(
        (state: AppState) => ({
            isSynagogue: state.User.user?.isSynagogue,
            enabledFeatures: state.System.enabledFeatures,
            isChurch: state.Donee.donee?.type === 'church',
        }),
    );
    const { t } = useTranslation();
    const navigate = useNavigate();
    const copy = React.useMemo(
        () => ({
            title: t('pages.settings.title'),
            appProfile: t('pages.settings.app-profile.title'),
            appProfileCaption: t('pages.settings.app-profile.caption'),
            bankInfo: t('pages.settings.bank-info.title'),
            bankInfoCaption: t('pages.settings.bank-info.caption'),
            users: t('pages.settings.users.title'),
            usersCaption: t('pages.settings.users.caption'),
            organizationInfo: t('pages.settings.organization-info.title'),
            organizationInfoCaption: t(
                'pages.settings.organization-info.caption',
            ),
            campuses: t('pages.settings.campuses.title'),
            campusesCaption: t('pages.settings.campuses.caption'),
            missionStatement: t('pages.settings.mission-statement.title'),
            missionStatementCaption: t(
                'pages.settings.mission-statement.caption',
            ),
            quickGive: t('pages.settings.quick-give.title'),
            quickGiveCaption: t('pages.settings.quick-give.caption'),
            needHelp: t('pages.settings.need-help.title'),
            needHelpCaption: t('pages.settings.need-help.caption'),
        }),
        [t],
    );
    const onTileClick = React.useCallback(
        (actionUrl: string) => {
            navigate(actionUrl);
        },
        [navigate],
    );
    const features = React.useMemo(
        () => ({
            allSettings: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_ALL,
            ),
            appProfile: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_APP_PROFILE,
            ),
            orgInfo: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_ORGANIZATION_INFO,
            ),
            mission: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_MISSION,
            ),
            bankInfo: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_BANK_INFO,
            ),
            quickGive: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_QUICK_GIVE,
            ),
            users: isFeatureEnabled(enabledFeatures, Features.SETTINGS_USERS),
            campuses: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_CAMPUSES,
            ),
            needHelp: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_NEED_HELP,
            ),
        }),
        [enabledFeatures],
    );

    const dashboardTiles = React.useMemo(() => {
        const result: DashboardTileProps[] = [];
        if (features.appProfile) {
            result.push({
                title: copy.appProfile,
                description: copy.appProfileCaption,
                href: PATH.SETTINGS.APP_PROFILE('profile'),
                onClick: () =>
                    onTileClick(PATH.SETTINGS.APP_PROFILE('profile')),
                iconUrl: SETTINGS_ASSETS_PATH.USER_ICON,
            });
        }
        if (features.bankInfo) {
            result.push({
                title: copy.bankInfo,
                description: copy.bankInfoCaption,
                href: PATH.SETTINGS.BANK_INFO('account'),
                onClick: () => onTileClick(PATH.SETTINGS.BANK_INFO('account')),
                iconUrl: SETTINGS_ASSETS_PATH.BANK_INFO,
            });
        }
        if (features.users) {
            result.push({
                title: copy.users,
                description: copy.usersCaption,
                href: PATH.SETTINGS.USERS(),
                onClick: () => onTileClick(PATH.SETTINGS.USERS()),
                iconUrl: SETTINGS_ASSETS_PATH.USERS_ICON,
            });
        }
        if (features.orgInfo) {
            result.push({
                title: copy.organizationInfo,
                description: copy.organizationInfoCaption,
                href: PATH.SETTINGS.ORG_INFO,
                onClick: () => onTileClick(PATH.SETTINGS.ORG_INFO),
                iconUrl: SETTINGS_ASSETS_PATH.ORGANIZATION_INFO,
            });
        }
        if (features.campuses && isChurch) {
            result.push({
                title: copy.campuses,
                description: copy.campusesCaption,
                href: PATH.SETTINGS.CAMPUSES,
                onClick: () => onTileClick(PATH.SETTINGS.CAMPUSES),
                iconUrl: SETTINGS_ASSETS_PATH.LOCATIONS,
            });
        }
        if (features.mission) {
            result.push({
                title: copy.missionStatement,
                description: copy.missionStatementCaption,
                href: PATH.SETTINGS.MISSION_STATEMENT,
                onClick: () => onTileClick(PATH.SETTINGS.MISSION_STATEMENT),
                iconUrl: SETTINGS_ASSETS_PATH.MISSION_STATEMENT,
            });
        }
        if (features.quickGive && isSynagogue) {
            result.push({
                title: copy.quickGive,
                description: copy.quickGiveCaption,
                href: PATH.SETTINGS.QUICK_GIVE,
                onClick: () => onTileClick(PATH.SETTINGS.QUICK_GIVE),
                iconUrl: SETTINGS_ASSETS_PATH.GIVE_HAND,
            });
        }
        if (features.needHelp) {
            result.push({
                title: copy.needHelp,
                description: copy.needHelpCaption,
                href: webConfig.supportUrl,
                iconUrl: SETTINGS_ASSETS_PATH.QUESTION_MARK,
            });
        }
        return result;
    }, [PATH.SETTINGS, copy, features, isChurch, isSynagogue, onTileClick]);

    return { dashboardTiles, title: copy.title, isMobile } as const;
};
