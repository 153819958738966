import React, { useEffect, useMemo, useState } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    AutocompleteOption,
    GivelifyFullscreenLoading,
    LoaderRequestState,
} from '@givelify/ui';
import { primaryRepresentativeAPI } from '../../../api/requests/api';
import { I18N_NAMESPACE } from '../../../consts';
import CancelModal from '../components/CancelModal';
import OnboardingTopSection from '../OnboardingTopSection';
import Address from './address';
import { PrimaryRepInfo } from './Info';
import { PrimaryRepBreadcrumbs } from './PrimaryRepBreadcrumbs';
import Summary from './summary';
import { PrimaryRepDetailsProps, PrimaryRepStep } from './types';

export const PrimaryRepDetails: React.FCC<PrimaryRepDetailsProps> = (props) => {
    const [data, setData] = useState(props.data);
    const [infoCompleted, setInfoCompleted] = useState<boolean>(false);
    const [addressCompleted, setAddressCompleted] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<PrimaryRepStep>('info');
    const [summaryReached, setSummaryReached] = useState<boolean>(false);
    const [iAmPrimaryRep, setIAmPrimaryRep] = useState<boolean | undefined>();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [titles, setTitles] = useState<AutocompleteOption[]>([]);

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.primaryRepresentative`,
    });

    const copy = useMemo(
        () => ({
            submitNote: scopedTranslate('submitNote'),
            submitSuccess: scopedTranslate('submitSuccess'),
        }),
        [scopedTranslate],
    );

    const [requestState, setRequestState] =
        useState<LoaderRequestState>('REQUEST_INIT');

    const onSubmitClick = async () => {
        setRequestState('REQUEST_START');
        const result = await primaryRepresentativeAPI(props.doneeId, {
            ...data,
            socialSecurityNumber: data.socialSecurityNumber.replace(/-/g, ''),
        });
        setRequestState(result.success ? 'REQUEST_SUCCESS' : 'REQUEST_INIT');
    };

    const onCancelClick = (skipModal?: boolean) => {
        if (skipModal) {
            props.onClose();
        } else {
            setShowCancelModal(true);
        }
    };

    const onCancelSubmit = () => props.onClose();
    const onCancelClose = () => setShowCancelModal(false);

    const navigate = (step: PrimaryRepStep) => {
        setCurrentStep(summaryReached ? 'summary' : step);
    };

    useEffect(() => {
        if (currentStep === 'summary') {
            setSummaryReached(true);
        }
    }, [currentStep]);

    useEffect(() => {
        if (props.getTitlesRequestState.type === 'REQUEST_SUCCESS') {
            setTitles(
                props.getTitlesRequestState.response.data.map((item) => ({
                    value: item.id.toString(),
                    label: item.name,
                })) as AutocompleteOption[],
            );
        }
    }, [props.getTitlesRequestState]);

    return (
        <>
            <OnboardingTopSection>
                <PrimaryRepBreadcrumbs
                    active={currentStep}
                    addressCompleted={addressCompleted}
                    disableNavigation={!summaryReached}
                    infoCompleted={infoCompleted}
                    onClick={(value) => setCurrentStep(value as PrimaryRepStep)}
                    summaryCompleted={summaryReached}
                />
            </OnboardingTopSection>

            {currentStep === 'info' && (
                <PrimaryRepInfo
                    data={data}
                    iAmPrimaryRep={iAmPrimaryRep}
                    onCancelClick={(skipModal) => onCancelClick(skipModal)}
                    onContinueClick={(result) => {
                        setData({
                            ...data,
                            firstName: result.firstName,
                            lastName: result.lastName,
                            birthDate: result.birthDate,
                            title: result.title,
                            socialSecurityNumber: result.socialSecurityNumber,
                            isPrimaryRepresentative: !!iAmPrimaryRep,
                        });
                        navigate('address');
                        setInfoCompleted(true);
                    }}
                    onRadioChange={(value) => setIAmPrimaryRep(value)}
                    titles={titles}
                    user={props.user}
                />
            )}
            {currentStep === 'address' && (
                <Address
                    address={data.primaryRepresentativeAddress}
                    onBackClick={() => setCurrentStep('info')}
                    onCancelClick={() => onCancelClick(false)}
                    onSubmit={(address) => {
                        setData((val) => ({
                            ...val,
                            primaryRepresentativeAddress: address,
                        }));
                        setAddressCompleted(true);
                        setCurrentStep('summary');
                    }}
                />
            )}
            {currentStep === 'summary' && (
                <Summary
                    address={data.primaryRepresentativeAddress}
                    birthDate={data.birthDate}
                    firstName={data.firstName}
                    isLoading={requestState === 'REQUEST_START'}
                    lastName={data.lastName}
                    onBack={() => setCurrentStep('address')}
                    onCancel={() => onCancelClick(false)}
                    onEditClick={setCurrentStep}
                    onSubmit={onSubmitClick}
                    ssn={data.socialSecurityNumber}
                    title={data.title}
                />
            )}

            {showCancelModal && (
                <CancelModal
                    onCancel={onCancelClose}
                    onSubmit={onCancelSubmit}
                    page="primaryRepresentative"
                />
            )}

            <GivelifyFullscreenLoading
                note={copy.submitNote}
                onComplete={() =>
                    props.onSubmit({
                        ...data,
                        status: 'in_progress',
                    })
                }
                requestState={requestState}
                successMessage={copy.submitSuccess}
                successTimeout={3000}
                timeout={4000}
            />
        </>
    );
};
