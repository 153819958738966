import { makeStyles, Theme } from '@material-ui/core';

const getPaperStyles = (theme: Theme) =>
    ({
        overflowX: 'auto',
        borderRadius: 11,
        padding: theme.spacing(4),
    } as const);

export const usePaperStyles = makeStyles((theme: Theme) => ({
    paper: getPaperStyles(theme),
    paperWithoutPadding: { ...getPaperStyles(theme), padding: '0 !important' },
    integrationPagination: {
        '& .MuiIconButton-root': {
            padding: 0,
        },
    },
}));
