import React, { useMemo } from 'react';
import {
    DesignTokens,
    GivelifyAutocomplete,
    GivelifyEmailField,
    GivelifyLabel,
    GivelifyTextField,
} from '@givelify/ui';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    UserInfo,
    UserInfoErrorMessages,
    UserInfoErrors,
} from 'types/userTypes';
import { inviteUserModalStyle } from './inviteUserModalStyle';

interface StepOneProps {
    onUserInfoChange: ({ name, value }) => void;
    userInfo: UserInfo;
    userInfoErrors: UserInfoErrors;
    userInfoErrorMessages?: UserInfoErrorMessages;
    withStep?: boolean;
    isEdit?: boolean;
}

const StepOne: React.FCC<StepOneProps> = (props: StepOneProps) => {
    const { officerTitles } = useSelector((state: AppState) => ({
        officerTitles: state.Settings.Officers.officerTitles,
    }));
    const {
        userInfo,
        onUserInfoChange,
        userInfoErrors,
        userInfoErrorMessages,
        withStep = true,
    } = props;
    const styles = inviteUserModalStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            step1: t('labels.step1'),
            userInformation: t('pages.settings.users.add-new-modal.title'),
            allFieldsrequired: t(
                'pages.settings.users.add-new-modal.form.allRequired',
            ),
            firstName: t('pages.settings.users.add-new-modal.form.firstName'),
            lastName: t('pages.settings.users.add-new-modal.form.lastName'),
            title: t('pages.settings.users.add-new-modal.form.title'),
            email: t('pages.settings.users.add-new-modal.form.email'),
            titleExample: t(
                'pages.settings.users.add-new-modal.form.titleExample',
            ),
            willSendLink: t(
                'pages.settings.users.add-new-modal.form.willSendLink',
            ),
        }),
        [t],
    );

    const options = useMemo(() => {
        return officerTitles.map((title) => ({
            label: title,
            value: title,
        }));
    }, [officerTitles]);

    const handleInputChange = (name, value) => {
        onUserInfoChange({ name, value });
    };

    const onTitleChange = (event, value) => {
        if (typeof value !== 'string') {
            onUserInfoChange({ name: 'title', value: value?.label || '' });
        } else {
            onUserInfoChange({ name: 'title', value: value });
        }
    };

    return (
        <div style={{ maxWidth: 688 }}>
            {withStep && (
                <GivelifyLabel
                    className={styles.stepHeader}
                    color={DesignTokens.color.neutralGrey}
                    marginBottom="32px"
                    text={copy.step1}
                    variant="heading3M"
                />
            )}
            <GivelifyLabel
                className={styles.marginBottom16}
                fontSize={22}
                fontWeight="bold"
                marginBottom="16px"
                text={copy.userInformation}
                variant="heading2S"
            />
            <GivelifyLabel
                className={styles.allFieldText}
                color={DesignTokens.color.neutralGrey}
                marginBottom="8px"
                text={copy.allFieldsrequired}
                variant="caption1"
            />
            <Grid container spacing={3}>
                <Grid item className={styles.firstNameBlock} sm={6} xs={12}>
                    <GivelifyTextField
                        countLength
                        fullWidth
                        id="first-name"
                        label={copy.firstName}
                        maxLength={55}
                        name="firstName"
                        onChange={(event) => {
                            handleInputChange('firstName', event.target.value);
                        }}
                        placeholder={copy.firstName}
                        state={userInfoErrors.firstName ? 'error' : 'idle'}
                        value={userInfo?.firstName || ''}
                    />
                </Grid>
                <Grid item className={styles.lastNameBlock} sm={6} xs={12}>
                    <GivelifyTextField
                        countLength
                        fullWidth
                        id="last-name"
                        label={copy.lastName}
                        maxLength={55}
                        name="lastName"
                        onChange={(event) => {
                            handleInputChange('lastName', event.target.value);
                        }}
                        placeholder={copy.lastName}
                        state={userInfoErrors.lastName ? 'error' : 'idle'}
                        value={userInfo?.lastName || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GivelifyAutocomplete
                        allowCustomInput
                        disableClearable
                        className={styles.titleSelect}
                        clearOnBlur={false}
                        defaultValue={
                            userInfo?.title
                                ? {
                                      value: userInfo?.title,
                                      label: userInfo?.title,
                                  }
                                : null
                        }
                        id="title"
                        label={copy.title}
                        name="title"
                        onChange={onTitleChange}
                        onInputChange={onTitleChange}
                        options={options}
                        placeholder={copy.title}
                        state={userInfoErrors.title ? 'error' : 'idle'}
                    />
                    <div className={styles.titleSelectHelperText}>
                        <GivelifyLabel
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={copy.titleExample}
                        />
                        <GivelifyLabel
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={`${userInfo?.title?.length || 0}/25`}
                        />
                    </div>
                </Grid>
                <Grid item className={styles.emailBox} xs={12}>
                    <GivelifyEmailField
                        fullWidth
                        id="email"
                        label={copy.email}
                        leftHelperText={
                            userInfoErrors?.email &&
                            userInfoErrorMessages?.email
                                ? userInfoErrorMessages.email
                                : props.isEdit
                                ? ''
                                : copy.willSendLink
                        }
                        name="email"
                        onChange={(event) =>
                            handleInputChange('email', event.target.value)
                        }
                        placeholder={copy.email}
                        state={userInfoErrors.email ? 'error' : 'idle'}
                        value={userInfo?.email || ''}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default StepOne;
