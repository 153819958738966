import React from 'react';
import { GivelifyIconVariant } from '@givelify/givelify-ui';
import DashboardMenu from 'components/dashboardMenu';
import { MenuTitleInfo } from 'components/dashboardMenu/types';
import { useTranslation } from 'react-i18next';
import { useGasRouterContext } from 'router/GasRouterProvider';

export const DonationsRoot: React.FCC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t('donationsRoot.title'),
            description: t('donationsRoot.description'),
        }),
        [t],
    );

    const { PATH } = useGasRouterContext();

    const menuItems = React.useMemo<MenuTitleInfo[]>(
        () => [
            {
                icon: 'description' as GivelifyIconVariant,
                title: t('donationsRoot.donationSummary.title'),
                description: t('donationsRoot.donationSummary.description'),
                link: PATH.DONATIONS.DONATION_SUMMARY_ENVELOPES(),
            },
            {
                icon: 'description',
                title: t('donationsRoot.donationActivity.title'),
                description: t('donationsRoot.donationActivity.description'),
                link: PATH.DONATIONS.DONATIONS_ACTIVITY(),
            },
            {
                icon: 'account-balance',
                title: t('donationsRoot.bankDeposits.title'),
                description: t('donationsRoot.bankDeposits.description'),
                link: PATH.DONATIONS.BANK_DEPOSITS,
            },
            {
                icon: 'credit-card',
                title: t('donationsRoot.refunds.title'),
                description: t('donationsRoot.refunds.description'),
                link: PATH.DONATIONS.REFUNDS,
            },
        ],
        [t, PATH.DONATIONS],
    );

    return (
        <DashboardMenu
            description={copy.description}
            menuItems={menuItems}
            title={copy.title}
        />
    );
};
