import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import {
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Grid,
    FormControl,
    IconButton,
    IconButtonTypeMap,
    useMediaQuery,
    OutlinedInput,
    useTheme,
    styled,
    InputLabel,
} from '@mui/material';
import { CloseIcon, SelectIcon } from '../assets';
import { GivelifyButton, GivelifyButtonProps } from '../button';
import { GivelifyCheckbox } from '../checkbox';
import { GivelifyLabel } from '../label';
import { GivelifyRadio } from '../radio';
import { DesignTokens } from '../specify';

import { DropdownOption, GivelifyDropdownProps } from './types';

type StyledFormControlProps = {
    mode: 'default' | 'accent';
    width: 'default' | 'fullwidth' | 'auto';
    shape: 'pill' | 'input';
};
const StyledFormControl = styled(FormControl, {
    shouldForwardProp: (prop) =>
        prop !== 'mode' && prop !== 'width' && prop !== 'shape',
})<StyledFormControlProps>(({ mode, width, shape, theme, size }) => ({
    '& .open': {
        color:
            mode === 'accent'
                ? DesignTokens.color.globalInfo400
                : DesignTokens.color.textPrimary,
        '& .MuiTypography-root': {
            color:
                mode === 'accent'
                    ? DesignTokens.color.globalInfo400
                    : undefined,
        },
        '& .MuiSelect-icon': {
            color:
                mode === 'accent'
                    ? DesignTokens.color.globalInfo400
                    : undefined,
        },
    },
    '&.open MuiTypography-root': {
        borderColor:
            mode === 'accent' ? DesignTokens.color.globalInfo400 : undefined,
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: shape === 'pill' ? theme.spacing(3) : theme.spacing(0.5),
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        padding: 0,
        width: width === 'default' ? '150px' : undefined,
        paddingRight: '24px',
        '&:not(.open).Mui-focused': {
            '& fieldset': {
                ...(mode === 'accent' && {
                    borderColor: DesignTokens.color.globalInfo400,
                }),
            },
            '&:hover': {
                '& fieldset': {
                    border: '1px solid rgba(0, 0, 0, 0.87)',
                },
            },
        },
        '&.open fieldset': {
            borderColor:
                mode === 'accent'
                    ? DesignTokens.color.globalInfo400
                    : undefined,
        },
    },
    '& .MuiSelect-select': {
        padding:
            shape === 'pill'
                ? '12px 48px 12px 24px'
                : size === 'medium'
                ? '16px 48px 16px 16px'
                : '12px 48px 12px 12px',
    },
    '& .MuiSelect-icon': {
        right: '20px',
    },
}));

type StyledMenuItemProps = {
    mode: 'default' | 'accent';
};

const StyledMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'mode' && prop !== 'width',
})<StyledMenuItemProps>(({ theme, mode }) => ({
    minHeight: '48px',
    padding: '12px 32px',
    color: DesignTokens.color.textPrimary,
    '&:hover': {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryHover,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'inherit',
    },
    '&.Mui-selected': {
        backgroundColor:
            mode === 'accent'
                ? DesignTokens.color.backgroundComponentPrimaryDefault
                : DesignTokens.color.backgroundComponentPrimaryHover,
        color:
            mode === 'accent'
                ? DesignTokens.color.globalInfo400
                : DesignTokens.color.textPrimary,
        '&.Mui-focusVisible': {
            backgroundColor:
                mode === 'accent'
                    ? DesignTokens.color.backgroundComponentPrimaryDefault
                    : DesignTokens.color.backgroundComponentPrimaryHover,
            '&:hover': {
                backgroundColor:
                    mode === 'accent'
                        ? DesignTokens.color.backgroundComponentPrimaryHover
                        : undefined,
            },
        },
        '&:hover': {
            ...(mode === 'accent'
                ? {
                      backgroundColor:
                          DesignTokens.color.backgroundComponentPrimaryHover,
                  }
                : undefined),
        },
        [theme.breakpoints.down('mobile')]: {
            '&.Mui-focusVisible': {
                backgroundColor: '#F4F2FF',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '12px 24px',
        fontSize: '12px',
        lineHeight: '12px',
        minHeight: 'auto',
    },
}));

const StyledTitleWrapper = styled(Grid)({
    padding: '12px 32px',
    gap: 34,
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 9999,
    background: DesignTokens.color.textWhite,
    '@media (max-width: 767px)': {
        padding: '12px 24px',
    },
});

const StyledSelect = styled(Select)({
    '& .MuiSelect-select': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});

const StyledIcon = styled(IconButton)({
    padding: 0,
    transition: '.3s ease',
    '&.Mui-disabled': {
        opacity: 0.38,
    },
});

const GivelifyLabelEllipsisCommon = styled(GivelifyLabel, {
    shouldForwardProp: (prop) => prop !== 'secondLabel',
})<{ secondLabel?: boolean }>(({ secondLabel, theme }) => ({
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    ...(secondLabel && {
        [theme.breakpoints.down('tablet')]: {
            fontSize: '12px',
            lineHeight: secondLabel ? '24px' : '16px',
        },
    }),
}));

const GivelifyLabelEllipsisPrimary = styled(GivelifyLabelEllipsisCommon)(
    () => ({
        fontWeight: 700,
    }),
);

const GivelifyLabelEllipsisSecondary = styled(GivelifyLabelEllipsisCommon)(
    () => ({}),
);

const NoEventsFormControl = styled(StyledFormControl)({
    pointerEvents: 'none',
});

const MobileOverlay = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('mobile')]: {
        display: 'block',
        background: DesignTokens.color.overlayModal,
        position: 'fixed',
        left: 0,
        width: '100vw',
        zIndex: 1,
        top: '0',
        height: '100vh',
    },
}));

const StyledInput = styled(OutlinedInput, {
    shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth?: boolean }>(({ fullWidth, theme }) => ({
    borderRadius: 24,
    height: 48,
    maxWidth: fullWidth ? '100%' : 150,
    width: fullWidth ? '100%' : 150,
    color: DesignTokens.color.textPrimary,
}));

const StyledMobileOpenButton = styled(GivelifyButton)({
    fontSize: '12px',
    lineHeight: '24px',
    borderColor: DesignTokens.color.borderComponentDefault,
    padding: '4 12px',
    color: DesignTokens.color.textPrimary,
    whiteSpace: 'nowrap',
    '&&': {
        '&:hover': {
            borderColor: DesignTokens.color.borderComponentDefault,
            color: DesignTokens.color.textPrimary,
            background: 'none',
        },
    },
});

const StyledInputLabel = styled(InputLabel)(({ shrink }) => ({
    '&:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 13px) scale(1)',
    },
}));

const MobileOpenButton = React.forwardRef<
    HTMLButtonElement,
    GivelifyButtonProps
>((props: GivelifyButtonProps, ref) => {
    return <StyledMobileOpenButton {...props} ref={ref} />;
});

type DropdownValues = Array<string | number> | (string | number);

export const GivelifyDropdown: React.FCC<GivelifyDropdownProps> = ({
    variant = 'default',
    IconComponent,
    children,
    inputNameAttr,
    options,
    menuTitle,
    hasCloseButton,
    multipleLine,
    renderMultiple,
    MenuItemWrapper,
    hasMobileOverlay,
    mode = 'default',
    width = 'default',
    onMenuItemRender,
    setRenderValueText,
    noOptionsLabel = 'No options',
    mobileButtonText,
    shape = 'pill',
    state = 'idle',
    leftHelperText,
    rightHelperText,
    darkMode,
    ...props
}) => {
    const [values, setValues] = React.useState<DropdownValues>(
        (variant === 'checkbox'
            ? props.value ?? []
            : props.value) as DropdownValues,
    );
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const theme = useTheme();
    const isTablet = useMediaQuery('@media (max-width: 900px)');
    const isMobile = useMediaQuery('@media (max-width: 767px)');

    const showMobileOpenButton = Boolean(mobileButtonText) && isMobile;

    React.useEffect(() => {
        setValues(
            (variant === 'checkbox'
                ? props.value ?? []
                : props.value) as DropdownValues,
        );
        // listen only to props.value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const handleChange = React.useCallback(
        (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
            const {
                target: { value },
            } = event;
            setValues(value as DropdownValues);
            props.onChange?.(event, child);
        },
        [props],
    );

    const handleButtonClick = React.useCallback(() => {
        if (Array.isArray(values) && values.length === options.length) {
            setValues([]);
        } else {
            setValues(options.map((option) => option.id));
        }
    }, [options, values]);

    const handleSelectOpen = React.useCallback(
        (e: React.SyntheticEvent<Element, Event>) => {
            setIsOpen(true);
            props.onOpen && props.onOpen(e);
        },
        [props],
    );

    const handleSelectClose = React.useCallback(
        (e: React.SyntheticEvent<Element, Event>) => {
            setIsOpen(false);
            props.onClose && props.onClose(e);
        },
        [props],
    );

    const renderValue = React.useCallback(
        (value: unknown) => {
            if (Array.isArray(value)) {
                return options
                    .filter((option) => value.includes(option.id))
                    .map((option) => option.label)
                    .join(', ');
            }

            const variant = 'body1';
            const secondaryVariant = 'body2';

            let primaryText: string | undefined,
                secondaryText: string | undefined;
            const noValue =
                value === undefined || value === null || value === '';

            if (noValue) {
                primaryText = props.label || props.placeholder;
            } else {
                const selectedOption = options.find(
                    (option) => option.id === value,
                );
                primaryText =
                    selectedOption && setRenderValueText
                        ? setRenderValueText(selectedOption)
                        : selectedOption?.label;
                secondaryText = selectedOption?.secondaryLabel;
            }

            return (
                <>
                    <GivelifyLabelEllipsisPrimary
                        text={primaryText}
                        title={primaryText}
                        variant={variant}
                    />
                    {secondaryText && renderMultiple && (
                        <GivelifyLabelEllipsisSecondary
                            secondLabel
                            color={DesignTokens.color.textSecondary}
                            text={secondaryText}
                            title={secondaryText}
                            variant={secondaryVariant}
                        />
                    )}
                </>
            );
        },
        [
            setRenderValueText,
            options,
            renderMultiple,
            props.placeholder,
            props.label,
        ],
    );

    const getOption = React.useCallback(
        (option: DropdownOption) => {
            if (onMenuItemRender) {
                return onMenuItemRender(option);
            }
            switch (variant) {
                case 'radio': {
                    const RadioOption = (
                        <GivelifyRadio
                            checked={values === option.id}
                            label={option.label}
                            multipleLine={multipleLine}
                            secondLabel={option.secondaryLabel}
                        />
                    );
                    return MenuItemWrapper ? (
                        <MenuItemWrapper {...option}>
                            {RadioOption}
                        </MenuItemWrapper>
                    ) : (
                        RadioOption
                    );
                }
                case 'checkbox': {
                    const CheckboxOption = (
                        <GivelifyCheckbox
                            checked={
                                Array.isArray(values) &&
                                values?.includes(option.id)
                            }
                            label={option.label}
                            multipleLine={multipleLine}
                            secondLabel={option.secondaryLabel}
                        />
                    );
                    return MenuItemWrapper ? (
                        <MenuItemWrapper {...option}>
                            {CheckboxOption}
                        </MenuItemWrapper>
                    ) : (
                        CheckboxOption
                    );
                }
                default: {
                    const DefaultOption = (
                        <Stack
                            alignItems={multipleLine ? 'flex-start' : 'center'}
                            direction={multipleLine ? 'column' : 'row'}
                            spacing={1}
                            width="100%"
                        >
                            <GivelifyLabelEllipsisPrimary
                                text={option.label}
                                title={option.label}
                                variant="body1"
                            />
                            {option.secondaryLabel && (
                                <GivelifyLabelEllipsisSecondary
                                    color={DesignTokens.color.textSecondary}
                                    text={option.secondaryLabel}
                                    title={option.secondaryLabel}
                                    variant="body1"
                                />
                            )}
                        </Stack>
                    );
                    return MenuItemWrapper ? (
                        <MenuItemWrapper {...option}>
                            {DefaultOption}
                        </MenuItemWrapper>
                    ) : (
                        DefaultOption
                    );
                }
            }
        },
        [MenuItemWrapper, multipleLine, onMenuItemRender, values, variant],
    );

    const DefaultIconComponent = React.useCallback(
        (props: IconButtonTypeMap) => (
            <StyledIcon {...props}>
                <SelectIcon />
            </StyledIcon>
        ),
        [],
    );

    const helperText = React.useMemo(() => {
        if (!leftHelperText && !rightHelperText) return null;

        return (
            <Stack
                flexDirection="row"
                marginTop={1}
                paddingLeft={state === 'error' ? 0 : 2}
                paddingRight={state === 'error' ? 0 : 2}
            >
                {leftHelperText ? (
                    <GivelifyLabel
                        color={
                            props.disabled
                                ? DesignTokens.color.textDisabled
                                : state === 'error'
                                ? darkMode
                                    ? DesignTokens.color.textWhite
                                    : DesignTokens.color.textErrorDefault
                                : //: state === 'warning'
                                  //? DesignTokens.color.textWarningDefault
                                  //: state === 'success'
                                  //? DesignTokens.color.textSuccessDefault
                                  DesignTokens.color.textSecondary
                        }
                        id={`${props.id}-leftHelperText`}
                        lineHeight="16px"
                        variant="caption1"
                    >
                        {leftHelperText}
                    </GivelifyLabel>
                ) : null}
                {rightHelperText ? (
                    <GivelifyLabel
                        color={
                            props.disabled
                                ? DesignTokens.color.textDisabled
                                : state === 'error'
                                ? darkMode
                                    ? DesignTokens.color.textWhite
                                    : DesignTokens.color.textErrorDefault
                                : //: state === 'warning'
                                  //? DesignTokens.color.textWarningDefault
                                  //: state === 'success'
                                  //? DesignTokens.color.textSuccessDefault
                                  DesignTokens.color.textSecondary
                        }
                        id={`${props.id}-rightHelperText`}
                        lineHeight="16px"
                        sx={{ ml: 'auto' }}
                        variant="caption1"
                    >
                        {rightHelperText}
                    </GivelifyLabel>
                ) : null}
            </Stack>
        );
    }, [
        rightHelperText,
        leftHelperText,
        state,
        props.id,
        props.disabled,
        darkMode,
    ]);

    if (options.length === 0) {
        return (
            <NoEventsFormControl
                fullWidth={width !== 'auto'}
                mode={mode}
                shape={shape}
                size={props.size}
                width={width}
            >
                {props.input ? (
                    <props.input.type
                        {...props.input.props}
                        placeholder={noOptionsLabel}
                    />
                ) : (
                    <StyledInput
                        fullWidth={width === 'fullwidth'}
                        placeholder={noOptionsLabel}
                    />
                )}
            </NoEventsFormControl>
        );
    }
    if (options.length === 1) {
        return (
            <NoEventsFormControl
                fullWidth={width !== 'auto'}
                mode={mode}
                shape={shape}
                width={width}
            >
                <StyledSelect
                    {...props}
                    IconComponent={() => null}
                    disabled={false}
                    input={props.input}
                    onChange={handleChange}
                    open={false}
                    renderValue={renderValue}
                    value={options[0].id}
                >
                    {options.map((option) => (
                        <StyledMenuItem
                            key={option.id}
                            mode={mode}
                            value={option.id}
                        >
                            {getOption(option)}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            </NoEventsFormControl>
        );
    }

    return (
        <StyledFormControl
            error={state === 'error'}
            fullWidth={width !== 'auto'}
            mode={mode}
            shape={shape}
            size={props.size}
            width={width}
        >
            {isOpen && hasMobileOverlay && <MobileOverlay />}
            {showMobileOpenButton ? (
                <MobileOpenButton
                    ref={buttonRef}
                    onClick={handleSelectOpen}
                    text={mobileButtonText as string}
                    variant="secondary"
                />
            ) : null}
            {props.label && <StyledInputLabel>{props.label}</StyledInputLabel>}
            <StyledSelect
                {...props}
                IconComponent={IconComponent ?? DefaultIconComponent}
                MenuProps={{
                    sx: {
                        zIndex: 1301,
                    },
                    MenuListProps: {
                        sx: {
                            padding: 0,
                            maxHeight: 416,
                            overflowY: 'auto',
                            '@media (max-width: 900px)': {
                                maxHeight: 400,
                            },
                            '@media (max-width: 767px)': {
                                // 94px = header height, 16px = paddings
                                maxHeight: 'calc(100vh - 94px - 16px)',
                            },
                        },
                    },
                    PaperProps: {
                        sx: {
                            ...(isMobile
                                ? {
                                      '&&': {
                                          boxShadow:
                                              '0px 0.25px 2.75px rgba(10, 0, 77, 0.039), 0px 2.38915px 7.81903px rgba(10, 0, 77, 0.08)',
                                          borderRadius: '40px 40px 0px 0px',
                                          width: '100vw',
                                          maxWidth: '100vw',
                                          position: 'fixed',
                                          bottom: 0,
                                          left: '0 !important',
                                          top: 'auto !important',
                                          padding: '4px 0 12px 0',
                                          [theme.breakpoints.down('mobile')]: {
                                              paddingTop: '12px',
                                          },
                                      },
                                  }
                                : null),
                            padding: '16px 0',
                            width:
                                variant === 'checkbox'
                                    ? 400
                                    : isTablet
                                    ? 360
                                    : 326,
                            maxWidth:
                                variant === 'checkbox'
                                    ? 400
                                    : isTablet
                                    ? 'calc(100vw - 30px)'
                                    : 326,
                            overflow: 'hidden',
                            boxShadow: DesignTokens.shadow.shadowHigh,
                            borderWidth:
                                DesignTokens.border
                                    .borderComponentAccentSecondary.width,
                            borderStyle:
                                DesignTokens.border
                                    .borderComponentAccentSecondary.style,
                            borderColor:
                                DesignTokens.border
                                    .borderComponentAccentSecondary.color,
                            borderRadius: '8px',
                            ...(shape === 'input'
                                ? props.size === 'medium'
                                    ? {
                                          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)':
                                              {
                                                  transform:
                                                      'translate(16px, 13px)',
                                              },
                                      }
                                    : {
                                          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)':
                                              {
                                                  transform:
                                                      'translate(14px, 16px)',
                                              },
                                      }
                                : {}),
                        },
                    },
                    ...props.MenuProps,
                }}
                autoWidth={width === 'auto'}
                className={mergeClassNames(
                    props.className,
                    isOpen ? 'open' : '',
                )}
                fullWidth={width === 'fullwidth'}
                input={props.input}
                multiple={variant === 'checkbox'}
                onChange={handleChange}
                onClose={handleSelectClose}
                onOpen={handleSelectOpen}
                open={isOpen}
                renderValue={renderValue}
                sx={{
                    display: showMobileOpenButton ? 'none' : 'inherit',
                }}
                value={values}
            >
                {(variant === 'checkbox' || menuTitle) && (
                    <StyledTitleWrapper
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        wrap="nowrap"
                    >
                        {menuTitle && (
                            <>
                                <GivelifyLabel
                                    bold={isMobile}
                                    color={
                                        isMobile
                                            ? DesignTokens.color.textPrimary
                                            : DesignTokens.color.textSecondary
                                    }
                                    text={menuTitle}
                                    variant={isMobile ? 'body1' : 'body2'}
                                />
                                {hasCloseButton ? (
                                    <GivelifyButton
                                        onClick={handleSelectClose}
                                        size="small"
                                        variant="icon"
                                    >
                                        <CloseIcon />
                                    </GivelifyButton>
                                ) : null}
                            </>
                        )}
                        {variant === 'checkbox' && (
                            <GivelifyButton
                                onClick={handleButtonClick}
                                size="small"
                                text={
                                    Array.isArray(values) &&
                                    values.length === options.length
                                        ? 'Clear All'
                                        : 'Select All'
                                }
                                variant="ghost"
                            />
                        )}
                    </StyledTitleWrapper>
                )}
                {options.map((option) => (
                    <StyledMenuItem
                        key={option.id}
                        mode={mode}
                        value={option.id}
                    >
                        {getOption(option)}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
            {helperText}
        </StyledFormControl>
    );
};
