import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';

const useFilterStyles = makeStyles((theme: Theme) => {
    return {
        filter: {
            '& .MuiOutlinedInput-root': {
                borderRadius: '2rem',
                borderColor: theme.palette.primary.main,
                '&:focus-within, &:active, &:focus': {
                    borderColor: theme.palette.primary.main,
                    boxShadow: 'rgba(64, 143, 218, 0.25) 0 0 0 0.2rem',
                    borderRadius: '2rem',
                },
            },
            '& .MuiSelect-root': {
                paddingLeft: theme.spacing(3),
                fontWeight: 'bold',
                height: '32px',
                boxSizing: 'border-box',
                borderWidth: 1,
                display: 'flex',
                alignItems: 'center',
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: '40px',
                borderColor: '#aaa',
            },
            '& .Mui-focused': {
                borderRadius: '2rem',
                boxShadow: 'unset',
                borderWidth: 1,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 1,
                    borderColor: '#aaa',
                },
            },
        },
    };
});

interface FilterInputWrapperProps {
    className?: string;
}

export const FilterInputWrapper: React.FCC<FilterInputWrapperProps> = ({
    children,
    className,
}) => {
    const classes = useFilterStyles();
    return <div className={`${classes.filter} ${className}`}>{children}</div>;
};
