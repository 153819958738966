import React, { useState } from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { inputStyles } from './styles';
import {
    IconButton,
    InputAdornment,
    InputBaseComponentProps,
    TextField,
} from '@material-ui/core';
import { GvlInputSize } from './types';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import { GivelifyIcon } from '../components/GivelifyIcon';

interface GivelifyInputRefProps {
    /**
     * Pass a ref to the `input` element.
     */
    inputRef?: React.Ref<any>;
    /**
     * Pass a ref to the `input props` element.
     */
    inputPropsRef?: React.Ref<any>;
}
interface GivelifyInputMultilineProps {
    /**
     * If `true`, a textarea element will be rendered instead of an input.
     */
    multiline?: boolean;
    /**
     * Number of rows to display when multiline option is set to true.
     */
    rows?: number;
}
interface GivelifyInputInnerProps {
    /**
     * End `InputAdornment` for this component.
     */
    endAdornment?: React.ReactNode;
    /**
     * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
     */
    inputProps?: InputBaseComponentProps;
}
export enum Keys {
    ENTER = 'Enter',
}

type Handler = (value: string | number) => void;

const onKeypressHandlerFactory = (
    handler: Handler,
    event: React.KeyboardEvent,
) => {
    if (event.key === Keys.ENTER) {
        handler((event.target as HTMLInputElement).value);
    }
};

export interface GivelifyInputProps extends GivelifyBoxMarginProps {
    /**
     * Id for the input
     */
    id?: string;
    /**
     * Size of the input (Large, Medium, Dense)
     */
    size?: GvlInputSize;
    /**
     * Input aria label for accesability
     */
    ariaLabel: string;
    /**
     * Input label for accesability
     */
    inputLabel: string;
    /**
     * Use full width of the parent
     */
    fullWidth?: boolean;
    /**
     * Make input look focused
     */
    isFocused?: boolean;
    /**
     * Input placeholder
     */
    placeholder?: string;
    /**
     * Label to display on top this input
     */
    label?: string;
    /**
     * Disable input
     */
    disabled?: boolean;
    /**
     * Small helper text at the bottom of the input
     */
    helperText?: string;
    /**
     * Render helper text at right side
     */
    helperTextRight?: boolean;
    /**
     * If true, input state will show error
     */
    error?: boolean;
    /**
     * If true, input state will show success (overrides error)
     */
    success?: boolean;
    /**
     * Input value
     */
    value?: string | number;
    /**
     * On change event callback
     */
    onChange?: (value: string) => void;
    /**
     * On change event callback with native event param
     */
    onNativeChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * On focus event callback
     */
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    /**
     * If provided default width or fullWidth props will be overriden by the provided value
     */
    width?: number;
    /**
     * Name attribute of the `input` element.
     */
    name?: string;
    /**
     * Provided classname.
     */
    className?: string;
    /**
     * The default value of the `input` element.
     */
    defaultValue?: string | number | readonly string[];
    /**
     * Input type. Default is 'text
     */
    type?: 'text' | 'email' | 'password' | 'number';
    /**
     * On lost focus event
     */
    onBlur?: (value: string | number) => void;
    /**
     * On change event callback with native event param
     */
    onNativeBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    /**
     * On press Enter event
     */
    onEnter?: (value: string | number) => void;
    /**
     * Max length of input
     */
    maxLength?: number | null;
    /**
     * Label shrink
     */
    labelShrink?: boolean;
}

/**
 * Givelify Input
 */
export const GivelifyInput: React.FCC<
    GivelifyInputProps &
        GivelifyInputRefProps &
        GivelifyInputMultilineProps &
        GivelifyInputInnerProps
> = ({ type = 'text', ...props }) => {
    const {
        box,
        boxMargin,
        focus,
        fakeFocus,
        focusError,
        focusSuccess,
        large,
        medium,
        dense,
        inputBoxSuccess,
        mediumLabel,
        denseLabel,
        customWidth,
        helperTextRoot,
        helperTextContained,
    } = inputStyles({
        width: props.width ? props.width : 0,
        helperTextRight: props.helperTextRight,
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        marginRight: props.marginRight,
        marginTop: props.marginTop,
    });
    const rootClassName = mergeClassNames(
        boxMargin,
        props.success && inputBoxSuccess,
        props.isFocused && fakeFocus,
        props.className,
    );
    const inputPropsClassName = mergeClassNames(
        !props.multiline && box,
        props.size === undefined && !props.multiline && medium,
        props.size === 'large' && !props.multiline && large,
        props.size === 'medium' && !props.multiline && medium,
        props.size === 'dense' && !props.multiline && dense,
        props.width && customWidth,
    );
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(event.target.value);
        if (props.onNativeChange) props.onNativeChange(event);
    };

    const [showPassword, setShowPassword] = useState(type !== 'password');
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };
    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) props.onBlur(event.target.value);
        if (props.onNativeBlur) props.onNativeBlur(event);
    };
    const onEnterHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.onEnter) onKeypressHandlerFactory(props.onEnter, event);
    };
    return (
        <TextField
            id={props.id}
            className={rootClassName}
            data-testid={props.id}
            name={props.name}
            inputRef={props.inputRef}
            label={props.label}
            fullWidth={props.fullWidth}
            placeholder={props.placeholder}
            multiline={props.multiline}
            minRows={props.rows}
            variant="outlined"
            classes={{
                root: props.error
                    ? focusError
                    : props.success
                    ? focusSuccess
                    : focus,
            }}
            inputProps={{
                className: inputPropsClassName,
                'aria-label': props.inputLabel,
                'aria-placeholder': props.placeholder,
                ...props.inputProps,
                maxLength: props.maxLength,
            }}
            disabled={props.disabled}
            FormHelperTextProps={{
                classes: {
                    contained: helperTextContained,
                    root: helperTextRoot,
                },
            }}
            helperText={props.helperText}
            error={props.error}
            aria-label={props.ariaLabel}
            value={props.value}
            onChange={onChange}
            onFocus={props.onFocus}
            type={type === 'password' && showPassword ? 'text' : type}
            InputProps={{
                ref: props.inputPropsRef,
                endAdornment: props.endAdornment
                    ? props.endAdornment
                    : type === 'password' && (
                          <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                  {showPassword ? (
                                      <GivelifyIcon variant="password-open" />
                                  ) : (
                                      <GivelifyIcon variant="password" />
                                  )}
                              </IconButton>
                          </InputAdornment>
                      ),
            }}
            InputLabelProps={{
                classes: {
                    outlined:
                        props.size === 'dense'
                            ? denseLabel
                            : props.size === 'medium'
                            ? mediumLabel
                            : undefined,
                },
                shrink: props.labelShrink,
            }}
            defaultValue={props.defaultValue}
            onBlur={handleOnBlur}
            onKeyPress={onEnterHandler}
        />
    );
};

export type GivelifyFormInputProps<Form extends FieldValues> =
    GivelifyInputProps &
        GivelifyInputInnerProps & {
            name: keyof Form;
            formRef: UseFormMethods<Form>;
        };

export const GivelifyFormInput = <Form extends FieldValues>(
    props: GivelifyFormInputProps<Form>,
) => {
    const { formRef, ...inputProps } = props;

    const { register, errors } = formRef;
    const name = inputProps.name.toString();

    const error = (errors as any)[name];
    const massage = error && error.message ? error.message : '';
    return (
        <GivelifyInput
            className={props.className}
            helperText={massage}
            error={!!massage}
            inputRef={register}
            {...inputProps}
            name={name}
        />
    );
};

export const GivelifyInputMemoized = React.memo(GivelifyInput);
