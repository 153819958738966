import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithLocaltime } from '@givelify/utils';
import { makeStyles, Theme } from '@material-ui/core/styles';

const dateRangeCellStyles = makeStyles((theme: Theme) => ({
    cell: {
        paddingLeft: '16px !important',
        paddingRight: '16px important',
        zIndex: 300,
    },
}));

interface Props {
    row: { startDate: Date; endDate: Date };
}

export const DateRangeFormatter: React.FCC<Props> = ({
    row,
}: {
    row: { startDate: Date; endDate: Date };
}) => {
    return (
        <GivelifyLabel
            text={`${formatWithLocaltime(
                row.startDate,
            )} - ${formatWithLocaltime(row.endDate)}`}
            variant="body2"
            whiteSpace="nowrap"
        />
    );
};

export const DateRangeCell = (props: Table.DataCellProps) => {
    const classes = dateRangeCellStyles();

    return (
        <Table.Cell {...props} className={classes.cell}>
            <DateRangeFormatter row={props.row} />
        </Table.Cell>
    );
};
