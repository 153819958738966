import React from 'react';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '@givelify/api';
import {
    isSucceeded,
    responseOrUndefined,
    TimeFrameValue,
    useApiRequest,
} from '@givelify/utils';
import { getDonationService } from 'api/utils/serviceProvider';
import { useTranslation } from 'react-i18next';
import { LifeTimeData } from 'store/dashboard/lifeTime/types';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';
import {
    Amount,
    Divider,
    Title,
    TotalDonationsSkeleton,
    TotalDonationsWrapper,
} from './styles';

type TotalDonationsProps = {
    doneeId: number;
    timeFrame: TimeFrameValue;
    transactionFilter: TRANSACTION_FILTER_VALUE;
    envelopeIds: number[];
    messageFilter: MESSAGE_FILTER_VALUE[];
    search?: string;
};

const TotalDonationsComponent: React.FCC<TotalDonationsProps> = ({
    envelopeIds,
    messageFilter,
    transactionFilter,
    timeFrame,
    doneeId,
    search,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t('donationsActivity.text.totalDonations'),
        }),
        [t],
    );

    const service = getDonationService();

    const [getDonationsStatisticRequest, makeGetDonationsStatisticRequest] =
        useApiRequest<LifeTimeData>();

    React.useEffect(() => {
        void makeGetDonationsStatisticRequest(
            service.getTotalDonationsByQuery(
                doneeId,
                {
                    timeFrame,
                    envelopeIds,
                    transactionFilter,
                    messageFilter,
                    search,
                },
                'activity',
            ),
        );
    }, [
        doneeId,
        timeFrame,
        envelopeIds,
        transactionFilter,
        messageFilter,
        search,
        service,
        makeGetDonationsStatisticRequest,
    ]);

    const donationStatistic = responseOrUndefined(getDonationsStatisticRequest);
    const isDonationStatisticSucceeded = isSucceeded(
        getDonationsStatisticRequest,
    );

    return isDonationStatisticSucceeded ? (
        <TotalDonationsWrapper data-testid="total-donations-wrapper">
            <Title
                data-testid="total-donations-text"
                text={copy.title}
                variant="body2"
            />
            <Divider />
            <Amount
                data-testid="total-donations-amount"
                text={formatMoneyWithCommasAndDolarSign(
                    donationStatistic?.data?.sum,
                )}
                variant="heading3M"
            />
        </TotalDonationsWrapper>
    ) : (
        <TotalDonationsSkeleton
            data-testid="total-donations-skeleton"
            variant="rectangular"
        />
    );
};

export const TotalDonations = React.memo(TotalDonationsComponent);
