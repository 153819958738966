import React, { useMemo } from 'react';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { IntegrationTypes } from 'pages/integrations/types';
import {
    ACS,
    SHELBY,
} from 'pages/integrations/utils/integrationFormatFilterUtils';
import { selectIntegrationTypeStyle } from '../../style';
import GroupSelectIntegrationVersion from './GroupSelectIntegrationVersion';

interface SelectIntegrationTypeProps {
    integration: IntegrationTypes;
    onChange: (value: string) => void;
    isReadOnly: boolean;
}

const acsOptions = [ACS.ACS, ACS.ACSREALM];
const shelbyOptions = [SHELBY.SHELBYARENA, SHELBY.SHELBYNEXT];

const SelectIntegrationVersion: React.FCC<SelectIntegrationTypeProps> = ({
    integration,
    onChange,
    isReadOnly,
}) => {
    const classes = selectIntegrationTypeStyle();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            selectVersion: t(
                'pages.integrations.content.setup-dialog.integration-form.selectVersion',
                {
                    integrationType: integration.generalIntegrationType,
                },
            ),
        }),
        [t, integration.generalIntegrationType],
    );

    let values;
    if (integration.generalIntegrationType === 'ACS') {
        values = acsOptions;
    } else if (integration.generalIntegrationType === 'SHELBY') {
        values = shelbyOptions;
    }

    return (
        <div className={classes.selectIntegrationContent}>
            <GivelifyLabel text={copy.selectVersion} variant="body1" />
            <div className={classes.selectTypeContent}>
                <GroupSelectIntegrationVersion
                    initialValues={values}
                    integrationType={integration.generalIntegrationType}
                    isReadOnly={isReadOnly}
                    label="Integration Version"
                    name="integrationVersion"
                    onChange={onChange}
                    options={values}
                />
            </div>
        </div>
    );
};

export default SelectIntegrationVersion;
