import React, { useMemo } from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { GivelifyTab } from '@givelify/ui';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { useTranslation } from 'react-i18next';
import { IntegrationTypes } from '../../../../../types';

interface F1CCBUnMatchDonorTabsHeaderProps {
    value: number;
    onChange: (ewValue: number) => void;
    isLoading: boolean;
    integration: IntegrationTypes;
}

const Loading: JSX.Element = (
    <FacebookLoading
        borderRadius={0}
        height={49}
        marginBottom={24}
        width="100%"
    />
);

const AutoIntegrationUnMatchDonorTabsHeader: React.FCC<
    F1CCBUnMatchDonorTabsHeaderProps
> = ({ value, onChange, isLoading, integration }) => {
    const translationPrefix =
        integration.integrationType === 'F1GO'
            ? 'f1GoDonorPage'
            : 'f1DonorPage';
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            potential: t(`${translationPrefix}.potential`),
            enterF1: t(`${translationPrefix}.enterF1`),
            enterCCB: t('pages.integrations.ccbIntegration.enterCCBId'),
            enterF1Go: t('pages.integrations.f1goIntegration.enterId'),
            createNewF1: t(`${translationPrefix}.createNewF1`),
            enterPlanningCenterId: t(
                'pages.integrations.planningCenter.enterPlanningCenterId',
            ),
            createNewPlanningCenter: t(
                'pages.integrations.planningCenter.createNewPlanningCenter',
            ),
        }),
        [t, translationPrefix],
    );

    const { isCCB, isPlanningCenter, isF1, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    if (isLoading) return Loading;

    const options = [
        { label: copy.potential },
        {
            label: isCCB
                ? copy.enterCCB
                : isF1
                ? copy.enterF1
                : isF1Go
                ? copy.enterF1Go
                : copy.enterPlanningCenterId,
        },
    ];
    if (isF1 || isF1Go) {
        options.push({ label: copy.createNewF1 });
    }
    if (isPlanningCenter) {
        options.push({ label: copy.createNewPlanningCenter });
    }
    return <GivelifyTab onChange={onChange} options={options} value={value} />;
};

export default AutoIntegrationUnMatchDonorTabsHeader;
