import React, { useMemo } from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { GivelifyIcon as NewGivelifyIcon } from '@givelify/ui';
import { formatTaxId } from '@givelify/utils';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { OnboardingActions } from '../../../OnboardingActions';
import {
    Divider,
    StyledForm,
    SummaryWrapper,
} from '../../../requiredBankInfo/AccountDetails/Summary/styles';
import { UseTaxIdContext, CurrentPage } from '../TaxIdContext';

const ActionLabel = ({
    text,
    onClick,
    name,
}: {
    text: string;
    onClick: () => void;
    name: string;
}) => (
    <GivelifyLink
        name={name}
        onClick={onClick}
        text={
            <Grid
                container
                alignItems="center"
                flexDirection="row"
                gap="4px"
                justifyContent="right"
            >
                <GivelifyIcon
                    color={DesignTokens.color.textAccentDefault}
                    size={16}
                    variant="edit-outline"
                />
                {text}
            </Grid>
        }
        variant="caption1"
    ></GivelifyLink>
);

const TaxIdSummaryView: React.FCC = () => {
    const {
        taxIdInfo,
        organizationType,
        requireAdditionalInfo,
        onBackClick,
        onCancelClick,
        onSummaryContinue,
        onSummaryLinkNavigate,
        nonProfitHappyPathFileUpload,
        filePickerProps,
        selectedDocumentType,
    } = UseTaxIdContext();

    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            ein: t('taxId.ein'),
            yearFounded: t('taxId.organizationInfoPage.orgYear'),
            denomination: t('taxId.organizationInfoPage.denomination'),
            membersCount: t('taxId.organizationInfoPage.membersCount'),

            edit: t('labels.edit'),
            submit: t('labels.submit'),
            header: t('taxId.summaryHeader'),
            description: t('taxId.summaryDescription'),
            documentType: t(
                `captivePortal.taxId.summary.${selectedDocumentType}`,
            ),
        }),
        [t, selectedDocumentType],
    );

    const churchHappyPath =
        organizationType === 'church' && !requireAdditionalInfo;

    const { taxId, organizationYear, congregationSize } = taxIdInfo;

    const items: {
        title: string;
        description: string | undefined;
        navigateTo: CurrentPage;
    }[] = churchHappyPath
        ? [
              {
                  title: copy.ein,
                  description: formatTaxId(taxId),
                  navigateTo: 'taxId',
              },
              {
                  title: copy.yearFounded,
                  description: organizationYear,
                  navigateTo: 'orgInfo',
              },
              {
                  title: copy.denomination,
                  description: taxIdInfo.religiousAffiliation,
                  navigateTo: 'orgInfo',
              },
              {
                  title: copy.membersCount,
                  description: congregationSize,
                  navigateTo: 'orgInfo',
              },
          ]
        : nonProfitHappyPathFileUpload
        ? [
              {
                  title: copy.documentType,
                  description: filePickerProps.uploadedFile?.name,
                  navigateTo: 'uploadDocument',
              },
              {
                  title: copy.yearFounded,
                  description: organizationYear,
                  navigateTo: 'yearFounded',
              },
          ]
        : [
              {
                  title: copy.ein,
                  description: formatTaxId(taxId),
                  navigateTo: 'taxId',
              },
              {
                  title: copy.yearFounded,
                  description: organizationYear,
                  navigateTo: 'yearFounded',
              },
          ];

    return (
        <StyledForm>
            <GivelifyLabel
                marginBottom={1}
                text={copy.header}
                variant="heading1S"
            />

            <GivelifyLabel
                marginBottom={3}
                text={copy.description}
                variant="body1"
            />
            <SummaryWrapper gridTemplateColumns="6fr 1fr">
                {items.map((item, index) => (
                    <React.Fragment key={item.title}>
                        <div>
                            <GivelifyLabel text={item.title} variant="body1B" />
                            <GivelifyLabel
                                className="expand-text"
                                text={item.description}
                                variant="body2"
                            />
                        </div>
                        <ActionLabel
                            name={item.navigateTo}
                            onClick={() =>
                                onSummaryLinkNavigate(item.navigateTo)
                            }
                            text={copy.edit}
                        />
                        {index !== items.length - 1 && (
                            <Divider className="full-width" />
                        )}
                    </React.Fragment>
                ))}
            </SummaryWrapper>
            <OnboardingActions
                continueIcon={<NewGivelifyIcon color="#fff" variant="lock" />}
                continueText={copy.submit}
                disableContinue={false}
                onBack={onBackClick}
                onCancel={() => onCancelClick(false)}
                onContinue={onSummaryContinue}
                submitButtonName="submit"
            />
        </StyledForm>
    );
};

export default TaxIdSummaryView;
