import React from 'react';
import { formatWithLocaltime } from '@givelify/utils';
import ChartEmptyState from 'assets/ChartEmptyState.svg';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
    BoldEmptyStateText,
    EmptyStateImg,
    EmptyStateText,
    EmptyStateWrapper,
    TextWrapper,
} from './styles';

type EmptyStateProps = {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
};

const EmptyStateComponent: React.FCC<EmptyStateProps> = ({
    startDate,
    endDate,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            noDonations: t('overview.noDonations'),
        }),
        [t],
    );

    return (
        <EmptyStateWrapper data-testid="trend-chart-empty-state">
            <EmptyStateImg alt="No donations" src={ChartEmptyState} />
            <TextWrapper data-testid="trend-chart-empty-state-text">
                <EmptyStateText variant="heading2S">
                    {copy.noDonations}
                </EmptyStateText>
                &nbsp;
                <BoldEmptyStateText
                    fontWeight="bold"
                    textAlign="center"
                    variant="heading2S"
                >
                    {formatWithLocaltime(startDate, 'MMM D, YYYY')} -{' '}
                    {formatWithLocaltime(endDate, 'MMM D, YYYY')}
                </BoldEmptyStateText>
            </TextWrapper>
        </EmptyStateWrapper>
    );
};

export const EmptyState = React.memo(EmptyStateComponent);
