import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { GivelifyTheme } from './GivelifyTheme';

type StyledEngineProviderProps = {
    injectFirst?: boolean;
    children: React.ReactChild;
};

export const GivelifyThemeProvider: React.FCC<StyledEngineProviderProps> = ({
    injectFirst,
    children,
}) => {
    return (
        <ThemeProvider theme={GivelifyTheme}>
            <StyledEngineProvider injectFirst={injectFirst}>
                {children}
            </StyledEngineProvider>
        </ThemeProvider>
    );
};
