import {
    requestInit,
    requestStart,
    requestSuccess,
    requestError,
} from '@givelify/utils';
import { Reducer } from 'redux';
import {
    SET_DONATIONS_CURRENT_LOADING,
    SET_DONATIONS_CURRENT_SUCCESS,
    SET_DONATIONS_CURRENT_FAILED,
} from './current/types';
import {
    SET_DONATIONS_PREVIOUS_LOADING,
    SET_DONATIONS_PREVIOUS_SUCCESS,
    SET_DONATIONS_PREVIOUS_FAILED,
} from './previous/types';
import { DonationsState, DonationsActions } from './types';

const initialState = {
    current: requestInit(),
    previous: requestInit(),
};

const DonationsReducer: Reducer<DonationsState, DonationsActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case SET_DONATIONS_CURRENT_LOADING:
            return { ...state, current: requestStart() };
        case SET_DONATIONS_PREVIOUS_LOADING:
            return { ...state, previous: requestStart() };
        case SET_DONATIONS_CURRENT_SUCCESS:
            return { ...state, current: requestSuccess(action.data) };
        case SET_DONATIONS_PREVIOUS_SUCCESS:
            return { ...state, previous: requestSuccess(action.data) };
        case SET_DONATIONS_CURRENT_FAILED:
            return { ...state, current: requestError(action.error) };
        case SET_DONATIONS_PREVIOUS_FAILED:
            return { ...state, previous: requestError(action.error) };
        default:
            return state;
    }
};

export default DonationsReducer;
