import { ErrorResponse } from '@givelify/utils';
import { DonorsTimeFrameData } from '../types';

export const SET_DONORS_PREVIOUS_LOADING = 'SET_DONORS_PREVIOUS_LOADING';
export const SET_DONORS_PREVIOUS_SUCCESS = 'SET_DONORS_PREVIOUS_SUCCESS';
export const SET_DONORS_PREVIOUS_FAILED = 'SET_DONORS_PREVIOUS_FAILED';

export interface SetDonorsPreviousLoading {
    type: typeof SET_DONORS_PREVIOUS_LOADING;
}
export interface SetDonorsPreviousSuccess {
    type: typeof SET_DONORS_PREVIOUS_SUCCESS;
    data: DonorsTimeFrameData;
}
export interface SetDonorsPreviousFailed {
    type: typeof SET_DONORS_PREVIOUS_FAILED;
    error: ErrorResponse;
}
