import React, { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyLabel, GivelifyNotification } from '@givelify/ui';
import {
    BUTTON_IDENTIFIER,
    TrackingProvider,
    useTrackingContext,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { I18N_NAMESPACE } from '../../../../../consts';
import { OnboardingActions } from '../../../OnboardingActions';
import OnboardingPage from '../../../OnboardingPage';
import OnboardingTopSection from '../../../OnboardingTopSection';
import { useDocumentUploadContext } from '../DocumentUploadContext';
import { DocumentType } from '../types';
import { usePreloadImages } from '../UploadDocument/usePreloadImages';
import { ReactComponent as BankStatementSvg } from './icons/bankStatement.svg';
import { ReactComponent as VerificationLetterSvg } from './icons/verificationLetter.svg';
import { ReactComponent as VoidedCheckSvg } from './icons/voidedCheck.svg';
import { Wrapper } from './styles';

const UploadDocumentIcon: React.FCC<{
    type: DocumentType;
    selectedType?: DocumentType;
    onClick: (value: DocumentType) => void;
    trackName: string;
}> = ({ type, onClick, selectedType, trackName }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.checkingAccount.selectDocumentType.${type}`,
    });

    const copy = useMemo(
        () => ({
            title: scopedTranslate(`title`),
            mobileTitle: scopedTranslate(`mobileTitle`),
            title2: scopedTranslate(`title2`),
            mobileTitle2: scopedTranslate(`mobileTitle2`),
            alt: scopedTranslate(`alt`),
            title3:
                type === 'verificationLetter'
                    ? scopedTranslate(`title3`)
                    : undefined,
        }),
        [scopedTranslate, type],
    );

    const title = isMobile ? copy.mobileTitle : copy.title;
    const title2 = isMobile ? copy.mobileTitle2 : copy.title2;
    const title3 = isMobile ? copy.title3 : undefined;

    const IconComponent =
        (type === 'voidedCheck' && VoidedCheckSvg) ||
        (type === 'bankStatement' && BankStatementSvg) ||
        VerificationLetterSvg;

    const selected = type === selectedType;

    const { trackEvent } = useTrackingContext();

    const onClickInternal = () => {
        if (trackName) {
            trackEvent(`<${trackName}>_${BUTTON_IDENTIFIER}`);
        }
        onClick(type);
    };

    return (
        <Wrapper onClick={onClickInternal} selected={selected}>
            <GivelifyLabel
                align="center"
                text={title}
                variant={isMobile ? 'body2' : 'body1'}
            />
            {title2 && (
                <GivelifyLabel
                    align="center"
                    text={title2}
                    variant={isMobile ? 'body2' : 'body1'}
                />
            )}
            {title3 && (
                <GivelifyLabel
                    align="center"
                    text={title3}
                    variant={isMobile ? 'body2' : 'body1'}
                />
            )}
            <div
                style={{
                    marginTop: 12,
                    height: isMobile ? 32 : 54,
                }}
            >
                <IconComponent
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
        </Wrapper>
    );
};

const SelectDocumentType: React.FCC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const {
        onBack,
        selectedDocumentType,
        onSelectDocumentType,
        onCancel,
        onContinue,
        retryMessage,
    } = useDocumentUploadContext();

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.checkingAccount.selectDocumentType`,
    });

    const { header, description, warningMessage } = useMemo(
        () => ({
            header: scopedTranslate('header'),
            description: scopedTranslate('description'),
            warningMessage: scopedTranslate('warningMessage'),
        }),
        [scopedTranslate],
    );

    usePreloadImages();

    return (
        <TrackingProvider
            trackPageVisit
            pageName="SetUpCheckingAcc Select doc type"
        >
            <OnboardingPage
                content={
                    <>
                        <GivelifyLabel text={header} variant={'heading1S'} />
                        <GivelifyLabel
                            style={{
                                marginTop: 12,
                                marginBottom: 24,
                            }}
                            text={description}
                            variant={isMobile ? 'body2' : 'body1'}
                        />
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                gap: 16,
                            }}
                        >
                            <UploadDocumentIcon
                                onClick={onSelectDocumentType}
                                selectedType={selectedDocumentType}
                                trackName="VoidedCheck"
                                type="voidedCheck"
                            />
                            <UploadDocumentIcon
                                onClick={onSelectDocumentType}
                                selectedType={selectedDocumentType}
                                trackName="BankStatement"
                                type="bankStatement"
                            />
                            <UploadDocumentIcon
                                onClick={onSelectDocumentType}
                                selectedType={selectedDocumentType}
                                trackName="VerificationLetter"
                                type="verificationLetter"
                            />
                        </div>
                        <OnboardingActions
                            disableContinue={!selectedDocumentType}
                            onBack={onBack}
                            onCancel={onCancel}
                            onContinue={onContinue}
                        />
                    </>
                }
                header={
                    <OnboardingTopSection>
                        <GivelifyNotification
                            open
                            text={retryMessage || warningMessage}
                            variant="warning"
                        />
                    </OnboardingTopSection>
                }
            />
        </TrackingProvider>
    );
};

export default SelectDocumentType;
