import React, { useMemo } from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { DesktopMenuItemIcon } from '../styles';
import { PendingInvitesContainer } from './style';
import { usePendingInvites } from './usePendingInvites';

type PendingInvitesProps = {
    onAction: () => void;
    isMobile?: boolean;
};

export const PendingInvites: React.FCC<PendingInvitesProps> = ({
    onAction,
    isMobile,
}) => {
    const { navigateToUsers } = useCaptivePortalContext();
    const pendingInvites = usePendingInvites();

    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            pendingInvites: t('userDropdown.pendingInvites'),
            viewInvites: t('userDropdown.viewInvites'),
        }),
        [t],
    );

    const goToInvites = () => {
        navigateToUsers();
        onAction();
    };

    return pendingInvites ? (
        <PendingInvitesContainer
            className={isMobile && 'isMobile'}
            onClick={isMobile ? goToInvites : null}
        >
            <DesktopMenuItemIcon
                className={isMobile && 'isMobile'}
                variant="envelope"
            />
            <GivelifyLabel
                noWrap
                color={
                    isMobile
                        ? DesignTokens.color.textAccentDefault
                        : DesignTokens.color.textSecondary
                }
                style={{ marginRight: 16 }}
                text={`${copy.pendingInvites}: ${pendingInvites}`}
                variant="caption1"
            />
            {!isMobile && (
                <>
                    <GivelifyLink
                        noWrap
                        name="Help View Invite List"
                        onClick={goToInvites}
                        text={copy.viewInvites}
                        variant="caption1"
                    />
                    <DesktopMenuItemIcon
                        style={{
                            color: DesignTokens.color.textAccentDefault,
                        }}
                        variant="right-chevron"
                    />
                </>
            )}
        </PendingInvitesContainer>
    ) : null;
};
