import React, { useCallback, useContext, useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyTable } from '@givelify/givelify-ui';
import {
    MODAL_NAME,
    formatWithTimezone,
    useTrackingContext,
} from '@givelify/utils';
import { Link, makeStyles } from '@material-ui/core';
import { DonorProfileContext } from 'pages/donors/DonorProfileContext';
import { DONOR_PROFILE_PAGE_CLICK_DONATION_AMOUNT } from 'utils/clevertapEvents';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { useAdvancedTranslation } from 'utils/i18n';
import { Donation } from '../..';
import envelopeNameSummary from '../../envelopeNameSummary';
import { DonationDetailsModal } from './DonationDetailsModal';

interface DonationHistoryTableProps {
    donations: Donation[];
    currentPage: number;
    totalItems: number;
    rowsPerPage: number;
    onChangePage: (pageNumber: number) => void;
}

const useStyle = makeStyles({
    link: {
        cursor: 'pointer',
    },
});

const baseTranslation = 'pages.donor_details';

export const DonorDonationHistoryTable: React.FCC<
    DonationHistoryTableProps
> = ({ donations, currentPage, totalItems, rowsPerPage, onChangePage }) => {
    const { trackEvent, trackOpenCloseEvent } = useTrackingContext();
    const classes = useStyle({});
    const { t, at } = useAdvancedTranslation();

    const { donor } = useContext(DonorProfileContext);

    if (!donations) {
        donations = [];
    }

    const copy = useMemo(
        () => ({
            date: t(`${baseTranslation}.date`),
            envelopeFund: at(`${baseTranslation}.envelope_fund`),
            amount: t(`${baseTranslation}.amount`),
            noRecords: t(`${baseTranslation}.no_records`),
        }),
        [t, at],
    );

    const [open, setOpen] = useState(false);
    const [modalDonation, setModalDonation] = useState<Donation>();
    const donationRowLinkClick = useCallback(
        (row: Donation) => {
            trackEvent(DONOR_PROFILE_PAGE_CLICK_DONATION_AMOUNT);
            setModalDonation(row);
            setOpen(true);
            trackOpenCloseEvent(true, MODAL_NAME.DonationDetails);
        },
        [trackEvent, trackOpenCloseEvent],
    );

    const detailsModalClose = useCallback(() => {
        trackOpenCloseEvent(false, MODAL_NAME.DonationDetails);
        setOpen(false);
    }, [trackOpenCloseEvent]);

    return (
        <>
            <GivelifyTable
                columns={[
                    {
                        name: 'createdAt',
                        label: copy.date,
                        // eslint-disable-next-line react/display-name
                        renderCell: ({ cellValue, row }) => (
                            <GivelifyLabel
                                id={`donation-date-${row.receiptNumber}`}
                                text={formatWithTimezone(
                                    cellValue,
                                    'MMM. D, YYYY',
                                )}
                                variant="body2"
                            />
                        ),
                    },
                    {
                        name: 'envelopes',
                        label: copy.envelopeFund,
                        // eslint-disable-next-line react/display-name
                        renderCell: ({ row }) => (
                            <GivelifyLabel
                                text={envelopeNameSummary(row.envelopes)}
                                variant="body2"
                            />
                        ),
                    },
                    {
                        name: 'amount',
                        label: copy.amount,
                        // eslint-disable-next-line react/display-name
                        renderCell: ({ cellValue, row }) => {
                            return (
                                <Link
                                    className={classes.link}
                                    id={`donation-receipt-${row.receiptNumber}`}
                                    onClick={() => donationRowLinkClick(row)}
                                >
                                    ${formatMoneyWithCommas(cellValue)}
                                </Link>
                            );
                        },
                    },
                ]}
                data={donations}
                keySelector={(i) => i.id}
                pagination={{
                    currentPage,
                    onChangePage,
                    totalItems,
                    rowsPerPage,
                }}
            />
            <DonationDetailsModal
                donation={modalDonation}
                donor={donor}
                onClose={detailsModalClose}
                open={open}
            />
        </>
    );
};
