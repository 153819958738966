import React, { useMemo } from 'react';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { IntegrationTypes } from '../../types';
import DonorMatching from './DonorMatching';
import EnvelopeMatching from './EnvelopeMatching';
import IntegrationSettings from './IntegrationSettings';
import IntegrationReports from './Reports';

interface CommonTabsContentProps {
    currentTab: number;
    integration: IntegrationTypes;
    integrationNoEnvelope?: boolean;
    integrationNoDonor: boolean;
    goToFirstTab: () => void;
    buildCustomReportClick: () => void;
}

const CommonTabsContent: React.FCC<CommonTabsContentProps> = ({
    integrationNoDonor,
    integrationNoEnvelope,
    currentTab,
    integration,
    goToFirstTab,
    buildCustomReportClick,
}) => {
    const envelopeTabNumber = useMemo(
        () => (integrationNoDonor ? 2 : 1),
        [integrationNoDonor],
    );
    const settingTabNumber = useMemo(() => {
        if (!integrationNoDonor || integrationNoEnvelope) {
            return 2;
        }
        return 3;
    }, [integrationNoDonor, integrationNoEnvelope]);

    return (
        <>
            {currentTab === 0 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsHomeTab}
                >
                    <IntegrationReports
                        buildCustomReportClick={buildCustomReportClick}
                    />
                </TrackingProvider>
            )}
            {integrationNoDonor && currentTab === 1 && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsDonorsTab}
                >
                    <DonorMatching doneMatch={goToFirstTab} />
                </TrackingProvider>
            )}

            {!integrationNoEnvelope && currentTab === envelopeTabNumber && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsEnvelopesTab}
                >
                    <EnvelopeMatching doneMatch={goToFirstTab} />
                </TrackingProvider>
            )}

            {currentTab === settingTabNumber && (
                <TrackingProvider
                    trackPageVisit
                    pageName={PAGE_NAME.IntegrationsSettingsTab}
                >
                    <IntegrationSettings
                        integration={integration}
                        savedSetting={goToFirstTab}
                    />
                </TrackingProvider>
            )}
        </>
    );
};

export default CommonTabsContent;
