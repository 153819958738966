import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ExpansionPanel as MUIExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    makeStyles,
} from '@material-ui/core';

const blueColor = '#cce2ef';
const useStyles = makeStyles((theme) => ({
    expansionPanel: {
        borderBottom: '2px solid ' + blueColor,
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
        },
        '& .MuiExpansionPanelSummary-root': {
            padding: theme.spacing(0, 4),
            '&.Mui-expanded': {
                minHeight: 0,
            },
            '& .MuiExpansionPanelSummary-content': {
                '&.Mui-expanded': {
                    margin: '12px 0',
                },
            },
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiExpansionPanelDetails-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '14px',
        },
    },
    expansionTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
}));

interface ExtensionPanelProps {
    label: string;
}

const ExtensionPanel: React.FCC<ExtensionPanelProps> = ({
    label,
    children,
}) => {
    const classes = useStyles();

    return (
        <MUIExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
            >
                <div className={classes.expansionTitle}>{label}</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
        </MUIExpansionPanel>
    );
};

export default ExtensionPanel;
