import React, { useMemo } from 'react';
import { GivelifyModal } from '@givelify/givelify-ui';
import { DialogProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DonationRow } from '../../../../api/models';
import { Donor } from '../../../../types/donorTypes';
import DonationItemRow, {
    DonationItemRowProps,
} from '../../../donations/DonationActivity/DonationActivityInfiniteLoader/DonationActivityRow';

interface DonationDetailsModalProps extends DialogProps {
    donation: DonationRow;
    donor: Donor;
    onClose: (
        event?: unknown,
        reason?: 'backdropClick' | 'escapeKeyDown',
    ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        [theme.breakpoints.down('md')]: {
            marginLeft: 20,
            marginRight: 20,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 52,
            marginRight: 52,
            marginBottom: 40,
        },
    },
}));

const DonationDetailsModalComponent: React.FCC<DonationDetailsModalProps> = ({
    donation,
    donor,
    onClose,
    open,
}) => {
    const classes = useStyles();

    const itemRowProps: DonationItemRowProps = useMemo(() => {
        if (!donation) return null;
        return {
            id: donation.id,
            name: donor.name,
            isRefund: donation.isRefund,
            refundDate: donation.refundDate,
            status: donation.status,
            latestStatusAt: donation.latestStatusAt,
            receiptNumber: donation.receiptNumber,
            location: `${donor.city}, ${donor.state}`,
            date: donation.createdAt,
            donation: donation.donationTotal,
            doneeName: donation.doneeName,
            donorAvatar: donor.picture,
            fees: donation.fees,
            donorId: donor.id,
            doneeAvatar: undefined,
            donationNoteId: undefined,
            messages: [],
            envelopes: donation.envelopes.map((e) => ({
                amount: e.amount,
                name: e.name,
                id: Number(e.id),
                offeringId: e.offeringId,
                doneeId: e.doneeId,
                externalId: e.externalId,
            })),
            donationFees: donation.donationFees,
        };
    }, [donation, donor]);

    const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onClose();
        window.scrollTo(0, 0);
    };

    if (!donation) return null;

    return (
        <GivelifyModal
            autoFullscreen
            showCloseButton
            autoFullscreenBreakpoint="sm"
            onClose={onClose}
            open={open}
            size="xlarge"
        >
            <div className={classes.wrapper}>
                <DonationItemRow
                    {...itemRowProps}
                    hideExpandButton
                    openInitial
                    onLinkClick={onLinkClick}
                />
            </div>
        </GivelifyModal>
    );
};

export const DonationDetailsModal = React.memo(DonationDetailsModalComponent);
