import React, { useState } from 'react';
import { GivelifyModal } from '@givelify/givelify-ui';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../../assets/integration-icons/times.svg';
import F1IntegrationSteps from '../../components/IntegrationSetup/F1IntegrationSteps';
import F1LoginContent from '../../components/IntegrationSetup/F1LoginContent';
import { IntegrationTypes } from '../../types';
import { useF1Styles } from './styles';

interface F1IntegrationSetupDialogProps {
    open: boolean;
    handleClose: () => void;
    type?: IntegrationTypes;
    setShowBanner: () => void;
}

const F1IntegrationSetupDialog: React.FCC<F1IntegrationSetupDialogProps> = ({
    open,
    handleClose,
    setShowBanner,
}) => {
    const [openLogin, setOpenLogin] = useState<boolean>(false);
    const classes = useF1Styles();
    const doneCallBack = () => {
        setShowBanner();
    };
    const { t: trans } = useTranslation();
    const t = (key, d?) =>
        trans(`pages.integrations.content.setup-dialog.${key}`, d || '');
    const copy = {
        title: t('f1IntegrationSetup.title'),
    };

    const switchToLogin = () => {
        setOpenLogin(true);
    };

    return (
        <GivelifyModal
            aria-labelledby="form-dialog-title"
            name="f1IntegrationSetupModal"
            onClose={handleClose}
            open={open}
            size="xlarge"
        >
            <div className={classes.f1Dialog}>
                <div className={classes.f1CloseDialog}>
                    <IconButton onClick={handleClose}>
                        <img alt="Close" src={closeIcon} title="Close" />
                    </IconButton>
                </div>
                <div className={classes.f1Title}>
                    <Typography variant="h2">{copy.title}</Typography>
                </div>
                {!openLogin && (
                    <F1IntegrationSteps switchToLogin={switchToLogin} />
                )}
                {openLogin && <F1LoginContent doneCallBack={doneCallBack} />}
            </div>
        </GivelifyModal>
    );
};

export default F1IntegrationSetupDialog;
