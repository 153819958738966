import React, { useMemo, useContext } from 'react';
import { ApiHandler, MESSAGE_FILTER_VALUE } from '@givelify/api';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyInfiniteScroll } from '@givelify/ui';
import { getDateRangeHash, TimeFrameValue } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Context } from '../../../../layout/MainLayoutContext';
import NoResultsScreen from '../../components/NoResultsScreen';
import RefundRowLoading from './RefundRowLoading';
import RefundsList from './RefundsList';
import useStyles from './styles';

type DonationsRowWrapperProps = {
    doneeId: number;
    timeFrame: TimeFrameValue;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    envelopesLoading?: boolean;
};

export const RefundsInfiniteLoader: React.FCC<DonationsRowWrapperProps> = (
    { doneeId, timeFrame, envelopeIds, messageFilter, envelopesLoading },
    props,
) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { mainHolderDivRef } = useContext(Context);
    const { t } = useTranslation();
    const classes = useStyles(props);
    const DonationRowText = useMemo(
        () => ({
            detail: t('bankDeposits.text.detail'),
            activityError: t('error.errorDonationsActivity'),
            refundsError: t('error.errorRefunds'),
            noActivity: t('error.noDonationsActivity'),
            noRefunds: t('error.noRefunds'),
        }),
        [t],
    );
    const Error = (
        <GivelifyLabel
            bold
            marginTop={16}
            text={DonationRowText.refundsError}
            variant="heading5"
        />
    );
    const Wrapper = (Component: JSX.Element) => (
        <div className={classes.borderTop}>{React.cloneElement(Component)}</div>
    );
    const dateRangeHash = getDateRangeHash(timeFrame);
    const { data, error, fetchNextPage, hasNextPage, isFetching } =
        useInfiniteQuery(
            [
                ApiHandler.instance.donees.getRefundedDonations.name,
                doneeId,
                dateRangeHash,
                envelopeIds,
                messageFilter,
            ],
            ({ pageParam = 1 }) =>
                ApiHandler.instance.donees.getRefundedDonations(
                    doneeId,
                    timeFrame,
                    envelopeIds,
                    pageParam,
                    messageFilter,
                ),
            {
                enabled: !envelopesLoading,
                getNextPageParam: (lastPage) => {
                    if (lastPage === undefined) return 1;
                    if (lastPage.success === false) return undefined;

                    const { pagination } = lastPage.response;
                    return pagination &&
                        pagination.currentPage < pagination.totalPages
                        ? pagination.currentPage + 1
                        : undefined;
                },
            },
        );
    const loadMore = React.useCallback(() => {
        if (!isFetching) {
            fetchNextPage();
        }
    }, [fetchNextPage, isFetching]);
    const empty =
        !data ||
        data.pages.length === 0 ||
        (data.pages[0].success && data.pages[0].response.data.length === 0);
    const loading =
        envelopesLoading || (isFetching && (!data || data.pages.length === 0));
    const infiniteScrollProps =
        isMobile && mainHolderDivRef
            ? {
                  getScrollParent: () => mainHolderDivRef.current,
                  useWindow: false,
              }
            : {};
    return (
        <GivelifyInfiniteScroll
            empty={empty}
            error={!!error}
            errorComponent={Wrapper(Error)}
            hasMore={hasNextPage}
            loadMore={loadMore}
            loading={loading}
            loadingComponent={<RefundRowLoading />}
            zerothComponent={<NoResultsScreen pageName="refund" />}
            {...infiniteScrollProps}
        >
            {data && data.pages ? (
                <RefundsList
                    data={data.pages.map((x) =>
                        x.success ? x.response : null,
                    )}
                />
            ) : null}
        </GivelifyInfiniteScroll>
    );
};
