import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IntegrationSetup, {
    IntegrationSetupProps,
} from '../../../components/IntegrationSetup';

const useStyles = makeStyles((theme: Theme) => ({
    editLink: {
        padding: 0,
        fontWeight: 400,
    },
    settingTab: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
}));

const IntegrationSettings: React.FCC<IntegrationSetupProps> = (props) => {
    const { settingTab } = useStyles();

    return (
        <div className={settingTab}>
            <IntegrationSetup {...props} />
        </div>
    );
};

export default IntegrationSettings;
