import { DesignTokens } from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const QuickGiveButtonBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    borderRadius: DesignTokens.measurement.borderRadiusL,
    boxShadow: DesignTokens.shadow.shadowHigh,
    padding: theme.spacing(2, 0),
}));

export const QuickGiveOptionsContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'active',
})<{ disabled?: boolean; active?: boolean }>(({ theme, disabled, active }) => ({
    padding: theme.spacing(3),
    borderRadius: DesignTokens.measurement.borderRadiusM,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: active
        ? theme.palette.primary.main
        : DesignTokens.border.borderComponentDefault.color,
    background: DesignTokens.color.backgroundSecondary,
    '&:hover': {
        borderColor: disabled ? undefined : theme.palette.primary.main,
        cursor: disabled ? undefined : 'pointer',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(3, 2),
    },
}));

export const HideOnMobile = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));
