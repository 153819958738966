import React, { useMemo } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { Grid } from '@material-ui/core';
import { Hidden } from '@mui/material';
import MobileSelector from 'components/MobileSelector';
import { TableColumnProps } from 'components/Tables/TableColumnTitle';
import DonorSortMobile from 'pages/donors/components/mobileFilters/DonorSortMobile';
import { CampuseItem } from '../../../EnvelopeMatching/types';
import MobileFilterSelect from '../MobileFilterSelect';
import { MatchOption, MatchOptionValue } from '../types';

interface SmControlsProps {
    reportId: number;
    columns?: TableColumnProps[];
    onSortingChange?: (sorting: Sorting[]) => void;
    sortDirection?: SortingDirection;
    sortColumn?: string;
    matchFilter: MatchOptionValue;
    onMatchFilterChange: (value: MatchOptionValue) => void;
    matchOptions: MatchOption[];
    matchFilterHeaderText: string;
    campusesOptions?: CampuseItem[];
    campusesFilter?: CampuseItem;
    onCampusesFilterChange: (value: CampuseItem) => void;
    campusFilterHeaderText: string;
    hasCampuses?: boolean;
}

const MobileControls: React.FCC<SmControlsProps> = ({
    reportId,
    columns,
    onSortingChange,
    sortColumn,
    sortDirection,
    matchFilter,
    onMatchFilterChange,
    matchOptions,
    matchFilterHeaderText,
    campusesFilter,
    campusesOptions,
    onCampusesFilterChange,
    campusFilterHeaderText,
    hasCampuses,
}) => {
    const canSort = useMemo(() => columns?.find((c) => c.sortable), [columns]);

    return (
        <Hidden mdUp>
            {canSort && (
                <Grid item md>
                    <DonorSortMobile
                        onChange={onSortingChange}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        tableColumns={columns}
                    />
                </Grid>
            )}
            {!reportId && (
                <Grid item>
                    <MobileFilterSelect
                        headerText={matchFilterHeaderText}
                        onChange={(selectedValue) => {
                            onMatchFilterChange(
                                selectedValue.value as MatchOptionValue,
                            );
                        }}
                        options={matchOptions}
                        value={matchFilter}
                    />
                </Grid>
            )}
            {!reportId && hasCampuses && (
                <MobileSelector
                    headerText={campusFilterHeaderText}
                    onSelect={(selectedItems) => {
                        const selectedCampusId = Number(selectedItems[0].value);
                        const selectedCampus = campusesOptions.find(
                            (co) => co.id === selectedCampusId,
                        );
                        onCampusesFilterChange(selectedCampus);
                    }}
                    properties={[
                        {
                            value: {
                                value: campusesFilter.id.toString(),
                                label: campusesFilter.name,
                            },
                            options: campusesOptions.map((co) => ({
                                value: co.id.toString(),
                                label: co.name,
                                description: co.address,
                            })),
                        },
                    ]}
                />
            )}
        </Hidden>
    );
};

export default MobileControls;
