import React, { useState } from 'react';
import { IntegrationTypes } from '../../../types';
import { Screen1 } from './Screen1';
import { Screen2 } from './Screen2';

type CCBContentProps = {
    //eslint-disable-next-line
    t: (key: any, d?: any) => string;
    type: IntegrationTypes;
    doneCallback: () => void;
};

export const CCBContent: React.FCC<CCBContentProps> = ({
    t,
    type,
    doneCallback,
}) => {
    const [currentScreen, setCurrentScreen] = useState<1 | 2>(1);

    return currentScreen === 1 ? (
        <Screen1 onSubmit={() => setCurrentScreen(2)} t={t} type={type} />
    ) : (
        <Screen2 doneCallBack={doneCallback} t={t} type={type} />
    );
};
