import React from 'react';
import { Divider, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SocialMediaName } from '../types';
import {
    CLICK_FB_GIVING_CARD,
    CLICK_IG_GIVING_CARD,
    CLICK_TW_GIVING_CARD,
    CLICK_YT_GIVING_CARD,
} from '../utils/cleverTapEvents';
import { pushEvent } from '../utils/cleverTapUtils';
import Label from './Label';

interface SocialMediaCardProps {
    title: string;
    description: string;
    actionUrl: string;
    assetName: SocialMediaName;
}
const useStyles = makeStyles((_: Theme) => ({
    container: {
        position: 'relative',
        padding: '24px 16px 72px 16px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
        borderRadius: '10px',
        transition: 'background .5s cubic-bezier(0.165, 0.84, 0.44, 1)',
        '&:hover': {
            background: '#F6F6F6',
            cursor: 'pointer',

            '& $learnMore': {
                textDecoration: 'underline',
                color: '#5F48F0',
            },
        },
        height: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    learnMore: {
        position: 'absolute',
        bottom: '24px',
        left: '16px',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '16px',
        color: '#373739',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    content: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#676E77',
    },
    icon: {
        width: 32,
        height: 32,
        marginRight: '16px',
    },
    divider: {
        margin: '24px -16px 16px -16px',
    },
}));

const CLEVERTAP_EVENTS: { [x in SocialMediaName]: string } = {
    facebook: CLICK_FB_GIVING_CARD,
    twitter: CLICK_TW_GIVING_CARD,
    instagram: CLICK_IG_GIVING_CARD,
    youtube: CLICK_YT_GIVING_CARD,
};

const SocialMediaCard: React.FCC<SocialMediaCardProps> = ({
    actionUrl,
    title,
    description,
    assetName,
}: SocialMediaCardProps) => {
    const classes = useStyles();
    const handleLinkClick = React.useCallback(() => {
        pushEvent(CLEVERTAP_EVENTS[assetName]);
    }, [assetName]);

    return (
        <Link
            className={classes.link}
            data-testid={`social-media-card-${title}`}
            to={actionUrl}
        >
            <div className={classes.container}>
                <div className={classes.header} onClick={handleLinkClick}>
                    <img
                        alt={title}
                        className={classes.icon}
                        src={`/social-media/${assetName}.svg`}
                    />
                    <div
                        className={classes.title}
                        data-testid={`social-media-card-title-${title}`}
                    >
                        {title}
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div
                    className={classes.content}
                    data-testid={`social-media-card-description-${title}`}
                >
                    {description}
                </div>
                <div className={classes.learnMore}>
                    <Label color="primary-blue" variant="body2">
                        Learn more
                    </Label>
                </div>
            </div>
        </Link>
    );
};

export default React.memo(SocialMediaCard);
