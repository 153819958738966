import React from 'react';
import {
    CloseOutlinedIcon,
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    UndoIcon,
} from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import {
    ClearFilterButton,
    CloseButton,
    CloseButtonText,
    DividerLine,
    FilterBody,
    FilterBottom,
    FilterHeader,
    UndoButton,
    UndoButtonText,
} from './styles';

type FilterContentProps = {
    onClose: () => void;
    onUndo: () => void;
    onClear: () => void;
    onSubmit: () => void;
    submitText?: string;
    children?: React.ReactNode;
    canSubmit?: boolean;
    canClear?: boolean;
    canUndo?: boolean;
};

export const FilterContent: React.FCC<FilterContentProps> = ({
    onClear,
    onClose,
    onUndo,
    onSubmit,
    submitText,
    children,
    canSubmit,
    canClear,
    canUndo,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            filters: t('filters.filters'),
            undo: t('filters.undo'),
            close: t('filters.close'),
            clearFilters: t('filters.clear'),
            viewDonors: t('filters.viewDonors'),
        }),
        [t],
    );
    return (
        <>
            <FilterHeader>
                <GivelifyLabel
                    color={DesignTokens.color.textWhite}
                    variant="heading1S"
                >
                    {copy.filters}
                </GivelifyLabel>
                <DividerLine flexItem orientation="vertical" variant="middle" />
                <UndoButton
                    disableRipple
                    data-testid="undo-button"
                    disabled={!canUndo}
                    name="Undo filter"
                    onClick={onUndo}
                    variant="icon"
                >
                    <UndoButtonText
                        alignItems="center"
                        color={
                            canUndo
                                ? DesignTokens.color.textWhite
                                : DesignTokens.color.textSecondary
                        }
                        display="flex"
                        gap="4px"
                    >
                        <UndoIcon
                            sx={{
                                width: 24,
                                height: 24,
                            }}
                        />
                        {copy.undo}
                    </UndoButtonText>
                </UndoButton>
                <CloseButton
                    disableRipple
                    data-testid="close-button"
                    name="Close filter"
                    onClick={onClose}
                    variant="icon"
                >
                    <CloseButtonText
                        alignItems="center"
                        color={DesignTokens.color.textWhite}
                        display="flex"
                        gap="4px"
                    >
                        {copy.close}
                        <CloseOutlinedIcon
                            sx={{
                                width: 24,
                                height: 24,
                            }}
                        />
                    </CloseButtonText>
                </CloseButton>
            </FilterHeader>
            <FilterBody>{children}</FilterBody>
            <FilterBottom>
                <GivelifyButton
                    isNarrow
                    data-testid="view-results-btn"
                    disabled={!canSubmit}
                    onClick={onSubmit}
                    text={submitText ? submitText : copy.viewDonors}
                    type="submit"
                    variant="primary"
                />
                <ClearFilterButton
                    isNarrow
                    data-testid="clear-filters-btn"
                    disabled={!canClear}
                    name="Clear filters"
                    onClick={onClear}
                    text={copy.clearFilters}
                    variant="ghost"
                />
            </FilterBottom>
        </>
    );
};
