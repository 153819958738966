import React, { useState, useEffect, useMemo } from 'react';
import {
    ApiHandler,
    LoginResponse,
    mapSignedUserToLocalUser,
} from '@givelify/api';
import { emailRegex } from '@givelify/givelify-ui';
import {
    GivelifyButton,
    GivelifyCheckbox,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import {
    Logger,
    useApiRequest,
    onLogin,
    useTrackingContext,
} from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { SignInRequest } from 'api/requests/auth';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUserEmailLocalStorage } from 'store/user/accessTokenInLocalStorage';
import {
    LOGIN_PAGE_CLICK_HELP,
    LOGIN_PAGE_CLICK_SHOW_PASS,
    LOGIN_PAGE_CLICK_SIGN_IN,
    LOGIN_PAGE_CLICK_SIGN_UP,
    LOGIN_PAGE_VIEW_NOT_VERIFIED,
} from 'utils/clevertapEvents';
import { useRecaptcha } from 'utils/useRecaptcha';
import { webConfig } from 'webConfig';
import { object, string } from 'yup';
import { setUser, setAccessToken } from '../../store/user/actions';
import { TextInput } from '../inputs/TextInput';

const useStyle = makeStyles((theme: Theme) => ({
    error: {
        marginBottom: theme.spacing(1),
    },
    form: {
        [theme.breakpoints.up('md')]: {
            width: 368,
        },
    },
    actionRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'right',
        paddingRight: 1,
        marginBottom: 33,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24,
            marginTop: -9,
        },
        '@media (max-width:320px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: 8,
        },
    },
    checkboxContent: {
        display: 'flex',
        alignItems: 'center',
    },
    checkbox: {
        margin: '0px 12px',
    },
    textField: {
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
        },
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            height: 36,
        },
    },
    createAccountRow: {
        textAlign: 'center',
        marginTop: 45,
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
        },
    },
    inlineBlock: {
        display: 'inline-block',
    },
    forgotPassword: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
    },
    signIn: {
        fontWeight: 800,
        fontSize: '16px',
        lineHeight: '20px',
    },
    signUp: {
        display: 'inline-block',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
    },
    list: {
        paddingLeft: '24px',
        fontWeight: 600,
        marginTop: 0,
        '& li': {
            color: '#ef5353',
            textAlign: 'left',
        },
    },
    visitSupport: {
        textDecoration: 'underline',
        padding: 0,
        display: 'inline-block',
        fontWeight: 800,
        fontSize: '14px',
        lineHeight: '24px',
        borderRadius: 0,
    },
}));

interface LoginFormProps {
    handleResetPasswordOpen?: () => void;
}

const NotVerifiedWarning = () => {
    const { trackEvent } = useTrackingContext();
    const classes = useStyle();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            toSignIn: t('login.notVerified.toSignIn'),
            checkEmail: t('login.notVerified.checkEmail'),
            click: t('login.notVerified.click'),
            help: t('login.notVerified.help'),
            link: t('login.notVerified.link'),
        }),
        [t],
    );

    const handleHelpClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_HELP);
        window.open(webConfig.supportUrl, '_blank');
    };

    return (
        <>
            <GivelifyLabel
                color="error200"
                text={copy.toSignIn}
                variant="body2"
            />
            <ul className={classes.list}>
                <li>{copy.checkEmail}</li>
                <li>{copy.click}</li>
                <li>
                    {copy.help}{' '}
                    <GivelifyButton
                        className={classes.visitSupport}
                        onClick={handleHelpClick}
                        text={copy.link}
                        variant="ghost-alt"
                    />
                </li>
            </ul>
        </>
    );
};

const LoginForm: React.FCC<LoginFormProps> = (props) => {
    const { trackEvent } = useTrackingContext();
    const classes = useStyle();
    const dispatch = useDispatch();
    const [loginApi, makeLoginRequest] = useApiRequest<LoginResponse>();
    const [formError, setFormError] = useState<string>('');
    const [showNotVerified, setShowNotVerified] = useState<boolean>(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const { t } = useTranslation();
    const { getRecaptchaToken } = useRecaptcha('signin');

    const loginFormText = useMemo(
        () => ({
            text: {
                welcome: t('login.text.welcome'),
                enterPassword: t('login.text.enter_password'),
                requiredEmail: t('login.text.required_email'),
                invalidEmail: t('login.text.invalid_email'),
                requiredPassword: t('login.text.required_password'),
                showPassword: t('login.text.showPassword'),
                newToGivelify: t('login.text.newToGivelify'),
            },
            link: {
                anotherUser: t('login.link.another_user'),
                removeAccount: t('login.link.remove_account'),
                forgotPassword: t('login.link.forgot_password'),
                signUp: t('login.title.signUp'),
            },
            title: {
                email: t('login.title.email'),
                password: t('login.title.password'),
                passwordHolder: t('login.title.password_holder'),
                login: t('login.title.login'),
                signIn: t('login.title.signIn'),
            },
            notRobot: t('labels.notRobot'),
            notVerified: {
                toSignIn: t('login.notVerified.toSignIn'),
                checkEmail: t('login.notVerified.checkEmail'),
                click: t('login.notVerified.click'),
                help: t('login.notVerified.help'),
                link: t('login.notVerified.link'),
            },
        }),
        [t],
    );

    const form = useForm<SignInRequest>({
        mode: 'onChange',
        resolver: yupResolver(
            object({
                username: string()
                    .required(loginFormText.text.requiredEmail)
                    .matches(emailRegex, loginFormText.text.invalidEmail),
                password: string().required(
                    loginFormText.text.requiredPassword,
                ),
            }),
        ),
        defaultValues: {
            username: getUserEmailLocalStorage(),
        },
    });

    const handleSubmit = async (value: SignInRequest) => {
        trackEvent(LOGIN_PAGE_CLICK_SIGN_IN);
        const token = await getRecaptchaToken();
        if (token) {
            makeLoginRequest(
                ApiHandler.instance.auth.login({
                    ...value,
                    captchaToken: token,
                }),
            );
        } else {
            setFormError(loginFormText.notRobot);
        }
    };

    const handleSignUpClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_SIGN_UP);
        window.open(webConfig.signupUrl, '_blank');
    };

    useEffect(() => {
        if (loginApi.type === 'REQUEST_START') {
            setShowNotVerified(false);
        }
        if (loginApi.type === 'REQUEST_ERROR') {
            if (loginApi.error.status === 412) {
                // not verified
                setFormError(null);
                setShowNotVerified(true);
                trackEvent(LOGIN_PAGE_VIEW_NOT_VERIFIED, null, 'view');
            } else {
                setFormError(loginApi.error.message);
            }
        }
        if (loginApi.type === 'REQUEST_SUCCESS') {
            Logger.log(
                `User ${loginApi.response.user.id} logged in successfully`,
                {
                    id: loginApi.response.user.id,
                    doneeId: loginApi.response.user.doneeId,
                },
                Logger.LogLevel.INFO,
            );
            const user = mapSignedUserToLocalUser(loginApi.response.user);
            dispatch(setUser(user));
            dispatch(setAccessToken(loginApi.response.accessToken));
            onLogin(user, webConfig.appVersion);
            Logger.configureUserData(user.doneeId, user.id);
        }
    }, [loginApi, dispatch, trackEvent]);

    const {
        formState: { isValid },
    } = form;

    const handlePasswordVisibility = () => {
        trackEvent(LOGIN_PAGE_CLICK_SHOW_PASS);
        setPasswordVisibility((val) => !val);
    };
    const submitForm = form.handleSubmit(handleSubmit);
    return (
        <form className={classes.form} onSubmit={submitForm}>
            <Typography
                className={classes.error}
                color="error"
                id="error-details"
            >
                {formError}
            </Typography>
            {showNotVerified && <NotVerifiedWarning />}
            <TextInput<SignInRequest>
                ariaLabel="Username input"
                autoFocus={true}
                boxSize="large"
                className={classes.textField}
                defaultValue=""
                formRef={form}
                id="username-input"
                label={
                    form.getValues().username ? loginFormText.title.email : ''
                }
                name="username"
                onEnterSubmit={() => submitForm()}
                placeholder={loginFormText.title.email}
                type="text"
                variant="alt"
            />
            <TextInput<SignInRequest>
                hidePasswordAdornment
                ariaLabel="Password input"
                autoComplete="on"
                boxSize="large"
                className={classes.textField}
                defaultValue=""
                formRef={form}
                id="password-input"
                label={
                    form.getValues().password
                        ? loginFormText.title.password
                        : ''
                }
                name="password"
                onEnterSubmit={() => submitForm()}
                placeholder={loginFormText.title.passwordHolder}
                showPassword={passwordVisibility}
                variant="alt"
            />
            <div className={classes.actionRow}>
                <div className={classes.checkboxContent}>
                    <GivelifyCheckbox
                        ariaLabel="login-show-password-checkbox"
                        checked={passwordVisibility}
                        className={classes.checkbox}
                        name="login-show-password-checkbox"
                        onChange={handlePasswordVisibility}
                    />
                    <GivelifyLabel
                        bold
                        color="neutralGrey"
                        text={loginFormText.text.showPassword}
                        variant="body3"
                    />
                </div>
                <GivelifyButton
                    className={classes.forgotPassword}
                    onClick={props.handleResetPasswordOpen}
                    text={loginFormText.link.forgotPassword}
                    variant="ghost-alt"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 24,
                }}
            ></div>
            <GivelifyButton
                className={classes.signIn}
                disabled={!isValid}
                isLoading={
                    loginApi.type === 'REQUEST_START' ||
                    loginApi.type === 'REQUEST_SUCCESS'
                }
                size="xLarge"
                text={loginFormText.title.signIn}
                type="submit"
                variant="primary-alt"
                width="100%"
            />
            <div className={classes.createAccountRow}>
                <GivelifyLabel
                    className={classes.inlineBlock}
                    fontWeight={600}
                    text={loginFormText.text.newToGivelify}
                    variant="body1"
                />
                <GivelifyButton
                    className={classes.signUp}
                    onClick={handleSignUpClick}
                    text={`${loginFormText.link.signUp}.`}
                    variant="ghost-alt"
                />
            </div>
        </form>
    );
};

export default LoginForm;
