import React from 'react';
import { DirectDepositInfo } from '../../@types/assets/onboarding';
import DirectDepositMain from '../components/DirectDepositMain';
import { DirectDepositManualEntryModal } from './DirectDepositManualEntryModal';

export interface BankingSettingsProps {
    doneeId: number;
    bankingInfo: DirectDepositInfo;
    onChange?: (data: DirectDepositInfo) => void;
    directDepositClassName?: string;
    isLoading?: boolean;
    hasError?: boolean;
    error?: string;
    showYodlee?: boolean;
    setButtonBringToFront: (value: boolean) => void;
}

export const BankingSettings: React.FunctionComponent<BankingSettingsProps> = (
    props,
) => {
    const {
        doneeId,
        bankingInfo,
        onChange,
        directDepositClassName,
        isLoading,
        hasError,
        error,
        showYodlee = true,
        setButtonBringToFront
    } = props;
    const [data, setData] = React.useState(bankingInfo);
    const [openManual, setOpenManual] = React.useState(!showYodlee);
    const openManualEntry = React.useCallback(() => {
        setOpenManual(true);
    }, [setOpenManual]);
    const handleYodleeSubmit = React.useCallback(
        (accountNumber: number, routingNumber: number) => {
            setData({ ...bankingInfo, accountNumber, routingNumber });
            openManualEntry();
        },
        [bankingInfo, setData, openManualEntry],
    );
    const handleManualSubmit = React.useCallback(
        (data: DirectDepositInfo) => {
            if (onChange) {
                onChange({ ...data, status: 'in_progress' });
            }
        },
        [onChange],
    );
    const handleManualClose = React.useCallback(() => {
        setOpenManual(false);
    }, [setOpenManual]);

    if (openManual) {
        return (
            <DirectDepositManualEntryModal
                data={data}
                error={error}
                hasError={hasError}
                isLoading={isLoading}
                isOpen={true}
                onCancel={handleManualClose}
                onClose={handleManualClose}
                onSubmit={handleManualSubmit}
                setButtonBringToFront={setButtonBringToFront}
            />
        );
    }
    return (
        <DirectDepositMain
            className={directDepositClassName}
            doneeId={doneeId}
            onSubmit={handleYodleeSubmit}
            openManualEntry={openManualEntry}
        />
    );
};
