import * as React from 'react';
import { styled, Tooltip, tooltipClasses } from '@mui/material';
import { DesignTokens } from '../specify';
import { GivelifyTooltipProps } from './types';

const GivelifyTooltipView: React.FCC<GivelifyTooltipProps> = ({
    className,
    children,
    variant = 'small',
    ...props
}) => {
    return (
        <Tooltip {...props} classes={{ popper: className }}>
            {children}
        </Tooltip>
    );
};
const StyledTooltip = styled(GivelifyTooltipView)(({ variant }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: DesignTokens.color.backgroundComponentToolTip,
        color: DesignTokens.color.textWhite,
        padding: variant === 'large' ? 16 : 8,
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
        fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
        lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
        borderRadius: DesignTokens.measurement.borderRadiusXs,
        width: variant === 'large' ? 240 : 'auto',
    },
    [`& .${tooltipClasses.arrow}`]: {
        [`&::before`]: {
            backgroundColor: DesignTokens.color.backgroundComponentToolTip,
        },
    },
}));

export const GivelifyTooltip: React.FCC<GivelifyTooltipProps> = ({
    ...props
}) => {
    return <StyledTooltip {...props} />;
};
