import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { styled, keyframes } from '@mui/material';

const loadingFrames = keyframes`
	100% {
		transform: rotate(1turn);
	}
`;

export type WidgetLoadingIconProps = {
    loading?: boolean;
    width?: number;
    color: string;
    icon: JSX.Element;
    className?: string;
};

export type WidgetLoadingIconStyleProps = {
    loading?: boolean;
    width?: number;
    color: string;
};

const WidgetIconBox = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'loading' &&
        prop !== 'color' &&
        prop !== 'icon' &&
        prop !== 'width',
})<WidgetLoadingIconStyleProps>`
    position: relative;
    z-index: 0;
    width: ${(props) =>
        props.loading ? `${props.width + 28}px` : `${props.width}px`};
    height: ${(props) =>
        props.loading ? `${props.width + 28}px` : `${props.width}px`};
    border-radius: 10px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background-color: #399953;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(#fff, #fff),
            linear-gradient(
                ${(props) => props.color},
                ${(props) => props.color}
            ),
            linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
        animation: ${loadingFrames} 4s linear infinite;
        display: ${(props) => (props.loading ? 'block' : 'none')};
    }
    transition: width 1s, height 1s;
`;

const WidgetIconWrapperOutline = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'loading' &&
        prop !== 'color' &&
        prop !== 'icon' &&
        prop !== 'width',
})<WidgetLoadingIconStyleProps>`
    width: ${(props) =>
        props.loading ? `${props.width + 24}px` : `${props.width}px`};
    height: ${(props) =>
        props.loading ? `${props.width + 24}px` : `${props.width}px`};
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 1s, height 1s;
`;

const WidgetIconWrapper = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'loading' &&
        prop !== 'color' &&
        prop !== 'icon' &&
        prop !== 'width',
})<WidgetLoadingIconStyleProps>`
    width: ${(props) =>
        props.loading ? `${props.width + 16}px` : `${props.width}px`};
    height: ${(props) =>
        props.loading ? `${props.width + 16}px` : `${props.width}px`};
    background-color: ${(props) => props.color};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => (props.loading ? '32px' : '40px')};
    transition: width 1s, height 1s font-size 1s;
    color: ${DesignTokens.color.iconWhite};
`;

export const WidgetLoadingIcon: React.FCC<WidgetLoadingIconProps> = ({
    loading,
    color,
    icon,
    className,
    width = 54,
}) => {
    return (
        <WidgetIconBox
            className={className}
            color={color}
            data-testid="average-donations-icon-box"
            loading={loading}
            width={width}
        >
            <WidgetIconWrapperOutline
                color={color}
                loading={loading}
                width={width}
            >
                <WidgetIconWrapper
                    color={color}
                    loading={loading}
                    width={width}
                >
                    {icon}
                </WidgetIconWrapper>
            </WidgetIconWrapperOutline>
        </WidgetIconBox>
    );
};
