import React, { useEffect, useMemo } from 'react';
import { FullWidthModal } from '@givelify/givelify-ui';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useTranslation } from 'react-i18next';
import { UserInfo, UserInfoErrors } from 'types/userTypes';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

interface EditUserModalProps {
    onEditUserModalClose: () => void;
    onSave: () => void;
    ctaSecondActionText: string;
    onUserInfoChange: ({ name, value }) => void;
    userInfo: UserInfo;
    userInfoErrors: UserInfoErrors;
    disabledSave: boolean;
}

const EditUserModal: React.FCC<EditUserModalProps> = (
    props: EditUserModalProps,
) => {
    const {
        userInfo,
        onUserInfoChange,
        userInfoErrors,
        disabledSave,
        onEditUserModalClose,
        onSave,
        ctaSecondActionText,
    } = props;

    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            editUser: t('pages.settings.users.edit-user'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();
    useEffect(() => {
        setButtonBringToFront(false);
        return () => setButtonBringToFront(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FullWidthModal
            open
            footer={{
                onCancel: onEditUserModalClose,
                onSubmit: onSave,
                cancelText: copy.cancel,
                submitText: ctaSecondActionText,
                disableSubmit: disabledSave,
                isLoading: false,
            }}
            heading={copy.editUser}
            id="edit-user-modal"
            name="edit-user"
            onClose={onEditUserModalClose}
            retryMessage=""
        >
            <StepOne
                isEdit
                onUserInfoChange={onUserInfoChange}
                userInfo={userInfo}
                userInfoErrors={userInfoErrors}
                withStep={false}
            />
            <StepTwo
                onUserInfoChange={onUserInfoChange}
                userInfo={userInfo}
                withStep={false}
            />
        </FullWidthModal>
    );
};

export default EditUserModal;
