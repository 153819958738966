import React from 'react';
import { formatWithTimezone } from '@givelify/utils';
import { Donation } from 'pages/donors/DonorProfile';
import envelopeNameSummary from 'pages/donors/DonorProfile/envelopeNameSummary';
import { useTranslation } from 'react-i18next';
import { DonationNote } from 'types/donationTypes';
import { Donor } from 'types/donorTypes';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';
import { DonationHistoryMessageMessage } from '../donationHistoryMessageMessage';
import { MessagesWrapper, BlueBorderBottom } from './styles';

interface DonationMemoProps {
    donation: Donation;
    note: DonationNote;
    donor: Donor;
}

const DonationMessageComponent: React.FCC<DonationMemoProps> = ({
    note,
    donation,
    donor,
}) => {
    const { t } = useTranslation();

    const copy = React.useMemo(
        () => ({
            totalDonated: t('pages.donor_details.new_donation_memo_title', {
                amount: formatMoneyWithCommasAndDolarSign(donation.amount),
                envelopes: envelopeNameSummary(donation.envelopes),
                date: formatWithTimezone(donation.createdAt, 'MMM. DD, YYYY'),
            }),
            receivedDateMessage: `${t('labels.received')}: ${formatWithTimezone(
                donation.createdAt,
                'MMM. DD, YYYY | h:mm A z',
            )}`,
        }),
        [t, donation],
    );

    return (
        <MessagesWrapper>
            <h3>{copy.totalDonated}</h3>
            <DonationHistoryMessageMessage
                dateMessage={copy.receivedDateMessage}
                message={note.memo}
                name={donor.name}
                photo={donor.picture}
            />
            {note.reply?.text && (
                <DonationHistoryMessageMessage
                    message={note.reply.text}
                    name={note.reply.userName}
                    photo={note.reply.userPicture}
                />
            )}
            <BlueBorderBottom />
        </MessagesWrapper>
    );
};

export const DonationMessage = React.memo(DonationMessageComponent);
