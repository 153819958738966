import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';
import { Arrow, GraphToolTip } from './styles';

export type ChartTooltipProps = {
    x: number;
    y: number;
    label: string;
    value: number;
};

const ChartTooltipComponent: React.FCC<ChartTooltipProps> = ({
    x,
    y,
    label,
    value,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            totalReceivedShort: t('overview.totalReceivedShort'),
        }),
        [t],
    );

    return (
        <GraphToolTip
            data-testid="bar-chart-tooltip"
            style={{
                left: x,
                top: y - 20,
            }}
        >
            <GivelifyLabel
                color={DesignTokens.color.textPrimary}
                data-testid="chart-tooltip-value"
                variant="body1B"
            >
                {formatMoneyWithCommasAndDolarSign(value)}
            </GivelifyLabel>
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                fontWeight="bold"
                variant="caption1B"
            >
                {copy.totalReceivedShort}
            </GivelifyLabel>
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                data-testid="chart-tooltip-label"
                variant="caption1"
            >
                {label}
            </GivelifyLabel>
            <Arrow />
        </GraphToolTip>
    );
};

export const ChartTooltip = React.memo(ChartTooltipComponent);
