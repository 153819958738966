import React, { useState } from 'react';
import { makeStyles, RadioGroup as MUIRadioGroup } from '@material-ui/core';
import { SelectOptionProps } from '../types';
import SelectorItem from './SelectorItem';

interface RadioGroupProps {
    options: SelectOptionProps[];
    onChange: (selectedItem: SelectOptionProps) => void;
    value: SelectOptionProps;
}

const useStyles = makeStyles(() => ({
    radioGroup: {
        width: '100%',
    },
}));

const RadioGroup: React.FCC<RadioGroupProps> = ({
    value,
    options,
    onChange,
}) => {
    const classes = useStyles();

    const [valueInternal, setValueInternal] = useState(value);

    const onChangeInternal = (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string,
    ) => {
        const selectedOption = options.find((o) => o.value === value);

        setValueInternal(selectedOption);
        onChange(selectedOption);
    };

    return (
        <MUIRadioGroup
            className={classes.radioGroup}
            onChange={onChangeInternal}
            value={valueInternal.value}
        >
            {options.map((option, i) => (
                <SelectorItem key={i} option={option} />
            ))}
        </MUIRadioGroup>
    );
};

export default RadioGroup;
