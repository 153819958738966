import React from 'react';
import { Donee } from '@givelify/api';
import {
    DesignTokens,
    DownArrowCircleIcon,
    GivelifyButton,
    GivelifyDropdown,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import {
    OutlinedInput,
    SelectChangeEvent,
    styled,
    useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DONEE_SEARCH_QUERY_NAME } from 'router/GasRouterProvider';
import {
    NAVIGATION_CAMPUS_SELECTOR,
    SELECT_DIFFERENT_CHURCH,
} from '../../../utils/clevertapEvents';

const CampusSelectorArrow = styled(GivelifyButton)(({ theme }) => ({
    '&&': {
        padding: 0,
        transition: '0.3s ease',
        right: 'auto !important',
        fontSize: 28,
        color: DesignTokens.color.iconPrimary,
        [theme.breakpoints.down('tablet')]: {
            fontSize: 24,
        },
    },
}));

const CampusDropdownWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    '& .MuiFormControl-root': {
        height: '100%',
    },
    '& .MuiOutlinedInput-root': {
        boxShadow: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    [theme.breakpoints.down('tablet')]: {
        width: '182px',
        maxWidth: '182px',
        paddingRight: 0,
    },
    [theme.breakpoints.down('mobile')]: {
        width: 'calc(100% - 54px)',
        maxWidth: 'calc(100% - 54px)',
        marginRight: 0,
        '& .MuiPaper-root': {
            width: 'calc(100% - 32px)',
        },
    },
}));

const CampusDropdown = styled(GivelifyDropdown)(({ theme }) => ({
    boxShadow: 'none',
    width: '400px',
    maxWidth: '400px',
    '&& .MuiSelect-select': {
        height: '100%',
        paddingLeft: 38,
        paddingRight: 24,
        paddingBottom: 8,
        '&:focus': {
            background: 'inherit',
        },
        [theme.breakpoints.down('tablet')]: {
            paddingLeft: theme.spacing(4),
        },
    },
    '&.MuiOutlinedInput-root': {
        '&& fieldset.MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    '&:hover': {
        '&& > button': {
            color: DesignTokens.color.iconHover,
        },
    },
    '&.open': {
        '&& button': {
            color: DesignTokens.color.globalInfo400,
        },
    },
    [theme.breakpoints.down('tablet')]: {
        '&&.MuiInputBase-root': {
            paddingRight: 0,
        },
        '&& .MuiSelect-select.MuiSelect-outlined': {
            padding: '8px 20px 8px 30px',
        },
    },
    [theme.breakpoints.down('lg')]: {
        width: '242px',
        maxWidth: '242px',
    },
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        maxWidth: '100%',
    },
    [theme.breakpoints.down('tablet')]: {
        '&&': {
            width: '182px',
            maxWidth: '182px',
            paddingRight: 0,
        },
    },
    [theme.breakpoints.down('mobile')]: {
        '&&': {
            width: '100%',
            maxWidth: '100%',
        },
    },
}));

const OptionLink = styled('a')({
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: DesignTokens.textStyle.globalCaption1.font.size,
    color: 'initial',
    cursor: 'arrow',
    textDecoration: 'none',
    '& a:link': {
        color: 'initial',
        cursor: 'arrow',
        textDecoration: 'none',
    },
    '& a:hover': {
        color: 'initial',
        cursor: 'arrow',
        textDecoration: 'none',
    },
    '& a:active': {
        color: 'initial',
        cursor: 'arrow',
        textDecoration: 'none',
    },
    '& a:visited': {
        color: 'initial',
        cursor: 'arrow',
        textDecoration: 'none',
    },
});

export type CampusesSelectorData = Pick<Donee, 'id' | 'name' | 'address'>;

export type CampusSelectorProps = {
    campuses: CampusesSelectorData[];
    onChange?: (campusId: number) => void;
    activeDoneeId?: number;
};

type OptionWrapperProps = {
    href: string;
};

const OptionWrapper: React.FCC<OptionWrapperProps> = (props) => {
    const { href, children } = props;
    const onClick = React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
        },
        [],
    );

    return (
        <OptionLink href={href} onClick={onClick}>
            {children}
        </OptionLink>
    );
};

const CampusSelector: React.FCC<CampusSelectorProps> = ({
    campuses,
    onChange,
    activeDoneeId,
}) => {
    const theme = useTheme();
    const location = useLocation();
    const { trackEvent } = useTrackingContext();

    const hasCampuses = campuses?.length > 1;

    const handleChange = React.useCallback(
        (event: SelectChangeEvent<number>) => {
            const selectionId = event.target.value as number;
            trackEvent(SELECT_DIFFERENT_CHURCH, { id: selectionId });
            onChange(selectionId);
        },
        [onChange, trackEvent],
    );

    const onOpen = React.useCallback(() => {
        trackEvent(NAVIGATION_CAMPUS_SELECTOR);
    }, [trackEvent]);

    const getCampusHref = (campusId: string | number) => {
        const searchParam = new URLSearchParams();
        searchParam.append(DONEE_SEARCH_QUERY_NAME, campusId.toString());
        return `${location.pathname}?${searchParam.toString()}`;
    };

    return (
        <CampusDropdownWrapper>
            <CampusDropdown
                multipleLine
                renderMultiple
                IconComponent={(props) => (
                    <CampusSelectorArrow {...props} variant="icon">
                        <DownArrowCircleIcon fontSize="large" />
                    </CampusSelectorArrow>
                )}
                MenuItemWrapper={({ id, children }) => (
                    <OptionWrapper href={getCampusHref(id)}>
                        {children}
                    </OptionWrapper>
                )}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    sx: {
                        [theme.breakpoints.down('mobile')]: {
                            '&& .MuiPaper-root': {
                                width: 'calc(100% - 32px)',
                                left: '16px !important',
                                top: '78px !important',
                                maxHeight: '214px',
                                background:
                                    DesignTokens.color
                                        .backgroundComponentPrimaryDefault,
                                border: `1px solid ${DesignTokens.border.borderComponentAccentSecondary.color}`,
                                borderRadius:
                                    DesignTokens.measurement.borderRadiusS,
                                '& .MuiList-root': {
                                    height: '100%',
                                },
                            },
                        },
                    },
                }}
                data-testid="campus-selector"
                disabled={!hasCampuses}
                id="campus-selector"
                input={
                    <OutlinedInput
                        sx={{
                            '&': {
                                border: 'none !important',
                                boxShadow: 'none !important',
                                height: '100%',
                            },
                        }}
                    />
                }
                labelId="campus-selector-label"
                onChange={handleChange}
                onOpen={onOpen}
                options={campuses.map((campus) => ({
                    id: campus.id,
                    label: campus.name,
                    secondaryLabel: campus.address,
                }))}
                value={activeDoneeId}
                width="fullwidth"
            />
        </CampusDropdownWrapper>
    );
};

CampusSelector.displayName = 'CampusSelector';

export default React.memo(CampusSelector);
