import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { CurrencyAnnotation } from './CurrencyAnnotation';
import HelpDropdown from './HelpDropdown';
import { UserDropdown } from './UserDropdown';
import { UserDropdownMobile } from './UserDropdownMobile';

const Dropdowns: React.FCC = () => {
    const theme = useTheme();
    const isNotMobile = useMediaQuery(theme.breakpoints.up('mobile'));

    const { doneeCountry } = useSelector((state: AppState) => ({
        doneeCountry: state.Donee.donee?.country,
    }));

    return (
        <>
            <UserDropdownMobile />
            <UserDropdown />
            {isNotMobile && doneeCountry ? (
                <CurrencyAnnotation country={doneeCountry} />
            ) : null}
            {isNotMobile && <HelpDropdown />}
        </>
    );
};

export default Dropdowns;
