import React from 'react';
import { GivelifyButton, GivelifyTextDivider } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { Hidden } from '@mui/material';
import MdiBuildIcon from '../../assets/icon/mdi-build.svg';
import GiveNowButtonLongPreview from '../../assets/online-giving/givelify-button-long-preview.png';
import GiveNowButtonPreview from '../../assets/online-giving/givelify-button-preview.png';
import GiveNowLinkTopArrow from '../../assets/online-giving/givenow-link-arrow-top.svg';
import GiveNowLinkRightArrow from '../../assets/online-giving/givenow-link-arrow.svg';
import CopyGivingLink from '../../components/CopyGivingLink';
import Label from '../../components/Label';
import Link from '../../components/Label/Link';
import Page from '../../components/Page';
import Config from '../../config';
import { useConnectorState } from '../../provider/Connector';
import { ONLINE_GIVING_LANDED } from '../../utils/cleverTapEvents';
import { pushEvent } from '../../utils/cleverTapUtils';
import { useTranslations } from '../../utils/translations';
import useShareModal from './Modals/useShareModal';
import { usePageStyle } from './style';

export const OnlineGiving: React.FCC = () => {
    const cs = usePageStyle();
    const { routeUrls } = useConnectorState();
    const { onlineGivingURLs } = useConnectorState();

    const givingLink =
        onlineGivingURLs.find((x) => x.channel === 'OGA')?.shortLink || '';

    const { intro, givelifyButton } = useTranslations(
        'onlineGiving.landingPage',
        {
            intro: ['title', 'desc', 'link'],
            givelifyButton: [
                'title',
                'desc',
                'getButton',
                'sendToDevelopers',
                'shareDesc',
                'linkDesc',
            ],
        },
    );

    React.useEffect(() => {
        pushEvent(ONLINE_GIVING_LANDED);
    }, []);

    const [openShareModal, ShareModal] = useShareModal();

    return (
        <Page useDivider title="Online giving">
            <ShareModal />
            <div
                className={cs.container + ' ' + cs.introBox}
                data-testid="online-giving-content"
            >
                <div>
                    <Label data-testid="description-title" variant="heading1">
                        {intro.title}
                    </Label>
                    <Label
                        className={cs.introDesc}
                        data-testid="description-text"
                        variant="body1"
                    >
                        {intro.desc}&nbsp;
                        <Link
                            href={Config.onlineGivingKbUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {intro.link}
                        </Link>
                    </Label>
                    <CopyGivingLink
                        buttonVariant="primary"
                        givingLink={givingLink}
                    />
                </div>
            </div>
            <div className={cs.container + ' ' + cs.mt3 + ' ' + cs.pb0}>
                <Grid container spacing={5}>
                    <Grid item className={cs.fullContent} sm={6} xs={12}>
                        <Label variant="heading4">{givelifyButton.title}</Label>
                        <Label
                            className={cs.givelifyButtonDesc}
                            variant="body1"
                        >
                            {givelifyButton.desc}
                        </Label>

                        <Link<'link'>
                            data-testid="get-givelify-button"
                            id="givelify-button"
                            tag="link"
                            to={routeUrls.givelifyButton || ''}
                        >
                            <img
                                alt=""
                                className={cs.buildIcon}
                                src={MdiBuildIcon}
                            />
                            {givelifyButton.getButton}
                        </Link>

                        <GivelifyTextDivider className={cs.divider} text="" />

                        <Label variant="body1">
                            {givelifyButton.shareDesc}
                        </Label>

                        <GivelifyButton
                            className={cs.shareButton}
                            data-testid="send-to-developers-button"
                            name="sendToDevelopers"
                            onClick={openShareModal}
                            text={givelifyButton.sendToDevelopers}
                            variant="secondary"
                        />
                    </Grid>

                    {/* Preview Section */}
                    <Grid
                        item
                        className={
                            cs.fullContent +
                            ' ' +
                            cs.previewImageBox +
                            ' ' +
                            cs.pb0
                        }
                        sm={6}
                        xs={12}
                    >
                        <Hidden smDown>
                            <div>
                                <img
                                    alt="button preview"
                                    className={cs.previewImage}
                                    src={GiveNowButtonPreview}
                                />
                                <div className={cs.previewArrowsContainer}>
                                    <div>
                                        <Label
                                            className={cs.previewArrowText}
                                            color="neutral-grey"
                                            variant="body3"
                                        >
                                            {givelifyButton.linkDesc}
                                        </Label>
                                        <img
                                            alt={givelifyButton.linkDesc}
                                            src={GiveNowLinkRightArrow}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className={cs.longImageWrap}>
                                <Hidden xsDown>
                                    <Label
                                        className={cs.previewArrowText}
                                        color="neutral-grey"
                                        variant="body3"
                                    >
                                        {givelifyButton.linkDesc}
                                    </Label>
                                    <img
                                        alt={givelifyButton.linkDesc}
                                        src={GiveNowLinkTopArrow}
                                        style={{ marginBottom: 8 }}
                                    />
                                </Hidden>
                                <img
                                    alt="button preview"
                                    className={cs.previewImage}
                                    src={GiveNowButtonLongPreview}
                                />
                            </div>
                        </Hidden>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
};
