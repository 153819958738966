export enum MESSAGE_FILTER_VALUE {
    REPLIED = 'REPLIED',
    UN_REPLIED = 'UN_REPLIED',
    NO_MESSAGES = 'NO_MESSAGES',
}

export const MessageFilterOptions = [
    MESSAGE_FILTER_VALUE.REPLIED,
    MESSAGE_FILTER_VALUE.UN_REPLIED,
    MESSAGE_FILTER_VALUE.NO_MESSAGES,
];

export enum TRANSACTION_FILTER_VALUE {
    ALL = 'ALL',
    POSTED = 'POSTED',
    PENDING = 'PENDING',
    REFUNDED = 'REFUNDED',
}

export const TransactionFilterOptions = [
    TRANSACTION_FILTER_VALUE.ALL,
    TRANSACTION_FILTER_VALUE.POSTED,
    TRANSACTION_FILTER_VALUE.PENDING,
];
