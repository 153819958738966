import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyPaper } from '@givelify/givelify-ui';
import { formatWithTimezone, mergeClassNames } from '@givelify/utils';
import { makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { PageName } from './types';

export interface EnvelopeDetailProps {
    id: number;
    name: string;
    offeringId: number;
    amount: number;
    doneeId: number;
    externalId: string;
}

export interface EnvelopRowProps {
    receiptNumber: number;
    date: string;
    envelopes: EnvelopeDetailProps[];
    total: number;
    fees: number;
    pageName: PageName;
}

const createStyles = (
    isRefund: boolean,
    theme: Theme,
): StyleRules<Record<string, unknown>, string> => ({
    tableHeaderText: {
        fontSize: 12,
    },
    wrapper: {
        display: 'grid',
        rowGap: 24,
        gridTemplateColumns: isRefund ? 'repeat(6, auto)' : 'repeat(5,auto)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(4, auto)',
        },
        '& > *': {
            textAlign: 'right',
        },
        padding: '24px 12px',
        backgroundColor: theme.colors.grey50,
    },
    mobileFirstRowHeader: {
        [theme.breakpoints.down('sm')]: {
            gridColumn: isRefund ? 'span 2' : 'span 4',
            textAlign: 'left',
        },
    },
    mobileFirstRowText: {
        [theme.breakpoints.down('sm')]: {
            gridRow: 2,
            gridColumn: isRefund ? 'span 2' : 'span 4',
            textAlign: 'left',
        },
    },
    donationDetailsHeader: {
        textAlign: 'left !important' as 'left',
    },
    donationDetailsText: {
        textAlign: 'left !important' as 'left',
    },
    envelopeNameHeader: {
        textAlign: isRefund ? 'right' : ('left !important' as 'left'),
    },
    envelopeNameText: {
        gridColumn: isRefund ? 3 : 2,
        textAlign: isRefund ? 'right' : ('left !important' as 'left'),
        [theme.breakpoints.down('sm')]: {
            gridColumn: 1,
            textAlign: 'left !important' as 'left',
        },
    },
    donationResult: {
        gridColumn: isRefund ? 4 : 3,
        [theme.breakpoints.down('sm')]: {
            gridColumn: 2,
        },
    },
    feesResult: {
        gridColumn: isRefund ? 5 : 4,
        [theme.breakpoints.down('sm')]: {
            gridColumn: 3,
        },
    },
    refundAmountResult: {
        gridColumn: isRefund ? 6 : 5,
        [theme.breakpoints.down('sm')]: {
            gridColumn: 4,
        },
    },
    totalBorder: {
        height: 1,
        gridColumn: '1 / -1',
        background: theme.colors.grey200,
    },
});

const useStyles = makeStyles<Theme>((theme) => createStyles(false, theme));
const useRefundStyles = makeStyles<Theme>((theme) => createStyles(true, theme));

const formatMoney = (money: string | number) =>
    `$${formatMoneyWithCommas(money)}`;

const DonationDetails: React.FCC<EnvelopRowProps> = (props) => {
    const { t } = useTranslation();

    const isRefund = props.pageName === 'refund';
    const commonClasses = useStyles();
    const refundClasses = useRefundStyles();
    const classes = isRefund ? refundClasses : commonClasses;

    const copy = useMemo(
        () => ({
            donationDetails: t('bankDeposits.text.donationDetails'),
            donation: t('bankDeposits.text.donation'),
            fees: t('bankDeposits.text.fees'),
            total: t('bankDeposits.text.total'),
            receipt: t('bankDeposits.text.receipt'),
            envelopeName: t('bankDeposits.text.envelopName'),
            donationDate: t('bankDeposits.text.donationDate'),
            refundAmount: t('bankDeposits.text.refundAmount'),
        }),
        [t],
    );
    const envelopes = props.envelopes;

    return (
        <GivelifyPaper className={classes.wrapper}>
            <GivelifyLabel
                className={mergeClassNames(
                    classes.mobileFirstRowHeader,
                    classes.tableHeaderText,
                    classes.donationDetailsHeader,
                )}
                text={copy.donationDetails}
            />
            {isRefund && (
                <GivelifyLabel
                    className={mergeClassNames(
                        classes.mobileFirstRowHeader,
                        classes.tableHeaderText,
                    )}
                    text={copy.donationDate}
                />
            )}
            <GivelifyLabel
                className={mergeClassNames(
                    classes.tableHeaderText,
                    classes.envelopeNameHeader,
                )}
                text={copy.envelopeName}
            />
            <GivelifyLabel
                className={mergeClassNames(classes.tableHeaderText)}
                text={copy.donation}
            />
            <GivelifyLabel
                className={classes.tableHeaderText}
                text={copy.fees}
            />
            <GivelifyLabel
                className={classes.tableHeaderText}
                text={copy.total}
            />
            <GivelifyLabel
                className={mergeClassNames(
                    classes.mobileFirstRowText,
                    classes.donationDetailsText,
                )}
                color="grey600"
                id={`receipt-no-${props.receiptNumber}`}
                text={`${copy.receipt} #: ${props.receiptNumber}`}
                variant="body2"
            />
            {isRefund && (
                <GivelifyLabel
                    className={mergeClassNames(classes.mobileFirstRowText)}
                    color="grey600"
                    id={`refund-date-${props.receiptNumber}`}
                    text={formatWithTimezone(props.date)}
                    variant="body2"
                />
            )}
            {envelopes.map((envelope, index) => {
                const feePercent = props.fees / props.total;
                const donation = formatMoney(envelope.amount || 0);
                const fees = formatMoney(
                    props.fees ? envelope.amount * feePercent : 0,
                );
                const refundAmount = formatMoney(
                    envelope.amount
                        ? envelope.amount - envelope.amount * feePercent
                        : 0,
                );
                return (
                    <React.Fragment key={index}>
                        <GivelifyLabel
                            className={mergeClassNames(
                                classes.envelopeNameText,
                                'details-envelope-name',
                            )}
                            color="grey600"
                            id={`envelope-name-${envelope.id}`}
                            text={envelope.name}
                            variant="body2"
                        />
                        <GivelifyLabel
                            className="details-donation-amount"
                            color="grey600"
                            id={`donation-${envelope.id}-${props.receiptNumber}`}
                            text={donation}
                            variant="body2"
                        />
                        <GivelifyLabel
                            className="details-receipt-number"
                            color="grey600"
                            id={`fee-${envelope.id}-${props.receiptNumber}`}
                            text={`-${fees}`}
                            variant="body2"
                        />
                        <GivelifyLabel
                            className="details-net-amount"
                            color="grey600"
                            id={`net-${envelope.id}-${props.receiptNumber}`}
                            text={refundAmount}
                            variant="body2"
                        />
                    </React.Fragment>
                );
            })}
            {envelopes.length > 1 && (
                <>
                    <div className={classes.totalBorder} />
                    <GivelifyLabel
                        bold
                        className={classes.donationResult}
                        color="grey600"
                        id={`total-donation-${props.receiptNumber}`}
                        text={formatMoney(props.total || 0)}
                        variant="body2"
                    />
                    <GivelifyLabel
                        bold
                        className={classes.feesResult}
                        color="grey600"
                        id={`total-fee-${props.receiptNumber}`}
                        text={formatMoney(props.fees || 0)}
                        variant="body2"
                    />
                    <GivelifyLabel
                        bold
                        className={classes.refundAmountResult}
                        color="grey600"
                        id={`total-net-${props.receiptNumber}`}
                        text={formatMoney(props.total - props.fees)}
                        variant="body2"
                    />
                </>
            )}
        </GivelifyPaper>
    );
};

export default DonationDetails;
