import React, { useState, useMemo, ChangeEvent, useEffect } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyTextField,
} from '@givelify/givelify-ui';
import { useInvokeApi } from '@givelify/utils';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { passwordRegExp } from 'utils/validationRegExp';
import Space from '../../../components/Space';
import { AppState } from '../../../store';
import GivelifyModal from '../../../theme/components/GivelifyModal';
import HandleServerErrorText from '../../../theme/components/HandleServerErrorText';
import { endPoint } from './EditProfileForm';

interface ChangePasswordObject {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    oldPasswordError?: string;
    newPasswordError?: string;
    confirmPasswordError?: string;
}

const changePasswordObject: ChangePasswordObject = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const useStyles = makeStyles((theme: Theme) => ({
    submitButton: {
        margin: '0 auto',
        marginBottom: 48,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

type ChangePasswordModalProps = {
    disabled: boolean;
};

const ChangePasswordModal: React.FCC<ChangePasswordModalProps> = ({
    disabled,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [requestState, makeRequest] = useInvokeApi();
    const userId = useSelector((state: AppState) => state.User.user.id);
    const copy = useMemo(
        () => ({
            required: t('error.required'),
            update: t('labels.update'),
            changePasswordText: t('editProfile.changePassword'),
            passwordError: t('editProfile.changePasswordError'),
            changeYourPassword: t('editProfile.changeYourPassword'),
            passwordCriteriaError: t('editProfile.passwordCriteriaError'),

            oldPassword: t('editProfile.textField.oldPassword'),
            newPassword: t('editProfile.textField.newPassword'),
            confirmPassword: t('editProfile.textField.confirmPassword'),
        }),
        [t],
    );

    const [changePassword, setChangePassword] =
        useState<ChangePasswordObject>(changePasswordObject);

    const handlePasswordCheck = () => {
        if (
            changePassword.newPassword !== '' &&
            !passwordRegExp.test(changePassword.newPassword)
        ) {
            setChangePassword({
                ...changePassword,
                newPasswordError: copy.passwordCriteriaError,
            });
        }
    };

    const handleOldPassword = (e: ChangeEvent<HTMLInputElement>) => {
        const oldPassword = e.target.value;
        let oldPasswordError = undefined;
        if (oldPassword === '') oldPasswordError = copy.required;
        return setChangePassword({
            ...changePassword,
            oldPassword,
            oldPasswordError,
        });
    };
    const handleNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        let newPasswordError = undefined;
        if (newPassword === '') newPasswordError = copy.required;
        return setChangePassword({
            ...changePassword,
            newPassword,
            newPasswordError,
        });
    };
    const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = e.target.value;
        return setChangePassword({
            ...changePassword,
            confirmPassword,
        });
    };
    const onBlur = () => {
        if (
            changePassword.newPassword !== '' &&
            changePassword.newPassword !== changePassword.confirmPassword
        ) {
            setChangePassword({
                ...changePassword,
                confirmPasswordError: copy.passwordError,
            });
        } else {
            setChangePassword({
                ...changePassword,
                confirmPasswordError: undefined,
            });
        }
    };

    const [open, isOpen] = useState<boolean>(false);
    const handleClose = () => isOpen(false);
    const handleOpen = () => isOpen(true);

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            isOpen(false);
        }
    }, [requestState]);

    const {
        oldPassword,
        oldPasswordError,
        newPassword,
        newPasswordError,
        confirmPassword,
        confirmPasswordError,
    } = changePassword;

    const onClick = () => {
        makeRequest('PATCH', endPoint(userId), {
            old_password: oldPassword,
            password: newPassword,
        });
    };

    return (
        <>
            <GivelifyButton
                disabled={disabled}
                onClick={handleOpen}
                text={copy.changePasswordText}
                variant="ghost"
            />
            <GivelifyModal
                handleClose={handleClose}
                isOpen={open}
                label={copy.changeYourPassword}
            >
                <GivelifyLabel
                    text={copy.changeYourPassword}
                    variant="heading3"
                />
                <Space gap={3} />
                <GivelifyTextField
                    autoFocus
                    hidePasswordAdornment
                    className={
                        oldPasswordError || oldPassword === '' ? '' : 'success'
                    }
                    id="current-password"
                    label={copy.oldPassword}
                    leftHelperText={oldPasswordError}
                    name="oldPassword"
                    onChange={handleOldPassword}
                    state={oldPasswordError ? 'error' : 'normal'}
                    type="password"
                    value={oldPassword}
                    width="100%"
                />
                <Space gap={3} />
                <GivelifyTextField
                    hidePasswordAdornment
                    className={
                        newPasswordError || newPassword === '' ? '' : 'success'
                    }
                    id="new-password"
                    label={copy.newPassword}
                    leftHelperText={newPasswordError}
                    name="newPassword"
                    onBlur={handlePasswordCheck}
                    onChange={handleNewPassword}
                    state={newPasswordError ? 'error' : 'normal'}
                    type="password"
                    value={newPassword}
                    width="100%"
                />
                <Space gap={3} />
                <GivelifyTextField
                    hidePasswordAdornment
                    className={
                        confirmPasswordError || confirmPassword === ''
                            ? ''
                            : 'success'
                    }
                    id="confirm-password"
                    label={copy.confirmPassword}
                    leftHelperText={confirmPasswordError}
                    name="confirmPassword"
                    onBlur={onBlur}
                    onChange={handleConfirmPassword}
                    state={confirmPasswordError ? 'error' : 'normal'}
                    type="password"
                    value={confirmPassword}
                    width="100%"
                />
                <HandleServerErrorText requestState={requestState} />
                <Space gap={5} />
                <GivelifyButton
                    className={classes.submitButton}
                    disabled={
                        !oldPassword ||
                        !newPassword ||
                        newPassword !== confirmPassword
                    }
                    name="submit"
                    onClick={onClick}
                    size="xLarge"
                    text={copy.update}
                    type="submit"
                    variant="primary"
                />
            </GivelifyModal>
        </>
    );
};

export default ChangePasswordModal;
