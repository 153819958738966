import React, { useEffect, useMemo } from 'react';
import {
    GivelifyModal,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useInvokeApi } from '@givelify/utils';
import { useCampusesContext } from '../../../CampusesProvider';
import useStyles from './styles';

interface DeleteCampusModalProps {
    campusId: number;
    open: boolean;
    onClose: () => void;
}

const DeleteCampusModal: React.FCC<DeleteCampusModalProps> = ({
    campusId,
    open,
    onClose,
}) => {
    const { content, text, modal } = useStyles();

    const { doneeId, getList } = useCampusesContext();
    const [deleteCampusRequestState, makeDeleteCampusRequest] =
        useInvokeApi<unknown>();

    useEffect(() => {
        if (deleteCampusRequestState.type === 'REQUEST_SUCCESS') {
            getList();
            onClose();
        }
    }, [deleteCampusRequestState, getList, onClose]);

    const onOk = () =>
        makeDeleteCampusRequest(
            'DELETE',
            `/donees/${doneeId}/campus/${campusId}`,
        );

    const loading = deleteCampusRequestState.type === 'REQUEST_START';

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem.deleteModal',
    });

    const { title, description } = useMemo(
        () => ({
            title: scopedTranslate('title'),
            description: scopedTranslate('description'),
        }),
        [scopedTranslate],
    );

    return (
        <GivelifyModal
            autoFullscreen
            centerButtons
            showCloseButton
            className={modal}
            isLoading={loading}
            onClose={onClose}
            onPrimaryClick={onOk}
            onSecondaryClick={onClose}
            open={open}
            primaryButtonText="Delete"
            primaryButtonVariant="danger"
        >
            <div className={content}>
                <GivelifyLabel bold text={title} variant="heading2" />
                <GivelifyLabel
                    className={text}
                    text={description}
                    variant="body1"
                />
            </div>
        </GivelifyModal>
    );
};

export default DeleteCampusModal;
