import React from 'react';
import { OnboardingProps } from '../OnboardingProps';
import { OnboardingV1 } from './OnboardingV1';
import { OnboardingV2 } from './OnboardingV2';

export const Onboarding: React.FCC<OnboardingProps> = React.memo((props) => {
    if (!props.donee.onboarding) return null;

    const isV2 = props.newOnboarding;
    return isV2 ? <OnboardingV2 {...props} /> : <OnboardingV1 {...props} />;
});

Onboarding.displayName = 'Onboarding';
