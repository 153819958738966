import React, { useMemo, useCallback } from 'react';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyTab } from '@givelify/ui';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { ReportsHeaderPageName } from '../GenerateReports/report/models';
interface ReportsPageHeaderProps {
    page: ReportsHeaderPageName;
}

const ReportsPageHeader: React.FC<ReportsPageHeaderProps> = ({ page }) => {
    const { enabledFeatures } = useSelector((state: AppState) => ({
        enabledFeatures: state.System.enabledFeatures,
    }));
    const { legacyReportsDashboard } = useMemo(
        () => ({
            legacyReportsDashboard: isFeatureEnabled(
                enabledFeatures,
                Features.LEGACY_REPORTS_DASHBOARD,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation();

    const copy = useMemo(
        () => ({
            reports: t('reports.reports'),
            generateReports: t('reports.root.generateReports.title'),
            legacyReports: t('reports.root.legacyReports.title'),
            reportHistory: t('reports.root.reportHistory.title'),
        }),
        [t],
    );

    const tabHrefs = React.useMemo(() => {
        const hrefs = [
            {
                href: PATH.REPORTS.GENERATE_REPORTS(),
                label: copy.generateReports,
                id: 'reports-tab',
            },
            {
                href: PATH.REPORTS.LEGACY_REPORTS,
                label: copy.legacyReports,
                id: 'reports-legacy-tab',
            },
            {
                href: PATH.REPORTS.REPORT_HISTORY,
                label: copy.reportHistory,
                id: 'reports-history-tab',
            },
        ];

        if (!legacyReportsDashboard) {
            hrefs.splice(1, 1);
        }

        return hrefs;
    }, [copy, legacyReportsDashboard, PATH.REPORTS]);

    const currentTab = useMemo(() => {
        switch (page) {
            case 'ReportsHistory':
                return tabHrefs.length > 2 ? 2 : 1;
            case 'GenerateLegacyReport':
                return 1;
            default:
                return 0;
        }
    }, [page, tabHrefs]);

    const onChange = useCallback(
        (tabIndex) => {
            const href = tabHrefs[tabIndex].href;
            navigate(href);
        },
        [navigate, tabHrefs],
    );

    return (
        <>
            <GivelifyLabel
                marginBottom={32}
                text={copy.reports}
                variant="heading1"
            />
            <GivelifyTab
                onChange={onChange}
                options={tabHrefs.map((item) => ({
                    label: item.label,
                    tabHref: item.href,
                    id: item.id,
                }))}
                value={currentTab}
            />
        </>
    );
};

export default ReportsPageHeader;
