import React from 'react';
import { GivelifyButton } from '../../button';
import { GivelifyLabelStyles } from '../../label/styles';
import { GivelifyTablePagination } from '../types';

interface PaginationProps {
    pagination?: GivelifyTablePagination;
}

const Pagination: React.FCC<PaginationProps> = ({ pagination }) => {
    const { body2 } = GivelifyLabelStyles({});

    if (!pagination) return null;

    const paginationRangeStart =
        pagination.currentPage * pagination.rowsPerPage + 1;
    const paginationRangeEnd = Math.min(
        (pagination.currentPage + 1) * pagination.rowsPerPage,
        pagination.totalItems,
    );
    const totalPages = Math.ceil(
        pagination.totalItems / pagination.rowsPerPage,
    );

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}
        >
            <span
                className={body2}
            >{`${paginationRangeStart} - ${paginationRangeEnd} of ${pagination.totalItems}`}</span>
            <GivelifyButton
                variant="ghost"
                iconVariant="previous-page"
                disabled={
                    pagination.currentPage === 0 || pagination.totalItems === 0
                }
                onClick={() =>
                    pagination.onChangePage(pagination.currentPage - 1)
                }
            />
            <GivelifyButton
                variant="ghost"
                iconVariant="next-page"
                disabled={
                    pagination.currentPage === totalPages - 1 ||
                    pagination.totalItems === 0
                }
                onClick={() =>
                    pagination.onChangePage(pagination.currentPage + 1)
                }
            />
        </div>
    );
};

export default Pagination;
