import {
    getDonationQuery,
    MESSAGE_FILTER_VALUE,
    TRANSACTION_FILTER_VALUE,
} from '@givelify/api';
import { IntegrationPaginatedResponse } from '@givelify/givelify-ui';
import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { DonationRow } from 'api/models';
import axios, { CancelTokenSource } from 'axios';

let infiniteLoaderDataCancelTokenSource: CancelTokenSource;

export const getDataForInfiniteLoader = async (
    doneeId: number,
    pageNumber: number,
    timeFrame: TimeFrameValue,
    transactionFilter: TRANSACTION_FILTER_VALUE,
    envelopeIds: number[],
    messageFilter: MESSAGE_FILTER_VALUE[],
): Promise<ApiResponse<IntegrationPaginatedResponse<DonationRow>>> => {
    infiniteLoaderDataCancelTokenSource?.cancel();
    infiniteLoaderDataCancelTokenSource = axios.CancelToken.source();

    const donationsQueryString = getDonationQuery({
        timeFrame,
        transactionFilter,
        page: pageNumber,
        envelopeIds,
        messageFilter,
    });
    const donationsUrl = `/donees/${doneeId}/donations${donationsQueryString}`;

    const donationsHttpRequest = getAxiosClient().get(donationsUrl, {
        cancelToken: infiniteLoaderDataCancelTokenSource.token,
    });
    return makeApiRequest<IntegrationPaginatedResponse<DonationRow>>(
        donationsHttpRequest,
    );
};
