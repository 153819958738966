import React, { useEffect, useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyRadio,
    GivelifyTooltip,
} from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Envelope } from 'api/models';
import { useAdvancedTranslation } from 'utils/i18n';
import { useGivingTueasdayContext } from './context/GivingTuesdayContext';
import { ReactComponent as EnvelopeIconSvg } from './img/envelopeIcon.svg';

const EnvelopeIcon = styled(EnvelopeIconSvg)(({ theme }) => ({
    width: 87,
    [theme.breakpoints.down('mobile')]: {
        width: 73,
    },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
    },
}));

const ScrollBarStyles = {
    '&:hover': {
        scrollbarWidth: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: DesignTokens.color.backgroundPrimary,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: DesignTokens.color.backgroundComponentQuaternary,
        },
    },
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        backgroundColor: DesignTokens.color.backgroundPrimary,
    },
};

const EnvelopesScrollableContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    maxHeight: 150,
    overflowY: 'scroll',
    ...ScrollBarStyles,
}));

const EnvelopesListScreen: React.FCC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'givingTuesday.envelopeList',
    );

    const copy = useMemo(
        () => ({
            header: scopedATranslate('header'),
            description1: scopedATranslate('description1'),
            description2: scopedTranslate('description2'),
            description3: scopedTranslate('description3'),
            description4: scopedTranslate('description4'),
            newEnvelopeLabel1: scopedATranslate('newEnvelopeLabel1'),
            newEnvelopeLabel2: scopedTranslate('newEnvelopeLabel2'),
            backButtonText: scopedTranslate('backButtonText'),
            confirmButtonText: scopedTranslate('confirmButtonText'),
            tooltipText: scopedTranslate('tooltipText'),
        }),
        [scopedTranslate, scopedATranslate],
    );

    const {
        givingTuesdayEnvelopes,
        envelopesLoading,
        backClick,
        updateEnvelopeConfirmClick: pickEnvelopeConfirmClick,
        showCreateEnvelopeFormClick,
    } = useGivingTueasdayContext();

    const [newEnvelopeSelected, setNewEnvelopeSelected] = useState(false);
    const [selectedEnvelope, setSelectedEnvelope] = useState<Envelope>();
    const onSelectEnvelope = (envelope: Envelope) => {
        pushEvent('Click GT23 Opt-in modal Select envelope');

        setSelectedEnvelope(envelope);
        setNewEnvelopeSelected(false);
    };
    const onSelectCreateEnvelope = () => {
        pushEvent('Click GT23 Opt-in modal Create a new envelope');

        setSelectedEnvelope(undefined);
        setNewEnvelopeSelected(true);
    };

    const confirmClick = () => {
        pushEvent('Click GT23 Opt-in modal Confirm_btn');

        if (newEnvelopeSelected) {
            showCreateEnvelopeFormClick();
        } else if (selectedEnvelope) {
            pickEnvelopeConfirmClick(selectedEnvelope);
        }
    };

    const backClickInternal = () => {
        pushEvent('Click GT23 Opt-in modal Back_btn');
        backClick();
    };

    useEffect(() => {
        pushEvent('View GT23 Opt-in modal');
    }, []);

    if (envelopesLoading) return null;

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <EnvelopeIcon />
                <GivelifyLabel
                    align="center"
                    style={{
                        marginTop: 16,
                        marginBottom: 16,
                    }}
                    text={copy.header}
                    variant={isMobile ? 'body1B' : 'heading2S'}
                />
                <GivelifyLabel
                    align="center"
                    style={{
                        color: DesignTokens.color.textSecondary,
                    }}
                    variant={isMobile ? 'body2' : 'body1'}
                >
                    {`${copy.description1} `}
                    <GivelifyLabel
                        style={{
                            color: DesignTokens.color.textAccentDefault,
                            display: 'inline',
                        }}
                        variant={isMobile ? 'body2' : 'body1'}
                    >
                        <GivelifyTooltip title={copy.tooltipText}>
                            <span>{copy.description2}</span>
                        </GivelifyTooltip>
                    </GivelifyLabel>
                    {` ${copy.description3} `}
                    <GivelifyLabel variant={isMobile ? 'body2B' : 'body1B'}>
                        {copy.description4}
                    </GivelifyLabel>
                </GivelifyLabel>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    marginTop: 32,
                    marginBottom: 32,
                }}
            >
                {givingTuesdayEnvelopes?.length > 0 && (
                    <>
                        <EnvelopesScrollableContainer>
                            {givingTuesdayEnvelopes.map((envelope) => (
                                <GivelifyRadio
                                    key={envelope.id}
                                    checked={
                                        selectedEnvelope?.id === envelope.id
                                    }
                                    label={envelope.name}
                                    onChange={() => onSelectEnvelope(envelope)}
                                    title={envelope.name}
                                />
                            ))}
                        </EnvelopesScrollableContainer>
                        <hr
                            style={{
                                margin: 0,
                                border: 0,
                                borderTop: `1px solid ${DesignTokens.color.dividerPrimaryLight}`,
                            }}
                        />
                    </>
                )}
                <GivelifyRadio
                    multipleLine
                    label={copy.newEnvelopeLabel1}
                    onChange={onSelectCreateEnvelope}
                    secondLabel={copy.newEnvelopeLabel2}
                    title={copy.newEnvelopeLabel1}
                />
            </div>
            <ButtonsWrapper>
                <GivelifyButton
                    onClick={backClickInternal}
                    text={copy.backButtonText}
                    variant="ghost"
                />
                <GivelifyButton
                    disabled={!selectedEnvelope && !newEnvelopeSelected}
                    onClick={confirmClick}
                    text={copy.confirmButtonText}
                    variant="primary"
                />
            </ButtonsWrapper>
        </>
    );
};

export default EnvelopesListScreen;
