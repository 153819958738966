import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { ApiHandler, UpdateDoneeResponse } from '@givelify/api';
import { ProfileMobilePreview } from '@givelify/onboarding';
import { GivelifyTextField, GivelifyLabel } from '@givelify/ui';
import {
    InvokeApiErrorResponse,
    isFailed,
    useApiRequest,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { ErrorText } from 'components/ErrorText';
import permissionTypes from 'constants/permissionTypes';
import { EditOption } from 'pages/settings/components/EditOption';
import SaveCancelButtons from 'pages/settings/components/SaveCancelButtons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { setDoneeMissionStatement } from 'store/donee/actions';
import permissionsByPath from 'utils/permissionsByPath';
import {
    MissionContainer,
    MissionDescriptionHolder,
    MissionEditor,
    MissionEditorSide,
    MissionExample,
    MissionExampleCaptionClosed,
    MissionPreviewSide,
    MissionTip,
    MissionTipContainer,
    MissionTitleContainer,
    SaveCancelButtonsWrapper,
} from './style';

const MissionStatementPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            yourStatement: t('pages.settings.mission-statement.your-statement'),
            addStatement: t('pages.settings.mission-statement.add-statement'),
            description: t('pages.settings.mission-statement.description'),
            description2: t('pages.settings.mission-statement.description2'),
            forExample: t('pages.settings.mission-statement.for-example'),
            example: t('pages.settings.mission-statement.example'),
            inputPlaceholder: t(
                'pages.settings.mission-statement.input-placeholder',
            ),
            edit: t('pages.settings.mission-statement.add-edit'),
            tip: t('labels.tip'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {
        doneeId,
        missionStatement,
        organizationName,
        organizationType,
        phoneNumber,
        fullAddress,
        coverUrl,
        representativeName,
        representativeAvatar,
        user,
    } = useSelector((state: AppState) => {
        return {
            user: state.User.user,
            doneeId: state.Donee.donee.id,
            organizationName: state.Donee.donee.name,
            organizationType: state.Donee.donee.type,
            missionStatement: state.Donee.donee.missionStatement || '',
            phoneNumber: state.Donee.donee.phone,
            fullAddress: {
                street: state.Donee.donee.address,
                city: state.Donee.donee.city,
                state: state.Donee.donee.state,
                zip: state.Donee.donee.zip,
            },
            coverUrl: state.Donee.donee.photo,
            representativeName: '',
            representativeAvatar:
                state.Donee.donee.onboarding.appProfile.faithLeader.avatar,
        };
    });

    const [missionText, setMissionText] = useState(missionStatement);
    const handleChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        if (e.target.value.length > 200) {
            e.target.value = e.target.value.substring(0, 200);
        }
        setMissionText(e.target.value);
    };
    const dispatch = useDispatch();
    const [doneeRequestState, makeDoneeRequest] =
        useApiRequest<UpdateDoneeResponse>();
    const [editorEnabled, setEditorEnabled] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const onEditClick = () => {
        setEditorEnabled(true);
    };
    const onEditCancel = useCallback(() => {
        setEditorEnabled(false);
        setSaveError(undefined);
        setMissionText(missionStatement);
    }, [missionStatement]);
    const onEditSubmit = async () => {
        setSaveError(undefined);
        await makeDoneeRequest(
            ApiHandler.instance.donees.updateMissionStatement(
                doneeId,
                missionText,
            ),
        );
        dispatch(setDoneeMissionStatement(doneeId, missionText, new Date()));
    };

    useEffect(() => {
        onEditCancel();
    }, [missionStatement, onEditCancel]);

    useEffect(() => {
        if (doneeRequestState.type === 'REQUEST_SUCCESS') {
            dispatch(
                setDoneeMissionStatement(
                    doneeId,
                    doneeRequestState.response.onboarding.appProfile
                        .missionStatement,
                    new Date(doneeRequestState.response.updatedAt),
                ),
            );
            setEditorEnabled(false);
            setSaveError(undefined);
        } else if (isFailed(doneeRequestState)) {
            setSaveError(
                (doneeRequestState as InvokeApiErrorResponse).error.message,
            );
        }
        // eslint-disable-next-line
    }, [doneeRequestState, dispatch]);

    const isLoading = doneeRequestState.type === 'REQUEST_START';

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.MISSION_STATEMENT][user?.role] ===
        permissionTypes.FULL_ACCESS;

    return (
        <MissionContainer>
            <MissionEditorSide>
                <MissionTitleContainer>
                    <GivelifyLabel
                        text={
                            editorEnabled
                                ? copy.addStatement
                                : copy.yourStatement
                        }
                        variant={isMobile ? 'heading3S' : 'heading2S'}
                    />
                    {!editorEnabled && hasFullAccess ? (
                        <EditOption
                            aria-label="Edit mission statement"
                            onClick={onEditClick}
                            testId="add-edit-button"
                            text={copy.edit}
                        />
                    ) : null}
                </MissionTitleContainer>
                {!editorEnabled && (
                    <GivelifyLabel
                        wrap
                        id="mission-description"
                        overflow="break-word"
                        text={missionText ? missionText : copy.description}
                        variant="body2"
                    />
                )}
                {editorEnabled ? (
                    <MissionEditor data-testid="mission-statement-editor">
                        <GivelifyTextField
                            fullWidth
                            multiline
                            aria-label="mission statement editor"
                            id="add-mission-statement"
                            label="Add mission statement"
                            onChange={handleChange}
                            placeholder={copy.inputPlaceholder}
                            rightHelperText={`${missionText.length}/200`}
                            rows={5}
                            value={missionText}
                        />
                    </MissionEditor>
                ) : null}
                <MissionTipContainer>
                    <MissionTip text={copy.tip} variant="body2" />
                    <MissionDescriptionHolder>
                        <GivelifyLabel
                            wrap
                            text={copy.description2}
                            variant="body2"
                        />
                        <GivelifyLabel
                            marginTop="20px"
                            text={copy.forExample}
                            variant="heading3S"
                        />
                        <MissionExample>
                            {copy.example}
                            <MissionExampleCaptionClosed
                                alt="close"
                                src="/caption-close.svg"
                            />
                        </MissionExample>
                    </MissionDescriptionHolder>
                </MissionTipContainer>
                {editorEnabled && (
                    <SaveCancelButtonsWrapper>
                        {saveError && <ErrorText text={saveError} />}
                        <SaveCancelButtons
                            cancelDisabled={isLoading}
                            onCancel={onEditCancel}
                            onSave={onEditSubmit}
                            saving={isLoading}
                            submitDisabled={
                                isLoading || missionText === missionStatement
                            }
                        />
                    </SaveCancelButtonsWrapper>
                )}
            </MissionEditorSide>
            <MissionPreviewSide>
                <ProfileMobilePreview
                    coverUrl={coverUrl}
                    fullAddress={{ ...fullAddress, phone: phoneNumber }}
                    isNonprofit={organizationType === 'nonprofit'}
                    organizationName={organizationName}
                    profileStatement={missionText}
                    representativeAvatar={representativeAvatar}
                    representativeName={representativeName}
                />
            </MissionPreviewSide>
        </MissionContainer>
    );
};

export default MissionStatementPage;
