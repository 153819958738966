import React, { useMemo } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { webConfig } from '../../../../../utils/webConfig';
import { StyledModal } from './styles';
import { ApplyForTaxIdModalProps } from './types';

export const ApplyForTaxIdModal: React.FCC<ApplyForTaxIdModalProps> = ({
    onClose,
    open,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const translationKey = 'taxId.helpModal.church';
    const copy = useMemo(
        () => ({
            ein: t('taxId.ein'),
            irs: t(`${translationKey}.irs`),
            documentsHeader: t(`${translationKey}.documentsHeader`),
            documentList1: t(`${translationKey}.documentList1`),
            documentList2: t(`${translationKey}.documentList2`),
            documentList3: t(`${translationKey}.documentList3`),
            documentList4: t(`${translationKey}.documentList4`),
            documentList5: t(`${translationKey}.documentList5`),
            dontHave: t(`${translationKey}.dontHave`),
            applyForTaxId: t(`${translationKey}.applyForTaxId`),
            forMoreInfo: t(`${translationKey}.forMoreInfo`),
            taxIdArticle: t(`${translationKey}.taxIdArticle`),
        }),
        [t],
    );
    return (
        <StyledModal
            showCloseButton
            CloseButton={<GivelifyIcon variant="close-outlined" />}
            onClose={onClose}
            open={open}
        >
            <GivelifyLabel
                className="header"
                text={copy.ein}
                variant="heading1S"
            />
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                text={copy.irs}
                variant="body2"
            />
            <GivelifyLabel
                className="header sub-header"
                text={copy.documentsHeader}
                variant="heading1S"
            />
            <ul>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList1}
                        variant="body2"
                    />
                </li>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList2}
                        variant="body2"
                    />
                </li>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList3}
                        variant="body2"
                    />
                </li>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList4}
                        variant="body2"
                    />
                </li>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList5}
                        variant="body2"
                    />
                </li>
            </ul>
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                {copy.dontHave}{' '}
                <GivelifyLink
                    fontWeight={600}
                    href={webConfig.applyEinLink}
                    target="_blank"
                    text={copy.applyForTaxId}
                />
            </GivelifyLabel>

            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                {copy.forMoreInfo}{' '}
                <GivelifyLink
                    fontWeight={600}
                    href={webConfig.taxIdHelpArticleURL}
                    target="_blank"
                    text={copy.taxIdArticle}
                />
            </GivelifyLabel>

            <GivelifyButton
                fullWidth
                className="confirm"
                onClick={onClose}
                text="Got it, Thanks"
                variant="primary"
            />
        </StyledModal>
    );
};
