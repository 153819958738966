import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { TabBadge, TabBox, TabItem, TabMarker } from './styles';
import { GivelifyTabProps } from './types';

export const GivelifyTab: React.FCC<GivelifyTabProps> = ({
    value = 0,
    onChange,
    options,
    className,
}) => {
    const scrollableTabs = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (scrollableTabs.current) {
            const container = scrollableTabs.current;
            const tabElement = container.children[value] as HTMLDivElement;

            const tabLeft = tabElement.offsetLeft;
            const tabRight = tabLeft + tabElement.clientWidth;
            const containerLeft = container.scrollLeft;
            const containerRight = container.scrollLeft + container.clientWidth;

            const isTabFullyVisible =
                tabLeft >= containerLeft && tabRight <= containerRight;

            if (!isTabFullyVisible) {
                container.scrollTo({
                    left:
                        tabLeft -
                        container.clientWidth / 2 +
                        tabElement.clientWidth / 2,
                    behavior: 'smooth',
                });
            }
        }
    }, [scrollableTabs, options.length, value]);
    const hasBadges = React.useMemo(
        () => options.some((item) => item.badgeText),
        [options],
    );
    return (
        <TabBox
            ref={scrollableTabs}
            className={className}
            hasBadges={hasBadges}
        >
            {options.map((item, index) => (
                <TabItem
                    key={`tab-${index}`}
                    active={index === value}
                    className={mergeClassNames(
                        'tab-item',
                        index === value && 'tab-selected',
                    )}
                    data-testid={item.id || 'tab'}
                    href={item.tabHref}
                    id={item.id || ''}
                    onClick={(ev) => {
                        ev.preventDefault();
                        onChange(index);
                    }}
                >
                    <span className="tab-label">{item.label}</span>
                    {index === value && <TabMarker className="tab-marker" />}
                    {item.badgeText && (
                        <TabBadge className="tab-badge">
                            {item.badgeText}
                        </TabBadge>
                    )}
                </TabItem>
            ))}
        </TabBox>
    );
};
