import { makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => ({
    modalContainer: {
        '& input': {
            background: '#fff',
            borderColor: '#adadad',
            padding: theme.spacing(3),
        },
    },
    confirmationText: {
        marginTop: 46,
        marginBottom: 32,
    },
    description: {
        marginBottom: 92,
    },
}));
