import React from 'react';
import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { ClickAwayListener, Grid, styled, Tooltip } from '@mui/material';
import { DataBubble, DataBubbleProps } from './DataBubble';
import { VariantColor } from './styles';
import { BubbleVariant } from './types';

export type DataBubbleWrapperProps = {
    title: string;
    text: string;
    linkText: string;
    linkUrl: string;
} & Omit<DataBubbleProps, 'isActive'>;

const Wrapper = styled('div')({
    position: 'relative',
    display: 'inline-flex',
});

const TooltipLink = styled(GivelifyLink)({
    color: DesignTokens.color.textWhite,
    fontWeight: 'normal',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
        color: DesignTokens.color.textWhite,
    },
});
const Circle = styled('div', {
    shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: BubbleVariant }>((props) => ({
    width: '16px',
    height: '16px',
    borderRadius: '100%',
    marginRight: '8px',
    backgroundColor: VariantColor[props.variant],
}));

export const DataBubbleWrapper: React.FCC<DataBubbleWrapperProps> = ({
    maxCount,
    variant,
    count,
    title,
    text,
    linkText,
    linkUrl,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClickAway = React.useCallback(() => setIsOpen(false), []);

    return (
        <Wrapper data-testid="click-away">
            <ClickAwayListener
                disableReactTree
                mouseEvent="onClick"
                onClickAway={handleClickAway}
            >
                <Tooltip
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                borderRadius: '4px',
                                padding: '20px 16px 16px',
                                backgroundColor:
                                    DesignTokens.color
                                        .backgroundComponentToolTip,
                                '& .MuiTooltip-arrow': {
                                    color: DesignTokens.color
                                        .backgroundComponentToolTip,
                                },
                            },
                        },
                    }}
                    open={isOpen}
                    placement="right-start"
                    title={
                        <div data-testid="tooltip-content">
                            <Grid
                                container
                                alignItems="center"
                                marginBottom="8px"
                            >
                                <Circle variant={variant} />
                                <GivelifyLabel
                                    color={DesignTokens.color.textWhite}
                                    data-testid="tooltip-title"
                                >
                                    {title}
                                </GivelifyLabel>
                            </Grid>
                            <GivelifyLabel
                                color={DesignTokens.color.textWhite}
                                data-testid="tooltip-text"
                                marginBottom="16px"
                                variant="body2"
                            >
                                {text}
                            </GivelifyLabel>
                            <TooltipLink
                                data-testid="tooltip-link"
                                href={linkUrl}
                                text={linkText}
                            />
                        </div>
                    }
                >
                    <div
                        data-testid="data-bubble-wrapper"
                        onClick={() => setIsOpen(true)}
                    >
                        <DataBubble
                            count={count}
                            isActive={isOpen}
                            maxCount={maxCount}
                            variant={variant}
                        />
                    </div>
                </Tooltip>
            </ClickAwayListener>
        </Wrapper>
    );
};
