import React, { useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    GivelifyButton,
    GivelifyLoading,
    GivelifyIcon,
} from '@givelify/givelify-ui';
import {
    getAxiosClient,
    mergeClassNames,
    useTrackingContext,
} from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DownloadReport } from 'store/integrations/thunks';
import { Report } from 'store/report/types';
import {
    INTEGRATIONS_REPORTS_DOWNLOAD_REPORT,
    INTEGRATIONS_REPORTS_REGENERATE_REPORT,
} from 'utils/clevertapEvents';
import { useRegenerateRequest } from '../../../useRegenerateRequest';
import ConfirmModal from './ConfirmModal';
import useStyles from './styles';

interface ReportActionsProps {
    openMatchModal: (reportId: number, type: 'donor' | 'envelope') => void;
    isReadOnly: boolean;
}

const ReportActions: React.FCC<Table.DataCellProps & ReportActionsProps> = ({
    openMatchModal,
    isReadOnly,
    ...props
}) => {
    const report = props.row;

    const { t } = useTranslation();
    const { reportLinkWrapper, loader, iconWithLabel, errorIcon, waitIcon } =
        useStyles();
    const dispatch = useDispatch();
    const [reportState, regenerateReportRequest] = useRegenerateRequest(
        report.id,
    );
    const { trackEvent } = useTrackingContext();

    const totalUnmatchedItems =
        report.totalUnmatchedDonors + report.totalUnmatchedEnvelopes;
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const copy = {
        download: t('labels.download'),
        sent: t('labels.sent'),
        failed: t('labels.failed'),
        processing: t('labels.processing'),
        regenerate: t('labels.regenerate'),
        redownload: t('labels.redownload'),
        requested: t('labels.requested'),
    };

    const onRegenerateClick = () => {
        trackEvent(INTEGRATIONS_REPORTS_REGENERATE_REPORT, {
            reportId: report.id,
        });
        regenerateReportRequest();
    };

    const downloadReport = () => {
        trackEvent(INTEGRATIONS_REPORTS_DOWNLOAD_REPORT, { id: report.id });
        dispatch(DownloadReport(report.id, report.downloadLink));
        setShowConfirmModal(false);

        getAxiosClient({ responseType: 'blob', withCredentials: true })
            .get(report.downloadLink)
            .then((r) => {
                const href = URL.createObjectURL(r.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', report.fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(() => null);
    };

    const downloadClick = () => {
        if (totalUnmatchedItems > 0) {
            setShowConfirmModal(true);
        } else {
            downloadReport();
        }
    };

    const openMatchClick = () => {
        setShowConfirmModal(false);
        if (report.totalUnmatchedDonors > 0) {
            openMatchModal(report.id, 'donor');
        } else {
            openMatchModal(report.id, 'envelope');
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const states: Record<Report['reportState'], any> = {
        sent: (
            <>
                <GivelifyIcon
                    className={iconWithLabel}
                    color="inherit"
                    variant="send"
                />
                {copy.sent}
            </>
        ),
        failed: (
            <>
                <GivelifyIcon
                    className={mergeClassNames(iconWithLabel, errorIcon)}
                    color="red"
                    variant="close-simple"
                />
                {copy.failed}
            </>
        ),
        processing: (
            <>
                <GivelifyLoading className={loader} type="circular" />
                {copy.processing}
            </>
        ),
        complete: (
            <GivelifyButton
                onClick={downloadClick}
                startIconVariant="download"
                text={report.imported ? copy.redownload : copy.download}
                variant="link"
            />
        ),
        requested: (
            <>
                <FontAwesomeIcon className={waitIcon} icon={faStopwatch} />
                {copy.requested}
            </>
        ),
        regenerate: (
            <GivelifyButton
                disabled={isReadOnly}
                onClick={onRegenerateClick}
                startIconVariant="redo"
                text={copy.regenerate}
                variant="link"
            />
        ),
    };
    return (
        <Table.Cell
            {...props}
            style={{
                paddingLeft: 16,
                paddingRight: 16,
            }}
        >
            <ConfirmModal
                onClose={() => setShowConfirmModal(false)}
                onDownloadClick={downloadReport}
                onMatchClick={openMatchClick}
                open={showConfirmModal}
                totalUnmatchedItems={totalUnmatchedItems}
            />
            <div className={reportLinkWrapper}>
                {states[reportState ? reportState : report.reportState]}
            </div>
        </Table.Cell>
    );
};

export default ReportActions;
