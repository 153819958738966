import React from 'react';
import { GivelifyRadio } from '@givelify/givelify-ui';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';

const useStyles = makeStyles(() =>
    createStyles({
        toggleStyles: {
            display: 'flex',
            marginBottom: 28,
            '& .radio-first': {
                marginRight: 59,
            },
        },
    }),
);

export interface ToggleButtonsProps {
    yes: boolean;
    handleYes: (yes: boolean) => unknown;
}

const ToggleButtons: React.FCC<ToggleButtonsProps> = ({ handleYes, yes }) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { toggleStyles } = useStyles();
    const yesText = t('labels.yes');
    const noText = t('labels.no');
    const onChangeYes = () => {
        handleYes(true);
    };
    const onChangeNo = () => {
        handleYes(false);
    };
    return (
        <div className={toggleStyles}>
            <GivelifyRadio
                ariaLabel="address-yes"
                checked={yes}
                className="radio-first"
                id="address-yes"
                label={yesText}
                labelPosition="after"
                name="address-yes"
                onChange={onChangeYes}
                value={yesText}
                variant="primary"
            />
            <GivelifyRadio
                ariaLabel="address-no"
                checked={!yes}
                id="address-no"
                label={noText}
                labelPosition="after"
                name="address-no"
                onChange={onChangeNo}
                value={noText}
                variant="primary"
            />
        </div>
    );
};

export default React.memo(ToggleButtons);
