import React, { useState, useMemo, useEffect } from 'react';
import {
    GivelifySwitch,
    GivelifyLabel,
    FacebookLoading,
    GivelifyLoading,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { Divider, Grid } from '@material-ui/core';
import Space from 'components/Space';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    getIntegrationSettings,
    updateIntegrationAutoExport,
} from '../AutoIntegrationContent/components/automatedIntegrationRequest';
import { AutoIntegrationSettingData } from '../AutoIntegrationContent/types';
import CredentialForm from './components/SettingTabContent/CredentialForm';
import { settingTabStyle } from './style';

interface F1SettingTabContentProps {
    isReadOnly: boolean;
}

const FormLoading = () => {
    return (
        <>
            <FacebookLoading
                borderRadius={4}
                height={48}
                marginBottom={32}
                width="100%"
            />
            <FacebookLoading
                borderRadius={4}
                height={48}
                marginBottom={32}
                width="100%"
            />
        </>
    );
};

const F1SettingTabContent: React.FCC<F1SettingTabContentProps> = ({
    isReadOnly,
}) => {
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));
    const classes = settingTabStyle();
    const { t } = useTranslation();
    const [getIntegrationSetting, requestIntegrationSetting] =
        useApiRequest<AutoIntegrationSettingData>();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        requestIntegrationSetting(getIntegrationSettings(userDoneeId));
        setLoading(true);
    }, [doneeId, requestIntegrationSetting, userDoneeId]);
    const [checked, setChecked] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    useEffect(() => {
        if (getIntegrationSetting.type === 'REQUEST_SUCCESS') {
            const integrationSetting: AutoIntegrationSettingData =
                getIntegrationSetting.response;
            setChecked(integrationSetting.data.settings.donationsAutoexport);
            setUsername(integrationSetting.data.credentials?.username);
            setLoading(false);
        }
    }, [getIntegrationSetting]);
    const [requestAutoExport, makeRequestAutoExport] = useApiRequest();
    const [disabled, setDisabled] = useState<boolean>(false);
    const changeAutoExport = (value: boolean) => {
        setChecked(value);
        makeRequestAutoExport(updateIntegrationAutoExport(userDoneeId, value));
        setDisabled(true);
    };
    useEffect(() => {
        if (requestAutoExport.type === 'REQUEST_SUCCESS') {
            setDisabled(false);
        }
    }, [requestAutoExport]);

    const copy = useMemo(
        () => ({
            exportTitle: t('f1SettingPage.exportTitle'),
            turnSetting: t('f1SettingPage.turnSetting'),
            f1Credential: t('f1SettingPage.f1Credential'),
            updateCredential: t('f1SettingPage.updateCredential'),
        }),
        [t],
    );

    return (
        <div className={classes.settingContent}>
            <div className={classes.turnSetting}>
                <GivelifyLabel
                    bold
                    text={copy.exportTitle}
                    variant="heading5"
                />
                <Space gap={2} />
                <GivelifyLabel
                    className={classes.settingDesc}
                    text={copy.turnSetting}
                    variant="body1"
                />
                <Space gap={3} />
                {loading && <GivelifyLoading type="circular" />}
                {!loading && (
                    <GivelifySwitch
                        checked={checked}
                        disabled={disabled}
                        onChange={changeAutoExport}
                        variant="thick-labeled"
                    />
                )}
            </div>
            <Divider />
            <div className={classes.credentialContent}>
                <GivelifyLabel
                    bold
                    marginBottom={24}
                    text={copy.f1Credential}
                    variant="heading5"
                />
                <Grid container>
                    <Grid item lg={6} md={8} xs={12}>
                        {loading && <FormLoading />}
                        {!loading && (
                            <CredentialForm
                                isReadOnly={isReadOnly}
                                updateCredential={copy.updateCredential}
                                username={username}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default F1SettingTabContent;
