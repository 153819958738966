import React, { useMemo, useState } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { Focused } from './Focused';
import { NotFocused } from './NotFocused';
import { useStyles } from './useStyles';

type OnValueUpdate = (value: string) => void;

export enum States {
    NOT_FOCUSED,
    FOCUSED,
}

export interface ExpandableInputProps {
    assignLabel: string;
    saveLabel: string;
    value: string;
    onValueUpdate: OnValueUpdate;
    readOnly?: boolean;
}

export const ExpandableInput: React.FCC<ExpandableInputProps> = ({
    assignLabel,
    saveLabel,
    value,
    onValueUpdate,
    readOnly,
}) => {
    const classes = useStyles();
    const [currentState, setCurrentState] = useState<States>(
        States.NOT_FOCUSED,
    );
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            NA: t(`pages.donor_details.na`),
        }),
        [t],
    );

    const isFocused = currentState === States.FOCUSED;

    return (
        <div className={classes.innerWrapper}>
            {isFocused ? (
                <Focused
                    classes={classes}
                    onValueUpdate={onValueUpdate}
                    saveLabel={saveLabel}
                    setState={setCurrentState}
                    value={value}
                />
            ) : readOnly ? (
                <GivelifyLabel
                    text={value?.length ? value : copy.NA}
                    variant="body2"
                />
            ) : (
                <NotFocused
                    label={assignLabel}
                    setState={setCurrentState}
                    value={value}
                />
            )}
        </div>
    );
};
