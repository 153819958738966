import React, { useCallback, useState } from 'react';
import { GivelifyTextFieldState, GivelifyTextField } from '../textField';
import { phoneValidation } from '../utils/phoneNumberUtils';

const phoneMask = '(000) 000-0000';

export interface PhoneNumberFieldProps {
    name?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    phone: string | undefined;
    onChange?: (phone: string) => unknown;
    className?: string;
    placeholder: string;
    showState?: boolean;
    phoneErrorMessage?: string;
}

export const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
    name,
    phone,
    inputRef,
    className,
    placeholder,
    onChange,
    showState = false,
    phoneErrorMessage,
}) => {
    const [newPhone, setPhone] = useState<string>(phone || '');
    const [inputState, setInputState] =
        React.useState<GivelifyTextFieldState>('idle');
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setPhone(value);
            onChange && onChange(value);
            setInputState(phoneValidation(value) ? 'idle' : 'error');
        },
        [setPhone, onChange, setInputState],
    );
    React.useEffect(() => {
        if (showState) {
            setInputState(phoneValidation(newPhone) ? 'idle' : 'error');
        }
        //eslint-disable-next-line
    }, [showState]);

    return (
        <GivelifyTextField
            fullWidth
            aria-label={placeholder}
            className={className}
            id="phone"
            inputRef={inputRef}
            label={placeholder}
            leftHelperText={
                inputState === 'error' ? phoneErrorMessage : undefined
            }
            maskOptions={{
                mask: phoneMask,
                lazy: false,
            }}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            state={inputState}
            value={newPhone}
        />
    );
};
