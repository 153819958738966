import React, { useMemo } from 'react';
import {
    GivelifyInfiniteLoader,
    IntegrationPaginatedResponse,
} from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { Envelope } from 'api/models';
import { ErrorText } from 'components/ErrorText';
import { useAdvancedTranslation } from 'utils/i18n';
import EnvelopeListItem from '../components/EnvelopeListItem';
import { envelopesPageStyle } from '../components/envelopesPageStyle';
import NoItemsPanel from '../components/NoItemsPanel';
import { useEnvelopesContext } from '../context/EnvelopesProvider';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
}));

const InactiveEnvelopesPage: React.FCC = () => {
    const { envelopesContainer, envelopesListContainer } = envelopesPageStyle();
    const classes = useStyles();
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            title: at('pages.settings.envelopes2.inactive-envelopes-tab.title'),
            pageLoadFail: t('labels.page-load-fail'),
            zero: at('pages.settings.envelopes2.inactive-envelopes-tab.zero'),
        }),
        [t, at],
    );

    const {
        inactiveEnvelopes: data,
        getInactiveEnvelopesRequestState: requestState,
        inactiveEnvelopesPageNumber: pageNumber,
        loadNewInactivePage: loadNextPage,
    } = useEnvelopesContext();

    return (
        <div className={envelopesContainer}>
            <div
                aria-label="Inactive envelopes list"
                className={envelopesListContainer}
            >
                <GivelifyInfiniteLoader
                    ErrorComponent={<ErrorText text={copy.pageLoadFail} />}
                    LoadingComponent={<div />}
                    NoDataComponent={<NoItemsPanel type="hidden" />}
                    data={data}
                    isError={requestState === 'REQUEST_ERROR'}
                    isLoading={requestState === 'REQUEST_START'}
                    loadNextPage={loadNextPage}
                    pageNumber={pageNumber}
                    renderData={(
                        dataSet: IntegrationPaginatedResponse<Envelope>,
                    ) => (
                        <div className={classes.wrapper}>
                            {dataSet.data.map((item) => (
                                <EnvelopeListItem key={item.id} {...item} />
                            ))}
                        </div>
                    )}
                    reset={undefined}
                />
            </div>
        </div>
    );
};

export default InactiveEnvelopesPage;
