import React from 'react';
import { Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { SinglePollsIcon } from '../assets';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { PollAnswer } from './PollAnswer';
import {
    AnswersWrapper,
    CollapseButton,
    CollapseWrapper,
    Divider,
    IconWrapper,
    PollHeader,
    PollWrapper,
    QuestionButton,
    QuestionsWrapper,
    RightInfo,
} from './styles';

type Answer = {
    id: number;
    answer: string;
    votes: number;
    selectedByGivingPartnerUser: boolean;
    isCustomAnswer: boolean;
};

export type GivelifySinglePollsProps = {
    title: string;
    active: boolean;
    isAnswered: boolean;
    endDate: dayjs.Dayjs;
    totalAnswers: number;
    answers: Answer[];
    onPollAnswer: (answer: Answer) => void;
    onCollapseToggle: () => void;
    open?: boolean;
};

export const GivelifySinglePolls: React.FCC<GivelifySinglePollsProps> = ({
    title,
    active,
    isAnswered,
    endDate,
    totalAnswers,
    answers,
    onPollAnswer,
    onCollapseToggle,
    open = true,
}) => {
    const { t } = useTranslation();

    const copy = React.useMemo(
        () => ({
            chooseAnswerToSeeResults: t(
                'components.poll.chooseAnswerToSeeResults',
            ),
            vote: t('components.poll.vote'),
            votes: t('components.poll.votes'),
            daysLeft: t('components.poll.daysLeft'),
            dayLeft: t('components.poll.dayLeft'),
            thanksForParticipating: t('components.poll.thanksForParticipating'),
            pollEnded: t('components.poll.pollEnded'),
            pollEndedDesc: t('components.poll.pollEndedDesc'),
        }),
        [t],
    );

    const showQuestions = React.useMemo(() => {
        return !isAnswered && active;
    }, [isAnswered, active]);

    const daysLeft = React.useMemo(() => {
        return endDate.diff(dayjs().startOf('day'), 'days');
    }, [endDate]);

    return (
        <PollWrapper data-testid="givelify-single-poll" showBgImg={open}>
            <PollHeader>
                <IconWrapper>
                    <SinglePollsIcon height="32px" width="32px" />
                </IconWrapper>
                <GivelifyLabel data-testid="poll-title" variant="heading2S">
                    {title}
                </GivelifyLabel>
                <RightInfo>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        data-testid="poll-total-answers"
                        text={`${totalAnswers} ${
                            totalAnswers === 1 ? copy.vote : copy.votes
                        }`}
                        variant="body2"
                    />
                    <Divider />
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        data-testid="poll-days-left"
                        text={
                            active
                                ? `${daysLeft} ${
                                      daysLeft === 1
                                          ? copy.dayLeft
                                          : copy.daysLeft
                                  }`
                                : copy.pollEnded
                        }
                        variant="body2"
                    />
                    <CollapseButton
                        data-testid="toggle-poll-button"
                        onClick={onCollapseToggle}
                        text={
                            open ? (
                                <GivelifyIcon
                                    color={DesignTokens.color.iconPrimary}
                                    fontSize={24}
                                    variant="up-chevron"
                                />
                            ) : (
                                <GivelifyIcon
                                    color={DesignTokens.color.iconPrimary}
                                    fontSize={24}
                                    variant="down-chevron"
                                />
                            )
                        }
                        variant="ghost"
                    />
                </RightInfo>
            </PollHeader>
            <Collapse in={open}>
                <CollapseWrapper data-testid="poll-collapsible-wrapper">
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        data-testid="poll-description"
                        text={
                            active
                                ? isAnswered
                                    ? copy.thanksForParticipating
                                    : copy.chooseAnswerToSeeResults
                                : copy.pollEndedDesc
                        }
                        variant="body2"
                    />

                    {showQuestions ? (
                        <QuestionsWrapper>
                            {answers.map((answer) => (
                                <QuestionButton
                                    key={answer.id}
                                    data-testid="poll-answer"
                                    onClick={() => onPollAnswer(answer)}
                                    text={
                                        <GivelifyLabel
                                            text={answer.answer}
                                            variant="body2"
                                        />
                                    }
                                    variant="primaryAlt"
                                />
                            ))}
                        </QuestionsWrapper>
                    ) : (
                        <AnswersWrapper>
                            {answers.map((answer) => (
                                <PollAnswer
                                    key={answer.id}
                                    fillPercent={Math.round(
                                        (answer.votes / (totalAnswers || 1)) *
                                            100,
                                    )}
                                    isSelected={
                                        answer.selectedByGivingPartnerUser
                                    }
                                    text={answer.answer}
                                />
                            ))}
                        </AnswersWrapper>
                    )}
                </CollapseWrapper>
            </Collapse>
        </PollWrapper>
    );
};
