/* eslint-disable react/display-name */
import * as React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { Virtuoso } from 'react-virtuoso';
import EnvelopeListItem from '../../../components/EnvelopeListItem';
import { useEnvelopesContext } from '../../../context/EnvelopesProvider';
import MobileSortModal from './MobileSortModal';

const ItemContainerSortable = ReactSortableHOC.SortableElement((props) => (
    <div {...props} />
));
const ListContainerSortable = ReactSortableHOC.SortableContainer<{
    listRef: React.ForwardedRef<unknown>;
}>(({ listRef, ...props }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        <div
            ref={listRef}
            {...props}
            style={{
                ...props.style,
                display: 'flex',
                flexDirection: 'column',
                gap: isMobile ? 16 : 24,
            }}
        />
    );
});

interface SortableActiveEnvelopeListProps {
    isOpenSortModal?: boolean;
    closeSortModal?: () => void;
}

export const SortableActiveEnvelopeList: React.FunctionComponent<
    SortableActiveEnvelopeListProps
> = (props) => {
    const { isOpenSortModal, closeSortModal } = props;

    const {
        activeEnvelopes: items,
        dragAndDrop,
        dndDisabled,
    } = useEnvelopesContext();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const list: any = React.useMemo(
        () =>
            React.forwardRef((props, ref) => {
                return (
                    <ListContainerSortable
                        {...props}
                        disableAutoscroll
                        useDragHandle
                        listRef={ref}
                        lockAxis="y"
                        onSortEnd={(...args) => {
                            if (dndDisabled) return;
                            dragAndDrop(args[0].oldIndex, args[0].newIndex);
                        }}
                        shouldCancelStart={() => dndDisabled}
                    />
                );
            }),
        [dndDisabled, dragAndDrop],
    );

    const item = React.useMemo(
        () => (props) =>
            <ItemContainerSortable index={props['data-index']} {...props} />,
        [],
    );

    return (
        <>
            <Virtuoso
                useWindowScroll
                components={{
                    List: list,
                    Item: item,
                }}
                data={items}
                itemContent={(index, item) => (
                    <EnvelopeListItem key={item.id} {...item} />
                )}
                style={{ width: '100%', marginTop: 24 }}
            />
            <MobileSortModal
                item={item}
                list={list}
                onClose={closeSortModal}
                open={isOpenSortModal}
            />
        </>
    );
};
