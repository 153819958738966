import React from 'react';
import { NoResultsProps, TableNoResults } from 'components/tableNoResults';
import { Wrapper } from './styles';

const NoResultsWrapperComponent: React.FCC<NoResultsProps> = (props) => {
    return (
        <Wrapper>
            <TableNoResults
                hasFilters={props.hasFilters}
                name="donations-activity"
                search={props.search}
            />
        </Wrapper>
    );
};

export const NoResultsWrapper = React.memo(NoResultsWrapperComponent);
