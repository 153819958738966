import React, { useMemo } from 'react';
import {
    FacebookLoading,
    GivelifyButton,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { mergeClassNames, formatPhoneNumber } from '@givelify/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { OrganizationSuggestionsResponse } from '../../types';
import useStyles from './styles';

interface ListItemProps {
    data?: OrganizationSuggestionsResponse;
    isLoading?: boolean;
    onSelect?: (item: OrganizationSuggestionsResponse) => void;
}

const ListItem: React.FCC<ListItemProps> = ({ isLoading, data, onSelect }) => {
    const classes = useStyles();

    const { campuses } = useSelector((state: AppState) => ({
        campuses: state.Donee.campuses,
    }));

    const organizationId = parseInt(data?.id);
    const { name, address, city, state, zip, phone, unregistered, doneeId } =
        data?.source || {};

    const yourCampus = useMemo(
        () => campuses.find((c) => c.id === organizationId),
        [organizationId, campuses],
    );

    const accountClaimed =
        !yourCampus && organizationId > 0 && unregistered === '0';

    const canAdd = !yourCampus && !accountClaimed;

    const formattedPhone = useMemo(
        () => (phone ? formatPhoneNumber(phone) : ''),
        [phone],
    );

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY:
            'pages.settings.campuses.addCampusModal.searchForm.resultList.listItem',
    });

    const copy = useMemo(
        () => ({
            addCampus: scopedTranslate('addCampus'),
            yourCampus: scopedTranslate('yourCampus'),
            accountClaimed: scopedTranslate('accountClaimed'),
        }),
        [scopedTranslate],
    );

    return (
        <div
            className={mergeClassNames(
                classes.campusListItemWrapper,
                !isLoading && classes.borderHover,
            )}
        >
            {isLoading ? (
                <FacebookLoading borderRadius={0} height={175} width="auto" />
            ) : (
                <div className={classes.listItemContent}>
                    <div className={classes.listItemText}>
                        <GivelifyLabel
                            bold
                            className={classes.listItemHeader}
                            id={`name-${doneeId}`}
                            text={name}
                            variant="body2"
                        />
                        <GivelifyLabel
                            id={`address-${doneeId}`}
                            text={address}
                            variant="body2"
                        />
                        <GivelifyLabel
                            id={`citystatezip-${doneeId}`}
                            text={`${city} ${state} ${zip}`}
                            variant="body2"
                        />
                        <GivelifyLabel
                            id={`telephone-${doneeId}`}
                            text={formattedPhone}
                            variant="body2"
                        />
                    </div>
                    <div className={classes.listItemAction}>
                        {canAdd && (
                            <GivelifyButton
                                bold
                                data-doneeId={doneeId}
                                id={`addCampus-${doneeId}`}
                                onClick={() => onSelect(data)}
                                text={copy.addCampus}
                                variant="link"
                            />
                        )}
                        {yourCampus && (
                            <GivelifyLabel
                                className={mergeClassNames(
                                    classes.chip,
                                    classes.yourCampusChip,
                                )}
                                text={copy.yourCampus}
                                variant="body2"
                            />
                        )}
                        {accountClaimed && (
                            <GivelifyLabel
                                className={mergeClassNames(
                                    classes.chip,
                                    classes.accountClaimedChip,
                                )}
                                text={copy.accountClaimed}
                                variant="body2"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListItem;
