import React, { useMemo, useState } from 'react';
import { GivelifyLabel, GivelifySelect } from '@givelify/givelify-ui';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DATES,
    MONDAY,
    SCHEDULED_REPORT_FREQUENCIES,
    ScheduledReport,
    Weekday,
    WEEKDAYS,
    WEEKLY,
} from 'store/integration/types';

interface IntegrationSetupFrequencyProps {
    reportExists: boolean;
    form: UseFormMethods<ScheduledReport>;
    scheduledReport: ScheduledReport;
    resetFormDates: (frequency: ScheduledReport['frequency']) => void;
    cadenceSubSelectType: string;
    onDateChange: () => void;
    onChangeDaySelected: (value: Weekday) => void;
    onChangeDateSelected: (value: number) => void;
    isReadOnly: boolean;
}

export const inputStyle = makeStyles((theme: Theme) => ({
    newSetting: {
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    oldSetting: {
        flexWrap: 'wrap',
    },
    select: {
        width: 250,
        marginRight: theme.spacing(2),
        maxWidth: 250,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '16px auto 8px',
        },
    },
    dateGenerate: {
        marginTop: theme.spacing(4),
    },
}));

const IntegrationSetupFrequency: React.FCC<IntegrationSetupFrequencyProps> = ({
    reportExists,
    scheduledReport,
    resetFormDates,
    cadenceSubSelectType,
    onDateChange,
    onChangeDaySelected,
    onChangeDateSelected,
    isReadOnly,
}) => {
    const { newSetting, oldSetting, select, dateGenerate } = inputStyle();

    const { t: trans } = useTranslation();

    const copy = useMemo(
        () => ({
            weekGenerate: trans('pages.integrations.settings.weekGenerate'),
            monthGenerate: trans('pages.integrations.settings.monthGenerate'),
            cadences: SCHEDULED_REPORT_FREQUENCIES.map((freq) => ({
                frequency: freq,
                label: trans(`report_cadence.${freq}`),
            })),
            frequency: trans('pages.integrations.settings.frequency'),
            selectDay: trans('pages.integrations.settings.selectDay'),
            selectDate: trans('pages.integrations.settings.selectDate'),
        }),
        [trans],
    );

    const [frequency, setFrequency] = useState<ScheduledReport['frequency']>(
        scheduledReport?.frequency,
    );
    const onChangeFrequency = (value) => {
        setFrequency(value.key);
        resetFormDates(value.key);
    };
    const onDayReportChange = (value) => {
        onChangeDaySelected(value.key);
        onDateChange();
    };
    const onDateReportChange = (value) => {
        onChangeDateSelected(value.key);
        onDateChange();
    };

    return (
        <Box className={!reportExists ? newSetting : oldSetting}>
            <GivelifySelect
                ariaLabel="frequency"
                defaultValue={frequency || WEEKLY}
                disabled={isReadOnly}
                id="frequency"
                inputLabel="frequency"
                label={copy.frequency}
                marginTop={8}
                name="frequency"
                onChange={onChangeFrequency}
                options={copy.cadences.map((freqCopy) => ({
                    key: freqCopy.frequency,
                    value: freqCopy.frequency,
                }))}
                size="dense"
                width={250}
            />
            {cadenceSubSelectType && (
                <GivelifyLabel
                    bold
                    className={dateGenerate}
                    marginBottom={8}
                    text={
                        cadenceSubSelectType === 'date'
                            ? copy.monthGenerate
                            : copy.weekGenerate
                    }
                    variant="body1"
                />
            )}
            {cadenceSubSelectType === 'day' && (
                <GivelifySelect
                    ariaLabel="scheduleDay"
                    className={select}
                    defaultValue={scheduledReport?.scheduledDay || MONDAY}
                    disabled={isReadOnly}
                    inputLabel="scheduleDay"
                    label={copy.selectDay}
                    name="scheduledDay"
                    onChange={onDayReportChange}
                    options={WEEKDAYS.map((freq) => ({
                        key: freq,
                        value: freq,
                    }))}
                    width={250}
                />
            )}
            {cadenceSubSelectType === 'date' && (
                <GivelifySelect
                    ariaLabel="scheduledDate"
                    className={select}
                    defaultValue={scheduledReport?.scheduledDate || '1'}
                    disabled={isReadOnly}
                    inputLabel="scheduledDate"
                    label={copy.selectDate}
                    name="scheduledDate"
                    onChange={onDateReportChange}
                    options={DATES.map((freq) => ({
                        key: freq,
                        value: freq.toString(),
                    }))}
                    width={250}
                />
            )}
        </Box>
    );
};

export default IntegrationSetupFrequency;
