import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled, keyframes } from '@mui/material';
import { VariantColor } from './styles';
import { BubbleVariant } from './types';

export type DataBubbleProps = {
    variant: BubbleVariant;
    count: number;
    maxCount: number;
    isActive?: boolean;
};

export const maxBubbleSize = 96;

type BubbleProps = {
    variant: BubbleVariant;
    circleRadius: number;
};

const renderAnimation = (size: number) => keyframes`
      from {
          width: 0;
          height: 0;
      }
      to {
          width: ${size}px;
          height: ${size}px;
      }
`;

const Bubble = styled('div', {
    shouldForwardProp: (prop) => prop !== 'circleRadius' && prop !== 'variant',
})<BubbleProps>((props) => ({
    backgroundColor: VariantColor[props.variant],
    '&.active': {
        backgroundColor: DesignTokens.color.textWhite,
    },
    border: `1px solid ${VariantColor[props.variant]}`,
    transition: '0.3s ease',
    minWidth: '21px',
    minHeight: '21px',
    maxWidth: maxBubbleSize,
    maxHeight: maxBubbleSize,
    animation: `${renderAnimation(
        props.circleRadius * maxBubbleSize,
    )} 0.3s ease forwards`,
    borderRadius: '100%',
}));

type BubbleTextProps = {
    type: BubbleVariant;
} & Omit<BubbleProps, 'variant'>;

const BubbleText = styled(GivelifyLabel, {
    shouldForwardProp: (prop) => prop !== 'circleRadius' && prop !== 'variant',
})<BubbleTextProps>((props) => ({
    position: props.circleRadius <= 0.5 ? 'relative' : 'absolute',
    transition: '0.3s ease',
    color: props.circleRadius <= 0.5 ? 'inherit' : DesignTokens.color.textWhite,
    visibility: props.circleRadius > 0 ? 'visible' : 'hidden',
    '&.active': {
        color: VariantColor[props.type],
    },
}));

const BubbleWrapper = styled('div')({
    width: `${maxBubbleSize}px`,
    height: `${maxBubbleSize}px`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
});

export const DataBubble: React.FCC<DataBubbleProps> = ({
    variant,
    maxCount,
    count,
    isActive = false,
}) => {
    const circleRadius = count / maxCount;
    return (
        <BubbleWrapper>
            <Bubble
                circleRadius={circleRadius}
                className={isActive ? 'active' : ''}
                data-testid="bubble"
                variant={variant}
            />
            <BubbleText
                circleRadius={circleRadius}
                className={isActive ? 'active' : ''}
                data-testid="bubble-text"
                type={variant}
            >
                {count}
            </BubbleText>
        </BubbleWrapper>
    );
};
