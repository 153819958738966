import React, { useEffect, useMemo, useState } from 'react';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '@givelify/api';
import {
    GivelifyLabel,
    initialIntegrationPaginatedResponse,
    IntegrationPaginatedResponse,
    GivelifyInfiniteLoader,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest, useTimeFrameEffect } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { DonationRow } from 'api/models';
import { DataTableSortDirection } from 'components';
import dayjs from 'dayjs';
import { mapDonationRowToDonationItemRowProps } from 'pages/donations/utils/DonationsRowUtils';
import { createPortal } from 'react-dom';
import { usePreloadNoDataImages } from '../../hooks/usePreloadNoDataImages';
import { DonationTableHeader } from '../DonationTableHeader';
import { NoData } from '../noData';
import { NoResultsWrapper } from '../noResultsWrapper';
import { TotalDonations } from '../totalDonations';
import DonationActivityRow, {
    DonationItemRowProps,
} from './DonationActivityRow';
import DonationActivityRowsLoading from './DonationActivityRowLoading';
import { getDataForInfiniteLoader } from './service';
import useStyles from './styles';

type DonationActivityResponse = IntegrationPaginatedResponse<DonationRow> & {
    lifetimeTotals?: {
        average: number;
        count: number;
        donorsCount: number;
        netSum: number;
        sum: number;
    };
};

type DonationActivityInfiniteLoaderProps = {
    doneeId: number;
    timeFrame: TimeFrameValue;
    onSortRequest: (field: string, direction: DataTableSortDirection) => void;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    envelopesLoading?: boolean;
    sort: string;
    search?: string;
    hasFilters: boolean;
    showNoData: boolean;
    setShowNoData: (flag: boolean) => void;
    hasMid: boolean;
    orgName: string;
    city: string;
    allDocumentsInReview: boolean;
    onLoadingChange?: (loading: boolean) => void;
    totalDonationAmountRef?: React.MutableRefObject<HTMLDivElement>;
};

const DonationActivityInfiniteLoader: React.FCC<
    DonationActivityInfiniteLoaderProps
> = ({
    doneeId,
    timeFrame,
    envelopeIds,
    messageFilter,
    onSortRequest,
    sort,
    transactionFilter,
    envelopesLoading,
    search,
    hasFilters,
    showNoData,
    setShowNoData,
    allDocumentsInReview,
    hasMid,
    orgName,
    city,
    onLoadingChange,
    totalDonationAmountRef,
}) => {
    const { rowsWrapper, borderTop, divider } = useStyles();

    const { t } = useAdvancedTranslation();
    const DonationRowText = useMemo(
        () => ({
            activityError: t('error.errorDonationsActivity'),
        }),
        [t],
    );

    const [isLoading, setIsLoading] = useState(true);
    const [dataSet, setData] = useState<DonationActivityResponse>(
        initialIntegrationPaginatedResponse,
    );

    const [requestState, makeRequest] =
        useApiRequest<DonationActivityResponse>();

    const [requestData, setRequestData] = useState<{
        doneeId: number;
        pageNumber: number;
        timeFrame: TimeFrameValue;
        transactionFilter: TRANSACTION_FILTER_VALUE;
        envelopeIds: number[];
        messageFilter: MESSAGE_FILTER_VALUE[];
        search?: string;
        sort?: string;
    }>({
        doneeId,
        transactionFilter,
        timeFrame,
        envelopeIds,
        messageFilter,
        pageNumber: 1,
        search,
        sort,
    });
    const startStamp = dayjs(timeFrame.start).format('YYYY-MM-DD');
    const endStamp = dayjs(timeFrame.end).format('YYYY-MM-DD');

    usePreloadNoDataImages();

    useEffect(
        () => {
            setRequestData({
                doneeId,
                timeFrame,
                transactionFilter,
                envelopeIds,
                messageFilter,
                pageNumber: 1,
                search,
                sort,
            });
        },
        // listen only to these changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            doneeId,
            startStamp,
            endStamp,
            timeFrame.selector,
            transactionFilter,
            envelopeIds,
            messageFilter,
            search,
            sort,
        ],
    );

    useEffect(() => {
        setData(initialIntegrationPaginatedResponse);
    }, [requestData.doneeId]);

    useTimeFrameEffect(
        () => {
            setIsLoading(true);
            if (onLoadingChange) {
                onLoadingChange(true);
            }
            if (envelopesLoading) return;
            void makeRequest(
                getDataForInfiniteLoader(
                    requestData.doneeId,
                    requestData.pageNumber,
                    requestData.timeFrame,
                    requestData.transactionFilter,
                    requestData.envelopeIds,
                    requestData.messageFilter,
                    requestData.search,
                    requestData.sort,
                ),
            );
        },
        requestData.timeFrame,
        [
            makeRequest,
            requestData.doneeId,
            requestData.pageNumber,
            requestData.transactionFilter,
            requestData.envelopeIds,
            requestData.messageFilter,
            requestData.search,
            requestData.sort,
            envelopesLoading,
            onLoadingChange,
        ],
    );

    useEffect(() => {
        if (requestState.type !== 'REQUEST_SUCCESS') return;
        if (requestState.response.meta.currentPage === 1) {
            setData({
                data: requestState.response.data,
                meta: requestState.response.meta,
                lifetimeTotals: requestState.response.lifetimeTotals,
            });
        } else {
            const mergeData = [...dataSet.data, ...requestState.response.data];
            const uniqueData = mergeData.filter(
                (value, index, self) =>
                    self.findIndex((item) => item.id === value.id) === index,
            );
            setData((prevDataSet) => ({
                ...prevDataSet,
                data: uniqueData,
            }));
        }
        setIsLoading(false);
        if (onLoadingChange) {
            onLoadingChange(false);
        }
        // listen only on requestState change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestState]);

    React.useEffect(() => {
        setShowNoData(
            !isLoading && dataSet.data.length === 0 && !hasFilters && !search,
        );
    }, [setShowNoData, isLoading, dataSet, dataSet.data, hasFilters, search]);

    const Error = (
        <GivelifyLabel
            bold
            marginTop={16}
            text={DonationRowText.activityError}
            variant="heading5"
        />
    );

    const Wrapper = (Component: JSX.Element) => (
        <div className={borderTop}>{React.cloneElement(Component)}</div>
    );

    const loadNextPage = () => {
        if (isLoading) return;
        setIsLoading(true);
        if (onLoadingChange) {
            onLoadingChange(true);
        }
        setRequestData((prev) => ({
            ...prev,
            pageNumber: prev.pageNumber + 1,
        }));
    };

    return (
        <div>
            {showNoData ? (
                <NoData
                    city={city}
                    documentsSubmitted={allDocumentsInReview}
                    hasMid={hasMid}
                    orgName={orgName}
                />
            ) : (
                <>
                    {totalDonationAmountRef?.current
                        ? createPortal(
                              <TotalDonations
                                  doneeId={requestData.doneeId}
                                  envelopeIds={requestData.envelopeIds}
                                  messageFilter={requestData.messageFilter}
                                  search={requestData.search}
                                  timeFrame={requestData.timeFrame}
                                  transactionFilter={
                                      requestData.transactionFilter
                                  }
                              />,
                              totalDonationAmountRef?.current,
                          )
                        : null}
                    <DonationTableHeader
                        hasFilters={
                            dataSet.meta.total < dataSet.lifetimeTotals?.count
                        }
                        loading={isLoading}
                        onSortRequest={onSortRequest}
                        sort={sort}
                        totalCount={dataSet.meta.total}
                        totalUnfilteredDonations={dataSet.lifetimeTotals?.count}
                    />
                    <GivelifyInfiniteLoader
                        ErrorComponent={Wrapper(Error)}
                        LoadingComponent={<DonationActivityRowsLoading />}
                        NoDataComponent={
                            <NoResultsWrapper
                                hasFilters={hasFilters}
                                search={search}
                            />
                        }
                        data={dataSet}
                        isError={requestState.type === 'REQUEST_ERROR'}
                        isLoading={isLoading}
                        loadNextPage={loadNextPage}
                        pageNumber={requestData.pageNumber}
                        renderData={(
                            dataSet: IntegrationPaginatedResponse<DonationRow>,
                        ) => (
                            <div
                                className={rowsWrapper}
                                data-testid="donations-rows-wrapper"
                            >
                                {dataSet.data.map((r, index, array) => {
                                    const data: DonationItemRowProps =
                                        mapDonationRowToDonationItemRowProps(r);
                                    return data && data.id ? (
                                        <React.Fragment key={r.id}>
                                            <DonationActivityRow {...data} />
                                            {index !== array.length - 1 && (
                                                <div className={divider} />
                                            )}
                                        </React.Fragment>
                                    ) : null;
                                })}
                            </div>
                        )}
                        reset={undefined}
                    />
                </>
            )}
        </div>
    );
};

export default DonationActivityInfiniteLoader;
