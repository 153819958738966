import { defaultStartDate } from '@givelify/utils';
import { Campus } from './Campus';

/** User's role. Make accept one of the following values: 1 - Basic; 2 - Financial; 3 - Admin; 4 - Admin Panel User */
export type UserRoleId = 1 | 2 | 3 | 4;

/** User's role name. */
export type UserRole = 'Basic' | 'Financial' | 'Admin' | 'Admin Panel User';

export type SignedUser = {
    /**
     * Same as officialId
     * @format int64
     */
    id: number;
    /**
     * ID for the user
     * @format int64
     */
    officialId?: number;
    doneeId: number;
    employeeId?: string;
    /**
     * User's title
     * @maxLength 25
     */
    title: string;
    /** @maxLength 25 */
    phone: string;
    /**
     * User's first name
     * @minLength 3
     * @maxLength 55
     */
    name: string;
    /**
     * User's last name
     * @minLength 1
     * @maxLength 55
     */
    lname: string;
    /**
     * @minLength 7
     * @maxLength 111
     */
    fullName: string;
    /**
     * @format email
     * @maxLength 255
     */
    email: string;
    picture?: string;
    /** @maxLength 20 */
    exAvatar?: string;
    /** @maxLength 255 */
    avatar?: string;
    avatarCroppedCoordinates?: string;
    avatarOriginal?: string;
    preferences?: any[];
    hasIntegration?: boolean;
    isSuper?: boolean;
    isSynagogue?: boolean;
    notify?: boolean;
    /** @maxLength 255 */
    doneeName?: string;
    /** @format date-time */
    doneeSignupDate?: string;
    address?: string;
    address2?: string;
    city?: string;
    /**
     * Donee's State
     * @maxLength 55
     * @example "IN"
     */
    state?: string;
    /** @maxLength 50 */
    country?: string | null;
    /**
     * @maxLength 5
     * @example "12345"
     */
    zip?: string;
    /** @maxLength 20 */
    type: 'church' | 'nonprofit' | null;
    campuses?: Campus[];
    parentCampus?: string | null;
    photo?: string;
    /** User's role name. */
    role: UserRole;
    /** User's role. Make accept one of the following values: 1 - Basic; 2 - Financial; 3 - Admin; 4 - Admin Panel User */
    roleId?: UserRoleId;
    givingPartnerTimezone?: string;
};

export type User = Omit<SignedUser, 'name' | 'lname' | 'type'> & {
    username: string;
    firstName: string;
    lastName: string;
    profilePicUrl?: string;
    createdDateUser: Date;
};

export const mapSignedUserToLocalUser = (user: SignedUser): User => {
    const { name, lname, type, ...restUser } = user;
    return {
        ...restUser,
        username: user.email,
        profilePicUrl: user.picture,
        createdDateUser: new Date(user.doneeSignupDate || defaultStartDate),
        firstName: user.name,
        lastName: user.lname,
    };
};
