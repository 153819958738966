import React from 'react';
import { Deposit } from '@givelify/api';
import { GivelifyModal } from '@givelify/ui';
import { formatWithLocaltime } from '@givelify/utils';
import { Theme, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatMoneyWithCommas } from '../../../../utils/formatMoneyWithCommas';
import { scrollBarStyles } from '../../components/style';
import { BankDepositModalPagination } from './BankDepositModalPagination';

type BankDepositModalProps = {
    donation: Deposit | undefined;
    handleClose: () => unknown;
};

const useModalTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {
            '& .no-data': {
                padding: 10,
            },
            '& .pagination': {
                float: 'right',
            },
            '& .pagination td': {
                border: 'none',
            },
            '& .table-container': {
                height: '65vh',
                scrollbarColor: '#cacaca transparent',
                scrollbarWidth: 'thin',
                zIndex: 1,
                ...scrollBarStyles(),
            },
            '& .table-head': {
                borderTop: '1px solid #CAD2DB',
                borderBottom: '1px solid #CAD2DB',
                '& th': {
                    lineHeight: '16px',
                    background: theme.palette.background.paper,
                    borderTop: '1px solid #CAD2DB',
                },
            },
            '& .heading': {
                padding: theme.spacing(6),
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '26px',
            },
        },
    }),
);

const BankDepositModal: React.FCC<BankDepositModalProps> = ({
    donation,
    handleClose,
}) => {
    const { t } = useTranslation();
    const { modalContent } = useModalTableStyles();
    if (!donation) return null;
    return (
        <GivelifyModal
            fullWidth
            noPadding
            showCloseButton
            aria-label="Deposit details"
            maxWidth="lg"
            onClose={handleClose}
            open={true}
        >
            <div className={modalContent}>
                <Typography className="heading" variant="h2">
                    {t('bankDeposits.totalDepositOn')}{' '}
                    {formatWithLocaltime(donation.depositDate)} - $
                    {formatMoneyWithCommas(donation.depositAmount)}
                </Typography>
                <BankDepositModalPagination donation={donation} />
            </div>
        </GivelifyModal>
    );
};

export default BankDepositModal;
