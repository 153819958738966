import React from 'react';
import { useWindowSize } from '@givelify/utils';
import { FilterContent } from './FilterContent';
import { FilterFormWrapper, FilterSlide, FilterBackdrop } from './styles';

export type FilterComponentProps = {
    disabled?: boolean;
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    onUndo: () => void;
    onClear: () => void;
    submitText?: string;
    canSubmit?: boolean;
    canClear?: boolean;
    canUndo?: boolean;
    children?: React.ReactNode;
};

export const FilterPanel: React.FCC<FilterComponentProps> = ({
    open,
    onClose,
    onSubmit,
    onUndo,
    onClear,
    submitText,
    canSubmit,
    canClear,
    canUndo,
    children,
}) => {
    const { innerHeight } = useWindowSize();
    return (
        <>
            <FilterSlide
                data-testid="filter-slide"
                direction="left"
                in={open}
                vh={innerHeight}
            >
                <FilterFormWrapper>
                    <FilterContent
                        canClear={canClear}
                        canSubmit={canSubmit}
                        canUndo={canUndo}
                        onClear={onClear}
                        onClose={onClose}
                        onSubmit={onSubmit}
                        onUndo={onUndo}
                        submitText={submitText}
                    >
                        {children}
                    </FilterContent>
                </FilterFormWrapper>
            </FilterSlide>
            <FilterBackdrop
                invisible
                data-testid="filter-backdrop-overlay"
                open={open}
            />
        </>
    );
};
