import React, { useEffect, useMemo, useState } from 'react';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '@givelify/api';
import {
    GivelifyLabel,
    initialIntegrationPaginatedResponse,
    IntegrationPaginatedResponse,
    GivelifyInfiniteLoader,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { DonationRow } from 'api/models';
import dayjs from 'dayjs';
import NoResultsScreen from '../../components/NoResultsScreen';
import { mapDonationRowToDonationItemRowProps } from '../../utils/DonationsRowUtils';
import DonationActivityRow, {
    DonationItemRowProps,
} from './DonationActivityRow';
import DonationActivityRowsLoading from './DonationActivityRowLoading';
import { getDataForInfiniteLoader } from './service';
import useStyles from './styles';

type DonationActivityInfiniteLoaderProps = {
    doneeId: number;
    timeFrame: TimeFrameValue;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    envelopesLoading?: boolean;
};

const DonationActivityInfiniteLoader: React.FCC<
    DonationActivityInfiniteLoaderProps
> = ({
    doneeId,
    timeFrame,
    envelopeIds,
    messageFilter,
    transactionFilter,
    envelopesLoading,
}) => {
    const { wrapper, rowsWrapper, borderTop, divider } = useStyles();

    const { t } = useAdvancedTranslation();
    const DonationRowText = useMemo(
        () => ({
            activityError: t('error.errorDonationsActivity'),
        }),
        [t],
    );

    const [isLoading, setIsLoading] = useState(true);
    const [dataSet, setData] = useState<
        IntegrationPaginatedResponse<DonationRow>
    >(initialIntegrationPaginatedResponse);

    const [requestState, makeRequest] =
        useApiRequest<IntegrationPaginatedResponse<DonationRow>>();

    const [requestData, setRequestData] = useState<{
        doneeId: number;
        pageNumber: number;
        timeFrame: TimeFrameValue;
        transactionFilter: TRANSACTION_FILTER_VALUE;
        envelopeIds: number[];
        messageFilter: MESSAGE_FILTER_VALUE[];
    }>({
        doneeId,
        transactionFilter,
        timeFrame,
        envelopeIds,
        messageFilter,
        pageNumber: 1,
    });
    const startStamp = dayjs(timeFrame.start).format('YYYY-MM-DD');
    const endStamp = dayjs(timeFrame.end).format('YYYY-MM-DD');
    useEffect(
        () => {
            setRequestData({
                doneeId,
                timeFrame,
                transactionFilter,
                envelopeIds,
                messageFilter,
                pageNumber: 1,
            });
        },
        // listen only to these changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            doneeId,
            startStamp,
            endStamp,
            timeFrame.selector,
            transactionFilter,
            envelopeIds,
            messageFilter,
        ],
    );

    useEffect(() => {
        setData(initialIntegrationPaginatedResponse);
    }, [requestData.doneeId]);

    useEffect(() => {
        setIsLoading(true);
        if (envelopesLoading) return;
        void makeRequest(
            getDataForInfiniteLoader(
                requestData.doneeId,
                requestData.pageNumber,
                requestData.timeFrame,
                requestData.transactionFilter,
                requestData.envelopeIds,
                requestData.messageFilter,
            ),
        );
    }, [
        makeRequest,
        requestData.doneeId,
        requestData.pageNumber,
        requestData.timeFrame,
        requestData.transactionFilter,
        requestData.envelopeIds,
        requestData.messageFilter,
        envelopesLoading,
    ]);

    useEffect(() => {
        if (requestState.type !== 'REQUEST_SUCCESS') return;
        if (requestState.response.meta.currentPage === 1) {
            setData({
                data: requestState.response.data,
                meta: requestState.response.meta,
            });
        } else {
            const mergeData = [...dataSet.data, ...requestState.response.data];
            const uniqueData = mergeData.filter(
                (value, index, self) =>
                    self.findIndex((item) => item.id === value.id) === index,
            );
            setData((prevDataSet) => ({
                ...prevDataSet,
                data: uniqueData,
            }));
        }
        setIsLoading(false);
        // listen only on requestState change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestState]);

    const Error = (
        <GivelifyLabel
            bold
            marginTop={16}
            text={DonationRowText.activityError}
            variant="heading5"
        />
    );

    const Wrapper = (Component: JSX.Element) => (
        <div className={borderTop}>{React.cloneElement(Component)}</div>
    );

    const loadNextPage = () => {
        if (isLoading) return;
        setIsLoading(true);
        setRequestData((prev) => ({
            ...prev,
            pageNumber: prev.pageNumber + 1,
        }));
    };

    return (
        <div className={wrapper}>
            <GivelifyInfiniteLoader
                ErrorComponent={Wrapper(Error)}
                LoadingComponent={<DonationActivityRowsLoading />}
                NoDataComponent={<NoResultsScreen pageName="activity" />}
                data={dataSet}
                isError={requestState.type === 'REQUEST_ERROR'}
                isLoading={isLoading}
                loadNextPage={loadNextPage}
                pageNumber={requestData.pageNumber}
                renderData={(
                    dataSet: IntegrationPaginatedResponse<DonationRow>,
                ) => (
                    <div
                        className={rowsWrapper}
                        data-testid="donations-rows-wrapper"
                    >
                        {dataSet.data.map((r, index, array) => {
                            const data: DonationItemRowProps =
                                mapDonationRowToDonationItemRowProps(r);
                            return data && data.id ? (
                                <React.Fragment key={r.id}>
                                    <DonationActivityRow {...data} />
                                    {index !== array.length - 1 && (
                                        <div className={divider} />
                                    )}
                                </React.Fragment>
                            ) : null;
                        })}
                    </div>
                )}
                reset={undefined}
            />
        </div>
    );
};

export default DonationActivityInfiniteLoader;
