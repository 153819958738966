import React from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLink,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { PromptWrapper } from './styles';

type OldDashboardNavigationPromptProps = {
    onAccept: () => void;
    onCancel: () => void;
};

const StyledLink = styled(GivelifyLink)(({ theme }) => ({
    color: DesignTokens.color.textWhite,
    fontWeight: 600,
    '&:hover': {
        color: DesignTokens.color.textWhite,
    },
}));

const StyledIcon = styled(GivelifyIcon)(({ theme }) => ({
    fontSize: '64px',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '48px',
    },
}));

export const OldDashboardNavigationPrompt: React.FCC<
    OldDashboardNavigationPromptProps
> = ({ onAccept, onCancel }) => {
    const { isOldDashboardLinkHidden } = useSelector((state: AppState) => ({
        isOldDashboardLinkHidden: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.OVERVIEW_HIDE_OLD_DASHBOARD_LINK,
            false,
        ),
    }));
    const { t } = useTranslation();

    const copy = React.useMemo(
        () => ({
            title: t('reports.legacyReportsDashboard.legacyPrompt.title'),
            description: t(
                'reports.legacyReportsDashboard.legacyPrompt.description',
            ),
            accept: t('reports.legacyReportsDashboard.legacyPrompt.accept'),
            skip: t('reports.legacyReportsDashboard.legacyPrompt.skip'),
        }),
        [t],
    );
    return (
        <PromptWrapper>
            <Grid
                container
                alignContent="center"
                justifyContent="center"
                rowSpacing={2}
                textAlign="center"
            >
                <Grid item>
                    <StyledIcon variant="report-colored" />
                </Grid>
                <Grid item>
                    <div className="title">{copy.title}</div>
                </Grid>
                <Grid item>
                    <div className="description">{copy.description}</div>
                </Grid>
                <Grid item marginTop="8px" xs={12}>
                    <GivelifyButton
                        fullWidth
                        className="go-to-reports"
                        onClick={onAccept}
                        text={copy.accept}
                        variant="primary"
                    />
                </Grid>
                {isOldDashboardLinkHidden ? null : (
                    <Grid item xs={12}>
                        <StyledLink onClick={onCancel} text={copy.skip} />
                    </Grid>
                )}
            </Grid>
        </PromptWrapper>
    );
};
