import React, { useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { GivelifyAvatar, GivelifyLink } from '@givelify/ui';
import {
    convertToDate,
    formatWithTimezone,
    getInitialsFromName,
    mergeClassNames,
} from '@givelify/utils';
import DonationDetails from 'pages/donations/components/DonationDetails';
import Messenger from 'pages/donations/components/Messenger';
import { MessageProps } from 'pages/donations/components/Messenger/Message';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import {
    DONATIONS_PAGE_CLICK_CLOSE_DETAILS_LINK,
    DONATIONS_PAGE_CLICK_OPEN_DETAILS_LINK,
} from 'utils/clevertapEvents';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { encryptString } from 'utils/hashUtils';
import { AppState } from '../../../../../../store';
import {
    Features,
    isFeatureEnabled,
} from '../../../../../../utils/featureGating';
import useStyles from '../styles';
import { EnvelopeDetailProps } from './DonationDetails';

export interface DonationItemRowProps {
    id: number;
    name: string;
    isRefund?: boolean;
    refundDate?: string;
    status?: string;
    latestStatusAt?: string;
    receiptNumber?: number;
    location?: string;
    date?: string;
    donation: number;
    doneeName: string;
    donorAvatar: string;
    envelopes?: EnvelopeDetailProps[];
    fees?: number;
    donationNoteId: number | undefined;
    messages?: MessageProps[];
    doneeAvatar?: string;
    donorId: number;
    openInitial?: true;
    hideExpandButton?: true;
}

const DonationActivityRow: React.FCC<DonationItemRowProps> = ({
    id,
    donorAvatar,
    name,
    date,
    donation,
    donationNoteId,
    messages,
    openInitial,
    hideExpandButton,
    receiptNumber,
    envelopes,
    fees,
    donorId,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        rowWrapper,
        headerWrapper,
        avatar,
        total,
        dateText,
        secondRowWrapper,
        messenger,
        btnDetail,
        donationDetailRow,
    } = useStyles();
    const [open, setOpen] = useState<boolean>(openInitial || false);
    const { t } = useAdvancedTranslation();
    const { enabledFeatures } = useSelector((state: AppState) => ({
        enabledFeatures: state.System.enabledFeatures,
    }));
    const { newDonorDetailsPageEnabled } = useMemo(
        () => ({
            newDonorDetailsPageEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.NEW_DONOR_DETAILS_PAGE,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const handleOpen = () => {
        setOpen(!open);
    };

    const copy = useMemo(
        () => ({
            viewDetail: t('bankDeposits.text.viewDetail'),
            closeDetail: t('bankDeposits.text.closeDetail'),
            refundStatus: t('refunds.text.refundStatus'),
        }),
        [t],
    );

    const { PATH } = useGasRouterContext();
    const encryptedId = encryptString(String(donorId));
    const linkUrl = newDonorDetailsPageEnabled
        ? PATH.DONORS.PROFILE_DONATION_HISTORY({ donorId: encryptedId })
        : PATH.DONORS.PROFILE(encryptedId);

    const onDonorLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate(linkUrl, {
            state: { pathname: location.pathname, search: location.search },
        });
    };
    const convertedDate = convertToDate(date);
    const formattedDate = formatWithTimezone(convertedDate, 'MMM D, YYYY');
    const formattedTime = formatWithTimezone(convertedDate, 'hh:mm A z');
    const formattedDateTimeString = `${formattedDate} | ${formattedTime}`;

    const showMessages = messages.length > 0;
    const showSecondRow = showMessages || !hideExpandButton;

    return (
        <div className={rowWrapper}>
            <div className={headerWrapper}>
                <GivelifyAvatar
                    className={avatar}
                    color="eucalyptus"
                    size="medium"
                    src={donorAvatar}
                    text={getInitialsFromName(name)}
                />
                <div>
                    <GivelifyLink
                        data-testid="donor-name"
                        href={linkUrl}
                        name="Donor Name"
                        onClick={onDonorLinkClick}
                        text={name}
                    />
                </div>
                <GivelifyLabel
                    className={total}
                    data-testid="donation-amount"
                    text={`$${formatMoneyWithCommas(donation)}`}
                    variant="heading2"
                />
                <GivelifyLabel
                    className={mergeClassNames(dateText, 'donation-date')}
                    text={formattedDateTimeString}
                    variant="body2"
                />
            </div>
            {showSecondRow && (
                <div
                    className={secondRowWrapper}
                    data-testid="donation-message-row"
                >
                    {showMessages && (
                        <Messenger
                            className={messenger}
                            donationNoteId={donationNoteId}
                            messages={messages}
                        />
                    )}
                    {!hideExpandButton && (
                        <GivelifyButton
                            className={btnDetail}
                            data-testid="view-detail-button"
                            endIconVariant={
                                open ? 'chevron-up' : 'chevron-down'
                            }
                            id={`view-detail-${id}`}
                            name={
                                open
                                    ? DONATIONS_PAGE_CLICK_CLOSE_DETAILS_LINK
                                    : DONATIONS_PAGE_CLICK_OPEN_DETAILS_LINK
                            }
                            onClick={handleOpen}
                            text={open ? copy.closeDetail : copy.viewDetail}
                            variant="link"
                        />
                    )}
                </div>
            )}
            {open && (
                <div
                    className={donationDetailRow}
                    data-testid="donation-detail-row"
                    id={`donation-detail-${id}`}
                >
                    <DonationDetails
                        date={date}
                        envelopes={envelopes}
                        fees={fees}
                        pageName="activity"
                        receiptNumber={receiptNumber}
                        total={donation}
                    />
                </div>
            )}
        </div>
    );
};

export default DonationActivityRow;
