import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import {
    GivelifyBoxMarginProps,
    MobilePreviewBase,
    MobilePreviewContent,
} from './style';

export interface GivelifyMobilePreviewBaseProps extends GivelifyBoxMarginProps {
    scale?: number;
    classes?: {
        root?: string;
        content?: string;
    };
    className?: string;
}

export const GivelifyMobilePreviewBase: React.FCC<
    GivelifyMobilePreviewBaseProps
> = (props) => {
    const {
        classes,
        className,
        scale,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        margin,
    } = props;
    const rootClassName = mergeClassNames(classes?.root, className);

    return (
        <MobilePreviewBase
            className={rootClassName}
            margin={margin}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginTop={marginTop}
            scale={scale}
        >
            <MobilePreviewContent className={classes?.content}>
                {props.children}
            </MobilePreviewContent>
        </MobilePreviewBase>
    );
};
