import React from 'react';
import { RequestState } from '@givelify/utils';
import { CampusItem } from './CampusList/types';

export interface AddCampusItem {
    name: string;
    nickname: string;
    address: string;
    zip: string;
    city: string;
    state: string;
    phone: string;
    campusId?: number;
    ypId?: number;
}

interface CampusesContextProps {
    doneeId: number;
    isEditable: boolean;
    campuses: CampusItem[];
    getList: () => void;
    addCampus: (data: AddCampusItem) => void;

    isLoading: boolean;
    hasItems: boolean;
    noItems: boolean;

    showAddCampusModal: boolean;
    setShowAddCampusModal: (show: boolean) => void;

    showConfirmModal: boolean;
    setShowConfirmModal: (show: boolean) => void;

    showCampusSubmittedModal: boolean;
    setShowCampusSubmittedModal: (show: boolean) => void;

    addCampusRequestState: RequestState<{
        data: CampusItem;
    }>;
}

const NOT_INITIALIZED = () => {
    throw new Error('Context not initialized');
};

export const CampusesContext = React.createContext<CampusesContextProps>({
    doneeId: undefined,
    isEditable: undefined,
    campuses: [],
    getList: NOT_INITIALIZED,
    addCampus: NOT_INITIALIZED,

    isLoading: false,
    hasItems: false,
    noItems: false,

    showAddCampusModal: false,
    setShowAddCampusModal: NOT_INITIALIZED,

    showConfirmModal: false,
    setShowConfirmModal: NOT_INITIALIZED,

    showCampusSubmittedModal: false,
    setShowCampusSubmittedModal: NOT_INITIALIZED,

    addCampusRequestState: undefined,
});
