import React, { useMemo } from 'react';
import { DesignTokens, GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { Divider, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { UseTaxIdContext } from '../TaxIdContext';

const Wrapper = styled('div')(() => ({
    border: `1px solid ${DesignTokens.border.borderComponentDefault.color}`,
    borderRadius: '8px',
    padding: '16px',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: '24px',
    marginBottom: '12px',
    '& .value': {
        fontWeight: 800,
    },
    '& .note': {
        fontSize: '14px',
        color: DesignTokens.color.textSecondary,
    },
    '& .divider': {
        borderColor: DesignTokens.color.dividerPrimaryLight,
    },
}));

export const TaxIdDetailsView: React.FCC = () => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            name: t('taxId.nonProfit.name'),
            location: t('taxId.nonProfit.location'),
            ein: t('taxId.nonProfit.ein'),
            publicIRS: t('taxId.nonProfit.publicIRS'),
        }),
        [t],
    );

    const { taxIdDetails } = UseTaxIdContext();

    return (
        <Wrapper>
            <GivelifyLabel>
                <span>{copy.name}: </span>{' '}
                <span className="value">{taxIdDetails.name}</span>
            </GivelifyLabel>
            <GivelifyLabel>
                <span>{copy.location}: </span>{' '}
                {taxIdDetails.address && (
                    <span className="value">{taxIdDetails.address}</span>
                )}
                {taxIdDetails.city && (
                    <span className="value">, {taxIdDetails.city}</span>
                )}
                {taxIdDetails.state && (
                    <span className="value">, {taxIdDetails.state}</span>
                )}
                {taxIdDetails.zip && (
                    <span className="value">, {taxIdDetails.zip}</span>
                )}
            </GivelifyLabel>

            <GivelifyLabel>
                <span>{copy.ein}: </span>{' '}
                <span className="value">{taxIdDetails.ein}</span>
            </GivelifyLabel>

            <Divider className="divider" />
            <GivelifyLabel alignItems="center" display="flex">
                <GivelifyIcon fontSize={28} variant="lock-check" />
                <span className="note">{copy.publicIRS}</span>
            </GivelifyLabel>
        </Wrapper>
    );
};
