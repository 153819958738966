import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NoResultsIcon from '../../../assets/no-results.svg';
import { PageName } from './types';

const useStyle = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 92,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 16,
                marginRight: 16,
            },
        },
    });
});

interface Props {
    pageName: PageName;
    className?: string;
}

type PageTranslationKey =
    | 'noDonations'
    | 'noRefunds'
    | 'noBankDeposits'
    | 'noDonorProfile';
const noResultsTranslation: Record<Props['pageName'], PageTranslationKey> = {
    activity: 'noDonations',
    refund: 'noRefunds',
    bankDeposit: 'noBankDeposits',
    donorProfile: 'noDonorProfile',
};

const NoResultsScreen: React.FCC<Props> = ({
    pageName,
    className = '',
}: Props) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            noDonations: t('error.noDonationsActivity'),
            noRefunds: t('error.noRefunds'),
            noBankDeposits: t('error.noBankDeposits'),
            tip: t('error.noResultsScreenTip'),
        }),
        [t],
    );
    return (
        <div
            className={`${classes.container} ${className}`}
            data-testid="no-results-block"
        >
            <img alt="No Results" src={NoResultsIcon} />
            <GivelifyLabel
                marginBottom={8}
                marginTop={8}
                text={copy[noResultsTranslation[pageName]]}
                variant="heading3"
            />
            <GivelifyLabel
                color="neutralGrey"
                text={copy.tip}
                variant="body1"
            />
        </div>
    );
};

export default NoResultsScreen;
