import React from 'react';
import {
    DesignTokens,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/ui';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountInfo } from './AccountInfo';
import { Box } from './styles';
import { AccountBoxProps } from './types';

type AccountWarningProps = AccountBoxProps;

export const AccountWarning: React.FCC<AccountWarningProps> = (props) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newAccount: t(
                'pages.settings.bank-info.customize-account-tab.new-account',
            ),
            warning: t(
                'pages.settings.bank-info.customize-account-tab.warning',
            ),
            info: t(
                'pages.settings.bank-info.customize-account-tab.warning-info',
            ),
            status: t('pages.settings.bank-info.customize-rep-tab.box.status'),
        }),
        [t],
    );
    const { bankingInfo } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        <Box>
            <div>
                <Stack alignItems="center" flexDirection="row">
                    <GivelifyLabel
                        marginRight="auto"
                        text={copy.newAccount}
                        variant={isMobile ? 'heading3S' : 'heading2S'}
                    />
                </Stack>
                <Stack alignItems="center" flexDirection="row" gap={0.5}>
                    <GivelifyLabel variant={isMobile ? 'body2' : 'body1'}>
                        {copy.status}{' '}
                    </GivelifyLabel>
                    <GivelifyLabel
                        color={DesignTokens.color.textWarningDefault}
                        variant={isMobile ? 'body2' : 'body1'}
                    >
                        {copy.warning}
                    </GivelifyLabel>
                    <GivelifyIcon fontSize={20} variant="warning-box-icon" />
                </Stack>
            </div>
            <GivelifyNotification open text={copy.info} variant="warning" />
            <AccountInfo bankingInfo={bankingInfo} className="detailes" />
        </Box>
    );
};
