import { styled } from '@mui/material';

export const TabBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hasBadges',
})<{ hasBadges?: boolean }>(({ theme, hasBadges }) => ({
    width: '100%',
    display: 'flex',
    gap: theme.spacing(5),
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('mobile')]: {
        gap: theme.spacing(4),
    },
    paddingTop: hasBadges ? theme.spacing(2) : 0,
}));

export const TabItem = styled('a', {
    shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
    userSelect: 'none',
    position: 'relative',
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: active
        ? theme.typography.body1B.fontWeight
        : theme.typography.body1.fontWeight,
    lineHeight: theme.typography.body1.lineHeight,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    [theme.breakpoints.down('mobile')]: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: active
            ? theme.typography.body2B.fontWeight
            : theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        gap: theme.spacing(0.5),
    },
}));

export const TabMarker = styled('div')(({ theme }) => ({
    borderTopWidth: 4,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.main,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
}));

export const TabBadge = styled('span')(({ theme }) => ({
    fontSize: theme.typography.data1.fontSize,
    fontWeight: theme.typography.data1.fontWeight,
    lineHeight: theme.typography.data1.lineHeight,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(0.375, 0.5, 0.125, 0.5),
    borderRadius: theme.spacing(2),
    position: 'absolute',
    left: '100%',
    bottom: '100%',
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
}));
