import React from 'react';
import { GivelifyLabel, GivelifyIcon, GivelifyButton } from '@givelify/ui';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import { StyledVerificationCard } from './style';

export type VerificationCardState =
    | 'unchecked'
    | 'in_progress'
    | 'request_callback'
    | 'verified'
    | 'require_information';

export interface VerificationCardProps {
    estimatedTime: number;
    badgeVariant: 'organization' | 'bank-colored' | 'primary-rep-colored';
    title: string;
    description: string;
    actionButtonText: string;
    retryButtonText: string;
    pendingDescription: string;
    warningDescription: string;
    retryDescription?: string;
    buttonName?: string;
    successDescription: string;
    onClick?: () => void;
    state: VerificationCardState;
    testId?: string;
    submittedAt: Date | null;
    submittedBy: string | null;
}

export const VerificationCard: React.FCC<VerificationCardProps> = ({
    estimatedTime,
    badgeVariant,
    title,
    description,
    actionButtonText,
    state,
    pendingDescription,
    retryButtonText,
    retryDescription,
    buttonName,
    successDescription,
    warningDescription,
    onClick,
    testId,
    submittedAt,
    submittedBy,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            status: t('verificationCard.status'),
            in_progress: t('verificationCard.in_review'),
            request_callback: t('verificationCard.in_review'),
            require_information: t('verificationCard.action_needed'),
            verified: t('verificationCard.completed'),
            submitted_on: t('verificationCard.submitted_on'),
            submitted_by: t('verificationCard.submitted_by', {
                userName: submittedBy,
            }),
            minutes: t('verificationCard.minutes'),
            unchecked: 'unchecked',
        }),
        [t, submittedBy],
    );

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const isInProgress =
        state === 'in_progress' || state === 'request_callback';
    const descriptionText =
        (state === 'unchecked' && description) ||
        (isInProgress && pendingDescription) ||
        (state === 'verified' && successDescription);

    return (
        <StyledVerificationCard data-testid={testId} variant={state}>
            {isMobile ? (
                <div
                    data-testid={`card-${testId}`}
                    onClick={
                        state === 'unchecked' || state === 'require_information'
                            ? onClick
                            : () => null
                    }
                >
                    <Grid container flexWrap="nowrap">
                        <Grid item className="mobile-card-icon-wrapper">
                            {state === 'unchecked' ? (
                                <GivelifyIcon
                                    className="mobile-card-icon"
                                    variant={badgeVariant}
                                />
                            ) : null}

                            {isInProgress ? (
                                <GivelifyIcon
                                    className="mobile-card-icon"
                                    variant="reminder-colored"
                                />
                            ) : null}

                            {state === 'verified' ? (
                                <GivelifyIcon
                                    className="mobile-card-icon"
                                    variant="success-colored"
                                />
                            ) : null}

                            {state === 'require_information' ? (
                                <GivelifyIcon
                                    className="mobile-card-icon"
                                    variant="error-warning-colored"
                                />
                            ) : null}
                        </Grid>
                        <Grid item>
                            <GivelifyLabel className="status-wrapper">
                                {state === 'unchecked' ? (
                                    <>
                                        <GivelifyIcon
                                            className="reminderIcon"
                                            variant="reminder"
                                        />{' '}
                                        {estimatedTime} {copy.minutes}
                                    </>
                                ) : (
                                    <span className="status">
                                        {copy.status}:{' '}
                                        <span
                                            className={`status-value ${state}`}
                                        >
                                            {copy[state]}
                                        </span>
                                    </span>
                                )}
                            </GivelifyLabel>
                            <GivelifyLabel className="heading" text={title} />
                        </Grid>
                    </Grid>
                    {state === 'require_information' ? (
                        <div className="warning-description">
                            {warningDescription}
                        </div>
                    ) : null}
                </div>
            ) : (
                <Grid container>
                    <Grid item xs={10}>
                        <div className="head-wrapper">
                            <GivelifyLabel className="heading" text={title} />
                            <div className="divider"></div>

                            <GivelifyLabel className="status-wrapper">
                                {state === 'unchecked' ? (
                                    <>
                                        <GivelifyIcon
                                            className="reminderIcon"
                                            variant="reminder"
                                        />{' '}
                                        {estimatedTime} {copy.minutes}
                                    </>
                                ) : (
                                    <span className="status">
                                        {copy.status}:{' '}
                                        <span
                                            className={`status-value ${state}`}
                                        >
                                            {copy[state]}
                                        </span>
                                    </span>
                                )}
                            </GivelifyLabel>
                        </div>
                        <div className="description">
                            {descriptionText}
                            {state === 'require_information' ? (
                                <Grid container flexWrap="nowrap">
                                    <Grid item>
                                        <GivelifyIcon
                                            className="action-needed-warning"
                                            variant="error-warning-colored"
                                        />
                                    </Grid>
                                    <Grid item className="warning-description">
                                        {warningDescription}
                                    </Grid>
                                </Grid>
                            ) : null}
                        </div>
                        {state === 'unchecked' ||
                        state === 'require_information' ? (
                            <GivelifyButton
                                className="verification-action"
                                data-testid={`button-${testId}`}
                                name={buttonName}
                                onClick={onClick}
                                text={
                                    state === 'require_information'
                                        ? retryButtonText
                                        : actionButtonText
                                }
                                variant="primary"
                            />
                        ) : null}
                        {isInProgress ? (
                            <div className="submitted-on">
                                {submittedBy
                                    ? copy.submitted_by
                                    : copy.submitted_on}{' '}
                                {dayjs(
                                    submittedAt ? submittedAt : Date.now(),
                                ).format('MM/DD/YYYY')}{' '}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item className="iconSection" xs={2}>
                        <div className="iconWrapper">
                            <GivelifyIcon
                                className="cardIcon"
                                variant={badgeVariant}
                            />

                            {isInProgress ? (
                                <GivelifyIcon
                                    className="statusIcon"
                                    variant="reminder-colored"
                                />
                            ) : null}

                            {state === 'verified' ? (
                                <GivelifyIcon
                                    className="statusIcon"
                                    variant="success-colored"
                                />
                            ) : null}

                            {state === 'require_information' ? (
                                <GivelifyIcon
                                    className="statusIcon"
                                    variant="error-warning-colored"
                                />
                            ) : null}
                        </div>
                    </Grid>
                </Grid>
            )}
        </StyledVerificationCard>
    );
};
