import React, { useMemo, useRef, useState, useEffect } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { formatWithTimezone, useTrackingContext } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import OnEnterChangeTextField from 'components/inputs/OnEnterChangeTextField';
import { addPhoneNumberFormatting } from 'pages/editProfile/components/EditProfileForm';
import { matchRowStyle } from 'pages/integrations/components/style';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MatchAsync } from 'store/integrations/thunks';
import { INTEGRATIONS_MATCH_DONOR } from 'utils/clevertapEvents';

export interface DonorMatchingProps {
    id: number;
    name: string;
    email: string;
    phone: string;
    picture: string;
    lastDonationDate: string | Date;
    externalId: string;
    integration: IntegrationTypes;
    onSuccess?: () => unknown;
    doneeId: number;
    className?: string;
    reportId?: number;
    isReadOnly: boolean;
}

const DonorMatchingRow: React.FCC<DonorMatchingProps> = (props) => {
    const classes = matchRowStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { trackEvent } = useTrackingContext();

    const inputRef = useRef(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const [updatedValue, setUpdatedValue] = useState<string>('');
    const [isError, setIsError] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const currentValue = isUpdated ? updatedValue : props.externalId;
    const showInput = isEdit || !currentValue;

    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);

    const itemClick = () => {
        setIsEdit(true);
    };

    useEffect(() => {
        if (isEdit) {
            inputRef.current.focus();
        }
    }, [isEdit]);

    const updateDonor = async (newValue: string) => {
        if ((newValue || '') === (currentValue || '')) return;

        trackEvent(INTEGRATIONS_MATCH_DONOR, {
            donorId: props.id,
            reportId: props.reportId,
        });

        const result = await MatchAsync(
            'donor',
            props.doneeId,
            props.id,
            newValue,
            currentValue,
            props.reportId,
        )(dispatch, null, null);

        if (!componentIsMounted.current) return;

        setIsError(!result.success);
        if (result.success) {
            setIsUpdated(true);
            setUpdatedValue(result.result);
            props.onSuccess();
            setIsEdit(false);
        } else {
            inputRef.current.focus();
        }
    };

    const copy = useMemo(
        () => ({
            placeHolderText: t(
                'pages.integration_envelope_matching.content.table.heading.code',
                {
                    reportType: props.integration.abbreviation,
                    fundCodeLabel: props.integration.memberIdLabel,
                },
            ),
            hintText: t('pages.integration_donor_matching.hintText'),
        }),
        [t, props],
    );

    return (
        <Grid
            container
            alignItems="stretch"
            className={`${classes.matchRowContent} ${props.className}`}
            justifyContent="space-between"
        >
            <Grid
                item
                className={classes.matchItem}
                lg={3}
                md={3}
                sm={4}
                xs={6}
            >
                <Box className={classes.donorNameContent} display="flex">
                    <GivelifyAvatar
                        color="grey"
                        size="xSmall"
                        src={props.picture}
                    />
                    <Box paddingLeft={2}>
                        <GivelifyLabel bold text={props.name} variant="body2" />
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                className={classes.matchItem}
                lg={3}
                md={3}
                sm={4}
                xs={6}
            >
                {props.email && (
                    <GivelifyLabel
                        className={classes.emailContent}
                        text={props.email}
                        variant="body2"
                    />
                )}
            </Grid>
            <Grid
                item
                className={classes.matchItem}
                lg={2}
                md={2}
                sm={4}
                xs={6}
            >
                <GivelifyLabel
                    text={addPhoneNumberFormatting(props.phone)}
                    variant="body2"
                />
            </Grid>
            <Grid
                item
                className={`${classes.matchItem} ${classes.lastMatchItem}`}
                lg={2}
                md={2}
                sm={5}
                xs={6}
            >
                {props.lastDonationDate && (
                    <GivelifyLabel
                        text={formatWithTimezone(props.lastDonationDate)}
                        variant="body2"
                    />
                )}
            </Grid>
            <Grid
                item
                className={classes.integrationInputRight}
                lg={2}
                md={2}
                sm={7}
                xs={12}
            >
                {showInput && (
                    <OnEnterChangeTextField
                        className={classes.integrationInput}
                        defaultValue={currentValue}
                        disabled={props.isReadOnly}
                        error={isError}
                        inputRef={inputRef}
                        onEnter={updateDonor}
                        placeholder={copy.placeHolderText}
                        type="tel"
                    />
                )}
                {!showInput && (
                    <Box className={classes.labelWrapper}>
                        <GivelifyButton
                            wrap
                            className={classes.matchButton}
                            disabled={props.isReadOnly}
                            name="matchedDonorValue"
                            onClick={itemClick}
                            size="auto"
                            text={currentValue}
                            variant="link"
                        />
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default React.memo(DonorMatchingRow);
