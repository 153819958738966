import { DonationQueryParams } from '@givelify/api';
import { makeApiRequest, getAxiosClient, ApiResponse } from '@givelify/utils';
import {
    getTotalDonationByQueryEndpoint,
    getLifeTimeDonationsApiEndpoint,
    getDonationsStatisticApiEndpoint,
    DonationsStatisticType,
} from 'api/client/endpoints';
import { IDonationService } from 'api/services/interfaces';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { LifeTimeData } from 'store/dashboard/lifeTime/types';
import { GetDonationsStatisticResponse } from '../responses/donations';
import { GetLifeTimeDonationsResponse } from '../responses/widgets';

export class DonationService implements IDonationService {
    private getDonationsStatisticCancelTokenSource: CancelTokenSource;
    private getTotalDonationsByQueryCancelTokenSource: CancelTokenSource;

    public async getLifeTimeDonations(
        doneeId: number,
    ): Promise<ApiResponse<GetLifeTimeDonationsResponse>> {
        const url = getLifeTimeDonationsApiEndpoint(doneeId);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest<GetLifeTimeDonationsResponse>(httpRequest);
    }

    public async getDonationsStatistic(
        doneeId: number,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs,
        type: DonationsStatisticType,
        skipCancel = false,
    ): Promise<ApiResponse<GetDonationsStatisticResponse>> {
        if (!skipCancel) {
            this.getDonationsStatisticCancelTokenSource?.cancel();
            this.getDonationsStatisticCancelTokenSource =
                axios.CancelToken.source();
        }

        const url = getDonationsStatisticApiEndpoint(
            doneeId,
            startDate,
            endDate,
            type,
        );
        const httpRequest = getAxiosClient().get(url, {
            cancelToken: skipCancel
                ? undefined
                : this.getDonationsStatisticCancelTokenSource.token,
        });
        return makeApiRequest<GetDonationsStatisticResponse>(httpRequest);
    }

    public async getTotalDonationsByQuery(
        doneeId: number,
        donationQuery: DonationQueryParams,
        pageName?: 'activity' | 'bankDeposit' | 'refund',
    ): Promise<ApiResponse<LifeTimeData>> {
        this.getTotalDonationsByQueryCancelTokenSource?.cancel();
        this.getTotalDonationsByQueryCancelTokenSource =
            axios.CancelToken.source();

        const url = getTotalDonationByQueryEndpoint(
            doneeId,
            donationQuery,
            pageName,
        );
        const httpRequest = getAxiosClient().get(url, {
            cancelToken: this.getTotalDonationsByQueryCancelTokenSource.token,
        });
        return makeApiRequest(httpRequest);
    }
}
