import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
import {
    DESKTOP_DRAWER_WIDTH,
    DESKTOP_HEADER_HEIGHT,
    MOBILE_HEADER_HEIGHT,
    TABLET_DRAWER_WIDTH,
    TABLET_HEADER_HEIGHT,
} from 'theme/layoutMeaserments';

export const MainNotificationWrapper = styled('div')(({ theme }) => ({
    width: '100vw',
    position: 'fixed',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        padding: 0,
    },
    '& .nt-root': {
        backgroundColor: DesignTokens.color.globalNeutral50,
        marginTop: DESKTOP_HEADER_HEIGHT,
        [theme.breakpoints.down('tablet')]: {
            padding: 0,
            margin: 0,
            maxWidth: 1088,
            paddingTop: theme.spacing(3),
            marginLeft: TABLET_DRAWER_WIDTH,
            marginTop: TABLET_HEADER_HEIGHT,
        },
        [theme.breakpoints.down('mobile')]: {
            padding: 0,
            marginLeft: 16,
            marginRight: 16,
            maxWidth: 'auto',
            paddingTop: theme.spacing(3),
            marginTop: MOBILE_HEADER_HEIGHT,
        },
    },
    '& .nt-root-main': {
        maxWidth: 1088 + DESKTOP_DRAWER_WIDTH + 32,
        padding: theme.spacing(3, 0, 0, 4),
    },
    '& .nt-root-content': {
        marginLeft: DESKTOP_DRAWER_WIDTH + 28,
        maxWidth: 1088 + 8,
        marginTop: DESKTOP_HEADER_HEIGHT,
        padding: theme.spacing(3, 0.5, 3, 0.5),
        [theme.breakpoints.down('tablet')]: {
            marginLeft: TABLET_DRAWER_WIDTH,
            marginTop: TABLET_HEADER_HEIGHT,
            paddingBottom: 0,
        },
        [theme.breakpoints.down('mobile')]: {
            padding: 0,
            marginLeft: 16,
            marginRight: 16,
            maxWidth: 'auto',
            paddingTop: theme.spacing(3),
            marginTop: MOBILE_HEADER_HEIGHT,
        },
    },
}));
