import React from 'react';
import { Address } from '@givelify/givelify-ui';
import { TrackingProvider } from '@givelify/utils';
import OnboardingPage from '../../../OnboardingPage';
import MailingAddressInfo from './MailingAddressInfo';
import View from './view';

interface MailingAddressProps {
    address: Address;
    addressIsSame?: boolean;
    bankingAddress?: Address;
    onContinueClick: (address: Address, addressIsSame: boolean) => void;
    onCancelClick: (skipModal?: boolean) => void;
    onBackClick: () => void;
}

const MailingAddress: React.FCC<MailingAddressProps> = ({
    address,
    addressIsSame,
    bankingAddress,
    onContinueClick,
    onCancelClick,
    onBackClick,
}) => {
    return (
        <TrackingProvider
            trackPageVisit
            pageName="SetUpCheckingAcc MailingAddress"
        >
            <OnboardingPage
                content={
                    <View
                        address={address}
                        addressIsSame={addressIsSame}
                        bankingAddress={bankingAddress}
                        onBackClick={onBackClick}
                        onCancelClick={onCancelClick}
                        onContinueClick={onContinueClick}
                    />
                }
                informationContent={<MailingAddressInfo />}
            />
        </TrackingProvider>
    );
};

export default MailingAddress;
