import React from 'react';
import {
    DATEPICKER_DAY_CLICK,
    DATEPICKER_END_DATE_CLICK,
    DATEPICKER_NEXT_MONTH_CLICK,
    DATEPICKER_PREV_MONTH_CLICK,
    DATEPICKER_START_DATE_CLICK,
    DATEPICKER_YEAR_SELECTION_CLICK,
    mergeClassNames,
    TimeFrameId,
    TimezoneManager,
    useTrackingContext,
} from '@givelify/utils';
import { InputAdornment, styled, TextFieldProps } from '@mui/material';
import {
    DateCalendar,
    DateField,
    DateView,
    PickersDayProps,
} from '@mui/x-date-pickers';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import { GivelifyButton } from '../button';
import { GivelifyIcon } from '../icon';
import { DesignTokens } from '../specify';
import { GivelifyTextField, GivelifyTextFieldProps } from '../textField';
import {
    CalendarComponentProps,
    GivelifyCalendarProps,
    ThemeMode,
    PickerMode,
} from './types';

const GivelifyCalendarRoot = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'themeMode' && propName !== 'pickerMode',
})<CalendarComponentProps & { pickerMode: PickerMode }>(
    ({ themeMode, pickerMode }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'blue',
        width: '336px',
        maxWidth: '336px',
        height: '346px',
        backgroundColor:
            themeMode === 'light'
                ? DesignTokens.color.backgroundSecondary
                : DesignTokens.color.backgroundComponentToolTip,
        boxShadow: 'none',
        borderRadius: '0px',
        '& .MuiDateCalendar-root': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
        },
        '& .MuiTypography-root': {
            width: 48,
            height: 32,
            flexShrink: 0,
            alignItems: 'flex-start',
            fontSize: DesignTokens.textStyle.globalBody2.font.size,
            lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
            color:
                themeMode === 'light'
                    ? DesignTokens.color.textPrimary
                    : DesignTokens.color.textWhite,
            margin: 0,
        },
        '& .MuiPickersCalendarHeader-root': {
            paddingLeft: '12px',
            paddingRight: 0,
            height: '48px',
            maxHeight: '48px',
            flexShrink: 0,
        },
        '& .MuiPickersCalendarHeader-label': {
            fontSize: DesignTokens.textStyle.globalHeadingS3.font.size,
            lineHeight: `${DesignTokens.textStyle.globalHeadingS3.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingS3.font.weight,
            color:
                themeMode === 'light'
                    ? DesignTokens.color.textPrimary
                    : DesignTokens.color.textWhite,
        },
        '& .MuiYearCalendar-root': {
            maxHeight: 274,
        },
        '& .MuiPickersYear-root': {
            flexBasis: '25%',
            '& .MuiPickersYear-yearButton': {
                fontSize: DesignTokens.textStyle.globalBody2.font.size,
                lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
                color:
                    themeMode === 'light'
                        ? DesignTokens.color.textPrimary
                        : DesignTokens.color.textWhite,
                '&:hover': {
                    background:
                        themeMode === 'light'
                            ? DesignTokens.color.backgroundComponentPrimaryHover
                            : DesignTokens.color.globalNeutral600,
                },
                '&:disabled': {
                    color: DesignTokens.color.textSecondary,
                },
            },
            '& .Mui-selected': {
                background: DesignTokens.color.backgroundComponentPrimaryAccent,
                color: DesignTokens.color.textWhite,
                '&:hover': {
                    color:
                        themeMode === 'light'
                            ? DesignTokens.color.textPrimary
                            : DesignTokens.color.textWhite,
                },
            },
        },
        ...(pickerMode === 'range' && {
            height: '396px',
        }),
    }),
);

const PickerDayRoot = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'themeMode' && propName !== 'pickerMode',
})<CalendarComponentProps>(({ themeMode, className }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '48px',
    fontSize: DesignTokens.textStyle.globalBody2.font.size,
    lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
    fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
    color:
        themeMode === 'light'
            ? DesignTokens.color.textPrimary
            : DesignTokens.color.textWhite,
    flexShrink: 0,
    ...(className === 'start' && {
        background:
            themeMode === 'light'
                ? DesignTokens.color.backgroundComponentSecondaryAccent
                : DesignTokens.color.globalNeutral700,
        width: '40px',
        marginLeft: '8px',
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        justifyContent: 'flex-start',
    }),
    ...(className === 'middle' && {
        background:
            themeMode === 'light'
                ? DesignTokens.color.backgroundComponentSecondaryAccent
                : DesignTokens.color.globalNeutral700,
    }),
    ...(className === 'end' && {
        background:
            themeMode === 'light'
                ? DesignTokens.color.backgroundComponentSecondaryAccent
                : DesignTokens.color.globalNeutral700,
        width: '40px',
        marginRight: '8px',
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
        justifyContent: 'flex-end',
    }),
    '& .picker-day-circle': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        background: 'none',
        color:
            themeMode === 'light'
                ? DesignTokens.color.textPrimary
                : DesignTokens.color.textWhite,
        '&:hover': {
            background:
                themeMode === 'light'
                    ? DesignTokens.color.backgroundComponentPrimaryHover
                    : DesignTokens.color.globalNeutral600,
        },
    },
    '& .picker-day-circle-today': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor:
            themeMode === 'light'
                ? DesignTokens.color.borderComponentDefault
                : DesignTokens.color.borderComponentDefault,
    },
    '& .picker-day-circle-outside': {
        color: DesignTokens.color.globalNeutral300,
    },
    '& .picker-day-circle-disabled': {
        color: DesignTokens.color.textSecondary,
        pointerEvents: 'none',
    },
    '& .picker-day-circle-selected': {
        background: DesignTokens.color.backgroundComponentPrimaryAccent,
        color: DesignTokens.color.textWhite,
        '&:hover': {
            background: DesignTokens.color.backgroundButtonPrimaryHover,
        },
    },
}));

const PickerDay: React.FCC<
    PickersDayProps<dayjs.Dayjs> & CalendarComponentProps
> = (props) => {
    const onBlur = (event: React.FocusEvent<HTMLButtonElement>) => {
        if (props.onBlur) {
            props.onBlur(event, props.day);
        }
    };
    const onClick = () => {
        props.onDaySelect(props.day);
    };
    const onFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
        if (props.onFocus) {
            props.onFocus(event, props.day);
        }
    };
    const day = dayjs(props.day);
    const date = day.date();
    const label = day.format('MMM D, YYYY');
    return (
        <PickerDayRoot className={props.className} themeMode={props.themeMode}>
            <button
                aria-label={label}
                className={mergeClassNames(
                    'picker-day-circle',
                    props.outsideCurrentMonth &&
                        props.className !== 'range' &&
                        'picker-day-circle-outside',
                    props.today && 'picker-day-circle-today',
                    props.disabled &&
                        props.className !== 'range' &&
                        'picker-day-circle-disabled',
                    props.selected && 'picker-day-circle-selected',
                    props.className === 'start' && 'picker-day-circle-selected',
                    props.className === 'end' && 'picker-day-circle-selected',
                )}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
            >
                {date}
            </button>
        </PickerDayRoot>
    );
};

const PickerPreviousIcon = () => (
    <GivelifyIcon
        color={DesignTokens.color.iconPrimary}
        data-testid="prev-month-icon"
        variant="left-chevron"
    />
);

const PickerNextIcon = () => (
    <GivelifyIcon
        color={DesignTokens.color.iconPrimary}
        data-testid="next-month-icon"
        variant="right-chevron"
    />
);

const PickerOpenIcon = () => (
    <GivelifyIcon
        color={DesignTokens.color.iconPrimary}
        data-testid="open-picker-icon"
        variant="down-chevron"
    />
);

const PickerCloseIcon = () => (
    <GivelifyIcon
        color={DesignTokens.color.iconPrimary}
        data-testid="close-picker-icon"
        variant="up-chevron"
    />
);

const DateInputRoot = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'themeMode' && propName !== 'pickerMode',
})<CalendarComponentProps>(({ themeMode, theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    flexDirection: 'column',

    fontSize: DesignTokens.textStyle.globalBody2.font.size,
    lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
    fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
    color:
        themeMode === 'light'
            ? DesignTokens.color.textPrimary
            : DesignTokens.color.textWhite,
}));

const DateInputWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

export const DateInput = ({
    onCalendarClick,
    themeMode,
    ariaLabel,
    ...props
}: Omit<GivelifyTextFieldProps, 'size'> & {
    onCalendarClick?: () => void;
    themeMode: ThemeMode;
    state?: 'idle' | 'error' | 'success' | 'warning';
    clearable?: boolean;
    name?: string;
    ariaLabel?: string;
    size?: 'large' | 'medium';
    width?: 'default' | 'compact';
}) => {
    const onCalendarClickCallback = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            if (onCalendarClick) {
                onCalendarClick();
            }
        },
        [onCalendarClick],
    );
    return (
        <GivelifyTextField
            {...props}
            InputProps={{
                ...props.InputProps,
                endAdornment: onCalendarClick ? (
                    <InputAdornment position="end">
                        <GivelifyButton
                            aria-label="Choose date"
                            disabled={props.disabled}
                            onClick={onCalendarClickCallback}
                            size="small"
                            variant="icon"
                        >
                            <GivelifyIcon
                                color={
                                    themeMode === 'light'
                                        ? DesignTokens.color.textPrimary
                                        : DesignTokens.color.textWhite
                                }
                                variant="calendar"
                            />
                        </GivelifyButton>
                    </InputAdornment>
                ) : undefined,
            }}
            aria-label={ariaLabel}
            darkMode={themeMode === 'dark'}
        />
    );
};

export const GivelifyCalendar: React.FCC<GivelifyCalendarProps> = ({
    className,
    pickerMode = 'date',
    themeMode = 'light',
    onViewChange,
    showDaysOutsideCurrentMonth = true,
    disableFuture = true,
    value,
    start,
    end,
    onDateChange,
    onRangeChange,
    format = 'MM/DD/YYYY',
    step = 'start',
    labelForSelectDate = 'Select a date',
    ...props
}) => {
    const { trackEvent } = useTrackingContext();
    const debounceFunctionRef = React.useRef<DebouncedFunc<() => void>>();
    const [view, setView] = React.useState<DateView>('day');
    const onViewChangeCallback = React.useCallback(
        (view: DateView) => {
            setView(view);
            trackEvent(DATEPICKER_YEAR_SELECTION_CLICK);
            if (onViewChange) {
                onViewChange(view);
            }
        },
        [trackEvent, setView, onViewChange],
    );
    const onChange = React.useCallback(
        (
            date: dayjs.Dayjs | null,
            selectionState: PickerSelectionState | undefined,
        ) => {
            if (pickerMode === 'date') {
                if (onDateChange && selectionState === 'finish') {
                    onDateChange(date);
                }
            } else {
                if (onRangeChange && selectionState === 'finish') {
                    const startDate = step === 'start' ? date : start;
                    const endDate = date;
                    if (startDate === undefined && endDate === undefined) {
                        return;
                    }
                    if (startDate === undefined && endDate !== undefined) {
                        onRangeChange(endDate, null, 'custom');
                    } else if (
                        startDate !== undefined &&
                        endDate === undefined
                    ) {
                        onRangeChange(startDate, null, 'custom');
                    } else if (
                        startDate !== undefined &&
                        endDate !== undefined
                    ) {
                        if (endDate?.isBefore(startDate)) {
                            onRangeChange(endDate, startDate, 'custom');
                        } else {
                            onRangeChange(startDate, endDate, 'custom');
                        }
                    }
                }
            }
        },
        [pickerMode, onDateChange, onRangeChange, step, start],
    );
    const debouncedInputChange = React.useCallback(
        (
            start: dayjs.Dayjs | null | undefined,
            end: dayjs.Dayjs | null | undefined,
            range: TimeFrameId,
        ) => {
            if (debounceFunctionRef.current) {
                debounceFunctionRef.current.cancel();
            }

            debounceFunctionRef.current = debounce(() => {
                let newStart =
                    start !== null && start !== undefined && start.isValid()
                        ? start
                        : null;
                let newEnd =
                    end !== null && end !== undefined && end.isValid()
                        ? end
                        : null;
                if (
                    newStart !== null &&
                    newEnd !== null &&
                    newStart.isAfter(newEnd)
                ) {
                    const temp = newStart;
                    newStart = newEnd;
                    newEnd = temp;
                }
                if (onRangeChange) {
                    onRangeChange(newStart, newEnd, range);
                }
            }, 800);

            debounceFunctionRef.current();
        },
        [onRangeChange, debounceFunctionRef],
    );
    const onStartDateFieldChange = React.useCallback(
        (
            newValue: dayjs.Dayjs | null,
            context: { validationError: string | null },
        ) => {
            if (!context.validationError && onRangeChange) {
                let startDate = newValue;

                if (disableFuture && newValue?.isAfter(dayjs())) {
                    startDate = dayjs().tz();
                } else if (props.minDate && newValue?.isBefore(props.minDate)) {
                    startDate = props.minDate;
                } else if (props.maxDate && newValue?.isAfter(props.maxDate)) {
                    startDate = props.maxDate;
                }

                debouncedInputChange(startDate, end, 'custom');
            }
        },
        [
            onRangeChange,
            disableFuture,
            props.minDate,
            props.maxDate,
            debouncedInputChange,
            end,
        ],
    );
    const onEndDateFieldChange = React.useCallback(
        (
            newValue: dayjs.Dayjs | null,
            context: { validationError: string | null },
        ) => {
            if (!context.validationError && onRangeChange) {
                let endDate = newValue;

                if (disableFuture && newValue?.isAfter(dayjs())) {
                    endDate = dayjs().tz();
                } else if (props.minDate && newValue?.isBefore(props.minDate)) {
                    endDate = props.minDate;
                } else if (props.maxDate && newValue?.isAfter(props.maxDate)) {
                    endDate = props.maxDate;
                }

                debouncedInputChange(start, endDate, 'custom');
            }
        },
        [
            onRangeChange,
            disableFuture,
            props.minDate,
            props.maxDate,
            debouncedInputChange,
            start,
        ],
    );
    return (
        <GivelifyCalendarRoot
            className={className}
            pickerMode={pickerMode}
            themeMode={themeMode}
        >
            {pickerMode === 'range' ? (
                <DateInputRoot
                    className="range-input-root"
                    themeMode={themeMode}
                >
                    <span className="range-input-root-label">
                        {labelForSelectDate}
                    </span>
                    <DateInputWrapper className="range-input-wrapper">
                        <DateField
                            fullWidth
                            className="rang-input-start"
                            format={format}
                            id="start-date"
                            inputProps={{
                                'data-testid': 'start-date',
                            }}
                            onChange={onStartDateFieldChange}
                            onClick={() =>
                                trackEvent(DATEPICKER_START_DATE_CLICK)
                            }
                            slots={{
                                textField: ({
                                    size,
                                    ...props
                                }: TextFieldProps) => (
                                    <DateInput
                                        {...props}
                                        themeMode={themeMode}
                                    />
                                ),
                            }}
                            timezone={TimezoneManager.getDefault()}
                            value={start}
                        />
                        <DateField
                            fullWidth
                            className="rang-input-end"
                            format={format}
                            id="end-date"
                            inputProps={{
                                'data-testid': 'end-date',
                            }}
                            onChange={onEndDateFieldChange}
                            onClick={() =>
                                trackEvent(DATEPICKER_END_DATE_CLICK)
                            }
                            slots={{
                                textField: ({
                                    size,
                                    ...props
                                }: TextFieldProps) => (
                                    <DateInput
                                        {...props}
                                        themeMode={themeMode}
                                    />
                                ),
                            }}
                            timezone={TimezoneManager.getDefault()}
                            value={end}
                        />
                    </DateInputWrapper>
                </DateInputRoot>
            ) : null}
            <DateCalendar<dayjs.Dayjs>
                {...props}
                className="calendar-root"
                dayOfWeekFormatter={(dayOfWeek) => dayOfWeek.format('ddd')}
                disableFuture={disableFuture}
                onChange={onChange}
                onViewChange={onViewChangeCallback}
                showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
                slotProps={
                    pickerMode === 'range'
                        ? {
                              day: ({ day }) => ({
                                  className:
                                      (day.isSame(start, 'day') &&
                                          day.isSame(end, 'day')) ||
                                      start === null ||
                                      end === null
                                          ? 'single'
                                          : end !== null &&
                                            day.isSame(start, 'day')
                                          ? 'start'
                                          : day.isSame(end, 'day')
                                          ? 'end'
                                          : day.isAfter(start) &&
                                            day.isBefore(end)
                                          ? 'middle'
                                          : undefined,
                              }),
                              previousIconButton: (props) => ({
                                  size: 'small',
                                  title: 'Previous month',
                                  onClick: () => {
                                      props.onGoToPrevious();
                                      trackEvent(DATEPICKER_PREV_MONTH_CLICK);
                                  },
                              }),
                              nextIconButton: (props) => ({
                                  size: 'small',
                                  title: 'Next month',
                                  onClick: () => {
                                      props.onGoToNext();
                                      trackEvent(DATEPICKER_NEXT_MONTH_CLICK);
                                  },
                              }),
                              switchViewButton: {
                                  title: 'Year selection',
                              },
                          }
                        : {
                              previousIconButton: {
                                  size: 'small',
                                  title: 'Previous month',
                              },
                              nextIconButton: {
                                  size: 'small',
                                  title: 'Next month',
                              },
                              switchViewButton: {
                                  title: 'Year selection',
                              },
                          }
                }
                slots={{
                    day: (props: PickersDayProps<dayjs.Dayjs>) => (
                        <PickerDay
                            {...props}
                            onDaySelect={(day: dayjs.Dayjs) => {
                                props.onDaySelect(day);
                                trackEvent(DATEPICKER_DAY_CLICK);
                            }}
                            themeMode={themeMode}
                        />
                    ),
                    leftArrowIcon: PickerPreviousIcon,
                    rightArrowIcon: PickerNextIcon,
                    switchViewIcon:
                        view === 'year' ? PickerCloseIcon : PickerOpenIcon,
                }}
                timezone={TimezoneManager.getDefault()}
                value={
                    pickerMode === 'date' ? value : end !== null ? end : start
                }
            />
        </GivelifyCalendarRoot>
    );
};
