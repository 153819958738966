import React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { convertOnboardingToAppProfile } from '@givelify/onboarding';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import permissionTypes from 'constants/permissionTypes';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import permissionsByPath from 'utils/permissionsByPath';
import { AdminAppProfileView } from './AdminAppProfileView';
import { BasicAppProfileView } from './BasicAppProfileView';

export const CustomizeProfileTab: React.FCC = () => {
    const {
        missionStatement,
        organizationName,
        organizationType,
        onboarding,
        userRole,
    } = useSelector((state: AppState) => ({
        missionStatement: state.Donee.donee.missionStatement,
        organizationName: state.Donee.donee.name,
        organizationType: state.Donee.donee.type,
        onboarding: state.Donee.donee.onboarding,
        userRole: state.User.user.role,
    }));
    const appProfileInfo = useMemo(
        () =>
            convertOnboardingToAppProfile(
                toCamelCase(onboarding, {
                    deep: true,
                }),
                missionStatement,
            ),
        [onboarding, missionStatement],
    );

    const [logoCurrent, setLogoCurrent] = React.useState(
        appProfileInfo.organizationLogo,
    );
    useEffect(() => {
        setLogoCurrent(appProfileInfo.organizationLogo);
    }, [appProfileInfo.organizationLogo]);

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.APP_PROFILE][userRole] ===
        permissionTypes.FULL_ACCESS;

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsAppProfileCustomizeProfile}
        >
            {hasFullAccess ? (
                <AdminAppProfileView
                    appProfile={appProfileInfo}
                    organizationAddress={appProfileInfo.organizationAddress}
                    organizationLogo={logoCurrent}
                    organizationName={organizationName}
                    organizationType={
                        organizationType === 'church' ? 'church' : 'nonprofit'
                    }
                    setOrganizationLogo={(data) =>
                        setLogoCurrent(
                            data ? data : appProfileInfo.organizationLogo,
                        )
                    }
                />
            ) : (
                <BasicAppProfileView
                    address={appProfileInfo.organizationAddress}
                    coverUrl={appProfileInfo.bannerImage?.croppedUrl}
                    isNonprofit={organizationType === 'nonprofit'}
                    missionStatement={missionStatement}
                    organizationAddress={appProfileInfo.organizationAddress}
                    organizationLogo={logoCurrent}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    representativeAvatar={
                        appProfileInfo.faithLeaderImage?.croppedUrl
                    }
                />
            )}
        </TrackingProvider>
    );
};
