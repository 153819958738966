import React from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { matchRowStyle } from '../../../../components/style';
import MatchSuccessful from './MatchSuccessful';
import { TooltipProgressBar } from './TooltipProgressBar';

interface MatchBottomContentProps {
    tooltipMessage: string;
    infoText: string;
    doneLabel: string;
    success: boolean;
    successText: string;
    doneClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    doneButton: {
        [theme.breakpoints.up('xs')]: {
            width: 140,
            minWidth: 140,
            marginLeft: 'auto',
            marginRight: 0,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const MatchBottomContent: React.FCC<MatchBottomContentProps> = (props) => {
    const classes = matchRowStyle();

    const componentClasses = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            className={classes.matchingFooter}
            justifyContent="space-between"
        >
            <Grid item md={6} xs={12}>
                <TooltipProgressBar
                    infoText={props.infoText}
                    tooltipMessage={props.tooltipMessage}
                />
            </Grid>
            <Grid item md={4} xs={12}>
                {props.success && (
                    <MatchSuccessful
                        success={props.success}
                        successText={props.successText}
                    />
                )}
            </Grid>
            <Grid item className={classes.matchingButtonContent} md={2} xs={12}>
                <GivelifyButton
                    className={componentClasses.doneButton}
                    name="done"
                    onClick={props.doneClick}
                    size="xLarge"
                    text={props.doneLabel}
                    type="submit"
                    variant="primary"
                />
            </Grid>
        </Grid>
    );
};

export default MatchBottomContent;
