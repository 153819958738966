import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';

const TopStaticText: React.FCC = () => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const heading = t('directDepositManualEntry.topStateText.heading');
    const caption = t('directDepositManualEntry.topStateText.caption');
    return (
        <>
            <GivelifyLabel
                bold
                marginBottom={8}
                text={heading}
                variant="heading3"
            />
            <GivelifyLabel marginBottom={32} text={caption} variant="body1" />
        </>
    );
};

export default React.memo(TopStaticText);
