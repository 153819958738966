import React from 'react';
import { Grid } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { GivelifyLoading } from '../loading';

type InfiniteLoaderProps = {
    loadingComponent: JSX.Element;
    errorComponent: JSX.Element;
    zerothComponent: JSX.Element;
    className?: string;
    useWindow?: boolean;
    getScrollParent?: () => HTMLElement;
    error: boolean;
    loading: boolean;
    empty: boolean;
    hasMore: boolean;
    loadMore: (page: number) => void;
};

const Loading = (
    <Grid
        key="loading-indicator"
        container
        alignItems="center"
        justifyContent="center"
        marginBottom={2}
        marginTop={2}
    >
        <GivelifyLoading margin={16} type="circular" />
    </Grid>
);

export const GivelifyInfiniteScroll: React.FCC<InfiniteLoaderProps> = ({
    errorComponent,
    loadingComponent,
    zerothComponent,
    children,
    className,
    getScrollParent,
    useWindow,
    error,
    loading,
    empty,
    hasMore,
    loadMore,
}) => {
    if (error) return errorComponent;
    if (loading) return loadingComponent;
    if (empty) return zerothComponent;
    return (
        <div className={className}>
            <InfiniteScroll
                initialLoad
                getScrollParent={getScrollParent}
                hasMore={hasMore}
                loadMore={loadMore}
                loader={Loading}
                pageStart={1}
                useWindow={useWindow}
            >
                {children}
            </InfiniteScroll>
        </div>
    );
};
