import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3, 3, 3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: DesignTokens.color.backgroundComponentQuaternary,
    borderRadius: DesignTokens.measurement.borderRadiusS,
    borderTopWidth: 8,
    borderTopColor: DesignTokens.color.backgroundButtonPrimaryDefault,
    height: 'auto',
    flexGrow: 0,
    marginLeft: 'auto',
    maxWidth: 344,
}));
