import React, { useState } from 'react';
import { GivelifyNotification } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import OnboardingPage from '../../../OnboardingPage';
import OnboardingTopSection from '../../../OnboardingTopSection';
import { useDocumentUploadContext } from '../DocumentUploadContext';
import bankStatementSmall from './img/bankStatement.png';
import bankVerificationLetterSmall from './img/verificationLetter.png';
import voidedCheck from './img/voidedCheck.png';
import PageContent from './PageContent';
import SidebarContent from './SidebarContent';
import { useUploadDocumentTranslation } from './useUploadDocumentTranslation';

const UploadDocument: React.FCC = () => {
    const { selectedDocumentType, retryMessage } = useDocumentUploadContext();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { text } = useUploadDocumentTranslation(selectedDocumentType!);

    const exampleImage =
        (selectedDocumentType === 'voidedCheck' && voidedCheck) ||
        (selectedDocumentType === 'bankStatement' && bankStatementSmall) ||
        (selectedDocumentType === 'verificationLetter' &&
            bankVerificationLetterSmall) ||
        '';

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState<'example' | 'uploaded'>(
        'example',
    );
    const showExampleModal = () => {
        setModalType('example');
        setShowModal(true);
    };
    const showUploadedModal = () => {
        setModalType('uploaded');
        setShowModal(true);
    };

    return (
        <TrackingProvider trackPageVisit pageName="SetUpCheckingAcc Upload doc">
            <OnboardingPage
                content={
                    <PageContent
                        exampleImage={exampleImage}
                        modalType={modalType}
                        onModalClose={() => setShowModal(false)}
                        showExampleModal={showExampleModal}
                        showModal={showModal}
                        showUploadedModal={showUploadedModal}
                    />
                }
                header={
                    <OnboardingTopSection>
                        <GivelifyNotification
                            open
                            text={retryMessage || text?.notificationText}
                            variant="warning"
                        />
                    </OnboardingTopSection>
                }
                informationContent={
                    <SidebarContent
                        exampleImage={exampleImage}
                        showExampleModal={showExampleModal}
                    />
                }
            />
        </TrackingProvider>
    );
};

export default UploadDocument;
