import React from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { I18N_NAMESPACE } from '../../consts';
import Card from './card';

export interface CardCompleteProps {
    progress?: number;
    onCompleteClick?: () => void;
    onCloseClick?: () => void;
    showRetry?: boolean;
    onReviewClick?: () => void;
    hasMid?: boolean;
}

const CardComplete: React.FCC<CardCompleteProps> = ({
    onCloseClick,
    onCompleteClick,
    onReviewClick,
    hasMid,
    progress,
    showRetry,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: 'onboardingCards.completeToRecieve.v2',
    });
    const copy = React.useMemo(
        () => ({
            title: scopedTranslate('title'),
            titleVerification: scopedTranslate('titleVerification'),
            titleCompleted: scopedTranslate('titleCompleted'),
            description: scopedTranslate('description'),
            descriptionVerification: scopedTranslate('descriptionVerification'),
            descriptionCompleted: scopedTranslate('descriptionCompleted'),
            complete: scopedTranslate('complete'),
            item1: scopedTranslate('item1'),
            item2: scopedTranslate('item2'),
            item3: scopedTranslate('item3'),
            descriptionRetry: scopedTranslate('descriptionRetry'),
        }),
        [scopedTranslate],
    );

    const headerText =
        (hasMid && copy.titleCompleted) ||
        (progress === undefined || progress < 100
            ? copy.title
            : copy.titleVerification);

    const descriptionText =
        (hasMid && copy.descriptionCompleted) ||
        (progress !== undefined &&
            progress >= 100 &&
            copy.descriptionVerification) ||
        (showRetry ? copy.descriptionRetry : copy.description);

    const pageVisitEventName =
        (hasMid && 'View Onboarding Verified MID approved Modal') ||
        (progress === undefined || progress < 100
            ? 'View Onboarding Set up Acc Modal'
            : 'View Onboarding In Review Modal');

    const closeButtonEventName = hasMid
        ? 'Click Onboarding Verified Modal Dismiss_btn'
        : 'Click Onboarding In Review Modal Dismiss_btn';

    return (
        <Card
            closeButtonEventName={closeButtonEventName}
            descriptionText={descriptionText}
            hasMid={hasMid}
            headerText={headerText}
            item1Text={copy.item1}
            item2Text={copy.item2}
            item3Text={copy.item3}
            onCloseClick={onCloseClick}
            onCompleteClick={
                progress === undefined || progress < 100
                    ? onCompleteClick
                    : undefined
            }
            onReviewClick={
                showRetry && (progress === undefined || progress < 100)
                    ? onReviewClick
                    : undefined
            }
            pageVisitEventName={pageVisitEventName}
            progress={progress}
            showList={!showRetry && (progress === undefined || progress < 100)}
            showRequired={progress === undefined || progress < 100}
            submitButtonEventName="Click Onboarding Set up Acc Modal Get Started_btn"
            submitText={copy.complete}
        />
    );
};

export default CardComplete;
