import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { ButtonBase, styled } from '@mui/material';

type ChartButtonStyleProps = {
    active?: boolean;
};
const ChartButtonStyled = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'active',
})<ChartButtonStyleProps>((props) => ({
    width: '100%',
    minWidth: '218px',
    flexDirection: 'column',
    padding: '16px 24px 18px 24px',
    alignItems: 'flex-start',
    borderRadius: DesignTokens.measurement.borderRadiusM,

    color: DesignTokens.color.textAccentDefault,
    backgroundColor: '#F9F8FF',

    ':hover': {
        backgroundColor: DesignTokens.color.backgroundButtonSecondaryDefault,
    },
    ...(props.active && {
        color: DesignTokens.color.textWhite,
        backgroundColor: DesignTokens.color.backgroundButtonPrimaryDefault,
        ':hover': {
            color: DesignTokens.color.textWhite,
            backgroundColor: DesignTokens.color.backgroundButtonPrimaryDefault,
        },
    }),
}));

export type ChartButtonProps = {
    value: string;
    description: string;
    onClick: (index: number) => void;
    index: number;
    active?: boolean;
};

const ChartButtonComponent: React.FCC<ChartButtonProps> = ({
    value,
    description,
    onClick,
    active,
    index,
}) => {
    const onClickCallback = React.useCallback(() => {
        onClick(index);
    }, [onClick, index]);
    return (
        <ChartButtonStyled
            disableRipple
            active={active}
            data-testid={`btn-${value}`}
            onClick={onClickCallback}
        >
            <GivelifyLabel
                bold
                color="inherit"
                fontSize="24px"
                marginBottom="4px"
                text={value}
            />
            <GivelifyLabel
                color="inherit"
                text={description}
                variant="body1B"
            />
        </ChartButtonStyled>
    );
};

export const ChartButton = React.memo(ChartButtonComponent);
