import { GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const Section = styled('div')(({ theme }) => ({
    position: 'relative',
    '& .MuiInputBase-root input': {
        border: 'none',
    },
    '& input:valid + fieldset': {
        border: '1px solid #ADADAD',
    },
}));

export const Subtitle = styled(GivelifyLabel)({
    lineHeight: '20px',
    marginBottom: 16,
    paddingRight: '65px',
    whiteSpace: 'normal',
});

export const Body = styled(GivelifyLabel)({
    whiteSpace: 'normal',
    wordBreak: 'break-word',
});

export const EditButtonContainer = styled('div')({
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 0,
    '& button': {
        height: 20,
    },
});

export const SubmitButton = styled(GivelifyButton)({
    marginLeft: 12,
});

export const SubmitButtonsHolder = styled('div')(({ theme }) => ({
    marginTop: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
        minWidth: 140,
        height: 48,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            width: '100%',
            height: 40,
        },
    },
}));

export const Paper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        gap: theme.spacing(2),
    },
}));
