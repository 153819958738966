import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SocialMediaName } from '../types';
import { useTranslations } from '../utils/translations';
import Label from './Label';

interface SMGivingTipProps extends React.HTMLAttributes<HTMLDivElement> {
    media: SocialMediaName;
    link?: string;
}

const useStyles = makeStyles((_: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #E3E3E3',
        borderRadius: '8px',
        padding: '16px',
    },
    tip: {
        background: '#373739',
        borderRadius: '31px',
        color: 'white',
        padding: '4px 16px',
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '14px',
        marginRight: '16px',
        flex: 'none',
        height: '0%',
    },
    link: {
        color: '#715AFF',
        textDecoration: 'none',
        '&:hover': {
            color: '#5F48F0',
            textDecoration: 'underline',
        },
    },
}));

const SMGivingTip: React.FCC<SMGivingTipProps> = ({
    className = '',
    media,
    link,
    ...rest
}: SMGivingTipProps) => {
    const cs = useStyles();
    const { tip } = useTranslations(`socialMediaGiving.${media}.page`, {
        tip: ['title', 'desc', 'linkTitle'],
    });

    return (
        <div {...rest} className={cs.container + ' ' + className}>
            <div className={cs.tip}>{tip.title}</div>

            <Label variant="body2">
                {tip.desc}
                {link ? (
                    <>
                        {' '}
                        <a
                            className={cs.link}
                            href={link}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {tip.linkTitle}
                        </a>
                    </>
                ) : null}
            </Label>
        </div>
    );
};

export default React.memo(SMGivingTip);
