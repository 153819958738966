import {
    ApiHandler,
    GetDepositsResponse,
    TRANSACTION_FILTER_VALUE,
} from '@givelify/api';
import { ApiResponse } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';

export const getDataForInfiniteLoader = async (
    doneeId: number,
    pageNumber: number,
    timeFrame: TimeFrameValue,
    transactionFilter: TRANSACTION_FILTER_VALUE,
    onlyDate?: boolean,
): Promise<ApiResponse<GetDepositsResponse>> => {
    const requests: Promise<ApiResponse<GetDepositsResponse>>[] = [];
    if (
        transactionFilter === TRANSACTION_FILTER_VALUE.ALL &&
        pageNumber === 1
    ) {
        requests.push(
            ApiHandler.instance.donees.getDeposits(
                doneeId,
                timeFrame,
                TRANSACTION_FILTER_VALUE.PENDING,
                onlyDate,
            ),
        );
    }
    requests.push(
        ApiHandler.instance.donees.getDeposits(
            doneeId,
            timeFrame,
            transactionFilter,
            onlyDate,
            pageNumber,
        ),
    );
    const responseArray = await Promise.all(requests);
    const failedResponse = responseArray.find((r) => !r.success);
    if (failedResponse) {
        return {
            success: false,
            error: failedResponse.error,
        };
    }
    const rowsMerged = responseArray.flatMap((r) =>
        r.success
            ? Array.isArray(r.response)
                ? r.response
                : r.response.data
            : [],
    );
    const lastResponse = responseArray[responseArray.length - 1];
    const pagination = lastResponse.success && lastResponse.response.pagination;
    return {
        success: true,
        response: {
            data: rowsMerged,
            pagination,
        },
    };
};
