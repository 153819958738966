import React from 'react';
import {
    DesignTokens,
    GivelifyCheckbox,
    GivelifyDatePicker,
} from '@givelify/ui';
import {
    CHECKBOX_IDENTIFIER,
    INPUT_IDENTIFIER,
    TimeFrameId,
    TimeFrameValue,
    useTrackingContext,
} from '@givelify/utils';
import { GivenAmountFilter, GivingStylesFilter } from 'api/hooks';
import { FilterInput } from 'components';
import {
    FilterPanelDateInputsWrapper,
    FilterPanelInputBlock,
    FilterPanelInputLabel,
    FilterPanelInputsWrapper,
    FilterPanelCheckboxWrapper,
} from 'components/filters/filterPanel/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MAX_CHAR_AMOUNT } from './schema';

export type DonorsFilter = {
    timeFrame: TimeFrameValue;
    givingStyles: GivingStylesFilter;
    givenAmount: GivenAmountFilter;
};

type DonorsFilterFormProps = {
    filter: DonorsFilter;
    setFilter: (filter: DonorsFilter, field?: keyof GivenAmountFilter) => void;
    doneeSignupDate?: Date;
    expand?: boolean;
    setExpand?: (expand: boolean) => void;
    errors: GivenAmountFilter;
};

export const DonorsFilterForm: React.FCC<DonorsFilterFormProps> = ({
    filter,
    setFilter,
    doneeSignupDate,
    errors,
}) => {
    const { t } = useTranslation();
    const { trackEvent } = useTrackingContext();
    const copy = React.useMemo(
        () => ({
            totalGiven: t('donors.totalGiven'),
            min: t('donors.min'),
            max: t('donors.max'),
            lastGivenAmount: t('donors.lastGivenAmount'),
            lastGivenOn: t('donors.lastGivenOn'),
            givingStyle: t('donors.givingStyleText'),
            new: t('donors.givingStyles.new'),
            occasional: t('donors.givingStyles.occasional'),
            consistent: t('donors.givingStyles.consistent'),
            decreasing: t('donors.givingStyles.decreasing'),
            inactive: t('donors.givingStyles.inactive'),
        }),
        [t],
    );
    const onRangeChange = React.useCallback(
        (start: dayjs.Dayjs, end: dayjs.Dayjs, range: TimeFrameId) => {
            const update: DonorsFilter = {
                ...filter,
                timeFrame:
                    start === null && end === null
                        ? undefined
                        : {
                              start,
                              end,
                              selector: range,
                          },
            };
            setFilter(update);
        },
        [filter, setFilter],
    );
    const onGivingStylesChange = React.useCallback(
        (style: string, checked: boolean) => {
            const update: DonorsFilter = {
                ...filter,
                givingStyles: {
                    ...filter.givingStyles,
                    [style]: checked,
                },
            };
            setFilter(update);

            trackEvent(`<Giving style>_${CHECKBOX_IDENTIFIER}`, {
                givingStyle: style,
            });
        },
        [filter, setFilter, trackEvent],
    );
    const onInputChange = React.useCallback(
        (name: keyof GivenAmountFilter, val: string) => {
            const update: DonorsFilter = {
                ...filter,
                givenAmount: {
                    ...filter.givenAmount,
                    [name]: val === undefined ? '' : val.replace(',', ''),
                },
            };
            setFilter(update, name);
        },
        [filter, setFilter],
    );
    return (
        <>
            <FilterPanelInputBlock id="last-given-on-block">
                <FilterPanelInputLabel color={DesignTokens.color.textWhite}>
                    {copy.lastGivenOn}
                </FilterPanelInputLabel>
                <FilterPanelDateInputsWrapper>
                    <GivelifyDatePicker
                        disableFuture
                        fullwidth
                        dataTestId="dateRange"
                        end={
                            filter.timeFrame &&
                            filter.timeFrame.end &&
                            dayjs(filter.timeFrame.end).isValid()
                                ? dayjs(filter.timeFrame.end)
                                : null
                        }
                        id="donors-date-range"
                        minDate={dayjs.tz(doneeSignupDate)}
                        name="lastGivenOn"
                        onOpenTrackingName={`<Date range>_${INPUT_IDENTIFIER}`}
                        onRangeChange={onRangeChange}
                        pickerMode="range"
                        popoverProps={{
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'bottom',
                            },
                            transformOrigin: {
                                horizontal: 'right',
                                vertical: 'top',
                            },
                        }}
                        range={filter.timeFrame?.selector}
                        start={
                            filter.timeFrame &&
                            filter.timeFrame.start &&
                            dayjs(filter.timeFrame.start).isValid()
                                ? dayjs(filter.timeFrame.start)
                                : null
                        }
                        themeMode="dark"
                    />
                </FilterPanelDateInputsWrapper>
            </FilterPanelInputBlock>
            <FilterPanelInputBlock>
                <FilterPanelInputLabel
                    color={DesignTokens.color.textWhite}
                    id="total-given-title"
                >
                    {copy.totalGiven}
                </FilterPanelInputLabel>
                <FilterPanelInputsWrapper>
                    <FilterInput
                        error={errors.totalGivenMin}
                        id="totalGivenMin"
                        label={copy.min}
                        maxLength={MAX_CHAR_AMOUNT}
                        name="totalGivenMin"
                        onChange={onInputChange}
                        value={filter.givenAmount.totalGivenMin}
                    />
                    <FilterInput
                        error={errors.totalGivenMax}
                        id="totalGivenMax"
                        label={copy.max}
                        maxLength={MAX_CHAR_AMOUNT}
                        name="totalGivenMax"
                        onChange={onInputChange}
                        value={filter.givenAmount.totalGivenMax}
                    />
                </FilterPanelInputsWrapper>
            </FilterPanelInputBlock>
            <FilterPanelInputBlock>
                <FilterPanelInputLabel
                    color={DesignTokens.color.textWhite}
                    id="last-gift-title"
                >
                    {copy.lastGivenAmount}
                </FilterPanelInputLabel>
                <FilterPanelInputsWrapper>
                    <FilterInput
                        error={errors.lastGivenMin}
                        id="lastGivenMin"
                        label={copy.min}
                        maxLength={MAX_CHAR_AMOUNT}
                        name="lastGivenMin"
                        onChange={onInputChange}
                        value={filter.givenAmount.lastGivenMin}
                    />
                    <FilterInput
                        error={errors.lastGivenMax}
                        id="lastGivenMax"
                        label={copy.max}
                        maxLength={MAX_CHAR_AMOUNT}
                        name="lastGivenMax"
                        onChange={onInputChange}
                        value={filter.givenAmount.lastGivenMax}
                    />
                </FilterPanelInputsWrapper>
            </FilterPanelInputBlock>
            <FilterPanelInputBlock>
                <FilterPanelInputLabel
                    color={DesignTokens.color.textWhite}
                    id="giving-style-title"
                >
                    {copy.givingStyle}
                </FilterPanelInputLabel>
                <FilterPanelInputsWrapper wrap className="giving-style-wrapper">
                    <FilterPanelCheckboxWrapper>
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.givingStyles.new}
                            id="givingStyleNew"
                            inputProps={{
                                'data-testid': 'givingStyleNew',
                            }}
                            label={copy.new}
                            name="givingStyleNew"
                            onChange={(_ev, checked) =>
                                onGivingStylesChange('new', checked)
                            }
                        />
                    </FilterPanelCheckboxWrapper>
                    <FilterPanelCheckboxWrapper>
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.givingStyles.occasional}
                            id="givingStyleOccasional"
                            inputProps={{
                                'data-testid': 'givingStyleOccasional',
                            }}
                            label={copy.occasional}
                            name="givingStyleOccasional"
                            onChange={(_ev, checked) =>
                                onGivingStylesChange('occasional', checked)
                            }
                        />
                    </FilterPanelCheckboxWrapper>
                    <FilterPanelCheckboxWrapper>
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.givingStyles.consistent}
                            id="givingStyleConsistent"
                            inputProps={{
                                'data-testid': 'givingStyleConsistent',
                            }}
                            label={copy.consistent}
                            name="givingStyleConsistent"
                            onChange={(_ev, checked) =>
                                onGivingStylesChange('consistent', checked)
                            }
                        />
                    </FilterPanelCheckboxWrapper>
                    <FilterPanelCheckboxWrapper>
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.givingStyles.decreasing}
                            id="givingStyleDecreasing"
                            inputProps={{
                                'data-testid': 'givingStyleDecreasing',
                            }}
                            label={copy.decreasing}
                            name="givingStyleDecreasing"
                            onChange={(_ev, checked) =>
                                onGivingStylesChange('decreasing', checked)
                            }
                        />
                    </FilterPanelCheckboxWrapper>
                    <FilterPanelCheckboxWrapper>
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.givingStyles.inactive}
                            id="givingStyleInactive"
                            inputProps={{
                                'data-testid': 'givingStyleInactive',
                            }}
                            label={copy.inactive}
                            name="givingStyleInactive"
                            onChange={(_ev, checked) =>
                                onGivingStylesChange('inactive', checked)
                            }
                        />
                    </FilterPanelCheckboxWrapper>
                </FilterPanelInputsWrapper>
            </FilterPanelInputBlock>
        </>
    );
};
