import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ImageWithDimensions } from '../../@types/assets/onboarding';
import { SaveCancelButtons } from '../../components/SaveCancelButton';
import { I18N_NAMESPACE } from '../../consts';
import { ImageButton, ImageButtonRef } from '../../imageCropper/ImageButton';
import { CustomizeProfileBanner } from '../../imageCropper/ImageUploadButtons';

export const profileFormGridStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGridStyles: {
            marginTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        topSliderStyles: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        organizationPreviewStyles: {
            marginTop: 31,
            maxWidth: 423,
            '& .organization-logo': {
                width: 60,
                height: 60,
                borderRadius: 50,
                border: '1px solid #D8DCE0',
                marginRight: theme.spacing(3),
            },
            '& .preview': {
                display: 'flex',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: 4,
                padding: '22px 24px',
            },
            '& .inline': {
                display: 'inline-block',
            },
        },
    }),
);

interface BannerImageSectionProps {
    bannerImage: ImageWithDimensions | undefined;
    setBannerImage: (image?: ImageWithDimensions) => unknown;
    submitProps?: {
        onSave: () => void;
        isSaving?: boolean;
        disable?: boolean;
    };
}

const BannerImageSection: React.FCC<BannerImageSectionProps> = ({
    bannerImage,
    setBannerImage,
    submitProps,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const imageButtonRef = React.useRef<ImageButtonRef>(null);
    const { headingText, descriptionText } = useMemo(
        () => ({
            headingText: t('customizeProfile.bannerImageSection.heading'),
            descriptionText: t(
                'customizeProfile.bannerImageSection.description',
            ),
        }),
        [t],
    );
    const onCancel = React.useCallback(() => {
        if (imageButtonRef && imageButtonRef.current) {
            imageButtonRef.current?.clear();
        }
    }, [imageButtonRef]);
    const isRemoteFile = React.useMemo(() => {
        if (bannerImage && bannerImage.url) {
            if (bannerImage.url.includes('data:')) {
                return false;
            }
        }
        return true;
    }, [bannerImage]);
    React.useEffect(() => {
        if (isRemoteFile) {
            imageButtonRef.current?.reset();
        }
    }, [isRemoteFile]);
    return (
        <div>
            <GivelifyLabel
                marginBottom={6}
                text={headingText}
                variant="heading3"
            />
            <GivelifyLabel
                marginBottom={8}
                text={descriptionText}
                variant="body1"
            />
            <ImageButton
                ref={imageButtonRef}
                Component={CustomizeProfileBanner}
                aspectRatio={16 / 9}
                id="banner-img"
                initialImage={bannerImage}
                onChange={setBannerImage}
                title={headingText}
            />
            {submitProps && bannerImage && !isRemoteFile ? (
                <SaveCancelButtons
                    onCancel={onCancel}
                    onSave={submitProps.onSave}
                    saving={submitProps.isSaving}
                    submitDisabled={submitProps.disable}
                />
            ) : null}
        </div>
    );
};

export default React.memo(BannerImageSection);
