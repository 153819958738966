import React from 'react';
import { checkPreviewStyles } from './styles';
import BankChequeOutline1ArrowIcon from '../assets/bankCheque/bank-cheque-outline-1-arrow.svg';
import BankChequeOutline1Icon from '../assets/bankCheque/bank-cheque-outline-1.svg';
import BankChequeOutline2ArrowIcon from '../assets/bankCheque/bank-cheque-outline-2-arrow.svg';
import BankChequeOutline2Icon from '../assets/bankCheque/bank-cheque-outline-2.svg';
import BankChequeOutlineDotIcon from '../assets/bankCheque/bank-cheque-outline-dot.svg';
import MainChequeIcon from '../assets/bankCheque/bank-cheque.png';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { mergeClassNames } from '../utils/classNameUtils';

export interface GivelifyBankChequePreviewProps extends GivelifyBoxMarginProps {
    description?: string;
    routingNumberText?: string;
    routingNumberTipText?: string;
    accountNumberText?: string;
    accountNumberTipText?: string;
    className?: string;
}

export const GivelifyBankChequePreview: React.FCC<GivelifyBankChequePreviewProps> =
    React.memo((props) => {
        const {
            description = 'Find your account numbers at the bottom of your checks.',
            routingNumberText = 'Routing number',
            routingNumberTipText = '9 digits',
            accountNumberText = 'Account number',
            accountNumberTipText = 'Up to 12 digits',
            margin,
            marginBottom,
            marginLeft,
            marginRight,
            marginTop,
            className,
        } = props;
        const {
            boxMargin,
            checkSuperContainer,
            checkContainer,
            checkTitle,
            checkImage,
            checkDescriptions,
            checkDescriptionRouting,
            checkDescriptionAccount,
            checkDescriptionMain,
            checkDescriptionCaption,
            checkOutline1,
            checkOutline1Arrow,
            checkOutline1Dot,
            checkOutline2,
            checkOutline2Arrow,
            checkOutline2Dot,
        } = checkPreviewStyles({
            margin,
            marginBottom,
            marginLeft,
            marginRight,
            marginTop,
        });

        const rootClassName = mergeClassNames(
            boxMargin,
            checkSuperContainer,
            className,
        );
        return (
            <div className={rootClassName}>
                <div className={checkContainer}>
                    <div className={checkTitle}>{description}</div>
                    <div className={checkImage}>
                        <img
                            src={MainChequeIcon}
                            alt="Bank check"
                            title="Bank check"
                        />
                        <img
                            className={checkOutline1}
                            src={BankChequeOutline1Icon}
                            alt="Bank check outline"
                            title="Bank check outline"
                        />
                        <img
                            className={checkOutline1Arrow}
                            src={BankChequeOutline1ArrowIcon}
                            alt="Bank check outline arrow"
                            title="Bank check outline arrow"
                        />
                        <img
                            className={checkOutline1Dot}
                            src={BankChequeOutlineDotIcon}
                            alt="Bank check outline dot"
                            title="Bank check outline dot"
                        />
                        <img
                            className={checkOutline2}
                            src={BankChequeOutline2Icon}
                            alt="Bank check outline"
                            title="Bank check outline"
                        />
                        <img
                            className={checkOutline2Arrow}
                            src={BankChequeOutline2ArrowIcon}
                            alt="Bank check outline arrow"
                            title="Bank check outline arrow"
                        />
                        <img
                            className={checkOutline2Dot}
                            src={BankChequeOutlineDotIcon}
                            alt="Bank check outline dot"
                            title="Bank check outline dot"
                        />
                    </div>
                    <div className={checkDescriptions}>
                        <div className={checkDescriptionRouting}>
                            <div className={checkDescriptionMain}>
                                {routingNumberText}
                            </div>
                            <div className={checkDescriptionCaption}>
                                {routingNumberTipText}
                            </div>
                        </div>
                        <div className={checkDescriptionAccount}>
                            <div className={checkDescriptionMain}>
                                {accountNumberText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
