import React from 'react';
import { DesignTokens, GivelifyAvatar, GivelifyLink } from '@givelify/ui';
import { AppBar, styled, useMediaQuery, useTheme } from '@mui/material';
import {
    DESKTOP_HEADER_HEIGHT,
    MOBILE_HEADER_HEIGHT,
    TABLET_HEADER_HEIGHT,
} from 'theme/layoutMeaserments';
import logo from '../../../assets/givelify-header-logo.svg';
import mobileLogo from '../../../assets/givelify-header-mobile-logo.svg';
import { TotalDonationsWidgetWrapper } from '../../../pages/overview/widgets';
import { DonorInfo, ToolbarContent } from '../../../styles/headerLayout';
import CampusSelectorWrapper from '../CampusSelector/CampusSelectorWrapper';
import Dropdowns from './Dropdowns';

export const AppBarBlock = styled(AppBar)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.appBar,
    background: '#FFFFFF',
    padding: '0 32px',
    boxShadow:
        '0px 0.25px 2.75px rgba(10, 0, 77, 0.039), 0px 2.38915px 7.81903px rgba(10, 0, 77, 0.08)',
    [theme.breakpoints.down('tablet')]: {
        padding: '0px 16px',
    },
}));

export const ToolbarContainer = styled('div')(({ theme }) => ({
    minHeight: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: DESKTOP_HEADER_HEIGHT,
    [theme.breakpoints.down('tablet')]: {
        height: TABLET_HEADER_HEIGHT,
    },
    [theme.breakpoints.down('mobile')]: {
        height: MOBILE_HEADER_HEIGHT,
    },
}));

export const LogoPicture = styled('picture')(({ theme }) => ({
    [theme.breakpoints.down('tablet')]: {
        height: 32,
    },
}));

export const LogoImg = styled('img')(({ theme }) => ({
    width: 224,
    [theme.breakpoints.down('tablet')]: {
        width: 208,
    },
    [theme.breakpoints.down('mobile')]: {
        width: 32,
    },
}));

export const DonorNameAvatar = styled(GivelifyAvatar)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const ToolbarLink = styled(GivelifyLink)(({ theme }) => ({
    color: '#494A50',
    display: 'inline-flex',
    fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const HeaderDivider = styled('div')(({ theme }) => ({
    height: 48,
    width: 1,
    opacity: 0.1,
    border: '1px solid #000000',
    margin: theme.spacing(0, 4),
    [theme.breakpoints.down('tablet')]: {
        margin: theme.spacing(0, 3),
    },
    [theme.breakpoints.down('mobile')]: {
        margin: theme.spacing(0, 2),
    },
}));

export type HeaderProps = {
    hideTotalDonations: boolean;
};

const HeaderComponent: React.FCC<HeaderProps> = ({ hideTotalDonations }) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const isNotMobile = useMediaQuery(theme.breakpoints.up('mobile'));

    return (
        <AppBarBlock
            color="secondary"
            data-testid="header"
            elevation={1}
            id="header"
        >
            <ToolbarContainer>
                <LogoPicture>
                    <source
                        media={`(max-width: 767.95px)`}
                        srcSet={mobileLogo}
                    />
                    <LogoImg
                        alt="Givelify Analytics Studio"
                        data-testid="givelify-logo"
                        src={logo}
                    />
                </LogoPicture>
                <HeaderDivider />
                <ToolbarContent>
                    <CampusSelectorWrapper />

                    {isTablet && isNotMobile && !hideTotalDonations ? (
                        <TotalDonationsWidgetWrapper fullWidth />
                    ) : null}

                    <DonorInfo>
                        <Dropdowns />
                    </DonorInfo>
                </ToolbarContent>
            </ToolbarContainer>
        </AppBarBlock>
    );
};

export const Header = React.memo(HeaderComponent);
