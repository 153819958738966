import React from 'react';
import { ApiHandler, Deposit } from '@givelify/api';
import {
    TimeFrameValues,
    useTrackingContext,
    MODAL_NAME,
    getDateRangeHash,
} from '@givelify/utils';
import { useTimeframeFilter } from 'api/hooks';
import { useTransactionFilter } from 'components/filters/transactionFilter';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { BANK_DEPOSITS_PAGE_CLICK_DEPOSIT_AMOUNT_LINK } from 'utils/clevertapEvents';

export const useBankDeposits = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            bankTitle: t('menu.donations.bankDeposits'),
            deposits: t('dashboard.deposits'),
            total: t('dashboard.total'),
            depositsTab: t('bankDeposits.tabName'),
            refundsTab: t('refunds.tabName'),
        }),
        [t],
    );
    const navigate = useNavigate();
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const { trackEvent, trackOpenCloseEvent } = useTrackingContext();
    const { PATH } = useGasRouterContext();
    const tabHrefs = React.useMemo(
        () => [PATH.DONATIONS.BANK_DEPOSITS, PATH.DONATIONS.REFUNDS],
        [PATH.DONATIONS.BANK_DEPOSITS, PATH.DONATIONS.REFUNDS],
    );
    const [timeFrame, setTimeFrame] = useTimeframeFilter();
    const [transactionFilterValue, setTransactionFilterValue] =
        useTransactionFilter();
    const [hasRefunds, setHasRefunds] = React.useState(false);
    const dateHash = getDateRangeHash(TimeFrameValues['lifetime']);
    const { data: getRefundsResponse } = useQuery(
        [
            ApiHandler.instance.donees.getRefundedDonations.name,
            doneeId,
            dateHash,
            [],
            1,
        ],
        () =>
            ApiHandler.instance.donees.getRefundedDonations(
                doneeId,
                TimeFrameValues['lifetime'],
                [],
                1,
            ),
    );
    React.useEffect(() => {
        if (getRefundsResponse === undefined || !getRefundsResponse.success)
            return;
        if (getRefundsResponse.response.data?.length > 0) setHasRefunds(true);
    }, [getRefundsResponse]);
    const [donation, setDonation] = React.useState<Deposit | undefined>();
    const handleClose = React.useCallback(() => {
        setDonation(undefined);
        trackOpenCloseEvent(false, MODAL_NAME.DepositDetails);
    }, [trackOpenCloseEvent]);
    const handleOpen = React.useCallback(
        (donation: Deposit) => {
            trackEvent(BANK_DEPOSITS_PAGE_CLICK_DEPOSIT_AMOUNT_LINK);
            setDonation(donation);
            trackOpenCloseEvent(true, MODAL_NAME.DepositDetails);
        },
        [trackEvent, trackOpenCloseEvent],
    );
    const handleTabCahnge = React.useCallback(
        (index: number) => {
            if (index === 1) {
                navigate(tabHrefs[1]);
            }
        },
        [navigate, tabHrefs],
    );
    const tabOptions = hasRefunds
        ? [
              {
                  label: copy.depositsTab,
                  tabHref: tabHrefs[0],
                  id: 'bank-deposits-tab',
              },
              {
                  label: copy.refundsTab,
                  tabHref: tabHrefs[1],
                  id: 'refunds-tab',
              },
          ]
        : [
              {
                  label: copy.depositsTab,
                  tabHref: tabHrefs[0],
                  id: 'bank-deposits-tab',
              },
          ];
    return {
        copy,
        doneeId,
        donation,
        handleClose,
        handleOpen,
        handleTabCahnge,
        setTimeFrame,
        setTransactionFilterValue,
        tabOptions,
        timeFrame,
        transactionFilterValue,
    } as const;
};
