import { makeStyles, Theme, createStyles } from '@material-ui/core';
import {
    multiColorStripBottomGradient,
    multiColorStripGradient,
} from '../theme/gradients';

export const useLoginLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflowX: 'hidden',
            backgroundColor: '#F9FAFC',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                alignContent: 'flex-start',
            },
            '@media (max-width:320px)': {
                height: 'auto',
                overflowY: 'scroll',
            },
        },
        logoContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            margin: '0 auto',
            padding: theme.spacing(1),
            paddingTop: 48,
            paddingBottom: 17,
            backgroundColor: '#F9FAFC',
            [theme.breakpoints.down('sm')]: {
                width: 343,
                maxWidth: 343,
                paddingTop: 32,
            },
            '@media (max-width:320px)': {
                width: 288,
                maxWidth: 288,
            },
        },
        body: {
            minHeight: 630,
            backgroundColor: theme.gas.palette.background.primary,
            boxShadow:
                '0px 0px 4px rgba(10, 0, 77, 0.039), 0px 5px 20px rgba(10, 0, 77, 0.039)',
            borderRadius: 60,
            '@media (max-width:960px)': {
                borderRadius: 40,
            },
            '@media (max-width:320px)': {
                width: 288,
                borderRadius: 20,
            },
        },
        loginBody: {
            padding: '52px 76px 31px',
            [theme.breakpoints.down('sm')]: {
                padding: '24px 16px 8px 16px',
            },
            '& hr': {
                color: '#EEEDF3',
            },
        },
        donateBody: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 106,
            padding: '15px 80px 41px',
            [theme.breakpoints.down('sm')]: {
                height: 84,
                padding: '16px 24px',
            },
            '@media (max-width:320px)': {
                height: 118,
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px 48px',
            },
        },
        giveButton: {
            width: 160,
            height: 42,
            minWidth: 160,
            [theme.breakpoints.down('sm')]: {
                width: 147,
                height: 36,
                minWidth: 147,
            },
        },
        form: {
            width: '100%',
            height: '100%',
            display: 'flex',
            paddingTop: 84,
            '@media (max-width:320px)': {
                paddingTop: 40,
                justifyContent: 'center',
            },
        },
        footer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#F9FAFC',
            [theme.breakpoints.down('sm')]: {
                padding: '16px 40px',
            },
            '@media (max-width:320px)': {
                padding: '16px 0px',
            },
        },
        securedRow: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '24px',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '12px 0px',
            },
        },
        copyRow: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 24,
            marginRight: 16,
            [theme.breakpoints.down('sm')]: {
                marginTop: 16,
                marginBottom: 0,
                marginRight: 0,
            },
        },
        copyRight: {
            marginRight: 16,
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
            },
        },
        termsLink: {
            marginRight: 16,
        },
        supportRowMobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
            },
        },
        showInWeb: {
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        donate: {
            flexGrow: 1,
            padding: theme.spacing(8, 0),
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'space-around',
            '& h2': {
                fontWeight: 'lighter',
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(5),
            },
            [theme.breakpoints.down('xs')]: {
                padding: 0,
                '& h2': {
                    fontSize: 24,
                    '& + div': {
                        display: 'none',
                    },
                },
            },
        },
        donateIcon: {
            fontSize: 90,
            color: '#AAAAAA',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
                '& + div': {
                    display: 'none',
                },
            },
        },
        colorStrip: {
            display: 'block',
            width: '55%',
            height: 2,
            backgroundImage: multiColorStripGradient,
        },
        borderStrip: {
            display: 'block',
            width: 2,
            height: '100%',
            backgroundImage: multiColorStripBottomGradient,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: 2,
                marginTop: 20,
                backgroundImage: multiColorStripGradient,
            },
        },
        logo: {
            height: 54,
            [theme.breakpoints.down('sm')]: {
                height: 34,
            },
        },
        studio: {
            height: 11,
            marginTop: 11,
            [theme.breakpoints.down('sm')]: {
                height: 8,
                marginTop: 8,
            },
        },
        bigTitle: {
            marginTop: theme.spacing(6),
            fontSize: 70,
            fontWeight: 100,
            lineHeight: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: 40,
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(6),
            },
        },
        donateLink: {
            fontSize: 16,
            '& svg': {
                paddingLeft: theme.spacing(2),
                width: 'auto !important',
            },
        },
        linkWiouthUnderline: {
            textDecoration: 'none',
            cursor: 'pointer',
            '&:focus, &:hover, &:visited, &:link, &:active': {
                textDecoration: 'none',
            },
        },
        pointerCursor: {
            cursor: 'pointer',
        },
    }),
);
