import React, { useEffect, useMemo } from 'react';
import {
    FullWidthModal,
    GivelifyBox,
    GivelifyEmailField,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useTranslation } from 'react-i18next';
import { Officer } from 'store/settings/officers/types';
import StepTwo from '../UserActionModal/StepTwo';
import { inviteToGivelifyModalStyles } from './inviteToGivelifyModalStyles';

interface InviteToGivelifyModalProps {
    open: boolean;
    onClose: () => void;
    user: Officer;
    handleUserInfoChange?: ({ name, value }) => void;
    handleEmailChange: (email: string) => void;
    handleInviteUser: () => void;
    emailError: boolean;
}

const InviteToGivelifyModal: React.FCC<InviteToGivelifyModalProps> = (
    props,
) => {
    const {
        onClose,
        open,
        user,
        handleEmailChange,
        emailError,
        handleUserInfoChange,
        handleInviteUser,
    } = props;
    const styles = inviteToGivelifyModalStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            inviteToGivelify: t('pages.settings.users.inviteToGivelify'),
            userInfo: t('pages.settings.users.add-new-modal.title'),
            faithLeader: t('pages.settings.users.faithLeader'),
            persimissions: t('labels.persimissions'),
            cancel: t('labels.cancel'),
            send: t('labels.send'),
            invite: t('labels.invite'),
            allFieldsrequired: t(
                'pages.settings.users.add-new-modal.form.allRequired',
            ),
            willEmailUser: t('pages.settings.users.willEmailUser', {
                required: '(Required)',
            }),
            email: t('pages.settings.users.add-new-modal.form.email'),
            ifUserInfoIncorrect: t('pages.settings.users.ifUserInfoIncorrect'),
        }),
        [t],
    );

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();
    useEffect(() => {
        setButtonBringToFront(!open);
        return () => setButtonBringToFront(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <div id="modal">
            <FullWidthModal
                footer={{
                    onCancel: onClose,
                    onSubmit: handleInviteUser,
                    cancelText: copy.cancel,
                    submitText: copy.invite,
                    disableSubmit: emailError || user.email === '',
                    isLoading: false,
                }}
                heading={copy.inviteToGivelify}
                id="invite-new-user-modal"
                name="invite-new-user"
                onClose={onClose}
                open={open}
                retryMessage=""
            >
                <GivelifyLabel
                    className={styles.tabBodyHeader}
                    text={copy.userInfo}
                    variant="heading3"
                />
                <GivelifyBox
                    backgroundColor="primaryWhite"
                    className={styles.userInfoBox}
                >
                    <div className={styles.userAvatarWrapper}>
                        <GivelifyAvatar color="grey" src={user.avatar} />
                    </div>
                    <div className={styles.userNameContent}>
                        <GivelifyLabel
                            bold
                            className={styles.fullName}
                            text={`${user.firstName} ${user.lastName}`}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={styles.title}
                            text={user.title}
                            variant="small"
                        />
                    </div>
                </GivelifyBox>
                <GivelifyLabel
                    color="neutralGrey"
                    text={copy.ifUserInfoIncorrect}
                    variant="body3"
                />
                <GivelifyEmailField
                    boxSize="large"
                    className={styles.emailField}
                    id="email"
                    label={copy.email}
                    name="email"
                    onChange={(event, value) => {
                        handleEmailChange(value);
                    }}
                    onEnterSubmit={handleEmailChange}
                    placeholder={copy.email}
                    state={emailError ? 'error' : 'normal'}
                    value={user.email || ''}
                />
                <GivelifyLabel
                    className={styles.emailFieldHelpText}
                    color="neutralGrey"
                    text={copy.willEmailUser}
                    variant="body3"
                />
                <StepTwo
                    onUserInfoChange={handleUserInfoChange}
                    userInfo={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        title: user.title,
                        email: user.email,
                        role: user.role,
                    }}
                    withStep={false}
                />
            </FullWidthModal>
        </div>
    );
};

export default InviteToGivelifyModal;
