import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    TrashIcon,
    UploadIcon,
    InfoWarningIcon,
} from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { OnboardingActions } from '../../../../../../requiredInfo/OnboardingActions';
import { UseTaxIdContext } from '../../../TaxIdContext';
import { useUploadDocumentTranslation } from '../useUploadDocumentTranslation';
import PreviewModal from './PreviewModal';
import {
    DeleteButton,
    ListWrapper,
    UploadedFileImage,
    UploadedFileWrapper,
    CheckMarkIcon,
} from './styles';

type PageContentProps = {
    showExampleModal: () => void;
    showUploadedModal: () => void;
    showModal: boolean;
    exampleImage: string;
    modalType: 'example' | 'uploaded';
    onModalClose: () => void;
};

const PageContent: React.FCC<PageContentProps> = ({
    showExampleModal,
    showUploadedModal,
    showModal,
    exampleImage,
    modalType,
    onModalClose,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const {
        selectedDocumentType,
        isLoading,
        onBackClick,
        onCancelClick,
        onUploadDocumentContinue,
        filePickerProps: {
            openFilePicker,
            uploadedFile,
            fileContent,
            onDeleteClick,
            fileConvertInProgress,
            error,
            fileExtension,
        },
    } = UseTaxIdContext();

    const { text, copy } = useUploadDocumentTranslation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedDocumentType! as
            | 'confirmationLetter'
            | 'bankStatement'
            | '990Form',
    );

    const validFileUploaded = !!(uploadedFile && fileContent);

    return (
        <>
            <GivelifyLabel
                style={{
                    marginBottom: isMobile ? 8 : 12,
                }}
                text={text?.heading}
                variant={'heading1S'}
            />
            <GivelifyLabel
                style={{
                    marginBottom: 24,
                    color: DesignTokens.color.textSecondary,
                }}
                variant={'body2'}
            >
                {isMobile ? (
                    <>
                        {text?.descriptionMobile1}{' '}
                        <GivelifyLink
                            name="ViewExample"
                            onClick={showExampleModal}
                            text={text?.descriptionMobile2 || ''}
                            variant="body2"
                        />{' '}
                        {text?.descriptionMobile3}
                    </>
                ) : (
                    text?.description
                )}
            </GivelifyLabel>
            {!isMobile && (
                <ListWrapper>
                    {text?.listItems.map((listItem, index) => (
                        <li key={index}>
                            <GivelifyLabel text={listItem} variant="body2" />
                        </li>
                    ))}
                </ListWrapper>
            )}
            {validFileUploaded ? (
                <UploadedFileWrapper>
                    <CheckMarkIcon />
                    <UploadedFileImage src={fileContent} />
                    <GivelifyLink
                        name="ViewUploadedDoc"
                        onClick={showUploadedModal}
                        style={{
                            marginTop: 12,
                            marginBottom: 16,
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        text={uploadedFile?.name}
                        title={uploadedFile?.name}
                    />
                    <DeleteButton
                        name="delete"
                        onClick={onDeleteClick}
                        startIcon={<TrashIcon />}
                        text={copy.delete}
                        variant="ghost"
                    />
                </UploadedFileWrapper>
            ) : (
                <GivelifyButton
                    isLoading={fileConvertInProgress}
                    name="UploadDoc"
                    onClick={openFilePicker}
                    startIcon={!fileConvertInProgress && <UploadIcon />}
                    text={text?.buttonText}
                    variant="ghost"
                />
            )}
            <div style={{ marginTop: 16 }}>
                {error && (
                    <div
                        style={{
                            marginBottom: 8,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <InfoWarningIcon
                            style={{
                                marginRight: 8,
                                color: DesignTokens.color.textErrorDefault,
                                width: 24,
                                height: 24,
                            }}
                        />
                        <GivelifyLabel
                            style={{
                                color: DesignTokens.color.textErrorDefault,
                            }}
                            variant="body2"
                        >
                            {error === 'size'
                                ? copy.errorSize
                                : copy.errorExtension}
                        </GivelifyLabel>
                    </div>
                )}
                {!validFileUploaded && (
                    <>
                        <GivelifyLabel
                            text={copy.labelExtension}
                            variant="body2"
                        />
                        <GivelifyLabel text={copy.labelSize} variant="body2" />
                    </>
                )}
            </div>

            <OnboardingActions
                continueText={copy.submit}
                disableContinue={!uploadedFile}
                isLoading={isLoading}
                onBack={onBackClick}
                onCancel={onCancelClick}
                onContinue={onUploadDocumentContinue}
            />
            <PreviewModal
                exampleImage={exampleImage || ''}
                fileContent={fileContent || ''}
                fileExtension={fileExtension || ''}
                modalType={modalType}
                onClose={onModalClose}
                open={showModal}
                uploadedFile={uploadedFile}
            />
        </>
    );
};

export default PageContent;
