import React from 'react';
import { useTrackingContext } from '@givelify/utils';
import { ClickAwayListener, useMediaQuery, useTheme } from '@mui/material';
import { DonationsStatisticType } from 'api/client/endpoints';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    Bar,
    BarChart as RechartBarChart,
    LabelList,
    BarProps,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from 'recharts';
import { AxisInterval } from 'recharts/src/util/types';
import { TREND_CHART_BAR_CLICK } from 'utils/clevertapEvents';
import { getRangeBasedDate } from 'utils/strings/getRangeBasedDateFormat';
import { ChartBar } from '../../../components/ChartBar';
import { ChartXAxisTick } from '../../../components/ChartXAxisTick';
import { HighestLabel } from '../../../components/HighestLabel';
import { TrendData } from '../../../types';
import { ChartTooltip } from '../ChartTooltip';
import { Wrapper } from './styles';

dayjs.extend(utc);

type BarChartComponentProps = {
    data: TrendData;
    dataKey: string;
    rangeDateType?: DonationsStatisticType;
};

const BarChartComponent: React.FCC<BarChartComponentProps> = ({
    data,
    dataKey,
    rangeDateType = 'daily',
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const isSmallTablet = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const { trackEvent } = useTrackingContext();
    const highestLabelRef = React.useRef<SVGTextElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [tooltipPosition, setTooltipPosition] = React.useState({
        x: 0,
        y: 0,
    });
    const [activeBarIndex, setActiveBarIndex] = React.useState(null);
    const barChartRef = React.useRef(null);
    const maxDataValue = React.useMemo(
        () => Math.max(...data.map((d) => d.sum)),
        [data],
    );
    const isOnlyDonation = React.useMemo(
        () => data.filter((item) => item.sum > 0).length <= 1,
        [data],
    );
    const handleBarClick = React.useCallback(
        (data: BarProps & TrendData[number], index: number) => {
            const { x, y, width, startDate } = data;
            if (startDate.isAfter(dayjs())) return null;

            const barTopLeftX = Number(x) + Number(width) / 2;
            setActiveBarIndex(index);
            setTooltipPosition({ x: barTopLeftX, y: Number(y) });

            trackEvent(TREND_CHART_BAR_CLICK);
        },
        [trackEvent],
    );
    const setHighestLabelWidth = React.useCallback(() => {
        setLabelWidth(
            highestLabelRef?.current?.getBoundingClientRect().width ?? 0,
        );
    }, [highestLabelRef]);
    const isHoursView = rangeDateType === 'hourly';
    const xAxisTickInterval: AxisInterval = React.useMemo(() => {
        const amountOfBars = data.length;
        if (isMobile) {
            switch (rangeDateType) {
                case 'hourly':
                    return 5;
                case 'daily':
                    return amountOfBars > 15 ? 1 : 0;
                case 'weekly':
                    return amountOfBars > 6 ? 1 : 0;
                case 'monthly':
                    return amountOfBars >= 12
                        ? amountOfBars >= 24
                            ? 2
                            : 1
                        : 0;
                case 'yearly':
                    return amountOfBars > 10 ? 1 : 0;
            }
        } else if (isTablet) {
            switch (rangeDateType) {
                case 'hourly':
                    return 2;
                case 'daily':
                    return amountOfBars > 14 ? 1 : 0;
                default:
                    return 0;
            }
        } else {
            switch (rangeDateType) {
                case 'hourly':
                    return 2;
                default:
                    return 0;
            }
        }
    }, [data, isMobile, isTablet, rangeDateType]);
    const chartTooltipLabel = React.useMemo(() => {
        if (activeBarIndex === null) return null;
        return getRangeBasedDate(
            data[activeBarIndex].startDate,
            data[activeBarIndex].endDate,
            rangeDateType,
        );
    }, [activeBarIndex, data, rangeDateType]);
    const maxHeight = React.useMemo(() => {
        if (isSmallTablet) return 192;
        if (isTablet) return 240;
        if (isMobile) return 230;
        return 232;
    }, [isMobile, isSmallTablet, isTablet]);

    const highestBar = React.useMemo(
        () =>
            data?.reduce(
                (acc, val, idx) => {
                    if (val.sum > acc.value) {
                        return { value: val.sum, index: idx };
                    }
                    return acc;
                },
                { value: data[0]?.sum, index: 0 },
            ),
        [data],
    );

    return (
        <Wrapper ref={barChartRef} data-testid="trend-bar-chart-wrapper">
            <ClickAwayListener
                disableReactTree={false}
                onClickAway={() => setActiveBarIndex(null)}
            >
                <div>
                    <ResponsiveContainer
                        aspect={1}
                        maxHeight={maxHeight}
                        width="99%"
                    >
                        <RechartBarChart
                            barCategoryGap={isHoursView || isTablet ? 0.5 : 2}
                            data={data}
                        >
                            <XAxis
                                axisLine={false}
                                dataKey={dataKey}
                                interval={xAxisTickInterval}
                                tick={(props) => (
                                    <ChartXAxisTick
                                        {...props}
                                        data={data}
                                        rangeDateType={rangeDateType}
                                    />
                                )}
                                tickLine={false}
                            />
                            <Tooltip
                                active={false}
                                content={() => null}
                                cursor={false}
                                isAnimationActive={false}
                            />
                            {!isOnlyDonation ? (
                                <ReferenceLine
                                    ifOverflow="extendDomain"
                                    strokeDasharray="4"
                                    y={maxDataValue}
                                />
                            ) : null}
                            <Bar
                                dataKey="sum"
                                onAnimationEnd={setHighestLabelWidth}
                                onClick={handleBarClick}
                                shape={(props) => (
                                    <ChartBar
                                        {...props}
                                        activeIndex={activeBarIndex}
                                    />
                                )}
                            >
                                <LabelList
                                    content={(props) => (
                                        <HighestLabel
                                            {...props}
                                            ref={highestLabelRef}
                                            data={data}
                                            highestLabelIndex={highestBar.index}
                                            labelWidth={labelWidth}
                                        />
                                    )}
                                    dataKey="sum"
                                />
                            </Bar>
                        </RechartBarChart>
                    </ResponsiveContainer>
                    {data[activeBarIndex]?.sum > 0 &&
                    activeBarIndex !== null ? (
                        <ChartTooltip
                            label={chartTooltipLabel}
                            value={data[activeBarIndex].sum}
                            x={tooltipPosition.x}
                            y={tooltipPosition.y}
                        />
                    ) : null}
                </div>
            </ClickAwayListener>
        </Wrapper>
    );
};

export const BarChart = React.memo(BarChartComponent);
