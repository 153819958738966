import React from 'react';
import { Grid } from '@material-ui/core';
import { Hidden } from '@mui/material';
import { FilterInputWrapper } from 'components/inputs/FilterInputWrapper';
import { CampuseItem } from 'pages/integrations/IntegrationContent/TabsContent/EnvelopeMatching/types';
import { matchSelect } from '../../../../../../utils/integrationFormatFilterUtils';
import { MatchOption } from '../../types';
import CampusesSelect from './CampusesSelect';
import { useStyles } from './CampusesSelect/styles';
import MatchSelect from './MatchSelect';

interface DesktopControlsProps {
    reportId: number;
    matchFilter: matchSelect;
    onMatchFilterChange: (value: matchSelect) => void;
    matchOptions: MatchOption[];
    campusesOptions?: CampuseItem[];
    campusesFilter?: CampuseItem;
    onCampusesFilterChange: (value: CampuseItem) => void;
    hasCampuses?: boolean;
}

const DesktopControls: React.FCC<DesktopControlsProps> = ({
    reportId,
    matchFilter,
    matchOptions,
    onMatchFilterChange,
    campusesOptions,
    campusesFilter,
    onCampusesFilterChange,
    hasCampuses,
}) => {
    const { filterHeader } = useStyles();
    return (
        <Hidden smDown>
            <Grid item md={8} xs={12}>
                <FilterInputWrapper className={filterHeader}>
                    {!reportId && (
                        <MatchSelect
                            matchFilter={matchFilter}
                            matchOptions={matchOptions}
                            onMatchFilterChange={onMatchFilterChange}
                        />
                    )}
                    {!reportId && hasCampuses && (
                        <CampusesSelect
                            campusesFilter={campusesFilter}
                            campusesOptions={campusesOptions}
                            onCampusesFilterChange={onCampusesFilterChange}
                        />
                    )}
                </FilterInputWrapper>
            </Grid>
        </Hidden>
    );
};

export default DesktopControls;
