import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store';
import { LoadTotalUnmatchedStateAsync } from '../../../../../../../store/automatedIntegration/thunks';
import { IntegrationTypes } from '../../../../../types';
import { donorTabStyle } from '../../style';
import {
    AutoInteDonorUnmatchData,
    IntegrationPaginatedResponse,
    integrationUrl,
} from '../../types';
import AutoIntegrationInfiniteLoader from '../AutoIntegrationInfiniteLoader';
import { TableLoadingComponent } from '../AutoIntegrationLoading';
import AutoIntegrationNoResultComponent from '../AutoIntegrationNoResultComponent';
import { matchDonor } from '../automatedIntegrationRequest';
import AutoIntegrationUnmatchDonorHeader from './AutoIntegrationUnmatchDonorHeader';
import AutoIntegrationUnMatchDonorModal from './AutoIntegrationUnMatchDonorModal';
import AutoIntegrationUnmatchDonorRowButton from './AutoIntegrationUnmatchDonorRowButton';
import AutoIntegrationUnmatchDonorRowInfo from './AutoIntegrationUnmatchDonorRowInfo';

interface F1CCBUnmatchDonorContentProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onSortingChange: (sorting: Sorting[]) => void;
    searchValue: string;
    campusSelected: number;
    integration: IntegrationTypes;
    isReadOnly: boolean;
}

const TRANSLATION_KEY = 'pages.integration_donor_matching';

const AutoIntegrationUnmatchDonorContent: React.FCC<
    F1CCBUnmatchDonorContentProps
> = ({
    sortColumn,
    sortDirection,
    onSortingChange,
    searchValue,
    campusSelected,
    integration,
    isReadOnly,
}) => {
    const classes = donorTabStyle();
    const { t, scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: TRANSLATION_KEY,
    });
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));
    const dispatch = useDispatch();

    const copy = useMemo(
        () => ({
            donorName: scopedTranslate(`content.table.heading.name`),
            lastDonation: scopedTranslate(
                `content.table.heading.last_donation`,
            ),
            lastDonationsDate: scopedTranslate(
                `content.table.heading.last_donations_date`,
            ),
            noOfMatches: scopedTranslate(`content.table.heading.no_of_matches`),
            sort: t('labels.sort'),
            errorMatchDonor: t('pages.integrations.matchError.errorDonor'),
            noMatchDonor: t('pages.integrations.matchError.noDonor'),
            noIntegrationId: t('f1DonorPage.noIntegrationId', {
                integration: integration.integrationType,
            }),
        }),
        [scopedTranslate, t, integration.integrationType],
    );

    const [selectedDonorId, setSelectedDonorId] = useState<number>();
    const [selectedPageNumber, setSelectedPageNumber] = useState<number>();
    const [unMatchModal, setUnMatchModal] = useState<boolean>(false);
    const openUnMatchModal = (donorId: number, pageNumber: number) => {
        setSelectedDonorId(donorId);
        setSelectedPageNumber(pageNumber);
        setUnMatchModal(true);
    };

    const [reset, setReset] = useState<true | undefined>();
    const [reload, setReload] = useState<boolean>(false);
    const url = useCallback(
        (pageNumber: number) => {
            return integrationUrl(
                userDoneeId,
                `donors?has_match=0&page=${pageNumber}&per_page=10${
                    searchValue.length > 0 ? '&search=' + searchValue : ''
                }${
                    campusSelected !== 0 ? '&campus_id=' + campusSelected : ''
                }&order_by=${sortColumn}&sort=${sortDirection}`,
            );
        },
        [userDoneeId, searchValue, campusSelected, sortColumn, sortDirection],
    );
    const Error = (
        <GivelifyLabel
            bold
            margin={16}
            text={copy.errorMatchDonor}
            variant="body2"
        />
    );
    const Zeroth = (
        <AutoIntegrationNoResultComponent text={copy.noMatchDonor} />
    );
    useEffect(() => {
        setReset(true);
    }, [doneeId]);
    const onReset = () => setReset(undefined);
    const onReloaded = () => setReload(false);

    const [matchDonorState, requestMatchDonor] = useApiRequest();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const matchDonorFunction = (donorId: number, chmsDonorId: number) => {
        setErrorMessage('');
        void requestMatchDonor(matchDonor(userDoneeId, donorId, chmsDonorId));
    };
    const closeUnMatchModal = React.useCallback(
        (isDonorMatched?: boolean) => {
            setUnMatchModal(false);
            setErrorMessage('');

            if (isDonorMatched) {
                setReload(true);
                dispatch(LoadTotalUnmatchedStateAsync());
            }
        },
        [dispatch],
    );
    const changeMessageFunction = (value: string) => setErrorMessage(value);
    useEffect(() => {
        if (matchDonorState.type === 'REQUEST_SUCCESS') {
            closeUnMatchModal();
            setReload(true);
            dispatch(LoadTotalUnmatchedStateAsync());
        } else if (matchDonorState.type === 'REQUEST_ERROR') {
            setErrorMessage(copy.noIntegrationId);
        }
    }, [matchDonorState, dispatch, copy.noIntegrationId, closeUnMatchModal]);
    const [hasData, setHasData] = useState<boolean>(false);
    const setDataFunction = (value: boolean) => setHasData(value);

    return (
        <>
            {!hasData && (
                <AutoIntegrationUnmatchDonorHeader
                    copy={{
                        donorName: copy.donorName,
                        lastDonation: copy.lastDonation,
                        noOfMatches: copy.noOfMatches,
                        lastDonationDate: copy.lastDonationsDate,
                        sort: copy.sort,
                    }}
                    onOrderClick={onSortingChange}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            )}
            <AutoIntegrationInfiniteLoader
                ErrorComponent={Error}
                LoadingComponent={TableLoadingComponent}
                ZerothComponent={Zeroth}
                hasData={setDataFunction}
                onReloaded={onReloaded}
                onReset={onReset}
                reload={reload}
                renderData={(
                    data: IntegrationPaginatedResponse<AutoInteDonorUnmatchData>,
                ) => (
                    <Grid container className={classes.unmatchDonorContent}>
                        <Grid item className={classes.unmatchDonorInfo}>
                            <AutoIntegrationUnmatchDonorHeader
                                copy={{
                                    donorName: copy.donorName,
                                    lastDonation: copy.lastDonation,
                                    noOfMatches: copy.noOfMatches,
                                    lastDonationDate: copy.lastDonationsDate,
                                    sort: copy.sort,
                                }}
                                onOrderClick={onSortingChange}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                            />
                            {data.data.map((donor) => (
                                <AutoIntegrationUnmatchDonorRowInfo
                                    key={donor.id}
                                    avatar={donor.pictureUrl}
                                    donorName={donor.fullName}
                                    lastDonation={donor.lastDonationAmount}
                                    lastDonationDate={donor.lastDonatedAt}
                                    numberOfMatches={
                                        donor.matchSuggestionsCount
                                    }
                                />
                            ))}
                        </Grid>
                        <Grid item className={classes.unmatchDonorButton}>
                            <div className={classes.unmatchHeaderDonorButton} />
                            {data.data.map((donor, id) => (
                                <AutoIntegrationUnmatchDonorRowButton
                                    key={donor.id}
                                    donorId={donor.id}
                                    isReadOnly={isReadOnly}
                                    openUnMatchModal={openUnMatchModal}
                                    pageNumber={data.pageNumbers[id]}
                                />
                            ))}
                        </Grid>
                    </Grid>
                )}
                reset={reset}
                selectedPageNumber={selectedPageNumber}
                url={url}
            />
            {unMatchModal && (
                <AutoIntegrationUnMatchDonorModal
                    donorId={selectedDonorId}
                    errorMessage={errorMessage}
                    handleChangeMessage={changeMessageFunction}
                    handleClose={closeUnMatchModal}
                    integration={integration}
                    isReadOnly={isReadOnly}
                    matchDonor={matchDonorFunction}
                    open={unMatchModal}
                />
            )}
        </>
    );
};

export default AutoIntegrationUnmatchDonorContent;
