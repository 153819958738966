import React from 'react';
import { GivelifyButton, GivelifyTextDivider } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';

import ShareIcon from '../../../assets/icon/share.svg';
import CopyGivingLink from '../../../components/CopyGivingLink';
import Label from '../../../components/Label';
import Page from '../../../components/Page';
import SMGivingTip from '../../../components/SMGivingTip';
import { useConnectorState, useDonee } from '../../../provider/Connector';
import {
    TW_GIVING_LOADED,
    TW_GIVING_SHARE,
} from '../../../utils/cleverTapEvents';
import { pushEvent } from '../../../utils/cleverTapUtils';
import { useTranslations } from '../../../utils/translations';
import Preview from './Preview';
import { useStyle } from './style';

export const useTwitterShareURL = (doneeName: string, givingLink: string) => {
    const { post } = useTranslations('socialMediaGiving.twitter.page', {
        post: ['text'],
    });

    const twitterShareURL = React.useMemo(() => {
        const postContent = post.text.replace('{DONEE_NAME}', doneeName);

        return (
            `https://twitter.com/intent/tweet?text=${postContent} ` +
            encodeURI(givingLink)
        );
    }, [doneeName, post.text, givingLink]);

    return twitterShareURL;
};

export const TwitterGiving: React.FCC = () => {
    const cs = useStyle();
    const donee = useDonee();
    const { onlineGivingURLs } = useConnectorState();

    const { pageTitle, increaseGiving, copyOrgLink } = useTranslations(
        'socialMediaGiving.twitter.page',
        {
            pageTitle: 'title',
            increaseGiving: ['title', 'desc', 'shareOnTwitter'],
            copyOrgLink: ['title', 'desc'],
        },
    );

    const givingLink =
        onlineGivingURLs.find((x) => x.channel === 'TWT')?.shortLink || '';

    const twitterShareURL = useTwitterShareURL(donee.name, givingLink);

    const handleShareClick = React.useCallback(() => {
        window.open(twitterShareURL, '_blank');
        pushEvent(TW_GIVING_SHARE);
    }, [twitterShareURL]);

    React.useEffect(() => {
        pushEvent(TW_GIVING_LOADED);
    }, []);

    return (
        <Page
            useBreadcrumb
            useDivider
            landingPageName="socialMediaGiving"
            name="Twitter"
            title={pageTitle}
        >
            <div className={cs.container} data-testid="twitter-giving">
                <Grid container spacing={5}>
                    <Grid item className={cs.fullContent} md={6} sm={12}>
                        <Label variant="heading4">{increaseGiving.title}</Label>
                        <Label className={cs.shareBody} variant="body1">
                            {increaseGiving.desc}
                        </Label>

                        <GivelifyButton
                            className={cs.shareButton}
                            data-testid="share-on-twitter-button"
                            name="shareOnTwitter"
                            onClick={handleShareClick}
                            startIcon={
                                <img
                                    alt="share"
                                    src={ShareIcon}
                                    title="share"
                                />
                            }
                            text={increaseGiving.shareOnTwitter}
                            variant="primary"
                        />

                        <GivelifyTextDivider className={cs.divider} text="or" />

                        <Label variant="heading4">{copyOrgLink.title}</Label>
                        <Label className={cs.shareBody} variant="body1">
                            {copyOrgLink.desc}
                        </Label>

                        <CopyGivingLink givingLink={givingLink} />
                    </Grid>

                    <Grid item className={cs.fullContent} md={6} sm={12}>
                        <Preview />
                    </Grid>
                </Grid>

                <SMGivingTip
                    className={cs.tipContainer}
                    link="https://twitter.com/twittersupport/status/458707240325615617"
                    media="twitter"
                />
            </div>
        </Page>
    );
};
