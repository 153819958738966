import React, { useMemo } from 'react';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { BasicPageWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import MissionStatementPage from './components/MissionStatementPage';

export const MissionStatement: React.FCC = () => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.mission-statement.title'),
            caption: t('pages.settings.mission-statement.caption'),
        }),
        [t],
    );

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsMissionStatement}
        >
            <BasicPageWrapper
                contentWrapper="legacy"
                description={copy.caption}
                title={copy.title}
            >
                <MissionStatementPage />
            </BasicPageWrapper>
        </TrackingProvider>
    );
};
