import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { ApiHandler } from '@givelify/api';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyErrorBanner,
    GivelifyTextField,
    emailRegex,
} from '@givelify/givelify-ui';
import {
    TrackingProvider,
    PAGE_NAME,
    useApiRequest,
    useTrackingContext,
} from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { LOGIN_PAGE_CLICK_FORGOT_PASSWORD_SUBMIT } from 'utils/clevertapEvents';
import { useRecaptcha } from 'utils/useRecaptcha';
import Space from '../../Space';
import { useStyle } from './useStyles';

interface Props {
    handleClose: () => void;
    handleResetPasswordSucceededModalOpen?: () => void;
}

export const RecoverPassword: React.FCC<Props> = ({
    handleClose,
    handleResetPasswordSucceededModalOpen,
}) => {
    const { trackEvent } = useTrackingContext();
    const [requestState, makeRequest] = useApiRequest();
    const [email, setEmail] = useState<string>('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isRobot, setIsRobot] = useState(false);
    const classes = useStyle();
    const { t } = useTranslation();
    const { getRecaptchaToken } = useRecaptcha('recover_password');
    const handleEmailChange = (_e: unknown, v: string) => setEmail(v);

    const recoverText = useMemo(
        () => ({
            recover: {
                header: t('login.recover.header'),
                description: t('login.recover.description'),
                button: t('login.recover.button'),
                cancelLink: t('login.recover.cancel_link'),
                signinLink: t('login.recover.signin_link'),
                requiredEmail: t('login.recover.required_email'),
                invalidEmail: t('login.recover.invalid_email'),
                email: t('login.recover.email'),
                forgotAlert: t('login.recover.forgot_alert'),
                helpContact: t('login.recover.help_contact'),
                confirmation: t('login.recover.confirmation'),
                pleaseCheckInbox: t('login.recover.pleaseCheckInbox'),
                errorSendingEmail: t('login.recover.errorSendingEmail'),
            },
            notRobot: t('labels.notRobot'),
        }),
        [t],
    );

    const sendRecoverEmail = async () => {
        if (!emailRegex.test(email)) {
            setIsValidEmail(false);
            return;
        }
        setIsValidEmail(true);
        const token = await getRecaptchaToken();
        if (token) {
            makeRequest(
                ApiHandler.instance.auth.forgotPassword({
                    email,
                    captchaToken: token,
                }),
            );
            setIsRobot(false);
        } else {
            setIsRobot(true);
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        trackEvent(LOGIN_PAGE_CLICK_FORGOT_PASSWORD_SUBMIT);
        e.preventDefault();
        sendRecoverEmail();
    };

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            handleClose();
            if (handleResetPasswordSucceededModalOpen) {
                handleResetPasswordSucceededModalOpen();
            }
        }
    }, [requestState, handleResetPasswordSucceededModalOpen, handleClose]);

    const formError =
        requestState.type === 'REQUEST_ERROR' || !isValidEmail || isRobot;
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.RecoverPassword}>
            <div className={classes.modalContainer}>
                <form onSubmit={onSubmit}>
                    <GivelifyLabel
                        className={classes.loginTitle}
                        text={recoverText.recover.header}
                        variant="heading3"
                    />
                    <Space gap={formError ? 4 : 5} />
                    {formError && (
                        <>
                            <GivelifyErrorBanner
                                text={
                                    isRobot
                                        ? recoverText.notRobot
                                        : isValidEmail
                                        ? recoverText.recover.errorSendingEmail
                                        : recoverText.recover.invalidEmail
                                }
                            />
                            <Space gap={3} />
                        </>
                    )}
                    <GivelifyLabel
                        text={recoverText.recover.description}
                        variant="body1"
                    />
                    <GivelifyTextField
                        required
                        ariaLabel="email"
                        id="email"
                        marginTop={24}
                        onChange={handleEmailChange}
                        onEnterSubmit={sendRecoverEmail}
                        placeholder={recoverText.recover.email}
                        type="text"
                        value={email}
                        variant="alt"
                    />
                    <Space gap={5} />
                    <GivelifyButton
                        className={classes.button}
                        disabled={!email}
                        isLoading={requestState.type === 'REQUEST_START'}
                        name="submit"
                        size="xLarge"
                        text={recoverText.recover.button}
                        type="submit"
                        variant="primary-alt"
                    />
                </form>
            </div>
        </TrackingProvider>
    );
};

export default RecoverPassword;
