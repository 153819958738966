import {
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const SocialItemValue = styled('div')({
    marginBottom: 14,
    display: 'flex',
    alignItems: 'center',
});

export const ImgContainer = styled('div')({
    minWidth: 28,
    height: 28,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const LinkContainer = styled('div')({
    width: 'calc(100% - 44px)',
});

export const ValLink = styled(GivelifyLink)({
    fontSize: 16,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    textDecoration: 'none',
    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    '&:visited': {
        textDecoration: 'none',
    },
});

export const WebsiteCol = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginTop: 12,
    },
}));

export const EditorContainerForm = styled('form')({
    width: '100%',
    marginTop: 32,
    marginBottom: 8,
});

export const EditorItemTitle = styled(GivelifyLabel)({
    lineHeight: '20px',
    marginBottom: 12,
});

export const EditorItemValue = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 40,
    '& .left-helper-text': {
        marginLeft: '12px',
    },
});

export const EditorInputField = styled(
    GivelifyFormTextField,
)({
    width: 'calc(100% - 12px)',
    marginLeft: '12px',
}) as typeof GivelifyFormTextField;
