import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DATE_RANGE_TYPE, ScheduledReport } from 'store/integration/types';
import IntegrationBaseOnSelector from './IntegrationBaseOnSelector';
import { useStyles } from './style';

interface IntegrationSetupBaseOnProps {
    reportExists: boolean;
    scheduledReport: ScheduledReport;
    onBaseChange: (value: DATE_RANGE_TYPE) => void;
    defaultBaseOn: string;
    isReadOnly: boolean;
}

const IntegrationSetupBaseOn: React.FCC<IntegrationSetupBaseOnProps> = ({
    onBaseChange,
    defaultBaseOn,
    isReadOnly,
}) => {
    const { integrationBaseOn, baseOnText } = useStyles();

    const baseOnOptions = [
        DATE_RANGE_TYPE.DONATION,
        DATE_RANGE_TYPE.BANK_DEPOSITS,
    ];

    const { t: trans } = useTranslation();
    const copy = useMemo(
        () => ({
            baseOnTitle: trans('pages.integrations.settings.baseOnTitle'),
        }),
        [trans],
    );

    return (
        <Box className={integrationBaseOn}>
            <GivelifyLabel
                bold
                className={baseOnText}
                text={copy.baseOnTitle}
            />
            <IntegrationBaseOnSelector
                defaultBaseOn={defaultBaseOn}
                initialValues={baseOnOptions}
                isReadOnly={isReadOnly}
                name="dateRangeType"
                onBaseChange={onBaseChange}
                options={baseOnOptions}
            />
        </Box>
    );
};

export default IntegrationSetupBaseOn;
