import React, { useEffect, useState } from 'react';
import { User } from '@givelify/api';
import { requestInit, useApiRequest } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { PrimaryRepresentativeInfo } from '../../@types/assets/onboarding';
import { primaryRepresentativeAPI } from '../../api/requests/api';
import { I18N_NAMESPACE } from '../../consts';
import { FullWidthModalOnboarding } from '../../modal';
import {
    getPrimaryRepLocalStorage,
    setPrimaryRepLocalStorage,
} from '../../utils/primaryRepLocalStorage';
import { PrimaryRepresentativePage } from './PrimaryRepresentativePage';
import { PrimaryRepresentativePageRef } from './utils';

interface PrimaryRepresentativeModalProps {
    doneeId: number;
    user: User;
    data: PrimaryRepresentativeInfo;
    isOpen: boolean;
    onCancel: () => unknown;
    onSubmit: (data: PrimaryRepresentativeInfo) => unknown;
    setButtonBringToFront: (value: boolean) => void;
}

const PrimaryRepresentativeModal: React.FCC<
    PrimaryRepresentativeModalProps
> = ({ onSubmit, isOpen, onCancel, data, doneeId, user, setButtonBringToFront }) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const [saveDataStatus, makeSaveDataRequest, setStatus] =
        useApiRequest<unknown>();
    const [step, setStep] = useState<1 | 2>(1);
    const pageRef = React.useRef<PrimaryRepresentativePageRef>(null);
    const [isFormValid, setFormValid] = React.useState(false);
    const {
        representativeModalTitle,
        defaultModalRetryText,
        errorMessage,
        submit,
        next,
    } = React.useMemo(
        () => ({
            representativeModalTitle: t('primaryRepresentativePage.title'),
            defaultModalRetryText: t('labels.defaultModalRetryText'),
            errorMessage: t('errors.generic'),
            submit: t('labels.submit'),
            next: t('labels.next'),
        }),
        [t],
    );
    const onPrimaryRepresentativeSubmit = React.useCallback(() => {
        if (pageRef.current) {
            const data =
                step === 1
                    ? pageRef.current.submitStep1()
                    : pageRef.current.submitStep2();
            if (!data) return;
            onSubmit(data);
            if (step === 2) {
                const step1 = getPrimaryRepLocalStorage();
                const primaryRep = step1 ? { ...step1, ...data } : data;
                makeSaveDataRequest(
                    primaryRepresentativeAPI(doneeId, primaryRep),
                );
            } else {
                setStep(2);
                setPrimaryRepLocalStorage(data);
            }
        }
    }, [onSubmit, doneeId, makeSaveDataRequest, step, pageRef]);
    const setValidation = React.useCallback(
        (isValid: boolean): void => {
            setFormValid(isValid);
        },
        [setFormValid],
    );

    useEffect(() => {
        if (saveDataStatus.type === 'REQUEST_SUCCESS') {
            if (step === 1) {
                setStep(2);
                setStatus(requestInit());
            } else {
                onSubmit({ ...data, status: 'in_progress' });
                onCancel();
            }
        }
        // eslint-disable-next-line
    }, [saveDataStatus.type]);

    const onClose = React.useCallback(() => {
        setStatus(requestInit());
        if (step === 2) {
            setStep(1);
        } else {
            onCancel();
        }
    }, [setStatus, onCancel, step]);

    const onStepClick = React.useCallback(
        (index: number) => {
            if (index === 0) {
                onClose();
            }
        },
        [onClose],
    );

    return (
        <FullWidthModalOnboarding
            useBackArrow
            errorMessage={
                saveDataStatus.type === 'REQUEST_ERROR'
                    ? saveDataStatus.error.message || errorMessage
                    : undefined
            }
            footer={{
                onCancel,
                onSubmit: onPrimaryRepresentativeSubmit,
                disableSubmit: !isFormValid,
                isLoading: saveDataStatus.type === 'REQUEST_START',
                submitText: step === 1 ? next : submit,
                setButtonBringToFront
            }}
            heading={representativeModalTitle}
            name="Primary Representative Modal"
            onClose={onClose}
            onStepClick={onStepClick}
            open={isOpen}
            retryMessage={
                data.status === 'require_information'
                    ? data.retryMessage || defaultModalRetryText
                    : undefined
            }
            steps={{
                currentStep: step,
                totalSteps: 2,
            }}
        >
            <PrimaryRepresentativePage
                ref={pageRef}
                data={data}
                onValidation={setValidation}
                step={step}
                user={user}
            />
        </FullWidthModalOnboarding>
    );
};

export default React.memo(PrimaryRepresentativeModal);
