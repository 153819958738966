import React, { useCallback, useState } from 'react';
import { GivelifyTextFieldState, GivelifyTextField } from '../textField';
import { isValidCity } from '../utils/addressUtils';

export interface CityFieldProps {
    name?: string;
    city: string | undefined;
    onChange?: (city: string) => unknown;
    className?: string;
    placeholder: string;
    showState?: boolean;
    cityErrorMessage?: string;
}

export const CityField: React.FC<CityFieldProps> = ({
    name,
    city,
    className,
    placeholder,
    onChange,
    showState = false,
    cityErrorMessage,
}) => {
    const [newCity, setCity] = useState<string>(city || '');
    const [inputState, setInputState] =
        React.useState<GivelifyTextFieldState>('idle');
    const handleChange = useCallback<
        React.ChangeEventHandler<HTMLInputElement>
    >(
        (e) => {
            const value = e.target.value;
            setCity(value);
            onChange && onChange(value);
            setInputState(isValidCity(value) ? 'idle' : 'error');
        },
        [setCity, onChange, setInputState],
    );
    React.useEffect(() => {
        if (showState) {
            setInputState(isValidCity(newCity) ? 'idle' : 'error');
        }
        //eslint-disable-next-line
    }, [showState]);
    return (
        <GivelifyTextField
            fullWidth
            aria-label={placeholder}
            className={className}
            id="city"
            label={placeholder}
            leftHelperText={
                inputState === 'error' ? cityErrorMessage : undefined
            }
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            state={inputState}
            value={newCity}
        />
    );
};
