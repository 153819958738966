import React from 'react';
import { User } from '@givelify/api';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    DirectDepositInfo,
    OrganizationType,
    PrimaryRepresentativeInfo,
    TaxIdInfo,
} from '../@types/assets/onboarding';
import { GetDenominationsResponse } from '../api/responses/denomination/GetDenominationsResponse';
import { getDenominationsRequest } from '../api/services/donee/DoneeService';
import { I18N_NAMESPACE } from '../consts';
import { DirectDepositCard } from './components/DirectDepositCard';
import { PrimaryRepresentativeCard } from './components/PrimaryRepresentativeCard';
import { TaxIdCard } from './components/TaxIdCard';
import { DirectDepositPage } from './DirectDepositPage';
import PrimaryRepresentativeModal from './primaryRepresentative/PrimaryRepresentativeModal';
import TaxIdModal from './TaxIdModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rcView: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .middle': {
                marginLeft: 16,
                marginRight: 16,
            },
            [theme.breakpoints.down('sm')]: {
                '&': {
                    flexDirection: 'column',
                },
                '& .middle': {
                    marginTop: 16,
                    marginBottom: 16,
                },
            },
        },
        rcTitle: {
            textAlign: 'center',
            marginBottom: 40,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 32,
            },
        },
    }),
);

export interface RequiredInfoPageProps {
    doneeId: number;
    organizationName: string;
    organizationType: OrganizationType;
    accountOwnerFullName: string;
    user: User;
    showYodlee: boolean;

    directDeposit: DirectDepositInfo;
    onSubmitDirectDeposit: (data: DirectDepositInfo) => unknown;

    taxId: TaxIdInfo;
    onSubmitTaxId: (data: TaxIdInfo) => unknown;

    primaryRepresentative: PrimaryRepresentativeInfo;
    onSubmitPrimaryRepresentative: (data: PrimaryRepresentativeInfo) => unknown;

    openPage?: 'banking' | 'taxId' | 'rep';

    setButtonBringToFront: (value: boolean) => void;
}

export const RequiredInfoPage: React.FCC<RequiredInfoPageProps> = ({
    doneeId,
    organizationName,
    organizationType,
    accountOwnerFullName,
    directDeposit,
    taxId,
    primaryRepresentative,
    onSubmitDirectDeposit,
    onSubmitTaxId,
    onSubmitPrimaryRepresentative,
    user,
    openPage,
    showYodlee,
    setButtonBringToFront,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { description } = React.useMemo(
        () => ({
            description: t('requiredInfo.description'),
        }),
        [t],
    );
    const { rcView, rcTitle } = useStyles();
    const [openDirectDeposit, setOpenDirectDeposit] = React.useState(false);
    const [openTaxId, setOpenTaxId] = React.useState(false);
    const [getDenominationsRequestState, makeGetDenominationsRequest] =
        useApiRequest<GetDenominationsResponse>();
    const [openPrimaryRepresentative, setOpenPrimaryRepresentative] =
        React.useState(false);
    const onDirectDepositClick = React.useCallback(() => {
        setOpenDirectDeposit(true);
    }, []);
    const onDirectDepositClose = React.useCallback(() => {
        setOpenDirectDeposit(false);
    }, []);
    const onTaxIdClick = React.useCallback(() => {
        setOpenTaxId(true);
    }, []);
    const onTaxIdClose = React.useCallback(() => {
        setOpenTaxId(false);
    }, []);
    const onPrimaryRepresentativeClick = React.useCallback(() => {
        setOpenPrimaryRepresentative(true);
    }, []);
    const onPrimaryRepresentativeClose = React.useCallback(() => {
        setOpenPrimaryRepresentative(false);
    }, []);

    React.useEffect(() => {
        makeGetDenominationsRequest(getDenominationsRequest());
    }, [makeGetDenominationsRequest]);

    React.useEffect(() => {
        switch (openPage) {
            case 'banking': {
                setOpenDirectDeposit(true);
                break;
            }
            case 'rep': {
                setOpenPrimaryRepresentative(true);
                break;
            }
            case 'taxId': {
                setOpenTaxId(true);
                break;
            }
            default:
                break;
        }
    }, [openPage]);

    return (
        <>
            <div className={rcTitle} id="rc-page-title">
                <GivelifyLabel
                    id="rc-page-title"
                    text={description}
                    variant="body1"
                />
            </div>
            <div className={rcView} id="rc-content">
                <DirectDepositCard
                    onClick={onDirectDepositClick}
                    state={directDeposit.status}
                />
                <TaxIdCard
                    className="middle"
                    onClick={onTaxIdClick}
                    state={taxId.status}
                />
                <PrimaryRepresentativeCard
                    onClick={onPrimaryRepresentativeClick}
                    state={primaryRepresentative.status}
                />
            </div>

            <DirectDepositPage
                data={directDeposit}
                doneeId={doneeId}
                isOpen={openDirectDeposit}
                onCancel={onDirectDepositClose}
                onSubmit={onSubmitDirectDeposit}
                setButtonBringToFront={setButtonBringToFront}
                showYodlee={showYodlee}
            />
            <TaxIdModal
                accountOwnerFullName={accountOwnerFullName}
                data={taxId}
                doneeId={doneeId}
                getDenominationsRequestState={getDenominationsRequestState}
                isOpen={openTaxId}
                onCancel={onTaxIdClose}
                onSubmit={onSubmitTaxId}
                organizationName={organizationName}
                organizationType={organizationType}
                setButtonBringToFront={setButtonBringToFront}
            />
            <PrimaryRepresentativeModal
                data={primaryRepresentative}
                doneeId={doneeId}
                isOpen={openPrimaryRepresentative}
                onCancel={onPrimaryRepresentativeClose}
                onSubmit={onSubmitPrimaryRepresentative}
                setButtonBringToFront={setButtonBringToFront}
                user={user}
            />
        </>
    );
};
