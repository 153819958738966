import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { AccountBoxProps } from './types';

type AccountInfoProps = AccountBoxProps & {
    className?: string;
    disabled?: boolean;
};

export const AccountInfo: React.FCC<AccountInfoProps> = (props) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            routingNumber: t(
                'pages.settings.bank-info.customize-account-tab.routing-number',
            ),
            accountNumber: t(
                'pages.settings.bank-info.customize-account-tab.account-number',
            ),
            bankingAddress: t(
                'pages.settings.bank-info.customize-account-tab.banking-address',
            ),
        }),
        [t],
    );
    const { disabled, className, bankingInfo } = props;
    const color = disabled ? 'neutralGrey75' : undefined;
    const addressDisplayString = React.useMemo(() => {
        if (
            bankingInfo.bankingAddress.city &&
            bankingInfo.bankingAddress.city !== 'null' &&
            bankingInfo.bankingAddress.state &&
            bankingInfo.bankingAddress.state !== 'null' &&
            bankingInfo.bankingAddress.zip &&
            bankingInfo.bankingAddress.zip !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.city}, ${bankingInfo.bankingAddress.state} ${bankingInfo.bankingAddress.zip}`;
        }
        if (
            bankingInfo.bankingAddress.city &&
            bankingInfo.bankingAddress.city !== 'null' &&
            bankingInfo.bankingAddress.state &&
            bankingInfo.bankingAddress.state !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.city}, ${bankingInfo.bankingAddress.state}`;
        }
        if (
            bankingInfo.bankingAddress.city &&
            bankingInfo.bankingAddress.city !== 'null' &&
            bankingInfo.bankingAddress.zip &&
            bankingInfo.bankingAddress.zip !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.city}, ${bankingInfo.bankingAddress.zip}`;
        }
        if (
            bankingInfo.bankingAddress.state &&
            bankingInfo.bankingAddress.state !== 'null' &&
            bankingInfo.bankingAddress.zip &&
            bankingInfo.bankingAddress.zip !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.state} ${bankingInfo.bankingAddress.zip}`;
        }
        if (
            bankingInfo.bankingAddress.city &&
            bankingInfo.bankingAddress.city !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.city}`;
        }
        if (
            bankingInfo.bankingAddress.state &&
            bankingInfo.bankingAddress.state !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.state}`;
        }
        if (
            bankingInfo.bankingAddress.zip &&
            bankingInfo.bankingAddress.zip !== 'null'
        ) {
            return `${bankingInfo.bankingAddress.zip}`;
        }
        return '';
    }, [bankingInfo]);
    return (
        <div className={className}>
            <GivelifyLabel
                color={color}
                text={copy.routingNumber}
                variant="body1B"
            />
            <GivelifyLabel
                color={color}
                id="bank-routing-number"
                marginBottom={2}
                text={bankingInfo.routingNumber}
                variant="body1"
            />
            <GivelifyLabel
                color={color}
                text={copy.accountNumber}
                variant="body1B"
            />
            <GivelifyLabel
                color={color}
                id="bank-account-number"
                marginBottom={2}
                text={bankingInfo.accountNumber}
                variant="body1"
            />
            {addressDisplayString ||
            (bankingInfo.bankingAddress.street &&
                bankingInfo.bankingAddress.street !== 'null') ? (
                <GivelifyLabel
                    color={color}
                    text={copy.bankingAddress}
                    variant="body1B"
                />
            ) : null}
            {bankingInfo.bankingAddress.street &&
            bankingInfo.bankingAddress.street !== 'null' ? (
                <GivelifyLabel
                    color={color}
                    id="bank-street"
                    text={bankingInfo.bankingAddress.street}
                    variant="body1"
                />
            ) : null}
            <GivelifyLabel
                color={color}
                id="bank-address"
                text={addressDisplayString}
                variant="body1"
            />
        </div>
    );
};
