import {
    DesignTokens,
    GivelifyIcon,
    GivelifyIconVariant,
    GivelifyLabel,
} from '@givelify/ui';
import { styled } from '@mui/material';
import { useCaptivePortalContext } from '../../CaptivePortalProvider';

interface OnboardingModalHeaderProps {
    iconVariant: GivelifyIconVariant;
    title: string;
    lockText: string;
}
const BORDER_COLOR = '#CAD2DB';

const OnboardingHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.up('mobile')]: {
        height: 80,
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '10px 16px',
    },
    borderBottom: `1px solid ${BORDER_COLOR}`,
    '& .heading': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('tablet')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.down('mobile')]: {
            fontSize: '16px',
            fontWeight: 800,
            marginLeft: '12px',
        },
    },
    '& .lock-icon': {
        marginLeft: theme.spacing(4),
        color: DesignTokens.color.neutralGrey,
        fontSize: '18px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '16px',
            marginLeft: theme.spacing(3),
        },
    },
    '& .lock-text': {
        marginLeft: theme.spacing(0.5),
        color: DesignTokens.color.neutralGrey,
        fontSize: '16px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '12px',
            width: '110px',
        },
    },
}));

const MainIcon = styled(GivelifyIcon)(({ theme }) => ({
    fontSize: '28px',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '24px',
        alignSelf: 'flex-start',
    },
}));

export const OnboardingModalHeader: React.FCC<OnboardingModalHeaderProps> = (
    props,
) => {
    const { headerDropdowns } = useCaptivePortalContext();

    return (
        <OnboardingHeader>
            <MainIcon variant={props.iconVariant} />
            <GivelifyLabel
                className="heading"
                id="onboarding-modal-title"
                text={props.title}
                variant="heading1S"
            />

            <GivelifyIcon className="lock-icon" variant="lock" />
            <GivelifyLabel
                className="lock-text"
                id="onboarding-modal-lock-text"
                text={props.lockText}
                variant="body2"
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'end',
                }}
            >
                {headerDropdowns}
            </div>
        </OnboardingHeader>
    );
};
