import React from 'react';
import {
    DATEPICKER_DATE_PRESET_ITEM_CLICK,
    TimeFrameId,
    TimeFrameValues,
    TimezoneManager,
    TrackingProvider,
    useTrackingContext,
} from '@givelify/utils';
import {
    Modal,
    InputAdornment,
    Popover,
    PopoverOrigin,
    styled,
    TextFieldProps,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { GivelifyButton } from '../button';
import { GivelifyIcon } from '../icon';
import { DesignTokens } from '../specify';
import { GivelifyTextField } from '../textField';
import { DateInput, GivelifyCalendar } from './GivelifyCalendar';
import { GivelifyPickerLayout } from './GivelifyPickerLayout';
import {
    GivelifyDatePickerProps,
    GivelifyDatePickerTextProps,
    PickerMode,
} from './types';

const DatePickerRoot = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'themeMode' &&
        propName !== 'pickerMode' &&
        propName !== 'hideTextInput',
})<{ pickerMode: PickerMode; hideTextInput: boolean }>(
    ({ pickerMode, hideTextInput }) => ({
        width: 'initial',
        minWidth: hideTextInput
            ? 'initial'
            : pickerMode === 'range'
            ? '271px'
            : 'initial',
    }),
);

const DateFieldStyled = styled(DateField)({
    '& .MuiInputBase-root': {
        paddingRight: '4px',
    },
});

const RangeFieldStyled = styled(GivelifyTextField)({
    '& .MuiInputBase-root': {
        paddingRight: '4px',
    },
    '& .date-picker-input-adornment': {
        marginLeft: '2px',
    },
});

const PopoverRoot = styled(Popover)(({ theme }) => ({
    '& .MuiPopover-paper': {
        marginTop: theme.spacing(0.5),
        boxShadow: DesignTokens.shadow.shadowHigh,
        borderRadius: DesignTokens.measurement.borderRadiusS,
        background: 'initial',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
}));

const ModalRoot = styled(Modal)(() => ({
    '& .MuiModal-backdrop': {
        background: DesignTokens.color.overlayModal,
    },
}));

const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

const defaulTexts: GivelifyDatePickerTextProps = {
    cancel: 'Cancel',
    submit: 'Save',
    rangeFieldLabel: 'Select a date',
};

export const GivelifyDatePickerComponent: React.FCC<
    GivelifyDatePickerProps
> = ({
    dataTestId,
    id,
    label,
    format = 'MM/DD/YYYY',
    pickerMode = 'date',
    themeMode = 'light',
    value,
    start,
    end,
    range = 'custom',
    onDateChange,
    onRangeChange,
    className,
    popoverProps = {
        anchorOrigin,
        transformOrigin,
    },
    formatRangeInputValue,
    fullwidth,
    state,
    name,
    ariaLabel,
    size = 'large',
    width,
    clearable,
    helperText,
    placeholder = format,
    rangePickerShortcuts,
    texts = defaulTexts,
    disableFuture = false,
    minDate,
    maxDate = disableFuture ? dayjs().tz() : undefined,
    disabled,
    onClose,
    calendarAnchorRef,
    open: openProp,
    hideTextInput = false,
    onOpenTrackingName,
    ...props
}) => {
    const { trackEvent } = useTrackingContext();
    const [cache, setCache] = React.useState({
        start: start,
        end: end,
        range: range,
    });
    const [startInner, setStartInner] = React.useState(start);
    const [endInner, setEndInner] = React.useState(end);
    const [rangeInner, setRangeInner] = React.useState<TimeFrameId>('custom');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const anchorEl = React.useRef<HTMLInputElement>(null);
    const [open, setOpen] = React.useState(openProp || false);
    const [step, setStep] = React.useState<'start' | 'end'>('start');
    const onCalendarClick = React.useCallback(() => {
        if (!disabled) {
            if (onOpenTrackingName) {
                trackEvent(onOpenTrackingName);
            }
            setOpen(true);
        }
    }, [disabled, onOpenTrackingName, trackEvent]);
    const onRangeClearClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setCache({
                start: null,
                end: null,
                range: 'custom',
            });
            setStep('start');
            if (onRangeChange) {
                onRangeChange(null, null, 'custom');
            }
        },
        [setCache, setStep, onRangeChange],
    );
    const onDateClearClick = React.useCallback(() => {
        if (onDateChange) {
            onDateChange(null);
        }
    }, [onDateChange]);
    const onDateFieldChange = React.useCallback(
        (newValue: unknown, context: { validationError: string | null }) => {
            if (context.validationError == null) {
                const date = dayjs(newValue as string);
                if (onDateChange) {
                    onDateChange(date);
                }
            }
        },
        [onDateChange],
    );
    const onDateChangeCallback = React.useCallback(
        (date: dayjs.Dayjs | null) => {
            if (onDateChange) {
                onDateChange(date);
            }
            if (onClose) {
                onClose('submit');
            } else {
                setOpen(false);
            }
        },
        [onDateChange, setOpen, onClose],
    );
    const onRangeChangeCallback = React.useCallback(
        (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
            setStartInner(startDate);
            setEndInner(endDate);
            setRangeInner('custom');
            setStep(step === 'start' && startDate !== null ? 'end' : 'start');
        },
        [step, setStep, setRangeInner],
    );
    const onShortcutClick = React.useCallback(
        (shortcut: TimeFrameId) => {
            if (onRangeChange) {
                if (shortcut === 'lifetime') {
                    setStartInner(minDate);
                    setEndInner(dayjs().tz());
                } else {
                    const newTimeFrame = TimeFrameValues[shortcut];
                    setStartInner(newTimeFrame.start);
                    setEndInner(newTimeFrame.end);
                }
                trackEvent(DATEPICKER_DATE_PRESET_ITEM_CLICK, {
                    'date preset': shortcut,
                });
                setRangeInner(shortcut);
                setStep('start');
            }
        },
        [onRangeChange, trackEvent, minDate],
    );
    const onSubmit = React.useCallback(() => {
        if (onClose) {
            onClose('submit');
        } else {
            setOpen(false);
        }
        setCache({
            start: startInner,
            end: endInner,
            range: rangeInner,
        });
        setStep('start');
        if (
            onRangeChange &&
            startInner !== undefined &&
            endInner !== undefined
        ) {
            onRangeChange(startInner, endInner, rangeInner);
        }
    }, [
        startInner,
        endInner,
        rangeInner,
        onRangeChange,
        setOpen,
        setStep,
        onClose,
    ]);
    const onCancelOrDismiss = React.useCallback(
        (reason: 'cancel' | 'dismiss') => {
            if (onClose) {
                onClose(reason);
            } else {
                setOpen(false);
            }
            setStartInner(cache.start);
            setEndInner(cache.end);
            setRangeInner(cache.range);
            setStep('start');
            if (onRangeChange) {
                onRangeChange(
                    cache.start !== undefined ? cache.start : null,
                    cache.end !== undefined ? cache.end : null,
                    cache.range,
                );
            }
        },
        [setOpen, onRangeChange, cache, setStep, onClose],
    );
    const onCancel = React.useCallback(() => {
        onCancelOrDismiss('cancel');
    }, [onCancelOrDismiss]);
    const onDismiss = React.useCallback(() => {
        onCancelOrDismiss('dismiss');
    }, [onCancelOrDismiss]);

    React.useEffect(() => {
        let tempStart = start;
        let tempEnd = end;
        if (tempStart === null && tempEnd !== null) {
            tempStart = tempEnd;
        } else if (tempStart !== null && tempEnd === null) {
            tempEnd = tempStart;
        }
        setStartInner(tempStart);
        setEndInner(tempEnd);
        const newRange =
            tempStart === null || tempEnd === null ? 'custom' : range;
        setRangeInner(newRange);
        setCache({
            start: tempStart,
            end: tempEnd,
            range: newRange,
        });
    }, [start, end, range]);
    React.useEffect(() => {
        setOpen(openProp || false);
    }, [openProp, setOpen]);
    const Picker = React.useMemo(() => {
        return React.forwardRef(() =>
            pickerMode === 'range' ? (
                <GivelifyPickerLayout
                    canSave={startInner !== null && endInner !== null}
                    className="date-picker-layout"
                    currentRange={rangeInner}
                    data-testid="datepicker"
                    onCancel={onCancel}
                    onShortcutClick={onShortcutClick}
                    onSubmit={onSubmit}
                    pickerMode={pickerMode}
                    rangePickerShortcuts={rangePickerShortcuts}
                    texts={texts}
                    themeMode={themeMode}
                >
                    <GivelifyCalendar
                        {...props}
                        className="date-picker-calendar"
                        disableFuture={disableFuture}
                        end={endInner !== undefined ? endInner : null}
                        format={format}
                        labelForSelectDate={texts.rangeFieldLabel}
                        maxDate={maxDate}
                        minDate={minDate}
                        onRangeChange={onRangeChangeCallback}
                        pickerMode="range"
                        start={startInner !== undefined ? startInner : null}
                        step={step}
                        themeMode={themeMode}
                    />
                </GivelifyPickerLayout>
            ) : (
                <GivelifyPickerLayout
                    canSave={startInner !== null && endInner !== null}
                    className="date-picker-layout"
                    currentRange={rangeInner}
                    onShortcutClick={onShortcutClick}
                    pickerMode={pickerMode}
                    texts={texts}
                    themeMode={themeMode}
                >
                    <GivelifyCalendar
                        {...props}
                        className="date-picker-calendar"
                        disableFuture={disableFuture}
                        format={format}
                        labelForSelectDate={texts.rangeFieldLabel}
                        maxDate={maxDate}
                        minDate={minDate}
                        onDateChange={onDateChangeCallback}
                        pickerMode="date"
                        themeMode={themeMode}
                        value={value !== undefined ? value : null}
                    />
                </GivelifyPickerLayout>
            ),
        );
    }, [
        pickerMode,
        themeMode,
        rangeInner,
        onShortcutClick,
        startInner,
        endInner,
        onSubmit,
        onCancel,
        rangePickerShortcuts,
        texts,
        props,
        minDate,
        maxDate,
        onRangeChangeCallback,
        format,
        step,
        disableFuture,
        value,
        onDateChangeCallback,
    ]);
    const formatRangeInputValueDefault = React.useMemo(() => {
        return formatRangeInputValue !== undefined
            ? formatRangeInputValue(startInner, endInner)
            : startInner || endInner
            ? `${startInner ? startInner?.format(format) : format} to ${
                  endInner ? endInner?.format(format) : format
              }`
            : '';
    }, [startInner, endInner, format, formatRangeInputValue]);
    return (
        <DatePickerRoot
            ref={anchorEl}
            className={className}
            hideTextInput={hideTextInput}
            pickerMode={pickerMode}
        >
            {hideTextInput ? null : pickerMode === 'range' ? (
                <RangeFieldStyled
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        inputProps: {
                            'data-testid': dataTestId ?? id,
                        },
                        endAdornment: (
                            <InputAdornment
                                className="date-picker-input-adornment"
                                position="end"
                            >
                                {startInner || endInner ? (
                                    <GivelifyButton
                                        aria-label="Clear date"
                                        className="date-picker-input-adornment-button"
                                        data-testid="datepicker-clear-date"
                                        disabled={disabled}
                                        name="Clear date"
                                        onClick={onRangeClearClick}
                                        size="small"
                                        variant="icon"
                                    >
                                        <GivelifyIcon
                                            className="date-picker-input-adornment-icon"
                                            color={
                                                DesignTokens.color.iconPrimary
                                            }
                                            fontSize={20}
                                            variant="close"
                                        />
                                    </GivelifyButton>
                                ) : null}
                                <GivelifyButton
                                    aria-label="Choose date"
                                    className="date-picker-input-adornment-button"
                                    disabled={disabled}
                                    onClick={onCalendarClick}
                                    size="small"
                                    variant="icon"
                                >
                                    <GivelifyIcon
                                        className="date-picker-input-adornment-icon"
                                        color={
                                            themeMode === 'light'
                                                ? DesignTokens.color.textPrimary
                                                : DesignTokens.color.textWhite
                                        }
                                        variant="calendar"
                                    />
                                </GivelifyButton>
                            </InputAdornment>
                        ),
                    }}
                    className="date-picker-input-range"
                    darkMode={themeMode === 'dark'}
                    disabled={disabled}
                    id={id}
                    label={texts.rangeFieldLabel}
                    onClick={onCalendarClick}
                    onFocus={(event) => event.currentTarget.blur()}
                    value={formatRangeInputValueDefault}
                />
            ) : (
                <DateFieldStyled
                    className="date-picker-input-single"
                    disabled={disabled}
                    format={format}
                    fullWidth={fullwidth}
                    id={id}
                    label={label}
                    onChange={onDateFieldChange}
                    slots={{
                        textField: (props: TextFieldProps) => (
                            <DateInput
                                {...props}
                                FormHelperTextProps={{
                                    id: `${id}-leftHelperText`,
                                }}
                                ariaLabel={ariaLabel}
                                className="date-picker-input-single-root"
                                clearable={clearable}
                                data-testid={id}
                                leftHelperText={helperText}
                                name={name}
                                onCalendarClick={onCalendarClick}
                                onClearClick={onDateClearClick}
                                placeholder={placeholder}
                                size={size}
                                state={state}
                                themeMode={themeMode}
                                width={width}
                            />
                        ),
                    }}
                    timezone={TimezoneManager.getDefault()}
                    value={value}
                />
            )}
            {isMobile ? (
                <ModalRoot
                    className="dialog-root-modal"
                    id={`date-picker-popover-${id}`}
                    onClose={onDismiss}
                    open={open}
                >
                    <Picker />
                </ModalRoot>
            ) : (
                <PopoverRoot
                    anchorEl={
                        calendarAnchorRef && calendarAnchorRef.current
                            ? calendarAnchorRef.current
                            : anchorEl.current
                    }
                    anchorOrigin={popoverProps.anchorOrigin}
                    className="dialog-root-popover"
                    id={`date-picker-popover-${id}`}
                    onClose={onDismiss}
                    open={
                        (anchorEl.current !== null ||
                            (calendarAnchorRef !== undefined &&
                                calendarAnchorRef.current !== null)) &&
                        open
                    }
                    transformOrigin={popoverProps.transformOrigin}
                >
                    <Picker />
                </PopoverRoot>
            )}
        </DatePickerRoot>
    );
};

export const GivelifyDatePicker: React.FCC<GivelifyDatePickerProps> = (
    props,
) => {
    return (
        <TrackingProvider
            pageName={props.name}
            trackPageVisit={false}
            viewType="modal"
        >
            <GivelifyDatePickerComponent {...props} />
        </TrackingProvider>
    );
};
