import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

type SimpleRowBoxProps = {
    showSeparator?: boolean;
};

const SimpleRowBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'showSeparator',
})<SimpleRowBoxProps>((props) => ({
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    borderBottomWidth: '1px',
    borderBottomColor: '#F3F3F7',
    borderBottomStyle: props.showSeparator ? 'solid' : 'none',
    boxSizing: 'content-box',
}));

export type WidgetContentSimpleRowProps = {
    id: string;
    label: string;
    value: string;
    showSeparator?: boolean;
    className?: string;
};

export const WidgetContentSimpleRow: React.FCC<WidgetContentSimpleRowProps> = ({
    id,
    label,
    value,
    showSeparator,
    className,
}) => {
    return (
        <SimpleRowBox
            className={className}
            data-testid={id}
            id={id}
            showSeparator={showSeparator}
        >
            <GivelifyLabel
                className="wr-label"
                data-testid={`${id}-label`}
                id={`${id}-label`}
                marginRight="auto"
                text={label}
            />
            <GivelifyLabel
                className="wr-value"
                data-testid={`${id}-value`}
                id={`${id}-value`}
                marginLeft="auto"
                text={value}
            />
        </SimpleRowBox>
    );
};
