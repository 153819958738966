import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import {
    GivelifyBoxMobileStyles,
    GivelifyBoxStyleProps,
    GivelifyBoxStyles,
} from './styles';

export interface GivelifyBoxProps extends GivelifyBoxStyleProps {
    smallScreenStyle?: GivelifyBoxStyleProps;
    className?: string;
    divProps?: React.HTMLAttributes<HTMLDivElement>;
}

function getSmallScreenStyles(smallScreenStyle?: GivelifyBoxStyleProps) {
    if (smallScreenStyle) {
        const { boxMarginSm, containerSm } =
            GivelifyBoxMobileStyles(smallScreenStyle);
        return { boxMarginSm, containerSm };
    }
    return { boxMarginSm: '', containerSm: '' };
}

export const GivelifyBox: React.FCC<GivelifyBoxProps> = (props) => {
    const { className, children, smallScreenStyle, divProps, ...styleProps } =
        props;
    const { boxMarginSm, containerSm } = getSmallScreenStyles(smallScreenStyle);
    const { boxMargin, container } = GivelifyBoxStyles(styleProps);
    const boxClassName = mergeClassNames(
        boxMargin,
        container,
        boxMarginSm,
        containerSm,
        className,
    );
    return (
        <div {...divProps} className={boxClassName}>
            {children}
        </div>
    );
};
