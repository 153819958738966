import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyButton } from '../button';
import { GivelifyLabel } from '../label';
import { GivelifyIcon } from '../components/GivelifyIcon';

const BORDER_COLOR = '#CAD2DB';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerStyles: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${BORDER_COLOR}`,
            '& .button-container': {
                width: 105,
                height: 72,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                justifyContent: 'center',
                borderRight: `1px solid ${BORDER_COLOR}`,
                [theme.breakpoints.down('sm')]: {
                    height: 55,
                    width: 56,
                },
            },
            '& .lock-icon': {
                width: 16,
                height: 21,
                marginLeft: theme.spacing(4),
                [theme.breakpoints.down('sm')]: {
                    height: 14,
                    width: 11,
                },
            },
            '& .heading': {
                fontWeight: 400,
                marginLeft: theme.spacing(4),
                [theme.breakpoints.down('sm')]: {
                    marginLeft: theme.spacing(3),
                },
            },
            '& .lock-text': {
                marginLeft: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    fontSize: 12,
                },
            },
        },
        closeStyles: {
            width: 40,
            height: 40,
            minWidth: 0,
            minHeight: 0,
            padding: 8,
            fontSize: 24,
            lineHeight: '0px',
            color: theme.colors
                ? theme.colors.neutralGrey
                : theme.palette.grey[400],
            borderRadius: '50%',
            '&:hover': {
                backgroundColor: theme.colors
                    ? theme.colors.neutralHoverGrey
                    : theme.palette.action.hover,
            },
            [theme.breakpoints.down('sm')]: {
                padding: 16,
                width: 56,
                height: 56,
            },
        },
        closeIconStyles: {
            width: 12,
            height: 20,
        },
    }),
);

interface ModalHeaderProps {
    heading: string;
    useBackArrow?: boolean;
    onClose: () => unknown;
    lockText?: string;
}

const ModalHeader: React.FCC<ModalHeaderProps> = ({
    heading,
    useBackArrow,
    onClose,
    lockText,
}) => {
    const { headerStyles } = useStyles();
    return (
        <div className={headerStyles}>
            <div className="button-container">
                <GivelifyButton
                    name={useBackArrow ? 'back' : 'close'}
                    variant="icon"
                    iconVariant={useBackArrow ? 'back' : 'bigClose'}
                    onClick={onClose}
                />
            </div>
            <GivelifyLabel
                id="onboarding-modal-title"
                variant="heading2"
                text={heading}
                className="heading"
            />
            {lockText && (
                <>
                    <GivelifyIcon
                        variant="lock"
                        className="lock-icon"
                        color="neutralGrey"
                    />
                    <GivelifyLabel
                        id="onboarding-modal-lock-text"
                        variant="body2"
                        text={lockText}
                        className="lock-text"
                    />
                </>
            )}
        </div>
    );
};

export default React.memo(ModalHeader);
