import React from 'react';
import {
    SearchIcon,
    ArrowRightSearchIcon,
    ClearIcon,
    DesignTokens,
} from '@givelify/ui';
import { INPUT_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import {
    SearchIconWrapper,
    SearchInput,
    SearchWrapper,
    SearchButton,
    ClearButton,
} from './styles';

export type SearchByNameInputProps = {
    onSearchSubmit: (search: string) => void;
    search: string | null;
    disabled: boolean;
};

const SearchByNameInputComponent: React.FCC<SearchByNameInputProps> = ({
    onSearchSubmit,
    search,
    disabled,
}) => {
    const { t } = useTranslation();
    const { trackEvent } = useTrackingContext();
    const [value, setValue] = React.useState(search ?? '');
    const [isEngaged, setIsEngaged] = React.useState(false);

    const copy = React.useMemo(
        () => ({
            searchByName: t('labels.searchByName'),
        }),
        [t],
    );

    const onSubmitHandler = React.useCallback(() => {
        onSearchSubmit(value);
        setIsEngaged(true);
    }, [onSearchSubmit, value]);

    const onClearClickHandler = React.useCallback(() => {
        setValue('');
        setIsEngaged(false);
        onSearchSubmit('');
    }, [onSearchSubmit]);

    const onEnterPressHandler = React.useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                onSubmitHandler();
            }
        },
        [onSubmitHandler],
    );

    React.useEffect(() => {
        setValue(search ?? '');
    }, [search]);

    React.useEffect(() => {
        if (search !== value || search === '') {
            setIsEngaged(false);
        } else {
            setIsEngaged(true);
        }
        // listen to value changes only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <SearchWrapper data-testid="search-wrapper" width={value.length}>
            <SearchInput
                fullWidth
                InputProps={{
                    onClick: () => trackEvent(`<Search>_${INPUT_IDENTIFIER}`),
                    sx: {
                        borderRadius: '100px',
                        padding: '0 0 0 20px',
                    },
                    startAdornment: (
                        <SearchIconWrapper
                            id="inputs-search-input-icon"
                            isEngaged={isEngaged}
                        >
                            <SearchIcon
                                sx={{
                                    color: disabled
                                        ? DesignTokens.color.textDisabled
                                        : '',
                                }}
                            />
                        </SearchIconWrapper>
                    ),
                    endAdornment:
                        (search ?? '').length > 0 || value.length > 0 ? (
                            <div>
                                {isEngaged ? (
                                    <ClearButton
                                        aria-label="Clear icon"
                                        data-testid="search-clear-btn"
                                        onClick={onClearClickHandler}
                                        variant="icon"
                                    >
                                        <ClearIcon />
                                    </ClearButton>
                                ) : (
                                    <SearchButton
                                        aria-label="Search icon"
                                        data-testid="search-submit-btn"
                                        onClick={onSubmitHandler}
                                        variant="icon"
                                    >
                                        <ArrowRightSearchIcon />
                                    </SearchButton>
                                )}
                            </div>
                        ) : null,
                }}
                disabled={disabled}
                inputProps={{
                    'data-testid': 'search-input',
                }}
                isEngaged={isEngaged}
                maxLength={55}
                onChange={(event) => setValue(event.target.value)}
                onFocus={(e) => e.target.select()}
                onKeyDown={onEnterPressHandler}
                placeholder={copy.searchByName}
                value={value}
            />
        </SearchWrapper>
    );
};

export const SearchByNameInput = React.memo(SearchByNameInputComponent);
