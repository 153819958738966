import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { useF1Styles } from './style';

interface F1IntegrationSetupStepProps {
    icon: string;
    title: string;
    content: string;
    hasStepLink: boolean;
}

const F1IntegrationSetupStep: React.FCC<F1IntegrationSetupStepProps> = ({
    icon,
    title,
    content,
    hasStepLink,
}) => {
    const classes = useF1Styles();
    return (
        <Grid item className={classes.f1Step} md={4} xs={12}>
            {hasStepLink && <div className={classes.stepLink} />}
            <div className={classes.f1StepImage}>
                <img alt="F1 Setup Step" src={icon} title="F1 Setup Step" />
            </div>
            <GivelifyLabel
                className={classes.f1StepTitle}
                text={title}
                variant="heading5"
            />
            <GivelifyLabel text={content} variant="body1" />
        </Grid>
    );
};

export default F1IntegrationSetupStep;
