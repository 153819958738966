import React from 'react';
import { GivelifyButton, GivelifySnackbar } from '@givelify/givelify-ui';
import { copyToClipboard } from '@givelify/utils';
import {  SnackbarCloseReason } from '@material-ui/core';
import { Hidden } from '@mui/material';
import clevertap from 'clevertap-web-sdk';

import { SocialMediaName } from '../../types';
import { SNACKBAR_TIMEOUT } from '../../types/const';
import {
    FB_GIVING_COPY_LINK,
    IG_GIVING_COPY_LINK,
    TW_GIVING_COPY_LINK,
    YT_GIVING_COPY_LINK,
} from '../../utils/cleverTapEvents';
import { useTranslations } from '../../utils/translations';
import Label from '../Label';
import { useStyle } from './style';

type ButtonVariant = 'primary' | 'secondary';
interface Props {
    media?: SocialMediaName;
    buttonVariant?: ButtonVariant;
    givingLink: string;
}

interface CopyLinkBoxProps {
    event?: string;
    link?: string;
    isLoading?: boolean;
    buttonVariant?: ButtonVariant;
}

const CLEVERTAP_EVENTS: { [x in SocialMediaName]: string } = {
    facebook: FB_GIVING_COPY_LINK,
    twitter: TW_GIVING_COPY_LINK,
    instagram: IG_GIVING_COPY_LINK,
    youtube: YT_GIVING_COPY_LINK,
};

export const CopyLinkBox: React.FCC<CopyLinkBoxProps> = ({
    event,
    link,
    isLoading,
    buttonVariant = 'secondary',
}: CopyLinkBoxProps) => {
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const cs = useStyle();

    const { copyOrgLink } = useTranslations('socialMediaGiving.facebook', {
        copyOrgLink: 'copyLink',
    }) as { copyOrgLink: string };

    const handleCopyLinkClick = () => {
        if (event) {
            clevertap?.event.push(event);
        }

        if (link) {
            copyToClipboard(link).then((isSuccess) => {
                if (isSuccess) setIsSnackbarOpen(true);
            });
        }
    };

    const handleCloseSnackbar = (
        _: React.SyntheticEvent<unknown>,
        reason: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') return;

        setIsSnackbarOpen(false);
    };

    return (
        <>
            <GivelifySnackbar
                autoHideDuration={SNACKBAR_TIMEOUT}
                message="Link copied."
                onClose={handleCloseSnackbar}
                open={isSnackbarOpen}
            />
            <Hidden only={['xs']}>
                <div className={cs.linkContainer}>
                    {isLoading ? (
                        <div
                            className={cs.animating}
                            data-testid="online-giving-link-loading"
                            style={{ height: 32 }}
                        ></div>
                    ) : (
                        <Label
                            className={cs.link}
                            data-testid="online-giving-link"
                            id="online-giving-link"
                        >
                            {link}
                        </Label>
                    )}
                    <div style={{ position: 'relative' }}>
                        <GivelifyButton
                            className={cs.copyLinkButton}
                            data-testid="online-giving-link-copy-button"
                            disabled={!!isLoading}
                            loadingIndicator="ring"
                            name="copyLinkDesktop"
                            onClick={handleCopyLinkClick}
                            size="small"
                            text={copyOrgLink}
                            variant={buttonVariant}
                        />
                    </div>
                </div>
            </Hidden>
            <Hidden only={['sm', 'lg', 'md', 'xl']}>
                {isLoading ? (
                    <div
                        className={cs.animating}
                        data-testid="online-giving-link-loading"
                        style={{ height: 32 }}
                    ></div>
                ) : (
                    <Label className={cs.link} data-testid="online-giving-link">
                        {link}
                    </Label>
                )}
                <GivelifyButton
                    className={cs.copyLinkButton}
                    disabled={!!isLoading}
                    loadingIndicator="ring"
                    name="copyLinkMobile"
                    onClick={handleCopyLinkClick}
                    size="small"
                    text={copyOrgLink}
                    variant={buttonVariant}
                />
            </Hidden>
        </>
    );
};

const CopyGivingLink: React.FCC<Props> = ({
    media,
    buttonVariant,
    givingLink,
}: Props) => {
    return (
        <CopyLinkBox
            buttonVariant={buttonVariant}
            event={media ? CLEVERTAP_EVENTS[media] : undefined}
            link={givingLink}
        />
    );
};

export default React.memo(CopyGivingLink);
