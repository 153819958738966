import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { textDividerStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

export type GivelifyTextDividerProps = React.HTMLAttributes<HTMLHRElement> & {
    className?: string;
    text?: string;
} & GivelifyBoxMarginProps;

export const GivelifyTextDivider: React.FCC<GivelifyTextDividerProps> = (
    props,
) => {
    const {
        margin,
        marginLeft,
        marginRight,
        marginBottom,
        marginTop,
        className,
        ...hrProps
    } = props;
    const { boxMargin, textDivider } = textDividerStyles({
        margin: margin,
        marginLeft: marginLeft,
        marginBottom: marginBottom,
        marginRight: marginRight,
        marginTop: marginTop,
    });
    return (
        <hr
            {...hrProps}
            className={mergeClassNames(boxMargin, textDivider, className)}
            data-content={props.text}
        />
    );
};
