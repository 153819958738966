import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyModal,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { integrationStyle } from './style';

interface ThirdPartyModalProps {
    openModal: boolean;
    closeModal: () => void;
}

const ThirdPartyModal: React.FCC<ThirdPartyModalProps> = ({
    openModal,
    closeModal,
}) => {
    const classes = integrationStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.integrations.content.thirdPartyModal.title'),
            following: t(
                'pages.integrations.content.thirdPartyModal.following',
            ),
            givelifyIntegration: t(
                'pages.integrations.content.thirdPartyModal.givelifyIntegration',
            ),
            acsInfo: t('pages.integrations.content.thirdPartyModal.acsInfo'),
            shelbyInfo: t(
                'pages.integrations.content.thirdPartyModal.shelbyInfo',
            ),
        }),
        [t],
    );
    return (
        <GivelifyModal
            autoFullscreen
            showCloseButton
            name="thirdPartyModal"
            onClose={closeModal}
            open={openModal}
            width={952}
        >
            <div className={classes.thirdPartyModal}>
                <GivelifyLabel text={copy.title} variant="heading1" />
                <GivelifyTextDivider />
                <div className={classes.thirdPartyInfo}>
                    <GivelifyLabel text={copy.following} variant="body1" />
                    <GivelifyLabel
                        marginTop={16}
                        text={copy.givelifyIntegration}
                        variant="body1"
                    />
                </div>
                <div className={classes.listThirdParty}>
                    <GivelifyLabel
                        className="integration-name"
                        text="FellowshipOne™"
                        variant="heading4"
                    />
                    <GivelifyLabel
                        className="integration-name"
                        text="Church Community Builder"
                        variant="heading4"
                    />
                    <GivelifyLabel
                        className="integration-name"
                        text="ACS Technologies ®"
                        variant="heading4"
                    />
                    <GivelifyLabel
                        color="#676e77"
                        text={copy.acsInfo}
                        variant="caption2"
                    />
                    <GivelifyLabel
                        className="integration-name"
                        text="Power Church Software"
                        variant="heading4"
                    />
                    <GivelifyLabel
                        className="integration-name"
                        text="Servant Keeper ®"
                        variant="heading4"
                    />
                    <GivelifyLabel
                        className="integration-name"
                        text="Shelby System ®"
                        variant="heading4"
                    />
                    <GivelifyLabel
                        color="#676e77"
                        text={copy.shelbyInfo}
                        variant="caption2"
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default ThirdPartyModal;
