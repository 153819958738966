import React, { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';

interface ViewProfilePreviewLinkProps {
    hideFullScreenPreviewOption?: boolean;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    viewProfile: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
}));

const ViewProfilePreviewLink: React.FCC<ViewProfilePreviewLinkProps> = ({
    hideFullScreenPreviewOption,
    onClick,
}) => {
    const classes = useStyles();
    const { t } = useTranslation(I18N_NAMESPACE);
    const { viewProfilePreview } = useMemo(
        () => ({
            viewProfilePreview: t('customizeProfile.viewProfilePreview'),
        }),
        [t],
    );

    if (hideFullScreenPreviewOption) return null;

    return (
        <div className={classes.viewProfile}>
            <GivelifyButton
                onClick={onClick}
                text={viewProfilePreview}
                variant="link"
            />
        </div>
    );
};

export default ViewProfilePreviewLink;
