import React, { useMemo } from 'react';
import { DesignTokens, GivelifyLabel, GivelifyFormRadio } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { OnboardingActions } from '../../../OnboardingActions';
import OnboardingPage from '../../../OnboardingPage';
import { TaxIdInfoHelp } from '../ein/TaxIdInfoHelp';
import { UseTaxIdContext } from '../TaxIdContext';
import { TaxIdDetailsView } from './TaxIdDetailsView';

const StyledForm = styled('div')(({ theme }) => ({
    '& .page-description': {
        color: DesignTokens.color.textSecondary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '14px',
        },
    },
    '& .input-field': {
        marginTop: '24px',
    },
    '& .radio': {
        marginBottom: '64px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
}));

const NonProfitView: React.FCC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { t } = useTranslation(I18N_NAMESPACE);
    const { formState } = useFormContext();

    const copy = useMemo(
        () => ({
            header: t('taxId.nonProfit.header'),
            description: t('taxId.nonProfit.description'),
            infoAccurate: t('taxId.nonProfit.infoAccurate'),
            infoInaccurate: t('taxId.nonProfit.infoInaccurate'),
        }),
        [t],
    );

    const { onBackClick, onCancelClick } = UseTaxIdContext();

    return (
        <StyledForm>
            <GivelifyLabel
                style={{
                    marginBottom: isMobile ? 8 : 12,
                    display: 'flex',
                    alignItems: 'center',
                }}
                text={copy.header}
                variant="heading1S"
            />

            <GivelifyLabel
                style={{
                    color: DesignTokens.color.textSecondary,
                }}
                text={copy.description}
                variant="body1"
            />

            <TaxIdDetailsView />

            <div className="radio">
                <GivelifyFormRadio
                    label={copy.infoAccurate}
                    name="informationAccurate"
                    value="1"
                />
                <GivelifyFormRadio
                    label={copy.infoInaccurate}
                    name="informationAccurate"
                    value="0"
                />
            </div>
            <OnboardingActions
                disableContinue={!formState.isValid}
                onBack={onBackClick}
                onCancel={onCancelClick}
            />
        </StyledForm>
    );
};

const NonProfitFormView = () => {
    return (
        <TrackingProvider
            trackPageVisit
            pageName="EIN Federal Tax Id Org Address"
        >
            <OnboardingPage
                content={<NonProfitView />}
                informationContent={<TaxIdInfoHelp />}
            />
        </TrackingProvider>
    );
};

export default NonProfitFormView;
