import { GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { useDocumentUploadContext } from '../DocumentUploadContext';
import { useUploadDocumentTranslation } from './useUploadDocumentTranslation';

type SidebarContentProps = {
    exampleImage: string;
    showExampleModal: () => void;
};

const SidebarContent: React.FCC<SidebarContentProps> = ({
    exampleImage,
    showExampleModal,
}) => {
    const { selectedDocumentType } = useDocumentUploadContext();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { text, copy } = useUploadDocumentTranslation(selectedDocumentType!);

    return (
        <>
            <GivelifyLabel text={text?.informationHeader} variant="heading3S" />
            <img
                alt={text?.imageAlt}
                src={exampleImage}
                style={{
                    width: '100%',
                    marginTop: 16,
                    marginBottom: 16,
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <GivelifyButton
                    name="ViewExample"
                    onClick={showExampleModal}
                    text={copy.viewLarger}
                    variant="ghost"
                />
            </div>
        </>
    );
};

export default SidebarContent;
