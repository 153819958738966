import { TRANSACTION_FILTER_VALUE } from '@givelify/api';
import { useFilterParams } from 'api/hooks';

const paramNames = ['transaction'] as const;

export const useTransactionFilter = () => {
    const [filter, setFilter] = useFilterParams<
        TRANSACTION_FILTER_VALUE,
        typeof paramNames
    >(
        TRANSACTION_FILTER_VALUE.ALL,
        paramNames,
        (state, _paramName) => {
            if (state === TRANSACTION_FILTER_VALUE.ALL) {
                return '';
            }
            return state;
        },
        (values) => {
            if (values['transaction']) {
                const selectedParam = values[
                    'transaction'
                ].toLocaleUpperCase() as TRANSACTION_FILTER_VALUE;
                if (
                    selectedParam === TRANSACTION_FILTER_VALUE.PENDING ||
                    selectedParam === TRANSACTION_FILTER_VALUE.POSTED
                ) {
                    return selectedParam;
                }
            }
            return TRANSACTION_FILTER_VALUE.ALL;
        },
    );

    return [filter, setFilter] as const;
};
