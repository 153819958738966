import React, { useMemo } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { GivelifyButton } from '@givelify/givelify-ui';
import { Box, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TableColumnProps } from '../../../../../components/Tables/TableColumnTitle';
import { useFilterModalStyle } from '../useFilterModalStyle';
import SortColumnPanel from './SortColumnPanel';
import SortOrderPanel from './SortOrderPanel';

interface DonorFilterMobileProps {
    tableColumns: TableColumnProps[];
    onChange?: (sorting: Array<Sorting>) => void;
    sortDirection: SortingDirection;
    sortColumn: string;
}

const DonorSortMobile: React.FCC<DonorFilterMobileProps> = ({
    onChange,
    tableColumns,
    sortColumn: sortColumnInitial,
    sortDirection: sortDirectionInitial,
}) => {
    const classes = useFilterModalStyle();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            sortBy: t(`labels.sort_by`),
            sortOrder: t(`pages.donors.sort_order`),
            column: t(`pages.donors.column`),
            apply: t(`labels.apply`),
            ascending: t(`labels.ascending`),
            descending: t(`labels.descending`),
        }),
        [t],
    );

    const sortOrderOptions = useMemo<
        { name: string; value: SortingDirection }[]
    >(
        () => [
            {
                name: copy.ascending,
                value: 'asc',
            },
            {
                name: copy.descending,
                value: 'desc',
            },
        ],
        [copy],
    );

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [sortOrder, setSortOrder] =
        React.useState<SortingDirection>(sortDirectionInitial);
    const [sortColumn, setSortColumn] = React.useState(sortColumnInitial);

    const handleSortOrderChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSortOrder(event.target.value as SortingDirection);
    };

    const handleSortColumnChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSortColumn(event.target.value);
    };

    const applyFilters = () => {
        onChange([
            {
                columnName: sortColumn,
                direction: sortOrder,
            },
        ]);
        handleClose();
    };

    return (
        <>
            <GivelifyButton
                name="filter"
                onClick={handleOpen}
                size="small"
                text={copy.sortBy}
                variant="link"
            />
            <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                onClose={handleClose}
                open={open}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingTop={2}
                >
                    <h3 className={classes.title}>{copy.sortBy}</h3>
                    <GivelifyButton
                        bold
                        name="apply"
                        onClick={applyFilters}
                        text={copy.apply}
                        variant="ghost"
                    />
                </Box>
                <hr className={classes.divider} />
                <SortOrderPanel
                    handleSortOrderChange={handleSortOrderChange}
                    sortOrder={sortOrder}
                    sortOrderLabel={copy.sortOrder}
                    sortOrderOptions={sortOrderOptions}
                />
                <SortColumnPanel
                    handleSortColumnChange={handleSortColumnChange}
                    sortColumn={sortColumn}
                    sortColumnLabel={copy.column}
                    tableColumns={tableColumns}
                />
            </Dialog>
        </>
    );
};

// TODO: get rid of this component
export default DonorSortMobile;
