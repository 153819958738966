import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { InfoBox } from '../../infoBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adminTip: {
            flex: 1,
            marginLeft: 10,
            marginRight: 'auto',
            maxWidth: 544,
            justifyContent: 'center',
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
    }),
);

const PrimaryRepresentativeTipComponent: React.FCC = () => {
    const { adminTip } = useStyles();
    const { t } = useTranslation(I18N_NAMESPACE);
    const tipTitle = t('primaryRepresentativePage.tipTitle');
    const tipContent1 = t('primaryRepresentativePage.tipContent1');
    const tipContent2 = t('primaryRepresentativePage.tipContent2');
    const tipContent3 = t('primaryRepresentativePage.tipContent3');

    return (
        <div className={adminTip} id="admin-tip">
            <div>
                <InfoBox>
                    <GivelifyLabel
                        id="admin-form-tip"
                        marginBottom={24}
                        marginTop={8}
                        text={tipTitle}
                        variant="heading2"
                    />
                    <GivelifyLabel
                        id="admin-form-content1"
                        marginBottom={20}
                        marginTop={20}
                        text={tipContent1}
                        variant="body1"
                    />
                    <GivelifyLabel
                        id="admin-form-content2"
                        marginBottom={20}
                        marginTop={20}
                        text={tipContent2}
                        variant="body1"
                    />
                    <GivelifyLabel
                        id="admin-form-content3"
                        marginTop={20}
                        text={tipContent3}
                        variant="body1"
                    />
                </InfoBox>
            </div>
        </div>
    );
};

export const PrimaryRepresentativeTip = React.memo(
    PrimaryRepresentativeTipComponent,
);
