import * as React from 'react';
import {
    GivelifyDatePicker,
    GivelifyFilter,
    GivelifyMenuItem,
} from '@givelify/ui';
import {
    TimeFrameId,
    getDefaultStartDate,
    selectorWithRangeValueToString,
    toCustomRangeString,
    PAGE_NAME,
} from '@givelify/utils';
import {
    ExcludeTodayTimeFrameValues,
    TimeFrameFilterIds,
    TimeFrameValue,
    TimeFrameValues,
} from '@givelify/utils';
import { styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);

const List = styled('ul')({
    padding: 0,
    listStyleType: 'initial',
    marginBlock: 0,
    marginInline: 0,
    paddingInline: 0,
});

type TimeFrameSelectorProps = {
    value: TimeFrameValue;
    onChange: (value: TimeFrameValue) => void;
    disableFuture?: boolean;
    className?: string;
    maxDate?: dayjs.Dayjs;
    mode?: 'default' | 'list';
    width?: 'auto' | 'fullwidth';
    variant?: 'default' | 'input';
    isMobile?: boolean;
    excludeToday?: boolean;
};

export const TimeFrameFilter: React.FCC<TimeFrameSelectorProps> = ({
    value,
    onChange,
    maxDate,
    mode = 'default',
    width = 'auto',
    variant,
    className,
    isMobile,
    excludeToday,
}) => {
    value.selector = TimeFrameFilterIds.includes(value.selector)
        ? value.selector
        : 'custom';
    const { t } = useTranslation();
    const ref = React.useRef<HTMLButtonElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const [prevValue, setPrevValue] = React.useState(value);
    const TimeFrame = excludeToday
        ? ExcludeTodayTimeFrameValues
        : TimeFrameValues;
    const getOptions = React.useMemo(() => {
        return TimeFrameFilterIds.map((id) => {
            if (id === 'custom') {
                return {
                    id,
                    label:
                        value.selector === 'custom' && mode === 'default'
                            ? toCustomRangeString(
                                  t(`components.timeFrameSelector.customLong`),
                                  value,
                              )
                            : t(`components.timeFrameSelector.custom`),
                };
            }
            return {
                id,
                label: selectorWithRangeValueToString(
                    t(`components.timeFrameSelector.${id}`),
                    TimeFrame[id],
                ),
            };
        });
    }, [t, value, mode, TimeFrame]);
    const rangePickerShortcuts = React.useMemo(() => {
        return TimeFrameFilterIds.filter((x) => x !== 'custom').map((id) => {
            return {
                key: id,
                label: t(`components.timeFrameSelector.${id}`),
            };
        });
    }, [t]);
    const onOpen = React.useCallback(() => {
        if (value.selector === 'custom' && mode === 'default') {
            setCalendarOpen(true);
        } else {
            setMenuOpen(true);
        }
    }, [mode, value.selector]);
    const onClose = React.useCallback(() => {
        setMenuOpen(false);
    }, []);
    const onSelectorChange = React.useCallback(
        (selector: string) => {
            setMenuOpen(false);
            const value = selector as TimeFrameId;
            if (value === 'custom' && mode === 'default') {
                setCalendarOpen(true);
                return;
            }
            if (onChange) onChange(TimeFrame[value]);
        },
        [mode, onChange, TimeFrame],
    );
    const getFilterText = React.useCallback(() => {
        const option = getOptions.find((x) => x.id === value.selector);
        if (!option) return '';
        if (option.id === 'custom' && (mode === 'list' || isMobile)) {
            return isMobile
                ? t(`components.timeFrameSelector.customLong`)
                : t(`components.timeFrameSelector.custom`);
        }
        return option.label;
    }, [getOptions, mode, value.selector, t, isMobile]);
    const onRenderMenuContent = React.useCallback(() => {
        return (
            <List id="time-frame-selector-gvlmn-options">
                {getOptions.map((opt) => (
                    <GivelifyMenuItem
                        key={opt.id}
                        id={`option-${opt.id}`}
                        onClick={() => onSelectorChange(opt.id)}
                        selected={opt.id === value.selector}
                        text={opt.label}
                    />
                ))}
            </List>
        );
    }, [getOptions, onSelectorChange, value.selector]);
    const onCalendarClose = React.useCallback(
        (reason: 'submit' | 'cancel' | 'dismiss') => {
            setCalendarOpen(false);
            if (reason === 'cancel' && prevValue.selector === 'custom') {
                onChange(TimeFrame['lifetime']);
            }
        },
        [onChange, prevValue, TimeFrame],
    );
    const onRangeChange = React.useCallback(
        (start: Dayjs, end: Dayjs, range: TimeFrameId) => {
            if (
                range !== value.selector ||
                !value.start.isSame(start) ||
                !value.end.isSame(end)
            ) {
                onChange({
                    start,
                    end,
                    selector: range,
                });
            }
        },
        [onChange, value],
    );
    React.useEffect(() => {
        setPrevValue(value);
    }, [value]);
    return (
        <div className={className}>
            <GivelifyFilter
                ref={ref}
                id="time-frame-selector-gvlmn"
                menuProps={{
                    maxHeight: 'auto',
                }}
                name="Date Select"
                onClick={onOpen}
                onClose={onClose}
                onRenderMenuContent={onRenderMenuContent}
                open={menuOpen}
                text={getFilterText()}
                value={[value.selector]}
                variant={variant}
                width={width}
            />
            <GivelifyDatePicker
                disableFuture
                hideTextInput
                calendarAnchorRef={ref}
                end={value.end}
                maxDate={maxDate}
                minDate={dayjs.tz(getDefaultStartDate())}
                name={PAGE_NAME.Datepicker}
                onClose={onCalendarClose}
                onRangeChange={onRangeChange}
                open={calendarOpen}
                pickerMode="range"
                range={value.selector}
                rangePickerShortcuts={rangePickerShortcuts}
                start={value.start}
            />
        </div>
    );
};
