import React, { useState, useEffect } from 'react';
import { Donee, User } from '@givelify/api';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    GivelifyNotification,
} from '@givelify/ui';
import { TrackingProvider, useApiRequest } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { DirectDepositInfo } from '../@types/assets/onboarding';
import { GetDenominationsResponse } from '../api/responses/denomination/GetDenominationsResponse';
import { GetTitlesResponse } from '../api/responses/titles/GetTitlesResponse';
import { getDenominationsRequest } from '../api/services/donee/DoneeService';
import { getTitlesRequest } from '../api/services/titles/GetTitltesService';
import { I18N_NAMESPACE } from '../consts';
import { DirectDepositPage } from '../requiredInfo/DirectDepositPage';
import PrimaryRepresentativeModal from '../requiredInfo/primaryRepresentative/PrimaryRepresentativeModal';
import TaxModal from '../requiredInfo/TaxIdModal';
import { convertDoneeToOnboardingInfo } from '../utils/convertDoneeToOnboardingInfo';
import { useCaptivePortalContext } from './CaptivePortalProvider';
import { CustomizeProfile } from './components/customizeProfile';
import { DirectDepositVerification } from './components/DirectDepositVerification';
import { PrimaryRepresentativeVerification } from './components/PrimaryRepresentativeVerification';
import { TaxIdVerification } from './components/TaxIdVerification';
import { RequiredPrimaryRepInfo } from './requiredInfo/primaryRepresentative';
import { RequiredBankInfo } from './requiredInfo/requiredBankInfo';
import checkAccountNumberImage from './requiredInfo/requiredBankInfo/images/check-accountNumber.png';
import checkRoutingNumberImage from './requiredInfo/requiredBankInfo/images/check-routingNumber.png';
import { RequiredTaxIdInfo } from './requiredInfo/taxId';

export const CaptivePortal: React.FCC<{
    donee: Donee;
    showYodlee: boolean;
    user: User;
    setButtonBringToFront: (value: boolean) => void;
}> = ({ donee, showYodlee, user, setButtonBringToFront }) => {
    const {
        goToSettingsClick,
        goToOverviewClick,
        skipOnboardingClick,
        bankVerificationEnabled,
        taxIdEnabled,
        primaryRepEnabled,
        directDeposit,
        setDirectDeposit,
        primaryRepresentative,
        setPrimaryRepresentative,
        taxId,
        setTaxId,
        captivePortalNotification,
        setCaptivePortalNotification,
    } = useCaptivePortalContext();

    const { scopedTranslate, t } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: 'captivePortal',
    });
    const copy = React.useMemo(
        () => ({
            header: scopedTranslate('header'),
            reviewDescription: scopedTranslate('reviewDescription'),
            requireInformationDescription: scopedTranslate(
                'requireInformationDescription',
            ),
            description: scopedTranslate('description'),
            skip1: scopedTranslate('skip1'),
            skip2: scopedTranslate('skip2'),
            skipLinkText: scopedTranslate('skipLinkText'),
            loading: t('taxId.loading'),
            loadingSuccess: t('taxId.success'),
            documentUploadMessage: t('captivePortal.documentUploadLoading'),
            documentUploadSuccess: t('captivePortal.documentUploadSuccess'),
        }),
        [scopedTranslate, t],
    );

    const onboarding = convertDoneeToOnboardingInfo(donee);

    const [showScreen, setShowScreen] = useState<
        'bankInfo' | 'taxId' | 'primaryRep'
    >();

    const [getDenominationsRequestState, makeGetDenominationsRequest] =
        useApiRequest<GetDenominationsResponse>();

    const [getTitlesRequestState, makeGetTitlesRequest] =
        useApiRequest<GetTitlesResponse>();

    useEffect(() => {
        makeGetTitlesRequest(getTitlesRequest());
    }, [makeGetTitlesRequest]);

    useEffect(() => {
        makeGetDenominationsRequest(getDenominationsRequest());
    }, [makeGetDenominationsRequest]);

    const statuses = [
        taxId.status,
        directDeposit.status,
        primaryRepresentative.status,
    ];

    const allInReview = statuses.every(
        (status) =>
            status === 'in_progress' ||
            status === 'request_callback' ||
            status === 'verified',
    );
    const anyRequireInformation = statuses.some(
        (status) => status === 'require_information',
    );

    const description =
        (allInReview && copy.reviewDescription) ||
        (anyRequireInformation && copy.requireInformationDescription) ||
        copy.description;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    useEffect(() => {
        if (allInReview) window.scrollTo(0, 0);
    }, [allInReview]);

    const handleSubmit = (data: DirectDepositInfo) => {
        setDirectDeposit(data);
        if (data.status !== 'require_information') {
            setShowScreen(undefined);
        }
    };

    useEffect(() => {
        new Image().src = checkRoutingNumberImage;
        new Image().src = checkAccountNumberImage;
    }, []);

    const isNonProfit = onboarding.organizationType === 'nonprofit';

    const hideCustomizeProfileCard = React.useMemo(() => {
        const bannerImage = onboarding.appProfile.bannerImage;
        const organizationLogo = onboarding.appProfile.organizationLogo;
        const faithLeaderImage = onboarding.appProfile.faithLeaderImage;
        const faithLeader = onboarding.appProfile.faithLeader;
        if (isNonProfit) {
            return Boolean(bannerImage) && Boolean(organizationLogo);
        } else {
            return (
                Boolean(bannerImage) &&
                Boolean(organizationLogo) &&
                Boolean(faithLeaderImage) &&
                Boolean(faithLeader)
            );
        }
    }, [isNonProfit, onboarding]);

    return (
        <TrackingProvider
            trackPageVisit
            pageName="Captive Portal"
            viewType="page"
        >
            <div data-testid="captivePortal">
                {captivePortalNotification && (
                    <GivelifyNotification
                        {...captivePortalNotification}
                        open
                        onClose={() => setCaptivePortalNotification(null)}
                    />
                )}
                <GivelifyLabel
                    text={copy.header}
                    variant={isMobile ? 'heading1S' : 'heading1M'}
                />
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    style={{
                        marginTop: isMobile ? 4 : 12,
                    }}
                    text={description}
                    variant={isMobile ? 'body2' : 'heading2S'}
                />

                {allInReview && (
                    <GivelifyButton
                        name="Continue to Analytics Studio"
                        onClick={goToOverviewClick}
                        style={{
                            marginTop: 24,
                        }}
                        text="Continue to Analytics Studio"
                        variant="primary"
                    />
                )}

                {showScreen === 'bankInfo' &&
                    (bankVerificationEnabled ? (
                        <RequiredBankInfo
                            data={directDeposit}
                            doneeId={donee.id}
                            onClose={() => setShowScreen(undefined)}
                            onSubmit={handleSubmit}
                            requireAdditionalInfo={
                                directDeposit.status === 'require_information'
                            }
                        />
                    ) : (
                        <DirectDepositPage
                            data={directDeposit}
                            doneeId={donee.id}
                            isOpen={true}
                            onCancel={() => {
                                setShowScreen(undefined);
                            }}
                            onSubmit={(data) => {
                                setDirectDeposit(data);
                            }}
                            setButtonBringToFront={setButtonBringToFront}
                            showYodlee={showYodlee}
                        />
                    ))}

                {showScreen === 'taxId' &&
                    (taxIdEnabled ? (
                        <RequiredTaxIdInfo
                            accountOwnerFullName=""
                            data={taxId}
                            documentUploadMessage={copy.documentUploadMessage}
                            documentUploadSuccess={copy.documentUploadSuccess}
                            doneeId={donee.id}
                            getDenominationsRequestState={
                                getDenominationsRequestState
                            }
                            isOpen={true}
                            loadingMessage={copy.loading}
                            loadingSuccessMessage={copy.loadingSuccess}
                            onCancel={() => setShowScreen(undefined)}
                            onClose={() => setShowScreen(undefined)}
                            onSubmit={(data) => {
                                setTaxId(data);
                                setShowScreen(undefined);
                            }}
                            organizationName={donee.name}
                            organizationType={onboarding.organizationType}
                            requireAdditionalInfo={
                                taxId.status === 'require_information'
                            }
                        />
                    ) : (
                        <TaxModal
                            accountOwnerFullName=""
                            data={taxId}
                            doneeId={donee.id}
                            getDenominationsRequestState={
                                getDenominationsRequestState
                            }
                            isOpen={true}
                            onCancel={() => setShowScreen(undefined)}
                            onSubmit={(data) => {
                                setTaxId(data);
                                setShowScreen(undefined);
                            }}
                            organizationName={donee.name}
                            organizationType={onboarding.organizationType}
                            setButtonBringToFront={setButtonBringToFront}
                        />
                    ))}

                {showScreen === 'primaryRep' &&
                    (primaryRepEnabled ? (
                        <RequiredPrimaryRepInfo
                            data={primaryRepresentative}
                            doneeId={donee.id}
                            getTitlesRequestState={getTitlesRequestState}
                            isOpen={true}
                            onCancel={() => {
                                setShowScreen(undefined);
                            }}
                            onClose={() => setShowScreen(undefined)}
                            onSubmit={(data) => {
                                setPrimaryRepresentative(data);
                                setShowScreen(undefined);
                            }}
                            user={user}
                        />
                    ) : (
                        <PrimaryRepresentativeModal
                            data={primaryRepresentative}
                            doneeId={donee.id}
                            isOpen={true}
                            onCancel={() => {
                                setShowScreen(undefined);
                            }}
                            onSubmit={(data) => {
                                setPrimaryRepresentative(data);
                            }}
                            setButtonBringToFront={setButtonBringToFront}
                            user={user}
                        />
                    ))}

                <div
                    style={{
                        marginTop: isMobile ? 32 : 42,
                        marginBottom: isMobile ? 32 : 42,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isMobile ? 16 : 32,
                        }}
                    >
                        <TaxIdVerification
                            buttonName="Verify Organization"
                            doneeId={donee.id}
                            onClick={() => setShowScreen('taxId')}
                            state={taxId.status}
                            submittedAt={taxId.submittedAt}
                            submittedBy={
                                taxId.submittedByOfficialId === user.id
                                    ? null
                                    : taxId.submittedByOfficialId
                            }
                        />
                        <DirectDepositVerification
                            buttonName="Set Up Checking Account"
                            doneeId={donee.id}
                            onClick={() => setShowScreen('bankInfo')}
                            state={directDeposit.status}
                            submittedAt={directDeposit.submittedAt}
                            submittedBy={
                                directDeposit.submittedByOfficialId === user.id
                                    ? null
                                    : directDeposit.submittedByOfficialId
                            }
                        />
                        <PrimaryRepresentativeVerification
                            buttonName="Add Representative"
                            doneeId={donee.id}
                            onClick={() => setShowScreen('primaryRep')}
                            state={primaryRepresentative.status}
                            submittedAt={primaryRepresentative.submittedAt}
                            submittedBy={
                                primaryRepresentative.submittedByOfficialId ===
                                user.id
                                    ? null
                                    : primaryRepresentative.submittedByOfficialId
                            }
                        />
                    </div>
                    {!hideCustomizeProfileCard ? (
                        <CustomizeProfile
                            goToSettingsClick={goToSettingsClick}
                            isNonProfit={isNonProfit}
                        />
                    ) : (
                        <>
                            <GivelifyLabel
                                style={{
                                    marginTop: 16,
                                    color: DesignTokens.color.textSecondary,
                                }}
                                variant={isMobile ? 'body2' : 'body1'}
                            >
                                {`${copy.skip1} `}
                                <GivelifyLink
                                    name="Continue to Studio for now"
                                    onClick={skipOnboardingClick}
                                    text={copy.skipLinkText}
                                    variant={isMobile ? 'body2B' : 'body1B'}
                                />
                            </GivelifyLabel>
                            <GivelifyLabel
                                style={{
                                    color: DesignTokens.color.textSecondary,
                                }}
                                text={copy.skip2}
                                variant={isMobile ? 'body2' : 'body1'}
                            />
                        </>
                    )}
                </div>
            </div>
        </TrackingProvider>
    );
};
