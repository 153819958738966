import React, { useMemo } from 'react';
import { PAGE_NAME } from '@givelify/utils';
import { useTabFilter } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useGasRouterContext } from 'router/GasRouterProvider';
import SettingsPage from '../SettingsPage';
import { CustomizeProfileTab } from './CustomizeProfileTab';
import { CustomizeReceiptTab } from './CustomizeReceiptTab';
import { ProfileTab } from './style';

const TAB_PATH = {
    profile: 'profile',
    receipt: 'receipt',
} as const;

const AppProfile: React.FCC = () => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.app-profile.title'),
            description: t('pages.settings.app-profile.caption'),
            profileTab: t(
                'pages.settings.app-profile.customize-profile-tab.title',
            ),
            receiptTab: t(
                'pages.settings.app-profile.customize-receipt-tab.title',
            ),
        }),
        [t],
    );
    const { PATH } = useGasRouterContext();
    const [tab, setTab] = useTabFilter([TAB_PATH.profile, TAB_PATH.receipt], {
        tab: TAB_PATH.profile,
    });
    const tabHrefs = React.useMemo(
        () => [
            PATH.SETTINGS.APP_PROFILE(TAB_PATH.profile),
            PATH.SETTINGS.APP_PROFILE(TAB_PATH.receipt),
        ],
        [PATH.SETTINGS],
    );
    const handleChange = (newValue: number) => {
        const newTab = newValue === 0 ? TAB_PATH.profile : TAB_PATH.receipt;
        if (newTab !== tab.tab) {
            setTab({ tab: newTab });
        }
    };

    return (
        <SettingsPage
            description={copy.description}
            pageName={PAGE_NAME.SettingsAppProfile}
            title={copy.title}
            trackPageVisit={false}
        >
            <ProfileTab
                onChange={handleChange}
                options={[
                    {
                        label: copy.profileTab,
                        tabHref: tabHrefs[0],
                    },
                    {
                        label: copy.receiptTab,
                        tabHref: tabHrefs[1],
                    },
                ]}
                value={tab.tab === TAB_PATH.profile ? 0 : 1}
            />
            {tab.tab === TAB_PATH.profile && <CustomizeProfileTab />}
            {tab.tab === TAB_PATH.receipt && <CustomizeReceiptTab />}
        </SettingsPage>
    );
};

export default AppProfile;
