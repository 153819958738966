import React, { ReactChild } from 'react';
import { Theme, createStyles, makeStyles, Modal } from '@material-ui/core';
import Div100vh from 'react-div-100vh';
import ModalHeader from './ModalHeader';
import ModalTopMessage from './ModalTopMessage';
import { ModalSteps, ModalHorizontalSteps } from './ModalSteps';
import { SubmitFooter } from './SubmitFooter';
import { TrackingProvider } from '@givelify/utils';
import { mergeClassNames } from '../utils/classNameUtils';

interface StyleProps {
    hasFooter: boolean;
    steps: boolean;
    normalContentPadding?: {
        paddingLeft?: number;
        paddingRight?: number;
        paddingTop?: number;
        paddingBottom?: number;
    };
    smallContentPadding?: {
        paddingLeft?: number;
        paddingRight?: number;
        paddingTop?: number;
        paddingBottom?: number;
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalStyles: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transform: `translate(0, 0)`,
            width: '100vw !important',
            overflow: 'auto',
            background: theme.colors ? theme.colors.primaryWhite : '#ffffff',
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            '& .content': {
                boxSizing: 'border-box',
                paddingTop: ({ normalContentPadding }: StyleProps) =>
                    normalContentPadding?.paddingTop
                        ? normalContentPadding?.paddingTop
                        : 40,
                paddingLeft: ({ normalContentPadding }: StyleProps) =>
                    normalContentPadding?.paddingLeft
                        ? normalContentPadding?.paddingLeft
                        : 16,
                paddingRight: ({ normalContentPadding }: StyleProps) =>
                    normalContentPadding?.paddingRight
                        ? normalContentPadding?.paddingRight
                        : 16,
                flex: 1,
                [theme.breakpoints.down('sm')]: {
                    paddingTop: ({ smallContentPadding }: StyleProps) =>
                        smallContentPadding?.paddingTop
                            ? smallContentPadding?.paddingTop
                            : 24,
                    paddingLeft: ({ smallContentPadding }: StyleProps) =>
                        smallContentPadding?.paddingLeft
                            ? smallContentPadding?.paddingLeft
                            : 16,
                    paddingRight: ({ smallContentPadding }: StyleProps) =>
                        smallContentPadding?.paddingRight
                            ? smallContentPadding?.paddingRight
                            : 16,
                },
                overflowY: 'auto',
            },
            '& .content-holder': {
                maxWidth: ({ steps }: StyleProps) => (steps ? 1380 : 1259),
                minHeight: 117,
                margin: '0 auto',
                display: 'flex',
                width: '100%',
                boxSizing: 'border-box',
            },
            '& .content-flex': {
                overflow: 'visible',
                width: '100%',
                paddingBottom: ({ normalContentPadding }: StyleProps) =>
                    normalContentPadding?.paddingBottom
                        ? normalContentPadding?.paddingBottom
                        : 40,
                [theme.breakpoints.down('sm')]: {
                    paddingBottom: ({ smallContentPadding }: StyleProps) =>
                        smallContentPadding?.paddingBottom
                            ? smallContentPadding?.paddingBottom
                            : 40,
                },
            },
            '& .footer': {
                zIndex: 1,
                backgroundColor: theme.colors?.primaryWhite,
                height: 90,
                boxShadow: '0px -1px 2px rgba(58, 76, 99, 0.2)',
                [theme.breakpoints.down('xs')]: {
                    height: 72,
                },
            },
            [theme.breakpoints.down('sm')]: {
                '& .content-flex': {
                    width: '100%',
                },
            },
        },
    }),
);

export interface FullWidthModalProps {
    id?: string;
    testId?: string;
    name: string;
    heading: string;
    open: boolean;
    onClose: () => unknown;
    children: ReactChild | ReactChild[];
    useBackArrow?: boolean;
    lockText?: string;
    retryMessage?: string | undefined;
    errorMessage?: string | undefined;
    steps?:
        | {
              totalSteps: number;
              currentStep: number;
          }
        | undefined;
    footer?:
        | {
              onCancel: () => void;
              onSubmit: () => void;
              cancelText: string;
              submitText: string;
              disableSubmit: boolean;
              isLoading: boolean;
          }
        | undefined;
    normalContentPadding?: {
        paddingLeft?: number;
        paddingRight?: number;
        paddingTop?: number;
        paddingBottom?: number;
    };
    smallContentPadding?: {
        paddingLeft?: number;
        paddingRight?: number;
        paddingTop?: number;
        paddingBottom?: number;
    };
    onStepClick?: (step: number) => void;
    className?: string;
    classContentName?: string;
    classFooterName?: string;
    trackPageVisit?: boolean;
}

export const FullWidthModal: React.FCC<FullWidthModalProps> = ({
    id,
    testId,
    name,
    heading,
    open,
    onClose,
    children,
    useBackArrow,
    retryMessage,
    errorMessage,
    lockText,
    footer,
    steps,
    normalContentPadding,
    smallContentPadding,
    onStepClick,
    className,
    classContentName,
    classFooterName,
    trackPageVisit = false,
}) => {
    const { modalStyles } = useStyles({
        hasFooter: !!footer,
        steps: steps !== undefined,
        normalContentPadding,
        smallContentPadding,
    });

    if (!open) return null;
    return (
        <TrackingProvider
            pageName={`${name} Modal`}
            trackPageVisit={trackPageVisit}
        >
            <Modal
                open
                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-title"
                disableEnforceFocus
                disableAutoFocus
                disableRestoreFocus
                id={id}
                className={className}
                data-testid={testId}
            >
                <Div100vh className={modalStyles}>
                    <ModalHeader
                        heading={heading}
                        useBackArrow={useBackArrow}
                        lockText={lockText}
                        onClose={onClose}
                    />
                    <div
                        className={mergeClassNames('content', classContentName)}
                    >
                        <div className="content-holder">
                            {steps && (
                                <ModalSteps
                                    currentStep={steps.currentStep}
                                    totalSteps={steps.totalSteps}
                                    onStepClick={onStepClick}
                                />
                            )}
                            <div className="content-flex">
                                {steps && (
                                    <ModalHorizontalSteps
                                        currentStep={steps.currentStep}
                                        totalSteps={steps.totalSteps}
                                        onStepClick={onStepClick}
                                    />
                                )}
                                <ModalTopMessage
                                    variant="error"
                                    message={errorMessage}
                                />
                                <ModalTopMessage
                                    variant="warning"
                                    message={retryMessage}
                                />
                                {children}
                            </div>
                        </div>
                    </div>
                    {footer && (
                        <div
                            className={mergeClassNames(
                                'footer',
                                classFooterName,
                            )}
                        >
                            <SubmitFooter
                                onCancel={footer.onCancel}
                                onSubmit={footer.onSubmit}
                                cancelText={footer.cancelText}
                                submitText={footer.submitText}
                                disableSubmit={footer.disableSubmit}
                                isLoading={footer.isLoading}
                                testId={testId}
                            />
                        </div>
                    )}
                </Div100vh>
            </Modal>
        </TrackingProvider>
    );
};
