import React from 'react';
import { CardsStack, Onboarding, OnboardingProps } from '@givelify/onboarding';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import PageTitle from 'components/PageTitle';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { MainWrapperTopSection } from 'layout/MainWrapperTopSection';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppActions, AppState } from 'store';
import { updateNotification as updateDoneeNotification } from 'store/doneeNotification/thunks';
import { updateNotification } from 'store/notifications/thunks';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import ConfirmContactInfoPanel from './Cfro/ConfirmContactInfoPanel';
import { CfroProvider, useCfroContext } from './Cfro/context';
import FirstDonationPanel from './FirstDonationPanel';
import { GivingTuesdayProvider } from './GivingTuesday/context';
import { OverviewMain } from './OverviewMain';

const cardProps: OnboardingProps['cardProps'] = {
    marginTop: 0,
    containerPadding: 16,
    defaultCardWidth: 1080,
    drawerWidth: 256,
    cardSpacing: 32,
    swipeLength: 0.25,
    parentWidth: 1080,
    parentPadding: 16,
    deviations: {
        portWidth: 0,
        cardWidth: 0,
        cardSpacing: 0,
        portOffset: 0,
        cardPositionXSecond: 0,
    },
};

export const OverviewPage: React.FCC = () => {
    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();

    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();

    const {
        user,
        donee,
        onboardingEnabled,
        yodleeEnabled,
        campuses,
        enabledFeatures,
        startedOnboardingV2,
        hideIntroductionVideoCards,
        hideOnboardingProfileMessage,
        hideOnboardingVerifiedMessage,
    } = useSelector((state: AppState) => ({
        user: state.User.user,
        donee: state.Donee.donee,
        onboardingEnabled: state.System.onboardingEnabled,
        yodleeEnabled: state.System.yodleeEnabled,
        campuses: state.Donee.campuses,
        enabledFeatures: state.System.enabledFeatures,
        startedOnboardingV2: state.DoneeNotifications.startedOnboardingV2,
        hideIntroductionVideoCards:
            state.Notifications.hideIntroductionVideoCards ||
            state.DoneeNotifications.hideVideoCardsModal, // we're hiding this block if campus has at least 2 dontaions
        hideOnboardingProfileMessage:
            state.DoneeNotifications.hideOnboardingProfileMessage,
        hideOnboardingVerifiedMessage:
            state.DoneeNotifications.hideOnboardingVerifiedMessage,
    }));

    const {
        featureRedirectIntegrationEnabled,
        introductionVideoCardsEnabled,
        newOnboardingEnabled,
        captivePortalEnabled,
    } = React.useMemo(
        () => ({
            featureRedirectIntegrationEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.REDIRECT_INTEGRATIONS,
            ),
            introductionVideoCardsEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.OVERVIEW_VIDEO_CARDS,
                false,
            ),
            newOnboardingEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.NEW_ONBOARDING,
                false,
            ),
            captivePortalEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.CAPTIVE_PORTAL,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const { showCfroPanel } = useCfroContext();

    const onboardingIsComplete = React.useMemo(() => {
        if (!campuses || campuses.length === 0) return false;
        const complete = campuses.find((x) => x.onboarding.hasCompleted);
        return !!complete;
    }, [campuses]);

    const showOnboardingV1 = onboardingEnabled && !onboardingIsComplete;

    const showCompleteNotification =
        startedOnboardingV2 &&
        (!hideOnboardingProfileMessage || !hideOnboardingVerifiedMessage);
    const showOnboardingV2 =
        onboardingEnabled &&
        (!donee.onboarding.hasMid || showCompleteNotification);

    const showOnboarding =
        (newOnboardingEnabled ? showOnboardingV2 : showOnboardingV1) &&
        !captivePortalEnabled;

    const showTimeToFirstDonationPanel =
        ((newOnboardingEnabled && startedOnboardingV2) ||
            captivePortalEnabled) &&
        !!donee.onboarding.hasMid &&
        !donee.lastDonationDate;

    const camelcaseDonee = React.useMemo(
        () => toCamelCase(donee, { deep: true }),
        [donee],
    );

    const onOnboardingV2Start = () => {
        void dispatch(updateDoneeNotification('startedOnboardingV2', true));
    };

    const onProfileClose = () => {
        void dispatch(
            updateDoneeNotification('hideOnboardingProfileMessage', true),
        );
    };

    const onVerifiedClose = () => {
        void dispatch(
            updateDoneeNotification('hideOnboardingVerifiedMessage', true),
        );
    };

    const onVideoCardsCloseClick = () => {
        void dispatch(updateNotification('hideIntroductionVideoCards', true));
    };

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();

    if (featureRedirectIntegrationEnabled) {
        navigate(PATH.DATA.ROOT);
        return null;
    }

    const isHQ = donee.id === campuses[0]?.id && !donee.parentCampus;

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.Overview}>
            <CfroProvider>
                <div data-testid="page-overview">
                    <PageTitle
                        description="Overview Page"
                        title="Overview Page"
                    />
                    <MainWrapperTopSection>
                        {showOnboarding && isHQ && (
                            <Onboarding
                                cardProps={{
                                    ...cardProps,
                                    introductionVideoCardsEnabled,
                                    hideIntroductionVideoCards,
                                    onVideoCardsCloseClick,
                                }}
                                donee={camelcaseDonee}
                                hideProfile={hideOnboardingProfileMessage}
                                hideVerified={hideOnboardingVerifiedMessage}
                                newOnboarding={newOnboardingEnabled}
                                onOnboardingV2Start={onOnboardingV2Start}
                                onProfileClose={onProfileClose}
                                onVerifiedClose={onVerifiedClose}
                                setButtonBringToFront={setButtonBringToFront}
                                settingsUsersPath={PATH.SETTINGS.USERS()}
                                showYodlee={yodleeEnabled}
                                startedOnboardingV2={startedOnboardingV2}
                                user={user}
                            />
                        )}
                        {captivePortalEnabled && (
                            <CardsStack
                                donee={donee}
                                hideProfileCard={hideOnboardingProfileMessage}
                                hideVideoCard={hideIntroductionVideoCards}
                                introductionVideoCardsEnabled={
                                    introductionVideoCardsEnabled
                                }
                                onCompleteCardClose={onVerifiedClose}
                                onProfileCardClose={onProfileClose}
                                onVideoCardClose={onVideoCardsCloseClick}
                                setButtonBringToFront={setButtonBringToFront}
                                settingsUsersPath={PATH.SETTINGS.USERS()}
                                showCompleteCard={
                                    !hideOnboardingVerifiedMessage
                                }
                                user={user}
                            />
                        )}
                        {showTimeToFirstDonationPanel && isHQ && (
                            <FirstDonationPanel />
                        )}
                        {!showOnboarding &&
                            !showTimeToFirstDonationPanel &&
                            showCfroPanel &&
                            hideOnboardingProfileMessage &&
                            isHQ && <ConfirmContactInfoPanel />}
                        <GivingTuesdayProvider />
                    </MainWrapperTopSection>
                    <OverviewMain />
                </div>
            </CfroProvider>
        </TrackingProvider>
    );
};
