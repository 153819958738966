import { DesignTokens, GivelifyLabel, GivelifyPaper } from '@givelify/ui';
import { styled } from '@mui/material';

export const PageBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

export const PageTitle = styled(GivelifyLabel)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
    fontSize: DesignTokens.textStyle.globalHeadingL2.font.size,
    lineHeight: `${DesignTokens.textStyle.globalHeadingL2.font.lineHeight}px`,
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalHeadingM1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalHeadingM1.font.lineHeight}px`,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalHeadingS2.font.size,
        lineHeight: `${DesignTokens.textStyle.globalHeadingS2.font.lineHeight}px`,
        gap: theme.spacing(2),
    },
}));

export const PageHeader = styled('div')(({ theme }) => ({
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(0, 2),
    },
}));

export const BasicPageLegacyPaper = styled(GivelifyPaper)(({ theme }) => ({
    padding: theme.spacing(4),
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
    },
}));

export const BasicPageSimplePaper = styled('div')(({ theme }) => ({
    padding: 0,
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(0, 2, 2, 2),
    },
}));
