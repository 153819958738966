export const getDoneeUpdateApiEndpoint = (doneeId: number) =>
    `/donees/${doneeId}`;

export const getPrimaryRepresentativeApiEndpoint = (doneeId: number) =>
    `/donees/${doneeId}/beneficiary`;

export const getDoneeOnboardingApiEndpoint = (doneeId: number) =>
    `/donees/${doneeId}/onboarding`;

export const getDenominationsApiEndpoint = () => '/denominations/';

export const directDepositUrl = (id: number) => `/donees/${id}/bank-info`;

export const yodleeAccessTokenUrl = (id: number) =>
    `/donees/${id}/yodlee-token`;

export const yodleeAccountsUrl = (
    id: number,
    accountId: string,
    providerAccountId: string,
    yodleeAccessToken: string,
) =>
    `/donees/${id}/yodlee-accounts?token=${yodleeAccessToken}&account_id=${accountId}&provider_account_id=${providerAccountId}`;

export const customizeProfileUrl = (id: number) => `/donees/${id}/faith-leader`;

export const updateAppProfileUrl = (id: number) => `/donees/${id}`;

export const updateFaithLeadereUrl = (id: number) =>
    `/donees/${id}/faith-leader`;

export const getRepresentativesUrl = (id: number) =>
    `/donees/${id}/representatives`;

export const getBankNameUrl = (routingNumber: string) =>
    `/bank-names/${routingNumber}`;

export const getTaxIdDetailsUrl = (taxId: string) => `/irs/${taxId}`;

export const getTitlesUrl = () => `/titles`;

export const postDenominationUrl = () => `/denominations`;
