import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyRadio,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { mergeClassNames } from '@givelify/utils';
import { inviteUserModalStyle } from './inviteUserModalStyle';

interface PermissionCardProps {
    id?: string;
    title: string;
    subTitle: string;
    details: Array<unknown>;
    selected: boolean;
    onSelected: () => void;
    disabled?: boolean;
}

const DetailRow = ({ detail }) => {
    const styles = inviteUserModalStyle();
    return (
        <div className={styles.detailRow}>
            <GivelifyIcon
                className={styles.checkIcon}
                color="primary"
                size="20"
                variant="check"
            />
            {detail}
        </div>
    );
};

const PermissionCard: React.FCC<PermissionCardProps> = (
    props: PermissionCardProps,
) => {
    const {
        id,
        title,
        subTitle,
        details,
        selected,
        onSelected,
        disabled = false,
    } = props;
    const styles = inviteUserModalStyle();
    return (
        <div
            className={mergeClassNames(
                styles.cardContent,
                selected ? styles.cardSelected : null,
                disabled ? styles.cardDisabled : null,
            )}
            data-testid={id}
            onClick={onSelected}
        >
            <div className={styles.cardTitleContent}>
                <GivelifyLabel text={title} variant="heading3" />
                <GivelifyLabel text={subTitle} variant="body2" />
            </div>
            <GivelifyTextDivider marginBottom={15} marginTop={12} />
            <div className={styles.detailContent}>
                {details.map((detail, index) => (
                    <div key={index}>
                        <DetailRow detail={detail} />
                    </div>
                ))}
            </div>
            <div className={styles.cardFooter}>
                <GivelifyRadio
                    ariaLabel="user-permission-radio"
                    checked={selected}
                    className={mergeClassNames(
                        styles.radioButton,
                        selected ? styles.radioButtonSelected : null,
                    )}
                    disabled={!selected && disabled}
                    id={id || 'permission-card'}
                    name="user-permission-radio"
                    tabIndex={1}
                    value="a"
                    variant={selected ? 'primary' : 'secondary'}
                />
            </div>
        </div>
    );
};

export default PermissionCard;
