import React, { useMemo } from 'react';
import { FacebookLoading, GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName, useTrackingContext } from '@givelify/utils';
import { Box, Link, Typography } from '@material-ui/core';
import permissionTypes from 'constants/permissionTypes';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { Donor } from 'types/donorTypes';
import { DONOR_PROFILE_PAGE_CLICK_DONOR_EMAIL_LINK } from 'utils/clevertapEvents';
import { useAdvancedTranslation } from 'utils/i18n';
import permissionsByPath from 'utils/permissionsByPath';
import AssignDonorMemberId from '../../components/AssignDonorMemberId';
import { useStyle } from './useStyles';

export interface DonorDetailProps {
    className?: string;
    donor: Donor;
    doneeId: number;
    hasLoadedDonor: boolean;
}

const Loading: JSX.Element = (
    <FacebookLoading borderRadius={0} height={30} marginTop={4} width="100%" />
);

const baseTranslation = 'pages.donor_details';

export const DonorDetails: React.FCC<DonorDetailProps> = ({
    className,
    donor,
    doneeId,
    hasLoadedDonor,
}) => {
    const { trackEvent } = useTrackingContext();
    const classes = useStyle();

    const { t, at } = useAdvancedTranslation();

    const copy = useMemo(
        () => ({
            memberNumber: at(`${baseTranslation}.member_number`),
            givelifyNumber: t(`${baseTranslation}.givelify_number`),
            donorType: t(`${baseTranslation}.donor_type`),
            contactInfo: t(`${baseTranslation}.contact_info`),
            address: t(`${baseTranslation}.address`),
            profilePicture: t(`${baseTranslation}.profile_picture`),
            na: t(`${baseTranslation}.na`),
        }),
        [t, at],
    );

    const user = useSelector((state: AppState) => state.User.user);

    const memberIdReadOnly =
        permissionsByPath[PATH.DONORS.PROFILE][user?.role] !==
        permissionTypes.FULL_ACCESS;

    const onMailUrlClick = () => {
        trackEvent(DONOR_PROFILE_PAGE_CLICK_DONOR_EMAIL_LINK);
    };

    return (
        <Box className={`${classes.outerBox} ${className}`} display="flex">
            <div className={classes.wrapper}>
                <div className={classes.donorDetails}>
                    <div className={classes.imageWrapper}>
                        <GivelifyAvatar
                            color="eucalyptus"
                            src={donor.picture}
                            text={getInitialsFromName(donor.name)}
                            variant="square"
                        />
                    </div>
                    <Box flex={1}>
                        <Typography className={classes.title} id="donor-name">
                            {donor.name}
                        </Typography>
                        <Box className={classes.detail}>
                            <Box className={classes.label}>
                                {copy.memberNumber}
                            </Box>
                            <Box>
                                {!hasLoadedDonor ? (
                                    Loading
                                ) : (
                                    <AssignDonorMemberId
                                        doneeId={doneeId}
                                        donorId={donor.id}
                                        memberId={donor.externalId}
                                        readOnly={memberIdReadOnly}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.detail}>
                            <Box className={classes.label}>
                                {copy.givelifyNumber}
                            </Box>
                            {!hasLoadedDonor
                                ? Loading
                                : donor.givelifyNumber || copy.na}
                        </Box>
                        <Box className={classes.detail}>
                            <Box className={classes.label}>
                                {copy.donorType}
                            </Box>
                            {!hasLoadedDonor ? (
                                <GivelifyLabel
                                    fontWeight={400}
                                    id="giving-style"
                                    text="Loading"
                                    variant="body2"
                                />
                            ) : (
                                <GivelifyLabel
                                    fontWeight={400}
                                    id="giving-style"
                                    text={donor.donorType.statusType}
                                    variant="body2"
                                />
                            )}
                        </Box>
                        <Box className={classes.detail}>
                            <Box className={classes.label}>
                                {copy.contactInfo}
                            </Box>
                            {!hasLoadedDonor ? Loading : donor.phone || copy.na}
                            <br />
                            <Link
                                href={`mailto:${donor.email}`}
                                onClick={onMailUrlClick}
                            >
                                {!hasLoadedDonor
                                    ? Loading
                                    : donor.email || copy.na}
                            </Link>
                        </Box>
                    </Box>
                </div>
                <div className={classes.detail}>
                    <div className={classes.label}>{copy.address}</div>
                    {!hasLoadedDonor ? (
                        Loading
                    ) : (
                        <>
                            {donor.address}, {donor.city}
                            <br />
                            {donor.state}, {donor.zip}
                        </>
                    )}
                </div>
            </div>
            <Box className={classes.verticalRule} />
        </Box>
    );
};
