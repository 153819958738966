import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { Stack } from '@mui/material';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';

export type ChartLegendProps = {
    data: { label: string; value: number }[];
};

const ChartLegendComponent: React.FCC<ChartLegendProps> = ({ data }) => {
    return (
        <Stack
            borderTop="1px solid rgba(0, 0, 0, 0.1)"
            flexDirection="row"
            justifyContent="space-between"
            paddingTop={2}
            width="726px"
        >
            {data.map((item, index) => (
                <Stack key={`legend-${index}`} gap={1}>
                    <GivelifyLabel text={item.label} variant="data1" />
                    <GivelifyLabel
                        text={formatMoneyWithCommasAndDolarSign(item.value)}
                        variant="body1B"
                    />
                </Stack>
            ))}
        </Stack>
    );
};

export const ChartLegend = React.memo(ChartLegendComponent);
