import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { Skeleton, styled } from '@mui/material';

const TotalDonationsWrapper = styled('div', {
    shouldForwardProp: (propName) => propName !== 'fullWidth',
})<{ fullWidth?: boolean }>(({ fullWidth, theme }) => ({
    padding: '0px 24px',
    width: fullWidth ? '100%' : '224px',
    height: '88px',
    maxWidth: '100%',
    background: DesignTokens.color.backgroundSecondary,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('tablet')]: {
        padding: 0,
        height: 'auto',
        width: 'auto',
        marginLeft: 'auto',
        borderRadius: 0,
        textAlign: 'right',
    },
    [theme.breakpoints.down('mobile')]: {
        background: DesignTokens.color.backgroundPrimary,
    },
    [theme.breakpoints.down('mobile')]: {
        height: '80px',
        background: DesignTokens.color.backgroundPrimary,
        borderRadius: DesignTokens.measurement.borderRadiusM,
        padding: '12px 20px',
        textAlign: 'left',
        marginLeft: 0,
    },
}));

const SkeletonBlock = styled(Skeleton)(({ theme }) => ({
    width: '160px',
    height: '29px',
    [theme.breakpoints.down('tablet')]: {
        height: '24px',
    },
}));

const TotalDonationText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalBody2B.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody2B.font.lineHeight}px`,
    },
}));

const TotalDonationValue = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalHeadingS1.font.lineHeight}px`,
    },
}));

export type TotalDonationsWidgetViewProps = {
    isLoading: boolean;
    title: string;
    value: string;
    fullWidth?: boolean;
    className?: string;
};

export const TotalDonationsWidgetView: React.FCC<
    TotalDonationsWidgetViewProps
> = ({ isLoading, title, value, fullWidth, className }) => {
    return (
        <TotalDonationsWrapper
            aria-describedby="total-donations-title"
            className={className}
            data-testid="lifetime-donations"
            fullWidth={fullWidth}
            id="lifetime-donations"
        >
            <TotalDonationText
                bold
                noWrap
                color={DesignTokens.color.globalNeutral700}
                data-testid="total-donations-title"
                id="total-donations-title"
                text={title}
                title={title}
                variant="body2"
            />
            {isLoading ? (
                <SkeletonBlock
                    data-testid="total-donations-value-skeleton"
                    variant="rectangular"
                />
            ) : (
                <TotalDonationValue
                    bold
                    color={DesignTokens.color.globalSuccess400}
                    data-testid="total-donations-value"
                    id="total-donations-value"
                    text={value}
                    title={value}
                    variant="heading1S"
                />
            )}
        </TotalDonationsWrapper>
    );
};
