import React from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { OrganizationType } from '../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../consts';
import Card from './card';

export interface CardProfileProps {
    onCustomizeClick?: () => void;
    requiredPartIsComplete?: boolean;
    onCloseClick?: () => void;
    customizeProfileStarted?: boolean;
    organizationType: OrganizationType;
}

const CardProfile: React.FCC<CardProfileProps> = ({
    customizeProfileStarted,
    onCloseClick,
    onCustomizeClick,
    requiredPartIsComplete,
    organizationType,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: 'onboardingCards.customizeYourProfile',
    });
    const copy = React.useMemo(
        () => ({
            title: scopedTranslate('v2.title'),
            title2: scopedTranslate('v2.title2'),
            description1: scopedTranslate('v2.description1'),
            description2: scopedTranslate('v2.description2'),
            notStartedButtonText: scopedTranslate('v2.notStartedButtonText'),
            startedButtonText: scopedTranslate('v2.startedButtonText'),
            item1Church: scopedTranslate('v2.item1Church'),
            item1Nonprofit: scopedTranslate('v2.item1Nonprofit'),
            item2: scopedTranslate('v2.item2'),
            item3: scopedTranslate('v2.item3'),
            sampleOrganization: scopedTranslate('sampleOrganization'),
        }),
        [scopedTranslate],
    );

    return (
        <Card
            showSampleOrganization
            closeButtonEventName="Click Onboarding Profile Completed Modal Dismiss_btn"
            descriptionText={
                requiredPartIsComplete ? copy.description2 : copy.description1
            }
            headerText={requiredPartIsComplete ? copy.title2 : copy.title}
            item1Text={
                organizationType === 'church'
                    ? copy.item1Church
                    : copy.item1Nonprofit
            }
            item2Text={copy.item2}
            item3Text={copy.item3}
            onCloseClick={requiredPartIsComplete ? onCloseClick : undefined}
            onCompleteClick={
                !requiredPartIsComplete ? onCustomizeClick : undefined
            }
            organizationType={organizationType}
            pageVisitEventName={
                requiredPartIsComplete
                    ? 'View Onboarding Profile Completed Modal'
                    : 'View Onboarding Set Up Profile Modal'
            }
            showList={!requiredPartIsComplete}
            submitButtonEventName="Click Onboarding Set Up Profile Modal Set up your Profile_btn"
            submitText={
                customizeProfileStarted
                    ? copy.startedButtonText
                    : copy.notStartedButtonText
            }
        />
    );
};

export default CardProfile;
