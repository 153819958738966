import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { GivelifyRadio } from '../radio';
import { GivelifyLabel } from '../label';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        position: 'relative',
        background: 'inherit',
        boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
        borderRadius: 10,
        display: 'flex',
        minHeight: 64,
    },
    radioWrapper: {
        backgroundColor: theme.colors?.neutralHoverGrey,
        width: 46,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    headerContentWrapper: {
        alignSelf: 'center',
        flex: 1,
        margin: theme.spacing('10px', '8px'),
    },
    heading: {
        minHeight: 44,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
}));

export interface GivelifyListTileProps {
    id?: string;
    selected: boolean;
    onSelect: (selected: boolean) => void;
    heading?: string;
    headerContent?: JSX.Element;
}

export const GivelifyListTile: React.FCC<GivelifyListTileProps> = ({
    id,
    selected,
    onSelect,
    heading,
    headerContent,
    children,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div
                className={classes.radioWrapper}
                onClick={() => onSelect(!selected)}
            >
                <GivelifyRadio
                    id={id}
                    checked={selected}
                    labelBold
                    labelPosition="after"
                    ariaLabel="open"
                    name="open"
                    variant="primary"
                />
            </div>
            <div className={classes.headerContentWrapper}>
                {heading && (
                    <div
                        className={classes.heading}
                        onClick={() => onSelect(!selected)}
                    >
                        <GivelifyLabel variant="body2" bold text={heading} />
                    </div>
                )}
                {headerContent && (
                    <div
                        className={classes.heading}
                        onClick={() => onSelect(!selected)}
                    >
                        {headerContent}
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};
