import React from 'react';
import { TRANSACTION_FILTER_VALUE } from '@givelify/api';
import { GivelifyLifetimeBanner } from '@givelify/givelify-ui';
import {
    GivelifyLabel,
    GivelifyLink,
    GivelifyTab,
    GivelifyTabProps,
} from '@givelify/ui';
import { TimeFrameValue } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import AllDonationsTotal from '../../pages/donations/components/AllDonationsTotal';
import { EnvelopesFilter, EnvelopesFilterProps } from './envelopesFilter';
import { MainFilterDrawer } from './MainFilterDrawer';
import { MessageFilter, MessageFilterProps } from './messageFilter';
import {
    DesktopToolbar,
    DesktopToolbarControls,
    Divider,
    MobileToolbar,
    TitleRow,
} from './styles';
import { TimeFrameFilter } from './TimeFrameFilter';
import { TransactionFilter, TransactionFilterProps } from './transactionFilter';

type MainFilterRowProps = {
    title?: string;
    tabProps?: GivelifyTabProps;
    totalBannerProps?: {
        size: 'normal' | 'small';
        position: 'none' | 'title' | 'row';
        mode: 'empty' | 'real';
        topText: string;
        bottomText: string;
    };
    timeFrameFilterProps: {
        value: TimeFrameValue;
        onChange: (timeFrame: TimeFrameValue) => unknown;
    };
    transactionFilterProps?: TransactionFilterProps;
    messageFilterProps?: MessageFilterProps;
    envelopesFilterProps?: EnvelopesFilterProps;
    page: 'activity' | 'bankDeposit' | 'refund';
};

export const MainFilterRow: React.FCC<MainFilterRowProps> = ({
    title,
    tabProps,
    totalBannerProps,
    timeFrameFilterProps,
    envelopesFilterProps,
    messageFilterProps,
    transactionFilterProps,
    page,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);
    const { rowBanner, titleBanner } = React.useMemo(() => {
        if (
            totalBannerProps === undefined ||
            totalBannerProps.position === 'none'
        ) {
            return {
                titleBanner: null,
                rowBanner: null,
            };
        }
        const banner =
            totalBannerProps.mode === 'empty' ||
            (envelopesFilterProps && envelopesFilterProps.disabled) ? (
                <GivelifyLifetimeBanner
                    isDisabled={false}
                    isLoading={
                        envelopesFilterProps && envelopesFilterProps.disabled
                    }
                    leftBottomText={totalBannerProps.bottomText}
                    leftText={totalBannerProps.topText}
                    rightText="$0.00"
                    size={totalBannerProps.size}
                />
            ) : (
                <AllDonationsTotal
                    envelopeIds={
                        envelopesFilterProps?.options.length ===
                        envelopesFilterProps?.values.length
                            ? undefined
                            : envelopesFilterProps?.values
                            ? envelopesFilterProps.values
                            : []
                    }
                    messageFilter={messageFilterProps?.values}
                    pageName={page}
                    size={totalBannerProps.size}
                    timeFrame={timeFrameFilterProps.value}
                    transactionFilter={
                        page === 'refund'
                            ? TRANSACTION_FILTER_VALUE.REFUNDED
                            : transactionFilterProps?.value
                    }
                />
            );
        if (totalBannerProps.position === 'title') {
            return {
                titleBanner: banner,
                rowBanner: null,
            };
        }
        return {
            titleBanner: isMobile ? banner : null,
            rowBanner: banner,
        };
    }, [
        isMobile,
        page,
        envelopesFilterProps,
        messageFilterProps,
        timeFrameFilterProps,
        totalBannerProps,
        transactionFilterProps,
    ]);

    const TitleSection = title ? (
        <TitleRow>
            <GivelifyLabel
                data-testid="donations-title"
                fontSize={36}
                id="page-title"
                text={title}
                variant="heading1"
            />
            {titleBanner}
        </TitleRow>
    ) : null;

    const TabSection = tabProps ? (
        <GivelifyTab {...tabProps} />
    ) : page === 'activity' ? (
        <Divider sx={{ mb: 2 }} />
    ) : null;
    const DesktopToolbarSection = isMobile ? null : (
        <DesktopToolbar>
            <DesktopToolbarControls>
                <TimeFrameFilter
                    isMobile
                    onChange={timeFrameFilterProps.onChange}
                    value={timeFrameFilterProps.value}
                />
                {envelopesFilterProps ? (
                    <EnvelopesFilter {...envelopesFilterProps} />
                ) : null}
                {messageFilterProps ? (
                    <MessageFilter {...messageFilterProps} />
                ) : null}
                {transactionFilterProps ? (
                    <TransactionFilter {...transactionFilterProps} />
                ) : null}
            </DesktopToolbarControls>
            {rowBanner}
        </DesktopToolbar>
    );

    const MobileToolbarSection = isMobile ? (
        <MobileToolbar>
            {timeFrameFilterProps ? (
                <TimeFrameFilter
                    isMobile
                    onChange={timeFrameFilterProps.onChange}
                    value={timeFrameFilterProps.value}
                />
            ) : null}
            <GivelifyLink
                fontSize={16}
                onClick={() => setOpenFilterDrawer(true)}
                text={'Filter'}
            />
            <MainFilterDrawer
                envelopesFilterProps={envelopesFilterProps}
                messageFilterProps={messageFilterProps}
                onClose={() => setOpenFilterDrawer(false)}
                onOpen={() => setOpenFilterDrawer(true)}
                open={openFilterDrawer}
                transactionFilterProps={transactionFilterProps}
            />
        </MobileToolbar>
    ) : null;

    return (
        <div>
            {TitleSection}
            {TabSection}
            {DesktopToolbarSection}
            {MobileToolbarSection}
        </div>
    );
};
