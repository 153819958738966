import React from 'react';
import { Grid, styled } from '@mui/material';
import { GivelifyButton } from '../button';
import { DesignTokens } from '../specify';

const PollAnswer = styled(GivelifyButton)(({ theme }) => ({
    boxShadow: DesignTokens.shadow.shadowMediumHigh,
    color: DesignTokens.color.textPrimary,
    background: DesignTokens.color.backgroundButtonSecondaryDefault,
    border: 'none',
    fontSize: '16px',
    fontWeight: 600,
    height: '40px',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        fontWeight: 600,
    },
    '&:hover': {
        background: DesignTokens.color.backgroundComponentPrimaryHover,
        color: DesignTokens.color.textPrimary,
        border: 'none',
    },
}));

type GivelifyPollActionsProps = {
    answers: string[];
    onAnswer: (answerId: number) => void;
};

export const GivelifyPollActions: React.FCC<GivelifyPollActionsProps> = ({
    answers,
    onAnswer,
}) => {
    return (
        <Grid container className="answers" justifyContent="center">
            {answers.map((answer, index) => (
                <Grid key={answer} item md={6} sm={6} xs={12}>
                    <PollAnswer
                        disableRipple
                        fullWidth
                        onClick={() => onAnswer(index)}
                        text={answer}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
