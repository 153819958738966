import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { homeTabStyle } from '../style';

interface IntegrationStatsCardProps {
    title: string;
    type: 'donor' | 'envelope';
    unMatchNumber: number;
    viewUnMatch: () => unknown;
    viewMatch: () => unknown;
    totalMatched: number;
    loading: boolean;
    syncStatus: boolean;
}

const IntegrationStatsCard: React.FCC<IntegrationStatsCardProps> = ({
    title,
    type,
    unMatchNumber,
    viewUnMatch,
    viewMatch,
    totalMatched,
    loading,
    syncStatus,
}) => {
    const classes = homeTabStyle();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            matchDonors: t('autoIntegrationPage.matchDonors'),
            matchEnvelopes: t('autoIntegrationPage.matchEnvelopes'),
            matched: t('f1IntegrationPage.matched'),
            typeHave: t('autoIntegrationPage.typeHave', {
                type:
                    type === 'donor'
                        ? t('f1IntegrationPage.donorTab')
                        : t('f1IntegrationPage.envelopeTab'),
            }),
            notBeenMatched: t('autoIntegrationPage.notBeenMatched'),
        }),
        [t, type],
    );

    if (loading || !syncStatus) {
        return (
            <div className={classes.homeItemContent}>
                <GivelifyLabel text={title} variant="heading3" />
                <div className={classes.matchedContent}>
                    <GivelifyIcon spin marginBottom={20} variant="loading" />
                    <GivelifyLabel
                        className={classes.matchedTitle}
                        text={copy.matched}
                        variant="heading2"
                    />
                    <div className={classes.totalMatched}>
                        <div className={classes.totalUnmatchedText}>
                            <GivelifyLabel
                                className={classes.matchedNumber}
                                text="-"
                                variant="body1"
                            />
                            <GivelifyLabel
                                className={classes.unMatchedText}
                                text={copy.typeHave}
                                variant="body1"
                            />
                            <GivelifyButton
                                className={classes.unMatchedText}
                                onClick={viewUnMatch}
                                text={copy.notBeenMatched}
                                variant="ghost"
                            />
                        </div>
                    </div>
                    <div className={classes.viewUnMatch}>
                        <GivelifyButton
                            className={classes.centerButton}
                            disabled={loading || !syncStatus}
                            name={
                                type === 'donor'
                                    ? 'viewUnmatchedDonor'
                                    : 'viewUnmatchedEnvelope'
                            }
                            onClick={viewUnMatch}
                            size="xLarge"
                            text={
                                type === 'donor'
                                    ? copy.matchDonors
                                    : copy.matchEnvelopes
                            }
                            variant="primary"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.homeItemContent}>
            <GivelifyLabel text={title} variant="heading3" />
            <div className={classes.matchedContent}>
                <GivelifyLabel
                    className={classes.matchNumber}
                    text={totalMatched}
                    variant="heading1"
                />
                <GivelifyLabel
                    className={classes.matchedTitle}
                    text={copy.matched}
                    variant="heading2"
                />
                <div className={classes.totalMatched}>
                    <div className={classes.totalUnmatchedText}>
                        <GivelifyLabel
                            className={classes.matchedNumber}
                            text={unMatchNumber}
                            variant="body1"
                        />
                        <GivelifyLabel
                            className={classes.unMatchedText}
                            text={copy.typeHave}
                            variant="body1"
                        />
                        <GivelifyButton
                            className={classes.tabLink}
                            onClick={viewUnMatch}
                            text={copy.notBeenMatched}
                            variant="ghost"
                        />
                    </div>
                </div>
                <div className={classes.viewUnMatch}>
                    <GivelifyButton
                        className={classes.centerButton}
                        name={
                            type === 'donor'
                                ? 'viewUnmatchedDonor'
                                : 'viewUnmatchedEnvelope'
                        }
                        onClick={viewUnMatch}
                        size="xLarge"
                        text={
                            type === 'donor'
                                ? copy.matchDonors
                                : copy.matchEnvelopes
                        }
                        variant="primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default IntegrationStatsCard;
