import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyTextField,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { Theme, makeStyles } from '@material-ui/core';
import { FaithLeaderEditorInfo } from '../../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../../consts';
import { FaithLeaderValidationResult } from './types';

interface FaithLeaderEditorProps {
    faithLeader: FaithLeaderEditorInfo | undefined;
    onChangeFaithLeader: (
        faithLeader: FaithLeaderEditorInfo | undefined,
    ) => unknown;
    addNewFaithLeader: boolean;
    validationResult: FaithLeaderValidationResult;
}

const useStyles = makeStyles((theme: Theme) => ({
    formStyles: {
        display: 'grid',
        gap: 16,
        marginTop: 6,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            '& > :nth-child(3)': {
                gridColumn: '1 / 3',
            },
        },
    },
}));

const FaithLeaderEditor: React.FCC<FaithLeaderEditorProps> = ({
    faithLeader,
    onChangeFaithLeader,
    addNewFaithLeader,
    validationResult,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: 'customizeProfile.faithLeaderSection.editor',
    });
    const { formStyles } = useStyles();

    const [firstNameInternal, setFirstNameInternal] = useState('');
    const [lastNameInternal, setLastNameInternal] = useState('');
    const [titleInternal, setTitleInternal] = useState('');

    const [firstNameIsDirty, setFirstNameIsDirty] = useState(false);
    const [lastNameIsDirty, setLastNameIsDirty] = useState(false);
    const [titleIsDirty, setTitleIsDirty] = useState(false);

    useEffect(() => {
        if (addNewFaithLeader) {
            setFirstNameInternal('');
            setLastNameInternal('');
            setTitleInternal('');
            setFirstNameIsDirty(false);
            setLastNameIsDirty(false);
            setTitleIsDirty(false);
        }
    }, [addNewFaithLeader]);

    const {
        firstNameText,
        lastNameText,
        titleText,
        invalidFirstName,
        invalidLastName,
        invalidTitle,
    } = useMemo(
        () => ({
            firstNameText: scopedTranslate('firstName'),
            lastNameText: scopedTranslate('lastName'),
            titleText: scopedTranslate('title'),
            invalidFirstName: scopedTranslate('invalidFirstName'),
            invalidLastName: scopedTranslate('invalidLastName'),
            invalidTitle: scopedTranslate('invalidTitle'),
        }),
        [scopedTranslate],
    );

    const onFirstNameChange = (_e: unknown, firstName: string) => {
        setFirstNameInternal(firstName);
        setFirstNameIsDirty(true);
        onChangeFaithLeader({
            userId: faithLeader?.userId || 0,
            firstName,
            lastName: faithLeader?.lastName || '',
            title: faithLeader?.title || '',
            avatar: faithLeader?.avatar || '',
            avatarCroppedCoordinates:
                faithLeader?.avatarCroppedCoordinates || '',
            avatarOriginal: faithLeader?.avatarOriginal || '',
        });
    };
    const onLastNameChange = (_e: unknown, lastName: string) => {
        setLastNameInternal(lastName);
        setLastNameIsDirty(true);
        onChangeFaithLeader({
            userId: faithLeader?.userId || 0,
            firstName: faithLeader?.firstName || '',
            lastName,
            title: faithLeader?.title || '',
            avatar: faithLeader?.avatar || '',
            avatarCroppedCoordinates:
                faithLeader?.avatarCroppedCoordinates || '',
            avatarOriginal: faithLeader?.avatarOriginal || '',
        });
    };

    const onTitleChange = (_e: unknown, title: string) => {
        setTitleInternal(title);
        setTitleIsDirty(true);
        onChangeFaithLeader({
            userId: faithLeader?.userId || 0,
            firstName: faithLeader?.firstName || '',
            lastName: faithLeader?.lastName || '',
            title,
            avatar: faithLeader?.avatar || '',
            avatarCroppedCoordinates:
                faithLeader?.avatarCroppedCoordinates || '',
            avatarOriginal: faithLeader?.avatarOriginal || '',
        });
    };

    const firstNameError =
        firstNameIsDirty && validationResult.errors?.firstName;
    const lastNameError = lastNameIsDirty && validationResult.errors?.lastName;
    const titleError = titleIsDirty && validationResult.errors?.title;

    return (
        <div className={formStyles}>
            <GivelifyTextField
                ariaLabel={firstNameText}
                id="first-name"
                label={firstNameText}
                leftHelperText={firstNameError ? invalidFirstName : undefined}
                onChange={onFirstNameChange}
                placeholder={firstNameText}
                state={firstNameError ? 'error' : 'normal'}
                value={firstNameInternal}
            />
            <GivelifyTextField
                ariaLabel={lastNameText}
                id="last-name"
                label={lastNameText}
                leftHelperText={lastNameError ? invalidLastName : undefined}
                onChange={onLastNameChange}
                placeholder={lastNameText}
                state={lastNameError ? 'error' : 'normal'}
                value={lastNameInternal}
            />
            <GivelifyTextField
                ariaLabel={titleText}
                id="title"
                label={titleText}
                leftHelperText={titleError ? invalidTitle : undefined}
                onChange={onTitleChange}
                placeholder={titleText}
                state={titleError ? 'error' : 'normal'}
                value={titleInternal}
            />
        </div>
    );
};

export default FaithLeaderEditor;
