import React, { useMemo } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { addPhoneNumberFormatting } from '../../../../../../editProfile/components/EditProfileForm';
import { unMatchDonorTabStyle } from '../style';

interface F1CCBPotentialMatchDonorRowProps {
    donorId: number;
    matchPercent: number;
    f1FirstName: string;
    f1LastName: string;
    f1DonorId: number;
    f1DonorEmail: string;
    f1DonorPhone: string;
    f1DonorAddress: string;
    matchDonor: (donorId: number, chmsDonorId: number) => void;
    isReadOnly: boolean;
}

const AutoIntegrationPotentialMatchDonorRow: React.FCC<
    F1CCBPotentialMatchDonorRowProps
> = ({
    donorId,
    matchPercent,
    f1FirstName,
    f1LastName,
    f1DonorId,
    f1DonorEmail,
    f1DonorPhone,
    f1DonorAddress,
    matchDonor,
    isReadOnly,
}) => {
    const classes = unMatchDonorTabStyle();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            confirm: t('f1DonorPage.confirm'),
            strongMatch: t('f1DonorPage.strongMatch'),
            partialMatch: t('f1DonorPage.partialMatch'),
            weakMatch: t('f1DonorPage.weakMatch'),
        }),
        [t],
    );

    const onMatchDonor = () => {
        matchDonor(donorId, f1DonorId);
    };

    return (
        <Grid container alignItems="center" className={classes.f1DonorInfo}>
            <Grid item className={classes.rowItem} md={1} xs={2}>
                <GivelifyLabel
                    className={`${classes.italicText} ${
                        matchPercent >= 90
                            ? classes.strongMatch
                            : matchPercent >= 75 && matchPercent < 90
                            ? classes.partialMatch
                            : classes.weakMatch
                    }`}
                    text={
                        matchPercent >= 90
                            ? copy.strongMatch
                            : matchPercent >= 75 && matchPercent < 90
                            ? copy.partialMatch
                            : copy.weakMatch
                    }
                    variant="body3"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={5}>
                <GivelifyLabel
                    bold
                    className={classes.textOverflow}
                    text={f1FirstName + ' ' + f1LastName}
                    variant="body2"
                />
                <GivelifyLabel text={f1DonorId} variant="body2" />
            </Grid>
            <Grid item className={classes.rowItem} md={3} xs={5}>
                <GivelifyLabel
                    className={classes.textOverflow}
                    text={f1DonorEmail}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={6}>
                <GivelifyLabel
                    text={addPhoneNumberFormatting(f1DonorPhone)}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={6}>
                <GivelifyLabel
                    className={classes.donorAddress}
                    text={f1DonorAddress}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={12}>
                <GivelifyButton
                    disabled={isReadOnly}
                    name="confirm"
                    onClick={onMatchDonor}
                    text={copy.confirm}
                />
            </Grid>
        </Grid>
    );
};

export default AutoIntegrationPotentialMatchDonorRow;
