import React from 'react';
import { Hidden } from '@mui/material';

import InstagramLinkArrow from '../../../assets/social-media-preview/instagram-link-arrow.svg';
import InstagramBio from '../../../assets/social-media-preview/instagram.png';

import Label from '../../../components/Label';

import { useTranslations } from '../../../utils/translations';
import { useStyle } from './style';

const Preview: React.FCC = () => {
    const cs = useStyle();

    const { preview } = useTranslations('socialMediaGiving.instagram.page', {
        preview: ['title', 'linkDesc'],
    });

    return (
        <div className={cs.previewContainer}>
            <div>
                <Label className={cs.previewTitle} variant="body3">
                    {preview.title}
                </Label>
                <img
                    alt="instagram"
                    className={cs.previewImage}
                    src={InstagramBio}
                    title="instagram"
                />
            </div>

            <div className={cs.previewArrowsContainer}>
                <Hidden xsDown>
                    <Label
                        className={cs.previewArrowText + ' ' + cs.linkArrowText}
                        variant="body3"
                    >
                        {preview.linkDesc}
                    </Label>
                    <img
                        alt={preview.linkDesc}
                        className={cs.linkArrow}
                        src={InstagramLinkArrow}
                        title="indicator arrow"
                    />
                </Hidden>
            </div>
        </div>
    );
};

export default Preview;
