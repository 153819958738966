import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyProgressBar } from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { Link } from '@material-ui/core';
import dayjs from 'dayjs';
import { EVENTS } from '../utils/clevertap';
import { usdFormatter } from '../utils/currency';
import { useTranslation } from '../utils/i18n';
import { givelithonEnvelopeStyle } from './style';

export interface EnvelopeProps {
    id: number;
    doneeId: number;
    name: string;
    amount: number;
    active: boolean;
    goal: number | null;
    time?: string;
    description?: string;
    fundId?: string;
    startDate?: string | '0000-00-00';
    endDate?: string | '0000-00-00';
    howLong?: 'Always' | 'Timed';
}

export interface GivelithonEnvelopeProps extends EnvelopeProps {
    doneeName: string;
    linkToGivelithon: (envelopeId: number) => void;
    linkToEditGivelithon: (envelopeId: number) => void;
}

const formatDate = (date: string) => {
    if (!dayjs(date).isValid()) {
        return ' ';
    }
    return dayjs(date).format('MMM DD, YYYY');
};

export const GivelithonEnvelope: React.FCC<GivelithonEnvelopeProps> = (
    props,
) => {
    const classes = givelithonEnvelopeStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            availableUntil: t('givelithonPage.availableUntil'),
            availableByDefault: t('givelithonPage.availableByDefault'),
            availableAlways: t('givelithonPage.availableAlways'),
            launch: t('givelithonButton.launch'),
            edit: t('givelithonButton.edit'),
        }),
        [t],
    );
    const { trackEvent } = useTrackingContext();

    const available =
        props.howLong === 'Timed' && props.endDate
            ? copy.availableUntil + ' ' + formatDate(props.endDate)
            : props.doneeId === 0
            ? copy.availableByDefault
            : copy.availableAlways;

    let percent;
    if (props.goal && props.amount) {
        const percentValue = (props.amount / props.goal) * 100;
        if (percentValue % 1 === 0) {
            percent = percentValue as number;
        } else {
            percent = parseFloat(percentValue.toFixed(0));
        }
    }

    const goToGivelithon = () => {
        trackEvent(EVENTS.OPEN_GIVELITHON_LAUNCH, {
            envelopeId: props.id,
        });
        props.linkToGivelithon(props.id);
    };

    // const goToEditGivelithon = () => {
    //     props.linkToEditGivelithon(props.id);
    // };

    return (
        <div
            className={classes.givelithonEnvelope}
            data-testid={`givelithon-envelope-${props.id}`}
        >
            <div
                className={classes.envelopeName}
                id={`givelithon-env-info-${props.id}`}
            >
                <GivelifyLabel
                    bold
                    wordBreak
                    dataTestId={`givelithon-env-name-${props.id}`}
                    id={`givelithon-env-name-${props.id}`}
                    text={props.name}
                    variant="body1"
                />
                <GivelifyLabel
                    dataTestId={`givelithon-env-available-${props.id}`}
                    id={`givelithon-env-available-${props.id}`}
                    text={available}
                    variant="body2"
                />
            </div>
            <div
                className={classes.envelopeGoal}
                id={`givelithon-goal-${props.id}`}
            >
                {props.goal ? (
                    <GivelifyProgressBar
                        goal={props.goal}
                        id={props.id}
                        percentigeText={
                            percent ? percent.toString() + '%' : '0%'
                        }
                        value={percent ? percent : 0}
                        valueText={
                            props.amount && props.amount > 0
                                ? usdFormatter.format(props.amount)
                                : '$0'
                        }
                    />
                ) : (
                    <div />
                )}
            </div>
            <div className={classes.envelopeGroupButton}>
                <Link
                    className={classes.launchGivelithonBtn}
                    data-testid={`open-launch-${props.id}`}
                    id={`open-launch-${props.id}`}
                    onClick={goToGivelithon}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {copy.launch}
                </Link>
                {/* Disabled for now at least until https://givelify15.atlassian.net/browse/DTZ-308 is done */}
                {/* <GivelifyButton
                    variant="ghost"
                    text={copy.edit}
                    startIconVariant="edit"
                    onClick={goToEditGivelithon}
                    name="navigateToEditEnvelopeModal"
                /> */}
            </div>
        </div>
    );
};
