import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import DonationsHeaderRow from '../RefundHeaderRow';
import useStyles from '../styles';

const DonationRowLoading: React.FCC = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.firstRowWrapper}>
                <div className={classes.column2}>
                    <FacebookLoading
                        borderRadius={4}
                        height={22}
                        marginBottom={4}
                        width={170}
                    />
                </div>
                <div className={classes.column5}>
                    <FacebookLoading borderRadius={4} height={22} width={98} />
                </div>
                <div className={classes.column1}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                        }}
                    >
                        <FacebookLoading
                            borderRadius={4}
                            height={22}
                            width={92}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.secondRowWrapper}>
                <FacebookLoading borderRadius={4} height={24} width={150} />
            </div>
        </div>
    );
};

const DonationsRowLoading: React.FCC = () => {
    return (
        <>
            <DonationsHeaderRow />
            <DonationRowLoading />
            <DonationRowLoading />
            <DonationRowLoading />
        </>
    );
};

export default DonationsRowLoading;
