import React from 'react';
import { CaptivePortal } from '@givelify/onboarding';
import PageTitle from 'components/PageTitle';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

const CaptivePortalPage = () => {
    const { donee, user, yodleeEnabled } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
        user: state.User.user,
        yodleeEnabled: state.System.yodleeEnabled,
    }));

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();

    return (
        <>
            <PageTitle
                description="Givelify Dashboard"
                title="Givelify Dashboard"
            />
            <CaptivePortal
                donee={donee}
                setButtonBringToFront={setButtonBringToFront}
                showYodlee={yodleeEnabled}
                user={user}
            />
        </>
    );
};

export default CaptivePortalPage;
