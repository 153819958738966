import React, { useEffect, useState } from 'react';
import { useTrackingContext } from '@givelify/utils';
import { Grid, styled, useTheme, useMediaQuery } from '@mui/material';
import { GivelifyButton } from '../button';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import { GivelifyPaper } from '../paper';
import { DesignTokens } from '../specify';
import { GivelifyPollActions } from './GivelifyPollActions';

export type GivelifyPollProps = {
    poll: {
        question: string;
        answers: string[];
        userAnswer?: number;
    };
    text: {
        title: string;
        thankYouMessage: string;
        closePoll: string;
    };
    onAnswer?: (answerId: number) => void;
    onClose?: () => void;
};

const PollWrapper = styled(GivelifyPaper)(({ theme }) => ({
    boxShadow: DesignTokens.shadow.shadowMediumHigh,
    borderRadius: '10px',
    backgroundColor: DesignTokens.color.backgroundComponentHighlight,
    padding: theme.spacing(3),
    position: 'relative',
    '& .icon': {
        fontSize: '56px',
        marginRight: '24px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '32px',
            marginRight: '12px',
        },
    },
    '& .answers': {
        marginTop: theme.spacing(4),
        '& .MuiGrid-item': {
            padding: '8px',
        },
        [theme.breakpoints.down('mobile')]: {
            marginTop: theme.spacing(3),
            '& .MuiGrid-item': {
                padding: '6px',
            },
        },
    },
    '& .answered': {
        [theme.breakpoints.down('mobile')]: {
            marginBottom: theme.spacing(4),
        },
    },
    '& .head': {
        fontSize: '14px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '12px',
        },
    },
    '& .question': {
        fontSize: DesignTokens.measurement.fontDefaultSize,
        lineHeight: '32px',
        fontWeight: 700,
        color: DesignTokens.color.textPrimary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
        },
    },
    '& .pollActions': {
        marginLeft: '80px',
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 0,
        },
    },
    '& .close': {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        bottom: 'unset',
        fontSize: '16px',
        [theme.breakpoints.down('mobile')]: {
            top: 'unset',
            bottom: '16px',
            fontSize: '14px',
        },
    },
}));

export const GivelifyPoll: React.FCC<GivelifyPollProps> = ({
    poll,
    onAnswer,
    onClose,
    text,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [userInput, setUserInput] = useState<number | null>(null);
    const { trackEvent } = useTrackingContext();
    const answerVariants = ['A', 'B', 'C', 'D'];

    const handleAnswer = (answerId: number) => {
        setUserInput(answerId);

        trackEvent(`Click_poll_answer_${answerVariants[answerId]}`);

        if (onAnswer) {
            onAnswer(answerId);
        }
    };

    useEffect(() => {
        trackEvent('View Overview Page Poll MVP');
        // run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PollWrapper>
            <Grid container>
                <Grid item>
                    <GivelifyIcon className="icon" variant="poll" />
                </Grid>
                <Grid
                    item
                    className={userInput !== null ? 'answered' : ''}
                    xs={10}
                >
                    <GivelifyLabel
                        className="head"
                        color="navigationTextDefault"
                        fontWeight={800}
                        text={text.title}
                        variant="body2"
                    />
                    <GivelifyLabel
                        className="question"
                        text={
                            userInput === null
                                ? poll.question
                                : text.thankYouMessage
                        }
                    />
                </Grid>
            </Grid>
            {userInput === null ? (
                <Grid container>
                    <Grid item className="pollActions" xs={12}>
                        <GivelifyPollActions
                            answers={poll.answers}
                            onAnswer={handleAnswer}
                        />
                    </Grid>
                </Grid>
            ) : isMobile ? (
                <GivelifyButton
                    className="close"
                    onClick={() => onClose?.()}
                    text={text.closePoll}
                    variant="ghost"
                />
            ) : (
                <GivelifyButton
                    children={<GivelifyIcon variant="close" />}
                    className="close"
                    onClick={() => onClose?.()}
                    text={'test123'}
                    variant="icon"
                />
            )}
        </PollWrapper>
    );
};
