import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    GivelifyLabel,
    GivelifyButton,
    SortDirection,
} from '@givelify/givelify-ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useApiRequest } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { Report } from '../../../store/report/types';
import { setHideContinueOnMobileScreen } from '../../../store/user/actions';
import {
    initialPaginationCamelCase,
    PaginatedResponse,
    PaginationCamelCase,
} from '../../../types/paginationTypes';
import ContinueOnDesktop from '../../integrations/ContinueOnMobile';
import ReportsPageHeader from '../components/ReportsPageHeader';
import { reportHistoryStyle } from '../components/style';
import ReportHistoryNoResult from './ReportHistoryNoResult';
import ReportHistoryContent from './ReportHistoryTable';
import { getReportHistory } from './request';

const desktopPageSize = '8';
const mobilePageSize = '6';

const ReportsHistory: React.FCC = () => {
    const { t } = useTranslation();
    const classes = reportHistoryStyle();
    const { doneeId, hideContinueOnMobileScreen } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            hideContinueOnMobileScreen: state.User.hideContinueOnMobileScreen,
        }),
    );
    const [dataReports, setDataReports] = useState<Report[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const pageSize = isMobile ? mobilePageSize : desktopPageSize;
    const ReportHistoryText = useMemo(
        () => ({
            title: t('reports.title'),
            reports: t('reports.reports'),
            history: t('reports.history'),
            historyTitle: t('reportHistory.historyTitle'),
            historyDefine: t('reportHistory.historyDefine'),
            noReport: t('reportHistory.noReport'),
            noReportDefine: t('reportHistory.noReportDefine'),
            goReport: t('reportHistory.goReport'),
            reportHistoryContinue: t('reportHistory.continue'),
            refresh: t('reportHistory.refresh'),
        }),
        [t],
    );

    const handleChangePage = (pageNumber: number) => setPageNumber(pageNumber);

    const [reportsRequestState, getReportHistoryRequest] =
        useApiRequest<PaginatedResponse<Report>>();

    const [pagination, setPagination] = useState<PaginationCamelCase>({
        ...initialPaginationCamelCase,
        perPage: pageSize,
        currentPage: 1,
    });

    const [sorting, setSorting] = useState<{
        columnName: keyof Report;
        direction: SortDirection;
    }>({
        columnName: 'createdAt',
        direction: 'desc',
    });

    const getHistoryData = useCallback(() => {
        getReportHistoryRequest(
            getReportHistory(doneeId, pageSize, pageNumber),
        );
    }, [getReportHistoryRequest, doneeId, pageSize, pageNumber]);

    useEffect(() => {
        getHistoryData();
    }, [getHistoryData]);

    useEffect(() => {
        if (reportsRequestState.type !== 'REQUEST_SUCCESS') return;

        const data = reportsRequestState.response.data;
        const orderedReports = orderBy(
            data,
            [sorting.columnName],
            [sorting.direction],
        );

        setDataReports(orderedReports);
        setPagination(
            reportsRequestState.response.pagination as PaginationCamelCase,
        );
    }, [reportsRequestState, sorting]);

    if (isMobile && !hideContinueOnMobileScreen)
        return (
            <ContinueOnDesktop
                onContinueClick={() => {
                    dispatch(setHideContinueOnMobileScreen());
                }}
            />
        );

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.ReportsHistory}>
            <ReportsPageHeader page="ReportsHistory" />
            <div className={classes.reportHistoryHeader}>
                <GivelifyLabel
                    text={ReportHistoryText.historyDefine}
                    variant={isMobile ? 'body2' : 'body1'}
                />
                <GivelifyButton
                    className={classes.refreshButton}
                    name="Refresh"
                    onClick={getHistoryData}
                    startIconVariant="redo"
                    text={ReportHistoryText.refresh}
                    variant="ghost"
                />
            </div>

            {(reportsRequestState.type === 'REQUEST_SUCCESS' &&
                reportsRequestState.response.data.length === 0) ||
            reportsRequestState.type === 'REQUEST_ERROR' ? (
                <ReportHistoryNoResult />
            ) : (
                <ReportHistoryContent
                    currentPage={pagination.currentPage - 1}
                    data={dataReports}
                    isLoading={reportsRequestState.type !== 'REQUEST_SUCCESS'}
                    onChangePage={handleChangePage}
                    onSortingChange={(column, direction) =>
                        setSorting({
                            columnName: column,
                            direction,
                        })
                    }
                    rowsPerPage={parseInt(pagination.perPage)}
                    sorting={sorting}
                    totalItems={pagination.total}
                />
            )}
        </TrackingProvider>
    );
};

export default ReportsHistory;
