import { MESSAGE_FILTER_VALUE } from '../models';

export const getMessageFilterQuery = (
    values?: MESSAGE_FILTER_VALUE[],
): string => {
    if (!values || values.length === 0) return '';
    return values
        .map((v) => {
            switch (v) {
                case MESSAGE_FILTER_VALUE.NO_MESSAGES:
                    return '&no_messages=1';
                case MESSAGE_FILTER_VALUE.REPLIED:
                    return '&replied_messages=1';
                case MESSAGE_FILTER_VALUE.UN_REPLIED:
                    return '&un_replied_messages=1';
                default:
                    return '';
            }
        })
        .join('');
};
