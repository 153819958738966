import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgShield = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="28"
        viewBox="0 0 24 28"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M18.1561 7.02063L19.4951 7.62325C19.4951 7.62325 19.5 11.2516 19.5 13.0691C19.5 14.8865 18.6945 17.0675 16.9542 18.885C15.7892 20.1016 14.6434 20.7302 13.5584 21.3255C13.0224 21.6195 12.5013 21.9054 12 22.25C11.4987 21.9054 10.9776 21.6195 10.4417 21.3255L10.4417 21.3255C9.35665 20.7302 8.21092 20.1015 7.04584 18.885C5.3053 17.0675 4.5 14.8865 4.5 13.0691C4.5 11.2516 4.5049 7.62325 4.5049 7.62325L5.84388 7.02063C9.75885 5.25867 14.2412 5.25867 18.1561 7.02063ZM3.88928 6.2554C3.35171 6.49734 3.00569 7.03172 3.0049 7.62123L3.00245 9.66299C3.00123 10.7972 3 12.1594 3 13.0691C3 15.3047 3.97724 17.8495 5.9625 19.9225C6.96237 20.9665 7.94888 21.6288 8.85265 22.1547C9.21151 22.3635 9.52273 22.5337 9.80804 22.6897L9.80807 22.6897L9.80808 22.6897C10.2949 22.956 10.7063 23.1809 11.1503 23.4861C11.6621 23.838 12.3379 23.838 12.8497 23.4861C13.2937 23.1809 13.7051 22.956 14.1919 22.6898C14.4773 22.5337 14.7885 22.3635 15.1474 22.1547C16.0512 21.6288 17.0378 20.9665 18.0376 19.9224C20.0225 17.8495 21 15.3048 21 13.0691C21 12.1594 20.9988 10.7972 20.9976 9.66299L20.9951 7.62123C20.9943 7.03172 20.6483 6.49733 20.1107 6.2554L18.7717 5.65278C14.4653 3.71462 9.53474 3.71462 5.22827 5.65278L3.88928 6.2554ZM10.6583 16.0659C10.7931 16.189 10.9648 16.2499 11.1365 16.2499C11.3183 16.2499 11.4995 16.1819 11.6365 16.0458L15.5503 12.157C15.8204 11.8886 15.816 11.4573 15.5402 11.1944C15.2645 10.9314 14.8216 10.9357 14.5514 11.2042L11.1158 14.6176L9.42736 13.0767C9.14548 12.8197 8.7031 12.8333 8.43912 13.1077C8.17514 13.3821 8.18912 13.8128 8.471 14.0698L10.6583 16.0659Z"
            fill="#5F47F6"
            fillRule="evenodd"
        />
    </svg>
);

export const ShieldIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgShield}
        fontSize={fontSize}
    />
);
