import React from 'react';
import { OnboardingContainer } from '../OnboardingContainer';
import { OnboardingProps } from '../OnboardingProps';

export const OnboardingV2: React.FCC<OnboardingProps> = React.memo((props) => {
    if (props.donee.onboarding.hasMid && !props.startedOnboardingV2)
        return null;

    if (props.hideVerified && props.hideProfile) return null;

    return <OnboardingContainer {...props} />;
});

OnboardingV2.displayName = 'OnboardingV2';
