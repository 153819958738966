import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FacebookLoading } from '@givelify/givelify-ui';

export const TableLoadingComponent = (
    <>
        <FacebookLoading borderRadius={0} height={80} width="100%" />
        <hr style={{ margin: 0 }} />
        <FacebookLoading borderRadius={0} height={80} width="100%" />
        <hr style={{ margin: 0 }} />
        <FacebookLoading borderRadius={0} height={80} width="100%" />
    </>
);

export const TableBigRowLoadingComponent = (
    <>
        <FacebookLoading borderRadius={0} height={131} width="100%" />
        <hr style={{ margin: 0 }} />
        <FacebookLoading borderRadius={0} height={131} width="100%" />
        <hr style={{ margin: 0 }} />
        <FacebookLoading borderRadius={0} height={131} width="100%" />
    </>
);

export const CarotIcon: React.FCC = () => (
    <FontAwesomeIcon
        className="material-icons MuiSelect-icon"
        icon={faChevronDown}
    />
);
