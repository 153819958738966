/* eslint-disable-next-line */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
    requestInit,
    RequestState,
    TimeFrameValues,
    toISODate,
    useInvokeApi,
} from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { mapDonorResponseDataToDonor } from 'pages/integrations/IntegrationContent/TabsContent/DonorMatching/types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import {
    DonationsResponse,
    DonationsResponseMeta,
} from '../../types/donationTypes';
import {
    Donor,
    DonorResponse,
    mapDonationsResponseDataToDonation,
} from '../../types/donorTypes';
import {
    initialPaginationCamelCase,
    PaginationCamelCase,
} from '../../types/paginationTypes';
import { decryptString } from '../../utils/hashUtils';
import { Donation } from './DonorProfile';

// TODO: Re-write this whole DonorProfileContext in terms of Redux
// its awfully written as it does not account for the usage of different states of the API request
// Such as, error with status code of 401 vs 404 vs 500
const mapResponseToDonor = (
    donorsRequestState: RequestState<DonorResponse>,
): RequestState<Donor> => {
    switch (donorsRequestState.type) {
        case 'REQUEST_SUCCESS':
            return {
                type: 'REQUEST_SUCCESS',
                response: mapDonorResponseDataToDonor(
                    donorsRequestState.response.data,
                ),
            };

        default:
            return donorsRequestState;
    }
};

const donationsMetaInitialState: DonationsResponseMeta = {
    count: 0,
    sum: 0,
    average: 0,
};
const donorInitialState: Donor = {
    id: 0,
    givelifyNumber: '',
    externalId: '',
    name: '',
    donorType: {
        id: 4,
        statusType: 'Undefined',
    },
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    picture: '',
    lastDonationAmount: 0,
    lastDonationDate: undefined,
    lifetimeDonations: 0,
};

export interface DonorProfileContextType {
    donor?: Donor;
    setDonor?: (donor: Donor) => void;
    onTimeFrameChange?: (timeFrame: TimeFrameValue) => void;
    donationsMeta?: DonationsResponseMeta;
    doneeId?: number;
    donations?: Donation[];
    isLoadingDonationsPage?: boolean;
    donorProfileRequestState: RequestState<Donor>;
    pageChange?: (page: number) => void;
    donationPagination?: Partial<PaginationCamelCase>;
    timeFrame?: TimeFrameValue;
}

export const DonorProfileContext = createContext<DonorProfileContextType>({
    donorProfileRequestState: requestInit(),
});

const buildDonationsQuery = (doneeId, donorId, startDate, endDate, page) => {
    return `/donees/${doneeId}/donors/${donorId}/donations?startDate=${toISODate(
        startDate,
    )}&endDate=${toISODate(endDate)}&page=${page}`;
};

export const DonorContextProvider: React.FCC = ({ children }) => {
    const [timeFrame, setTimeFrame] = useState<TimeFrameValue>(
        TimeFrameValues.thisYear,
    );
    const onTimeFrameChange = (timeFrame: TimeFrameValue) => {
        setTimeFrame(timeFrame);
    };

    const { encryptedDonorId } = useParams<{ encryptedDonorId: string }>();
    const donorId = decryptString(encryptedDonorId);

    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));

    const [donationPagination, setDonationPagination] = useState<
        Partial<PaginationCamelCase>
    >(initialPaginationCamelCase);

    const [donorsRequestState, getDonor] = useInvokeApi<DonorResponse>();
    const [donationsRequestState, getDonations] =
        useInvokeApi<DonationsResponse>();

    const [donor, setDonor] = useState<Donor>(donorInitialState);
    const [donations, setDonations] = useState<Donation[]>([]);
    const [donationsMeta, setDonationsMeta] = useState<DonationsResponseMeta>(
        donationsMetaInitialState,
    );

    useEffect(() => {
        getDonor('GET', `/donees/${doneeId}/donors/${donorId}`);
    }, [getDonor, doneeId, donorId]);

    useEffect(() => {
        if (donationsRequestState.type === 'REQUEST_SUCCESS') {
            setDonations(
                donationsRequestState.response.data.map((donation) =>
                    mapDonationsResponseDataToDonation(donation),
                ),
            );
            setDonationsMeta(donationsRequestState.response.meta);
            setDonationPagination(donationsRequestState.response.pagination);
        } else if (donationsRequestState.type === 'REQUEST_ERROR') {
            setDonations([]);
            setDonationsMeta(donationsMetaInitialState);
            setDonationPagination(initialPaginationCamelCase);
        }
    }, [donationsRequestState]);
    useEffect(() => {
        if (donorsRequestState.type === 'REQUEST_SUCCESS') {
            setDonor(
                mapDonorResponseDataToDonor(donorsRequestState.response.data),
            );
        } else if (donorsRequestState.type === 'REQUEST_ERROR') {
            setDonor(donorInitialState);
        }
    }, [donorsRequestState]);

    const isLoadingDonationsPage = useMemo(
        () => donationsRequestState.type === 'REQUEST_START',
        [donationsRequestState],
    );

    useEffect(() => {
        getDonations(
            'GET',
            buildDonationsQuery(
                doneeId,
                donorId,
                timeFrame.start,
                timeFrame.end,
                donationPagination.currentPage,
            ),
        );
    }, [
        getDonations,
        doneeId,
        donorId,
        timeFrame,
        donationPagination.currentPage,
    ]);
    const pageChange = (page: number) => {
        setDonationPagination({
            ...donationPagination,
            // eslint-disable-next-line
            currentPage: page + 1,
        });
    };

    const donorProfileRequestState = useMemo(
        () => mapResponseToDonor(donorsRequestState),
        [donorsRequestState],
    );

    return (
        <DonorProfileContext.Provider
            value={{
                onTimeFrameChange,
                donor,
                donationsMeta,
                doneeId,
                donations,
                isLoadingDonationsPage,
                donorProfileRequestState,
                pageChange,
                donationPagination,
                timeFrame,
            }}
        >
            {children}
        </DonorProfileContext.Provider>
    );
};
