import React, { useMemo } from 'react';
import { GivelifyButton, GivelifyIcon } from '@givelify/ui';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EditButton = styled(GivelifyButton)(({ theme }) => ({
    marginLeft: 'auto',
    '& .icon': {
        fontSize: 24,
    },
    //on hover underscore
    '&:hover': {
        textDecoration: 'underline',
    },
    padding: 0,
}));

type EditOptionProps = {
    onClick: () => void;
    text?: string;
    testId?: string;
};

export const EditOption: React.FCC<EditOptionProps> = (
    props: EditOptionProps,
) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            edit: t('labels.edit'),
        }),
        [t],
    );
    return (
        <EditButton
            area-label="Edit"
            classes={{ startIcon: 'icon' }}
            data-testid={props.testId}
            onClick={props.onClick}
            startIcon={<GivelifyIcon variant="edit" />}
            text={props.text ? props.text : copy.edit}
            variant="ghost"
        />
    );
};
