import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import { Envelope } from 'api/models';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';
import EnvelopeListItemIcon from './EnvelopeListItemIcon';
import useStyles from './styles';

const ActiveEnvelopeListItemHandle = ReactSortableHOC.SortableHandle<{
    children: React.ReactNode;
    className: string;
}>(
    (props) => <div {...props} />,
);

const EnvelopeMobileSortItem: React.FCC<Envelope> = (props) => {
    const { active, name } = props;
    const classes = useStyles();

    const { updatePrioritiesRequestState, movedItemId } = useEnvelopesContext();
    const itemIsMoved = movedItemId === props.id;
    const isMoving = updatePrioritiesRequestState === 'REQUEST_START';
    const otherItemIsMoving = !itemIsMoved && isMoving;

    return (
        <div
            aria-label="Active envelope"
            className={mergeClassNames(
                classes.wrapper,
                classes.mobileWrapper,
                active && classes.wrapperActive,
            )}
            test-id={name}
        >
            <ActiveEnvelopeListItemHandle className={classes.dragZoneMobile}>
                <EnvelopeListItemIcon active={active} id={props.id} />
                <div className={classes.titleWrapper}>
                    <GivelifyLabel
                        wrap
                        className={classes.wrap}
                        color={
                            (!active || otherItemIsMoving) &&
                            DesignTokens.color.textDisabled
                        }
                        id={`envelope-name-${props.id}`}
                        text={name}
                        variant="heading3S"
                    />
                </div>
            </ActiveEnvelopeListItemHandle>
        </div>
    );
};

export default EnvelopeMobileSortItem;
