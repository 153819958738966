import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ApiEndpoints } from '../../endpoints';
import { GetCurrentUserResponse } from '../../responses';
import { IUsersService } from '../interfaces';

export class UsersService implements IUsersService {
    public async getCurrentUser(): Promise<
        ApiResponse<GetCurrentUserResponse>
    > {
        const url = ApiEndpoints.users.user();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetCurrentUserResponse>(
            httpRequest,
        );
        return result;
    }
}
