import React from 'react';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { TableColumnProps } from '../../../../../components/Tables/TableColumnTitle';
import { useFilterModalStyle } from '../useFilterModalStyle';

interface OwnProps {
    sortColumn: string;
    tableColumns: TableColumnProps[];
    handleSortColumnChange: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    sortColumnLabel: string;
}

const SortColumnPanel: React.FCC<OwnProps> = ({
    sortColumn,
    tableColumns,
    handleSortColumnChange,
    sortColumnLabel,
}) => {
    const classes = useFilterModalStyle();

    const TableColumnItem = ({ column }: { column: TableColumnProps }) => (
        <FormControlLabel
            className={classes.checkmarkRadio}
            control={
                <Radio
                    checkedIcon={
                        <FontAwesomeIcon
                            className={classes.checkIcon}
                            color="#52545B"
                            icon={faCheck}
                            size="xs"
                        />
                    }
                    icon={
                        <FontAwesomeIcon
                            className={classes.checkIcon}
                            color="transparent"
                            icon={faCheck}
                            size="xs"
                        />
                    }
                />
            }
            label={column.title}
            value={column.name}
        />
    );

    return (
        <ExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
            >
                <div className={classes.expansionTitle}>{sortColumnLabel}</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <RadioGroup
                    className={classes.radioGroup}
                    onChange={handleSortColumnChange}
                    value={sortColumn}
                >
                    {tableColumns
                        .filter((c) => c.sortable)
                        .map((column, i) => (
                            <TableColumnItem key={i} column={column} />
                        ))}
                </RadioGroup>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default SortColumnPanel;
