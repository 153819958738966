import React, { useCallback, useMemo } from 'react';
import { SortingDirection, Sorting } from '@devexpress/dx-react-grid';
import { Grid, TableSortLabel, Tooltip } from '@material-ui/core';
import { matchRowStyle } from 'pages/integrations/components/style';
import { useTranslation } from 'react-i18next';

interface HeaderCellOwnProps {
    name: string;
    label: string;
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: () => void;
    sortLabel: string;
}

const HeaderCell: React.FCC<HeaderCellOwnProps> = ({
    name,
    label,
    onOrderClick,
    sortColumn,
    sortDirection,
    sortLabel,
}) => (
    <Tooltip aria-label={sortLabel} title={sortLabel}>
        <TableSortLabel
            active={sortColumn === name}
            direction={sortColumn === name ? sortDirection : 'asc'}
            onClick={onOrderClick}
        >
            {label}
        </TableSortLabel>
    </Tooltip>
);

interface DonorMatchingHeaderProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: (sorting: Sorting[]) => void;
    copy: {
        sort: string;
    };
}

const DonorMatchingHeader: React.FCC<DonorMatchingHeaderProps> = ({
    sortColumn,
    sortDirection,
    onOrderClick,
    copy,
}) => {
    const classes = matchRowStyle();

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integration_envelope_matching.content.ccb';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const text = useMemo(
        () => ({
            envelopeNameColumnTitle: scopedTranslate('envelopeNameColumnTitle'),
            categoryColumnTitle: scopedTranslate('categoryColumnTitle'),
        }),
        [scopedTranslate],
    );

    return (
        <>
            <Grid item className={classes.matchHeaderItem} md={3} xs={4}>
                <HeaderCell
                    label={text.envelopeNameColumnTitle}
                    name={'name'}
                    onOrderClick={() =>
                        onOrderClick([
                            {
                                columnName: 'name',
                                direction:
                                    sortDirection === 'asc' ? 'desc' : 'asc',
                            },
                        ])
                    }
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortLabel={copy.sort}
                />
            </Grid>
            <Grid item className={classes.matchHeaderItem} md={3} xs={4}>
                <HeaderCell
                    label={text.categoryColumnTitle}
                    name={'email'}
                    onOrderClick={() =>
                        onOrderClick([
                            {
                                columnName: 'email',
                                direction:
                                    sortDirection === 'asc' ? 'desc' : 'asc',
                            },
                        ])
                    }
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortLabel={copy.sort}
                />
            </Grid>
        </>
    );
};

export default DonorMatchingHeader;
