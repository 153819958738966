import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Link,
    Theme,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReportType } from 'store/report/types';
import { integrationStyle } from '../components/style';

interface IntegrationItemCardProps {
    imagePath: string;
    integrationName: string;
    integrationTitle?: string;
    integrationAbout: string;
    integrationType: ReportType;
    handleOpen: (type) => void;
}

const cardStyle = makeStyles((theme: Theme) => ({
    media: {
        height: 150,
        backgroundSize: 'contain',
        padding: 8,
        backgroundOrigin: 'content-box',
    },
    cardInfo: {
        padding: theme.spacing(4, 3, 0, 3),
        height: 90,
    },
    cardFooter: {
        padding: theme.spacing(3, 3, 4),
    },
    cardArea: {
        '&:hover': {
            '& $enableLink': {
                textDecoration: 'underline',
            },
        },
    },
    enableLink: {},
}));

const IntegrationItemCard: React.FCC<IntegrationItemCardProps> = (props) => {
    const classes = integrationStyle();
    const { media, cardInfo, cardFooter, enableLink, cardArea } = cardStyle();

    const { t } = useTranslation();
    const onClick = () => props.handleOpen(props.integrationType);

    return (
        <Card className={classes.card}>
            <CardActionArea className={cardArea} onClick={onClick}>
                <CardMedia
                    className={media}
                    image={props.imagePath}
                    title={props.integrationName}
                />
                <Divider />
                <CardContent className={cardInfo}>
                    <GivelifyLabel
                        bold
                        className={classes.cardName}
                        text={props.integrationTitle || props.integrationName}
                        variant="heading5"
                    />
                    <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                    >
                        {props.integrationAbout}
                    </Typography>
                </CardContent>
                <CardActions className={cardFooter}>
                    <Link className={enableLink} onClick={onClick}>
                        {t('labels.enable')}
                    </Link>
                </CardActions>
            </CardActionArea>
        </Card>
    );
};

export default IntegrationItemCard;
