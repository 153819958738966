import { ErrorResponse, getAxiosClient } from '@givelify/utils';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApplyCaseMiddlewareOptions } from 'axios-case-converter';
import { TFunction } from 'i18next';
import { setAccessToken } from './user/actions';

export const unauthorizedError = new Error('Unauthorized Login Attempt');
export const invalidFormRequestError = new Error('Invalid form request');
export const unknownError = new Error('Something went wrong');

const errorThrow = (error) => {
    if (error.isAxiosError && error.status === 401) {
        throw unauthorizedError;
    } else if (error.isAxiosError && error.status === 422) {
        throw invalidFormRequestError;
    } else {
        throw unknownError;
    }
};

export const handleErrorMessages = (
    e: ErrorResponse,
    t: TFunction,
): ErrorResponse => {
    if (!e.isAxiosError || !e.status) return e;
    const status = e.status;
    if (status === 404) {
        e.message = t('error.status.404');
    } else if (status === 500) {
        e.message = t('error.status.500');
    } else if (status === 401) {
        e.message = t('error.status.401');
    }
    return e;
};

const requestFlowWrapper = async (
    dispatch,
    httpClientRequest: () => Promise<AxiosResponse>,
) => {
    try {
        return await httpClientRequest();
    } catch (error) {
        if (error.isAxiosError) {
            const status = error.status;
            if (status === 401) {
                dispatch(setAccessToken(undefined));
            }
        }
        errorThrow(error);
    }
};

/**
 * HTTP POST
 * @param url
 * @param data
 * @param config
 */
export const httpPost =
    (
        url: string,
        data: unknown = null,
        config: {
            axios?: AxiosRequestConfig;
            caseMiddlewareConfig?: ApplyCaseMiddlewareOptions;
        } = undefined,
    ) =>
    async (dispatch) => {
        return await requestFlowWrapper(dispatch, () =>
            getAxiosClient(config?.axios, config?.caseMiddlewareConfig).post(
                url,
                data,
                config?.axios,
            ),
        );
    };

/**
 * HTTP GET
 *
 * @param url
 * @param data
 * @param config
 */
export const httpGet =
    (url: string, config: AxiosRequestConfig = undefined) =>
    async (dispatch) => {
        return await requestFlowWrapper(dispatch, () =>
            getAxiosClient().get(url, config),
        );
    };

// // put
export const httpPut =
    (url: string, data = null, config: AxiosRequestConfig = undefined) =>
    async (dispatch) => {
        return await requestFlowWrapper(dispatch, () =>
            getAxiosClient().put(url, data, config),
        );
    };

// // head
export const httpHead =
    (url: string, config: AxiosRequestConfig = undefined) =>
    async (dispatch) => {
        return await requestFlowWrapper(dispatch, () =>
            getAxiosClient().head(url, config),
        );
    };

// // delete
export const httpDelete =
    (url: string, config: AxiosRequestConfig = undefined) =>
    async (dispatch) => {
        return await requestFlowWrapper(dispatch, () =>
            getAxiosClient().delete(url, config),
        );
    };

// // patch
export const httpPatch =
    (url: string, data = null, config: AxiosRequestConfig = undefined) =>
    async (dispatch) => {
        return await requestFlowWrapper(dispatch, () =>
            getAxiosClient().patch(url, data, config),
        );
    };
