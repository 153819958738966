import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as LoadingCircle } from '../assets/icons/loading-circle.svg';
import { loadingStyles } from './styles';

interface LoadingRingProps {
    className?: string;
    color?: 'primary' | 'secondary' | 'alt';
}

const LoadingRing: React.FCC<LoadingRingProps> = (props) => {
    const { loadingRing, primary, secondary, alt } = loadingStyles();
    const className = mergeClassNames(
        loadingRing,
        props.color === 'primary' && primary,
        props.color === 'secondary' && secondary,
        props.color === 'alt' && alt,
        props.className,
    );
    return (
        <SvgIcon
            className={className}
            component={LoadingCircle}
            viewBox="0 0 20 20"
        />
    );
};

export default LoadingRing;
