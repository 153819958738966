import React from 'react';
import { DesignTokens, GivelifyLabel, GivelifyPagination } from '@givelify/ui';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type DataTablePaginationProps = {
    pageNumber: number;
    maxPages: number;
    resultCount: number;
    from: number;
    to: number;
    onPageChange?: (pageNumber: number) => void;
};

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
}));

const PaginationInfoWrapper = styled('div')({
    position: 'absolute',
    right: '32px',
    top: '50%',
    transform: 'translateY(-50%)',
});

const PaginationBold = styled('span')(({ theme }) => ({
    fontWeight: 800,
    fontFamily: `${DesignTokens.font.areaNormalExtrabold}, sans-serif`,
    fontSize: '18px',
    [theme.breakpoints.down('tablet')]: {
        fontWeight: 600,
        fontSize: '16px',
    },
}));

const StyledLabel = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
    },
}));

const DataTablePaginationComponent: React.FCC<DataTablePaginationProps> = ({
    maxPages,
    pageNumber,
    resultCount,
    from,
    to,
    onPageChange,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            showing: t('donors.showing'),
            of: t('donors.of'),
        }),
        [t],
    );
    return (
        <PaginationWrapper data-testid="data-table-pagination">
            <GivelifyPagination
                maxPages={maxPages}
                onPageChange={onPageChange}
                pageNumber={pageNumber > maxPages ? maxPages : pageNumber}
            />
            {isMobile ? null : (
                <PaginationInfoWrapper>
                    <StyledLabel
                        noWrap
                        data-testid="pagination-info-text"
                        variant="body1"
                    >
                        {copy.showing + ' '}
                        <PaginationBold>
                            {from && to ? `${from}-${to}` : 0} {copy.of}{' '}
                            {resultCount}
                        </PaginationBold>
                    </StyledLabel>
                </PaginationInfoWrapper>
            )}
        </PaginationWrapper>
    );
};

export const DataTablePagination = React.memo(DataTablePaginationComponent);
