import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const MobilePreviewContainer = styled('div')({
    textAlign: 'center',
    overflow: 'visible',
});
export const MobilePreviewCoverSection = styled('div')({
    height: 210,
    width: 375,
});
export const MobilePreviewCover = styled('img')({
    position: 'relative',
    top: -1,
    height: 210,
    width: 375,
    objectFit: 'cover',
});
export const MobilePreviewDetailsSection = styled('div')({
    background: DesignTokens.color.backgroundPrimary,
    boxShadow: DesignTokens.shadow.shadowHigh,
    padding: 20,
    paddingBottom: 32,
    position: 'relative',
    '& .profileStatement': {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 700,
        marginTop: 12,
        textOverflow: 'ellipsis',
        maxHeight: 85,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
    },
    zIndex: 2,
});

export const MobilePreviewBackArrow = styled('div')({
    position: 'absolute',
    left: 16,
    top: 'calc(50% - 20.5px/2 - 290.75px)',
    width: 36,
    height: 36,
    background: DesignTokens.color.backgroundPrimary,
    boxShadow: '0px 2px 4px rgba(40, 74, 119, 0.16)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const MobilePreviewShare = styled('div')({
    position: 'absolute',
    right: 22,
    top: 'calc(50% - 20.5px/2 - 290.75px)',
    width: 36,
    height: 36,
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px rgba(40, 74, 119, 0.16)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const MobilePreviewOrgSec = styled('div')({
    display: 'flex',
    marginTop: 44,
});

export const MobilePreviewOrgBadge = styled('img')({
    marginLeft: 6,
});

export const MobilePreviewTaxTip = styled('div')({
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
    color: DesignTokens.color.textSecondary,
    marginTop: 12,
});

export const MobilePreviewButton = styled('div')({
    background: 'linear-gradient(98.52deg, #F85520 8.25%, #F85520 132.88%)',
    boxShadow: '0px 2px 4px rgba(249, 86, 32, 0.3)',
    borderRadius: 100,
    height: 52,
    color: DesignTokens.color.textWhite,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '24px',
    display: 'flex',
    marginTop: 12,
    fontSize: 16,
    fontWeight: 900,
});

export const MobilePreviewPhoneSec = styled('div')({
    display: 'flex',
    marginTop: 24,
    marginBottom: 5,
    '& .phoneValue': {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 600,
        marginLeft: 21.5,
        color: DesignTokens.color.textPrimary,
    },
});

export const MobilePreviewLocationSec = styled('div')({
    display: 'flex',
    marginTop: 16,
    alignItems: 'start',
    '& .phoneValue': {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 600,
        marginLeft: 21.5,
        color: DesignTokens.color.textPrimary,
    },
});

export const MobilePreviewAvatar = styled('img')({
    position: 'absolute',
    left: 20,
    top: -36,
    width: 72,
    height: 72,
    backgroundSize: 'cover',
    borderRadius: 100,
    background: DesignTokens.color.neutralGrey,
    border: '4px solid #FFFFFF',
});

export const MobilePreviewFav = styled('img')({
    position: 'absolute',
    right: 20,
    top: -14,
    border: '4px solid #FFFFFF',
    borderRadius: 100,
});

export const MobileMap = styled('img')({
    position: 'relative',
    top: -50,
});
