import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { mergeClassNames } from '../utils/classNameUtils';

export interface GivelifyErrorBannerProps extends GivelifyBoxMarginProps {
    text: string | React.ReactChild[] | React.ReactChild;
    align?: 'center' | 'left' | 'right';
    className?: string;
}
type StyleProps = Omit<GivelifyErrorBannerProps, 'text' | 'className'>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorText: {
            fontFamily: theme.gas.font,
            color: theme.colors.error300,
            borderRadius: 4,
            display: 'inline-block',
            marginTop: theme.spacing(3),
            backgroundColor: theme.colors.error100,
            paddingTop: 4,
            paddingBottom: 4,
            width: '100%',
            fontSize: 14,
            lineHeight: '24px',
            fontWeight: 600,
        },
        boxMargin: {
            marginLeft: (props: StyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: StyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: StyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: StyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        textAlign: {
            textAlign: (props: StyleProps) => props.align ?? 'center',
        },
    }),
);

export const GivelifyErrorBanner: React.FCC<GivelifyErrorBannerProps> = (
    props,
) => {
    const { boxMargin, errorText, textAlign } = useStyles({
        margin: props.margin,
        marginBottom: props.marginBottom,
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        marginTop: props.marginTop,
        align: props.align,
    });
    const className = mergeClassNames(
        boxMargin,
        errorText,
        textAlign,
        props.className,
    );
    return (
        <div className={className} data-testid="error-banner">
            {props.text}
        </div>
    );
};
