import { createTheme, TabsClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { spacing } from './spacing';

export const GivelifyColorPalette = {
    primary: '#715AFF',
    primaryAlt: '#F85520',
    primaryDarkGray: '#282636',
    primaryWhite: '#FFFFFF',
    accentDarkBlue: '#5F48F0',
    accentPrimaryAlt: '#E34612',
    accentPrimaryDarkAlt: '#D93B07',
    accentGivingGreen: '#C6BDFF',
    accentAvatarBlue: '#f4f2ff',
    accentBlue25: '#ECF4F7',
    neutralGrey: '#73717F',
    neutralGrey75: '#94939E',
    neutralPlatinum: '#E4E3E8',
    neutralHoverGrey: '#EEEDF3',
    neutralBlue30: '#D2E8F4',
    neutralDark: '#1A051D',
    graphBlue1: '#715AFF',
    graphBlue2: '#9C8CFF',
    graphBlue3: '#C6BDFF',
    graphGreen1: '#49DCB1',
    graphGreen2: '#B6F1E0',
    error: '#EB3333',
    error75: '#EF5353',
    error25: '#FDEEEE',
    warning: '#CC7E00',
    warning75: '#FFDA85',
    warning25: '#FEFAEC',
    success: '#00AC31',
    success75: '#73c997',
    success25: '#EBF9EF',
    backgroundTooltip: "'#717171'",

    generosityOrange: '#F85520',
    generosityOrange600: '#C83808',
    generosityOrangeButtonHover: '#E34612',
    generosityOrangeButtonPressed: '#D93B07',
    generosityOrangeLightNavHover: '#FEF1ED',
    black: '#282636',
    white: '#FFFFFF',
    guidingViolet: '#715AFF',
    guidingVioletButtonHover: '#5F48F0',
    guidingVioletButtonPressed: '#5039E3',
    guidingVioletLight: '#F4F2FF',
    accentViolet100: '#C6BDFF',
    grey50: '#FAFAFC',
    grey100: '#F3F3F7',
    grey200: '#D0CFD9',
    grey300: '#BAB8C7',
    grey500: '#94939E',
    grey600: '#73717F',
    disabled: '#E4E3E8',
    chartViolet400: '#715AFF',
    chartViolet300: '#A293FF',
    chartViolet200: '#C6BDFF',
    chartMint400: '#49DCB1',
    chartMint200: '#80E6C8',
    chartGreen100: '#B6F1E0',
    chartBlue100: '#1799F7',
    chartBlue200: '#8BCCFB',
    error300: '#EB3333',
    error200: '#EF5353',
    error100: '#FDEEEE',
    warning300: '#CC7E00',
    warning200: '#FFDA85',
    warning100: '#FEFAEC',
    success300: '#00AC31',
    success200: '#73c997',
    success100: '#EBF9EF',
    info100: '#E9F9FF',
    info200: '#6AC5E7',
    navigationTextDefault: '#6A6873',
    navigationTextHover: '#6A6873',
};

export type GivelifyColor = keyof typeof GivelifyColorPalette;
export type GivelifyColorOrString =
    | GivelifyColor
    | (string & { fromT?: unknown });
export const GasDefaultPalette = {
    font: '"AreaNormal", san-serif',
    elevation: {
        low: '0px 0px 1px rgba(10, 0, 77, 0.039), 0px 0.5px 1.5px rgba(10, 0, 77, 0.19)',
        medium: '0px 0.25px 1px rgba(10, 0, 77, 0.039), 0px 0.85px 3px rgba(10, 0, 77, 0.19)',
        high: '0px 1px 6px rgba(10, 0, 77, 0.04), 0px 5.5px 30px rgba(10, 0, 77, 0.05)',
    },
    palette: {
        button: {
            primary: {
                idle: {
                    background: GivelifyColorPalette.guidingViolet,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                hover: {
                    background: GivelifyColorPalette.guidingVioletButtonHover,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                active: {
                    background: GivelifyColorPalette.guidingVioletButtonPressed,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                disabled: {
                    background: GivelifyColorPalette.disabled,
                    border: 'initial',
                    text: GivelifyColorPalette.grey500,
                },
            },
            secondary: {
                idle: {
                    background: GivelifyColorPalette.white,
                    border: GivelifyColorPalette.guidingViolet,
                    text: GivelifyColorPalette.guidingViolet,
                },
                hover: {
                    background: GivelifyColorPalette.white,
                    border: GivelifyColorPalette.guidingVioletButtonHover,
                    text: GivelifyColorPalette.guidingVioletButtonHover,
                },
                active: {
                    background: GivelifyColorPalette.white,
                    border: GivelifyColorPalette.guidingVioletButtonPressed,
                    text: GivelifyColorPalette.guidingVioletButtonPressed,
                },
                disabled: {
                    background: GivelifyColorPalette.disabled,
                    border: 'initial',
                    text: GivelifyColorPalette.grey500,
                },
            },
            alt: {
                idle: {
                    background: GivelifyColorPalette.generosityOrange,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                hover: {
                    background:
                        GivelifyColorPalette.generosityOrangeButtonHover,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                active: {
                    background:
                        GivelifyColorPalette.generosityOrangeButtonPressed,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                disabled: {
                    background: GivelifyColorPalette.disabled,
                    border: 'initial',
                    text: GivelifyColorPalette.grey500,
                },
            },
            altSecondary: {
                idle: {
                    background: GivelifyColorPalette.white,
                    border: GivelifyColorPalette.generosityOrange,
                    text: GivelifyColorPalette.generosityOrange,
                },
                hover: {
                    background: GivelifyColorPalette.white,
                    border: GivelifyColorPalette.generosityOrangeButtonHover,
                    text: GivelifyColorPalette.generosityOrangeButtonHover,
                },
                active: {
                    background: GivelifyColorPalette.white,
                    border: GivelifyColorPalette.generosityOrangeButtonPressed,
                    text: GivelifyColorPalette.generosityOrangeButtonPressed,
                },
                disabled: {
                    background: GivelifyColorPalette.disabled,
                    border: 'initial',
                    text: GivelifyColorPalette.grey500,
                },
            },
            danger: {
                idle: {
                    background: GivelifyColorPalette.error200,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                hover: {
                    background: GivelifyColorPalette.error300,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                active: {
                    background: GivelifyColorPalette.error300,
                    border: 'initial',
                    text: GivelifyColorPalette.white,
                },
                disabled: {
                    background: GivelifyColorPalette.disabled,
                    border: 'initial',
                    text: GivelifyColorPalette.grey500,
                },
            },
            dangerSecondary: {
                idle: {
                    background: GivelifyColorPalette.white,
                    border: 'initial',
                    text: GivelifyColorPalette.error200,
                },
                hover: {
                    background: GivelifyColorPalette.grey100,
                    border: GivelifyColorPalette.error300,
                    text: GivelifyColorPalette.error300,
                },
                active: {
                    background: GivelifyColorPalette.grey100,
                    border: GivelifyColorPalette.error300,
                    text: GivelifyColorPalette.error300,
                },
                disabled: {
                    background: GivelifyColorPalette.disabled,
                    border: 'initial',
                    text: GivelifyColorPalette.grey500,
                },
            },
        },
        background: {
            primary: GivelifyColorPalette.white,
            secondary: GivelifyColorPalette.grey100,
        },
        action: {
            accent: GivelifyColorPalette.accentViolet100,
            accentLight: GivelifyColorPalette.guidingVioletLight,
            disabledBackground: GivelifyColorPalette.disabled,
            disabledOutline: GivelifyColorPalette.disabled, //???
            disabledText: GivelifyColorPalette.grey500,
            hover: GivelifyColorPalette.grey100,
        },
        graph: {
            colors: [
                GivelifyColorPalette.guidingViolet,
                GivelifyColorPalette.chartViolet300,
                GivelifyColorPalette.chartViolet200,
                GivelifyColorPalette.chartGreen100,
                GivelifyColorPalette.chartMint200,
                GivelifyColorPalette.chartMint400,
                GivelifyColorPalette.chartBlue100,
                GivelifyColorPalette.chartBlue200,
                GivelifyColorPalette.grey100,
                GivelifyColorPalette.grey200,
                GivelifyColorPalette.grey300,
            ],
            envelopeDonutColors: {
                occasional: GivelifyColorPalette.chartViolet200,
                new: GivelifyColorPalette.chartViolet300,
                consistent: GivelifyColorPalette.chartViolet400,
                decreasing: GivelifyColorPalette.chartMint200,
                inactive: GivelifyColorPalette.chartMint400,
            },
        },
        state: {
            error: {
                background: GivelifyColorPalette.error100,
                border: GivelifyColorPalette.error200,
                text: GivelifyColorPalette.error300,
            },
            warning: {
                background: GivelifyColorPalette.warning100,
                border: GivelifyColorPalette.warning200,
                text: GivelifyColorPalette.warning300,
            },
            success: {
                background: GivelifyColorPalette.success100,
                border: GivelifyColorPalette.success200,
                text: GivelifyColorPalette.success300,
            },
        },
        label: {
            primary: GivelifyColorPalette.black,
            secondary: GivelifyColorPalette.grey600,
            light: GivelifyColorPalette.grey500,
            contrast: GivelifyColorPalette.white,
            placeholder: GivelifyColorPalette.grey600,
        },
        input: {
            idle: {
                background: GivelifyColorPalette.white,
                border: GivelifyColorPalette.grey200,
                text: GivelifyColorPalette.black,
            },
            hover: {
                background: GivelifyColorPalette.white,
                border: GivelifyColorPalette.grey100,
                text: GivelifyColorPalette.grey500,
            },
            focused: {
                background: GivelifyColorPalette.white,
                border: GivelifyColorPalette.guidingViolet,
                text: GivelifyColorPalette.grey500,
            },
            disabled: {
                background: GivelifyColorPalette.white,
                border: GivelifyColorPalette.disabled,
                text: GivelifyColorPalette.grey200,
            },
            focusedAlt: {
                background: GivelifyColorPalette.white,
                border: GivelifyColorPalette.generosityOrange,
                text: GivelifyColorPalette.grey500,
            },
        },
        misc: {
            separator: GivelifyColorPalette.grey100,
            navHover: GivelifyColorPalette.generosityOrangeLightNavHover,
        },
        primary: GivelifyColorPalette.guidingViolet,
        alt: GivelifyColorPalette.generosityOrange,
        navigation: {
            background: {
                default: GivelifyColorPalette.white,
                hover: GivelifyColorPalette.grey100,
                select: GivelifyColorPalette.generosityOrangeLightNavHover,
            },
            text: {
                default: GivelifyColorPalette.navigationTextDefault,
                hover: GivelifyColorPalette.navigationTextHover,
                select: GivelifyColorPalette.generosityOrange600,
            },
        },
    },
};

export const GivelifyTheme = createTheme({
    overrides: {
        MuiTabs: {
            indicator: {
                height: 4,
                backgroundColor: '#715AFF',
            },
            root: {
                borderBottom: '1px solid #94939E',
                '& .Mui-selected .MuiTab-wrapper': {
                    color: '#715AFF',
                    fontWeight: 'bold',
                },
            },
            vertical: {}, // in the future if we need vertical tabs
            // MUI types for Tabs are screwed up in this version,
            // but they have fixed it in the next version which is not released yet
        } as Partial<Record<TabsClassKey, CSSProperties>>,
        MuiTab: {
            root: {
                fontSize: 16,
                lineHeight: '19px',
                color: '#282636',
                textTransform: 'none',
                fontWeight: 400,
                minWidth: 'inherit',
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 32,
            },
        },
    },
    colors: GivelifyColorPalette,
    gas: GasDefaultPalette,
    palette: {
        primary: {
            main: GivelifyColorPalette.primary,
            dark: GivelifyColorPalette.accentDarkBlue,
            light: GivelifyColorPalette.accentAvatarBlue,
            contrastText: GivelifyColorPalette.primaryWhite,
        },
        background: {
            default: GivelifyColorPalette.primaryWhite,
            paper: GivelifyColorPalette.primaryWhite,
        },
        action: {
            disabled: GivelifyColorPalette.neutralGrey75,
            disabledBackground: GivelifyColorPalette.neutralPlatinum,
            disabledOpacity: 1,
            hover: GivelifyColorPalette.neutralHoverGrey,
        },
        error: {
            main: GivelifyColorPalette.error75,
            dark: GivelifyColorPalette.error,
            light: GivelifyColorPalette.error25,
        },
        success: {
            main: GivelifyColorPalette.success75,
            dark: GivelifyColorPalette.success,
            light: GivelifyColorPalette.success25,
        },
        warning: {
            main: GivelifyColorPalette.warning75,
            dark: GivelifyColorPalette.warning,
            light: GivelifyColorPalette.warning25,
        },
        info: {
            main: GivelifyColorPalette.graphBlue2,
            dark: GivelifyColorPalette.graphBlue1,
            light: GivelifyColorPalette.graphBlue3,
        },
        text: {
            primary: GivelifyColorPalette.primaryDarkGray,
            secondary: GivelifyColorPalette.neutralGrey,
            disabled: GivelifyColorPalette.neutralPlatinum,
        },
    },
    typography: {
        fontFamily: 'AreaNormal',
        fontWeightBold: 700,
        fontWeightRegular: 400,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontSize: 16,
        h1: {
            fontSize: 32,
            lineHeight: '38px',
            fontWeight: 300,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h2: {
            fontSize: 24,
            lineHeight: '26px',
            fontWeight: 400,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h3: {
            fontSize: 24,
            lineHeight: '26px',
            fontWeight: 700,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h4: {
            fontSize: 18,
            lineHeight: '18px',
            fontWeight: 700,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h5: {
            fontSize: 16,
            lineHeight: '16px',
            fontWeight: 700,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
    },
    spacing,
});
