import React, { useMemo } from 'react';
import { FullWidthModal } from '@givelify/givelify-ui';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { makeStyles, Theme } from '@material-ui/core';
import { Virtuoso, ListProps, ItemProps } from 'react-virtuoso';
import { useAdvancedTranslation } from 'utils/i18n';
import EnvelopeMobileSortItem from '../../../components/EnvelopeListItem/EnvelopeMobileSortItem';
import { useEnvelopesContext } from '../../../context/EnvelopesProvider';

interface MobileSortModalProps {
    open: boolean;
    onClose: () => void;
    list: React.ComponentType<ListProps>;
    item: React.ComponentType<ItemProps>;
}

const useStyles = makeStyles((theme: Theme) => ({
    sortModalContent: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        '& .content-flex': {
            paddingBottom: 0,
        },
    },
    dragDescription: {
        padding: theme.spacing(0, 3),
    },
    sortModalFooter: {
        minHeight: 80,
        '& #submit-footer': {
            '& button': {
                height: 48,
                margin: 0,
            },
        },
        '& button:first-child': {
            display: 'none',
        },
    },
    listEnvelope: {
        '& > div': {
            padding: theme.spacing(0, 4),
        },
    },
}));

const MobileSortModal: React.FCC<MobileSortModalProps> = ({
    open,
    onClose,
    item,
    list,
}) => {
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            reorderEnvelope: t('pages.settings.envelopes2.reorderEnvelope'),
            dragDescription: at('pages.settings.envelopes2.dragDescription'),
        }),
        [at, t],
    );

    const { dragDescription, listEnvelope, sortModalContent, sortModalFooter } =
        useStyles();

    const { activeEnvelopes: items, updatePrioritiesRequestState } =
        useEnvelopesContext();

    return (
        <FullWidthModal
            classContentName={sortModalContent}
            classFooterName={sortModalFooter}
            footer={{
                cancelText: 'Done',
                submitText: 'Done',
                disableSubmit: false,
                isLoading: updatePrioritiesRequestState === 'REQUEST_START',
                onCancel: onClose,
                onSubmit: onClose,
            }}
            heading={copy.reorderEnvelope}
            id="reorderModal"
            name="sortModal"
            onClose={onClose}
            open={open}
        >
            <div>
                <GivelifyLabel
                    className={dragDescription}
                    color={DesignTokens.color.textSecondary}
                    marginBottom={3}
                    text={copy.dragDescription}
                    variant="body1"
                />
                <Virtuoso
                    className={listEnvelope}
                    components={{
                        List: list,
                        Item: item,
                    }}
                    data={items}
                    itemContent={(index, item) => (
                        <EnvelopeMobileSortItem key={item.id} {...item} />
                    )}
                    style={{
                        width: '100%',
                        height: 'calc(100vh - 240px)',
                    }}
                />
            </div>
        </FullWidthModal>
    );
};

export default MobileSortModal;
