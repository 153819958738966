import { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Img1 from './img/1.png';
import Img2 from './img/2.png';
import Img3 from './img/3.png';
import {
    CalendlyAvatar,
    CalendlyAvatarGroup,
    ConfirmationWrapper,
    Divider,
} from './styles';
import { CalendlyResponse } from './types';

const parseDate = (
    dateTimeString: string,
    timezone: string,
    format: string,
) => {
    let result;
    try {
        result = dayjs(dateTimeString).tz(timezone).format(format);
    } catch {
        const currentTimezone = dayjs.tz.guess();
        result = dayjs(dateTimeString).tz(currentTimezone).format(format);
    }
    return result;
};

const ConfirmationModal: React.FCC<{
    response: CalendlyResponse;
    onClose: () => void;
}> = ({ response, onClose }) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            confirmed1: t('calendly.confirmed1'),
            confirmed2: t('calendly.confirmed2'),
            willCallYouAt: t('calendly.willCallYouAt'),
            calendarConfirmation: t('calendly.calendarConfirmation'),
            sentTo: t('calendly.sentTo'),
            thanks: t('calendly.thanks'),
        }),
        [t],
    );

    const TIME_FORMAT = 'h:mma';
    const startTime = parseDate(
        response.data.startTime,
        response.data.timezone,
        TIME_FORMAT,
    );
    const endTime = parseDate(
        response.data.endTime,
        response.data.timezone,
        TIME_FORMAT,
    );

    const DATE_FORMAT = 'dddd, MMMM DD, YYYY';
    const date = parseDate(
        response.data.endTime,
        response.data.timezone,
        DATE_FORMAT,
    );

    return (
        <GivelifyModal
            open
            showCloseButton
            maxWidth="xs"
            name="Talk with an Expert Confirmation"
            onClose={onClose}
        >
            <CalendlyAvatarGroup>
                <CalendlyAvatar src={Img1} />
                <CalendlyAvatar src={Img2} />
                <CalendlyAvatar src={Img3} />
            </CalendlyAvatarGroup>
            <ConfirmationWrapper>
                <GivelifyIcon variant="success" />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <GivelifyLabel text={copy.confirmed1} variant="body1B" />
                    <GivelifyLabel text={copy.confirmed2} variant="body1B" />
                </div>
                <Divider />
                <GivelifyIcon variant="givelify" />
                <GivelifyLabel text={response.data.name} variant="body2" />
                <GivelifyIcon fontSize={24} variant="calendar" />
                <div>
                    <GivelifyLabel
                        text={`${startTime} - ${endTime}`}
                        variant="body2B"
                    />
                    <br />
                    <GivelifyLabel text={date} variant="body2B" />
                </div>
                <GivelifyIcon variant="earthGlobe" />
                <GivelifyLabel text={response.data.timezone} variant="body2" />
                <GivelifyIcon variant="phone" />
                <GivelifyLabel
                    text={`${copy.willCallYouAt} ${response.data.phone}`}
                    variant="body2"
                />
                <Divider />
                <div
                    style={{
                        gridColumn: 'span 2',
                    }}
                >
                    <GivelifyLabel
                        text={copy.calendarConfirmation}
                        variant="body2"
                    />
                    <GivelifyLabel variant="body2">
                        {copy.sentTo}{' '}
                        <GivelifyLabel
                            text={response.data.email}
                            variant="body2B"
                            variantMapping={{
                                body2B: 'span',
                            }}
                        />
                    </GivelifyLabel>
                </div>
            </ConfirmationWrapper>
            <GivelifyButton
                onClick={onClose}
                style={{ width: 272, alignSelf: 'center', marginTop: 32 }}
                text={copy.thanks}
                variant="primary"
            />
        </GivelifyModal>
    );
};

export default ConfirmationModal;
