import { useRef, useState } from 'react';
import React from 'react';
import { ApiHandler } from '@givelify/api';
import { getDateRangeHash } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { useFilterParams } from 'api/hooks';
import { useQuery } from 'react-query';
import { ListItem } from './types';

const paramNames = ['envelopeIds'] as const;

export type FilterState = {
    loading: boolean;
    options: ListItem[];
    values: number[];
};

export const useEnvelopesFilter = (
    doneeId: number,
    timeFrame: TimeFrameValue,
) => {
    const [defaultValues, setDefaultValues] = useState<number[] | undefined>(
        undefined,
    );
    const [filterState, setFilterState] = useState<FilterState>({
        loading: true,
        options: [],
        values: [],
    });
    const dateHash = getDateRangeHash(timeFrame);
    const { data: getEnvelopesResponse } = useQuery(
        [
            ApiHandler.instance.envelopes.getEnvelopesData.name,
            doneeId,
            dateHash,
        ],
        () =>
            ApiHandler.instance.envelopes.getEnvelopesData(doneeId, {
                timeFrame: timeFrame,
                sort: 'priority',
                pageSize: 9999,
            }),
    );
    const isFirsyLoadRef = useRef(true);
    const [filter, setFilter] = useFilterParams<
        typeof defaultValues,
        typeof paramNames
    >(
        defaultValues,
        paramNames,
        (state, _paramName) => {
            if (
                state === undefined ||
                state.length === filterState.options.length
            ) {
                return '';
            }
            if (state.length > 0) {
                return state.join('_');
            } else if (state?.length === 0) {
                return 'none';
            }
            return '';
        },
        (values) => {
            if (values['envelopeIds'] === 'none') {
                return [];
            }
            const splited = values['envelopeIds'].split('_');
            if (splited.length > 0) {
                const newValues = splited
                    .map((value) => parseInt(value))
                    .filter((x) => !isNaN(x))
                    .sort();
                return newValues;
            }
            return undefined;
        },
    );
    React.useEffect(() => {
        if (getEnvelopesResponse && getEnvelopesResponse.success) {
            const options = getEnvelopesResponse.response.data.map(
                (envelope) => ({
                    id: envelope.id,
                    label: envelope.name,
                }),
            );
            const isFirstLoad = isFirsyLoadRef.current;
            const values =
                isFirstLoad && filter
                    ? filter
                    : options.map((option) => option.id);
            const newDefaultValues = options.map((option) => option.id);
            setFilterState({
                options,
                values,
                loading: false,
            });
            setDefaultValues(newDefaultValues);
            isFirsyLoadRef.current = false;
        }
        //listen only to getRequest change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEnvelopesResponse]);

    const onChange = (values: number[]) => {
        setFilter(values);
        setFilterState({
            ...filterState,
            values,
            loading: false,
        });
    };

    return [filterState, onChange] as const;
};
