import React from 'react';
import { TrackingProvider } from '@givelify/utils';
import OnboardingPage from '../../../OnboardingPage';
import View from './view';

const TaxIdSummary: React.FCC = () => {
    return (
        <TrackingProvider trackPageVisit pageName="EIN Federal Tax Id Summary">
            <OnboardingPage content={<View />} informationContent={null} />
        </TrackingProvider>
    );
};

export default TaxIdSummary;
