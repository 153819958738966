import React, { useCallback, useState, useMemo } from 'react';
import { GivelifyTextField, GivelifyTextFieldState } from '../textField';
import { isValidStreet, isPOBoxInPOBoxNotAllowed } from '../utils/addressUtils';

export interface StreetFieldProps {
    name?: string;
    street: string | undefined;
    onChange?: (street: string) => unknown;
    className?: string;
    placeholder: string;
    poBoxNotAllowedText: string | undefined;
    showState?: boolean;
    streetErrorMessage?: string;
}

export const StreetField: React.FC<StreetFieldProps> = ({
    name,
    street,
    className,
    placeholder,
    poBoxNotAllowedText,
    onChange,
    showState = false,
    streetErrorMessage,
}) => {
    const [newStreet, setStreet] = useState<string>(street || '');
    const [inputState, setInputState] =
        React.useState<GivelifyTextFieldState>('idle');
    const handleChange = useCallback<
        React.ChangeEventHandler<HTMLInputElement>
    >(
        (e) => {
            const value = e.target.value;
            setStreet(value);
            onChange && onChange(value);
            setInputState(
                isValidStreet(value, poBoxNotAllowedText !== undefined)
                    ? 'idle'
                    : 'error',
            );
        },
        [setStreet, onChange, setInputState, poBoxNotAllowedText],
    );
    React.useEffect(() => {
        if (showState) {
            setInputState(
                isValidStreet(newStreet, poBoxNotAllowedText !== undefined)
                    ? 'idle'
                    : 'error',
            );
        }
        //eslint-disable-next-line
    }, [showState]);

    const leftHelperText = useMemo(() => {
        const isPOBoxNotAllowed = isPOBoxInPOBoxNotAllowed(
            newStreet,
            poBoxNotAllowedText !== undefined,
        );
        return inputState === 'error'
            ? !isPOBoxNotAllowed && !!poBoxNotAllowedText
                ? streetErrorMessage
                : poBoxNotAllowedText
            : poBoxNotAllowedText;

        // ? 'Street name and number are both needed'
        // : poBoxNotAllowedText
        // ? poBoxNotAllowedText
        // : undefined;
    }, [inputState, newStreet, poBoxNotAllowedText, streetErrorMessage]);

    return (
        <GivelifyTextField
            fullWidth
            aria-label={placeholder}
            classes={className ? { root: className } : undefined}
            id="street"
            label={placeholder}
            leftHelperText={leftHelperText}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            state={inputState}
            value={newStreet}
        />
    );
};
