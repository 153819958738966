import React, { useEffect, useState } from 'react';
import { FacebookLoading, GivelifyLabel } from '@givelify/givelify-ui';
import InfiniteLoader from 'pages/donations/components/InfiniteLoader';
import { IntegrationTypes } from 'pages/integrations/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { PaginatedResponse } from 'types/paginationTypes';
import { useMatchingStyles } from '../DonorMatching';

const Loading = <FacebookLoading borderRadius={0} height={256} width="100%" />;

interface OwnProps {
    integration: IntegrationTypes;
    url: (pageNumber: number) => string;
    reportId?: number;
    renderData: (
        data: PaginatedResponse<unknown>,
    ) => JSX.Element | JSX.Element[];
    loadErrorText: string;
    noItemsText: string;
}

const MatchingInfiniteLoader: React.FCC<OwnProps> = ({
    url,
    renderData,
    loadErrorText,
    noItemsText,
}) => {
    const { errorRow, heightLoader } = useMatchingStyles();
    const [reset, setReset] = useState(0);
    const doneeId = useSelector((state: AppState) => state.Donee.donee.id);

    const Error = (
        <GivelifyLabel
            bold
            className={errorRow}
            text={loadErrorText}
            variant="heading5"
        />
    );
    const Zeroth = (
        <GivelifyLabel
            bold
            className={errorRow}
            text={noItemsText}
            variant="heading5"
        />
    );
    useEffect(() => {
        setReset((val) => val + 1);
    }, [doneeId]);
    const onReset = () => setReset(undefined);
    return (
        <InfiniteLoader
            ErrorComponent={Error}
            LoadingComponent={Loading}
            ZerothComponent={Zeroth}
            className={heightLoader}
            onReset={onReset}
            renderData={renderData}
            reset={reset}
            url={url}
            useWindow={false}
        />
    );
};

export default React.memo(MatchingInfiniteLoader);
