import React, { useCallback, useMemo } from 'react';
import {
    GivelifyTextDivider,
    GivelifyBreadcrumbs,
} from '@givelify/givelify-ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { Paper } from '@material-ui/core';
import dayjs from 'dayjs';
import { useDoneeStartDate } from 'hooks/useDoneeStartDate';
import { integrationStyle } from 'pages/integrations/components/style';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { usePaperStyles } from 'styles/paperClasses';
import { CustomReportForm } from './CustomReportForm';

interface CustomReportsProps {
    integration: IntegrationTypes;
    closeCustomReportForm: () => void;
}

const CustomReports: React.FCC<CustomReportsProps> = ({
    integration,
    closeCustomReportForm,
}) => {
    const { doneeId, campuses, isSuperUser, reportType } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            campuses: state.Donee.campuses,
            isSuperUser: state.User.user.isSuper,
            reportType: state.ReportIntegration.scheduledReport.reportType,
        }),
    );
    const doneeStartDate = useDoneeStartDate();
    const { paperWithoutPadding } = usePaperStyles();
    const classes = integrationStyle();

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integrations_custom_report';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const copy = useMemo(
        () => ({
            reports: scopedTranslate('reports'),
            buildCustomReports: scopedTranslate('buildCustomReports'),
        }),
        [scopedTranslate],
    );

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.IntegrationsCustomReports}
        >
            <GivelifyTextDivider />
            <GivelifyBreadcrumbs
                options={[
                    { label: copy.reports, onClick: closeCustomReportForm },
                    { label: copy.buildCustomReports },
                ]}
            />
            <Paper
                className={`${paperWithoutPadding} ${classes.reportContent}`}
            >
                <CustomReportForm
                    campuses={campuses}
                    doneeId={doneeId}
                    doneeStartDate={dayjs.tz(doneeStartDate)}
                    integration={integration}
                    isSuperUser={isSuperUser}
                    onGenerateReport={closeCustomReportForm}
                    reportType={reportType}
                />
            </Paper>
        </TrackingProvider>
    );
};

export default CustomReports;
