import React from 'react';
import {
    DropdownOption,
    GivelifyButton,
    GivelifyForm,
    GivelifyFormDropDown,
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/ui';
import { isFailed, isSucceeded, useApiRequest } from '@givelify/utils';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Donor } from 'api/models';
import { CreateF1GoDonorProfileRequest } from 'api/services/requests';
import { GetF1GoDonorOptionsResponse } from 'api/services/responses';
import { getIntegrationService } from 'api/utils/serviceProvider';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type CreateF1GoDonorProfileRequestForm = Omit<
    CreateF1GoDonorProfileRequest,
    'givelifyDonorId'
>;

type AutoIntegrationF1GoCreateProfileProps = {
    doneeId: number;
    donorId: number;
    donor: Partial<Donor>;
    handleClose: (isDonorMatched?: boolean) => void;
};

export const AutoIntegrationF1GoCreateProfile: React.FCC<
    AutoIntegrationF1GoCreateProfileProps
> = ({ doneeId, donorId, donor, handleClose }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            createProfile: t('f1GoDonorPage.createProfile'),
            contactInfo: t('f1GoDonorPage.contactInfo'),
            phoneType: t('f1GoDonorPage.phoneType'),
            firstName: t('f1GoDonorPage.firstName'),
            lastName: t('f1GoDonorPage.lastName'),
            address: t('f1GoDonorPage.address'),
            email: t('f1GoDonorPage.email'),
            phone: t('f1GoDonorPage.phone'),
            zip: t('f1GoDonorPage.zip'),
            state: t('f1GoDonorPage.state'),
            createDonorProfile: t('f1GoDonorPage.createDonorProfile'),
        }),
        [t],
    );
    const schema = yup.object().shape({
        firstName: yup.string(),
        lastName: yup.string(),
        email: yup.string().nullable(),
        phoneTypeId: yup.string().nullable(),
        phone: yup.string().nullable(),
        streetAddress: yup.string().nullable(),
        city: yup.string().nullable(),
        state: yup.string().nullable(),
        zip: yup.string().nullable(),
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [phoneTypes, setPhoneTypes] = React.useState<DropdownOption[]>([]);
    const service = getIntegrationService();
    const [getOptionsRequest, makeGetOptionsRequest] =
        useApiRequest<GetF1GoDonorOptionsResponse>();
    const [postProfileRequest, makePostProfileRequest] = useApiRequest();
    const [errorMessage, setErrorMessage] = React.useState('');
    const onSubmit = React.useCallback(
        (data: CreateF1GoDonorProfileRequestForm) => {
            void makePostProfileRequest(
                service.postF1GoDonorProfile(doneeId, {
                    ...data,
                    givelifyDonorId: donorId,
                }),
            );
        },
        [doneeId, donorId, makePostProfileRequest, service],
    );
    React.useEffect(() => {
        void makeGetOptionsRequest(service.getF1GoProfileOptions(doneeId));
    }, [doneeId, makeGetOptionsRequest, service]);
    React.useEffect(() => {
        if (isSucceeded(getOptionsRequest)) {
            setPhoneTypes(
                getOptionsRequest.response.phoneTypeOptions.map((x) => ({
                    label: x.name,
                    id: x.id,
                })),
            );
        }
    }, [getOptionsRequest]);
    React.useEffect(() => {
        if (isSucceeded(postProfileRequest)) {
            handleClose(true);
        } else if (isFailed(postProfileRequest)) {
            setErrorMessage(postProfileRequest.error.message);
        }
    }, [handleClose, postProfileRequest]);
    return (
        <GivelifyForm<CreateF1GoDonorProfileRequestForm>
            defaultValues={{
                firstName: donor?.name.split(' ')[0],
                lastName: donor?.name.split(' ')[1],
                phone: donor?.phone,
                city: donor?.city,
                state: donor?.state,
                zip: donor?.zip,
                email: donor?.email,
                phoneTypeId: null,
                streetAddress: donor?.address,
            }}
            onSubmit={onSubmit}
            schema={schema}
        >
            <Stack gap={2} marginTop={4} paddingBottom={0}>
                <GivelifyLabel text={copy.createProfile} variant="body1B" />
                <GivelifyNotification
                    open={errorMessage.length > 0}
                    text={errorMessage}
                    variant="error"
                />
                <Stack
                    flexDirection={isMobile ? 'column' : 'row'}
                    gap={2}
                    marginTop={1}
                >
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="firstName"
                        label={copy.firstName}
                        name="firstName"
                        size="medium"
                    />
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="lastName"
                        label={copy.lastName}
                        name="lastName"
                        size="medium"
                    />
                </Stack>
                <Stack flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="address"
                        label={copy.address}
                        name="streetAddress"
                        size="medium"
                    />
                </Stack>
                <Stack
                    flexDirection={isMobile ? 'column' : 'row'}
                    gap={2}
                    marginBottom={1}
                >
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="city"
                        label="City"
                        name="city"
                        size="medium"
                    />
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="state"
                        label={copy.state}
                        name="state"
                        size="medium"
                    />
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="zip"
                        label={copy.zip}
                        name="zip"
                        size="medium"
                    />
                </Stack>
                <GivelifyLabel text={copy.contactInfo} variant="body1B" />
                <Stack
                    flexDirection={isMobile ? 'column' : 'row'}
                    gap={2}
                    marginTop={1}
                >
                    <GivelifyFormDropDown<CreateF1GoDonorProfileRequestForm>
                        data-testid="phoneTypeId"
                        label={copy.phoneType}
                        name="phoneTypeId"
                        options={phoneTypes}
                        placeholder={copy.phoneType}
                        shape="input"
                        size="small"
                        variant="default"
                        width="fullwidth"
                    />
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="phone"
                        label={copy.phone}
                        name="phone"
                        size="medium"
                    />
                </Stack>
                <Stack flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                    <GivelifyFormTextField<CreateF1GoDonorProfileRequestForm>
                        disabled
                        fullWidth
                        data-testid="email"
                        label={copy.email}
                        name="email"
                        size="medium"
                    />
                </Stack>
                <Stack marginLeft="auto" marginRight="auto" marginTop={3}>
                    <GivelifyButton
                        isLoading={postProfileRequest.type === 'REQUEST_START'}
                        size="large"
                        text={copy.createDonorProfile}
                        type="submit"
                        variant="primary"
                    />
                </Stack>
            </Stack>
        </GivelifyForm>
    );
};
