import { TimeFrameValue } from '@givelify/utils';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const getDateRangeQuery = (
    timeFrame: TimeFrameValue,
    prefix: '?' | '&',
    onlyDate?: boolean,
): string => {
    const startDate = onlyDate
        ? timeFrame.start.startOf('day')
        : dayjs.tz(timeFrame.start.format('YYYY-MM-DD')).startOf('day');
    const endDate = onlyDate
        ? timeFrame.end.endOf('day')
        : dayjs.tz(timeFrame.end.format('YYYY-MM-DD')).endOf('day');

    const format = onlyDate ? 'YYYY-MM-DD' : undefined;

    const formattedStart = format
        ? startDate.format(format)
        : startDate.toISOString();
    const formattedEnd = format
        ? endDate.format(format)
        : endDate.toISOString();

    return timeFrame.selector !== 'lifetime'
        ? `${prefix}start=${formattedStart}&end=${formattedEnd}`
        : prefix;
};
