import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyTextDivider } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import ToggleButtons, { ToggleButtonsProps } from './ToggleButtons';

const DirectDepositAddressToggle: React.FCC<ToggleButtonsProps> = ({
    yes,
    handleYes,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { heading, subHeading } = useMemo(
        () => ({
            heading: t('directDepositManualEntry.addressToggle.heading'),
            subHeading: t('directDepositManualEntry.addressToggle.subHeading'),
        }),
        [t],
    );
    return (
        <>
            <GivelifyTextDivider marginBottom={32} marginTop={32} />
            <GivelifyLabel
                bold
                marginBottom={16}
                text={heading}
                variant="heading3"
            />
            <GivelifyLabel
                marginBottom={18}
                text={subHeading}
                variant="body1"
            />
            <ToggleButtons handleYes={handleYes} yes={yes} />
        </>
    );
};

export default React.memo(DirectDepositAddressToggle);
