import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifySnackbar,
    useAdvancedTranslation,
    GivelifyTheme,
} from '@givelify/givelify-ui';
import { formatWithTimezone, formatWithLocaltime } from '@givelify/utils';
import { useApiRequest } from '@givelify/utils';
import {
    makeStyles,
    Paper,
    TablePagination,
    ThemeProvider,
} from '@material-ui/core';
import { Theme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { usePaperStyles } from 'styles/paperClasses';
import { IntegrationTypes } from '../../../types';
import {
    downloadExportDonation,
    getExportedDonation,
} from './components/automatedIntegrationRequest';
import { ExportDonationDialog } from './components/ExportedTabContent/ExportDonationDialog';
import AutoIntegrationExportTable from './components/ExportedTabContent/ExportTable';
import {
    AutoInteExportedDonationsResponse,
    AutoIntegrationExportedDonationData,
    initialIntegrationPagination,
    IntegrationPaginatedResponse,
    IntegrationPagination,
    mapAutoIntegrationExportedDonationToData,
} from './types';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        marginTop: theme.spacing(4),
        padding: 0,
    },
}));

const pageSize = '8';
const dateFormat = 'YYYY-MM-DD';

interface F1CCBExportedContentProps {
    integration: IntegrationTypes;
}

const AutoIntegrationExportedContent: React.FCC<F1CCBExportedContentProps> = ({
    integration,
}) => {
    const classes = useStyles();
    const { t } = useAdvancedTranslation();
    const paperClasses = usePaperStyles();
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));

    const [pagination, setPagination] = useState<IntegrationPagination>({
        ...initialIntegrationPagination,
        perPage: pageSize,
        currentPage: 1,
    });
    const [pageNumber, setPageNumber] = useState<number>(0);
    const handleChangePage = (e: unknown, pageNumber: number) =>
        setPageNumber(pageNumber);
    const [exportedDonationsRequest, makeRequestExportedDonation] =
        useApiRequest<
            IntegrationPaginatedResponse<AutoInteExportedDonationsResponse>
        >();
    const [exportedDonations, setExportedDonations] = useState<
        AutoIntegrationExportedDonationData[]
    >([]);
    useEffect(() => {
        makeRequestExportedDonation(
            getExportedDonation(userDoneeId, pageNumber, pageSize),
        );
    }, [userDoneeId, pageNumber, makeRequestExportedDonation]);
    useEffect(() => {
        if (exportedDonationsRequest.type === 'REQUEST_SUCCESS') {
            const exportedData = exportedDonationsRequest.response.data;
            setExportedDonations(
                exportedData.map((exportedDonation) =>
                    mapAutoIntegrationExportedDonationToData(exportedDonation),
                ),
            );
            setPagination(
                exportedDonationsRequest.response.meta as IntegrationPagination,
            );
        }
    }, [exportedDonationsRequest]);

    const [exportStartDate, setExportStartDate] = useState<Date>();
    const [exportEndDate, setExportEndDate] = useState<Date>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [exported, setExported] = useState<boolean>(false);

    const closeExported = () => setExported(false);
    const [downloadStatus, requestDownload] = useApiRequest();
    const exportSubmit = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setErrorMessage('');
        setExportStartDate(start.toDate());
        setExportEndDate(end.toDate());
        requestDownload(
            downloadExportDonation(
                userDoneeId,
                start.format(dateFormat),
                end.format(dateFormat),
            ),
        );
    };

    useEffect(
        () => {
            if (downloadStatus.type === 'REQUEST_SUCCESS') {
                const url = window.URL.createObjectURL(
                    // Prepending UTF-8 BOM sequence which is lost here during processing
                    new Blob([
                        decodeURIComponent('%ef%bb%bf'),
                        downloadStatus.response as unknown as BlobPart,
                    ]),
                );
                const link = document.createElement('a');
                link.href = url;
                const start = formatWithLocaltime(exportStartDate, 'YYYYMMDD');
                const end = formatWithLocaltime(exportEndDate, 'YYYYMMDD');
                link.setAttribute(
                    'download',
                    `GIV_exported_${start}_to_${end}.csv`,
                );
                link.click();
                setExported(true);
            } else if (downloadStatus.type === 'REQUEST_ERROR') {
                setErrorMessage(downloadStatus.error.message);
            }
        },
        // eslint-disable-next-line
        [downloadStatus],
    );

    const { downloadExport } = useMemo(
        () => ({
            downloadExport: t('pages.integration_exported_donations.download', {
                startDate: formatWithTimezone(exportStartDate),
                endDate: formatWithTimezone(exportEndDate),
            }),
        }),
        [t, exportStartDate, exportEndDate],
    );

    return (
        <ThemeProvider theme={GivelifyTheme}>
            <ExportDonationDialog
                errorMessage={errorMessage}
                exported={exported}
                onSubmit={exportSubmit}
            />
            <GivelifySnackbar
                hasCloseButton
                autoHideDuration={5000}
                message={downloadExport}
                onClose={closeExported}
                open={exported}
            />
            <Paper className={`${paperClasses.paper} ${classes.wrapper}`}>
                <AutoIntegrationExportTable
                    integration={integration}
                    isLoading={
                        exportedDonationsRequest.type === 'REQUEST_INIT' ||
                        exportedDonationsRequest.type === 'REQUEST_START'
                    }
                    rows={exportedDonations}
                />
                <TablePagination
                    backIconButtonProps={{
                        'aria-label': t('labels.previous'),
                    }}
                    className={paperClasses.integrationPagination}
                    component="div"
                    count={pagination?.total}
                    nextIconButtonProps={{
                        'aria-label': t('labels.next'),
                    }}
                    onPageChange={handleChangePage}
                    page={pageNumber}
                    rowsPerPage={parseInt(pagination?.perPage)}
                    rowsPerPageOptions={[parseInt(pagination?.perPage)]}
                />
            </Paper>
        </ThemeProvider>
    );
};

export default AutoIntegrationExportedContent;
