import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import { Address, AddressWithPhone } from '@givelify/givelify-ui';
import { DesignTokens, GivelifyLabel, ShowAndEditAddress } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { webConfig } from '../../utils/webConfig';
import {
    PrimaryRepresentativeStepRef,
    PrimaryRepresentativeStepTwoProps,
    usePrimaryRepresentativeStepOneStyles,
} from './utils';

const PrimaryRepresentativeStepTwoComponent = (
    {
        data,
        termsOfUseHRefClick,
        onValidation,
    }: PrimaryRepresentativeStepTwoProps,
    forwardRef: ForwardedRef<PrimaryRepresentativeStepRef>,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { adminTerms } = usePrimaryRepresentativeStepOneStyles();
    const [address, setAddress] = React.useState(
        data.primaryRepresentativeAddress,
    );

    const copy = React.useMemo(
        () => ({
            primaryRepresentativeAddress: t(
                'primaryRepresentativePage.primaryRepresentativeAddress',
            ),
            streetAddressPlaceholder: t(
                'primaryRepresentativePage.streetAddressPlaceholder',
            ),
            streetAddressTip: t('primaryRepresentativePage.streetAddressTip'),
            statePlaceholder: t('primaryRepresentativePage.statePlaceholder'),
            zipPlaceholder: t('primaryRepresentativePage.zipPlaceholder'),
            cityPlaceholder: t('primaryRepresentativePage.cityPlaceholder'),
            phonePlaceholder: t('primaryRepresentativePage.phonePlaceholder'),
            footerText1: t('primaryRepresentativePage.footerText1'),
            footerText2: t('primaryRepresentativePage.footerText2'),
            termsOfUse: t('primaryRepresentativePage.termsOfUse'),
            allFieldsRequired: t('labels.allFieldsRequired'),
        }),
        [t],
    );

    useImperativeHandle(forwardRef, () => ({
        submit: () => ({
            ...data,
            primaryRepresentativeAddress: address,
        }),
    }));

    const onAddressChange = React.useCallback(
        (newAddress: AddressWithPhone | Address) => {
            if ('phone' in newAddress) {
                setAddress(newAddress);
            }
        },
        [setAddress],
    );
    const termLinkClicked = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
            if (termsOfUseHRefClick !== undefined) {
                e.preventDefault();
                termsOfUseHRefClick();
            }
        },
        [termsOfUseHRefClick],
    );
    return (
        <>
            <GivelifyLabel
                fontSize="22px"
                id="admin-form-heading-2"
                lineHeight="30px"
                marginBottom={2}
                text={copy.primaryRepresentativeAddress}
                variant="heading3M"
            />
            <GivelifyLabel
                color={DesignTokens.color.globalNeutral600}
                id="admin-form-tip-2"
                marginBottom={2}
                text={copy.allFieldsRequired}
                variant="caption1"
            />
            <ShowAndEditAddress
                isEdit
                address={address}
                cityPlaceholder={copy.cityPlaceholder}
                name="Primary Representative Address Editor"
                onChange={onAddressChange}
                phonePlaceholder={copy.phonePlaceholder}
                poBoxNotAllowedText={copy.streetAddressTip}
                setIsAddressValid={onValidation}
                statePlaceholder={copy.statePlaceholder}
                streetPlaceholder={copy.streetAddressPlaceholder}
                zipPlaceholder={copy.zipPlaceholder}
            />
            <div className={adminTerms}>
                {copy.footerText1}
                <a
                    className="link"
                    href={webConfig.termsOfUse}
                    onClick={termLinkClicked}
                    target="blank"
                >
                    {copy.termsOfUse}
                </a>
                {copy.footerText2}
            </div>
        </>
    );
};

export const PrimaryRepresentativeStepTwo = forwardRef<
    PrimaryRepresentativeStepRef,
    PrimaryRepresentativeStepTwoProps
>(PrimaryRepresentativeStepTwoComponent);
