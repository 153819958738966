import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import ShowYodleeBank from './ShowYodleeBank';
import { usaBankList, YodleeBankOnClick } from './usaBankList';

const MAX_LENGTH = 16;
const MAX_SEARCH_LENGTH = 1;

interface YodleeBanksProps {
    searchTerm: string;
    notFoundText: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        yodleeBankStyles: {
            maxWidth: 840,
            margin: '0 auto',
            textAlign: 'left',
            '& .not-found': {
                textAlign: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
    }),
);

// same as unfilteredBanks, just to avoid searched term length check
const filteredBanks = (
    term: string,
    onClick: (id: number) => unknown,
): React.ReactNode[] => {
    let counter = 0;
    const termLowered = term.toLowerCase();
    const banks: React.ReactNode[] = [];
    for (let index = 0; index < usaBankList.length; index++) {
        if (MAX_LENGTH === counter) {
            return banks;
        }
        const element = usaBankList[index];
        if (element.name.toLowerCase().includes(termLowered)) {
            banks.push(
                <ShowYodleeBank key={index} onClick={onClick} {...element} />,
            );
            counter += 1;
        }
    }
    return banks;
};

const unfilteredBanks = (
    onClick: (id: number) => unknown,
): React.ReactNode[] => {
    const banks: React.ReactNode[] = [];
    for (let index = 0; index < usaBankList.length; index++) {
        if (MAX_LENGTH === index) {
            return banks;
        }
        const element = usaBankList[index];
        banks.push(
            <ShowYodleeBank key={index} onClick={onClick} {...element} />,
        );
    }
    return banks;
};

const YodleeBanks: React.FCC<YodleeBanksProps & YodleeBankOnClick> = ({
    searchTerm,
    notFoundText,
    onClick,
}) => {
    const { yodleeBankStyles } = useStyles();
    const list =
        searchTerm.length > MAX_SEARCH_LENGTH
            ? filteredBanks(searchTerm, onClick)
            : unfilteredBanks(onClick);
    return (
        <div className={yodleeBankStyles}>
            {list.length === 0 ? (
                <GivelifyLabel className="not-found" text={notFoundText} />
            ) : (
                list
            )}
        </div>
    );
};

export default React.memo(YodleeBanks);
