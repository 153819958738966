import React, { useCallback } from 'react';
import { DirectDepositInfo } from '../../@types/assets/onboarding';
import DirectDepositMain from './DirectDepositMain';
import {
    DirectDepositManualEntry,
    DirectDepositManualEntryRef,
} from './directDepositManualEntry/DirectDepositManualEntry';

interface DirectDepositContentProps {
    manualEntry: boolean;
    setManualEntry: (manualEntry: boolean) => unknown;
    doneeId: number;
    data: DirectDepositInfo;
    setValid: (valid: boolean) => unknown;
    onSubmit: (d: DirectDepositInfo) => unknown;
    showYodlee: boolean;
    directDepositManualEntryRef: React.RefObject<DirectDepositManualEntryRef>;
}

export const DirectDepositContent: React.FCC<DirectDepositContentProps> = ({
    manualEntry,
    setManualEntry,
    doneeId,
    data,
    onSubmit,
    setValid,
    showYodlee,
    directDepositManualEntryRef,
}) => {
    const openManualEntry = useCallback(
        () => setManualEntry(true),
        [setManualEntry],
    );
    const handleYodleeSubmit = useCallback(
        (accountNumber: number, routingNumber: number) => {
            onSubmit({ ...data, accountNumber, routingNumber });
            openManualEntry();
        },
        [onSubmit, data, openManualEntry],
    );
    if (!showYodlee || manualEntry) {
        return (
            <DirectDepositManualEntry
                ref={directDepositManualEntryRef}
                data={data}
                onValidation={setValid}
                showChequeSection={data.status === 'require_information'}
            />
        );
    }
    return (
        <DirectDepositMain
            doneeId={doneeId}
            onSubmit={handleYodleeSubmit}
            openManualEntry={openManualEntry}
        />
    );
};
