import React from 'react';
import { Checkbox } from '@material-ui/core';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyLabel } from '../label';
import { checkboxStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GvlLabelVariant } from '../label/types';
import { CHECKBOX_IDENTIFIER, useTrackingContext } from '@givelify/utils';

export interface GivelifyCheckboxProps extends GivelifyBoxMarginProps {
    /**
     * Input name
     */
    name: string;
    /**
     * Input value
     */
    value?: string;
    /**
     * Default Input value
     */
    defaultValue?: string;
    /**
     * Input aria-label
     */
    ariaLabel: string;
    /**
     * Color primary or secondary
     */
    variant?: 'primary' | 'secondary' | 'special';
    /**
     * Radio state
     */
    checked: boolean;
    /**
     * OnChange handler
     */
    onChange?: (checked: boolean) => void;
    /**
     * Input label to display
     */
    label?: string;
    /**
     * Input label position
     */
    labelPosition?: 'before' | 'after';
    /**
     * Use bold label
     */
    labelBold?: boolean;
    /**
     * Show indeterminate state
     */
    indeterminate?: boolean;
    /**
     * Use clickable label
     */
    labelIsClickable?: boolean;
    labelVariant?: GvlLabelVariant;
    className?: string;
    classNameLabel?: string;
    id?: string;
    track?: boolean;
    disabled?: boolean;
}

export const GivelifyCheckbox: React.FCC<GivelifyCheckboxProps> = (props) => {
    const {
        ariaLabel,
        checked,
        name,
        className,
        classNameLabel,
        defaultValue,
        indeterminate,
        label,
        labelBold,
        labelPosition,
        labelVariant = 'body1',
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        onChange,
        value,
        variant = 'secondary',
        labelIsClickable = false,
        id,
        track = true,
        disabled,
    } = props;
    const {
        boxMargin,
        radioContainer,
        labelAfter,
        labelBefore,
        checkedStyle,
        rootStyle,
    } = checkboxStyles({
        variant,
        margin,
        marginLeft,
        marginBottom,
        marginRight,
        marginTop,
        labelIsClickable,
    });
    const rootClassName = mergeClassNames(boxMargin, radioContainer, className);
    const classNameLabelAfter = mergeClassNames(labelAfter, classNameLabel);
    const classNameLabelBefore = mergeClassNames(labelBefore, classNameLabel);
    const { trackEvent } = useTrackingContext();
    const onClick = () => {
        if (props.name && track) {
            trackEvent(`<${props.name}>_${CHECKBOX_IDENTIFIER}`);
        }
        if (onChange) {
            onChange(!checked);
        }
    };
    return (
        <div
            id={`${id ? `${id}-container` : ''}`}
            className={rootClassName}
            onClick={labelIsClickable && label ? onClick : undefined}
        >
            {label && labelPosition === 'before' ? (
                <GivelifyLabel
                    className={classNameLabelBefore}
                    text={label}
                    variant={labelVariant}
                    bold={labelBold}
                />
            ) : null}
            <Checkbox
                classes={{
                    root: rootStyle,
                    checked: checkedStyle,
                }}
                checked={checked}
                disabled={disabled}
                onClick={labelIsClickable && label ? undefined : onClick}
                value={value}
                name={name}
                inputProps={{ 'aria-label': ariaLabel }}
                color="default"
                indeterminate={indeterminate}
                defaultValue={defaultValue}
                disableFocusRipple
                disableTouchRipple
                disableRipple
            />
            {label && labelPosition !== 'before' ? (
                <GivelifyLabel
                    className={classNameLabelAfter}
                    text={label}
                    variant={labelVariant}
                    bold={labelBold}
                />
            ) : null}
        </div>
    );
};
