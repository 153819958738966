import { useCallback } from 'react';
import { Method } from 'axios';
import { getAxiosClient } from '../client';
import { makeApiRequest } from '../requests';
import { useApiRequest } from './useApiRequests';

/**
 * Hook returns a function that can be used to make requests at a later time
 * (Such as in response to user interactions)
 *
 * @param {string} url - endpoint for the request
 * @param {Method} method - Http request method
 * @param {T} data
 */
export const useInvokeApi = <Response, Request = unknown>() => {
    const [state, make] = useApiRequest<Response>();
    const makeRequest = useCallback(
        (method: Method, url: string, data?: Request) => {
            let request = undefined;
            const axiosClient = getAxiosClient(undefined, {
                caseMiddleware: {
                    requestTransformer: (data: any) => data,
                },
            });
            switch (method) {
                case 'GET':
                    request = axiosClient.get(url);
                    break;
                case 'POST':
                    request = axiosClient.post(url, data);
                    break;
                case 'PUT':
                    request = axiosClient.put(url, data);
                    break;
                case 'DELETE':
                    request = axiosClient.delete(url);
                    break;
                case 'PATCH':
                    request = axiosClient.patch(url, data);
                    break;
                default:
                    break;
            }
            if (request !== undefined) {
                make(makeApiRequest(request));
            }
        },
        [make],
    );
    return [state, makeRequest] as const;
};

export type InvokeApiErrorResponse = { error: { message: string } };
