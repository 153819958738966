import {
    BAR_IDENTIFIER,
    BUTTON_IDENTIFIER,
    CHECKBOX_IDENTIFIER,
    DROPDOWN_IDENTIFIER,
    LINK_IDENTIFIER,
    SECTOR_IDENTIFIER,
} from '@givelify/utils';

export const USER_LOGOUT = `(Avatar) <Sing out>_${LINK_IDENTIFIER}`;
export const GO_TO_EDIT_PROFILE = `(Avatar) <Edit Profile>_${LINK_IDENTIFIER}`;
export const SELECT_DIFFERENT_CHURCH = 'churchFilter_change';
export const SELECT_DIFFERENT_TIME_FRAME = 'timeframeFilter_change';
export const UPDATE_USER_PHOTO = 'UserPhoto_upload';

export const INTEGRATIONS_REPORTS_DOWNLOAD_REPORT = 'Download_Report';
export const INTEGRATIONS_REPORTS_REGENERATE_REPORT = 'Regenerate_Report';
export const INTEGRATIONS_MATCH_DONOR_MODAL_OPEN = 'Donors_Modal_Open';
export const INTEGRATIONS_MATCH_DONOR_MODAL_CLOSE = 'Donors_Modal_Close';
export const INTEGRATIONS_MATCH_DONOR = 'Match_Donor';
export const INTEGRATIONS_MATCH_ENVELOPE_MODAL_OPEN = 'Envelopes_Modal_Open';
export const INTEGRATIONS_MATCH_ENVELOPE_MODAL_CLOSE = 'Envelopes_Modal_Close';
export const INTEGRATIONS_MATCH_ENVELOPE = 'Match_Envelope';
export const INTEGRATIONS_CREATE = 'Integrations_Create';
export const INTEGRATIONS_UPDATE = 'Integrations_Update';

//LOGIN page events
export const LOGIN_PAGE_CLICK_SHOW_PASS = `<Show Pass>_${CHECKBOX_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_SIGN_IN = `<Sign In>_${BUTTON_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_SIGN_UP = `<Sign Up>_${LINK_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_FORGOT_PASSWORD = `<Forgot Pass>_${LINK_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_FORGOT_PASSWORD_CLOSE = `(Reset Pass Box) <DismissX>_${BUTTON_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_FORGOT_PASSWORD_SUBMIT = `(Reset Pass Box) <Send Reset Link>_${BUTTON_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_GIVE_NOW = `<Give Now>_${BUTTON_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_SUPPORT_LINK = `<Support>_${LINK_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_PRIVACY_LINK = `<Privacy Policy>_${LINK_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_TERMS_LINK = `<Terms of Use>_${LINK_IDENTIFIER}`;
export const LOGIN_PAGE_CLICK_HELP = `<Email Not Verified Support>_${LINK_IDENTIFIER}`;
export const LOGIN_PAGE_VIEW_NOT_VERIFIED = '<Email Not Verified Warning>';

//NAVIGATION
export const NAVIGATION_CLICK_LINK = `Navigation <TARGET>_${LINK_IDENTIFIER}`;
export const NAVIGATION_CLICK_SELECT_CAMPUS = `<Select Campus>_${BUTTON_IDENTIFIER}`;
export const NAVIGATION_CLICK_OLD_DASHBOARD_LINK = `<Login to old dashboard>_${LINK_IDENTIFIER}`;
export const NAVIGATION_CLICK_GO_TO_REPORTS = `Old dashboard reports custom modal <Go to Reports>_${BUTTON_IDENTIFIER}`;
export const NAVIGATION_CLICK_GO_TO_OLD_DASHBOARD = `Old dashboard reports custom modal <Continue to Old Dashboard>_${BUTTON_IDENTIFIER}`;
export const NAVIGATION_VIEW_OLD_DASHBOARD_NAVIGATION_PROMPT =
    'View Old dashboard reports custom modal';
export const NAVIGATION_CAMPUS_SELECTOR = `<Select Campus>_${DROPDOWN_IDENTIFIER}`;

// OVERVIEW page events
export const TREND_CHART_BAR_CLICK = `<Trend chart>_${BAR_IDENTIFIER}`;
export const TOP_ENVELOPES_CHART_SECTOR_CLICK = `<Top envelopes chart>_${SECTOR_IDENTIFIER}`;
export const GIVING_STYLE_CHART_SECTOR_CLICK = `<Giving style chart>_${SECTOR_IDENTIFIER}`;
export const OVERVIEW_PAGE_GIVING_TREND_BAR = `<Giving trend chart>_${BAR_IDENTIFIER}`;

//DONORS and DONOR PROFILE page events
export const DONOR_PROFILE_PAGE_CLICK_BACK = `<Back>_${LINK_IDENTIFIER}`;
export const DONOR_PROFILE_PAGE_CLICK_DONATIONS_HISTORY_LINK = `<Donations History>_${LINK_IDENTIFIER}`;
export const DONOR_PROFILE_PAGE_CLICK_MESSAGE_HISTORY_LINK = `<Message History>_${LINK_IDENTIFIER}`;
export const DONOR_PROFILE_PAGE_CLICK_DONATION_AMOUNT = `<Donation Amount>_${LINK_IDENTIFIER}`;
export const DONOR_PROFILE_PAGE_CLICK_DONOR_EMAIL_LINK = `<Donor Email>_${LINK_IDENTIFIER}`;
export const DONOR_PROFILE_PAGE_CLICK_EDIT_MEMBER_ID_LINK = 'Edit Member ID';
export const DONOR_PROFILE_PAGE_CLICK_SAVE_EDIT_MEMBER_ID_LINK =
    'Save Member ID';
export const DONOR_PROFILE_PAGE_CLICK_CANCEL_EDIT_MEMBER_ID_LINK =
    'Cancel Edit Member ID';
export const NEW_DONORS_PAGE_CLICK_DONOR_NAME_LINK = `<Donor Name>_${LINK_IDENTIFIER}`;

//DONATIONS page events
export const DONATIONS_PAGE_CLICK_OPEN_DETAILS_LINK = 'View Details';
export const DONATIONS_PAGE_CLICK_CLOSE_DETAILS_LINK = 'Close Details';
export const DONATIONS_PAGE_CLICK_SEND_MESSAGE_SBMT = `<Send Messege>_${BUTTON_IDENTIFIER}`;

//BANK DEPOSITS page events
export const BANK_DEPOSITS_PAGE_CLICK_DEPOSIT_AMOUNT_LINK = `<Deposit Amount>_${LINK_IDENTIFIER}`;
export const BANK_DEPOSITS_REFUNDS_PAGE_DETAILS_LNK = `<Donation Details>_${LINK_IDENTIFIER}`;
