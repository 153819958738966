import { Donee, Onboarding } from '@givelify/api';
import {
    DirectDepositInfo,
    ImageWithDimensions,
    OnboardingInfo,
    OrganizationType,
} from '../@types/assets/onboarding';
import {
    AppProfile,
    PrimaryRepresentativeInfo,
    TaxIdInfo,
} from './../@types/assets/onboarding';

type DimensionsAPI = {
    width: number;
    height: number;
    x: number;
    y: number;
};

const getImageWithDimensions = (
    original: string | undefined | null,
    cropped: string | undefined | null,
    dimensions: string | null,
): ImageWithDimensions | undefined => {
    if (!original || !cropped) {
        return undefined;
    }

    const area: DimensionsAPI = dimensions ? JSON.parse(dimensions) : {};

    return {
        url: original,
        croppedUrl: cropped,
        dimensions: {
            croppedArea: {
                width: area.width,
                height: area.height,
                x: area.x,
                y: area.y,
            },
            croppedAreaPixels: {
                width: area.width,
                height: area.height,
                x: area.x,
                y: area.y,
            },
        },
    };
};

const getOrganizationType = (
    type: string | undefined | null,
): OrganizationType => (type && type === 'nonprofit' ? 'nonprofit' : 'church');

export const convertOnboardingToDirectDepositInfo = (
    onboarding: Onboarding,
): DirectDepositInfo => {
    const bankInfo = onboarding.bankInfo ? onboarding.bankInfo : undefined;
    return {
        status: bankInfo?.status || 'unchecked',
        retryMessage: bankInfo?.retryMessage,
        accountNumber: bankInfo?.accountNumber || '',
        routingNumber: bankInfo?.routingNumber || '',
        bankingAddress: bankInfo
            ? {
                  city: onboarding.bankInfo.billingCity || '',
                  state: onboarding.bankInfo.billingState || '',
                  street: onboarding.bankInfo.billingAddress || '',
                  zip: onboarding.bankInfo.billingZip || '',
              }
            : {
                  city: '',
                  state: '',
                  street: '',
                  zip: '',
              },
        bankChequeFile: undefined,
        submittedAt: bankInfo?.submittedAt || null,
        submittedByOfficialId: bankInfo?.submittedByOfficialId || null,
    };
};

export const convertDoneeToDirectDepositInfo = ({
    onboarding,
}: Donee): DirectDepositInfo => {
    return onboarding
        ? convertOnboardingToDirectDepositInfo(onboarding)
        : {
              status: 'unchecked',
              accountNumber: '',
              routingNumber: '',
              bankingAddress: {
                  city: '',
                  state: '',
                  street: '',
                  zip: '',
              },
              bankChequeFile: undefined,
              submittedAt: null,
              submittedByOfficialId: null,
          };
};

export const convertOnboardingToAppProfile = (
    onboarding: Onboarding,
    missionStatement: string,
): AppProfile => {
    const coverPhoto = onboarding.appProfile
        ? onboarding.appProfile.coverPhoto
        : undefined;
    const faithLeader = onboarding.appProfile
        ? onboarding.appProfile.faithLeader
        : undefined;
    const organizationLogo = onboarding.appProfile
        ? onboarding.appProfile.organizationLogo
        : undefined;
    const organizationAddress = onboarding.appProfile
        ? onboarding.appProfile.organizationAddress
        : undefined;

    const accountOwner = onboarding.appProfile
        ? onboarding.appProfile.accountOwner
        : undefined;

    return {
        bannerImage: coverPhoto
            ? getImageWithDimensions(
                  coverPhoto.coverPhotoOriginal,
                  coverPhoto.coverPhoto,
                  coverPhoto.coverPhotoCroppedCoordinates,
              )
            : undefined,
        doNotDisplayFaithLeader: !faithLeader?.userId,
        faithLeader: faithLeader
            ? {
                  userId: faithLeader.userId || 0,
                  firstName: faithLeader.firstName || '',
                  lastName: faithLeader.lastName || '',
                  title: faithLeader.title || '',
                  avatar: faithLeader.avatar || '',
                  avatarCroppedCoordinates:
                      faithLeader.avatarCroppedCoordinates || '',
                  avatarOriginal: faithLeader.avatarOriginal || '',
              }
            : undefined,
        faithLeaderImage: faithLeader
            ? getImageWithDimensions(
                  faithLeader.avatarOriginal,
                  faithLeader.avatar,
                  faithLeader.avatarCroppedCoordinates,
              )
            : undefined,
        missionStatement: missionStatement,
        organizationAddress: {
            city: organizationAddress?.city || '',
            phone: organizationAddress?.phone || '',
            state: organizationAddress?.state || '',
            street: organizationAddress?.street || '',
            zip: organizationAddress?.zip || '',
        },
        organizationLogo: organizationLogo
            ? getImageWithDimensions(
                  organizationLogo.logoOriginal,
                  organizationLogo.logo,
                  organizationLogo.logoCroppedCoordinates,
              )
            : undefined,
        accountOwner: {
            avatar: accountOwner?.avatar || '',
            avatarCroppedCoordinates:
                accountOwner?.avatarCroppedCoordinates || '',
            avatarOriginal: accountOwner?.avatarOriginal || '',
            firstName: accountOwner?.firstName || '',
            lastName: accountOwner?.lastName || '',
            title: accountOwner?.title || '',
            userId: accountOwner?.userId || null,
        },
    };
};

export const convertDoneeToAppProfile = ({
    onboarding,
    missionStatement,
}: Donee): AppProfile => {
    return onboarding
        ? convertOnboardingToAppProfile(onboarding, missionStatement || '')
        : {
              bannerImage: undefined,
              faithLeaderImage: undefined,
              missionStatement: missionStatement || '',
              organizationAddress: {
                  city: '',
                  phone: '',
                  state: '',
                  street: '',
                  zip: '',
              },
              organizationLogo: undefined,
              faithLeader: undefined,
              doNotDisplayFaithLeader: true,
              accountOwner: undefined,
          };
};

export const convertOnboardingToPrimaryRepresentativeInfo = (
    onboarding: Onboarding,
): PrimaryRepresentativeInfo => {
    const primaryRepresentative = onboarding.primaryRepresentative;
    return {
        status: primaryRepresentative
            ? primaryRepresentative.status || 'unchecked'
            : 'unchecked',
        retryMessage: primaryRepresentative.retryMessage,
        primaryRepresentativeAddress: {
            city: primaryRepresentative?.city || '',
            phone: primaryRepresentative?.phoneNumber || '',
            state: primaryRepresentative?.state || '',
            street: primaryRepresentative?.address || '',
            zip: primaryRepresentative?.zip || '',
        },
        isPrimaryRepresentative:
            primaryRepresentative?.isPrimaryRepresentative || false,
        birthDate: primaryRepresentative?.dateOfBirth
            ? new Date(primaryRepresentative?.dateOfBirth)
            : null,
        firstName: primaryRepresentative?.firstName || '',
        lastName: primaryRepresentative?.lastName || '',
        socialSecurityNumber: primaryRepresentative?.ssn || '',
        title: primaryRepresentative?.title || '',
        beneficiaryIdFile: undefined,
        submittedAt: primaryRepresentative
            ? primaryRepresentative.submittedAt
            : null,
        submittedByOfficialId: primaryRepresentative
            ? primaryRepresentative.submittedByOfficialId
            : null,
    };
};

export const convertDoneeToPrimaryRepresentativeInfo = ({
    onboarding,
}: Donee): PrimaryRepresentativeInfo => {
    return onboarding
        ? convertOnboardingToPrimaryRepresentativeInfo(onboarding)
        : {
              birthDate: null,
              firstName: '',
              beneficiaryIdFile: undefined,
              isPrimaryRepresentative: false,
              lastName: '',
              primaryRepresentativeAddress: {
                  city: '',
                  phone: '',
                  state: '',
                  street: '',
                  zip: '',
              },
              socialSecurityNumber: '',
              status: 'unchecked',
              title: '',
              submittedAt: null,
              submittedByOfficialId: null,
          };
};

export const convertOnboardingToTaxIdInfo = (
    onboarding: Onboarding,
): TaxIdInfo => {
    const ein = onboarding.ein;
    return {
        status: ein ? ein.status || 'unchecked' : 'unchecked',
        retryMessage: ein.retryMessage,
        hasTaxId: !!(ein && ein.ein),
        taxId: ein ? ein.ein || '' : '',
        organizationYear: ein ? ein.organizationYear?.toString() || '' : '',
        congregationSize: ein ? ein.congregationSize || '' : '',
        religiousAffiliation: ein
            ? ein.religiousAffiliation?.toString() || ''
            : '',
        einFile: undefined,
        submittedAt: ein ? ein.submittedAt : null,
        submittedByOfficialId: ein ? ein.submittedByOfficialId : null,
    };
};

export const convertDoneeToTaxIdInfo = ({ onboarding }: Donee): TaxIdInfo => {
    return onboarding
        ? convertOnboardingToTaxIdInfo(onboarding)
        : {
              status: 'unchecked',
              hasTaxId: false,
              taxId: '',
              organizationYear: '',
              congregationSize: '',
              religiousAffiliation: '',
              einFile: undefined,
              submittedAt: null,
              submittedByOfficialId: null,
          };
};

export const convertDoneeToOnboardingInfo = ({
    id,
    onboarding,
    missionStatement,
    name,
    type,
}: Donee): OnboardingInfo => {
    return {
        doneeId: id,
        appProfile: convertOnboardingToAppProfile(
            onboarding,
            missionStatement || '',
        ),
        directDeposit: convertOnboardingToDirectDepositInfo(onboarding),
        primaryRepresentative:
            convertOnboardingToPrimaryRepresentativeInfo(onboarding),
        taxId: convertOnboardingToTaxIdInfo(onboarding),
        onboardingCompleted: onboarding.hasCompleted || false,
        organizationName: name || '',
        organizationType: getOrganizationType(type),
    };
};
