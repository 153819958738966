import React, { useCallback, useMemo } from 'react';
import { GivelifyForm } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { I18N_NAMESPACE } from '../../../../../consts';
import {
    routingNumberStartsWith,
    validateSequence,
} from '../../../../../utils/validation';

import { AccountInfoFormProps, FormSchema } from './types';
import View from './view';

const AccountInfo: React.FCC<AccountInfoFormProps> = ({
    data,
    bankName,
    onCancelClick,
    onContinueClick,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const TRANSLATION_KEY_VALIDATION =
        'directDepositManualEntry.textFields.validation';
    const scopedTranslateValidation = useCallback(
        (key: string, args = undefined) =>
            t(`${TRANSLATION_KEY_VALIDATION}.${key}`, args),
        [t],
    );

    const copy = useMemo(
        () => ({
            routingNumber: {
                length: scopedTranslateValidation(`routingNumber.length`),
                startsWith: scopedTranslateValidation(
                    `routingNumber.startsWith`,
                ),
                consecutive: scopedTranslateValidation(
                    `routingNumber.consecutive`,
                ),
                space: scopedTranslateValidation(`routingNumber.space`),
            },
            accountNumber: {
                length: scopedTranslateValidation(`accountNumber.length`),
                consecutive: scopedTranslateValidation(
                    `accountNumber.consecutive`,
                ),
                space: scopedTranslateValidation(`accountNumber.space`),
                startsWith: scopedTranslateValidation(`accountNumber.space`),
                match: scopedTranslateValidation(`accountNumber.match`),
            },
        }),
        [scopedTranslateValidation],
    );

    const schema: yup.SchemaOf<FormSchema> = yup.object().shape({
        routingNumber: yup
            .string()
            .required()
            .test({
                test: routingNumberStartsWith,
                message: copy.routingNumber.startsWith,
            })
            .test({
                test: (value = '') => validateSequence(value, 9),
                message: copy.routingNumber.consecutive,
            })
            .matches(/^\d+$/, copy.routingNumber.space)
            .length(9, copy.routingNumber.length),
        showAccountNumber: yup.bool(),
        bankName: yup.string(),
        accountNumber: yup.string().when('showAccountNumber', {
            is: true,
            then: (schema) =>
                schema
                    .test({
                        test: (value = '') => validateSequence(value, 4),
                        message: copy.accountNumber.consecutive,
                    })
                    .matches(/^\d+$/, copy.accountNumber.space)
                    .min(4, copy.accountNumber.length)
                    .when('retypeAccountNumber', {
                        is: (val: any) => !!val,
                        then: (schema) =>
                            schema.oneOf(
                                [yup.ref('retypeAccountNumber')],
                                copy.accountNumber.match,
                            ),
                    }),
        }),
        retypeAccountNumber: yup.string().when('showAccountNumber', {
            is: true,
            then: (schema) =>
                schema
                    .test({
                        test: (value = '') => validateSequence(value, 4),
                        message: copy.accountNumber.consecutive,
                    })
                    .matches(/^\d+$/, copy.accountNumber.space)
                    .min(4, copy.accountNumber.length)
                    .oneOf(
                        [yup.ref('accountNumber')],
                        copy.accountNumber.match,
                    ),
        }),
    });

    return (
        <GivelifyForm<FormSchema>
            defaultValues={{
                routingNumber: data.routingNumber?.toString(),
                accountNumber: data.accountNumber?.toString(),
                showAccountNumber: !!data.accountNumber,
                retypeAccountNumber: data.accountNumber?.toString(),
                bankName,
            }}
            onSubmit={(
                { showAccountNumber, accountNumber, routingNumber, bankName },
                form,
            ) => {
                if (showAccountNumber) {
                    onContinueClick(
                        routingNumber,
                        accountNumber || '',
                        bankName || '',
                    );
                } else {
                    form.setValue('showAccountNumber', true);
                    form.setError('showAccountNumber', { type: 'error' });
                }
            }}
            schema={schema}
        >
            <View
                bankName={bankName}
                data={data}
                onCancelClick={onCancelClick}
                onContinueClick={onContinueClick}
            />
        </GivelifyForm>
    );
};

export default AccountInfo;
