import React from 'react';
import { GivelifyModal } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';
import { CustomModalContent } from './CustomModalContent';

const useStyle = makeStyles((theme: Theme) => ({
    content: {
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 32px)',
        },
    },
}));

export default function useCustomModal() {
    const cs = useStyle();
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const modalToRender = () => (
        <GivelifyModal
            disableBackdropClick
            showCloseButton
            contentClassName={cs.content}
            onClose={() => {
                setIsModalOpen(false);
            }}
            open={isModalOpen}
            testId="custom-modal"
        >
            <CustomModalContent />
        </GivelifyModal>
    );

    return [() => setIsModalOpen(true), modalToRender] as const;
}
