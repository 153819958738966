import React, { useMemo } from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { inviteDoneModalStyle } from './inviteDoneModalStyle';

interface InviteDoneModalProps {
    onClose: () => void;
    onAddAnother: () => void;
    userName: string;
}

const InviteDoneModal: React.FCC<InviteDoneModalProps> = (
    props: InviteDoneModalProps,
) => {
    const { onClose, onAddAnother, userName } = props;
    const styles = inviteDoneModalStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            success: t('pages.settings.users.success-modal.heading'),
            added: t('pages.settings.users.success-modal.text'),
            linkSent: t('pages.settings.users.success-modal.linkSent'),
            linkExpiration: t(
                'pages.settings.users.success-modal.linkExpiration',
            ),
            backButton: t('pages.settings.users.success-modal.backButton'),
            continueButton: t(
                'pages.settings.users.success-modal.continueButton',
            ),
        }),
        [t],
    );

    return (
        <GivelifyModal
            centerButtons
            open
            showCloseButton
            onClose={onClose}
            onPrimaryClick={onAddAnother}
            onSecondaryClick={onClose}
            primaryButtonText={copy.continueButton}
            secondaryButtonText={copy.backButton}
        >
            <div className={styles.modalContent}>
                <GivelifyIcon size={64} variant="insititution-new-checkmark" />
                <GivelifyLabel
                    marginTop={8}
                    text={copy.success}
                    variant="heading3"
                />
                <GivelifyLabel
                    marginTop={32}
                    text={`${userName} ${copy.added}`}
                    variant="heading5"
                />
                <GivelifyLabel
                    marginTop={8}
                    text={copy.linkSent}
                    variant="body1"
                />
                <GivelifyLabel
                    marginBottom={32}
                    text={copy.linkExpiration}
                    variant="body1"
                />
            </div>
        </GivelifyModal>
    );
};

export default InviteDoneModal;
