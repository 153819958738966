import React, { useMemo } from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { GivelifyLabel, DesignTokens } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';
import { useAdvancedTranslation } from 'utils/i18n';

interface NoItemsPanelProps {
    type: 'shown' | 'hidden';
}

const useStyles = makeStyles(() => ({
    wrapper: {
        minHeight: 180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: DesignTokens.color.backgroundPrimary,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: DesignTokens.border.borderComponentDisabled.color,
        borderRadius: 8,
        padding: 16,
    },
}));

const NoItemsPanel: React.FCC<NoItemsPanelProps> = ({ type }) => {
    const classes = useStyles();

    const { scopedATranslate } = useAdvancedTranslation(
        'pages.settings.envelopes2',
    );

    const copy = useMemo(
        () => ({
            noActiveEnvelopesText: scopedATranslate(
                'active-envelopes-tab.noEnvelopesText',
            ),
            noActiveEnvelopesDescription: scopedATranslate(
                'active-envelopes-tab.noEnvelopesDescription',
            ),
            noInactiveEnvelopesText: scopedATranslate(
                'inactive-envelopes-tab.noEnvelopesText',
            ),
            noInactiveEnvelopesDescription: scopedATranslate(
                'inactive-envelopes-tab.noEnvelopesDescription',
            ),
        }),
        [scopedATranslate],
    );

    return (
        <div className={classes.wrapper}>
            <GivelifyIcon marginBottom={8} size={64} variant="giving-money" />
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                text={
                    type === 'shown'
                        ? copy.noActiveEnvelopesText
                        : copy.noInactiveEnvelopesText
                }
                textAlign="center"
                variant="heading3S"
            />
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                text={
                    type === 'shown'
                        ? copy.noActiveEnvelopesDescription
                        : copy.noInactiveEnvelopesDescription
                }
                textAlign="center"
                variant="body1"
            />
        </div>
    );
};

export default NoItemsPanel;
