import React from 'react';
import { getAxiosClient, useApiRequest } from '@givelify/utils';
import { GetReportDetailsResponse } from 'api/services/responses';
import { getReportsService } from 'api/utils/serviceProvider';
import { useSearchParams } from 'react-router-dom';
import ReportHistory from './index';

export const ReportHistoryDownloadWrapper: React.FCC = () => {
    const [searchParams] = useSearchParams();
    const service = getReportsService();
    const [reportDetailsRequest, makeReportDetailsRequest] =
        useApiRequest<GetReportDetailsResponse>();
    React.useEffect(() => {
        const reportIdString = Array.from(searchParams.entries()).find(
            ([key]) => key.toLowerCase() === 'reportid',
        )?.[1];

        const reportId = parseInt(reportIdString);
        if (!isNaN(reportId) && reportId > 0) {
            makeReportDetailsRequest(service.getReportDetailsById(reportId));
        }
    }, [makeReportDetailsRequest, searchParams, service]);
    React.useEffect(() => {
        if (reportDetailsRequest.type === 'REQUEST_SUCCESS') {
            getAxiosClient({ responseType: 'blob', withCredentials: true })
                .get(reportDetailsRequest.response.downloadLink)
                .then((r) => {
                    const href = URL.createObjectURL(r.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute(
                        'download',
                        reportDetailsRequest.response.fileName,
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
        }
    }, [reportDetailsRequest]);
    return <ReportHistory />;
};
