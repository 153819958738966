import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import {
    Switch,
    SwitchClassKey,
    SwitchProps,
    createStyles,
    Theme,
    withStyles,
} from '@material-ui/core';
import { switchStyles } from './styles';
import { GvlSwitchLabelPosition, GvlSwitchVariant } from './types';
import { GivelifyLabel } from '../label';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

export interface GivelifySwitchProps extends GivelifyBoxMarginProps {
    id?: string;
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (checked: boolean) => void;
    text?: string;
    variant?: GvlSwitchVariant;
    textPosition?: GvlSwitchLabelPosition;
    textIsBold?: boolean;
    name?: string;
    labelOn?: string;
    labelOff?: string;
    className?: string;
    disabled?: boolean;
}

export const GivelifySwitch: React.FCC<GivelifySwitchProps> = (props) => {
    const {
        boxMargin,
        switchWithBeforeAfterText,
        textBefore,
        textAfter,
        thickSwitchContainer,
        thickSwitchTrack,
        thickSwitchInput,
        thickSwitchThumb,
        thickSwitchLabelOn,
        thickSwitchLabelOff,
    } = switchStyles({
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
    });

    if (!props.variant || props.variant === 'default') {
        if (props.text) {
            switch (props.textPosition) {
                case 'before':
                case undefined:
                    return renderSwitchWithTextBefore(
                        renderDefaultSwitch(
                            props.id,
                            props.checked,
                            props.defaultChecked,
                            props.onChange,
                            props.name,
                            undefined,
                            props.disabled,
                        ),
                        props.text,
                        boxMargin,
                        switchWithBeforeAfterText,
                        textBefore,
                        props.className,
                        props.textIsBold,
                    );
                case 'after':
                    return renderSwitchWithTextAfter(
                        renderDefaultSwitch(
                            props.id,
                            props.checked,
                            props.defaultChecked,
                            props.onChange,
                            props.name,
                            undefined,
                            props.disabled,
                        ),
                        props.text,
                        boxMargin,
                        switchWithBeforeAfterText,
                        textAfter,
                        props.className,
                        props.textIsBold,
                    );
            }
        }
        const cn = mergeClassNames(boxMargin, props.className);
        return renderDefaultSwitch(
            props.id,
            props.checked,
            props.defaultChecked,
            props.onChange,
            props.name,
            cn,
            props.disabled,
        );
    }
    if (props.variant === 'thick') {
        if (props.text) {
            switch (props.textPosition) {
                case 'before':
                case undefined:
                    return renderSwitchWithTextBefore(
                        renderThickSwitch(
                            thickSwitchContainer,
                            thickSwitchInput,
                            thickSwitchTrack,
                            thickSwitchThumb,
                            thickSwitchLabelOn,
                            thickSwitchLabelOff,
                            props.id,
                            props.checked,
                            props.defaultChecked,
                            props.onChange,
                            props.name,
                        ),
                        props.text,
                        boxMargin,
                        switchWithBeforeAfterText,
                        textBefore,
                        props.className,
                        props.textIsBold,
                    );
                case 'after':
                    return renderSwitchWithTextAfter(
                        renderThickSwitch(
                            thickSwitchContainer,
                            thickSwitchInput,
                            thickSwitchTrack,
                            thickSwitchThumb,
                            thickSwitchLabelOn,
                            thickSwitchLabelOff,
                            props.id,
                            props.checked,
                            props.defaultChecked,
                            props.onChange,
                            props.name,
                        ),
                        props.text,
                        boxMargin,
                        switchWithBeforeAfterText,
                        textAfter,
                        props.className,
                        props.textIsBold,
                    );
            }
        }
        const cn = mergeClassNames(boxMargin, props.className);
        return renderThickSwitch(
            thickSwitchContainer,
            thickSwitchInput,
            thickSwitchTrack,
            thickSwitchThumb,
            thickSwitchLabelOn,
            thickSwitchLabelOff,
            props.id,
            props.checked,
            props.defaultChecked,
            props.onChange,
            props.name,
            undefined,
            undefined,
            undefined,
            cn,
        );
    }
    if (props.text) {
        switch (props.textPosition) {
            case 'before':
            case undefined:
                return renderSwitchWithTextBefore(
                    renderThickSwitch(
                        thickSwitchContainer,
                        thickSwitchInput,
                        thickSwitchTrack,
                        thickSwitchThumb,
                        thickSwitchLabelOn,
                        thickSwitchLabelOff,
                        props.id,
                        props.checked,
                        props.defaultChecked,
                        props.onChange,
                        props.name,
                        true,
                        props.labelOn,
                        props.labelOff,
                    ),
                    props.text,
                    boxMargin,
                    switchWithBeforeAfterText,
                    textBefore,
                    props.className,
                    props.textIsBold,
                );
            case 'after':
                return renderSwitchWithTextAfter(
                    renderThickSwitch(
                        thickSwitchContainer,
                        thickSwitchInput,
                        thickSwitchTrack,
                        thickSwitchThumb,
                        thickSwitchLabelOn,
                        thickSwitchLabelOff,
                        props.id,
                        props.checked,
                        props.defaultChecked,
                        props.onChange,
                        props.name,
                        true,
                        props.labelOn,
                        props.labelOff,
                    ),
                    props.text,
                    boxMargin,
                    switchWithBeforeAfterText,
                    textAfter,
                    props.className,
                    props.textIsBold,
                );
        }
    }
    const cn = mergeClassNames(boxMargin, props.className);
    return renderThickSwitch(
        thickSwitchContainer,
        thickSwitchInput,
        thickSwitchTrack,
        thickSwitchThumb,
        thickSwitchLabelOn,
        thickSwitchLabelOff,
        props.id,
        props.checked,
        props.defaultChecked,
        props.onChange,
        props.name,
        true,
        props.labelOn,
        props.labelOff,
        cn,
    );
};

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const DefaultSwitch = withStyles((theme: Theme) =>
    createStyles({
        switchBase: {
            color: theme.gas.palette.background.primary,
            '&$checked': {
                color: theme.gas.palette.primary,
                '&$disabled': {
                    color: theme.colors.accentViolet100,
                },
            },
            '&$checked + $track': {
                backgroundColor: theme.gas.palette.action.accent,
                opacity: 1,
            },
            '&$disabled': {
                color: theme.colors.guidingVioletLight,
            },
            '&$disabled + $track': {
                backgroundColor: theme.gas.palette.action.disabledBackground,
                opacity: 1,
            },
        },
        checked: {
            '&$disabled + $track': {
                backgroundColor: theme.colors.guidingVioletLight,
                opacity: 1,
            },
        },
        track: {},
        thumb: {},
        disabled: {},
    }),
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            id={props.id}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked,
                thumb: classes.thumb,
                disabled: classes.disabled,
            }}
            {...props}
        />
    );
});

function renderSwitchWithTextBefore(
    switchComponent: React.ReactNode,
    text: string,
    boxMargin: string,
    switchWithBeforeAfterText: string,
    textBefore: string,
    className?: string,
    textIsBold?: boolean,
    id?: string,
): React.ReactElement<any, any> | null {
    const cn = mergeClassNames(boxMargin, switchWithBeforeAfterText, className);
    return (
        <div id={id} className={cn}>
            <GivelifyLabel
                className={textBefore}
                text={text}
                variant="body1"
                bold={textIsBold}
            />
            {switchComponent}
        </div>
    );
}

function renderSwitchWithTextAfter(
    switchComponent: React.ReactNode,
    text: string,
    boxMargin: string,
    switchWithBeforeAfterText: string,
    textAfter: string,
    className?: string,
    textIsBold?: boolean,
    id?: string,
): React.ReactElement<any, any> | null {
    const cn = mergeClassNames(boxMargin, switchWithBeforeAfterText, className);
    return (
        <div id={id} className={cn}>
            {switchComponent}
            <GivelifyLabel
                className={textAfter}
                text={text}
                variant="body1"
                bold={textIsBold}
            />
        </div>
    );
}

function renderDefaultSwitch(
    id?: string,
    checked?: boolean,
    defaultChecked?: boolean,
    onChange?: (checked: boolean) => void,
    name?: string,
    className?: string,
    disabled?: boolean,
): React.ReactElement<any, any> | null {
    return (
        <div id={`${id ? `${id}-container` : ''}`}>
            <DefaultSwitch
                id={id}
                className={className}
                checked={checked}
                onChange={(_event, checked) => {
                    if (onChange) onChange(checked);
                }}
                name={name}
                defaultChecked={defaultChecked}
                disabled={disabled}
            />
        </div>
    );
}

function renderThickSwitch(
    thickSwitchContainer: string,
    thickSwitchInput: string,
    thickSwitchTrack: string,
    thickSwitchThumb: string,
    thickSwitchLabelOn: string,
    thickSwitchLabelOff: string,
    id?: string,
    checked?: boolean,
    defaultChecked?: boolean,
    onChange?: (checked: boolean) => void,
    name?: string,
    showLabels?: boolean,
    labelOn?: string,
    labelOff?: string,
    className?: string,
): React.ReactElement<any, any> | null {
    const cn = mergeClassNames(thickSwitchContainer, className);
    return (
        <div id={`${id ? `${id}-container` : ''}`}>
            <label id={id} className={cn}>
                <input
                    className={thickSwitchInput}
                    type="checkbox"
                    checked={checked}
                    defaultChecked={defaultChecked}
                    onChange={() => {
                        if (onChange) onChange(!checked);
                    }}
                    name={name}
                />
                <span className={thickSwitchTrack}>
                    {showLabels ? (
                        <GivelifyLabel
                            text={labelOn ? labelOn : 'On'}
                            variant="caption2"
                            bold
                            className={thickSwitchLabelOn}
                        />
                    ) : null}
                    {showLabels ? (
                        <GivelifyLabel
                            text={labelOff ? labelOff : 'Off'}
                            variant="caption2"
                            bold
                            className={thickSwitchLabelOff}
                        />
                    ) : null}
                    <span className={thickSwitchThumb}></span>
                </span>
            </label>
        </div>
    );
}
