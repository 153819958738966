import { DonationRow } from 'api/models';
import { PaginatedResponse } from '../../../types/paginationTypes';

export enum DONATIONS {
    SET = 'DONATIONS_SET',
    APPEND = 'DONATIONS_APPEND',
    CLEAR = 'DONATIONS_CLEAR',
}

export interface DonationsSet {
    data: PaginatedResponse<DonationRow>;
    type: DONATIONS.SET;
}

export interface DonationsAppend {
    data: PaginatedResponse<DonationRow>;
    type: DONATIONS.APPEND;
}

export interface DonationsClear {
    type: DONATIONS.CLEAR;
}
export type DonationsPageActions =
    | DonationsSet
    | DonationsAppend
    | DonationsClear;
