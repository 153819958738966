import { STATE_DROPDOWN_OPTIONS } from "@givelify/utils";

export interface Address {
    street: string;
    zip: string;
    state: string;
    city: string;
    country?: string;
}

export interface AddressWithPhone extends Address {
    phone: string;
}

export const getStateOption = (
    state: string | undefined,
): { id: string; label: string } | null => {
    if (!state) return null;
    const stByVal = STATE_DROPDOWN_OPTIONS.find((x) => x.id === state);
    if (stByVal) return stByVal;
    const stByName = STATE_DROPDOWN_OPTIONS.find(
        (x) => x.label.toLowerCase() === state.toLowerCase(),
    );
    if (stByName) return stByName;
    return null;
};

export const getStateValue = (state: string | undefined): string => {
    if (!state) return '';
    const stByVal = STATE_DROPDOWN_OPTIONS.find((x) => x.id === state);
    if (stByVal) return stByVal.id;
    const stByName = STATE_DROPDOWN_OPTIONS.find(
        (x) => x.label.toLowerCase() === state.toLowerCase(),
    );
    if (stByName) return stByName.id;
    return state;
};

export const getStateName = (state: string | undefined): string => {
    if (!state) return '';
    const st = STATE_DROPDOWN_OPTIONS.find((x) => x.id === state);
    if (st) return st.label;
    return state;
};
const streetRegex = /([A-z]+\s\d+)|(\d([a-zA-Z0-9]?)+\s[A-z]+)/;

const POBOXES = [
    'Box *',
    'Box-*',
    'Box*',
    'HC73 P.O. Box *',
    'P O Box*',
    'P. O. Box',
    'P.O *',
    'P.O*',
    'P.O',
    'PO.*',
    'PO. *',
    'PO.-*',
    'P.O. Box *',
    'P.O. Box',
    'P.O.B *',
    'P.O.B. *',
    'P.O.B. #*',
    'P.O.B.',
    'P0 Box',
    'PO *',
    'P.O. *',
    'P.O.',
    'PO Box N',
    'PO Box',
    'PO-Box',
    'POB *',
    'POB',
    'POBOX*',
    'POBOX *',
    'Po Box',
    'Post *',
    'Post Box *',
    'Post Office Box *',
    'Post Office Box',
    'box #*',
    'box *',
    'number *',
    'p box',
    'p-o box',
    'p-o-box',
    'p.o box',
    'p.o. box',
    'p.o.-box',
    'p.o.b. #*',
    'p.o.b.',
    'p/o box',
    'po #*',
    'po box *',
    'po box',
    'po num*',
    'po-box',
    'post office box',
    'Post Box #*',
    'postofficebox',
    '* PO Box',
    '* P.O. Box',
    '* P.O.B.',
    '* PO Box',
    'PO-*',
    'PO*',
    'PO.B.',
    'P.O.B',
    'PO.B',
    'P.OB.',
];

const POBOXES_REGEX = POBOXES.map(
    (pobox) =>
        pobox.toLowerCase().replace(/\./g, '\\.').replace(/\*/g, '\\d+') +
        '\\s?',
);

const combinedRegex = new RegExp(POBOXES_REGEX.join('|'), 'i');

export const isContainsPOBox = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    return combinedRegex.test(lowerCaseText);
};

export const isValidStreet = (
    street: string,
    isPOBoxNotAllowed: boolean,
): boolean => {
    if (street.length < 2) return false;
    try {
        if (!streetRegex.test(street)) return false;
    } catch (e) {
        return false;
    }
    if (isPOBoxNotAllowed) {
        return !isContainsPOBox(street);
    }
    return true;
};

export const isPOBoxInPOBoxNotAllowed = (
    street: string,
    isPOBoxNotAllowed: boolean,
): boolean => {
    if (isPOBoxNotAllowed) {
        return isContainsPOBox(street);
    }
    return false;
};

export const isValidCity = (city: string): boolean => city.length > 1;
export const isValidState = (state: string): boolean => state.length > 1;
export const isValidZip = (zip: string): boolean => zip.length === 5;

export const isValidAddress = (
    { street, city, state, zip }: Address | AddressWithPhone,
    isPOBoxNotAllowed: boolean,
): boolean => {
    return (
        isValidStreet(street, isPOBoxNotAllowed) &&
        isValidCity(city) &&
        isValidState(state) &&
        isValidZip(zip)
    );
};
