import { createContext, useContext, useState } from 'react';

const IntegrationContext = createContext({
    haveBanner: false,
    setHaveBanner: (value: boolean) => undefined,
});

export const useIntegrationContext = () => useContext(IntegrationContext);

export const IntegrationContextProvider: React.FCC = ({ children }) => {
    const [haveBanner, setHaveBanner] = useState<boolean>(false);

    return (
        <IntegrationContext.Provider
            value={{
                haveBanner,
                setHaveBanner,
            }}
        >
            {children}
        </IntegrationContext.Provider>
    );
};
