import React, { useCallback, useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyModal } from '@givelify/givelify-ui';
import { GivelifyButton } from '@givelify/ui';
import { Theme } from '@material-ui/core';
import { Slider, createStyles, makeStyles } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import {
    ImageDimensions,
    ImageWithDimensions,
} from '../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../consts';

export interface ImageCropperProps {
    url: string;
    aspectRatio: number;
    onClose: () => unknown;
    onApply: (dimensions: ImageWithDimensions) => unknown;
    onChangePicture: () => unknown;
    onDeletePicture: () => unknown;
    isOpen: boolean;
    isRound?: true;
    title: string | undefined;
    applyButtonText?: string;
    changeButtonText?: string;
    deleteButtonText?: string;
    isBackdropClickDisabled?: boolean;
    isLoading?: boolean;
}

const controllerHeight = 164;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cropContainer: {
            position: 'relative',
            width: '100%',
            height: 600,
            '& .crop-container': {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: controllerHeight,
            },
            '& .slider-wrapper': {
                textAlign: 'center',
                marginBottom: theme.spacing(4),
            },
            '& .button-wrapper': {
                marginTop: theme.spacing(2),
                display: 'flex',
                justifyContent: 'flex-end',
            },
            '& .change-button': {
                marginRight: 10,
            },
            '& .slider': {
                maxWidth: 640,
            },
            '& .slider span:first-child': {
                height: 4,
            },
            '& .MuiSlider-rail': {
                height: 4,
            },
            '& .slider span:last-child': {
                width: 28,
                height: 28,
                marginTop: -13,
                marginLeft: -11,
            },
            '& .MuiSlider-thumb': {
                width: 28,
                height: 28,
                marginTop: -13,
                marginLeft: -11,
            },
            '& .controls-wrapper': {
                padding: 30,
            },
            '& .actions': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            '& .delete-button': {
                cursor: 'pointer',
            },
            '& .controls': {
                color: theme.palette.primary.main,
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: controllerHeight,
            },

            [theme.breakpoints.down('xs')]: {
                '& .controls': {
                    height: 175,
                },
                '& .crop-container': {
                    bottom: 175,
                },
                '& .slider-wrapper': {
                    marginBottom: theme.spacing(2),
                },
                '& .actions': {
                    marginTop: theme.spacing(4),
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                '& .button-wrapper': {
                    marginTop: 40,
                },
                '& .change-button': {
                    marginBottom: 10,
                },
            },
        },
    }),
);

let dimensions: ImageDimensions | undefined = undefined;

export const ImageCropper: React.FCC<ImageCropperProps> = React.memo(
    ({
        title,
        url,
        aspectRatio,
        isOpen,
        isRound,
        onClose,
        onApply,
        onChangePicture,
        onDeletePicture,
        applyButtonText,
        deleteButtonText,
        changeButtonText,
        isBackdropClickDisabled = true,
        isLoading,
    }) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const { cropContainer } = useStyles();
        const [crop, setCrop] = useState({ x: 0, y: 0 });
        const [zoom, setZoom] = useState(1);

        const { applyText, changePhotoText, deletePhotoText } = useMemo(
            () => ({
                applyText: t('labels.apply'),
                changePhotoText: t('labels.changePhoto'),
                deletePhotoText: t('labels.deletePhoto'),
            }),
            [t],
        );

        const onApplyClicked = () => {
            const i = document.createElement('img');
            i.onload = () => {
                if (!dimensions) return;
                const { width, height, x, y } = dimensions.croppedAreaPixels;
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = isRound ? width : height;

                const context = canvas.getContext('2d');
                if (context != null) {
                    if (isRound) {
                        const r = width / 2;
                        context.save();
                        context.beginPath();
                        context.arc(r, r, r, 0, Math.PI * 2, true);
                        context.closePath();
                        context.clip();

                        context.drawImage(i, -x, -y);

                        context.beginPath();
                        context.arc(r, r, r, 0, Math.PI * 2, true);
                        context.clip();
                        context.closePath();
                        context.restore();
                    } else {
                        context.drawImage(i, -x, -y);
                    }
                    onApply({
                        dimensions,
                        url,
                        croppedUrl: canvas.toDataURL('image/png', 90),
                    });
                }
            };
            i.src = url;
        };
        const onCropComplete = useCallback(
            (croppedArea: Area, croppedAreaPixels: Area) => {
                dimensions = { croppedArea, croppedAreaPixels };
            },
            [],
        );
        return (
            <GivelifyModal
                autoFullscreen
                showCloseButton
                disableBackdropClick={isBackdropClickDisabled}
                onClose={onClose}
                open={isOpen}
            >
                {title && (
                    <GivelifyLabel
                        bold
                        marginBottom={14}
                        marginLeft={32}
                        text={title}
                        variant="heading2"
                    />
                )}
                <div className={cropContainer}>
                    <div className="crop-container">
                        <Cropper
                            showGrid
                            aspect={aspectRatio}
                            crop={crop}
                            cropShape={isRound ? 'round' : 'rect'}
                            image={url}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            zoom={zoom}
                        />
                    </div>
                    <div className="controls">
                        <div className="controls-wrapper">
                            <div className="slider-wrapper">
                                <Slider
                                    aria-labelledby="Zoom"
                                    className="slider"
                                    max={3}
                                    min={1}
                                    onChange={(
                                        _e: unknown,
                                        zoom: number | number[],
                                    ) =>
                                        // it will never be a number in this case
                                        // MUI forces you to put number[]
                                        typeof zoom === 'number'
                                            ? setZoom(zoom)
                                            : null
                                    }
                                    step={0.1}
                                    value={zoom}
                                />
                            </div>
                            <div className="actions">
                                <div
                                    className="delete-button"
                                    onClick={onDeletePicture}
                                >
                                    {deleteButtonText || deletePhotoText}
                                </div>
                                <div className="button-wrapper">
                                    <GivelifyButton
                                        className="change-button"
                                        onClick={onChangePicture}
                                        text={
                                            changeButtonText || changePhotoText
                                        }
                                    />
                                    <GivelifyButton
                                        className="change-button"
                                        isLoading={isLoading}
                                        onClick={onApplyClicked}
                                        text={applyButtonText || applyText}
                                        variant="primary"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GivelifyModal>
        );
    },
);
