import { useEffect, useState } from 'react';
import { useInvokeApi } from '@givelify/utils';
import { useDispatch } from 'react-redux';
import { UpdateReport } from 'store/integrations/actions';
import { ReportHistory, ReportState } from 'store/report/types';
import { mapReportResponse } from '../utils/mapReportResponse';

export const useRegenerateRequest = (
    reportId: number,
): [ReportState, () => void] => {
    const dispatch = useDispatch();
    const [reportStatus, setReportStatus] = useState<ReportState>();
    const [regenerateReportRequestState, regenerateReport] =
        useInvokeApi<ReportHistory>();

    const [getReportByIdState, getReportById] = useInvokeApi<ReportHistory>();

    useEffect(() => {
        if (regenerateReportRequestState.type === 'REQUEST_START') {
            setReportStatus('processing');
        }

        if (regenerateReportRequestState.type !== 'REQUEST_SUCCESS') return;

        getReportById(
            'GET',
            `reports/${regenerateReportRequestState.response?.id}`,
        );
    }, [regenerateReportRequestState, getReportById]);

    useEffect(() => {
        if (getReportByIdState.type !== 'REQUEST_SUCCESS') return;

        const report = mapReportResponse(getReportByIdState.response);

        if (getReportByIdState.response.report_state !== 'complete') {
            getReportById(
                'GET',
                `reports/${regenerateReportRequestState.response?.id}`,
            );
        } else {
            dispatch(UpdateReport(report, reportId));
            setReportStatus(report.reportState);
        }
    }, [
        dispatch,
        getReportById,
        getReportByIdState,
        regenerateReportRequestState,
        reportId,
    ]);

    return [reportStatus, () => regenerateReport('PUT', `reports/${reportId}`)];
};
