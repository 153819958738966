import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import { GivelifyTextField, isSsnValid } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyCheckbox,
    GivelifyDatePicker,
    GivelifyLabel,
} from '@givelify/ui';
import { debounce } from '@material-ui/core';
import { styled } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { RepUploadSection } from '../components/documentUpload/RepUploadSection';
import {
    isPrimaryRepresentativeStepOneDataValid,
    PrimaryRepresentativeStepRef,
    PrimaryRepresentativeStepOneProps,
} from './utils';

const BoxV = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(5),
}));

const BoxH = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
}));

const BoxZ = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
    },
    '& > *': {
        flex: 1,
    },
}));

const simpleValidation = (v: string) => v.length > 0;

const PrimaryRepresentativeStepOneComponent = (
    { data, onValidation, user }: PrimaryRepresentativeStepOneProps,
    forwardRef: ForwardedRef<PrimaryRepresentativeStepRef>,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            primaryRepresentativeInformation: t(
                'primaryRepresentativePage.primaryRepresentativeInformation',
            ),
            firstNamePlaceholder: t(
                'primaryRepresentativePage.firstNamePlaceholder',
            ),
            lastNamePlaceholder: t(
                'primaryRepresentativePage.lastNamePlaceholder',
            ),
            titlePlaceholder: t('primaryRepresentativePage.titlePlaceholder'),
            birthDatePlaceholder: t(
                'primaryRepresentativePage.birthDatePlaceholder',
            ),
            securityNoPlaceholder: t(
                'primaryRepresentativePage.securityNoPlaceholder',
            ),
            headerText: t('primaryRepresentativePage.headerText'),
            allFieldsRequired: t('labels.allFieldsRequired'),
            iAmPrimaryRepresentative: t(
                'primaryRepresentativePage.iAmPrimaryRepresentative',
            ),
            invalidSsnText: t('primaryRepresentativePage.invalidSsn'),
            mustBeOver18Text: t('primaryRepresentativePage.mustBeOver18'),
        }),
        [t],
    );
    const [isPrimaryRepresentative, setIsPrimaryRepresentative] =
        React.useState(data.isPrimaryRepresentative);
    const [beneficiaryIdFile, setBeneficiaryIdFile] = React.useState<
        File | undefined
    >();
    const [firstName, setFirstName] = React.useState(data.firstName);
    const [lastName, setLastName] = React.useState(data.lastName);
    const [birthDate, setBirthDate] = React.useState(data.birthDate);
    const [validBirthDate, setValidBirthDate] = React.useState(true);
    const [title, setTitle] = React.useState(data.title);
    const [socialSecurityNumber, setSocialSecurityNumber] = React.useState(
        data.socialSecurityNumber,
    );
    const fireValidationResultWithDebounce = debounce((isValid: boolean) => {
        if (onValidation) {
            onValidation(isValid);
        }
    }, 300);

    const onDateChange = React.useCallback((date: dayjs.Dayjs | null) => {
        if (date !== null) {
            setBirthDate(date.toDate());
            setValidBirthDate(date && dayjs().diff(date, 'year') >= 18);
        }
    }, []);

    React.useEffect(() => {
        const validation = isPrimaryRepresentativeStepOneDataValid(
            firstName,
            lastName,
            title,
            socialSecurityNumber,
            birthDate,
        );
        fireValidationResultWithDebounce(
            validation.isValid ||
                (data.status === 'require_information' && !!beneficiaryIdFile),
        );
    }, [
        firstName,
        lastName,
        title,
        socialSecurityNumber,
        birthDate,
        fireValidationResultWithDebounce,
        beneficiaryIdFile,
        data,
    ]);
    const onTextFieldValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            switch (event.target.id) {
                case 'admin-form-first-name':
                    setFirstName(value);
                    break;
                case 'admin-form-last-name':
                    setLastName(value);
                    break;
                case 'admin-form-title':
                    setTitle(value);
                    break;
                case 'admin-form-securityNo-1':
                case 'admin-form-securityNo-2': {
                    if (value.length > 0 && Number.isNaN(Number(value))) return;
                    let formatedValue = value.replace(' ', '');
                    formatedValue = formatedValue.replace(',', '');
                    formatedValue = formatedValue.replace('.', '');
                    setSocialSecurityNumber(formatedValue);
                    break;
                }
                default:
                    break;
            }
        },
        [setFirstName, setLastName, setTitle, setSocialSecurityNumber],
    );
    const submit = React.useCallback(
        () => ({
            ...data,
            isPrimaryRepresentative,
            firstName,
            lastName,
            title,
            birthDate,
            socialSecurityNumber,
            beneficiaryIdFile,
        }),
        [
            data,
            isPrimaryRepresentative,
            firstName,
            lastName,
            title,
            birthDate,
            socialSecurityNumber,
            beneficiaryIdFile,
        ],
    );
    useImperativeHandle(forwardRef, () => ({
        submit: submit,
    }));
    const handleCheckChange = React.useCallback(
        (checked: boolean): void => {
            setIsPrimaryRepresentative(checked);
            if (checked) {
                setFirstName(user.firstName);
                setLastName(user.lastName);
                setTitle(user.title);
            } else {
                setFirstName('');
                setLastName('');
                setTitle('');
            }
        },
        [setIsPrimaryRepresentative, user],
    );
    return (
        <>
            <RepUploadSection
                onChange={setBeneficiaryIdFile}
                show={data.status === 'require_information'}
            />
            <GivelifyLabel
                id="admin-form-header-text"
                marginBottom={4}
                text={copy.headerText}
                variant="body1"
            />
            <GivelifyLabel
                fontSize="22px"
                id="admin-form-heading-1"
                lineHeight="30px"
                marginBottom={2.25}
                text={copy.primaryRepresentativeInformation}
                variant="heading3M"
            />
            <GivelifyCheckbox
                aria-label="I am the primary representative"
                checked={isPrimaryRepresentative}
                id="primary-rep"
                label={copy.iAmPrimaryRepresentative}
                name="is primary representative"
                onChange={(_ev, ch) => handleCheckChange(ch)}
            />
            <GivelifyLabel
                color={DesignTokens.color.globalNeutral600}
                id="admin-form-tip"
                marginBottom={2}
                marginTop={3}
                text={copy.allFieldsRequired}
                variant="caption1"
            />
            <BoxV>
                <BoxH>
                    <GivelifyTextField
                        ariaLabel="First name editor"
                        data-testid="firstName"
                        debounceValidation={simpleValidation}
                        id="admin-form-first-name"
                        label={copy.firstNamePlaceholder}
                        onChange={onTextFieldValueChange}
                        placeholder={copy.firstNamePlaceholder}
                        value={firstName}
                        width="100%"
                    />
                    <GivelifyTextField
                        ariaLabel="Last name editor"
                        data-testid="lastName"
                        debounceValidation={simpleValidation}
                        id="admin-form-last-name"
                        label={copy.lastNamePlaceholder}
                        onChange={onTextFieldValueChange}
                        placeholder={copy.lastNamePlaceholder}
                        value={lastName}
                        width="100%"
                    />
                </BoxH>
                <BoxH>
                    <GivelifyTextField
                        ariaLabel="Title editor"
                        data-testid="title"
                        debounceValidation={simpleValidation}
                        id="admin-form-title"
                        label={copy.titlePlaceholder}
                        marginBottom={16}
                        onChange={onTextFieldValueChange}
                        placeholder={copy.titlePlaceholder}
                        value={title}
                        width="100%"
                    />
                </BoxH>
                <BoxZ>
                    <GivelifyDatePicker
                        disableFuture
                        fullwidth
                        ariaLabel="BirthDate editor"
                        helperText={validBirthDate ? '' : copy.mustBeOver18Text}
                        id="admin-form-birthDate-1"
                        label={copy.birthDatePlaceholder}
                        onDateChange={onDateChange}
                        placeholder={copy.birthDatePlaceholder}
                        size="medium"
                        state={validBirthDate ? 'idle' : 'error'}
                        value={birthDate ? dayjs(birthDate) : undefined}
                    />
                    <GivelifyTextField
                        ariaLabel="Security number editor"
                        debounceErrorText={copy.invalidSsnText}
                        debounceValidation={isSsnValid}
                        id="admin-form-securityNo-1"
                        label={copy.securityNoPlaceholder}
                        maxLength={9}
                        onChange={onTextFieldValueChange}
                        placeholder={copy.securityNoPlaceholder}
                        value={socialSecurityNumber}
                        width="100%"
                    />
                </BoxZ>
            </BoxV>
        </>
    );
};

export const PrimaryRepresentativeStepOne = forwardRef<
    PrimaryRepresentativeStepRef,
    PrimaryRepresentativeStepOneProps
>(PrimaryRepresentativeStepOneComponent);
