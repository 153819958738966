import { TimeFrameValue } from '@givelify/utils';
import { MESSAGE_FILTER_VALUE, TRANSACTION_FILTER_VALUE } from '../models';
import { getDateRangeQuery } from './dateRangeQuery';
import { getMessageFilterQuery } from './messageFilterQuery';

export type DonationQueryParams = {
    timeFrame: TimeFrameValue;
    page?: number;
    pageSize?: number;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    sort?: string;
    order?: string;
    onlyDate?: boolean;
    search?: string;
};

export const getDonationQuery = ({
    timeFrame,
    envelopeIds,
    messageFilter,
    transactionFilter,
    onlyDate,
    ...params
}: DonationQueryParams) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
            ([_, value]) => value !== undefined && value !== '',
        ),
    ) as Record<string, string>;

    const dateRangeQuery = getDateRangeQuery(timeFrame, '?', onlyDate);
    const envelopeQuery = envelopeIds
        ? envelopeIds.map((id) => `&envelopes[]=${id}`).join('')
        : '';
    const messageFilterQuery = getMessageFilterQuery(messageFilter);
    const transactionQuery =
        transactionFilter && transactionFilter !== TRANSACTION_FILTER_VALUE.ALL
            ? `&${transactionFilter.toLowerCase()}=1`
            : '';
    const otherParamsQuery = Object.keys(filteredParams).length
        ? `&${new URLSearchParams(filteredParams).toString()}`
        : '';

    const queryString = `${dateRangeQuery}${envelopeQuery}${messageFilterQuery}${transactionQuery}${otherParamsQuery}`;
    return queryString;
};
