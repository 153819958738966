import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GivelifyLoading } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { yodleeAccountsAPI } from '../api/requests/api';
import { I18N_NAMESPACE } from '../consts';
import {
    extractAccountAndRouting,
    openFastLink,
    YodleeAccountIds,
    yodleeContainerId,
    yodleeUnableToRetrieveKey,
} from './yodleeConfig';
import { YodleeSavingAccountError } from './YodleeErrorContent';
import { YodleeBankAccountInfo } from './yodleeResponseInterfaces';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        yodleeContainerStyles: {
            position: 'relative',
            '& .yodlee-loading': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: '-1',
                textAlign: 'center',
                transform: 'translate(-50%)',
            },
            '& .yodlee-loader': {
                marginTop: theme.spacing(3),
            },
            ['& #' + yodleeContainerId]: {
                maxHeight: '85vh',
                overflow: 'auto',
            },
        },
    }),
);

export interface YodleeOpenProps {
    doneeId: number;
    providerId: number;
    accessToken: string;
    onError: (errorKey: string) => unknown;
    onClose: () => unknown;
    onSubmit: (accountNumber: number, routingNumber: number) => unknown;
}

const YodleeOpen: React.FCC<YodleeOpenProps> = ({
    doneeId,
    providerId,
    accessToken,
    onError,
    onClose,
    onSubmit,
}) => {
    const { yodleeContainerStyles } = useStyles();
    const { t } = useTranslation(I18N_NAMESPACE);
    const [requestState, makeRequest] = useApiRequest<YodleeBankAccountInfo>();
    const [accountIds, setAccountIds] = useState<YodleeAccountIds | undefined>(
        undefined,
    );
    const [showSavingAccountError, setShowSavingAccountError] =
        useState<boolean>(false);

    const { yodleeLoadingMessage } = useMemo(
        () => ({
            yodleeLoadingMessage: t(
                'directDeposit.yodlee.yodleeLoadingMessage',
            ),
        }),
        [t],
    );

    const reOpenYodlee = useCallback(() => {
        setShowSavingAccountError(false);
    }, [setShowSavingAccountError]);

    useEffect(() => {
        if (showSavingAccountError) return;
        openFastLink(providerId, accessToken, onClose, onError, setAccountIds);
        //eslint-disable-next-line
    }, [providerId, accessToken, showSavingAccountError]);

    useEffect(() => {
        if (accountIds === undefined) return;
        makeRequest(
            yodleeAccountsAPI(
                doneeId,
                accountIds.accountId,
                accountIds.providerAccountId,
                accessToken,
            ),
        );
        //eslint-disable-next-line
    }, [accountIds]);

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            const response = extractAccountAndRouting(requestState.response);
            if (response) {
                if (response.accountType !== 'CHECKING') {
                    setShowSavingAccountError(true);
                } else {
                    onSubmit(response.account, response.routing);
                    onClose();
                }
            } else {
                onError(yodleeUnableToRetrieveKey);
                onClose();
            }
        }
        if (requestState.type === 'REQUEST_ERROR') {
            onError(yodleeUnableToRetrieveKey);
            onClose();
        }
        //eslint-disable-next-line
    }, [requestState, setShowSavingAccountError]);

    return (
        <div className={yodleeContainerStyles}>
            {showSavingAccountError ? (
                <YodleeSavingAccountError onClick={reOpenYodlee} />
            ) : (
                <>
                    <div id={yodleeContainerId}></div>
                    <div className="yodlee-loading">
                        <div>{yodleeLoadingMessage}</div>
                        <GivelifyLoading
                            className="yodlee-loader"
                            type="circular"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default React.memo(YodleeOpen);
